import Api from './api'

export default{
    all(){
            return Api.get('api/rule_policy/action_types/list')
    },

    save(payload){
            return Api.post('api/rule_policy/action_types/data_entry', payload)        
    },
    
    delete(payload){
        return Api.post('api/rule_policy/action_types/delete', payload)        
    },
    
    view(payload){
        return Api.get('api/rule_policy/action_types/' + payload)        
    },
    
}