import Api from './api'

export default{
    all(payload){
            return Api.get('api/employees/etq_info/list/' + payload)
    },

    save(payload){
            return Api.post('api/employees/etq_info/data_entry', payload)        
    },
    
    delete(payload){
        return Api.post('api/employees/etq_info/delete', payload)        
    },
    
    view(payload){
        return Api.get('api/employees/etq_info/' + payload)        
    },
    
}