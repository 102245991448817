var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.feedback_error)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[(typeof _vm.feedback_message === 'string')?_c('div',[_vm._v(" "+_vm._s(_vm.feedback_message)+" ")]):_vm._l((_vm.feedback_message),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error.message)+" ")])})],2):_vm._e(),[_c('div',{staticClass:"d-flex justify-content-center"},[_c('mdb-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"big":"","color":"blue"}})],1),(!_vm.isLoading)?_c('div',[_c('mdb-card',[_c('mdb-card-body',[_c('mdb-tabs',{staticClass:"mb-5",attrs:{"active":0,"tabs":"","card":"","color":"primary","justify":"","links":[
              { text: 'Attendance' },
              { text: 'Leave' },
              { text: 'Overtime' } ]}},[_c('template',{slot:'Attendance'},[(_vm.approver_list.attendance != '')?_c('div',[(
                    _vm.approver_list.attendance.attendance_auto_approval == 'yes'
                  )?_c('div',[_c('label',[_vm._v("Does not required approval for attendance request")])]):_c('div',[(_vm.approver_list.attendance.approvers.length > 0)?_vm._l((_vm.approver_list.attendance
                        .approvers),function(approvers,index){return _c('div',{key:index},[_vm._v(" Level "+_vm._s(approvers[0].attendance_approval_level)+" "),_c('mdb-row',_vm._l((approvers),function(approver,index2){return _c('mdb-col',{key:index2,attrs:{"md":"6"}},[_c('div',{staticClass:"approval_info_box mt-3"},[(approver.profile_image)?_c('img',{staticClass:"rounded-circle float-left",attrs:{"src":approver.profile_image,"width":"50","height":"50"},on:{"error":_vm.imageLoadError}}):_vm._e(),_c('div',{staticClass:"float-left ml-3 mb-3"},[_c('div',{staticClass:"employee_name"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approver.epinfo_name))+" ")]),_c('div',{staticClass:"employee_info_text_sec"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approver.jp_name))+" ")]),_c('div',{staticClass:"employee_info_text"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approver.department_name))+" ")])]),(
                                approvers.length > 1 ||
                                (_vm.approver_list.attendance.approvers.length !=
                                  1 &&
                                  _vm.approver_list.attendance.approvers.length ==
                                    approver.attendance_approval_level)
                              )?_c('mdb-btn',{staticClass:"float-right close_btn",attrs:{"color":"uno-color"},on:{"click":function($event){return _vm.showDeleteModal(
                                  'attendance',
                                  approver.attendance_approval_id,
                                  index,
                                  index2
                                )}}},[_c('mdb-icon',{staticClass:"uno-text close_btn_color mr-2",attrs:{"icon":"times-circle","size":"2x"}})],1):_vm._e()],1)])}),1),_c('hr')],1)}):_vm._e()],2)]):_vm._e()]),_c('template',{slot:'Leave'},[(_vm.approver_list.leave != '')?_c('div',[(_vm.approver_list.leave.leave_auto_approval == 'yes')?_c('div',[_c('label',[_vm._v("Does not required approval for leave request")])]):_c('div',[(_vm.approver_list.leave.approvers.length > 0)?_vm._l((_vm.approver_list.leave
                        .approvers),function(approvers,index){return _c('div',{key:index},[_vm._v(" Level "+_vm._s(approvers[0].leave_approval_level)+" "),_c('mdb-row',_vm._l((approvers),function(approver,index2){return _c('mdb-col',{key:index2,attrs:{"md":"6"}},[_c('div',{staticClass:"approval_info_box mt-3"},[(approver.profile_image)?_c('img',{staticClass:"rounded-circle float-left",attrs:{"src":approver.profile_image,"width":"50","height":"50"},on:{"error":_vm.imageLoadError}}):_vm._e(),_c('div',{staticClass:"float-left ml-3 mb-3"},[_c('div',{staticClass:"employee_name"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approver.epinfo_name))+" ")]),_c('div',{staticClass:"employee_info_text_sec"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approver.jp_name))+" ")]),_c('div',{staticClass:"employee_info_text"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approver.department_name))+" ")])]),(
                                approvers.length > 1 ||
                                (_vm.approver_list.leave.approvers.length != 1 &&
                                  _vm.approver_list.leave.approvers.length ==
                                    approver.leave_approval_level)
                              )?_c('mdb-btn',{staticClass:"float-right close_btn",attrs:{"color":"uno-color"},on:{"click":function($event){return _vm.showDeleteModal(
                                  'leave',
                                  approver.leave_approval_id,
                                  index,
                                  index2
                                )}}},[_c('mdb-icon',{staticClass:"uno-text close_btn_color mr-2",attrs:{"icon":"times-circle","size":"2x"}})],1):_vm._e()],1)])}),1),_c('hr')],1)}):_vm._e()],2)]):_vm._e()]),_c('template',{slot:'Overtime'},[(_vm.approver_list.overtime != '')?_c('div',[(
                    _vm.approver_list.overtime.overtime_auto_approval == 'yes'
                  )?_c('div',[_c('label',[_vm._v("Does not required approval for overtime request")])]):_c('div',[(_vm.approver_list.overtime.approvers.length > 0)?_vm._l((_vm.approver_list.overtime
                        .approvers),function(approvers,index){return _c('div',{key:index},[_vm._v(" Level "+_vm._s(approvers[0].overtime_approval_level)+" "),_c('mdb-row',_vm._l((approvers),function(approver,index2){return _c('mdb-col',{key:index2,attrs:{"md":"6"}},[_c('div',{staticClass:"approval_info_box mt-3"},[(approver.profile_image)?_c('img',{staticClass:"rounded-circle float-left",attrs:{"src":approver.profile_image,"width":"50","height":"50"},on:{"error":_vm.imageLoadError}}):_vm._e(),_c('div',{staticClass:"float-left ml-3 mb-3"},[_c('div',{staticClass:"employee_name"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approver.epinfo_name))+" ")]),_c('div',{staticClass:"employee_info_text_sec"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approver.jp_name))+" ")]),_c('div',{staticClass:"employee_info_text"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approver.department_name))+" ")])]),(
                                approvers.length > 1 ||
                                (_vm.approver_list.overtime.approvers.length !=
                                  1 &&
                                  _vm.approver_list.overtime.approvers.length ==
                                    approver.overtime_approval_level)
                              )?_c('mdb-btn',{staticClass:"float-right close_btn",attrs:{"color":"uno-color"},on:{"click":function($event){return _vm.showDeleteModal(
                                  'overtime',
                                  approver.overtime_approval_id,
                                  index,
                                  index2
                                )}}},[_c('mdb-icon',{staticClass:"uno-text close_btn_color mr-2",attrs:{"icon":"times-circle","size":"2x"}})],1):_vm._e()],1)])}),1),_c('hr')],1)}):_vm._e()],2)]):_vm._e()])],2)],1)],1),_c('ModalDeleteConfimation',{on:{"comfirmAction":_vm.handleDeleteRecord}})],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }