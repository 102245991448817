<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card
          class="mb-4"
          v-if="lateEarlyGraphData.label.length > 0 || late_data.length > 0"
        >
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Late/Early Report</h6>

                <div>
                  <VerticalBarChartView
                    v-if="
                      lateEarlyGraphData && lateEarlyGraphData.label.length > 0
                    "
                    :barGraphData="lateEarlyGraphData"
                    
                    :xlabel="'Departments'"
                    :ylabel="'Late Minutes'"
                  />
                </div>
                <br />
                <br />
                <mdb-row>
                  <mdb-col>
                    <div>
                      <mdb-tbl responsive striped bordered sm>
                        <mdb-tbl-head style="background: #96a6bf; color: #fff">
                          <tr>
                            <td>Department</td>
                            <td>Total Employee</td>
                            <td>Late Employee</td>
                            <td>Late Minutes</td>
                            <td>Late Amount</td>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr
                            v-for="(late_item, index) in late_data"
                            :key="index"
                          >
                            <td>{{ late_item["department"] }}</td>
                            <td>{{ late_item["total_employee"] }}</td>
                            <td>{{ late_item["late_employee"] }}</td>
                            <td>{{ late_item["late_minutes"] | formatNumbers }}</td>
                            <td>{{ late_item["late_amount"] | formatNumbers }}</td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <mdb-card class="mb-4" v-if="top_late_employee.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Top Employee's Late/Early Minutes</h6>

                <div>
                  <mdb-tbl responsive striped bordered sm>
                    <mdb-tbl-head style="background: #96a6bf; color: #fff">
                      <tr>
                        <td>Name</td>
                        <td>Position</td>
                        <td>Department</td>
                        <td>Late/Early Minutes</td>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body>
                      <tr
                        v-for="(item, index) in top_late_employee"
                        :key="index"
                      >
                        <td>{{ item["name"] }}</td>
                        <td>{{ item["position"] }}</td>
                        <td>{{ item["department"] }}</td>
                        <td>{{ item["late_minutes"]  | formatNumbers }}</td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <!-- ///////////////////// -->

        <mdb-card class="mb-4" v-if="late_comparision_data.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Monthly Late/Early Analysis</h6>

                <mdb-row>
                  <mdb-col>
                    <div>
                      <mdb-tbl responsive striped bordered sm>
                        <mdb-tbl-head style="background: #96a6bf; color: #fff">
                          <tr>
                            <td></td>
                            <td
                              :colspan="late_comparision_months.length"
                              style="text-align: center"
                            >
                              Late Minutes
                            </td>
                          </tr>

                          <tr>
                            <td
                              v-for="(
                                item_value, index
                              ) in late_comparision_months"
                              :key="index"
                            >
                              {{ item_value }}
                            </td>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr
                            v-for="(
                              overtime_item, index
                            ) in late_comparision_data"
                            :key="index"
                          >
                            <td
                              v-for="(item_value, index2) in overtime_item"
                              :key="index2"
                            >
                              {{ item_value  | formatNumbers }}
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <!-- ///////////////////// -->

        <!-- ///////////////////////// -->

        <mdb-card class="mb-4" v-if="late_detail_comparision_data.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Top Employee's Late/Early within 6 Months</h6>

                <mdb-row>
                  <mdb-col>
                    <div>
                      <mdb-tbl responsive striped bordered sm>
                        <mdb-tbl-head style="background: #96a6bf; color: #fff">
                          <tr>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td
                              :colspan="late_detail_comparision_header.length - 3"
                              style="text-align: center"
                            >
                              Late/Early Minutes
                            </td>
                          </tr>
                          <tr>
                            <td
                              v-for="(
                                item_value, index
                              ) in late_detail_comparision_header"
                              :key="index"
                            >
                              {{ item_value }}
                            </td>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr
                            v-for="(
                              late_item, index
                            ) in late_detail_comparision_data"
                            :key="index"
                          >
                            <td
                              v-for="(item_value, index2) in late_item"
                              :key="index2"
                            >
                              {{ item_value  | formatNumbers }}
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <!-- ///////////////////////// -->

        <mdb-row
          v-if="
            lateEarlyGraphData.label.length == 0 &&
            late_data.length == 0 &&
            top_late_employee.length == 0 &&
            late_comparision_data.length == 0 &&
            late_detail_comparision_data.length == 0
          "
        >
          <mdb-col>
            <mdb-card>
              <mdb-card-body>
                <div class="d-flex justify-content-center">
                  <h5 style="margin-top: 60px">No Record Found.</h5>
                </div>
                <div class="d-flex justify-content-center">
                  <img src="/empty_item.svg" alt="Empty Record" />
                </div>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </template>
    </div>
  </div>
</template>
<script>
import ManagementReport from "@/apis/ManagementReport";
import { mapActions, mapGetters } from "vuex";

import VerticalBarChartView from "@/components/VerticalBarChartView";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    VerticalBarChartView,
  },
  data() {
    return {
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",

      lateEarlyGraphData: false,
      late_data: [],
      top_late_employee: [],
      late_comparision_months: [],
      late_comparision_data: [],
      late_detail_comparision_header:[],
      late_detail_comparision_data: [],
    };
  },
  props: ["year", "month"],
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    fetchData() {
      this.updateLoading(true);

      const payload = {
        date: this.month + "-" + this.year,
      };

      // const response = {
      //   status: 200,
      //   data: {
      //     lateEarlyGraphData: {
      //       label: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
      //       dataLabel: "No of Employee",
      //       dataSet: [12, 19, 3, 5, 2, 3],
      //     },
      //     late_data: [
      //       {
      //         department: "lorum ipsuam",
      //         total_employee: 10,
      //         late_employee: 6,
      //         late_minutes: 50,
      //         late_amount: 30333,
      //       },
      //       {
      //         department: "lorum ipsuam",
      //         total_employee: 20,
      //         late_employee: 16,
      //         late_minutes: 150,
      //         late_amount: 130333,
      //       },
      //       {
      //         department: "lorum ipsuam",
      //         total_employee: 30,
      //         late_employee: 26,
      //         late_minutes: 250,
      //         late_amount: 230333,
      //       },
      //     ],

      //     top_late_employee: [
      //       {
      //         name: "lorum ipsuam",
      //         position: "lorum ipsuam",
      //         department: "lorum ipsuam",
      //         late_minutes: 30,
      //       },
      //       {
      //         name: "lorum ipsuam",
      //         position: "lorum ipsuam",
      //         department: "lorum ipsuam",
      //         late_minutes: 30,
      //       },
      //       {
      //         name: "lorum ipsuam",
      //         position: "lorum ipsuam",
      //         department: "lorum ipsuam",
      //         late_minutes: 30,
      //       },
      //     ],

      //     late_comparision_months: ["January", "February", "March"],

      //     late_comparision_data: [
      //       ["lorum ipsuam", 1, 2, 3],
      //       ["lorum ipsuam", 1, 2, 3],
      //       ["lorum ipsuam", 1, 2, 3],
      //     ],

      //     late_detail_comparision_data: [
      //       ["lorum name", "lorum position", "lorum department", 1, 2, 3],
      //       ["lorum name", "lorum position", "lorum department", 1, 2, 3],
      //       ["lorum name", "lorum position", "lorum department", 1, 2, 3],
      //     ],
      //   },
      // };

      // this.lateEarlyGraphData = response.data.lateEarlyGraphData;
      // this.late_data = response.data.late_data;
      // this.top_late_employee = response.data.top_late_employee;
      // this.late_comparision_months = response.data.late_comparision_months;
      // this.late_comparision_data = response.data.late_comparision_data;
      // this.late_detail_comparision_data = response.data.late_detail_comparision_data;

      ManagementReport.lateGraphData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.lateEarlyGraphData = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.lateData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.late_data = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.lateTopEmployee(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.top_late_employee = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.lateMonthlyComparison(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.late_comparision_months =
              response.data.data.comparision_header;
            this.late_comparision_data = response.data.data.comparision_data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.lateTopMonthlyEmployee(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.late_detail_comparision_header = 
              response.data.data.comparision_detail_header;
            this.late_detail_comparision_data =
              response.data.data.comparision_detail_data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });
    },

    validateParams() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (
        !months.find((month) => month.toLowerCase() == this.month.toLowerCase())
      ) {
        this.$router.push({ name: "managementReport" });
      }

      if (!/^\d{4}$/.test(this.year)) {
        this.$router.push({ name: "managementReport" });
      }

      this.fetchData();
    },
  },
  created() {
    this.validateParams();
  },
};
</script>

<style scoped>
.rotate_text {
  transform: rotate(180deg);

  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(180deg);

  /* Firefox */
  -moz-transform: rotate(180deg);

  /* IE */
  -ms-transform: rotate(180deg);

  /* Opera */
  -o-transform: rotate(180deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
</style>