import Api from './api'

export default{
    all(payload){
            return Api.get('api/employees/insurance/list', {params: payload})
    },

    save(payload){
            return Api.post('api/employees/insurance/data_entry', payload)        
    },
    
    // edit(payload){
    //     return Api.post('api/employees/insurance/edit', payload)        
    // },

    delete(payload){
        return Api.post('api/employees/insurance/delete', payload)        
    },
    
    view(payload){
        return Api.get('api/employees/insurance/' + payload)        
    },
    
}