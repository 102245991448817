<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <template v-if="!employee_id">
                <mdb-row class="mb-3">
                  <mdb-col offsetMd="2" md="8">
                    <div class="float-left mt-3 sec_title">Select Employee</div>
                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="float-right btn_outline_style"
                      icon="user-friends"
                      @click.native="modal = true"
                    >
                      {{ $t("payrolls.button.select_employee") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>

                <mdb-row v-if="selectedEmployeeData" class="mb-5">
                  <mdb-col offsetMd="2" md="8">
                    <div class="approval_info_box">
                      <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
                    </div>
                  </mdb-col>
                </mdb-row>
              </template>

              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    v-model="checkinDatePickerValue"
                    label="Check In Date"
                    title="Select date"
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-time-picker-2
                    id="checkinTimePicker"
                    label="Check In Time"
                    v-model="checkinTimePickerValue"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    v-model="checkoutDatePickerValue"
                    label="Check Out Date"
                    title="Select date"
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-time-picker-2
                    id="checkoutTimePicker"
                    label="Checkout Time"
                    v-model="checkoutTimePickerValue"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />

    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapSingleSelectTable
          @updateSelectedEmployee="setSelectedEmployee"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import Attendance from "@/apis/Attendance";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";

import EmployeeListBoostrapSingleSelectTable from "@/components/EmployeeListBoostrapSingleSelectTable";
import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  mdbTimePicker2,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

export default {
  name: "EmployeeAttendanceForm",
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbDatePicker2,
    mdbTimePicker2,
    ModalDeleteConfimation,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeInfoBoxLayout,
    EmployeeListBoostrapSingleSelectTable,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      attendance: {
        attendance_id: "",
        attendance_user_id: this.employee_id ? this.employee_id : "",
        attendance_checkin_time: "",
        attendance_checkout_time: "",
        attendance_unique_index: "",
      },
      checkinDatePickerValue: "",
      checkinTimePickerValue: "",

      checkoutDatePickerValue: "",
      checkoutTimePickerValue: "",
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      selectedEmployeeData: null,
      modal: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.attendance.attendance_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    setSelectedEmployee(val) {
      this.modal = false;
      this.selectedEmployeeData = val;
      this.attendance.attendance_user_id = val.employee_id;
    },
    handleCheckinPickersValue() {
      let output = "Pick date and time";
      if (this.checkinDatePickerValue && this.checkinTimePickerValue)
        output = `${this.checkinDatePickerValue} ${this.checkinTimePickerValue}`;
      else if (this.checkinDatePickerValue)
        output = this.checkinDatePickerValue;
      else if (this.checkinTimePickerValue)
        output = this.checkinTimePickerValue;
      return output;
    },

    handleCheckoutPickersValue() {
      let output = "Pick date and time";
      if (this.checkoutDatePickerValue && this.checkoutTimePickerValue)
        output = `${this.checkoutDatePickerValue} ${this.checkoutTimePickerValue}`;
      else if (this.checkoutDatePickerValue)
        output = this.checkoutDatePickerValue;
      else if (this.checkoutTimePickerValue)
        output = this.checkoutTimePickerValue;
      return output;
    },
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },

    parseDateTimeString(val) {
      const data = new Date(val);
      let month = data.getMonth() + 1;
      let day = data.getDate();
      let year = data.getFullYear();
      if (day <= 9) day = "0" + day;
      if (month < 10) month = "0" + month;

      let hour = data.getHours();
      let minutes = data.getMinutes();
      let ampm = "am";
      if (hour > 12) {
        hour = hour % 12;
        ampm = "pm";
      }

      if (hour <= 9) hour = "0" + hour;
      if (minutes <= 9) minutes = "0" + minutes;
      return {
        date: year + "-" + month + "-" + day,
        time: hour + ":" + minutes + ampm,
      };
    },

    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          Attendance.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  //  console.log(response.data.data)

                  this.attendance.attendance_id =
                    response.data.data.attendance_id;
                  this.attendance.attendance_user_id =
                    response.data.data.attendance_user_id;
                  this.attendance.attendance_checkin_time =
                    response.data.data.check_in.attendance_checkin_time;
                  this.attendance.attendance_checkout_time =
                    response.data.data.check_out.attendance_checkout_time;

                  if (
                    this.attendance.attendance_checkin_time != "" &&
                    this.attendance.attendance_checkin_time != "NULL" &&
                    this.attendance.attendance_checkin_time !=
                      "0000-00-00 00:00:00"
                  ) {
                    const rst = this.parseDateTimeString(
                      this.attendance.attendance_checkin_time
                    );

                    this.checkinDatePickerValue = rst.date;
                    this.checkinTimePickerValue = rst.time;
                  }

                  if (
                    this.attendance.attendance_checkout_time != "" &&
                    this.attendance.attendance_checkout_time != "NULL" &&
                    this.attendance.attendance_checkout_time !=
                      "0000-00-00 00:00:00"
                  ) {
                    const rst = this.parseDateTimeString(
                      this.attendance.attendance_checkout_time
                    );

                    this.checkoutDatePickerValue = rst.date;
                    this.checkoutTimePickerValue = rst.time;
                  }

                  if (!this.employee_id) {
                    this.selectedEmployeeData = {
                      epinfo_name: response.data.data.epinfo_name,
                      employee_code: response.data.data.employee_code,
                      profile_image: response.data.data.profile_image,
                      department_name: response.data.data.department_name,
                      jp_name: response.data.data.jp_name,
                      rt_name: response.data.data.rt_name,
                    };
                    //emp info
                  }

                  if (!this.employee_id) {
                    this.selectedEmployeeData =
                      response.data.data.employee_info;
                    //emp info
                  }

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    employeeAttendanceList: {
                      employee_id: this.attendance.attendance_user_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "employeeAttendanceList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "employeeAttendanceList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "employeeAttendanceList" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      this.attendance.attendance_checkin_time =
        this.checkinDatePickerValue + " " + this.checkinTimePickerValue;
      this.attendance.attendance_checkout_time =
        this.checkoutDatePickerValue + " " + this.checkoutTimePickerValue;
      this.attendance.attendance_unique_index = "44234234234";
      Attendance.save(this.attendance).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            // if (this.attendance.attendance_id != "") {
            //   this.$notify.success({
            //     message: response.data.message,
            //     position: "top right",
            //     timeOut: 5000,
            //   });
            //   this.$router.push({
            //     name: "employeeAttendanceList",
            //     params: { employee_id: this.attendance.attendance_user_id },
            //   });
            // } else {
            //   this.$notify.success({
            //     message: response.data.message,
            //     position: "top right",
            //     timeOut: 5000,
            //   });
            //   this.resetForm();
            // }
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });
            if (this.employee_id) {
              this.$router.push({
                name: "employeeAttendanceList",
                params: { employee_id: this.attendance.attendance_user_id },
              });
            } else {
              this.$router.push({
                name: "viewManageAttendance",
                params: { _id: this._id },
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
    },
    redirectBackAction() {
      // this.$router.push({
      //   name: "employeeAttendanceList",
      //   params: { employeeAttendanceList: this.attendance.attendance_user_id },
      // });
      if (this.employee_id) {
        this.$router.push({
          name: "employeeAttendanceList",
          params: { employee_id: this.attendance.attendance_user_id },
        });
      } else {
        if (this._id) {
          this.$router.push({
          name: "viewManageAttendance",
          params: { _id: this._id },
        });
        }else{
          this.$router.push({
          name: "listManageAttendances"
        });
        }
        
      }
    },
    resetAlert() {
      this.feedback_error = false;
    },
    // resetForm() {
    //   Object.keys(this.attendance).forEach((key) => {
    //     this.attendance[key] = "";
    //   });

    //   this.checkinDatePickerValue = "";
    //   this.checkinTimePickerValue = "";

    //   this.checkoutDatePickerValue = "";
    //   this.checkoutTimePickerValue = "";

    //   this.attendance.attendance_user_id = this.employee_id;
    // },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.attendance.attendance_id > 0) {
        Attendance.delete({
          attendance_id: this.attendance.attendance_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              // this.$router.push({
              //   name: "employeeAttendanceList",
              //   params: {
              //     _id: this.attendance.attendance_user_id,
              //   },
              // });
              if (this.employee_id) {
                this.$router.push({
                  name: "employeeAttendanceList",
                  params: { employee_id: this.attendance.attendance_user_id },
                });
              } else {
                this.$router.push({
                  name: "viewManageAttendance",
                  params: { _id: this._id },
                });
              }
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      employeeAttendanceList: {
        employee_id: this.attendance.attendance_user_id,
      },
    };
    this.fetchRecord();
  },
};
</script>