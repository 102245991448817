import Api from './api'

export default {
  employeeAttendance(payload) {
    return Api.get('api/manage_attendance/list', { params: payload })
  },

  save(payload) {
    return Api.post('api/manage_attendance/data_entry', payload)
  },

  delete(payload) {
    return Api.post('api/manage_attendance/delete', payload)
  },

  view(payload) {
    return Api.get('api/manage_attendance/' + payload)
  },

  behalf_approval(payload) {
    return Api.post('api/manage_attendance/behalf_approval', payload)
  },

  

  getApprovalList(payload) {
    return Api.get('api/mobile_api/attendance_approve/list', { params: payload })
  },
  
  getApprovalDetail(payload) {
    return Api.post('api/mobile_api/attendance_approve/detail', payload)
  },
  
  submitApproval(payload) {
    return Api.post('api/mobile_api/attendance_approve/approval', payload)
  },


  request_calculate(payload) {
    return Api.post('api/payroll/monthly_attendance/request_calculate', payload)
  },
  
  submit_calculate(payload) {
    return Api.post('api/payroll/monthly_attendance/calculate', payload)
  },

  
  getCheckMonthlyAttendance(payload) {
    return Api.get('api/payroll/monthly_attendance/list', { params: payload })
  },

  
  downloadMonthlyAttendance(payload) {
    return Api.post('api/payroll/monthly_attendance/export', payload)
  },



}