<template>
  <form @submit.prevent="handleFormSubmit">
    <mdb-row>
      <mdb-col md="4" lg="3" class="form-inline-search">
        <mdb-input
          type="text"
          :label="$t('general.formInput.keywords')"
          v-model="filterForm.keywords"
          outline
        />
      </mdb-col>
      <mdb-col md="4" lg="3" class="form-inline-search">
        <DepartmentDropdown
          @updateDepartmentID="setDepartmentID"
          :default_selected_value="filterForm.department_id"
          :required="false"
        />
      </mdb-col>
      <mdb-col md="4" lg="3" class="form-inline-search">
        <PositionDropdown
          @updatePositionID="setPositionID"
          :default_selected_value="filterForm.jp_id"
          :required="false"
          :resetSelected="doesFormReset"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col md="3" class="form-inline-search">
        <mdb-select
          search
          outline
          v-model.trim="yearsOptions"
          :label="$t('holidays.formInput.select_year')"
          placeholder=""
          v-on:getValue="getSelectValue"
        />
      </mdb-col>
      <mdb-col md="3" class="form-inline-search">
        <mdb-select
          outline
          v-model.trim="monthOptions"
          :label="$t('holidays.formInput.select_month')"
          placeholder=""
          v-on:getValue="getSelectMonthValue"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row class="mb-4">
      <mdb-col>
        <mdb-btn
          type="button"
          color="danger"
          class="btn_submit"
          size="sm"
          @click="resetForm"
        >
          <mdb-icon icon="undo" size="md" />
          {{ $t("general.button.reset") }}
        </mdb-btn>
        <mdb-btn type="Search" color="uno-color" class="btn_submit" size="sm">
          <mdb-icon icon="file-upload" size="md" />

          {{ $t("general.button.submit") }}
        </mdb-btn>
      </mdb-col>
    </mdb-row>
  </form>
</template>

<script>
import DepartmentDropdown from "@/components/DepartmentDropdown";
import PositionDropdown from "@/components/PositionDropdown";
import { mdbInput, mdbRow, mdbCol, mdbBtn, mdbIcon, mdbSelect } from "mdbvue";

const yearOption = (current, year) => {
  let years = [];
  for (let current_year = current; current_year >= 1900; --current_year) {
    if (year == current_year)
      years.push({ text: current_year, value: current_year, selected: true });
    else years.push({ text: current_year, value: current_year });
  }
  return years;
};

const month_name = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthOption = (month) => {
  let months = [];
  for (let i = 0; i < month_name.length; i++) {
    if (month == month_name[i])
      months.push({
        text: month_name[i],
        value: month_name[i],
        selected: true,
      });
    else months.push({ text: month_name[i], value: month_name[i] });
  }
  return months;
};

export default {
  components: {
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    DepartmentDropdown,
    PositionDropdown,
    mdbSelect,
  },
  props: ["filterFormData"],
  data() {
    return {
      filterForm: {
        keywords: "",
        department_id: "",
        jp_id: "",
        filter_year: "",
        filter_month: "",
      },
      yearsOptions: null,
      monthOptions: null,

      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,
    };
  },

  methods: {
    setDepartmentID(val) {
      this.filterForm.department_id = val;
    },
    setPositionID(val) {
      this.filterForm.jp_id = val;
    },
    getSelectValue(value) {
      this.filterForm.filter_year = value;
    },
    getSelectMonthValue(value) {
      this.filterForm.filter_month = value;
    },

    handleFormSubmit() {
      this.$emit("submitFilterForm", this.filterForm);
    },

    resetForm() {
      Object.keys(this.filterForm).forEach((key) => {
        this.filterForm[key] = "";
      });
      this.doesFormReset = !this.doesFormReset;
      this.$emit("submitFilterForm", this.filterForm);
    },
  },

  created() {
    
    this.filterForm = this.filterFormData;
    this.yearsOptions = yearOption(parseInt(new Date().getFullYear()) +1, this.filterForm.filter_year);
    this.monthOptions = monthOption(this.filterForm.filter_month);
  },
};
</script>