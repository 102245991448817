import Api from './api'

export default {

  all(payload) {
    return Api.get('api/manage_overtime/list', {params: payload})
  },

  save(payload) {
    return Api.post('api/manage_overtime/data_entry', payload)
  },

  delete(payload) {
    return Api.post('api/manage_overtime/delete', payload)
  },

  view(payload) {
    return Api.get('api/manage_overtime/' + payload)
  },


  behalf_approval(payload){
    return Api.post('api/manage_overtime/behalf_approval', payload)
  },


  
  getApprovalList(payload) {
    return Api.get('api/mobile_api/overtimes_approve/list', { params: payload })
  },
  
  submitApproval(payload) {
    return Api.post('api/mobile_api/overtimes_approve/approval', payload)
  },
  submitUpdateInApproval(payload) {
    return Api.post('api/mobile_api/overtimes_approve/update', payload)
  },


  exportCSVReport(payload){
    return Api.get('api/manage_overtime/export', { params: payload })
  }


}