import Api from './api'

export default{
    all(){
            return Api.get('api/rule_policy/attendance_policy/list')
    },

    save(payload){
            return Api.post('api/rule_policy/attendance_policy/data_entry', payload)        
    },
    
    delete(payload){
        return Api.post('api/rule_policy/attendance_policy/delete', payload)        
    },
    
    view(payload){
        return Api.get('api/rule_policy/attendance_policy/' + payload)        
    },
    
}