<template>
  <div>
    <h4>Payroll for : {{payroll_date}}</h4>
    <mdb-tbl responsive hover>
      <!-- Table head -->
      <mdb-tbl-head>
        <tr></tr>
      </mdb-tbl-head>

      <!-- Table body -->
      <mdb-tbl-body>
        <tr scope="row" v-for="(date_key, index) in data_ordering" :key="index">
          <td scope="row">{{ $t("payslips.label." + date_key) }}</td>
          <td v-for="(item, index) in calculateion_rule_data" :key="index">
            {{ item[date_key] }}
            <!-- {{date_key}} -->
          </td>
        </tr>

        <tr scope="row">
          <td scope="row" :colspan="calculateion_rule_data.length" class="text-right pr-4">
            {{ $t("payslips.label.salary_per_minute")}}
          </td>
          <td>
            {{ salaray_computing_other_amount.salary_per_minute }}
          </td>
        </tr>

        <tr scope="row">
          <td scope="row" :colspan="calculateion_rule_data.length" class="text-right pr-4">
            {{ $t("payslips.label.addition_amount")}}
          </td>
          <td>
            {{ salaray_computing_other_amount.other_addition_amount }}

            <div v-if="salaray_computing_other_amount.earned_leave_remark">
              {{ salaray_computing_other_amount.earned_leave_remark }}
            </div>
          </td>
        </tr>

        <tr scope="row">
          <td scope="row" :colspan="calculateion_rule_data.length" class="text-right pr-4">
           {{ $t("payslips.label.deduction_amount")}}
          </td>
          <td>
            {{ salaray_computing_other_amount.deduction_amount + salaray_computing_other_amount.action_amount }}
          </td>
        </tr>
        <tr scope="row">
          <td scope="row" :colspan="calculateion_rule_data.length" class="text-right pr-4">
            {{ $t("payslips.label.total_late_early_minutes")}}
          </td>
          <td>
             {{ salaray_computing_other_amount.total_late_early_minutes }}
          </td>
        </tr>
        
        <tr scope="row">
          <td scope="row" :colspan="calculateion_rule_data.length" class="text-right pr-4">
            {{ $t("payslips.label.total_late_early_penalty")}}
          </td>
          <td>
             {{ salaray_computing_other_amount.total_late_early_penalty }}
          </td>
        </tr>
        <tr scope="row">
          <td scope="row" :colspan="calculateion_rule_data.length" class="text-right pr-4">
            {{ $t("payslips.label.ssb")}}
          </td>
          <td>
            {{ salaray_computing_other_amount.ssb }}
          </td>
        </tr>
        <tr scope="row">
          <td scope="row" :colspan="calculateion_rule_data.length" class="text-right pr-4">
            {{ $t("payslips.label.tax")}}
          </td>
          <td>
             {{ salaray_computing_other_amount.tax }}
          </td>
        </tr>
        <tr scope="row">
          <td scope="row" :colspan="calculateion_rule_data.length" class="text-right pr-4">
            {{ $t("payslips.label.gross_pay")}}
          </td>
          <td>
             {{ salaray_computing_other_amount.gross_pay }}
          </td>
        </tr>
        <tr scope="row">
          <td scope="row" :colspan="calculateion_rule_data.length" class="text-right pr-4">
            {{ $t("payslips.label.net_pay")}}
          </td>
          <td>
             {{ salaray_computing_other_amount.net_pay }}
          </td>
        </tr>

      </mdb-tbl-body>
    </mdb-tbl>
    <!-- {{ calculateion_rule_data }} -->
  </div>
</template>

<script>
import { mdbTbl, mdbTblHead, mdbTblBody } from "mdbvue";
export default {
  name: "EmployeeAttendanceTable",
  components: { mdbTbl, mdbTblHead, mdbTblBody },
  props: [
    "salary_computing_rule",
    "total_salary_computing_rule",
    "salaray_computing_other_amount",
    "payroll_date"
  ],
  data() {
    return {
      data_ordering: [
        "rule",
        "position",
        "department",
        "effective_date",
        "working_days",
        "basic_salary",
        "daily_rate",
        "salary_calculation_days",
        "working_days",
        "attendance_days",
        "overtime_duration",
        "leave_days",
        "unpaid_leave_days",
        "absent_days",
        "actual_basic_salary",
        "overtime_amount",
        "unpaid_leave_absent_amount"
      ],
      calculateion_rule_data: [],
    };
  },
  created() {
    this.calculateion_rule_data = [];
    this.calculateion_rule_data = this.salary_computing_rule;
    this.total_salary_computing_rule.rule="Total";
    this.calculateion_rule_data.push(this.total_salary_computing_rule);
  },
};
</script>
