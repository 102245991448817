import Api from './api'

export default{
    list(payload){
      return Api.post('api/employees/time_table/filter', payload)
    },

    update(payload){
            return Api.post('api/employees/time_table/edit', payload)        
    },
    
    delete(payload){
        return Api.post('api/employees/time_table/delete', payload)        
    },
    
    view(payload){
        return Api.get('api/employees/time_table/' + payload)        
    },

}