<template>
  <div v-if="logs.length > 0">
    <div class="activity_header">Activity Log</div>
    <mdb-card>
      <mdb-card-body>
        <mdb-row v-for="(log, index) in logs" :key="index">
          <mdb-col>
            <mdb-media>
              <mdb-media-image
                circle
                src="https://mdbootstrap.com/img/Photos/Others/placeholder1.jpg"
                class="d-flex mr-3 log-avatar circle_img_width"
                alt=""                
              />
              <mdb-media-body>
                <p class="header_name mt-2">
                  {{ log.log_status | capitalizeFirstLetter }} by
                  {{ log.log_created_name | capitalizeFirstLetter }} <br />
                  <small>{{ log.log_created }}</small>
                </p>
                <div v-if="log.log_text">
                  <mdb-tbl  borderless sm>
                    <mdb-tbl-body>
                       
                      
                      <ListItem v-for="(change_data, index) in log.log_text" :key="index" :dataItem="change_data" @showModal="showPopupModal"/>
                     
                    </mdb-tbl-body>
                  </mdb-tbl>
                </div>
              </mdb-media-body>
            </mdb-media>
            <hr v-show="index < logs.length - 1" />
          </mdb-col>
        </mdb-row>
      </mdb-card-body>
    </mdb-card>

    <mdb-modal :show="ytmodal" @close="ytmodal = false" size="lg">
      <mdb-modal-body class="p-0" style="text-align:center;">

        <!-- <div class="embed-responsive embed-responsive-16by9 z-depth-1-half"> -->
          <!-- <iframe
            class="embed-responsive-item mx-auto"
            :src="modalSrc"
          ></iframe> -->

          <img :src="modalSrc" class="img-fluid" alt="Responsive image"  @error="imageLoadError">

        <!-- </div> -->
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn
          outline="primary"
          rounded
          size="md"
          @click.native="ytmodal = false"
          class="ml-4"
          >close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import ListItem from "@/components/logs/ListItem"
import noImageAvailable from '@/assets/no-image-available.jpg'
import {
  mdbCard,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbMedia,
  mdbMediaBody,
  mdbMediaImage,
  mdbTbl,
  mdbTblBody,
  // mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalFooter
} from "mdbvue";

export default {
  name: "log-list",
  props: ["logs"],
  components: {
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbMedia,
    mdbMediaBody,
    mdbMediaImage,
    mdbTbl,
    mdbTblBody,
    // mdbIcon,
mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalFooter,
    ListItem
  },

  data() {
    return {
      ytmodal: false,
      modalSrc: "",
    };
  },

  methods: {
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    showPopupModal(srcval) {
      this.modalSrc = srcval;
      this.ytmodal = true;
    },
    imageLoadError(){

      this.modalSrc = noImageAvailable;
    }
  },
  computed: {
    hasType(obj) {
      return this.containsKey(obj, "type");
    },
  },
};
</script>
<style scoped>
.activity_header{
  font-size: 14px !important;
  margin: 10px 0;
  color: #8590A2;
}
.media .log-avatar {
  width: 60px;
}
table.table-sm td,
table.table-sm th {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
table.table-borderless {
  margin-bottom: 0 !important;
}
hr {
  margin: 1.5em 0;
}
</style>