<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">
        <mdb-card>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th>
                    {{ $t("payslips.label.date") }}
                  </th>
                  <th>
                    {{ $t("payslips.label.attendance") }}
                  </th>
                  <th>
                    {{ $t("payslips.label.leaves") }}
                  </th>
                  <th>
                    {{ $t("payslips.label.absent") }}
                  </th>
                  <th>
                    {{ $t("payslips.label.overtime") }}
                  </th>
                </tr>
              </mdb-tbl-head>
              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(payslip, index) in payslips"
                  :key="index"
                >
                  <td scope="row" @click="rowClickEvent(payslip.id)">
                    {{ payslip.date | formatMonthYearString }}
                  </td>
                  <td @click="rowClickEvent(payslip.id)">
                    {{ payslip.attendance }}
                  </td>
                  <td @click="rowClickEvent(payslip.id)">
                    {{ payslip.leaves }}
                  </td>
                  <td @click="rowClickEvent(payslip.id)">
                    {{ payslip.absent }}
                  </td>
                  <td @click="rowClickEvent(payslip.id)">
                    {{ payslip.overtime }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </mdb-card-body>
        </mdb-card>
      </div>
    </template>
  </div>
</template>

<script>
import Payroll from "@/apis/Payroll";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  // mdbRow,
  // mdbCol,
  mdbSpinner,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    // mdbRow,
    // mdbCol,
    mdbSpinner,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },
  name: "EmployeePayslipList",

  data() {
    return {
      payslips: [],
      feedback_error: false,
      feedback_message: "",
    };
  },
  props: ["employee_id"],
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    rowClickEvent(id) {
      this.$router.push({
        name: "employeePayslipDetail",
        params: {employee_id:this.employee_id ,_id: id },
      });
    },

    fetchRecordList() {
      this.updateLoading(true);
      let params = {
        employee_id: this.employee_id,
      };

      Payroll.getPayslips(params)
        .then((response) => {
          if (response.status === 200) {
            this.payslips = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    if (!this.employee_id || Math.sign(this.employee_id) !== 1) {
      this.$router.push({ name: "listEmployee" });
    } else {
      this.fetchRecordList();
    }
  },
};
</script>
