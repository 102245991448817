<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row class="mb-5">
                <mdb-col offsetMd="2" md="8">
                  <label class="form-input-label">{{
                    $t("approval_workflow.formInput.apply_on")
                  }}</label>

                  <mdb-form-inline>
                    <mdb-input
                      type="checkbox"
                      id="apply_rule_on-attendance"
                      value="attendance"
                      v-model="apply_rule_on_attendance"
                      label="Attendance"
                    />

                    <mdb-input
                      type="checkbox"
                      id="apply_rule_on-leave"
                      value="leave"
                      v-model="apply_rule_on_leave"
                      label="Leave"
                    />

                    <mdb-input
                      type="checkbox"
                      id="apply_rule_on-overtime"
                      value="overtime"
                      v-model="apply_rule_on_overtime"
                      label="Overtime"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>

              <mdb-row class="mb-5">
                <mdb-col offsetMd="2" md="8">
                  <label class="form-input-label">{{
                    $t("approval_workflow.formInput.auto_approve")
                  }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="auto_approve_yes"
                      name="auto_approve"
                      radioValue="yes"
                      v-model="approval_workflow.auto_approval"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="auto_approve_no"
                      name="auto_approve"
                      radioValue="no"
                      v-model="approval_workflow.auto_approval"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>

              <mdb-row class="mb-5">
                <mdb-col offsetMd="2" md="8">
                  <label class="form-input-label">{{
                    $t("approval_workflow.formInput.reset_approvals")
                  }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="delete_previous_request_yes"
                      name="delete_previous_request"
                      radioValue="yes"
                      v-model="approval_workflow.delete_previous_request"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="delete_previous_request_no"
                      name="delete_previous_request"
                      radioValue="no"
                      v-model="approval_workflow.delete_previous_request"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>
              <hr />
              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <div class="float-left mt-3 sec_title">Request From</div>
                  <mdb-btn
                    type="button"
                    outline="primary"
                    class="float-right btn_outline_style"
                    icon="user-friends"
                    @click.native="modalOpen('request_from')"
                  >
                    {{ $t("approval_workflow.button.select_employee") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>

              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <div v-if="request_from_tab.length > 0">
                    <mdb-row>
                      <mdb-col
                        class="mb-3"
                        xl="6"
                        col="12"
                        v-for="(request_from_item, counter) in request_from_tab"
                        v-bind:key="counter"
                        waves
                      >
                        <div class="approval_info_box">
                          <EmployeeInfoBoxLayout
                            :user="request_from_item"
                            :showDelete="true"
                            @deleteEmployee="
                              deleteFromEmployee(request_from_item)
                            "
                          />
                        </div>
                      </mdb-col>
                    </mdb-row>
                  </div>
                </mdb-col>
              </mdb-row>

              <hr />

              <div v-show="approval_workflow.auto_approval == 'no'">
                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <mdb-btn
                      type="button"
                      color="uno-color"
                      class="btn_light_blue"
                      icon="file-upload"
                      size="md"
                      @click="addNewRequestTo"
                    >
                      {{ $t("approval_workflow.button.add_requst_to_step") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>
                <!-- {{ request_to_tabs }} -->
                <mdb-row
                  v-for="(request_to_item, counter) in request_to_tabs"
                  v-bind:key="counter"
                >
                  <mdb-col
                    offsetMd="2"
                    md="8"
                    class="request_to_topborder mb-3"
                  >
                    <div class="mt-3">
                      <div class="float-left mt-3 sec_title">Request To</div>
                      <div class="float-right">
                        <mdb-btn
                          type="button"
                          class="btn_delete"
                          color="uno-color"
                          @click="deleteRequestTo(counter)"
                          :disabled="shouldDisabledRequestToButton"
                        >
                          <mdb-icon icon="trash-alt" size="md" />
                          {{ $t("general.button.delete") }}
                        </mdb-btn>
                      </div>
                    </div>
                  </mdb-col>

                  <mdb-col offsetMd="2" md="8">
                    <label class="form-input-label"> Selection Type </label>
                    <mdb-form-inline>
                      <mdb-input
                        type="radio"
                        :id="'selection_type_individual' + counter"
                        name="selection_type"
                        radioValue="individual"
                        v-model="request_to_tabs[counter].type"
                        label="Employees"
                      />
                      <mdb-input
                        type="radio"
                        :id="'selection_type_group' + counter"
                        name="selection_type"
                        radioValue="group"
                        v-model="request_to_tabs[counter].type"
                        label="Approval Group"
                      />
                    </mdb-form-inline>
                  </mdb-col>

                  <mdb-col offsetMd="2" md="8">
                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="btn_outline_style"
                      icon="user-friends"
                      @click.native="modalOpen('request_to_' + counter)"
                      v-show="request_to_tabs[counter].type == 'individual'"
                    >
                      {{ $t("approval_workflow.button.select_employee") }}
                    </mdb-btn>

                    <ApprovalGroupDropdown
                      @updateApprovalGroupID="setApprovalGroupID"
                      :required="true"
                      :counter="counter"
                      v-if="!isLoading"
                      v-show="request_to_tabs[counter].type == 'group'"
                      :key="counter + temp_del_counter"
                      :default_selected_value="
                        request_to_tabs[counter].ids.length > 0
                          ? request_to_tabs[counter].ids[0]
                          : ''
                      "
                    />
                  </mdb-col>

                  <mdb-col
                    offsetMd="2"
                    md="8"
                    v-if="
                      request_to_tabs[counter].ids.length > 0 &&
                      request_to_tabs[counter].type == 'individual'
                    "
                  >
                    <mdb-row>
                      <mdb-col
                        class="mb-3"
                        xl="6"
                        col="12"
                        v-for="(request_to_item, counter2) in request_to_tabs[
                          counter
                        ].ids"
                        v-bind:key="counter2"
                        waves
                      >
                        <div class="approval_info_box">
                          <EmployeeInfoBoxLayout
                            :user="request_to_item"
                            :showDelete="true"
                            @deleteEmployee="
                              deletetoEmployee(request_to_item, counter)
                            "
                          />
                        </div>
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>
              </div>
              <hr />
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>

    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapCheckboxTable
          @updateSelectedEmployeeIDs="setSelectedEmployeeIDs"
          :default_selected_value="source_default_selected_value"
          :source_params="source_params"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import ApprovalWorkflow from "@/apis/ApprovalWorkflow";
import EmployeeListBoostrapCheckboxTable from "@/components/EmployeeListBoostrapCheckboxTable";

import ApprovalGroupDropdown from "@/components/ApprovalGroupDropdown";
import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFormInline,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    mdbFormInline,

    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeListBoostrapCheckboxTable,
    EmployeeInfoBoxLayout,
    ApprovalGroupDropdown,
  },
  data() {
    return {
      approval_workflow: {
        request_from: [],
        request_to: [],
        apply_rule_on: [],
        auto_approval: "no",
        delete_previous_request: "no",
      },

      apply_rule_on_attendance: false,
      apply_rule_on_leave: false,
      apply_rule_on_overtime: false,
      request_from_tab: [],
      request_to_tabs: [{ ids: [], allow_to_edit: false, type: "individual" }],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      modal: false,
      source_default_selected_value: "",
      source_params: "",

      temp_del_counter: 0,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    shouldDisabledRequestToButton() {
      return this.request_to_tabs.length > 1 ? false : true;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    addNewRequestTo() {
      this.request_to_tabs.push({
        ids: [],
        allow_to_edit: false,
        type: "individual",
      });
    },
    deleteRequestTo(counter) {
      this.request_to_tabs.splice(counter, 1);
      this.temp_del_counter++;
    },
    setApprovalGroupID(val) {
      // this.request_to_tabs.hr_ea_type_id = val;

      this.request_to_tabs[val.counter].ids = [val.value];
      // this.request_to_tabs[val.counter].type = "group";
    },
    deleteFromEmployee(emp) {
      this.request_from_tab = this.request_from_tab.filter((val) => {
        return val !== emp;
      });
    },

    deletetoEmployee(emp, counter) {
      this.request_to_tabs[counter].ids = this.request_to_tabs[
        counter
      ].ids.filter((val) => {
        return val !== emp;
      });
      this.request_from_tab = this.request_from_tab.filter((val) => {
        return val !== emp;
      });
    },

    modalOpen(param) {
      this.modal = true;
      this.source_params = param;

      if (param == "request_from") {
        this.source_default_selected_value = this.request_from_tab.map((e) => {
          return e.employee_id;
        });
      } else {
        this.source_default_selected_value = [];

        const tmp_index = param.substr(11);

        if (
          parseInt(tmp_index) >= 0 &&
          parseInt(tmp_index) < this.request_to_tabs.length
        ) {
          this.source_default_selected_value = this.request_to_tabs[
            param.substr(11)
          ].ids.map((e) => {
            return e.employee_id;
          });
        }
      }
    },

    setSelectedEmployeeIDs(val) {
      if (val[1] == "request_from") {
        this.request_from_tab = val[0];
      } else {
        // this.request_to_tabs[parseInt(val[1].substr(11))] = {
        //   ids: val[0],
        //   allow_to_edit: false,
        //   type:"individual"
        // };

        this.request_to_tabs[parseInt(val[1].substr(11))].ids = val[0];
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      // validation
      if (
        this.apply_rule_on_attendance == false &&
        this.apply_rule_on_leave == false &&
        this.apply_rule_on_overtime == false
      ) {
        this.feedback_message =
          "You must select at least one for Approval Workflow";
        this.feedback_error = true;
        this.formSubmitting = false;
      } else if (this.request_from_tab.length == 0) {
        this.feedback_message =
          "You must select at least one Employee in request from for Approval Workflow";
        this.feedback_error = true;
        this.formSubmitting = false;
      } else {
        if (this.approval_workflow.auto_approval == "no") {
          for (let value of this.request_to_tabs) {
            if (value.ids.length == 0) {
              this.feedback_message =
                "You must select at least one Employee or Approval Group in request to for Approval Workflow";
              this.feedback_error = true;
              this.formSubmitting = false;
              break;
            }
          }
        }
      }

      if (this.formSubmitting == true) {
        if (this.apply_rule_on_attendance == true) {
          this.approval_workflow.apply_rule_on.push("attendance");
        }
        if (this.apply_rule_on_leave == true) {
          this.approval_workflow.apply_rule_on.push("leave");
        }
        if (this.apply_rule_on_overtime == true) {
          this.approval_workflow.apply_rule_on.push("overtime");
        }

        this.approval_workflow.request_from = this.request_from_tab.map((e) => {
          return e.employee_id;
        });

        // if (this.approval_workflow.auto_approval == "yes") {
        //   delete this.approval_workflow.request_to;
        // } else {
        for (let value of this.request_to_tabs) {
          let tmp_ids = [];

          if (value.type == "individual") {
            tmp_ids = value.ids.map((e) => {
              return e.employee_id;
            });
          } else {
            tmp_ids = value.ids;
          }

          this.approval_workflow.request_to.push({
            ids: tmp_ids,
            allow_to_edit: value.allow_to_edit,
            type:value.type
          });
        }
        // }

        ApprovalWorkflow.save(this.approval_workflow).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.formSubmitting = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },

    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      this.approval_workflow = {
        request_from: [],
        request_to: [],
        apply_rule_on: [],
        auto_approval: "no",
        delete_previous_request: "no",
      };

      this.apply_rule_on_attendance = false;
      this.apply_rule_on_leave = false;
      this.apply_rule_on_overtime = false;
      this.request_from_tab = [];
      this.request_to_tabs = [{ ids: [], allow_to_edit: false }];
      this.source_default_selected_value = "";
      this.source_params = "";
    },
  },
};
</script>