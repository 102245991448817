import Api from './api'

export default{
    all(payload){
            return Api.get('api/announcements/list' ,{ params: payload})
    },

    save(payload){
            return Api.post('api/announcements/data_entry', payload)        
    },
    
    delete(payload){
        return Api.post('api/announcements/delete', payload)        
    },
    
    view(payload){
        return Api.get('api/announcements/edit/' + payload)        
    },

    deleteAttachment(payload){
        return Api.post('api/announcements/delete_file', payload)        
    },
    
    
    
    viewSummary(payload){
        return Api.post('api/announcements/' + payload)        
    },
    
    viewDetail(payload){
        return Api.post('api/announcements/employees' , payload)        
    },
    
}