import Api from './api'

export default{
    all(){
            return Api.get('api/settings/iso/list')
    },

    save(payload){
            return Api.post('api/settings/iso/data_entry', payload)        
    },
    
    delete(payload){
        return Api.post('api/settings/iso/delete', payload)        
    },
    
    view(payload){
        return Api.get('api/settings/iso/' + payload)        
    },

    
    deleteAttachment(payload){
        return Api.post('api/settings/iso/delete_file', payload)        
    },


    
}