<template>
  <div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div
      style="display: flex; align-items: center; justify-content: space-between"
    ></div>

    <div
      v-if="showDashboard == false"
      style="margin-0 auto; text-align:center;"
    >
      <img
        src="@/assets/dashboard.png"
        alt="Dashboard"
        style="max-width: 800px"
      />
    </div>
    <div v-if="!isLoading && showDashboard == true">
      <mdb-row>
        <mdb-col md="8" v-if="late_early_chart_data">
          <mdb-card>
            <mdb-card-header>
              <div class="float-left" style="padding: 12px 0">
                Late Ayalysis
              </div>
              <!-- 
              <div class="float-right">
                <mdb-btn
                  color="uno-color"
                  class="btn_submit"
                  @click="viewLateAnalysisDetail"
                  size="sm"
                >
                  <mdb-icon far icon="eye" size="md" />
                  Filter
                </mdb-btn>
              </div> -->
            </mdb-card-header>
            <mdb-card-body>
              <!-- {{late_early_chart_data}} -->
              <VerticalBarChartView2
                v-if="late_early_chart_data"
                :barGraphData="late_early_chart_data"
                :key="'2'"
                :xlabel="'Departments'"
                :ylabel="'Late Minutes'"
              />
            </mdb-card-body>
          </mdb-card>
        </mdb-col>

        <mdb-col md="4" v-if="attendanceCount">
          <mdb-card>
            <mdb-card-header> Attendance Analysis </mdb-card-header>
            <mdb-card-body>
              <PieChartView
                v-if="attendanceCount"
                :attendanceData="attendanceCount"
              />
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>

      <mdb-row>
        <mdb-col md="4" class="mt-4">
          <mdb-card>
            <mdb-card-body>
              <mdb-row>
                <mdb-col xs="8">
                  <p class="font-small grey-text mb-1">Total Employees</p>
                  <h2 class="ml-1 my-2 employee_count_box">
                    {{ total_active_employee }}
                  </h2>
                </mdb-col>
                <mdb-col xs="4" class="text-right"
                  ><mdb-icon
                    far
                    icon="chart-bar"
                    size="5x"
                    class="cyan-text pr-2"
                /></mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col md="4" class="mt-4">
          <mdb-card>
            <mdb-card-body>
              <mdb-row>
                <mdb-col xs="8">
                  <p class="font-small grey-text mb-1">Male</p>
                  <h2 class="ml-1 my-2 employee_count_box">{{ total_male }}</h2>
                </mdb-col>
                <mdb-col xs="4" class="text-right"
                  ><mdb-icon
                    far
                    icon="chart-bar"
                    size="5x"
                    class="cyan-text pr-2"
                /></mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col md="4" class="mt-4">
          <mdb-card>
            <mdb-card-body>
              <mdb-row>
                <mdb-col xs="8">
                  <p class="font-small grey-text mb-1">Female</p>
                  <h2 class="ml-1 my-2 employee_count_box">
                    {{ total_female }}
                  </h2>
                </mdb-col>
                <mdb-col xs="4" class="text-right"
                  ><mdb-icon
                    far
                    icon="chart-bar"
                    size="5x"
                    class="cyan-text pr-2"
                /></mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>

      <mdb-row class="mt-4">
        <mdb-col md="6">
          <template>
            <mdb-card>
              <mdb-card-header> Latest Announcements </mdb-card-header>
              <mdb-card-body>
                <mdb-tbl responsive hover>
                  <!-- Table body -->
                  <mdb-tbl-body v-if="announcements.length > 0">
                    <tr
                      scope="row"
                      v-for="(list_item, index) in announcements"
                      :key="index"
                    >
                      <td
                        scope="row"
                        @click="rowClickAnnouncementEvent(list_item)"
                        :class="index == 0 ? 'remove-border-top' : ''"
                      >
                        {{ list_item.title }}
                      </td>
                      <td
                        algin="right"
                        class="text-right"
                        :class="index == 0 ? 'remove-border-top' : ''"
                        width="80"
                      >
                        {{ list_item.time_ago }}
                      </td>
                    </tr>
                  </mdb-tbl-body>

                  <mdb-tbl-body v-else>
                    <tr scope="row">
                      <td scope="row">There is no announcement to display</td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </mdb-card-body>
            </mdb-card>
          </template>
        </mdb-col>

        <mdb-col md="6">
          <mdb-card>
            <mdb-card-header> Upcoming Holidays </mdb-card-header>

            <mdb-card-body>
              <mdb-tbl hover responsive>
                <!-- Table body -->
                <mdb-tbl-body v-if="holidays.length > 0">
                  <tr
                    scope="row"
                    v-for="(holiday, index) in holidays"
                    :key="index"
                  >
                    <td :class="index == 0 ? 'remove-border-top' : ''">
                      {{ holiday.holiday_name }}
                    </td>
                    <td
                      @click="rowClickEvent(holiday.holiday_id)"
                      class="text-right"
                      :class="index == 0 ? 'remove-border-top' : ''"
                    >
                      <template
                        v-if="
                          holiday.start_date != holiday.end_date
                        " 
                      >
                        {{ holiday.start_date | formatDateString }}
                        -
                        {{ holiday.end_date | formatDateString }}
                      </template>
                      <template v-else>
                        {{ holiday.start_date | formatDateString }}
                      </template>
                    </td>
                  </tr>
                </mdb-tbl-body>

                <mdb-tbl-body v-else>
                  <tr scope="row">
                    <td scope="row">
                      There is no upcoming holidays to display
                    </td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>

      <mdb-row class="mt-4" v-if="leaves_chart_data">
        <mdb-col md="12">
          <mdb-card>
            <mdb-card-header>
              <div class="float-left" style="padding: 12px 0">
                Leave Ayalysis
              </div>
              <!-- 
              <div class="float-right">
                <mdb-btn
                  color="uno-color"
                  class="btn_submit"
                  @click="viewLeaveAnalysisDetail"
                  size="sm"
                >
                  <mdb-icon far icon="eye" size="md" />
                  View Detail
                </mdb-btn>
              </div> -->
            </mdb-card-header>
            <mdb-card-body>
              <!-- {{leaves_chart_data}} -->
              <VerticalBarChartView
                v-if="leaves_chart_data"
                :barGraphData="leaves_chart_data"
                :key="'1'"
                :xlabel="'Departments'"
                :ylabel="'No of Employees'"
              />
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </div>
    <ModalDashboardFilter
      @submitFilterForm="fetchAnalysisData"
      @closeFilterForm="modalVisible = false"
      :modalVisible="modalVisible"
      :filterFormData="filterFormData"
      :formSubmitting="!modalVisible"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Dashboard from "@/apis/Dashboard";

import VerticalBarChartView from "@/components/VerticalBarChartView";
import VerticalBarChartView2 from "@/components/VerticalBarChartView2";
import PieChartView from "@/components/PieChartView";
import ModalDashboardFilter from "@/components/ModalDashboardFilter";

import {
  mdbRow,
  mdbCol,
  mdbSpinner,
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbIcon,
  mdbTbl,
  mdbTblBody,
  // mdbBtn,
} from "mdbvue";

export default {
  name: "HelloWorld",
  components: {
    mdbRow,
    mdbCol,
    mdbSpinner,
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbIcon,
    mdbTbl,

    mdbTblBody,
    PieChartView,
    VerticalBarChartView,
    VerticalBarChartView2,
    // mdbBtn,
    ModalDashboardFilter,
  },
  data() {
    return {
      showDashboard: false,
      holidays: [],
      announcements: [],
      total_active_employee: 0,
      total_male: 0,
      total_female: 0,
      attendanceCount: false,
      late_early_chart_data: false,
      leaves_chart_data: false,
      marker: {
        lat: 16.870215,
        lng: 96.172859,
        label: "",
      },

      modalVisible: false,
      filter_type: "",
      filterFormData: {},
      latecomponentKey: 0,
      leavecomponentKey: 0,
      late_filter_form_data: {
        year: "",
        month: "",
      },
      leave_filter_form_data: {
        year: "",
        month: "",
      },
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
  methods: {
    ...mapActions(["updateLoading"]),

    viewLateAnalysisDetail() {
      this.modalVisible = true;
      this.filter_type = "late";
      this.filterFormData = this.late_filter_form_data;
    },

    viewLeaveAnalysisDetail() {
      this.modalVisible = true;
      this.filter_type = "leave";
      this.filterFormData = this.leave_filter_form_data;
    },

    fetchAnalysisData(val) {
      if (this.filter_type == "late") {
        this.late_filter_form_data = val;

        Dashboard.getLateEarlyChartData(this.late_filter_form_data)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.latecomponentKey++;

                this.late_early_chart_data = response.data.data;
                // this.updateLoading(false);
              }
            }
            this.modalVisible = false;
          })
          .catch((error) => {
            if (!error.response) {
              // network error
              this.feedback_message = "Could not connected to server";
              this.feedback_error = true;

              // this.updateLoading(false);
            } else {
              console.log(error);
              // this.updateLoading(false);
            }

            this.modalVisible = false;
          });
      } else {
        this.leave_filter_form_data = val;

        Dashboard.getLeaveChartData(this.leave_filter_form_data)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.leavecomponentKey++;
                this.leaves_chart_data = response.data.data;
                // this.updateLoading(false);
              }
            }
            this.modalVisible = false;
          })
          .catch((error) => {
            if (!error.response) {
              // network error
              this.feedback_message = "Could not connected to server";
              this.feedback_error = true;

              // this.updateLoading(false);
            } else {
              console.log(error);
              // this.updateLoading(false);
            }
            this.modalVisible = false;
          });
      }

      // console.log(val);
    },

    fetchEmployeeCount() {
      this.updateLoading(true);

      Dashboard.employeeCount()
        .then((response) => {
          if (response.status === 200) {
            this.total_active_employee =
              response.data.data.total_active_employee;
            this.total_male = response.data.data.total_male;
            this.total_female = response.data.data.total_female;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });

      Dashboard.attendanceCount()
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.attendanceCount = [
                response.data.data.today_present,
                response.data.data.today_leave,
                response.data.data.today_absent,
              ];
            }
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;
          } else {
            console.log(error);
          }
        });

      Dashboard.getLateEarlyChartData(this.late_filter_form_data)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.late_early_chart_data = response.data.data;
              this.late_early_chart_data.dataLabel = "Departments";
              // this.updateLoading(false);
            }
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            // this.updateLoading(false);
          } else {
            console.log(error);
            // this.updateLoading(false);
          }
        });

      Dashboard.getLeaveChartData(this.leave_filter_form_data)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.leaves_chart_data = response.data.data;

              this.late_early_chart_data.dataLabel = "Departments";
              // this.updateLoading(false);
            }
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            // this.updateLoading(false);
          } else {
            console.log(error);
            // this.updateLoading(false);
          }
        });

      Dashboard.getAnnouncements()
        .then((response) => {
          if (response.status === 200) {
            this.announcements = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });

      Dashboard.getUpcomingHolidays()
        .then((response) => {
          if (response.status === 200) {
            this.holidays = response.data.data;
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;
            this.updateLoading(false);
          } else {
            this.feedback_message = error;
            this.feedback_error = true;
            this.updateLoading(false);
          }
        });
    },
  },
  beforeMount() {},

  created() {
    if (localStorage.getItem("is_admin") == "1") {
      this.showDashboard = true;
    } else {
      if (
        localStorage.getItem("user_permission") &&
        localStorage.getItem("user_permission") != "null"
      ) {
        if (
          JSON.parse(localStorage.getItem("user_permission")).find(
            (r) => r == "dashboard"
          )
        ) {
          this.showDashboard = true;
        } else {
          this.showDashboard = false;
        }
      } else {
        this.showDashboard = false;
      }
    }

    if (this.showDashboard == true) {
      this.fetchEmployeeCount();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.employee_count_box {
  font-family: "poppin_regular";
  /* font-size: 34px; */
}
h3 {
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 30px;
}
p {
  color: #969696;
  margin-bottom: 0;
  font-size: 14px;
}
.remove-border-top {
  border: 0 none;
}
</style>
