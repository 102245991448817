<template>
  <section class="form-elegant">
    <mdb-row class="d-flex justify-content-center">
      <mdb-col md="6" lg="5">
        <mdb-card>
          <mdb-card-body class="mx-4">
            <div class="text-center">
              <mdb-card-avatar color="white" class="mx-auto"
                ><img src="/unohr.png" class="" height="100"
              /></mdb-card-avatar>
            </div>
            <!-- rounded-circle -->
            <div class="text-center">
              <h3 class="dark-grey-text my-4">
                <strong>Success</strong>
              </h3>
            </div>
            <br />

            <div class="text-center">
              <p class="dark-grey-text my-4">
                  Password Reset Complete.
              </p>

              <p class="dark-grey-text my-4">
                  You can login from UNO HR mobile application with updated password.
              </p>
            </div>
          </mdb-card-body>
          <mdb-modal-footer class="mx-5 pt-3 mb-1">
            <p class="font-small grey-text d-flex justify-content-end">
              Powered BY <a href="#" class="blue-text ml-1"> SBG</a>
            </p>
          </mdb-modal-footer>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import {
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardBody,
  mdbModalFooter,
  mdbCardAvatar
} from "mdbvue";

export default {
  name: "Login",
  components: {
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbModalFooter,
    mdbCardAvatar
  },
  data() {
    return {};
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
  methods: {},
};
</script>

<style>
.form-elegant .font-small {
  font-size: 0.8rem;
}

.form-elegant .z-depth-1a {
  -webkit-box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26),
    0 4px 12px 0 rgba(121, 155, 254, 0.25);
  box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26),
    0 4px 12px 0 rgba(121, 155, 254, 0.25);
}

.form-elegant .z-depth-1-half,
.form-elegant .btn:hover {
  -webkit-box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28),
    0 4px 15px 0 rgba(36, 133, 255, 0.15);
  box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28),
    0 4px 15px 0 rgba(36, 133, 255, 0.15);
}
</style>