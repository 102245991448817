<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-show="!isLoading">
        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn
                color="uno-color"
                class="btn_submit"
                @click="createNewRecord"
              >
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body id="department_table">
            <ejs-treeview
              id="treeview"
              :fields="fields"
              :nodeClicked="nodeClicked"
            ></ejs-treeview>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import Department from "@/apis/Department";
import { mapActions, mapGetters } from "vuex";

import { treeBuilderDropdown } from "@/utilities/treeBuilder";

import Vue from "vue";
import { TreeViewPlugin } from "@syncfusion/ej2-vue-navigations";
Vue.use(TreeViewPlugin);

import {
  // mdbRow,
  // mdbCol,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  // mdbTbl,
  // mdbTblHead,
  // mdbTblBody,
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
} from "mdbvue";

export default {
  components: {
    // mdbRow,
    // mdbCol,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    // mdbTbl,
    // mdbTblHead,
    // mdbTblBody,
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
  },

  data() {
    return {
      fields: null,
      departments: [],
      department_tree: [],
      currentSort: "department_name",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    rowClickEvent(id) {
      this.$router.push({ name: "viewDepartment", params: { _id: id } });
    },

    createNewRecord() {
      this.$router.push({ name: "createDepartment" });
    },

    fetchRecordList(payload = "") {
      this.updateLoading(true);

      Department.all(payload)
        .then((response) => {
          if (response.status === 200) {
            this.departments = response.data.data;

            this.fields = {
              dataSource: treeBuilderDropdown(this.departments, ""),
              id: "department_id",
              parentID: "department_parent_id",
              text: "department_name",
              hasChildren: "hasChild",
            };

            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },

    nodeClicked: function () {
      var treeObj = document.getElementById("treeview").ej2_instances[0];
      let selectedNodes = parseInt(treeObj.selectedNodes[0]);
      if (!isNaN(selectedNodes) ) {
        this.$router.push({
          name: "viewDepartment",
          params: { _id: selectedNodes },
        });
      }
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
<style >
#department_table .e-text-content{
  border-bottom: 1px solid #dcdcdc;
  padding: 10px 0;
}
.e-treeview .e-fullrow {
    height: 48px;
}
</style>