<template>
  <div>
    <mdb-card class="mb-5">
      <mdb-card-body>
        <!-- <mdb-row v-if="selectedEmployeeData" class="mb-5">
          <mdb-col offsetMd="2" md="8">
            <div class="approval_info_box">
              <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
            </div>
          </mdb-col>
        </mdb-row> -->

        <mdb-row>
          <mdb-col offsetMd="2" md="8">
            <dl class="row">
              <dt class="col-sm-3">Employee Info:</dt>
              <dd class="col-sm-9">
                <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
              </dd>

              <dt class="col-sm-3">Status:</dt>
              <dd class="col-sm-9">
                {{ data.status }}

                {{
                  data.leave_complete == "incomplete" && data.status != "reject"
                    ? "( Waiting Approvals )"
                    : ""
                }}

                {{
                  data.leave_complete == "complete"
                    ? "( Approval Process Complete )"
                    : ""
                }}
              </dd>

              <dt class="col-sm-3">Leave Type:</dt>
              <dd class="col-sm-9">
                {{ data.leave_type }}
              </dd>

              <dt class="col-sm-3">Date:</dt>
              <dd class="col-sm-9">
                {{
                  data.from_date == data.to_date
                    ? data.from_date
                    : data.from_date + " - " + data.to_date
                }}
              </dd>

              <dt class="col-sm-3">Duration:</dt>
              <dd class="col-sm-9">
                {{ data.total }}
                {{ data.duration_label }}
              </dd>

              <dt class="col-sm-3">Description:</dt>
              <dd class="col-sm-9">
                {{ data.description }}
              </dd>

              <template v-if="data.attachments.length > 0">
                <dt class="col-sm-3">Attachments:</dt>
                <dd class="col-sm-9">
                  <div
                    v-for="(attachment_item, index) in data.attachments"
                    :key="index"
                  >
                    <div class="float-left attachments_text_style">
                      <a :href="attachment_item.attachment_key_name" target="_blank">
                      <img
                        v-if="
                          attachment_item.file_ext == 'png' ||
                          attachment_item.file_ext == 'gif' ||
                          attachment_item.file_ext == 'jpg' ||
                          attachment_item.file_ext == 'jpeg'
                        "
                        :src="attachment_item.attachment_key_name"
                        height="100"
                        class="announcement_thumbnail_image"
                      />
                      </a>
                    </div>
                  </div>
                </dd>
              </template>

              <dt class="col-sm-3">Apply At:</dt>
              <dd class="col-sm-9">
                {{ data.apply_at }}
              </dd>

              <dt class="col-sm-3">Leave Taken:</dt>
              <dd class="col-sm-9">
                <vc-calendar
                  class="custom-calendar max-w-full"
                  :masks="masks"
                  :attributes="attributes"
                  disable-page-swipe
                  is-expanded
                >
                  <template v-slot:day-content="{ day, attributes }">
                    <div class="flex flex-col h-full z-10 overflow-hidden">
                      <span class="day-label text-sm text-gray-900">{{
                        day.day
                      }}</span>
                      <div class="flex-grow overflow-y-auto overflow-x-auto">
                        <p
                          v-for="attr in attributes"
                          :key="attr.key"
                          class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                          :class="attr.customData.class"
                        >
                          {{ attr.customData.title }}
                        </p>
                      </div>
                    </div>
                  </template>
                </vc-calendar>
              </dd>
            </dl>
          </mdb-col>
        </mdb-row>

        <mdb-row v-if="data.approval_users.length > 0">
          <mdb-col offsetMd="2" md="8">
            <h4>Approved By</h4>
            <div
              v-for="(user, index) in data.approval_users"
              :key="index"
              class="mb-3"
            >
              <ApprovalApprovedUserLayout
                :user="{
                  epinfo_name: user.name,
                  jp_name: user.position,
                  department_name: user.department,
                  profile_image: user.image,
                  date: user.date,
                  description: user.description,
                  approval_status: user.approval_status,
                }"
              />
            </div>
          </mdb-col>
        </mdb-row>

        <mdb-row>
          <mdb-col offsetMd="2" md="8">
            <mdb-btn
              type="button"
              color="uno-color"
              class="btn_submit"
              @click="backAction()"
            >
              <mdb-icon icon="file-upload" size="md" />

              {{ $t("general.button.go_back") }}
            </mdb-btn>
            <!-- 
            <mdb-btn
              type="button"
              color="uno-color"
              class="btn_submit"
              @click="editAction()"
            >
              <mdb-icon icon="file-upload" size="md" />

              {{ $t("general.button.edit") }}
            </mdb-btn> -->
          </mdb-col>
        </mdb-row>

        <mdb-row>
          <mdb-col offsetMd="2" md="8">
            <hr />

            <label class="form-label">
              {{ $t("approvals.formInput.approval_status") }}
            </label>
            <mdb-input
              type="radio"
              id="req-opt-approve"
              radioValue="approve"
              v-model="approval_status"
              :label="$t('approvals.formInput.approve')"
              :disabled="data.status == 'approve'"
            />
            <mdb-input
              type="radio"
              id="req-opt-reject"
              radioValue="reject"
              v-model="approval_status"
              :label="$t('approvals.formInput.reject')"
              :disabled="data.status == 'reject'"
            />

            <mdb-input
              type="textarea"
              :label="$t('approvals.formInput.description')"
              v-model="approval_description"
              outline
              v-if="approval_status == 'reject'"
            />

            <mdb-btn
              type="Submit"
              color="uno-color"
              class="btn_submit"
              :disabled="showDisplaySubmit"
              @click="approvalAction"
            >
              <mdb-icon icon="file-upload" size="md" v-show="!formSubmitting" />

              <span
                class="spinner-border-vcenter spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="formSubmitting"
              ></span>
              {{ $t("general.button.submit") }}
            </mdb-btn>
          </mdb-col>
        </mdb-row>
      </mdb-card-body>
    </mdb-card>
    <!-- {{ data }} -->
  </div>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbInput,
} from "mdbvue";
import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import Leaves from "@/apis/Leaves";
import ApprovalApprovedUserLayout from "@/components/ApprovalApprovedUserLayout";



export default {
  name: "LeaveViewDetail",
  components: {
    mdbCard,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    EmployeeInfoBoxLayout,
    ApprovalApprovedUserLayout,
    mdbInput,
  },
  props: ["data"],
  data() {
    return {
      formSubmitting: false,
      selectedEmployeeData: {},

      approval_status: "",
      approval_description: "",

      ////////////////////////

      masks: {
        weekdays: "WWW",
      },
      attributes: [
        // {
        //   key: 1,
        //   customData: {
        //     title: "EL",
        //     class: "bg-warning text-white",
        //   },
        //   dates: new Date(year, month, 1),
        // },
      ],
      /////////////////////////
    };
  },
  computed: {
    showDisplaySubmit() {
      if (this.formSubmitting == true) {
        return true;
      } else if (this.approval_status == "") {
        return true;
      } else if (
        this.approval_status == "reject" &&
        this.approval_description == ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    backAction() {
      this.$emit("updateBackAction");
    },

    // editAction() {
    //   this.$emit("viewEditAction");
    // },

    approvalAction() {
      this.formSubmitting = true;

      Leaves.submitApproval({
        id: [this.data.id],
        status: this.approval_status,
        description: this.approval_description,
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            this.formSubmitting = false;
            this.$emit("refetchRecordAction");
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }
        }
      });
    },

    fetchRecord() {
      Leaves.getLeaveCalendarData({
        employee_id: this.data.employee_id,
        date: this.data.calendar_filter_date,
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              //
              for (let index = 0; index < response.data.data.length; ++index) {
                // console.log();


                
                this.attributes.push({
                  key: index,
                  customData: {
                    title: response.data.data[index]['code'],
                    class: "bg-warning text-white",
                  },
                  dates: new Date(response.data.data[index].date),
                });
              }
            }
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
    },
  },
  mounted() {
    this.fetchRecord();
  },
  created() {
    this.selectedEmployeeData = {
      epinfo_name: this.data.name,
      jp_name: this.data.position,
      department_name: this.data.department,
      profile_image: this.data.image,
    };
  },
};
</script>



<style scoped>
.announcement_thumbnail_image {
  border: 2px solid #c0c0c0;
  padding: 5px;
  margin-top: 10px;
  max-width: 150px;
  max-height: 100px;
}
.attachments_text_style{
  text-align: center;
  margin-right: 20px;
}
</style>

<style lang="postcss" scoped>
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>