<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading && !showDetail && !showEdit">
      <OvertimeApprovalSearchForm
        @submitFilterForm="handleSubmitFilterForm"
        :filterFormData="searchFormData"
      />

      <mdb-card>
        <mdb-card-header>
          <div class="float-left"></div>

          <div class="float-right" v-show="shouldApprovalButtonShow">
            <mdb-btn
              size="sm"
              color="uno-color"
              class="btn_submit"
              @click.native="approveModal=true"
              :disabled="approvalSubmitted"
              v-show="current_filtered_status != 'approve' "
            >
              <mdb-icon
                icon="trash-alt"
                far
                size="md"
                v-show="!approvalSubmitted"
              />

              <span
                class="spinner-border-vcenter spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="approvalSubmitted"
              ></span>
              Approve Selected
              <!-- {{ $t("general.button.delete_selected") }} -->
            </mdb-btn>

            <mdb-btn
              size="sm"
              color="danger"
              class="btn_submit"
              @click.native="rejectModal=true"
              :disabled="approvalSubmitted"
              v-show="current_filtered_status != 'reject' "
            >
              <mdb-icon
                icon="trash-alt"
                far
                size="md"
                v-show="!approvalSubmitted"
              />

              <span
                class="spinner-border-vcenter spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="approvalSubmitted"
              ></span>
              Cancel Selected
              <!-- {{ $t("general.button.delete_selected") }} -->
            </mdb-btn>
          </div>
        </mdb-card-header>

        <mdb-card-body>
          <mdb-tbl hover responsive>
            <!-- Table head -->
            <mdb-tbl-head>
              <tr>
                <th width="50">
                  <!-- Material unchecked -->
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="trmc_all"
                      @change="checkAllRecord"
                      v-model="isCheckAll"
                    />
                    <label class="form-check-label" for="trmc_all"></label>
                  </div>
                </th>
                <th></th>

                <th class="text-right">
                  {{ $t("general.formInput.date") }}
                </th>

                <th class="text-right">From - To</th>

                <th class="text-right">
                  {{ $t("general.formInput.status") }}
                </th>
              </tr>
            </mdb-tbl-head>
            <!-- Table head -->

            <!-- Table body -->
            <mdb-tbl-body>
              <tr
                scope="row"
                v-for="(record_item, index) in approval_record.data"
                :key="index"
              >
                <th scope="row">
                  <!-- Material unchecked -->
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      :id="'trmc_' + record_item.id"
                      :value="record_item.id"
                      v-model="selectedApprovalRecord"
                      @change="updateCheckall"
                    />
                    <label
                      class="form-check-label"
                      :for="'trmc_' + record_item.id"
                    ></label>
                  </div>
                </th>

                <td
                  class="align-middle"
                  align="left"
                  @click="rowClickEvent(record_item)"
                >
                  <EmployeeInfoTableCellLayout
                    :user="{
                      epinfo_name: record_item.name,
                      jp_name: record_item.position,
                      department_name: record_item.department,
                      profile_image: record_item.image,
                    }"
                  />
                </td>

                <td @click="rowClickEvent(record_item)" class="text-right">
                  {{ record_item.date }}
                </td>

                <td @click="rowClickEvent(record_item)" class="text-right">
                  {{ record_item.start_from }} - {{ record_item.end_at }}
                </td>

                <td @click="rowClickEvent(record_item)" class="text-right">
                  {{ record_item.status }}
                </td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>

          <pagination
            :data="approval_record"
            @pagination-change-page="fetchRecordList"
            :limit="2"
          ></pagination>
        </mdb-card-body>
      </mdb-card>
    </div>

    <template v-if="showDetail">
      <OvertimeViewDetail
        :data="detailData"
        @updateBackAction="hideDetailComponent"
        @refetchRecordAction="refetchRecord"
        @viewEditAction="showEditForm"
      />
    </template>

    <template v-if="showEdit">
      <OvertimeApprovalUpdateForm
        :data="detailData"
        @updateBackAction="hideEditForm"
        @updateSuccessAction="updateSuccessRecordItem"
      />
    </template>

    <mdb-modal size="md" :show="approveModal" @close="approveModal = false">
      <mdb-modal-header>
        <mdb-modal-title>Approval Confirmation</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        Are you sure to approve all selected Overtime Request
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="approveModal = false"
          >No</mdb-btn
        >
        <mdb-btn color="primary" @click="confirmApprovalAction('approve')" size="sm">Yes</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <mdb-modal size="md" :show="rejectModal" @close="rejectModal = false">
      <mdb-modal-header>
        <mdb-modal-title>Approval Confirmation</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        Are you sure to cancel all selected Overtime Request? If yes, Fill in
        description to cancel all selected request.

        <mdb-input
          type="textarea"
          :label="$t('general.formInput.remark')"
          v-model="approval_description"
          outline
          required
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="rejectModal = false"
          >No</mdb-btn
        >
        <mdb-btn color="primary" @click="confirmApprovalAction('reject')" size="sm">Yes</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import Vue from "vue";
Vue.component("pagination", require("laravel-vue-pagination"));
import OvertimeApprovalSearchForm from "@/components/search/OvertimeApprovalSearchForm";

import Overtime from "@/apis/Overtime";
import { mapActions, mapGetters } from "vuex";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";

import OvertimeViewDetail from "@/components/OvertimeViewDetail";
import OvertimeApprovalUpdateForm from "@/components/OvertimeApprovalUpdateForm";

import {
  //   mdbRow,
  //   mdbCol,
  mdbBtn,
    mdbInput,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

export default {
  components: {
    // mdbRow,
    // mdbCol,
    mdbBtn,
    mdbInput,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    OvertimeApprovalSearchForm,
    EmployeeInfoTableCellLayout,
    OvertimeViewDetail,
    OvertimeApprovalUpdateForm,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
  },

  data() {
    return {
      currentSort: "id",
      currentSortDir: "desc",
      feedback_error: false,
      feedback_message: "",
      approval_record: [],
      searchFormData: {
        keywords: "",
        date_from: "",
        date_to: "",
        status:"pending",
      },
      formSubmitting: false,
      isCheckAll: false,
      selectedApprovalRecord: [],
      deleteSubmitted: false,

      showDetail: false,
      showEdit: false,
      detailData: {},


      approveModal:false,
      rejectModal:false,
      approval_description:"",
      approvalSubmitted:false,

      current_filtered_status:"pending"
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    doesFormSubmitted() {
      return this.formSubmitting;
    },
    doesCheckAll() {
      return this.isCheckAll;
    },
    shouldApprovalButtonShow() {
      return this.selectedApprovalRecord.length > 0 ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    confirmApprovalAction(approval_status){

      this.approvalSubmitted = true;
      

      Overtime.submitApproval({
        id: this.selectedApprovalRecord,
        status: approval_status,
        description: this.approval_description,
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            this.approvalSubmitted = false;
            
            this.approveModal=false
            this.rejectModal=false

            this.fetchRecordList();
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }
          
            this.approvalSubmitted = false;
        }
      });

    },
    

    updateSuccessRecordItem(data) {
      this.detailData.date = data.date;
      this.detailData.start_from = data.start_from;
      this.detailData.end_at = data.end_at;
      this.detailData.description = data.description;

      this.hideEditForm();
    },

    showEditForm() {
      this.showDetail = false;
      this.showEdit = true;
    },

    hideEditForm() {
      this.showDetail = true;
      this.showEdit = false;
    },

    refetchRecord() {
      this.hideDetailComponent();
      this.fetchRecordList();
    },

    handleSubmitFilterForm(val) {
      this.searchFormData = val;
      this.fetchRecordList();
    },

    rowClickEvent(data) {
      this.showDetail = true;
      this.detailData = data;
      // this.$router.push({ name: "viewAttendanceApproval", params: { _id: id } });
    },

    hideDetailComponent() {
      this.showDetail = false;
      this.detailData = {};
    },

    checkAllRecord() {
      this.selectedApprovalRecord = [];
      if (this.isCheckAll) {
        for (let record_item of this.approval_record.data) {
          this.selectedApprovalRecord.push(record_item.id);
        }
      }
    },
    updateCheckall() {
      if (
        this.approval_record.data.length == this.selectedApprovalRecord.length
      ) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },

    getSelectValue(value) {
      this.filter_form.filter_year = value;
    },

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      const params = {
        source: "web",
        page: page,
        keywords: this.searchFormData.keywords,
        date_from: this.searchFormData.date_from,
        date_to: this.searchFormData.date_to,
        status: this.searchFormData.status,
      };

      this.current_filtered_status = this.searchFormData.status

      Overtime.getApprovalList(params)
        .then((response) => {
          if (response.status === 200) {
            this.approval_record = response.data.data;

            this.updateLoading(false);
            this.formSubmitting = false;
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
            this.formSubmitting = false;
          } else {
            this.feedback_message = error;
            this.feedback_error = true;

            this.updateLoading(false);
            this.formSubmitting = false;
          }
        });

      this.selectedApprovalRecord = [];
      this.isCheckAll = false;
    },
    handleFilterSubmit() {
      this.formSubmitting = true;
      this.fetchRecordList();
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>