<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-show="!isLoading">

        <EmployeeLeaveSearchForm @submitFilterForm="handleSubmitFilterForm" :filterFormData="searchFormData"/>


        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn color="uno-color" class="btn_submit" @click="createNewRecord" size="sm">
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>

            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th>
                    {{ $t("general.formInput.leave_type") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.from_date") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.to_date") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.no_of_days") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.status") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(leave_item, index) in employee_leaves"
                  :key="index"
                >
                  <td scope="row" @click="rowClickEvent(leave_item.el_id)">
                    {{ leave_item.leave_type_name }}
                  </td>
                  <td @click="rowClickEvent(leave_item.el_id)">
                    {{ leave_item.el_leave_from  | formatDateString}}
                  </td>
                  <td @click="rowClickEvent(leave_item.el_id)">
                    {{ leave_item.el_leave_to  | formatDateString}}
                  </td>
                  <td @click="rowClickEvent(leave_item.el_id)">
                    {{ leave_item.el_total_leaves }}
                  </td>
                  <td @click="rowClickEvent(leave_item.el_id)">
                    <template v-if="leave_item.el_request_by == 'behalf'">
                      Approved
                    </template>
                    <template
                      v-else-if="
                        leave_item.el_request_by != 'behalf' &&
                        leave_item.el_leave_status == 'approve' &&
                        leave_item.el_complete == '0'
                      "
                    >
                      Pending Approval
                    </template>
                    <template v-else>
                      {{ leave_item.el_leave_status }}
                    </template>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import Leaves from "@/apis/Leaves";
import { mapActions, mapGetters } from "vuex";

import EmployeeLeaveSearchForm from "@/components/search/EmployeeLeaveSearchForm";

import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    EmployeeLeaveSearchForm
  },
  name: "EmployeeLeaveList",

  data() {
    return {
      searchFormData: {
        filter_start_date:"",
        filter_end_date:"",
        status:""
      },
      employee_leaves: [],
      currentSort: "el_leave_from",
      currentSortDir: "desc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  props: ["employee_id"],
  computed: {
    ...mapGetters(["isLoading"]),

    sortedDocumentTypeList() {
      return this.action_types.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    rowClickEvent(id) {
      this.$router.push({
        name: "viewEmployeeLeave",
        params: { _id: id },
      });
    },

    handleSubmitFilterForm(val){
      this.searchFormData = val
      this.fetchRecordList({ employee_id: this.employee_id })
    },

    createNewRecord() {
      this.$router.push({ name: "createEmployeeLeave" });
    },

    fetchRecordList(payload = "") {
      this.updateLoading(true);

      if (payload.employee_id != undefined) {
        if (Math.sign(payload.employee_id) === 1) {

          const params = {
            employee_id: payload.employee_id,
            filter_start_date:this.searchFormData.filter_start_date,
            filter_end_date:this.searchFormData.filter_end_date,
            status:this.searchFormData.status
      
          };

          Leaves.all(params)
            .then((response) => {
              if (response.status === 200) {
                this.employee_leaves = response.data.data;
                this.updateLoading(false);
              }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.feedback_message = "Could not connected to server";
                this.feedback_error = true;

                this.updateLoading(false);
              } else {
                console.log(error);
                this.updateLoading(false);
              }
            });
        }
      }
    },
  },
  created() {

    if(this.searchFormData.filter_start_date=="" && this.searchFormData.filter_end_date==""){
      this.searchFormData.filter_start_date=parseInt(new Date().getFullYear())+"-01-01";
      this.searchFormData.filter_end_date=parseInt(new Date().getFullYear())+"-12-31";
    }
    this.fetchRecordList({ employee_id: this.employee_id });
    
  },
};
</script>
