<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-show="!isLoading">
        <mdb-card>

          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th>
                    {{ $t("general.formInput.leave_type") }}
                  </th>
                  <!-- <th>
                    {{ $t("general.formInput.allocation_days") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.leave_taken") }}
                  </th> -->
                  <th class="text-right">
                    {{ $t("general.formInput.leave_balance") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(leave_item, index) in employee_leaves"
                  :key="index"
                >
                  <td scope="row" >
                    {{ leave_item.leave_type_name}}
                  </td>

                  <!-- <td  >
                    {{ leave_item.max_allocation_days == 0  ? '-' :leave_item.max_allocation_days}}
                  </td>
                
                  <td >
                    {{ !leave_item.taken_leave_days ? '0' : leave_item.taken_leave_days }}
                  </td> -->

                  
                  <td  class="text-right">
                    {{ leave_item.lpd_allocation_days }}
                  </td>  
                  
                </tr>
              </mdb-tbl-body>

            </mdb-tbl>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import Leaves from "@/apis/Leaves";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },
  name: "employeeLeaveBalance",

  data() {
    return {
      employee_leaves: [],
      currentSort: "el_leave_from",
      currentSortDir: "desc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  props: ["employee_id"],
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),


    fetchRecordList(payload = "") {
      this.updateLoading(true);

      if (payload != undefined) {
        if (Math.sign(payload) === 1) {


          Leaves.getleaveBalance(payload)
            .then((response) => {
              if (response.status === 200) {
                this.employee_leaves = response.data;
                this.updateLoading(false);
              }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.feedback_message = "Could not connected to server";
                this.feedback_error = true;

                this.updateLoading(false);
              } else {
                console.log(error);
                this.updateLoading(false);
              }
            });
        }
      }
    },
  },
  created() {
    this.fetchRecordList(this.employee_id);
  },
};
</script>
