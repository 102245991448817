<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>
    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <WorkingHourDropdown
                    @updateWorkingHoursID="setWorkingHoursID"
                    :default_selected_value="
                      manual_duty_assignment.hr_da_working_hour_id
                    "
                    :required="true"
                    :key="counter"
                  />
                  <br />
                </mdb-col>
              </mdb-row>
              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8" class="mdatepicker-wrapper">
                  <m-date-picker
                    v-model="date"
                    :multi="multi"
                    :always-display="false"
                    :format="formatDate"
                    lang="en"
                  ></m-date-picker>
                </mdb-col>
              </mdb-row>

              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <label>Required to take attendance in assignment location</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="option-no"
                      radioValue="no"
                      v-model="location_required"
                      label="No"
                    />
                    <mdb-input
                      type="radio"
                      id="option-yes"
                      radioValue="yes"
                      v-model="location_required"
                      label="Yes"
                    />
                  </mdb-form-inline>
                </mdb-col>

                <mdb-col offsetMd="2" md="8" v-show="location_required == 'yes'">
                  <AttendanceLocationDropdown
                    @updateSelectedAttendanceLocationIDs="
                      setSelectedAttendanceLocationIds
                    "
                    :default_selected_value="
                      manual_duty_assignment.hr_da_allow_gps_location
                    "
                    :key="counter"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <hr />
                  Assign Employees
                  <mdb-btn
                    type="button"
                    outline="primary"
                    class="float-right btn_outline_style"
                    icon="user-friends"
                    @click.native="modalOpen()"
                  >
                    {{ $t("duty_assignment.button.select_employee") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>

              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <div v-if="selected_employee_ids.length > 0">
                    <mdb-chip
                      v-for="(e, i) in selected_employee_ids"
                      v-bind:key="i"
                      waves
                    >
                      {{ e.epinfo_name }}
                    </mdb-chip>
                  </div>

                  <hr />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="lg"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>

    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapCheckboxTable
          @updateSelectedEmployeeIDs="setSelectedEmployeeIDs"
          :default_selected_value="source_default_selected_value"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import ManualDutyAssignment from "@/apis/ManualDutyAssignment";
import EmployeeListBoostrapCheckboxTable from "@/components/EmployeeListBoostrapCheckboxTable";
import WorkingHourDropdown from "@/components/WorkingHourDropdown";
import AttendanceLocationDropdown from "@/components/AttendanceLocationDropdown";
import mDatePicker from "vue-multi-date-picker";
import Vue from "vue";
Vue.use(mDatePicker);
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbChip,
  mdbInput,
    mdbFormInline
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    WorkingHourDropdown,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeListBoostrapCheckboxTable,
    mdbChip,
    AttendanceLocationDropdown,
    mdbInput,
    mdbFormInline
  },
  data() {
    return {
      multi: true,
      date: [],

      manual_duty_assignment: {
        hr_da_user_id: [],
        hr_da_date: [],
        hr_da_working_hour_id: "",
        hr_da_allow_gps_location: [],
      },

      location_required: "no",

      selected_employee_ids: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      modal: false,
      source_default_selected_value: "",
      counter:0
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    setSelectedAttendanceLocationIds(val) {
      this.manual_duty_assignment.hr_da_allow_gps_location = val;
    },

    formatDate(data) {
      // let month = data.getMonth() + 1;
      // let day = data.getDate();
      // let year = data.getFullYear();
      // if (day <= 9) day = "0" + day;
      // if (month < 10) month = "0" + month;
      // const postDate = year + "-" + month + "-" + day;
      // return postDate;

      return data.toLocaleDateString();
    },
    setWorkingHoursID(val) {
      this.manual_duty_assignment.hr_da_working_hour_id = val;
    },

    modalOpen() {
      this.modal = true;

      this.source_default_selected_value = this.selected_employee_ids.map(
        (e) => {
          return e.employee_id;
        }
      );
    },

    setSelectedEmployeeIDs(val) {
      this.selected_employee_ids = val;
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      // validation
      if (this.selected_employee_ids.length == 0) {
        this.feedback_message =
          "You must select at least one Employee for Manual Duty Assignment";
        this.feedback_error = true;
        this.formSubmitting = false;
      }

      if (this.date.length == 0) {
        this.feedback_message =
          "You must select date for Manual Duty Assignment";
        this.feedback_error = true;
        this.formSubmitting = false;
      }

      if (this.formSubmitting == true) {
        this.manual_duty_assignment.hr_da_user_id =
          this.selected_employee_ids.map((e) => {
            return e.employee_id;
          });

        this.manual_duty_assignment.hr_da_date = this.date.map((data) => {
          let month = data.getMonth() + 1;
          let day = data.getDate();
          let year = data.getFullYear();
          if (day <= 9) day = "0" + day;
          if (month < 10) month = "0" + month;
          return year + "-" + month + "-" + day;
        });

        if(this.location_required == "no"){
          this.manual_duty_assignment.hr_da_allow_gps_location = [];
        }

        ManualDutyAssignment.save(this.manual_duty_assignment).then(
          (response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.resetForm();
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }
              this.formSubmitting = false;
            }
          }
        );
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },

    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      this.manual_duty_assignment = {
        hr_da_user_id: [],
        hr_da_date: [],
        hr_da_working_hour_id: "",
        hr_da_allow_gps_location: [],
      };

      this.date = [];
      this.location_required = "no";

      this.selected_employee_ids = [];
      this.counter++;
    },
  },
};
</script>
<style >
.mdatepicker-wrapper div.container {
  padding: 0 !important;
}
.selected-date {
  max-width: 100%;
  min-height: 100px;
  display: block;
}
</style>