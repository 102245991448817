<template>
  <mdb-card class="mb-5">
    <mdb-card-body>
      <div class="row">
        <div class="col-md-5 col-xl-4 sub-menu mb-xl-0 mb-5">
          <h6 class="sub-title text-uppercase font-weight-bold menu-text-color">
            {{ $t("menu.settings.general_config") }}
          </h6>
          <ul class="list-unstyled">
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listHoliday' }"
              >
                {{ $t("menu.settings.holidays") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listDocumentType' }"
              >
                {{ $t("menu.settings.document_types") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'accountReportConfiguration' }"
              >
                {{ $t("menu.settings.account_report_configuration") }}
              </router-link>
            </li>
          </ul>
        </div>

        <div class="col-md-5 col-xl-4 sub-menu mb-xl-0 mb-5">
          <h6 class="sub-title text-uppercase font-weight-bold menu-text-color">
            {{ $t("menu.settings.company_config") }}
          </h6>
          <ul class="list-unstyled">
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listCompanyInfo' }"
              >
                {{ $t("menu.settings.company_info") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listCompanyBranch' }"
              >
                {{ $t("menu.settings.company_branch") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listDepartment' }"
              >
                {{ $t("menu.settings.departments") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listTransactionType' }"
              >
                {{ $t("menu.settings.transactions") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listContractStatus' }"
              >
                {{ $t("menu.settings.contract_status") }}
              </router-link>
            </li>

            
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listApprovalGroup' }"
              >
                {{ $t("menu.settings.approvalGroup") }}
              </router-link>
            </li>

            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'approvalWorkflow' }"
              >
                {{ $t("menu.settings.approvalWorkflow") }}
              </router-link>
            </li>

            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listPermission' }"
              >
                {{ $t("menu.settings.listPermission") }}
              </router-link>
            </li>

            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listAttendanceLocation' }"
              >
                {{ $t("menu.settings.listAttendanceLocation") }}
              </router-link>
            </li>
          </ul>
        </div>

        <div class="col-md-5 col-xl-4 sub-menu mb-xl-0 mb-5">
          <h6 class="sub-title text-uppercase font-weight-bold menu-text-color">
            {{ $t("menu.settings.employee_config") }}
          </h6>
          <ul class="list-unstyled">
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listPosition' }"
              >
                {{ $t("menu.settings.position") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listRank' }"
              >
                {{ $t("menu.settings.rank") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listEmployeeCategory' }"
              >
                {{ $t("menu.settings.employee_category") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listEmployeeGroup' }"
              >
                {{ $t("menu.settings.employee_group") }}
              </router-link>
            </li>
          </ul>
        </div>

        <div class="col-md-5 col-xl-4 sub-menu mb-xl-0">
          <h6 class="sub-title text-uppercase font-weight-bold menu-text-color">
            {{ $t("menu.settings.rule_n_policy_config") }}
          </h6>
          <ul class="list-unstyled">
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listWorkingHours' }"
              >
                {{ $t("menu.settings.working_hours") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listAttendanceRule' }"
              >
                {{ $t("menu.settings.attendance_rule") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listAttendancePolicy' }"
              >
                {{ $t("menu.settings.attendance_policy") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listLateDeductionPolicy' }"
              >
                {{ $t("menu.settings.late_deduction_policy") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listAllowanceType' }"
              >
                {{ $t("menu.settings.allowance_type") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listAllowanceRule' }"
              >
                {{ $t("menu.settings.allowance_rule") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listLeaveType' }"
              >
                {{ $t("menu.settings.leave_types") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listLeaveRule' }"
              >
                {{ $t("menu.settings.leave_rule") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listOvertimeRule' }"
              >
                {{ $t("menu.settings.overtime_rule") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                class="menu-item-text-color menu-item pl-1 mt-2"
                :to="{ name: 'listActionType' }"
              >
                {{ $t("menu.settings.action_type") }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </mdb-card-body>
  </mdb-card>
</template>
<script>
import { mdbCard, mdbCardBody } from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
  },
};
</script>
<style scoped>
.sub-title {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
}
/* li{
  padding: 10px 0;  
} */
ul li a {
  width: 100%;
  transition: 0.3s;
  display: inline-block;
  padding: 10px 0;
}
ul li a:hover {
  padding-left: 15px !important;
  background: #e6e6e6;
}
</style>