<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <div class="float-left mt-3 sec_title">Leader(s)</div>
                  <mdb-btn
                    type="button"
                    outline="primary"
                    class="float-right btn_outline_style"
                    icon="user-friends"
                    @click.native="modalOpen('leaders')"
                  >
                    {{ $t("approval_workflow.button.select_employee") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>

              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <div v-if="leaders_tab.length > 0">
                    <mdb-row>
                      <mdb-col
                        class="mb-3"
                        xl="6"
                        col="12"
                        v-for="(leaders_item, counter) in leaders_tab"
                        v-bind:key="counter"
                        waves
                      >
                        <div class="approval_info_box">
                          <EmployeeInfoBoxLayout :user="leaders_item" />
                        </div>
                      </mdb-col>
                    </mdb-row>
                  </div>
                </mdb-col>
              </mdb-row>

              <hr />

              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <div class="float-left mt-3 sec_title">My Employees</div>
                  <div class="float-right">
                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="btn_outline_style"
                      icon="user-friends"
                      @click.native="modalOpen('employees')"
                    >
                      {{ $t("approval_workflow.button.select_employee") }}
                    </mdb-btn>
                  </div>
                </mdb-col>

                <mdb-col offsetMd="2" md="8" v-if="employees_tab.length > 0">
                  <mdb-row>
                    <mdb-col
                      class="mb-3"
                      xl="6"
                      col="12"
                      v-for="(employees_item, counter) in employees_tab"
                      v-bind:key="counter"
                      waves
                    >
                      <div class="approval_info_box">
                        <EmployeeInfoBoxLayout :user="employees_item" />
                      </div>
                    </mdb-col>
                  </mdb-row>
                </mdb-col>
              </mdb-row>
              <hr />
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>

    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapCheckboxTable
          @updateSelectedEmployeeIDs="setSelectedEmployeeIDs"
          :default_selected_value="source_default_selected_value"
          :source_params="source_params"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import EmployeeActivity from "@/apis/EmployeeActivity";
import EmployeeListBoostrapCheckboxTable from "@/components/EmployeeListBoostrapCheckboxTable";

import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeListBoostrapCheckboxTable,
    EmployeeInfoBoxLayout,
  },
  data() {
    return {
      activity_leader_employee_data: {
        leaders: [],
        employees: [],
        delete_previous_request:"no"
      },

      leaders_tab: [],
      employees_tab: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      modal: false,
      source_default_selected_value: "",
      source_params: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    modalOpen(param) {
      this.modal = true;
      this.source_params = param;

      if (param == "leaders") {
        this.source_default_selected_value = this.leaders_tab.map((e) => {
          return e.employee_id;
        });
      } else {
        this.source_default_selected_value = this.employees_tab.map((e) => {
          return e.employee_id;
        });
      }
    },

    setSelectedEmployeeIDs(val) {
      if (val[1] == "leaders") {
        this.leaders_tab = val[0];
      } else {
        this.employees_tab = val[0];
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      // validation
      if (this.leaders_tab.length == 0) {
        this.feedback_message =
          "You must select at least one Employee in request from for Approval Workflow";
        this.feedback_error = true;
        this.formSubmitting = false;
      } else if (this.employees_tab.length == 0) {
        this.feedback_message =
          "You must select at least one Employee in request to for Approval Workflow";
        this.feedback_error = true;
        this.formSubmitting = false;
      }

      if (this.formSubmitting == true) {
        this.activity_leader_employee_data.leaders =
          this.leaders_tab.map((e) => {
            return e.employee_id;
          });

        this.activity_leader_employee_data.employees = this.employees_tab.map(
          (e) => {
            return e.employee_id;
          }
        );

        EmployeeActivity.saveActivityViewer(this.activity_leader_employee_data).then(
          (response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.resetForm();
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.formSubmitting = false;
            }
          }
        );
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },

    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      this.activity_leader_employee_data = {
        leaders: [],
        employees: [],
        delete_previous_request:"no"
      };

      this.leaders_tab = [];
      this.employees_tab = [];
      this.source_default_selected_value = "";
      this.source_params = "";
    },
  },
};
</script>