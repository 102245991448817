<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-show="!isLoading">

        <!-- <form @submit.prevent="handleFilterSubmit">
          <mdb-row>
            <mdb-col md="4" lg="3">
              <mdb-input
                type="text"
                :label="$t('general.formInput.keywords')"
                v-model="keywords"
                outline
              />
            </mdb-col>
            <mdb-col md="8" lg="9" class="md-form md-outline">
              <mdb-btn color="primary" :disabled="doesFormSubmitted" size="sm">
                <mdb-icon
                  icon="file-upload"
                  size="lg"
                  v-show="!doesFormSubmitted"
                />

                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-show="doesFormSubmitted"
                ></span>
                {{ $t("general.button.submit") }}
              </mdb-btn>
              
              <mdb-btn color="primary" size="sm" class="float-right" @click="showAdvSearchModal = true">
                <mdb-icon icon="file-upload" size="lg" />
                Advance Search
              </mdb-btn>
            </mdb-col>
          </mdb-row>
        </form> -->

        <mdb-row>
           <mdb-col md="4">
            <mdb-input
              type="text"
              :label="$t('general.formInput.keywords')"
              v-model="filter_form.keywords"
              outline
            />
          </mdb-col>
          <mdb-col md="8">
            <mdb-btn
              color="primary"
              size="sm"
              class="btn_outline_style mt-4"
              @click="showAdvSearchModal = true"
            >
              <mdb-icon icon="file-upload" size="md" />
              Advance Search
            </mdb-btn>

              <mdb-btn
              color="primary"
              size="sm"
              class="btn_outline_style mt-4"
              @click="exportEmployeeList"
            >
              <mdb-icon icon="file-upload" size="md" />
              Export
            </mdb-btn>

          </mdb-col>
        </mdb-row>

        <mdb-card>
          <mdb-card-header>
            <div class="float-left emplyee_list_header">
              {{ $t("menu.settings.employee_list") }}
            </div>
            <div class="float-right">
              <mdb-btn
                color="uno-color"
                class="btn_submit"
                @click="createNewRecord"
                size="sm"
              >
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <!-- <th @click="sort('epinfo_name')"> -->
                  <th >
                    <div class="name_center">
                      <i
                        v-show="currentSort === 'epinfo_name'"
                        :class="[
                          currentSortDir === 'asc'
                            ? 'fas fa-arrow-up '
                            : 'fas fa-arrow-down ',
                        ]"
                        aria-hidden="true"
                      ></i>
                      {{ $t("general.formInput.name") }}
                    </div>
                  </th>
                  <!-- <th @click="sort('employee_code')"> -->
                  <th >
                    <i
                      v-show="currentSort === 'employee_code'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("employees.formInput.employee_id") }}
                  </th>
                  <th>
                    {{ $t("employees.formInput.rank") }}
                  </th>

                  <th>
                    {{ $t("employees.formInput.employment_type") }}
                  </th>

                  <!-- <th
                    width="120"
                    class="text-right"
                    @click="sort('employee_join_date')"
                  > -->
                  <th
                    width="120"
                    class="text-right"
                  >
                    <i
                      v-show="currentSort === 'employee_join_date'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("employees.formInput.join_date") }}
                  </th>
                  <th class="text-center">
                    {{ $t("general.formInput.status") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(record_item, index) in pageOfItems"
                  :key="index"
                >
                  <td
                    scope="row"
                    @click="rowClickEvent(record_item.employee_id)"
                    class="align-middle"
                    align="left"
                  >
                    <EmployeeInfoTableCellLayout :user="record_item" />
                  </td>
                  <td
                    @click="rowClickEvent(record_item.employee_id)"
                    class="align-middle"
                  >
                    {{ record_item.employee_code }}
                  </td>
                  <td
                    @click="rowClickEvent(record_item.employee_id)"
                    class="align-middle"
                  >
                    {{ record_item.rt_name }}
                  </td>
                  <td
                    @click="rowClickEvent(record_item.employee_id)"
                    class="align-middle"
                  >
                    {{ record_item.contract_type }}
                  </td>

                  <td
                    class="text-right align-middle"
                    @click="rowClickEvent(record_item.employee_id)"
                  >
                    {{ record_item.employee_join_date | formatDateString }}
                  </td>
                  <td
                    @click="rowClickEvent(record_item.employee_id)"
                    class="align-middle text-center"
                  >
                    <div v-if="record_item.status == 'active'">
                      <mdb-icon
                        icon="check-circle"
                        size="lg"
                        class="green-text"
                      />
                    </div>
                    <div v-else>
                      <mdb-icon
                        icon="check-circle"
                        size="lg"
                        class="red-text"
                      />
                    </div>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <div class="card-footer pb-0 pt-3">
              <jw-pagination
                :items="sortedRecordList"
                @changePage="onChangePage"
                :pageSize="rowsPerPage"
                :disableDefaultStyles="true"
                :labels="customLabels"
              ></jw-pagination>
            </div>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>

    <AdvanceSearchForm
      :showAdvSearchModal="showAdvSearchModal"
      @closingModal="showAdvSearchModal = false"
      @submitFilterModal="handleAdvanceSearchFilter"
    />
  </div>
</template>

<script>
import Vue from "vue";
import JwPagination from "jw-vue-pagination";
Vue.component("jw-pagination", JwPagination);

import Employee from "@/apis/Employee";
import { mapActions, mapGetters } from "vuex";
import AdvanceSearchForm from "@/components/AdvanceSearchForm";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";

import {
  mdbRow,
  mdbCol,
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbInput,
} from "mdbvue";

const customLabels = {
  first: "«",
  last: "»",
  previous: "<",
  next: ">",
};

export default {
  components: {
    mdbRow,
    mdbCol,
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbInput,
    AdvanceSearchForm,
    EmployeeInfoTableCellLayout,

  },

  data() {
    return {
      customLabels,
      keywords: "",
      doesFormSubmitted: false,
      employees: [],
      pageOfItems: [],
      currentSort: "",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
      showAdvSearchModal: false,
      rowsPerPage: this.$store.state.rowsPerPage,

      filter_form: {
        keywords: "",
      },

      adv_serach_form_data:{}
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    sortedRecordList() {

      let tempRecords = this.employees;
      if (this.filter_form.keywords != "" && this.filter_form.keywords) {
        
        tempRecords = tempRecords.filter((item) => {
          return item.epinfo_name
            .toUpperCase()
            .includes(this.filter_form.keywords.toUpperCase())
            ||
            item.employee_code
            .toUpperCase()
            .includes(this.filter_form.keywords.toUpperCase())
            ||
            item.department_name
            .toUpperCase()
            .includes(this.filter_form.keywords.toUpperCase())
            ||
            item.jp_name
            .toUpperCase()
            .includes(this.filter_form.keywords.toUpperCase());
        });

      }



      // return tempRecords.slice().sort((a, b) => {
      //   let modifier = 1;
      //   if (this.currentSortDir === "desc") modifier = -1;
      //   if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
      //   if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
      //   return 0;
      // });

      return tempRecords;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },

    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    rowClickEvent(id) {
      this.$router.push({
        name: "viewEmployeeInfo",
        params: { employee_id: id },
      });
    },

    createNewRecord() {
      this.$router.push({ name: "createEmployee" });
    },

    exportEmployeeList(){
      if(this.filter_form.keywords!=''){
        this.adv_serach_form_data.keywords = this.filter_form.keywords
      }

       Employee.exportEmployeeReport(this.adv_serach_form_data).then((response) => {
          if (response.status === 200) {

            let today = new Date();
          let [month, date, year] = today
            .toLocaleDateString("en-US")
            .split("/");
          let [hour, minute, second] = today
            .toLocaleTimeString("en-US")
            .split(/:| /);
          let today_string = year + month + date + "_" + hour + minute + second;

            var blob = new Blob(["\ufeff",response.data]);
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "EmployeeExport" + "_" + today_string + ".csv";
            link.click();

            this.formSubmitting = false;
          }
        });

    },

    handleAdvanceSearchFilter(formData) {

      this.adv_serach_form_data = formData;
      this.filter_form.keywords = formData.keywords;

      // console.log(formData);
      this.fetchRecordList(formData);
      this.showAdvSearchModal = false;
    },

    fetchRecordList(payload = "") {
      this.updateLoading(true);

      Employee.all(payload)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.employees = response.data.data;
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
<style >
.pagination li.previous {
  display: none;
}
.pagination li.next {
  display: none;
}
</style>