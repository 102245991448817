import Api from './api'

export default{

    save(payload){
            return Api.post('api/attendance/dynamic/data_entry', payload)        
    },
    
    delete(payload){
        return Api.post('api/attendance/dynamic/delete', payload)        
    },
    
    view(payload){
        return Api.get('api/attendance/dynamic/' + payload)        
    },
    
}