<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">
        <dl class="row">
          <dt class="col-sm-3">Title</dt>
          <dd class="col-sm-9">{{ announcement.title }}</dd>

          <dt class="col-sm-3">Description</dt>
          <dd class="col-sm-9">{{ announcement.description }}</dd>

          <dt class="col-sm-3">Total</dt>
          <dd class="col-sm-9">{{ announcement.employee_count.total }}</dd>

          <dt class="col-sm-3">Read</dt>
          <dd class="col-sm-9">{{ announcement.employee_count.read }}</dd>

          <dt class="col-sm-3">Unread</dt>
          <dd class="col-sm-9">{{ announcement.employee_count.unread }}</dd>

          <dt class="col-sm-3">Attachments</dt>
          <dd class="col-sm-9">
             <div v-if="announcement.attachments.length > 0">
              <div
                v-for="(attachment_item, index) in announcement.attachments"
                :key="index"
              >              
                  <div class="float-left attachments_text_style">
                  <a
                    :href="attachment_item.attachment_file_name"
                    target="_blank"
                  >                  
                  <mdb-card cascade class="attachment_card_style">
                        <mdb-view hover cascade>
                          <template>
                    <mdb-card-image
                      v-if="
                        attachment_item.file_ext == 'png' ||
                        attachment_item.file_ext == 'gif' ||
                        attachment_item.file_ext == 'jpg' ||
                        attachment_item.file_ext == 'jpeg'
                      "
                      :src="attachment_item.attachment_file_name"                      
                      class="announcement_thumbnail_image"
                    ></mdb-card-image>
                    <mdb-card-image
                      v-else-if="
                        attachment_item.file_ext == 'doc' ||
                        attachment_item.file_ext == 'docx'
                      "
                      src="/doc.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    ></mdb-card-image>
                    <mdb-card-image
                      v-else-if="
                        attachment_item.file_ext == 'xls' ||
                        attachment_item.file_ext == 'xlsx'
                      "
                      src="/spreadsheet.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    ></mdb-card-image>
                    <mdb-card-image
                      v-else-if="
                        attachment_item.file_ext == 'ppt' ||
                        attachment_item.file_ext == 'ppt'
                      "
                      src="/powerpoint.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    ></mdb-card-image>
                    <mdb-card-image
                      v-else-if="
                        attachment_item.file_ext == 'pdf'
                      "
                      src="/pdf.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    ></mdb-card-image>
                    <mdb-card-image
                      v-else
                      src="/unknowfile.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    ></mdb-card-image>
                    </template>
                        </mdb-view>
                        <mdb-card-body class="text-center em_attachments_card_body" cascade>
                          {{ attachment_item.attachment_original_file_name | filename_str_limit}}
                        </mdb-card-body>
                      </mdb-card>
                    <br />                    
                  </a>                
                </div>
              </div>
            </div>
          </dd>
        </dl>

        <div class="text-right">
          <mdb-btn
            type="button"
            color="primary"
            class="btn_outline_style"
            icon="edit"
            @click="redirectUpdateForm"
          >
            {{ $t("general.button.edit") }}
          </mdb-btn>
        </div>

        <!-- {{announcement}} -->
        <mdb-card>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->

              <mdb-tbl-head>
                <tr>
                  <th colspan="" @click="sort('epinfo_name')">
                    <div class="name_center">
                      <i
                        v-show="currentSort === 'epinfo_name'"
                        :class="[
                          currentSortDir === 'asc'
                            ? 'fas fa-arrow-up '
                            : 'fas fa-arrow-down ',
                        ]"
                        aria-hidden="true"
                      ></i>
                      {{ $t("general.formInput.name") }}
                    </div>
                  </th>
                  <th @click="sort('employee_code')">
                    <i
                      v-show="currentSort === 'employee_code'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("employees.formInput.employee_id") }}
                  </th>
                  <th>
                    {{ $t("employees.formInput.rank") }}
                  </th>

                  <th>Status</th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(record_item, index) in pageOfItems"
                  :key="index"
                >
                  <td scope="row" class="align-middle">
                    <EmployeeInfoTableCellLayout :user="record_item" />
                  </td>
                  <td class="align-middle">
                    {{ record_item.employee_code }}
                  </td>
                  <td class="align-middle">
                    {{ record_item.rt_name }}
                  </td>

                  <td>
                    {{ record_item.status }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>

            <div class="card-footer pb-0 pt-3">
              <jw-pagination
                :items="sortedRecordList"
                @changePage="onChangePage"
                :pageSize="rowsPerPage"
              ></jw-pagination>
            </div>
          </mdb-card-body>
        </mdb-card>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import JwPagination from "jw-vue-pagination";
Vue.component("jw-pagination", JwPagination);

import Announcement from "@/apis/Announcement";
import { mapActions, mapGetters } from "vuex";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  // mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbBtn,
  mdbView,
  mdbCardImage,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    // mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbBtn,
    mdbView,
    mdbCardImage,
    EmployeeInfoTableCellLayout,
  },
  props: ["_id"],
  data() {
    return {
      pageOfItems: [],
      rowsPerPage: this.$store.state.rowsPerPage,
      announcement: {},
      employees: [],
      currentSort: "epinfo_name",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    sortedRecordList() {
      return this.employees.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },

    redirectUpdateForm() {
      this.$router.push({
        name: "viewAnnouncement",
        params: { _id: this._id },
      });
    },

    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    fetchRecordList() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);

          Announcement.viewSummary(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.data.type == "individual") {
                  this.announcement = response.data.data;
                  this.employees = response.data.detail;
                  this.updateLoading(false);
                } else {
                  this.$router.push({
                    name: "viewAnnouncementDepartmentSummary",
                    params: { _id: this._id },
                  });
                }
              }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.feedback_message = "Could not connected to server";
                this.feedback_error = true;

                this.updateLoading(false);
              } else {
                console.log(error);
                this.updateLoading(false);
              }
            });
        } else {
          this.$router.push({ name: "listAnnouncement" });
        }
      } else {
        this.$router.push({ name: "listAnnouncement" });
      }
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
<style scoped>
.announcement_thumbnail_image {
  border: 2px solid #c0c0c0;
  /* padding: 5px; */
  margin-top: 10px;  
  width: 150px;  
  max-width: 150px;
  max-height: 100px;
}
.attachments_text_style{
  text-align: center;
  margin-right: 20px;
  width: 150px;
}
.card{
  box-shadow: none;  
}
.em_attachments_card_body{
  background: #f5f5f9;
  padding: 10px;
}
.card.card-cascade .view.view-cascade {
    border-radius: 0.25rem;
    box-shadow: none;
    background: #f5f5f9;
}
</style>