export const genderOption = (required) => {

    let data_list = [];

    if (required) {
        data_list.push({ 'value': '', 'text': 'All' })
    }
    data_list.push({ 'value': 'male', 'text': 'Male' })
    data_list.push({ 'value': 'female', 'text': 'Female' })
    return data_list;
}

export const employmentTypeOption = (required, selected_val = "") => {

    let data_list = [];

    if (required) {
        data_list.push({ 'value': '', 'text': 'All' })
    }
    data_list.push({ 'value': 'contract', 'text': 'Contract' })
    data_list.push({ 'value': 'permanent', 'text': 'Permanent' })

    if (selected_val != "") {
        for (let i = 0; i < data_list.length; i++) {
            if (data_list[i]['value'] == selected_val) {
                data_list[i]['selected'] = true;
            }

        }

    }
    return data_list;
}


export const relationshipOption = (required, selected_val = "") => {

    let data_list = [];

    if (!required) {
        data_list.push({ 'value': '', 'text': 'All' })
    }
    data_list.push({ 'value': 'father', 'text': 'Father' })
    data_list.push({ 'value': 'mother', 'text': 'Mother' })
    data_list.push({ 'value': 'brother', 'text': 'Brother' })
    data_list.push({ 'value': 'sister', 'text': 'Sister' })
    data_list.push({ 'value': 'spouse', 'text': 'Spouse' })
    data_list.push({ 'value': 'son', 'text': 'Son' })
    data_list.push({ 'value': 'daughter', 'text': 'Daughter' })

    if (selected_val != "") {
        for (let i = 0; i < data_list.length; i++) {
            if (data_list[i]['value'] == selected_val) {
                data_list[i]['selected'] = true;
            }
        }
    }

    return data_list;
}

export const langProficiencyOption = (required, selected_val = "") => {

    let data_list = [];

    if (!required) {
        data_list.push({ 'value': '', 'text': 'All' })
    }
    data_list.push({ 'value': '0', 'text': '0' })
    data_list.push({ 'value': '1', 'text': '1' })
    data_list.push({ 'value': '2', 'text': '2' })
    data_list.push({ 'value': '3', 'text': '3' })
    data_list.push({ 'value': '4', 'text': '4' })
    data_list.push({ 'value': '5', 'text': '5' })
    data_list.push({ 'value': '6', 'text': '6' })
    data_list.push({ 'value': '7', 'text': '7' })
    data_list.push({ 'value': '8', 'text': '8' })
    data_list.push({ 'value': '9', 'text': '9' })

    if (selected_val != "") {
        for (let i = 0; i < data_list.length; i++) {
            if (data_list[i]['value'] == selected_val) {
                data_list[i]['selected'] = true;
            }

        }

    }
    return data_list;
}


export const yearOption = (required, selected_val = "") => {

    let data_list = [];

    if (!required) {
        data_list.push({ 'value': '', 'text': 'All' })
    }

    for (
        let current_year = new Date().getFullYear();
        current_year >= 1900;
        --current_year
    ) {

        if (!isNaN(selected_val) && selected_val != null && selected_val != "" && current_year == selected_val) {
            data_list.push({ text: current_year, value: current_year, 'selected': true });
        } else {
            data_list.push({ text: current_year, value: current_year });
        }
    }
    return data_list;
}


export const additionalCategoryOption = (required, selected_val = "") => {

    let data_list = [];

    if (!required) {
        data_list.push({ 'value': '', 'text': 'All' })
    }
    data_list.push({ 'value': 'allowance', 'text': 'Allowance' })
    data_list.push({ 'value': 'bonus', 'text': 'Bonus' })
    data_list.push({ 'value': 'incentive', 'text': 'Incentive' })
    data_list.push({ 'value': 'tax_adjustment', 'text': 'Tax Adjustment' })
    data_list.push({ 'value': 'others', 'text': 'Others' })

    if (selected_val != "") {
        for (let i = 0; i < data_list.length; i++) {
            if (data_list[i]['value'] == selected_val) {
                data_list[i]['selected'] = true;
            }

        }

    }
    return data_list;
}

export const deductionCategoryOption = (required, selected_val = "") => {

    let data_list = [];

    if (!required) {
        data_list.push({ 'value': '', 'text': 'All' })
    }

    data_list.push({ 'value': 'hp', 'text': 'Hire Purchase' })
    data_list.push({ 'value': 'loan', 'text': 'Loan' })
    data_list.push({ 'value': 'saving', 'text': 'Saving' })
    data_list.push({ 'value': 'hostel_charges', 'text': 'Hostel Charges' })
    data_list.push({ 'value': 'tax_adjustment', 'text': 'Tax Adjustment' })
    data_list.push({ 'value': 'others', 'text': 'Others' })

    if (selected_val != "") {
        for (let i = 0; i < data_list.length; i++) {
            if (data_list[i]['value'] == selected_val) {
                data_list[i]['selected'] = true;
            }

        }

    }
    return data_list;
}

export const martialStatusOption = (required, selected_val = "") => {

    let data_list = [];

    if (!required) {
        data_list.push({ 'value': '', 'text': 'All' })
    }

    data_list.push({ 'value': 'single', 'text': 'Single' })
    data_list.push({ 'value': 'married', 'text': 'Married' })
    data_list.push({ 'value': 'widowed', 'text': 'Widowed' })
    data_list.push({ 'value': 'divorced', 'text': 'Divorced' })
    data_list.push({ 'value': 'separated', 'text': 'Separated' })

    if (selected_val != "") {
        for (let i = 0; i < data_list.length; i++) {
            if (data_list[i]['value'] == selected_val) {
                data_list[i]['selected'] = true;
            }

        }

    }
    return data_list;
}

export const OptionPenaltyType = (required, selected_val = "") => {

    let data_list = [];

    if (!required) {
        data_list.push({ 'value': '', 'text': 'All' })
    }
    data_list.push({ 'value': 'amount', 'text': 'amount' })
    data_list.push({ 'value': 'percentage', 'text': 'percentage' })

    if (selected_val != "") {
        for (let i = 0; i < data_list.length; i++) {
            if (data_list[i]['value'] == selected_val) {
                data_list[i]['selected'] = true;
            }

        }

    }
    return data_list;
}



export const hrTrainingType = (required, selected_val = "") => {

    let data_list = [];

    if (!required) {
        data_list.push({ 'value': '', 'text': 'All' })
    }
    data_list.push({ 'value': 'internal', 'text': 'Internal' })
    data_list.push({ 'value': 'external', 'text': 'External' })

    if (selected_val != "") {
        for (let i = 0; i < data_list.length; i++) {
            if (data_list[i]['value'] == selected_val) {
                data_list[i]['selected'] = true;
            }

        }

    }
    return data_list;
}



export const ssbCardTypeOption = (required, selected_val = "") => {

    let data_list = [];

    if (!required) {
        data_list.push({ 'value': '', 'text': 'All' })
    }
    data_list.push({ 'value': 'Temporary Card', 'text': 'Temporary Card' })
    data_list.push({ 'value': 'Smart Card', 'text': 'Smart Card' })
    data_list.push({ 'value': 'No Card', 'text': 'No Card' })

    if (selected_val != "") {
        for (let i = 0; i < data_list.length; i++) {
            if (data_list[i]['value'] == selected_val) {
                data_list[i]['selected'] = true;
            }

        }

    }
    return data_list;
}



export const approvalStatusOption = (required, selected_val = "") => {

    let data_list = [];

    if (!required) {
        data_list.push({ 'value': '', 'text': 'All' })
    }
    data_list.push({ 'value': 'pending', 'text': 'Pending' })
    data_list.push({ 'value': 'approve', 'text': 'Approve' })
    data_list.push({ 'value': 'reject', 'text': 'Cancel' })

    if (selected_val != "") {
        for (let i = 0; i < data_list.length; i++) {
            if (data_list[i]['value'] == selected_val) {
                data_list[i]['selected'] = true;
            }

        }

    }
    return data_list;
}