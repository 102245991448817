<template>
  <div>
    <mdb-select
    outline
      search
      v-model="axiosOptions"
      :label="selectLabel"
      @getValue="updateSelectedValue"
      :disabled="loading"
    />
  </div>
</template>

<script>
import { mdbSelect } from "mdbvue";
import AllowanceType from "@/apis/AllowanceType";
export default {
  name: "AllowanceTypeDropdown",
  components: {
    mdbSelect,
  },
  props: ["default_selected_value", "required","resetSelected"],
  data() {
    return {
      axiosOptions: [],
      loading:true,
      selectLabel : "Loading Allowance Type List"
    };
  },
  mounted() {
       AllowanceType.all()
        .then((response) => {
          if (response.status === 200) {
            const allowance_types = response.data.data;
            if(this.required === false){
                this.axiosOptions.push({
                    text: "All",
                    value: "",
                });
            }            

            for (let allowance_type of allowance_types) {
                if (this.default_selected_value === allowance_type.at_id) {
                    this.axiosOptions.push({
                    text: allowance_type.at_name,
                    value: allowance_type.at_id,
                    selected:true
                    });
                } else {
                    this.axiosOptions.push({
                    text: allowance_type.at_name,
                    value: allowance_type.at_id,
                    });
                }
            }    
            this.loading = false
            this.selectLabel = "Select Allowance Type"

          }
        })
  },
  methods: {
    updateSelectedValue(val) {
      if (val !== null) {
        this.$emit("updateAllowanceTypeID", val);
      }
    },
  },
  watch:{
    resetSelected(newValue, oldValue){
      if (newValue !== oldValue){
        this.axiosOptions = this.axiosOptions.map(val => ({text: val.text, value: val.value})) 
      }
    }
  }
};
</script>
