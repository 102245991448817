<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <template v-if="!employee_id">
                <mdb-row class="mb-3">
                  <mdb-col offsetMd="2" md="8">
                    <div class="float-left mt-3 sec_title">Select Employee</div>
                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="float-right btn_outline_style"
                      icon="user-friends"
                      @click.native="modal = true"
                    >
                      {{ $t("payrolls.button.select_employee") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>

                <mdb-row v-if="selectedEmployeeData" class="mb-5">
                  <mdb-col offsetMd="2" md="8">
                    <div class="approval_info_box">
                      <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
                    </div>
                  </mdb-col>
                </mdb-row>
              </template>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-select
                    v-model="OptionAddition"
                    placeholder="Select an option"
                    label=""
                    outline
                    @getValue="updateAdditionValue"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    :label="$t('addition.formInput.description')"
                    v-model="additions.ota_description"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="number"
                    :label="$t('addition.formInput.amount')"
                    v-model="additions.ota_amount"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <!-- <mdb-date-picker-2
                    :label="$t('addition.formInput.effective_month')"
                    v-model="additions.ota_effective_month"
                    title="Select date"
                  /> -->

                  <MonthDropdown
                    @updateMonth="setMonth"
                    :selected_date="temp_effective_month"
                    v-if="!isLoading"
                  />

                  <YearDropdown
                    @updateYear="setYear"
                    :selected_date="temp_effective_year"
                    v-if="!isLoading"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    outline="default"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="lg" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    outline="primary"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="lg"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    outline="danger"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    class="float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="lg"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />

    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapSingleSelectTable
          @updateSelectedEmployee="setSelectedEmployee"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import Addition from "@/apis/Addition";
import LogList from "@/components/logs/List";
import { additionalCategoryOption } from "@/constants/employee.js";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";

import EmployeeListBoostrapSingleSelectTable from "@/components/EmployeeListBoostrapSingleSelectTable";
import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";

import YearDropdown from "@/components/YearDropdown";
import MonthDropdown from "@/components/MonthDropdown";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  // mdbDatePicker2,
  mdbSelect,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  // mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    // mdbDatePicker2,
    mdbSelect,
    // mdbFormInline,
    ModalDeleteConfimation,

    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeInfoBoxLayout,
    EmployeeListBoostrapSingleSelectTable,

    YearDropdown,
    MonthDropdown,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      additions: {
        ota_id: "",
        ota_employee_id: this.employee_id ? this.employee_id : "",
        ota_category: "",
        ota_description: "",
        ota_amount: "",
        ota_effective_month: "",
      },
      OptionAddition: additionalCategoryOption(true, ""),
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",

      selectedEmployeeData: null,
      modal: false,

      temp_effective_month: "",
      temp_effective_year: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),
    setMonth(value) {
      this.temp_effective_month = value;
    },
    setYear(value) {
      this.temp_effective_year = value;
    },
    setSelectedEmployee(val) {
      this.modal = false;
      this.selectedEmployeeData = val;
      this.additions.ota_employee_id = val.employee_id;
    },
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    updateAdditionValue(val) {
      this.additions.ota_category = val;
    },

    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          Addition.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  let ota_id,
                    ota_employee_id,
                    ota_category,
                    ota_description,
                    ota_amount,
                    ota_effective_month;
                  // populate field
                  ({
                    ota_id,
                    ota_employee_id,
                    ota_category,
                    ota_description,
                    ota_amount,
                    ota_effective_month,
                  } = response.data.data);
                  this.additions.ota_id = ota_id;
                  this.additions.ota_employee_id = ota_employee_id;
                  this.additions.ota_category = ota_category;
                  this.additions.ota_description = ota_description;
                  this.additions.ota_amount = ota_amount;
                  this.additions.ota_effective_month = ota_effective_month;
                  //make selected option again
                  this.OptionAddition = additionalCategoryOption(
                    true,
                    ota_category
                  );

                  if (ota_effective_month != "") {
                    const parts = ota_effective_month.split("-");

                    this.temp_effective_month = parts[1];
                    this.temp_effective_year = parts[0];
                  }

                  if (!this.employee_id) {
                    this.selectedEmployeeData = {
                      epinfo_name: response.data.data.epinfo_name,
                      employee_code: response.data.data.employee_code,
                      profile_image: response.data.data.profile_image,
                      department_name: response.data.data.department_name,
                      jp_name: response.data.data.jp_name,
                      rt_name: response.data.data.rt_name,
                    };
                    //emp info
                  }

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    additionList: {
                      employee_id: this.additions.ota_employee_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "additionList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "additionList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "additionList" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      this.additions.ota_effective_month =
        this.temp_effective_year + "-" + (parseInt(this.temp_effective_month) >= 10 ? this.temp_effective_month : '0'+parseInt(this.temp_effective_month)) + "-01";

      Addition.update(this.additions).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });
            // this.$router.push({
            //   name: "additionList",
            //   params: { employee_id: this.additions.ota_employee_id },
            // });
            if (this.employee_id) {
              this.$router.push({
                name: "additionList",
                params: { employee_id: this.additions.ota_employee_id },
              });
            } else {
              this.$router.push({ name: "additionsManageList" });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
    },
    redirectBackAction() {
      // this.$router.push({
      //   name: "additionList",
      //   params: { employee_id: this.additions.ota_employee_id },
      // });
      if (this.employee_id) {
        this.$router.push({
          name: "additionList",
          params: { employee_id: this.additions.ota_employee_id },
        });
      } else {
        this.$router.push({ name: "additionsManageList" });
      }
    },
    resetAlert() {
      this.feedback_error = false;
    },

    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.additions.ota_id > 0) {
        Addition.delete({
          ota_id: this.additions.ota_id,
        })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                if (this.employee_id) {
                  this.$router.push({
                    name: "additionList",
                    params: { employee_id: this.additions.ota_employee_id },
                  });
                } else {
                  this.$router.push({ name: "additionsManageList" });
                }
                // this.$router.push({
                //   name: "additionList",
                //   params: {
                //     employee_id: this.additions.ota_employee_id,
                //   },
                // });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      additionList: {
        employee_id: this.additions.ota_employee_id,
      },
    };
    this.fetchRecord();
  },
};
</script>