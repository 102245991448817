<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">
        <OvertimeSearchForm @submitFilterForm="handleSubmitFilterForm"  @submitExport="handlesubmitExport" :filterFormData="searchFormData"/>

        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn
                color="uno-color"
                class="btn_submit"
                @click="createNewRecord"
                size="sm"
              >
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th></th>
                  <th>
                    {{ $t("general.formInput.date") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.hour_from") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.hour_to") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.duration") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.status") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(recordlist, index) in overtime_list.data"
                  :key="index"
                >
                  <td
                    scope="row"
                    @click="rowClickEvent(recordlist.ot_id)"
                  >
                    <EmployeeInfoTableCellLayout :user="recordlist" />
                  </td>
                  <td @click="rowClickEvent(recordlist.ot_id)">
                    {{ recordlist.ot_date  | formatDateString}}
                  </td>
                  <td @click="rowClickEvent(recordlist.ot_id)">
                    {{ recordlist.ot_start_from  | formatDateTimeString}}
                  </td>
                  <td @click="rowClickEvent(recordlist.ot_id)">
                    {{ recordlist.ot_end_at | formatDateTimeString }}
                  </td>
                  <td @click="rowClickEvent(recordlist.ot_id)">
                    {{ recordlist.ot_total_minutes }} mins
                  </td>
                  <td @click="rowClickEvent(recordlist.ot_id)">
                    <template v-if="recordlist.ot_request_by == 'behalf'">
                      Approved
                    </template>
                    <template
                      v-else-if="
                        recordlist.ot_request_by != 'behalf' &&
                        recordlist.ot_status == 'approve' &&
                        recordlist.ot_complete == '0'
                      "
                    >
                      Pending Approval
                    </template>
                    <template v-else>
                      {{ recordlist.ot_status }}
                    </template>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <pagination :data="overtime_list" @pagination-change-page="fetchRecordList" :limit="2"></pagination>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
Vue.component('pagination', require('laravel-vue-pagination'))

import Overtime from "@/apis/Overtime";
import { mapActions, mapGetters } from "vuex";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";

import OvertimeSearchForm from "@/components/search/OvertimeSearchForm";
import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    EmployeeInfoTableCellLayout,
    OvertimeSearchForm
  },

  data() {
    return {
      overtime_list: [],
      searchFormData: {
        keywords: "",
        department_id: "",
        jp_id: "",
        filter_start_date:"",
        filter_end_date:"",
        status:""

      },
      currentSort: "contract_reference",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    rowClickEvent(id) {
      this.$router.push({
        name: "viewManageOvertime",
        params: { _id: id },
      });
    },

    handleSubmitFilterForm(val){
      this.searchFormData = val
      this.fetchRecordList()
    },

    handlesubmitExport(){
      this.exportOvertimeData()
    },

    createNewRecord() {
      this.$router.push({
        name: "createManageOvertime"
      });
    },

    exportOvertimeData(){

      ///
      this.updateLoading(true);

      const payload = {
        keywords: this.searchFormData.keywords,
        department_id: this.searchFormData.department_id,
        jp_id: this.searchFormData.jp_id,        
        filter_start_date:this.searchFormData.filter_start_date,
        filter_end_date:this.searchFormData.filter_end_date,
        status:this.searchFormData.status
      };
       Overtime.exportCSVReport(payload).then((response) => {
          if (response.status === 200) {

            let today = new Date();
          let [month, date, year] = today
            .toLocaleDateString("en-US")
            .split("/");
          let [hour, minute, second] = today
            .toLocaleTimeString("en-US")
            .split(/:| /);
          let today_string = year + month + date + "_" + hour + minute + second;

            var blob = new Blob(["\ufeff",response.data]);
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "Overtime" + "_" + today_string + ".csv";
            link.click();

            this.formSubmitting = false;
            this.updateLoading(false);
          }
        });
      ///


    },

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      const params = {
        page: page,
        keywords: this.searchFormData.keywords,
        department_id: this.searchFormData.department_id,
        jp_id: this.searchFormData.jp_id,        
        filter_start_date:this.searchFormData.filter_start_date,
        filter_end_date:this.searchFormData.filter_end_date,
        status:this.searchFormData.status
      };
      Overtime.all(params)
        .then((response) => {
          if (response.status === 200) {
            this.overtime_list = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
