<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card
          class="mb-4"
          v-if="leaveGraphData.label.length > 0 || leaveRateData.length > 0"
        >
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Leave Rate</h6>

                <div>
                  <VerticalBarChartView
                    v-if="leaveGraphData && leaveGraphData.label.length > 0"
                    :barGraphData="leaveGraphData"
                    
                    :xlabel="'Departments'"
                    :ylabel="'Percentage of Leave'"
                  />
                </div>
                <br />
                <br />
                <div v-if="leaveRateData.length > 0">
                  <mdb-tbl responsive striped bordered sm>
                    <mdb-tbl-head style="background: #96a6bf; color: #fff">
                      <tr>
                        <td>Department</td>
                        <td>Active Employee</td>
                        <td>Leave Day</td>
                        <td>% of Leave</td>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body>
                      <tr v-for="(item, index) in leaveRateData" :key="index">
                        <td>{{ item["department"] }}</td>
                        <td>{{ item["active_employee"] }}</td>
                        <td>{{ item["leave_day"] }}</td>
                        <td>{{ item["percentage_of_leave"] }}</td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <!-- ///////////////////// -->

        <mdb-card class="mb-4" v-if="leave_data.length > 0">
          <mdb-card-body>
                <h6 class="mb-3">Leave Report</h6>
            <mdb-row>
              <mdb-col>
                <div>
                  <mdb-tbl responsive striped bordered sm>
                    <mdb-tbl-head style="background: #96a6bf; color: #fff">
                      <tr>
                        <td
                          v-for="(item_value, index2) in leave_header"
                          :key="index2"
                        >
                          {{ item_value }}
                        </td>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body>
                      <tr
                        v-for="(leave_item, index) in leave_data"
                        :key="index"
                      >
                        <td
                          v-for="(item_value, index2) in leave_item"
                          :key="index2"
                        >
                          {{ item_value }}
                        </td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card-body>
        </mdb-card>

        <!-- ///////////////////////////// -->

        <mdb-card class="mb-4" v-if="top_leave_employee.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Top Employee's Leaves</h6>

                <div>
                  <mdb-tbl responsive striped bordered sm>
                    <mdb-tbl-head style="background: #96a6bf; color: #fff">
                      <tr>
                        <td>Name</td>
                        <td>Position</td>
                        <td>Department</td>
                        <td>Leave Days</td>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body>
                      <tr
                        v-for="(item, index) in top_leave_employee"
                        :key="index"
                      >
                        <td>{{ item["name"] }}</td>
                        <td>{{ item["position"] }}</td>
                        <td>{{ item["department"] }}</td>
                        <td>{{ item["leave_days"] }}</td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >
        <!-- ///////////////////////////// -->

        <mdb-card class="mb-4" v-if="leave_comparision_data.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Monthly Leave Analysis</h6>

                <mdb-row>
                  <mdb-col>
                    <div>
                      <mdb-tbl responsive striped bordered sm>
                        <mdb-tbl-head style="background: #96a6bf; color: #fff">
                          <tr>
                            <td
                              v-for="(
                                item_value, index
                              ) in leave_comparision_months"
                              :key="index"
                            >
                              {{ item_value }}
                            </td>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr
                            v-for="(
                              leave_item, index
                            ) in leave_comparision_data"
                            :key="index"
                          >
                            <td
                              v-for="(item_value, index2) in leave_item"
                              :key="index2"
                            >
                              {{ item_value }}
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <!-- ///////////////////////// -->

        <!-- ///////////////////////// -->

        <mdb-card class="mb-4" v-if="leave_detail_comparision_data.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Top Employee's Leaves within 6 Months</h6>

                <mdb-row>
                  <mdb-col>
                    <div>
                      <mdb-tbl responsive striped bordered sm>
                        <mdb-tbl-head style="background: #96a6bf; color: #fff">
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td
                              :colspan="leave_detail_comparision_months.length"
                              style="text-align: center"
                            >
                              leave Days
                            </td>
                          </tr>
                          <tr>
                            <td
                              v-for="(
                                item_value, index
                              ) in leave_detail_comparision_months"
                              :key="index"
                            >
                              {{ item_value }}
                            </td>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr
                            v-for="(
                              leave_item, index
                            ) in leave_detail_comparision_data"
                            :key="index"
                          >
                            <td
                              v-for="(item_value, index2) in leave_item"
                              :key="index2"
                            >
                              {{ item_value }}
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <!-- ///////////////////////// -->

        <!-- ///////////////////////////////////////// -->

        <mdb-row
          v-if="
            leaveGraphData.label.length == 0 &&
            leaveRateData.length == 0 &&
            leave_data.length == 0 &&
            top_leave_employee.length == 0 &&
            leave_comparision_data.length == 0 &&
            leave_detail_comparision_data.length == 0
          "
        >
          <mdb-col>
            <mdb-card>
              <mdb-card-body>
                <div class="d-flex justify-content-center">
                  <h5 style="margin-top: 60px">No Record Found.</h5>
                </div>
                <div class="d-flex justify-content-center">
                  <img src="/empty_item.svg" alt="Empty Record" />
                </div>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </template>
    </div>
  </div>
</template>
<script>
import ManagementReport from "@/apis/ManagementReport";
import { mapActions, mapGetters } from "vuex";

import VerticalBarChartView from "@/components/VerticalBarChartView";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    VerticalBarChartView,
  },
  data() {
    return {
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",

      leaveGraphData: false,
      leaveRateData: false,
      leave_header: [],
      leave_data: [],
      top_leave_employee: [],
      leave_comparision_months: [],
      leave_comparision_data: [],

      leave_detail_comparision_months: [],
      leave_detail_comparision_data: [],

      /////////////////////
    };
  },
  props: ["year", "month"],
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    fetchData() {
      this.updateLoading(true);

      const payload = {
        date: this.month + "-" + this.year,
      };

      // const response = {
      //   status: 200,
      //   data: {
      //     leaveGraphData: {
      //       label: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
      //       dataLabel: "No of Employee",
      //       dataSet: [12, 19, 3, 5, 2, 3],
      //     },

      //     leave_header: ["Department", "CL", "EL", "PL"],
      //     leave_data: [
      //       ["lorum ipsuam", 1, 2, 3],
      //       ["lorum ipsuam", 1, 2, 3],
      //       ["lorum ipsuam", 1, 2, 3],
      //       ["lorum ipsuam", 1, 2, 3],
      //       ["lorum ipsuam", 1, 2, 3],
      //     ],

      //     top_leave_employee: [
      //       {
      //         name: "lorum ipsuam",
      //         position: "lorum ipsuam",
      //         department: "lorum ipsuam",
      //         leave_days: 30,
      //       },
      //       {
      //         name: "lorum ipsuam",
      //         position: "lorum ipsuam",
      //         department: "lorum ipsuam",
      //         leave_days: 30,
      //       },
      //     ],

      //     leave_comparision_months: ["January", "February", "March"],

      //     leave_comparision_data: [
      //       ["dep 1", 1, 2, 3],
      //       ["dep 2", 1, 2, 3],
      //       ["dep 3", 1, 2, 3],
      //     ],

      //     leave_detail_comparision_data: [
      //       ["lorum name", "lorum position", "lorum department", 1, 2, 3],
      //       ["lorum name", "lorum position", "lorum department", 1, 2, 3],
      //       ["lorum name", "lorum position", "lorum department", 1, 2, 3],
      //     ],
      //   },
      // };

      // this.leaveGraphData = response.data.leaveGraphData;
      // this.leave_header = response.data.leave_header;
      // this.leave_data = response.data.leave_data;
      // this.top_leave_employee = response.data.top_leave_employee;
      // this.leave_comparision_months = response.data.leave_comparision_months;
      // this.leave_comparision_data = response.data.leave_comparision_data;
      // this.leave_detail_comparision_data =
      //   response.data.leave_detail_comparision_data;

      ManagementReport.leaveGraphData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.leaveGraphData = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.leaveRateData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.leaveRateData = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.leaveData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.leave_header = response.data.data.leave_header;
            this.leave_data = response.data.data.leave_data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.leaveTopEmployee(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.top_leave_employee = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.leaveMonthlyComparison(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.leave_comparision_months =
              response.data.data.comparision_header;
            this.leave_comparision_data = response.data.data.comparision_data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.leaveTopMonthlyEmployee(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.leave_detail_comparision_months =
              response.data.data.comparision_detail_header;
            this.leave_detail_comparision_data =
              response.data.data.comparision_detail_data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });
    },

    validateParams() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (
        !months.find((month) => month.toLowerCase() == this.month.toLowerCase())
      ) {
        this.$router.push({ name: "managementReport" });
      }

      if (!/^\d{4}$/.test(this.year)) {
        this.$router.push({ name: "managementReport" });
      }

      this.fetchData();
    },
  },
  created() {
    this.validateParams();
  },
};
</script>

<style scoped>
.rotate_text {
  transform: rotate(180deg);

  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(180deg);

  /* Firefox */
  -moz-transform: rotate(180deg);

  /* IE */
  -ms-transform: rotate(180deg);

  /* Opera */
  -o-transform: rotate(180deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
</style>