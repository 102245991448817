<template>
  <form @submit.prevent="handleFormSubmit">
    <mdb-row>
      <mdb-col md="4"  class="form-inline-search">
        <mdb-input
          type="text"
          :label="$t('general.formInput.keywords')"
          v-model="filterForm.keywords"
          outline
        />
      </mdb-col>
      
      <mdb-col md="4" class="form-inline-search">
        <mdb-date-picker-2
          v-model="filterForm.date_from"
          :label="$t('general.formInput.start_date')"
        />
      </mdb-col>

      <mdb-col md="4" class="form-inline-search">
        <mdb-date-picker-2
          v-model="filterForm.date_to"
          :label="$t('general.formInput.end_date')"
        />
      </mdb-col>

      <mdb-col md="4" class="form-inline-search">

          <LeaveTypeDropdown
                  @updateLeaveTypeID="setLeaveTypeID"
                  :default_selected_value="
                    filterForm.leave_type
                  "
                  :required="false"
                />
                
      </mdb-col>

      <mdb-col md="4" class="form-inline-search">
                  <mdb-select
                    v-model="approvalStatusOption"
                    placeholder=""
                    label="Status"
                    outline
                    @getValue="updateApprovalStatus"
                  />

                </mdb-col>
    </mdb-row>

    <mdb-row class="mb-4">
      <mdb-col>
        <mdb-btn
          type="button"
          color="danger"
          class="btn_submit"
          size="sm"
          @click="resetForm"
        >
          <mdb-icon icon="undo" size="md" />
          {{ $t("general.button.reset") }}
        </mdb-btn>
        <mdb-btn type="Search" color="uno-color" class="btn_submit" size="sm">
          <mdb-icon icon="file-upload" size="md" />

          {{ $t("general.button.submit") }}
        </mdb-btn>
      </mdb-col>
    </mdb-row>
  </form>
</template>

<script>
import { approvalStatusOption } from "@/constants/employee.js";
import LeaveTypeDropdown from "@/components/LeaveTypeDropdown";
import {
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  mdbSelect
} from "mdbvue";

export default {
  components: {
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    mdbDatePicker2,
    mdbSelect,
    LeaveTypeDropdown
  },
  props: ["filterFormData"],
  data() {
    return {
      filterForm: {
        keywords: "",
        date_from: "",
        date_to: "",
        leave_type:"",
        status:"",
      },
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,
      
      approvalStatusOption: approvalStatusOption(true, "pending"),
    };
  },

  methods: {
    updateApprovalStatus(val) {
      this.filterForm.status = val;
    },

    
    setLeaveTypeID(val) {
      this.filterForm.leave_type = val;
    },

    handleFormSubmit() {
      this.$emit("submitFilterForm", this.filterForm);
    },

    resetForm() {
      Object.keys(this.filterForm).forEach((key) => {
        this.filterForm[key] = "";
      });
      this.approvalStatusOption= approvalStatusOption(true, "pending")
      this.doesFormReset = !this.doesFormReset;
      this.$emit("submitFilterForm", this.filterForm);
    },
  },

  created() {
    this.filterForm = this.filterFormData;
  },
};
</script>