<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">
        <PayslipPublishingSearchForm
          @submitFilterForm="handleSubmitFilterForm"
          :filterFormData="searchFormData"
        />

        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn
                size="sm"
                color="uno-color"
                class="btn_submit"
                v-show="searchFormData.publishing == 'unpublish'"
                @click.native="modalOpen('publish')"
                :disabled="actionSubmitted || !shouldActionButtonShow"
              >
                <mdb-icon
                  icon="trash-alt"
                  far
                  size="md"
                  v-show="!actionSubmitted"
                />

                <span
                  class="spinner-border-vcenter spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-show="actionSubmitted"
                ></span>

                Publish Selected
              </mdb-btn>

              <mdb-btn
                size="sm"
                color="uno-color"
                class="btn_submit"
                @click.native="modalOpen('unpublish')"
                v-show="searchFormData.publishing == 'publish'"
                :disabled="actionSubmitted || !shouldActionButtonShow"
              >
                <mdb-icon
                  icon="trash-alt"
                  far
                  size="md"
                  v-show="!actionSubmitted"
                />

                <span
                  class="spinner-border-vcenter spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-show="actionSubmitted"
                ></span>

                Unpublish Selected
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <!-- ///////////////////////////////// -->

            <div class="list-group">
              <mdb-tbl responsive hover>
                <!-- Table head -->
                <mdb-tbl-head>
                  <tr>
                    <th>
                      <!-- Material unchecked -->
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="trmc_all"
                          @change="checkAllEmployees"
                          v-model="isCheckAll"
                        />
                        <label class="form-check-label" for="trmc_all"></label>
                      </div>
                    </th>
                    <th></th>
                    <th @click="sort('name')">
                      <i
                        v-show="currentSort === 'name'"
                        :class="[
                          currentSortDir === 'asc'
                            ? 'fas fa-arrow-up '
                            : 'fas fa-arrow-down ',
                        ]"
                        aria-hidden="true"
                      ></i>
                      {{ $t("general.formInput.name") }}
                    </th>
                    <th @click="sort('position')">
                      <i
                        v-show="currentSort === 'position'"
                        :class="[
                          currentSortDir === 'asc'
                            ? 'fas fa-arrow-up '
                            : 'fas fa-arrow-down ',
                        ]"
                        aria-hidden="true"
                      ></i>
                      {{ $t("general.formInput.jp_name") }}
                    </th>

                    <th @click="sort('department')">
                      <i
                        v-show="currentSort === 'department'"
                        :class="[
                          currentSortDir === 'asc'
                            ? 'fas fa-arrow-up '
                            : 'fas fa-arrow-down ',
                        ]"
                        aria-hidden="true"
                      ></i>
                      {{ $t("general.formInput.department_name") }}
                    </th>

                    <th @click="sort('rank')">
                      <i
                        v-show="currentSort === 'rank'"
                        :class="[
                          currentSortDir === 'asc'
                            ? 'fas fa-arrow-up '
                            : 'fas fa-arrow-down ',
                        ]"
                        aria-hidden="true"
                      ></i>
                      Rank
                    </th>
                    <th>Status</th>
                  </tr>
                </mdb-tbl-head>
                <!-- Table head -->

                <!-- Table body -->
                <mdb-tbl-body>
                  <tr v-for="(employee, index) in pageOfItems" :key="index">
                    <th scope="row">
                      <!-- Material unchecked -->
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          :id="'trmc_' + employee.id"
                          :value="employee"
                          v-model="selectedEmployees"
                          @change="updateCheckall"
                        />
                        <label
                          class="form-check-label"
                          :for="'trmc_' + employee.id"
                        ></label>
                      </div>
                    </th>

                    <td @click="rowClickEvent(employee)">
                      <img
                        :src="employee.image"
                        class="rounded-circle float-left"
                        width="50"
                        height="50"
                        v-if="employee.image"
                        @error="imageLoadError"
                      />
                    </td>
                    <td @click="rowClickEvent(employee)">
                      {{ employee.name }}
                    </td>
                    <td @click="rowClickEvent(employee)">
                      {{ employee.position }}
                    </td>
                    <td @click="rowClickEvent(employee)">
                      {{ employee.department }}
                    </td>
                    <td @click="rowClickEvent(employee)">
                      {{ employee.rank }}
                    </td>
                    <td @click="rowClickEvent(employee)">
                      {{ employee.status }}
                    </td>
                  </tr>
                </mdb-tbl-body>
                <!-- Table body -->
              </mdb-tbl>
              <div class="card-footer pb-0 pt-3">
                <jw-pagination
                  :items="employees"
                  @changePage="onChangePage"
                  :pageSize="rowsPerPage"
                ></jw-pagination>
              </div>
            </div>
            <!-- /////////////////////////////////  -->
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>

    <mdb-modal :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Confirmation !</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <p>Are you sure to {{ submit_action }} all selected record ?</p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
        <mdb-btn color="primary" @click="confirmAction" size="sm">Yes</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import Vue from "vue";
import JwPagination from "jw-vue-pagination";
Vue.component("jw-pagination", JwPagination);

import Payroll from "@/apis/Payroll";
import { mapActions, mapGetters } from "vuex";

import PayslipPublishingSearchForm from "@/components/search/PayslipPublishingSearchForm";
import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    PayslipPublishingSearchForm,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
  },

  data() {
    return {
      pageOfItems: [],
      rowsPerPage: this.$store.state.rowsPerPage,
      currentSort: "holiday_name",
      currentSortDir: "asc",
      selectedEmployees: [],
      isCheckAll: false,

      employees: [],

      searchFormData: {
        keywords: "",
        department_id: "",
        filter_year: "",
        filter_month: "",
        jp_id: "",
        employee_grades: "",
        publishing: "",
      },
      feedback_error: false,
      feedback_message: "",

      modal: false,
      actionSubmitted: false,

      submit_action: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    shouldActionButtonShow() {
      return this.selectedEmployees.length > 0 ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    imageLoadError(event) {
      event.target.src = "/default-user.png";
    },

    modalOpen(action) {
      this.modal = true;
      this.submit_action = action;
    },

    confirmAction() {
      this.actionSubmitted = true;

      let payload = {
        //filter data
        date:
          this.searchFormData.filter_month +
          "-" +
          this.searchFormData.filter_year,
        keywords: this.searchFormData.keywords,
        department_id: this.searchFormData.department_id,
        jp_id: this.searchFormData.jp_id,
        rt_id: this.searchFormData.employee_grades,
        status: this.searchFormData.publishing,

        // action data
        action_status: this.submit_action,
        select_all: "yes",
        payroll_ids: [],
      };
      if (this.isCheckAll == false) {
        payload.select_all = "no";
        for (let employee of this.selectedEmployees) {
          payload.payroll_ids.push(employee.id);
        }
      }

      Payroll.publishingAction(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            this.actionSubmitted = false;

            this.selectedEmployees= [];
            this.isCheckAll= false;
            this.submit_action="";

            this.fetchRecordList();

            this.modal = false;
          } else if (response.data.code === 500) {
            this.actionSubmitted = false;
            this.feedback_message = "Error";
            this.feedback_error = true;

            this.modal = false;
          }
        }
      });
    },

    handleSubmitFilterForm(val) {
      this.searchFormData = val;
      this.fetchRecordList();
    },

    fetchRecordList() {
      this.updateLoading(true);

      let params = {};

      if (
        this.searchFormData.filter_month == "" ||
        this.searchFormData.filter_year == ""
      ) {
        params = {};
      } else {
        params = {
          date:
            this.searchFormData.filter_month +
            "-" +
            this.searchFormData.filter_year,
          department_id: this.searchFormData.department_id,
          keywords: this.searchFormData.keywords,
          status: this.searchFormData.publishing,
          rt_id: this.searchFormData.employee_grades,
          jp_id: this.searchFormData.jp_id,
        };
      }

      Payroll.publishingList(params)
        .then((response) => {
          if (response.status === 200) {
            this.employees = response.data.data;

            this.searchFormData.keywords = response.data.filters.keywords;
            this.searchFormData.department_id =
              response.data.filters.department_id;
            this.searchFormData.filter_year = response.data.filters.year;
            this.searchFormData.filter_month = response.data.filters.month;
            this.searchFormData.jp_id = response.data.filters.jp_id;
            this.searchFormData.employee_grades = response.data.filters.rt_id;
            this.searchFormData.publishing =
              response.data.filters.status.toLowerCase();

            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },

    //////////////////////////
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    rowClickEvent(val) {
      // console.log(val)
      const found = this.selectedEmployees.find(function (e) {
        if (e.id == val.id) return true;
      });

      if (found == undefined) {
        this.selectedEmployees.push(val);
      } else {
        const index = this.selectedEmployees.indexOf(val);
        if (index > -1) {
          this.selectedEmployees.splice(index, 1);
        }
      }
      this.updateCheckall();
    },
    checkAllEmployees() {
      this.selectedEmployees = [];
      if (this.isCheckAll) {
        for (let employee of this.employees) {
          this.selectedEmployees.push(employee);
        }
      }
    },
    updateCheckall() {
      // console.log(this.employees)
      if (this.employees.length == this.selectedEmployees.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    ////////////////////////////
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
