import Api from './api'

export default {
    all(payload) {
        return Api.post('api/employees/user_advance_search', payload)
    },

    activeUsers() {
        return Api.post('api/employees/active_user')
    },

    save(payload) {
        return Api.post('api/employees/data_entry', payload)
    },

    delete(payload) {
        return Api.post('api/employees/delete', payload)
    },

    view(payload) {
        return Api.get('api/employees/' + payload)
    },

    viewShortInfoForBreadCrumb(payload){
        return Api.get('api/employees/short_info/' + payload)
    },

    viewPersonalInfo(payload) {
        return Api.get('api/employees/personal_info/' + payload)
    },

    updatePersonalInfo(payload) {
        return Api.post('api/employees/personal_info/data_entry', payload)
    },

    viewTaxSSBInfo(payload) {
        return Api.get('api/employees/view_tax_ssb_info/' + payload)
    },
    updateTaxSSBInfo(payload) {
        return Api.post('api/employees/add_tax_ssb_info', payload)
    },

    viewApprovers(payload) {
        return Api.get('api/employees/approver_list/' + payload)
    },

    viewApprovals(payload) {
        return Api.get('api/employees/approval_list/' + payload)
    },


    updateEmployeeCode(payload){
        return Api.post('api/employees/change_employee_code', payload)
    },

    
    updateEmployeeJoinDate(payload){
        return Api.post('api/employees/change_join_date', payload)
    },

    
    updateEmployeeEmailAndPassword(payload){
        return Api.post('api/employees/change_email_password', payload)
    },

    exportEmployeeReport(payload) {
        return Api.get('api/employees/export' , {params: payload})
    },

    LogoutUserSession(payload){
        return Api.get('api/employees/invalidate_all_token' , {params: payload})
    }    

}