<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card
          class="mb-4"
          v-if="salaryGraphData.label.length > 0 || salary_data.length > 0"
        >
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Salary Report</h6>

                <div>
                  <VerticalBarChartView
                    v-if="salaryGraphData && salaryGraphData.label.length > 0"
                    :barGraphData="salaryGraphData"
                    
                    :xlabel="'Departments'"
                    :ylabel="'Total Amount'"
                  />
                </div>
                <br />
                <br />
                <mdb-row v-if="salary_data.length > 0">
                  <mdb-col>
                    <div>
                      <mdb-tbl responsive striped bordered sm>
                        <mdb-tbl-head style="background: #96a6bf; color: #fff">
                          <tr>
                            <td>Department</td>
                            <td>Man Power</td>
                            <td>Salary</td>
                            <td>Leave</td>
                            <td>SSB</td>
                            <td>Tax</td>
                            <td>Deduction</td>
                            <td>Fine</td>
                            <td>Overtime</td>
                            <td>Addition</td>
                            <td>Allowance</td>
                            <td>Total Amount</td>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr v-for="(item, index) in salary_data" :key="index">
                            <td>{{ item["department"] }}</td>
                            <td>{{ item["man_power"]  | formatNumbers }}</td>
                            <td>{{ item["salary"]  | formatNumbers }}</td>
                            <td>{{ item["leave"]  | formatNumbers }}</td>
                            <td>{{ item["ssb"]  | formatNumbers }}</td>
                            <td>{{ item["tax"]  | formatNumbers }}</td>
                            <td>{{ item["deduction"]  | formatNumbers }}</td>
                            <td>{{ item["fine"]  | formatNumbers }}</td>
                            <td>{{ item["overtime"]  | formatNumbers }}</td>
                            <td>{{ item["addition"]  | formatNumbers }}</td>
                            <td>{{ item["allowance"]  | formatNumbers }}</td>
                            <td>{{ item["total_amount"]  | formatNumbers }}</td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <!-- ///////////////////// -->

        <mdb-card class="mb-4" v-if="salary_comparision_data.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Monthly Salary Comparison</h6>

                <mdb-row>
                  <mdb-col>
                    <div>
                      <mdb-tbl responsive striped bordered sm>
                        <mdb-tbl-head style="background: #96a6bf; color: #fff">
                          <tr>
                            <td>Month</td>
                            <td>Man Power</td>
                            <td>Salary</td>
                            <td>Leave</td>
                            <td>SSB</td>
                            <td>Tax</td>
                            <td>Deduction</td>
                            <td>Fine</td>
                            <td>Overtime</td>
                            <td>Addition</td>
                            <td>Allowance</td>
                            <td>Total Amount</td>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr
                            v-for="(item, index) in salary_comparision_data"
                            :key="index"
                          >
                            <td>{{ item["month"] }}</td>
                            <td>{{ item["man_power"]  | formatNumbers }}</td>
                            <td>{{ item["salary"]  | formatNumbers }}</td>
                            <td>{{ item["leave"]  | formatNumbers }}</td>
                            <td>{{ item["ssb"]  | formatNumbers }}</td>
                            <td>{{ item["tax"]  | formatNumbers }}</td>
                            <td>{{ item["deduction"]  | formatNumbers }}</td>
                            <td>{{ item["fine"]  | formatNumbers }}</td>
                            <td>{{ item["overtime"]  | formatNumbers }}</td>
                            <td>{{ item["addition"]  | formatNumbers }}</td>
                            <td>{{ item["allowance"]  | formatNumbers }}</td>
                            <td>{{ item["total_amount"]  | formatNumbers }}</td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <!-- ///////////////////////// -->

        <mdb-row
          v-if="
            salaryGraphData.label.length == 0 &&
            salary_data.length == 0 &&
            salary_comparision_data.length == 0
          "
        >
          <mdb-col>
            <mdb-card>
              <mdb-card-body>
                <div class="d-flex justify-content-center">
                  <h5 style="margin-top: 60px">No Record Found.</h5>
                </div>
                <div class="d-flex justify-content-center">
                  <img src="/empty_item.svg" alt="Empty Record" />
                </div>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </template>
    </div>
  </div>
</template>
<script>
import ManagementReport from "@/apis/ManagementReport";
import { mapActions, mapGetters } from "vuex";

import VerticalBarChartView from "@/components/VerticalBarChartView";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    VerticalBarChartView,
  },
  data() {
    return {
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",

      salaryGraphData: false,

      ////////////////////
      salary_data: [],
      salary_comparision_data: [],
      /////////////////////
    };
  },
  props: ["year", "month"],
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    fetchData() {
      this.updateLoading(true);

      const payload = {
        date: this.month + "-" + this.year,
      };

      // const response = {
      //   status: 200,
      //   data: {
      //     salaryGraphData: {
      //       label: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
      //       dataLabel: "No of Employee",
      //       dataSet: [12, 19, 3, 5, 2, 3],
      //     },
      //     salary_data: [
      //       {
      //         department: "lorum ipsuam",
      //         man_power: 10,
      //         salary: 6,
      //         leave: 50,
      //         ssb: 30333,
      //         tax: 30333,
      //         deduction: 30333,
      //         fine: 30333,
      //         overtime: 30333,
      //         addition: 30333,
      //         allowance: 30333,
      //         total_amount: 30333,
      //       },
      //       {
      //         department: "lorum ipsuam",
      //         man_power: 10,
      //         salary: 6,
      //         leave: 50,
      //         ssb: 30333,
      //         tax: 30333,
      //         deduction: 30333,
      //         fine: 30333,
      //         overtime: 30333,
      //         addition: 30333,
      //         allowance: 30333,
      //         total_amount: 30333,
      //       },
      //       {
      //         department: "lorum ipsuam",
      //         man_power: 10,
      //         salary: 6,
      //         leave: 50,
      //         ssb: 30333,
      //         tax: 30333,
      //         deduction: 30333,
      //         fine: 30333,
      //         overtime: 30333,
      //         addition: 30333,
      //         allowance: 30333,
      //         total_amount: 30333,
      //       },
      //     ],

      //     salary_comparision_data: [
      //       {
      //         department: "January",
      //         man_power: 10,
      //         salary: 6,
      //         leave: 50,
      //         ssb: 30333,
      //         tax: 30333,
      //         deduction: 30333,
      //         fine: 30333,
      //         overtime: 30333,
      //         addition: 30333,
      //         allowance: 30333,
      //         total_amount: 30333,
      //       },
      //       {
      //         department: "February",
      //         man_power: 10,
      //         salary: 6,
      //         leave: 50,
      //         ssb: 30333,
      //         tax: 30333,
      //         deduction: 30333,
      //         fine: 30333,
      //         overtime: 30333,
      //         addition: 30333,
      //         allowance: 30333,
      //         total_amount: 30333,
      //       },
      //       {
      //         department: "March",
      //         man_power: 10,
      //         salary: 6,
      //         leave: 50,
      //         ssb: 30333,
      //         tax: 30333,
      //         deduction: 30333,
      //         fine: 30333,
      //         overtime: 30333,
      //         addition: 30333,
      //         allowance: 30333,
      //         total_amount: 30333,
      //       },
      //     ],
      //   },
      // };

      // this.salaryGraphData = response.data.salaryGraphData;
      // this.salary_data = response.data.salary_data;
      // this.salary_comparision_data = response.data.salary_comparision_data;

      ManagementReport.salaryGraphData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.salaryGraphData = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.salaryData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.salary_data = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.salaryMonthlyComparison(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.salary_comparision_data = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });
    },

    validateParams() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (
        !months.find((month) => month.toLowerCase() == this.month.toLowerCase())
      ) {
        this.$router.push({ name: "managementReport" });
      }

      if (!/^\d{4}$/.test(this.year)) {
        this.$router.push({ name: "managementReport" });
      }

      this.fetchData();
    },
  },
  created() {
    this.validateParams();
  },
};
</script>

<style scoped>
.rotate_text {
  transform: rotate(180deg);

  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(180deg);

  /* Firefox */
  -moz-transform: rotate(180deg);

  /* IE */
  -ms-transform: rotate(180deg);

  /* Opera */
  -o-transform: rotate(180deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
</style>