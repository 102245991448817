<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <DocumentTypeDropdown
                    @updateDocumentTypeID="setDocumentTypeID"
                    :default_selected_value="document_type"
                    :required="true"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <DepartmentDropdown
                    @updateDepartmentID="setDepartmentID"
                    :default_selected_value="department_id"
                    :required="false"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>

              <mdb-row>
                 <mdb-col offsetMd="2" md="8" class="my-3"
                  v-if="download_info && Object.keys(download_info).length > 0"
                >
                  <a :href="download_info.file_url" target="_blank">
                    Download Zip File
                  </a>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
  </div>
</template>
<script>
import Report from "@/apis/Report";
import DepartmentDropdown from "@/components/DepartmentDropdown";
import DocumentTypeDropdown from "@/components/DocumentTypeDropdown";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    DepartmentDropdown,
    DocumentTypeDropdown,
  },
  data() {
    return {
      download_info: {},
      department_id: "",
      document_type: "",
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    setDocumentTypeID(val) {
      this.document_type = val;
    },

    setDepartmentID(val) {
      this.department_id = val;
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      const payload = {
        department_id: this.department_id,
        document_type: this.document_type,
      };

      this.download_info= {}

      Report.documentDownload(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.download_info = {
              file_name: response.data.file_name,
              file_url: response.data.file_url,
              file_path: response.data.file_path,
            };

            this.$notify.success({
              message:
                "File Creation complete. Click download button to start download your file.",
              position: "top right",
              timeOut: 5000,
            });

            this.formSubmitting = false;
          }
        }

        //   if (response.status === 200) {
        //     let today = new Date();
        //     let [month, date, year] = today
        //       .toLocaleDateString("en-US")
        //       .split("/");
        //     let [hour, minute, second] = today
        //       .toLocaleTimeString("en-US")
        //       .split(/:| /);
        //     let today_string =
        //       year + month + date + "_" + hour + minute + second;

        //     var blob = new Blob([response.data]);
        //     var link = document.createElement("a");
        //     link.href = window.URL.createObjectURL(blob);
        //     link.download = "document" + "_" + today_string + ".zip";
        //     link.click();

        //     this.formSubmitting = false;
        //   }
      });
    },

    resetAlert() {
      this.feedback_error = false;
    },
  },
  created() {},
};
</script>