<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">
        <mdb-card>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th>
                    {{ $t("payrolls.formInput.rq_payroll_date") }}
                  </th>
                  <th>
                    {{ $t("payrolls.formInput.rq_calculate_type") }}
                  </th>

                  <th>
                    {{ $t("payrolls.formInput.summary_info") }}
                  </th>
                  <th>
                    {{ $t("payrolls.formInput.rq_status") }}
                  </th>
                  <th width="120" class="text-right">
                    {{ $t("general.formInput.created") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(list_item, index) in queue_list.data"
                  :key="index"
                >
                  <td scope="row">
                    {{ list_item.rq_payroll_date | formatMonthYearString }}
                  </td>

                  <td scope="row">
                    {{ list_item.rq_calculate_type }}
                  </td>

                  <td scope="row">
                    <div v-if="list_item.rq_calculate_type == 'batch'">
                      Department : {{ list_item.rq_department_id }}
                      <br />
                      Ranks : {{ list_item.rq_rank_types }}
                    </div>
                    <div v-else-if="list_item.rq_calculate_type == 'single'">
                      {{ list_item.employe_info.epinfo_name }}<br />
                      {{ list_item.employe_info.employee_code }}<br />
                      {{ list_item.employe_info.jp_name }}
                      ({{ list_item.employe_info.rt_name }})<br />
                      {{ list_item.employe_info.department_name }}<br />
                    </div>
                  </td>

                  <td scope="row">
                    {{ list_item.rq_status }}
                  </td>

                  <td class="text-right">
                    {{ list_item.rq_created_at | formatDateString }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <pagination
              :data="queue_list"
              @pagination-change-page="fetchRecordList"
              :limit="2"
            ></pagination>
          </mdb-card-body>
        </mdb-card>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
Vue.component("pagination", require("laravel-vue-pagination"));
import Payroll from "@/apis/Payroll";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },

  data() {
    return {
      queue_list: [],
      currentSort: "",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      const params = {
        page: page,
      };

      Payroll.requestQueuelist(params)
        .then((response) => {
          if (response.status === 200) {
            this.queue_list = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
