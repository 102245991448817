<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row class="mt-3">
                <mdb-col offsetMd="2" md="8">
                  <p class="h6 text-color-primary">
                    {{ $t("employees.text.general_info") }}
                  </p>
                </mdb-col>
              </mdb-row>
            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                  
                  <mdb-file-input
                    @getValue="getFileInputValue"
                    :width="200"
                    btnColor="mdb-color"
                    rounded
                    accept="image/*"
                    btn-title="Profile Photo"                    
                    :key="logoFileInputKey"
                    img
                    :src="employee.epinfo_image!='' ? employee.epinfo_image : '/placeholder.jpg'"
                  />
               

                </mdb-col>
            </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="employee.epinfo_name"
                    outline
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.second_name')"
                    v-model="employee.epinfo_second_name"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.email')"
                    v-model="employee.epinfo_email"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.phone_no')"
                    v-model="employee.epinfo_phone_no"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.extension_no')"
                    v-model="employee.epinfo_extension_no"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <label>{{ $t("general.formInput.gender") }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="epinfo_gender-male"
                      name="epinfo_gender"
                      radioValue="male"
                      v-model="employee.epinfo_gender"
                      :label="$t('employees.formInput.male')"
                    />
                    <mdb-input
                      type="radio"
                      id="epinfo_gender-female"
                      name="epinfo_gender"
                      radioValue="female"
                      v-model="employee.epinfo_gender"
                      :label="$t('employees.formInput.female')"
                    />
                  </mdb-form-inline>
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    v-model="employee.epinfo_date_of_birth"
                    :label="$t('employee_info.formInput.date_of_birth')"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('employee_info.formInput.place_of_birth')"
                    v-model="employee.epinfo_place_of_birth"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.country_of_birth')"
                    v-model="employee.epinfo_country_of_birth"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.nationality')"
                    v-model="employee.epinfo_nationlity"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.race')"
                    v-model="employee.epinfo_race"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.religion')"
                    v-model="employee.epinfo_religion"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-select
                    v-model="martialStatusOption"
                    placeholder="Select Marital status"
                    label=""
                    outline
                    @getValue="updateMartialStatusValue"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="number"
                    :label="$t('general.formInput.no_of_child')"
                    v-model="employee.epinfo_no_of_child"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.blood_type')"
                    v-model="employee.epinfo_blood_type"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.oblivious_mark')"
                    v-model="employee.epinfo_oblivious_mark"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.hair_color')"
                    v-model="employee.epinfo_hair_color"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.eye_color')"
                    v-model="employee.epinfo_eye_color"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.height')"
                    v-model="employee.epinfo_height"
                    outline
                  />
                </mdb-col>



                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    label="Shirt Size"
                    v-model="employee.epinfo_shirt_size"
                    outline
                  />
                </mdb-col>

                
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    label="Pant Size"
                    v-model="employee.epinfo_pant_size"
                    outline
                  />
                </mdb-col>

                
                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    label="Shoes Size"
                    v-model="employee.epinfo_shoes_size"
                    outline
                  />
                </mdb-col>

              </mdb-row>

              <mdb-row class="mt-3">
                <mdb-col offsetMd="2" md="8">
                  <p class="h6 text-color-primary">
                    {{ $t("employees.text.id_info") }}
                  </p>
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.id_number')"
                    v-model="employee.epinfo_id_number"
                    outline
                  />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.passport_no')"
                    v-model="employee.epinfo_passport_no"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.place_of_issue')"
                    v-model="employee.epinfo_passport_place_of_issue"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    v-model="employee.epinfo_passport_date_of_issue"
                    :label="$t('general.formInput.date_of_issue')"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-date-picker-2
                    v-model="employee.epinfo_passport_date_of_expiry"
                    :label="$t('general.formInput.date_of_expiry')"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.visa_type')"
                    v-model="employee.epinfo_visa_type"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-date-picker-2
                    v-model="employee.epinfo_visa_validity"
                    :label="$t('general.formInput.visa_validity')"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    v-model="employee.epinfo_driving_license_expiry"
                    :label="$t('general.formInput.driving_license_expiry')"
                    title="Select date"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row class="mt-3">
                <mdb-col offsetMd="2" md="8">
                  <p class="h6 text-color-primary">
                    {{ $t("employees.text.emergency_contact") }}
                  </p>
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.emergency_contact_name')"
                    v-model="employee.epinfo_emergency_contact_name"
                    outline
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.emergency_phone_no')"
                    v-model="employee.epinfo_emergency_phone_no"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row class="mt-3">
                <mdb-col offsetMd="2" md="8">
                  <p class="h6 text-color-primary">
                    {{ $t("employees.text.current_address") }}
                  </p>
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.address')"
                    v-model="employee.epinfo_current_address"
                    outline
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.township')"
                    v-model="employee.epinfo_current_township"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.state')"
                    v-model="employee.epinfo_current_state"
                    outline
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.country')"
                    v-model="employee.epinfo_current_country"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row class="mt-3">
                <mdb-col offsetMd="2" md="8">
                  <p class="h6 text-color-primary">
                    {{ $t("employees.text.permanent_address") }}
                  </p>
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.address')"
                    v-model="employee.epinfo_permanent_address"
                    outline
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.township')"
                    v-model="employee.epinfo_permanent_township"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.state')"
                    v-model="employee.epinfo_permanent_state"
                    outline
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.country')"
                    v-model="employee.epinfo_permanent_country"
                    outline
                  />
                </mdb-col>
              </mdb-row>


               <mdb-row class="mt-3">
                <mdb-col offsetMd="2" md="8">
                  <p class="h6 text-color-primary">
                    Bank Infomation
                  </p>
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    label="Name in Bank Account"
                    v-model="employee.epinfo_name_bank"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
  </div>
</template>
<script>
import Employee from "@/apis/Employee";
import { martialStatusOption } from "@/constants/employee.js";
import LogList from "@/components/logs/List";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  mdbSelect,
  mdbFormInline,
  mdbFileInput
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbDatePicker2,
    mdbSelect,
    mdbFormInline,
    mdbFileInput
  },
  props: ["employee_id"],
  data() {
    return {
      employee: {},
      logs: [],
      martialStatusOption: martialStatusOption(true, ""),
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      logoFileInputKey: 0,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),
    updateMartialStatusValue(val) {
      this.employee.epinfo_martial_status = val;
    },
    getFileInputValue(val) {
      this.employee.epinfo_image = val[0];
    },

    fetchRecord() {
      if (this.employee_id != undefined) {
        if (Math.sign(this.employee_id) === 1) {
          this.updateLoading(true);
          Employee.viewPersonalInfo(this.employee_id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {

                  let fetch_data = response.data.data;
                  Object.keys(fetch_data).forEach(function (key) {
                    if(key =='epinfo_created' || key =='epinfo_updated'){
                      delete fetch_data[key]
                    }else{
                      if (fetch_data[key] === null) {
                        fetch_data[key] = "";
                      }
                    }
                    
                  });
                  this.employee =fetch_data
                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.martialStatusOption = martialStatusOption(
                    true,
                    response.data.data.epinfo_martial_status
                  );

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listEmployee" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listEmployee" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listEmployee" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.formSubmitting = true;

      let formData = new FormData();

      Object.entries(this.employee).forEach(([key, value]) => {
        if(key != 'epinfo_image'){          
          formData.append(key, value);
        }
      });

      if(this.employee.epinfo_image  && this.employee.epinfo_image != ''){
        formData.append("epinfo_image", this.employee.epinfo_image);
      }

      // formData.append("company_ida", '1111');
      // formData.append("company_idb", '2222');
      // formData.append("company_idc", '333');

      // console.log(formData);
      // this.formSubmitting = false;
      

      Employee.updatePersonalInfo(formData).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>