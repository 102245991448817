<template>
  <div>
    <mdb-horizontal-bar-chart
      :data="barChartData"
      :options="horizontalBarChartOptions"
      :width="1040"
      :height="300"
    >
    </mdb-horizontal-bar-chart>
  </div>
</template>

<script>
import { mdbHorizontalBarChart } from "mdbvue";

export default {
  name: "HorizontalBarChartView",
  components: {
    mdbHorizontalBarChart,
  },
  props: ["barGraphData", "ylabel", "xlabel"],
  data() {
    return {
      /////////////
      barChartData: {
        labels: this.barGraphData.label,
        datasets: [
          {
            // label: this.barGraphData.dataLabel,
            data: this.barGraphData.dataSet,
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
        ],
      },
      horizontalBarChartOptions: {
        responsive: false,
        maintainAspectRatio: false,
         legend: {
          position: "bottom",
          display:false
        },
        scales: {
          xAxes: [
            {
               ticks: {
                    beginAtZero: true
                },
              barPercentage: 1,
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)",
              },
              scaleLabel: {
                display: true,
                labelString: this.xlabel!=undefined ? this.xlabel : ''
              }
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)",
              },
               scaleLabel: {
                display: true,
                labelString: this.ylabel!=undefined ? this.ylabel : ''
              }
            },
          ],
        },
      },

      ////////////
    };
  },
};
</script>