<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">
        <PayslipSearchForm
          @submitFilterForm="handleSubmitFilterForm"
          :filterFormData="searchFormData"
        />

        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn
                color="uno-color"
                class="btn_submit"
                @click="createNewRecord"
                size="sm"
              >
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>

              <mdb-btn
                size="sm"
                color="uno-color"
                class="btn_delete"
                v-show="shouldActionButtonShow"
                @click.native="modalOpen()"
                :disabled="actionSubmitted"
              >
                <mdb-icon
                  icon="trash-alt"
                  far
                  size="md"
                  v-show="!actionSubmitted"
                />

                <span
                  class="spinner-border-vcenter spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-show="actionSubmitted"
                ></span>

                Delete Selected
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th width="50">
                    <!-- Material unchecked -->
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="payroll_all"
                        @change="checkAllRecord"
                        v-model="isCheckAll"
                      />
                      <label class="form-check-label" for="payroll_all"></label>
                    </div>
                  </th>
                  <th></th>
                  <th>
                    {{ $t("payslips.label.date") }}
                  </th>
                  <th>
                    {{ $t("payslips.label.attendance") }}
                  </th>
                  <th>
                    {{ $t("payslips.label.leaves") }}
                  </th>
                  <th>
                    {{ $t("payslips.label.absent") }}
                  </th>
                  <th>
                    {{ $t("payslips.label.overtime") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(payslip, index) in payslip_list.data"
                  :key="index"
                >
                  <td scope="row">
                    <!-- Material unchecked -->
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'payroll_' + payslip.id"
                        :value="payslip.id"
                        v-model="selectedPayrollRecord"
                        @change="updateCheckall"
                      />
                      <label
                        class="form-check-label"
                        :for="'payroll_' + payslip.id"
                      ></label>
                    </div>
                  </td>

                  <td @click="rowClickEvent(payslip.id)">
                    <EmployeeInfoTableCellLayout
                      :user="{
                        profile_image: payslip.image,
                        epinfo_name: payslip.name,
                        jp_name: payslip.position,
                        department_name: payslip.department,
                      }"
                    />
                  </td>
                  <td scope="row" @click="rowClickEvent(payslip.id)">
                    {{ payslip.date | formatMonthYearString }}
                  </td>
                  <td @click="rowClickEvent(payslip.id)">
                    {{ payslip.attendance }}
                  </td>
                  <td @click="rowClickEvent(payslip.id)">
                    {{ payslip.leaves }}
                  </td>
                  <td @click="rowClickEvent(payslip.id)">
                    {{ payslip.absent }}
                  </td>
                  <td @click="rowClickEvent(payslip.id)">
                    {{ payslip.overtime }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <pagination
              :data="payslip_list"
              @pagination-change-page="fetchRecordList"
              :limit="2"
            ></pagination>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>

    <mdb-modal :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Confirmation !</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <p>Are you sure to delete all selected record ?</p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
        <mdb-btn color="primary" @click="confirmAction" size="sm">Yes</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import Vue from "vue";
Vue.component("pagination", require("laravel-vue-pagination"));

import Payroll from "@/apis/Payroll";
import { mapActions, mapGetters } from "vuex";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";

import PayslipSearchForm from "@/components/search/PayslipSearchForm";
import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    EmployeeInfoTableCellLayout,
    PayslipSearchForm,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
  },

  data() {
    return {
      payslip_list: [],
      searchFormData: {
        keywords: "",
        department_id: "",
        filter_year: "",
        filter_month: "",
      },
      currentSort: "contract_reference",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",

      modal: false,
      isCheckAll: false,
      selectedPayrollRecord: [],
      actionSubmitted: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    shouldActionButtonShow() {
      return this.selectedPayrollRecord.length > 0 ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    rowClickEvent(id) {
      this.$router.push({
        name: "payslipDetail",
        params: { _id: id },
      });
    },

    modalOpen() {
      this.modal = true;
    },

    checkAllRecord() {
      this.selectedPayrollRecord = [];
      if (this.isCheckAll) {
        for (let record_item of this.payslip_list.data) {
          this.selectedPayrollRecord.push(record_item.id);
        }
      }
    },

    updateCheckall() {
      if (this.payslip_list.data.length == this.selectedPayrollRecord.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },

    confirmAction() {
      this.actionSubmitted = true;

      Payroll.deleteMultiplePayslip({
        ids: this.selectedPayrollRecord,
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            this.actionSubmitted = false;

            this.fetchRecordList();

            this.modal = false;
          } else if (response.data.code === 500) {
            this.actionSubmitted = false;
            this.feedback_message = "Error";
            this.feedback_error = true;

            this.modal = false;
          }
        }
      });
    },

    handleSubmitFilterForm(val) {
      this.searchFormData = val;
      this.fetchRecordList();
    },

    createNewRecord() {
      this.$router.push({
        name: "createPayroll",
      });
    },

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      const params = {
        page: page,
        keywords: this.searchFormData.keywords,
        department_id: this.searchFormData.department_id,
        date:
          this.searchFormData.filter_month != "" &&
          this.searchFormData.filter_year
            ? this.searchFormData.filter_month +
              "-" +
              this.searchFormData.filter_year
            : "",
      };
      Payroll.getPayslips(params)
        .then((response) => {
          if (response.status === 200) {
            this.payslip_list = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
