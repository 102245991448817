<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <mdb-card class="mb-5">
        <mdb-card-body>
          <form @submit.prevent="handleFormSubmit">
            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <mdb-select
                  search
                  outline
                  v-model.trim="yearsOptions"
                  :label="$t('holidays.formInput.select_year')"
                  placeholder=""
                  v-on:getValue="getSelectValue"
                />
              </mdb-col>
              <mdb-col offsetMd="2" md="8">
                <mdb-select
                  outline
                  v-model.trim="monthOptions"
                  :label="$t('holidays.formInput.select_month')"
                  placeholder=""
                  v-on:getValue="getSelectMonthValue"
                />
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <mdb-btn
                  type="Submit"
                  color="uno-color"
                  class="btn_submit"
                  :disabled="formSubmitting"
                >
                  <mdb-icon
                    icon="file-upload"
                    size="md"
                    v-show="!formSubmitting"
                  />

                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="formSubmitting"
                  ></span>
                  {{ $t("general.button.submit") }}
                </mdb-btn>
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <mdb-container>
                  <mdb-progress
                    v-if="formSubmitting"
                    :height="20"
                    :value="completed_percentage"
                    color="blue"
                    >{{ completed_percentage }}%</mdb-progress
                  >
                  <!-- <mdb-progress :height="20" :value="completed_percentage">{{completed_percentage}}%</mdb-progress> -->
                </mdb-container>
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col
                v-if="completed_percentage == 100"
                offsetMd="2"
                md="8"
                class="my-3"
              >
                Employee Timetable Report is successfully generated. Click
                download button to save your file.<br />

                <mdb-btn
                  type="button"
                  color="uno-color"
                  class="btn_submit"
                  @click="downloadTimetableReport()"
                  :disabled="file_downloading"
                >
                  <mdb-icon
                    icon="file-upload"
                    size="md"
                    v-show="!file_downloading"
                  />

                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="file_downloading"
                  ></span>
                  {{ $t("general.button.download_csv") }}
                </mdb-btn>
              </mdb-col>
            </mdb-row>
          </form>
        </mdb-card-body>
      </mdb-card>
    </template>
  </div>
</template>
<script>
import Report from "@/apis/Report";
import {
  mdbCard,
  mdbCardBody,
  //   mdbSpinner,
  mdbContainer,
  mdbProgress,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbSelect,
} from "mdbvue";

const yearOption = (current, year) => {
  let years = [];
  for (let current_year = current; current_year >= 1900; --current_year) {
    if (year == current_year)
      years.push({ text: current_year, value: current_year, selected: true });
    else years.push({ text: current_year, value: current_year });
  }
  return years;
};

const month_name = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthOption = (month) => {
  let months = [];
  for (let i = 0; i < month_name.length; i++) {
    if (month == month_name[i])
      months.push({
        text: month_name[i],
        value: month_name[i],
        selected: true,
      });
    else months.push({ text: month_name[i], value: month_name[i] });
  }
  return months;
};

export default {
  components: {
    mdbCard,
    mdbCardBody,
    // mdbSpinner,
    mdbContainer,
    mdbProgress,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    mdbSelect,
  },
  data() {
    return {
      yearsOptions: null,
      monthOptions: null,
      filter_year: "",
      filter_month: "",
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
      file_downloading: false,
      completed_percentage: 0,

      total_employee_count: 0,
      offset: 0,
      rows_per_time: 40,
    };
  },
  computed: {},

  methods: {
    getSelectValue(value) {
      this.filter_year = value;
    },
    getSelectMonthValue(value) {
      this.filter_month = value;
    },

    downloadTimetableReport() {
      // let temp_number_month =
      //   parseInt(month_name.indexOf(this.filter_month)) + 1;

      // let payload = {};

      // if (temp_number_month < 10) {
      //   payload.month = "0" + temp_number_month;
      //   payload.year = this.filter_year;
      // } else {
      //   payload.month = temp_number_month;
      //   payload.year = this.filter_year;
      // }

      const payload = {
          month: this.filter_month ,
          year: this.filter_year,
        };

      this.file_downloading = true;

      Report.downloadTimetableReport(payload).then((response) => {
        if (response.status === 200) {
          var blob = new Blob([response.data]);
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "TimeTableReport" + "_" + this.filter_month + this.filter_year + ".csv";
          link.click();

          this.file_downloading = false;
        } else {
          this.file_downloading = false;
        }
      });
    },

    handleFormSubmit() {
      if (this.filter_year != "" && this.filter_month != "") {
        this.resetAlert();
        this.formSubmitting = true;

        const payload = {
          date: this.filter_month + "-" + this.filter_year,
        };

        this.completed_percentage = 0;

        Report.requestCalculateTimetable(payload).then((response) => {
          if (response.status === 200) {
            if (response.data.code == 200) {
              this.total_employee_count =
                response.data.data.total_employee_count;
              if (this.total_employee_count > 0) {
                this.completed_percentage = 1;
                this.submitCalculation();
              }
            }
          } else {
            this.formSubmitting = false;
          }
        });
      } else {
        this.feedback_message = "Select Month and Year";
        this.feedback_error = true;
        this.formSubmitting = false;
      }
    },

    submitCalculation() {
      //   if (this.total_employee_count > this.rows_per_time) {
      //     this.total_recrusive_request = this.total_employee_count / this.rows_per_time;

      //     if (this.total_employee_count % this.rows_per_time > 0) {
      //       this.total_recrusive_request += 1;
      //     }
      //   } else {
      //     this.total_recrusive_request = 1;
      //   }

      const payload = {
        date: this.filter_month + "-" + this.filter_year,
        offset: this.offset,
        rows_per_time: this.rows_per_time,
      };

      Report.submitCalculateTimetable(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code == 200) {
            this.offset += this.rows_per_time;

            if (this.total_employee_count > this.offset) {
              this.submitCalculation();
              this.updatePercentageCalculation();
            } else {
              this.completed_percentage = 100;
              this.formSubmitting = false;
            }
          }
        } else {
          //   console.log(response);
          //   this.formSubmitting = false;
        }
      });
    },

    updatePercentageCalculation() {
      this.completed_percentage = Math.floor(
        (this.offset * 100) / this.total_employee_count
      );
    },

    resetAlert() {
      this.feedback_error = false;
    },
  },
  created() {
    this.yearsOptions = yearOption(parseInt(new Date().getFullYear()) + 1, "");
    this.monthOptions = monthOption("");
  },
};
</script>
<style >
.md-progress .progress-bar {
  height: 22px;
}
</style>