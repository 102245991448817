<template>
  <div>
    <mdb-card class="mb-5">
      <mdb-card-body>
        <!-- <mdb-row v-if="selectedEmployeeData" class="mb-5">
          <mdb-col offsetMd="2" md="8">
            <div class="approval_info_box">
              <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
            </div>
          </mdb-col>
        </mdb-row> -->

        <mdb-row>
          <mdb-col offsetMd="2" md="8">
            <dl class="row">

                
              <dt class="col-sm-3">Employee Info:</dt>
              <dd class="col-sm-9">
                <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
              </dd>

              <dt class="col-sm-3">Status:</dt>
              <dd class="col-sm-9">
                {{ data.status }}

                {{
                  data.overtime_complete == "incomplete" && data.status!="reject"
                    ? "( Waiting Approvals )"
                    : ""
                }}

                
                {{
                  data.overtime_complete == "complete"
                    ? "( Approval Process Complete )"
                    : ""
                }}
              </dd>

              <dt class="col-sm-3">Date:</dt>
              <dd class="col-sm-9">
                {{ data.date }}
              </dd>

              <dt class="col-sm-3">Start Time:</dt>
              <dd class="col-sm-9">
                {{ data.start_from }}
              </dd>

              <dt class="col-sm-3">End Time:</dt>
              <dd class="col-sm-9">
                {{ data.end_at }}
              </dd>

              <dt class="col-sm-3">Apply At:</dt>
              <dd class="col-sm-9">
                {{ data.created }}
              </dd>

              <dt class="col-sm-3">Description:</dt>
              <dd class="col-sm-9">
                {{ data.description }}
              </dd>
            </dl>
          </mdb-col>
        </mdb-row>

        <mdb-row v-if="data.approval_users.length > 0">
          <mdb-col offsetMd="2" md="8">
            <h4>Approved By</h4>
            <div v-for="(user, index) in data.approval_users" :key="index" class="mb-3">
                
              <ApprovalApprovedUserLayout
                :user="{
                  epinfo_name: user.name,
                  jp_name: user.position,
                  department_name: user.department,
                  profile_image: user.image,
                  date: user.date,
                  description: user.description,
                  approval_status:user.approval_status
                }"
              />
            </div>
          </mdb-col>
        </mdb-row>

        <mdb-row>
          <mdb-col offsetMd="2" md="8">
            <mdb-btn
              type="button"
              color="uno-color"
              class="btn_submit"
              @click="backAction()"
            >
              <mdb-icon icon="file-upload" size="md" />

              {{ $t("general.button.go_back") }}
            </mdb-btn>

            <mdb-btn
              type="button"
              color="uno-color"
              class="btn_submit"
              @click="editAction()"
            >
              <mdb-icon icon="file-upload" size="md" />

              {{ $t("general.button.edit") }}
            </mdb-btn>
          </mdb-col>
        </mdb-row>

        <mdb-row>
          <mdb-col offsetMd="2" md="8">
            <hr />

            <label class="form-label">
              {{ $t("approvals.formInput.approval_status") }}
            </label>
            <mdb-input
              type="radio"
              id="req-opt-approve"
              radioValue="approve"
              v-model="approval_status"
              :label="$t('approvals.formInput.approve')"
              :disabled="data.status == 'approve'"
            />
            <mdb-input
              type="radio"
              id="req-opt-reject"
              radioValue="reject"
              v-model="approval_status"
              :label="$t('approvals.formInput.reject')"
              :disabled="data.status == 'reject'"
            />

            <mdb-input
              type="textarea"
              :label="$t('approvals.formInput.description')"
              v-model="approval_description"
              outline
              v-if="approval_status == 'reject'"
            />

            <mdb-btn
              type="Submit"
              color="uno-color"
              class="btn_submit"
              :disabled="showDisplaySubmit"
              @click="approvalAction"
            >
              <mdb-icon icon="file-upload" size="md" v-show="!formSubmitting" />

              <span
                class="spinner-border-vcenter spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="formSubmitting"
              ></span>
              {{ $t("general.button.submit") }}
            </mdb-btn>
          </mdb-col>
        </mdb-row>


      </mdb-card-body>
    </mdb-card>
<!-- {{data}} -->
  </div>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbInput,
} from "mdbvue";
import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import Overtime from "@/apis/Overtime";
import ApprovalApprovedUserLayout from "@/components/ApprovalApprovedUserLayout";

export default {
  name: "OvertimeViewDetail",
  components: {
    mdbCard,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    EmployeeInfoBoxLayout,
    ApprovalApprovedUserLayout,
    mdbInput,
  },
  props: ["data"],
  data() {
    return {
      formSubmitting: false,
      selectedEmployeeData: {},

      approval_status:"",
      approval_description: "",
    };
  },
  computed:{
      showDisplaySubmit(){
          if(this.formSubmitting == true){
              return true;
          }else if(this.approval_status == ''){
              return true;
          }else if(this.approval_status == 'reject' && this.approval_description== ''){
              return true;
          }else{
              return false;
          }
      }
  },

  methods: {
    backAction() {
      this.$emit("updateBackAction");
    },

    editAction(){
        this.$emit("viewEditAction");
    },

    approvalAction() {
      this.formSubmitting = true;

      Overtime.submitApproval({
        id: [this.data.id],
        status: this.approval_status,
        description: this.approval_description,
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            this.formSubmitting = false;
            this.$emit("refetchRecordAction");
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }
        }
      });
    },
  },
  created() {
    this.selectedEmployeeData = {
      epinfo_name: this.data.name,
      jp_name: this.data.position,
      department_name: this.data.department,
      profile_image: this.data.image,
    };
  },
};
</script>
