<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <AttendanceApprovalSearchForm
        @submitFilterForm="handleSubmitFilterForm"
        :filterFormData="searchFormData"
      />

      <mdb-card>
        <mdb-card-header>
          <div class="float-left"></div>

          <div class="float-right">
            <mdb-btn
              size="sm"
              color="uno-color"
              class="btn_delete"
              v-show="shouldActionButtonShow"
              @click.native="modalOpen('reject')"
              :disabled="actionSubmitted"
            >
              <mdb-icon
                icon="trash-alt"
                far
                size="md"
                v-show="!actionSubmitted"
              />

              <span
                class="spinner-border-vcenter spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="actionSubmitted"
              ></span>

              Cancel Selected
            </mdb-btn>

            <mdb-btn
              size="sm"
              color="uno-color"
              class="btn_submit"
              v-show="shouldActionButtonShow"
              @click.native="modalOpen('approve')"
              :disabled="actionSubmitted"
            >
              <mdb-icon
                icon="trash-alt"
                far
                size="md"
                v-show="!actionSubmitted"
              />

              <span
                class="spinner-border-vcenter spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="actionSubmitted"
              ></span>

              Approve Selected
            </mdb-btn>
          </div>
        </mdb-card-header>

        <mdb-card-body>
          <mdb-tbl hover responsive>
            <!-- Table head -->
            <mdb-tbl-head>
              <tr>
                <th width="50">
                  <!-- Material unchecked -->
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="trmc_all"
                      @change="checkAllRecord"
                      v-model="isCheckAll"
                    />
                    <label class="form-check-label" for="trmc_all"></label>
                  </div>
                </th>
                <th></th>

                <th>Check In/ Out</th>

                <th class="text-right">
                  {{ $t("general.formInput.date") }}
                </th>

                <th class="text-right">
                  {{ $t("general.formInput.status") }}
                </th>
              </tr>
            </mdb-tbl-head>
            <!-- Table head -->

            <!-- Table body -->
            <mdb-tbl-body>
              <tr
                scope="row"
                v-for="(record_item, index) in approval_record.data"
                :key="index"
              >
                <td scope="row">
                  <!-- Material unchecked -->
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      :id="'trmc_' + record_item.oal_id"
                      :value="record_item.oal_id"
                      v-model="selectedApprovalRecord"
                      @change="updateCheckall"
                      :disabled="record_item.reject_button_status == true"
                    />
                    <label
                      class="form-check-label"
                      :for="'trmc_' + record_item.oal_id"
                    ></label>
                  </div>
                </td>

                <td
                  class="align-middle"
                  align="left"
                  @click="rowClickEvent(record_item.oal_id)"
                >
                  <EmployeeInfoTableCellLayout
                    :user="{
                      epinfo_name: record_item.name,
                      jp_name: record_item.position,
                      department_name: record_item.department,
                      profile_image: record_item.image,
                    }"
                  />
                </td>

                <td @click="rowClickEvent(record_item.oal_id)">
                  {{
                    record_item.check_in_out == "check_in"
                      ? "Check In"
                      : "Check Out"
                  }}
                  <br />
                  {{
                    record_item.reject_button_status == true
                      ? "Request Fix Attendance"
                      : ""
                  }}
                </td>

                <td
                  @click="rowClickEvent(record_item.oal_id)"
                  class="text-right"
                >
                  {{ record_item.date }}
                  <div v-if="record_item.late_early_mins != '00:00'">
                    {{ record_item.late_early_mins }}
                    {{
                      record_item.check_in_out == "check_in" ? "Late" : "Early"
                    }}
                  </div>
                </td>

                <td
                  @click="rowClickEvent(record_item.oal_id)"
                  class="text-right"
                >
                  {{ record_item.attendance_status }}
                </td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>

          <pagination
            :data="approval_record"
            @pagination-change-page="fetchRecordList"
            :limit="2"
          ></pagination>
        </mdb-card-body>
      </mdb-card>
    </div>

    <mdb-modal :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Confirmation !</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div v-if="action_name == 'reject'">
          <p>Are you sure to cancel all selected record ?</p>

          <mdb-input
            type="textarea"
            rows="5"
            v-model="approval_description"
            label="Remark"
            outline
          />
        </div>

        <div v-else>
          <p>Are you sure to approve all selected record ?</p>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
        <mdb-btn
          color="primary"
          @click="confirmAction"
          size="sm"
          :disabled="shouldShowDisabledYes"
          >Yes</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import Vue from "vue";
Vue.component("pagination", require("laravel-vue-pagination"));
import AttendanceApprovalSearchForm from "@/components/search/AttendanceApprovalSearchForm";

import Attendance from "@/apis/Attendance";
import { mapActions, mapGetters } from "vuex";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";

import {
  //   mdbRow,
  //   mdbCol,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
} from "mdbvue";

export default {
  components: {
    // mdbRow,
    // mdbCol,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    AttendanceApprovalSearchForm,
    EmployeeInfoTableCellLayout,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
  },

  data() {
    return {
      currentSort: "oal_id",
      currentSortDir: "desc",
      feedback_error: false,
      feedback_message: "",
      approval_record: [],
      searchFormData: {
        keywords: "",
        department_id: "",
        position_id: "",
        date_from: "",
        date_to: "",
      },
      formSubmitting: false,
      isCheckAll: false,
      selectedApprovalRecord: [],
      actionSubmitted: false,
      modal: false,
      action_name: false,
      approval_description: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    doesFormSubmitted() {
      return this.formSubmitting;
    },
    doesCheckAll() {
      return this.isCheckAll;
    },
    shouldActionButtonShow() {
      return this.selectedApprovalRecord.length > 0 ? true : false;
    },
    shouldShowDisabledYes() {
      if (this.action_name == "reject" && this.approval_description == "") {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    handleSubmitFilterForm(val) {
      this.searchFormData = val;
      this.fetchRecordList();
    },

    modalOpen(param) {
      this.action_name = param;
      this.modal = true;
    },

    confirmAction() {
      this.actionSubmitted = true;

      // console.log(this.selectedApprovalRecord);

      // console.log(this.action_name);
      // console.log(this.approval_description);

      Attendance.submitApproval({
        oal_id: this.selectedApprovalRecord,
        status: this.action_name,
        description: this.approval_description,
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            this.actionSubmitted = false;

            this.fetchRecordList();

            this.modal = false;
          } else if (response.data.code === 500) {
            this.actionSubmitted = false;
            this.feedback_message = "Error";
            this.feedback_error = true;

            this.modal = false;
          }
        }
      });
    },

    rowClickEvent(id) {
      this.$router.push({
        name: "viewAttendanceApproval",
        params: { _id: id },
      });
    },

    checkAllRecord() {
      this.selectedApprovalRecord = [];
      if (this.isCheckAll) {
        for (let record_item of this.approval_record.data) {
          if (record_item.reject_button_status !== true)
            this.selectedApprovalRecord.push(record_item.oal_id);
        }
      }
    },
    updateCheckall() {
      if (
        this.approval_record.data.length == this.selectedApprovalRecord.length
      ) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },

    getSelectValue(value) {
      this.filter_form.filter_year = value;
    },

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      const params = {
        source: "web",
        page: page,
        keywords: this.searchFormData.keywords,
        department_id: this.searchFormData.department_id,
        position_id: this.searchFormData.position_id,
        date_from: this.searchFormData.date_from,
        date_to: this.searchFormData.date_to,
        attendance_status: this.searchFormData.attendance_status,
      };

      Attendance.getApprovalList(params)
        .then((response) => {
          if (response.status === 200) {
            this.approval_record = response.data.data;

            this.updateLoading(false);
            this.formSubmitting = false;
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
            this.formSubmitting = false;
          } else {
            this.feedback_message = error;
            this.feedback_error = true;

            this.updateLoading(false);
            this.formSubmitting = false;
          }
        });

      this.selectedApprovalRecord = [];
    },
    handleFilterSubmit() {
      this.formSubmitting = true;
      this.fetchRecordList();
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>