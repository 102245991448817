<template>
  <div>
    <mdb-select
      outline
      search
      v-model="axiosOptions"
      :label="selectLabel"
      @getValue="updateSelectedValue"
      :disabled="loading"
    />
  </div>
</template>

<script>
import { mdbSelect } from "mdbvue";
import Position from "@/apis/Position";
export default {
  name: "PositionDropdown",
  components: {
    mdbSelect,
  },
  props: ["default_selected_value", "required","resetSelected"],
  data() {
    return {
      axiosOptions: [],
      loading: true,
      selectLabel: "Loading Position List",
    };
  },
  mounted() {
    Position.all().then((response) => {
      if (response.status === 200) {
        const positions = response.data.data;
        if (this.required === false) {
          this.axiosOptions.push({
            text: "All",
            value: "",
          });
        }

        for (let position of positions) {
          if (this.default_selected_value === position.jp_id) {
            this.axiosOptions.push({
              text: position.jp_name,
              value: position.jp_id,
              selected: true,
            });
          } else {
            this.axiosOptions.push({
              text: position.jp_name,
              value: position.jp_id,
            });
          }
        }
        this.loading = false;
        this.selectLabel = "Select Position";
      }
    });
  },
  methods: {
    updateSelectedValue(val) {
      if (val !== null) {
        this.$emit("updatePositionID", val);
      }
    },
  },
  watch: {
    resetSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.axiosOptions = this.axiosOptions.map((val) => ({
          text: val.text,
          value: val.value,
        }));
      }
    },
  },
};
</script>
