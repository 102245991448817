<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    required
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="languageInfos.elang_info_name"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-select
                    v-model="OptionSpeaking"
                    :label="$t('lang_info.formInput.speaking')"
                    outline
                    @getValue="updateSpeakingValue"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-select
                    v-model="OptionListening"
                    :label="$t('lang_info.formInput.listening')"
                    outline
                    @getValue="updateListeningValue"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-select
                    v-model="OptionReading"
                    :label="$t('lang_info.formInput.reading')"
                    outline
                    @getValue="updateReadingValue"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-select
                    v-model="OptionWriting"
                    :label="$t('lang_info.formInput.writing')"
                    outline
                    @getValue="updateWritingValue"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    :label="$t('general.formInput.remark')"
                    v-model="languageInfos.elang_info_remark"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import EmployeeLanguageInfo from "@/apis/EmployeeLanguageInfo";
import LogList from "@/components/logs/List";
import { langProficiencyOption } from "@/constants/employee.js";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  // mdbDatePicker2,
  mdbSelect,
  // mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    // mdbDatePicker2,
    mdbSelect,
    // mdbFormInline,
    ModalDeleteConfimation,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      languageInfos: {
        elang_info_id: "",
        elang_info_employee_id:
          this.employee_id != "undefined" ? this.employee_id : "",
        elang_info_name: "",
        elang_info_listening: "",
        elang_info_reading: "",
        elang_info_speaking: "",
        elang_info_writing: "",
        elang_info_remark: "",
      },
      OptionListening: langProficiencyOption(true, ""),
      OptionReading: langProficiencyOption(true, ""),
      OptionSpeaking: langProficiencyOption(true, ""),
      OptionWriting: langProficiencyOption(true, ""),
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.languageInfos.elang_info_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    updateListeningValue(val) {
      this.languageInfos.elang_info_listening = val;
    },
    updateReadingValue(val) {
      this.languageInfos.elang_info_reading = val;
    },
    updateSpeakingValue(val) {
      this.languageInfos.elang_info_speaking = val;
    },
    updateWritingValue(val) {
      this.languageInfos.elang_info_writing = val;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          EmployeeLanguageInfo.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)

                  let elang_info_id,
                    elang_info_employee_id,
                    elang_info_name,
                    elang_info_listening,
                    elang_info_reading,
                    elang_info_speaking,
                    elang_info_writing,
                    elang_info_remark;
                  // populate field
                  ({
                    elang_info_id,
                    elang_info_employee_id,
                    elang_info_name,
                    elang_info_listening,
                    elang_info_reading,
                    elang_info_speaking,
                    elang_info_writing,
                    elang_info_remark,
                  } = response.data.data);
                  this.languageInfos.elang_info_id = elang_info_id;
                  this.languageInfos.elang_info_employee_id = elang_info_employee_id;
                  this.languageInfos.elang_info_name = elang_info_name;
                  this.languageInfos.elang_info_listening = elang_info_listening;
                  this.languageInfos.elang_info_reading = elang_info_reading;
                  this.languageInfos.elang_info_speaking = elang_info_speaking;
                  this.languageInfos.elang_info_writing = elang_info_writing;
                  this.languageInfos.elang_info_remark = elang_info_remark;
                  //make selected option again
                  this.OptionListening = langProficiencyOption(
                    true,
                    elang_info_listening
                  );
                  this.OptionReading = langProficiencyOption(
                    true,
                    elang_info_reading
                  );
                  this.OptionSpeaking = langProficiencyOption(
                    true,
                    elang_info_speaking
                  );
                  this.OptionWriting = langProficiencyOption(
                    true,
                    elang_info_writing
                  );

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    languageInfoList: {
                      _id: this.languageInfos.elang_info_employee_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "languageInfoList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "languageInfoList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "languageInfoList" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      EmployeeLanguageInfo.save(this.languageInfos).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.languageInfos.elang_info_id != "") {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({
                name: "languageInfoList",
                params: { employee_id: this.languageInfos.elang_info_employee_id },
              });
            } else {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.resetForm();
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({
        name: "languageInfoList",
        params: { employee_id: this.languageInfos.elang_info_employee_id },
      });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.languageInfos).forEach((key) => {
        this.languageInfos[key] = "";
      });
      this.languageInfos.elang_info_employee_id = this.employee_id;
      this.OptionListening = langProficiencyOption(true, "")
      this.OptionReading = langProficiencyOption(true, "")
      this.OptionSpeaking = langProficiencyOption(true, "")
      this.OptionWriting = langProficiencyOption(true, "")
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.languageInfos.elang_info_id > 0) {
        EmployeeLanguageInfo.delete({
          elang_info_id: this.languageInfos.elang_info_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              this.$router.push({
                name: "languageInfoList",
                params: {
                  _id: this.languageInfos.elang_info_employee_id,
                },
              });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      languageInfoList: {
        employee_id: this.languageInfos.elang_info_employee_id,
      },
    };
    this.fetchRecord();
  },
};
</script>