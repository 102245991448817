<template>
  <div>
    <mdb-select
      outline
      search
      v-model="axiosOptions"
      :label="selectLabel"
      @getValue="updateSelectedValue"
      :disabled="loading"
    />
  </div>
</template>

<script>
import { mdbSelect } from "mdbvue";
import CompanyBranch from "@/apis/CompanyBranch";
export default {
  name: "CompanyBranchDropdown",
  components: {
    mdbSelect,
  },
  props: ["company_id", "default_selected_value", "required", "resetSelected"],
  data() {
    return {
      axiosOptions: [],
      loading: true,
      selectLabel: "---Disabled---",
    };
  },
  mounted() {

    if(this.company_id){
      this.fetchdata()
    }
  },
  methods: {
    updateSelectedValue(val) {
      if (val !== null) {
        this.$emit("updateCompanyBranchID", val);
      }
    },
    fetchdata() {
      let params = {
        cb_company_id: this.company_id,
      };
      this.axiosOptions= []

      CompanyBranch.all(params).then((response) => {
        if (response.status === 200) {
          const companybranches = response.data.data;
          if (this.required === false) {
            this.axiosOptions.push({
              text: "All",
              value: "",
            });
          }

          for (let companybranch of companybranches) {
            if (this.default_selected_value === companybranch.cb_id) {
              this.axiosOptions.push({
                text: companybranch.cb_name,
                value: companybranch.cb_id,
                selected: true,
              });
            } else {
              this.axiosOptions.push({
                text: companybranch.cb_name,
                value: companybranch.cb_id,
              });
            }
          }
          this.loading = false;
          this.selectLabel = "Select Company Branch";
        }
      });
    },
  },
  watch: {
    resetSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.axiosOptions = this.axiosOptions.map((val) => ({
          text: val.text,
          value: val.value,
        }));
      }
    },
    company_id(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.selectLabel = "Loading Company Branch List";
        this.fetchdata()
      }
    }
  },
};
</script>
