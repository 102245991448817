<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-show="!isLoading">
        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn color="uno-color" class="btn_submit" @click="createNewRecord" size="sm">
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>

                  <th></th>
                  
                  <th @click="sort('dt_name')">
                    <i
                      v-show="currentSort === 'dt_name'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("employee_document.formInput.type") }}
                  </th>

                  <th @click="sort('emp_document_name')">
                    <i
                      v-show="currentSort === 'emp_document_name'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("general.formInput.name") }}
                  </th>
                  <!-- <th>
                    
                  </th> -->
                  <th
                    width="120"
                    class="text-right"
                    @click="sort('emp_created')"
                  >
                    <i
                      v-show="currentSort === 'emp_created'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("general.formInput.created") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(record_item, index) in sortedRecordList"
                  :key="index"
                >
                <td scope="row" @click="rowClickEvent(record_item.emp_document_id)">
                  <div class="float-left attachments_text_style">
                  
                    <img
                      v-if="
                        record_item.file_ext == 'png' ||
                        record_item.file_ext == 'gif' ||
                        record_item.file_ext == 'jpg' ||
                        record_item.file_ext == 'jpeg'
                      "
                      :src="record_item.emp_file_name"
                      height="100"
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else-if="
                        record_item.file_ext == 'doc' ||
                        record_item.file_ext == 'docx'
                      "
                      src="/doc.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else-if="
                        record_item.file_ext == 'xls' ||
                        record_item.file_ext == 'xlsx'
                      "
                      src="/spreadsheet.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else-if="
                        record_item.file_ext == 'ppt' ||
                        record_item.file_ext == 'ppt'
                      "
                      src="/powerpoint.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else-if="
                        record_item.file_ext == 'pdf'
                      "
                      src="/pdf.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else
                      src="/unknowfile.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    />
                    
                </div>

                </td>
                 <td @click="rowClickEvent(record_item.emp_document_id)">
                    {{ record_item.dt_name }}
                  </td>

                  <td
                    
                    @click="rowClickEvent(record_item.emp_document_id)"
                  >
                    {{ record_item.emp_document_name }}
                  </td>
                  
                 
<!--                   
                  <td @click="rowClickEvent(record_item.emp_document_id)">
                    {{ record_item.emp_file_name }}
                  </td> -->
                  <td
                    class="text-right"
                    @click="rowClickEvent(record_item.emp_document_id)"
                  >
                    {{ record_item.emp_created  | formatDateString }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import EmployeeDocument from "@/apis/EmployeeDocument";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },

  data() {
    return {
      employee_documents: [],
      currentSort: "emp_updated",
      currentSortDir: "desc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  props: ["employee_id"],
  computed: {
    ...mapGetters(["isLoading"]),

    sortedRecordList() {
      return this.employee_documents.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    rowClickEvent(id) {
      this.$router.push({ name: "viewEmployeeDocument", params: { _id: id } });
    },

    createNewRecord() {
      this.$router.push({
        name: "createEmployeeDocument",
        params: { employee_id: this.employee_id },
      });
    },

    fetchRecordList() {
      this.updateLoading(true);

      if (this.employee_id != undefined) {
        if (Math.sign(this.employee_id) === 1) {
          EmployeeDocument.all(this.employee_id)
            .then((response) => {
              if (response.status === 200) {
                // console.log(response.data.data)
                this.employee_documents = response.data.data;
                this.updateLoading(false);
              }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.feedback_message = "Could not connected to server";
                this.feedback_error = true;

                this.updateLoading(false);
              } else {
                // console.log(error);
                this.updateLoading(false);
              }
            });
        }
      }
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
<style scoped>
.announcement_thumbnail_image {
  border: 2px solid #c0c0c0;
  padding: 5px;
  margin-top: 10px;
  max-width: 150px;
  max-height: 100px;
}
.attachments_text_style{
  text-align: center;
  margin-right: 20px;
}
</style>