// export const treeBuilder = (list) => {
//     var map = {}, node, roots = [], i;

//     for (i = 0; i < list.length; i += 1) {
//       map[list[i].id] = i; // initialize the map
//       list[i].children = []; // initialize the children
//     }

//     for (i = 0; i < list.length; i += 1) {
//       node = list[i];
//       if (node.parentId !== "0") {
//         // if you have dangling branches check that map[node.parentId] exists
//         list[map[node.parentId]].children.push(node);
//       } else {
//         roots.push(node);
//       }
//     }
//     return roots;
//   }

export const treeBuilder = (list, selectedVal) => {
  var map = {}, node, roots = [], i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].department_id] = i;
  }

  for (i = 0; i < list.length; i += 1) {

    if(list[i].department_parent_id == 0 ){
      list[i].department_parent_id = null;
    }  
    
    node = list[i];
    if (selectedVal.length > 0) {
      if (selectedVal.includes(node.department_id)) {
        node.isChecked = true
      } else {
        node.isChecked = false
      }
    }


    if (node.department_parent_id !== "" && node.department_parent_id !== null) {
      list[map[node.department_parent_id]].hasChild = true;
      list[map[node.department_parent_id]].expanded = true;
    }
    roots.push(node);

  }
  return roots;
}

export const treeBuilderDropdown = (list, selectedVal) => {
  var map = {}, node, roots = [], i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].department_id] = i;
  }

  for (i = 0; i < list.length; i += 1) {

    if(list[i].department_parent_id == 0 ){
      list[i].department_parent_id = null;
    }  

    node = list[i];
    if (selectedVal > 0) {
      if (selectedVal == node.department_id) {
        node.isChecked = true
      } else {
        node.isChecked = false
      }
    }


    if (node.department_parent_id !== "" && node.department_parent_id !== null) {
      list[map[node.department_parent_id]].hasChild = true;
      list[map[node.department_parent_id]].expanded = true;
    }
    roots.push(node);

  }
  return roots;
}


export const treeBuilderTable = (list, idAttr, parentAttr, childrenAttr) => {
  if (!idAttr) idAttr = 'department_id';
  if (!parentAttr) parentAttr = 'department_parent_id';
  if (!childrenAttr) childrenAttr = 'children';
  var treeList = [];
  var lookup = {};
  list.forEach(function(obj) {
      lookup[obj[idAttr]] = obj;
      obj[childrenAttr] = [];
  });
  list.forEach(function(obj) {
      if (obj[parentAttr] != null) {
          lookup[obj[parentAttr]][childrenAttr].push(obj);
      } else {
          treeList.push(obj);
      }
  });

  return treeList;
};


export const randomString = (length) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

