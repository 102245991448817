import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/mdbvue.css'
import './assets/css/main.css'
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import i18n from './i18n'
import Notify from 'mdbvue/lib/components/Notify'

import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});


import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load:{
    key:'AIzaSyDnJPPM4BuCzGfMjwbx4MbLStFNhANoI10'
  }
})

Vue.use(Notify)

import moment from 'moment';


Vue.filter('filename_str_limit', function (value) {
  if (!value) return '';
  value = value.toString();

  const total_str_length=20

  if (value.length <= total_str_length) {
    return value;
  }
  
  let filename = value.substr(0, value.lastIndexOf('.') + 1);
  let extension = value.substr(value.lastIndexOf('.') + 1);  

  let str_balance = (total_str_length - extension.length) - 3;

  return filename.substring(0, str_balance) + '._.' +  extension;
  // return filename.substring(0, 8) + '...' + filename.substring(filename.length - 6, 5) +  extension;
});


Vue.filter('formatNumbers', function (x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
})

Vue.filter('formatDateString', function (value) {
  if (value && value != '0000-00-00') {
    return moment(String(value)).format('DD MMM, YYYY')
  }else{
    return '-';
  }
})

Vue.filter('formatMonthYearString', function (value) {
  if (value) {
    return moment(String(value)).format('MMM, YYYY')
  }
})

Vue.filter('formatTimeString', function (value) {
  if (value) {
    return moment(String(value)).format('h:mm A')
  }
})


Vue.filter('formatDateTimeString', function (value) {
  if (value) {
    return moment(String(value)).format('DD MMM, YYYY h:mm A')
  }
})

Vue.filter('cutStringTableCell', function (value) {
  if (value.length > 25) {
    return value.substring(0, 22) + " ..";
  }
  return value
})




Vue.filter('formatSubstrTimeString', function (value) {
  if (value) {
    if (value.indexOf("am") >= 0) {
      return value.substr(0, value.indexOf("am")) + ' ' + value.substr(value.indexOf("am"), 2).toUpperCase();
    } else if (value.indexOf("pm") >= 0) {
      return value.substr(0, value.indexOf("pm")) + ' ' + value.substr(value.indexOf("pm"), 2).toUpperCase();
    } else {
      return value
    }
    // return moment(String(value)).format('h:mm A')
  }
})

Vue.filter('formatDurationString', function (value) {
  if (value) {
    let hours = (value / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return (rhours < 9 ? "0" + rhours : rhours) + ":" + (rminutes < 9 ? "0" + rminutes : rminutes) + "";
  }
})



Vue.filter('capitalizeFirstLetter', function (value) {

  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

})

Vue.directive('can', {
  bind: function (el, binding, vnode) {

    if (localStorage.getItem('is_admin') == '1') {

      //super admin

    } else {
      if (localStorage.getItem('user_permission') && localStorage.getItem('user_permission') != 'null') {

        if (!JSON.parse(localStorage.getItem('user_permission')).some(r => binding.value.indexOf(r) >= 0)) {
          // vnode.elm.style.display = "none";

          /////////
          const comment = document.createComment(' ');
          Object.defineProperty(comment, 'setAttribute', {
            value: () => undefined,
          });
          vnode.elm = comment;
          vnode.text = ' ';
          vnode.isComment = true;
          vnode.context = undefined;
          vnode.tag = undefined;
          vnode.data.directives = undefined;

          if (vnode.componentInstance) {
            vnode.componentInstance.$el = comment;
          }

          if (el.parentNode) {
            el.parentNode.replaceChild(comment, el);
          }
          /////////

        }
      } else {
        // console.log('fasd')

        const comment = document.createComment(' ');
        Object.defineProperty(comment, 'setAttribute', {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = ' ';
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;
        vnode.data.directives = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }

      }
    }




  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
