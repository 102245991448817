<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">
        <ContractSearchForm @submitFilterForm="handleSubmitFilterForm" :filterFormData="searchFormData"/>

        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn
                color="uno-color"
                class="btn_submit"
                @click="createNewRecord"
                size="sm"
              >
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th></th>
                  <th>
                    {{ $t("contract.formInput.reference") }}
                  </th>
                  <th>
                    {{ $t("contract.formInput.employment_type") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.start_date") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.end_date") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.status") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(recordlist, index) in contracts.data"
                  :key="index"
                >
                  <td
                    scope="row"
                    @click="rowClickEvent(recordlist.contract_id)"
                  >
                    <EmployeeInfoTableCellLayout :user="recordlist" />
                  </td>
                  <td @click="rowClickEvent(recordlist.contract_id)">
                    {{ recordlist.contract_reference }}
                  </td>
                  <td @click="rowClickEvent(recordlist.contract_id)">
                    {{ recordlist.contract_employment_type }}
                    <div
                      v-if="recordlist.contract_employment_type == 'contract'"
                    >
                      {{ recordlist.contract_duration }} Months
                    </div>
                  </td>
                  <td @click="rowClickEvent(recordlist.contract_id)">
                    {{ recordlist.contract_start_date | formatDateString }}
                  </td>
                  <td @click="rowClickEvent(recordlist.contract_id)">
                    {{ (recordlist.contract_quit_date ? recordlist.contract_quit_date : recordlist.contract_end_date) | formatDateString }}
                  </td>
                  <td @click="rowClickEvent(recordlist.contract_id)">
                    {{ recordlist.ct_name}}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <hr />
            <pagination :data="contracts" @pagination-change-page="fetchRecordList" :limit="2"></pagination>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
Vue.component('pagination', require('laravel-vue-pagination'))

import EmployeeContract from "@/apis/EmployeeContract";
import { mapActions, mapGetters } from "vuex";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";

import ContractSearchForm from "@/components/search/ContractSearchForm";
import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    EmployeeInfoTableCellLayout,
    ContractSearchForm
  },

  data() {
    return {
      contracts: [],
      searchFormData: {
        keywords: "",
        department_id: "",
        jp_id: "",
        employment_type: "",
        status: "",
        
        from_date: "",
        to_date: "",
        end_from_date: "",
        end_to_date: "",
      },
      currentSort: "contract_reference",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    rowClickEvent(id) {
      this.$router.push({
        name: "viewManageContract",
        params: { _id: id },
      });
    },

    handleSubmitFilterForm(val){
      this.searchFormData = val
      this.fetchRecordList()
    },

    createNewRecord() {
      this.$router.push({
        name: "createManageContract"
      });
    },

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      const params = {
        page: page,
        keywords: this.searchFormData.keywords,
        department_id: this.searchFormData.department_id,
        jp_id: this.searchFormData.jp_id,
        employment_type: this.searchFormData.employment_type,
        status: this.searchFormData.status,

        from_date: this.searchFormData.from_date,
        to_date: this.searchFormData.to_date,

        
        end_from_date: this.searchFormData.end_from_date,
        end_to_date: this.searchFormData.end_to_date,
      };
      EmployeeContract.all(params)
        .then((response) => {
          if (response.status === 200) {
            this.contracts = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
