<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-select
                    search
                    outline
                    v-model.trim="yearsOptions"
                    :label="$t('holidays.formInput.select_year')"
                    placeholder=""
                    v-on:getValue="getSelectValue"
                  />
                </mdb-col>
                <mdb-col offsetMd="2" md="8">
                  <mdb-select
                    outline
                    v-model.trim="monthOptions"
                    :label="$t('holidays.formInput.select_month')"
                    placeholder=""
                    v-on:getValue="getSelectMonthValue"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <DepartmentDropdown
                    @updateDepartmentID="setDepartmentID"
                    :default_selected_value="department_id"
                    :required="false"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
  </div>
</template>
<script>
import Report from "@/apis/Report";
import DepartmentDropdown from "@/components/DepartmentDropdown";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbSelect
} from "mdbvue";

const yearOption = (current, year) => {
  let years = [];
  for (let current_year = current; current_year >= 1900; --current_year) {
    if (year == current_year)
      years.push({ text: current_year, value: current_year, selected: true });
    else years.push({ text: current_year, value: current_year });
  }
  return years;
};

const month_name = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthOption = (month) => {
  let months = [];
  for (let i = 0; i < month_name.length; i++) {
    if (month == month_name[i])
      months.push({
        text: month_name[i],
        value: month_name[i],
        selected: true,
      });
    else months.push({ text: month_name[i], value: month_name[i] });
  }
  return months;
};

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    DepartmentDropdown,
    mdbSelect
  },
  data() {
    return {
      yearsOptions: null,
      monthOptions: null,
      department_id: "",
      filter_year: "",
      filter_month: "",
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    setDepartmentID(val) {
      this.department_id = val;
    },

    getSelectValue(value) {
      this.filter_year = value;
    },
    getSelectMonthValue(value) {
      this.filter_month = value;
    },

    handleFormSubmit() {
      if (this.filter_year != "" && this.filter_month != "") {
        this.resetAlert();
        this.formSubmitting = true;

        const payload = {
          department_id: this.department_id,
          date: this.filter_month + "-" + this.filter_year,
        };

        Report.accountReport(payload).then((response) => {
          if (response.status === 200) {
            let today = new Date();
            let [month, date, year] = today
              .toLocaleDateString("en-US")
              .split("/");
            let [hour, minute, second] = today
              .toLocaleTimeString("en-US")
              .split(/:| /);
            let today_string =
              year + month + date + "_" + hour + minute + second;

            var blob = new Blob(["\ufeff",response.data]);
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "AccountReport" + "_" + today_string + ".csv";
            link.click();

            this.formSubmitting = false;
          }
        });
      } else {
        this.feedback_message = "Select Month and Year";
        this.feedback_error = true;
      }
    },

    resetAlert() {
      this.feedback_error = false;
    },
  },
  created() {
    this.yearsOptions = yearOption(parseInt(new Date().getFullYear()) +1, "");
    this.monthOptions = monthOption("");
  },
};
</script>