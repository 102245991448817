<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>
      <!-- {{ temp_selected_attendance }}<br />
      {{ temp_selected_leave }}<br />
      {{ temp_selected_overtime }}<br /> -->
      <div v-if="!isLoading">
        <mdb-card>
          <mdb-card-body>
            <mdb-tabs
              :active="0"
              tabs
              card
              class="mb-5"
              color="primary"
              justify
              :links="[
                { text: 'Attendance' },
                { text: 'Leave' },
                { text: 'Overtime' },
              ]"
            >
              <template :slot="'Attendance'">
                <div v-if="approval_list.attendance != ''">
                  <template
                    v-if="approval_list.attendance.approvals.length > 0"
                  >
                    <mdb-row>
                      <mdb-col>
                        <div class="d-flex justify-content-end">
                          <mdb-btn
                            type="button"
                            rounded
                            size="sm"
                            @click="selectAll('attendance')"
                            color="uno-color"
                            class="btn_submit mt-2 mb-4"
                            :disabled="
                              temp_selected_attendance.length > 0 &&
                              temp_selected_attendance.length ==
                                approval_list.attendance.approvals.length
                            "
                          >
                            <mdb-icon icon="check-square" size="md" />
                            Select All
                          </mdb-btn>

                          <mdb-btn
                            type="button"
                            rounded
                            size="sm"
                            @click="unselectAll('attendance')"
                            color="uno-color"
                            class="btn_submit mt-2 mb-4"
                            :disabled="temp_selected_attendance.length == 0"
                          >
                            <mdb-icon far icon="check-square" size="md" />
                            Unselect All
                          </mdb-btn>

                          <mdb-btn
                            type="button"
                            rounded
                            size="sm"
                            @click="showDeleteModal('attendance')"
                            color="uno-color"
                            class="btn_sm_delete mt-2 mb-4"
                            :disabled="temp_selected_attendance.length == 0"
                          >
                            <mdb-icon icon="trash-alt" size="md" />
                            Delete Selected
                          </mdb-btn>
                        </div>
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col
                        v-for="(approval, index) in approval_list.attendance
                          .approvals"
                        :key="index"
                        md="6"
                      >
                        <!--  -->
                        <div
                          class="approval_info_box mt-3"
                          style="cursor: pointer"
                          @click="
                            checkEmployee(
                              'attendance',
                              approval.attendance_approval_id,
                              index
                            )
                          "
                        >
                          <img
                            :src="approval.profile_image"
                            class="rounded-circle float-left"
                            width="50"
                            height="50"
                            v-if="approval.profile_image"
                            @error="imageLoadError"
                          />

                          <div class="float-left ml-3 mb-3">
                            <div class="employee_name">
                              {{ approval.epinfo_name | cutStringTableCell }}
                            </div>
                            <div class="employee_info_text_sec">
                              {{ approval.jp_name | cutStringTableCell }}
                            </div>
                            <div class="employee_info_text">
                              {{
                                approval.department_name | cutStringTableCell
                              }}
                            </div>
                          </div>

                          <mdb-btn
                            color="uno-color"
                            class="float-right close_btn"
                          >
                            <mdb-icon
                              icon="check-square"
                              size="2x"
                              class="uno-text close_btn_color mr-2"
                              :class="{ selected_employee: approval.selected }"
                            />
                          </mdb-btn>
                        </div>
                        <!--  -->

                        <!-- {{ approval }} -->
                      </mdb-col>
                    </mdb-row>
                  </template>
                  <template v-else>
                    <br />No Approvals<br /><br /><br /><br
                  /></template>
                </div>
              </template>
              <template :slot="'Leave'">
                <div v-if="approval_list.leave != ''">
                  <template v-if="approval_list.leave.approvals.length > 0">
                    <mdb-row>
                      <mdb-col>
                        <div class="d-flex justify-content-end">
                          <mdb-btn
                            type="button"
                            rounded
                            size="sm"
                            @click="selectAll('leave')"
                            color="uno-color"
                            class="btn_submit mt-2 mb-4"
                            :disabled="
                              temp_selected_leave.length > 0 &&
                              temp_selected_leave.length ==
                                approval_list.leave.approvals.length
                            "
                          >
                            <mdb-icon icon="check-square" size="md" />
                            Select All
                          </mdb-btn>

                          <mdb-btn
                            type="button"
                            rounded
                            size="sm"
                            @click="unselectAll('leave')"
                            color="uno-color"
                            class="btn_submit mt-2 mb-4"
                            :disabled="temp_selected_leave.length == 0"
                          >
                            <mdb-icon far icon="check-square" size="md" />
                            Unselect All
                          </mdb-btn>

                          <mdb-btn
                            type="button"
                            rounded
                            size="sm"
                            @click="showDeleteModal('leave')"
                            color="uno-color"
                            class="btn_sm_delete mt-2 mb-4"
                            :disabled="temp_selected_leave.length == 0"
                          >
                            <mdb-icon icon="trash-alt" size="md" />
                            Delete Selected
                          </mdb-btn>
                        </div>
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col
                        v-for="(approval, index) in approval_list.leave
                          .approvals"
                        :key="index"
                        md="6"
                      >
                        <!--  -->
                        <div
                          class="approval_info_box mt-3"
                          style="cursor: pointer"
                          @click="
                            checkEmployee(
                              'leave',
                              approval.leave_approval_id,
                              index
                            )
                          "
                        >
                          <img
                            :src="approval.profile_image"
                            class="rounded-circle float-left"
                            width="50"
                            height="50"
                            v-if="approval.profile_image"
                            @error="imageLoadError"
                          />

                          <div class="float-left ml-3 mb-3">
                            <div class="employee_name">
                              {{ approval.epinfo_name | cutStringTableCell }}
                            </div>
                            <div class="employee_info_text_sec">
                              {{ approval.jp_name | cutStringTableCell }}
                            </div>
                            <div class="employee_info_text">
                              {{
                                approval.department_name | cutStringTableCell
                              }}
                            </div>
                          </div>

                          <mdb-btn
                            color="uno-color"
                            class="float-right close_btn"
                          >
                            <mdb-icon
                              icon="check-square"
                              size="2x"
                              class="uno-text close_btn_color mr-2"
                              :class="{ selected_employee: approval.selected }"
                            />
                          </mdb-btn>
                        </div>
                        <!--  -->
                        <!-- {{ approval }} -->
                      </mdb-col>
                    </mdb-row>
                  </template>
                  <template v-else>
                    <br />No Approvals<br /><br /><br /><br
                  /></template>
                </div>
              </template>
              <template :slot="'Overtime'">
                <div v-if="approval_list.overtime != ''">
                  <template v-if="approval_list.overtime.approvals.length > 0">
                    <mdb-row>
                      <mdb-col>
                        <div class="d-flex justify-content-end">
                          <mdb-btn
                            type="button"
                            rounded
                            size="sm"
                            @click="selectAll('overtime')"
                            color="uno-color"
                            class="btn_submit mt-2 mb-4"
                            :disabled="
                              temp_selected_overtime.length > 0 &&
                              temp_selected_overtime.length ==
                                approval_list.overtime.approvals.length
                            "
                          >
                            <mdb-icon icon="check-square" size="md" />
                            Select All
                          </mdb-btn>

                          <mdb-btn
                            type="button"
                            rounded
                            size="sm"
                            @click="unselectAll('overtime')"
                            color="uno-color"
                            class="btn_submit mt-2 mb-4"
                            :disabled="temp_selected_overtime.length == 0"
                          >
                            <mdb-icon far icon="check-square" size="md" />
                            Unselect All
                          </mdb-btn>

                          <mdb-btn
                            type="button"
                            rounded
                            size="sm"
                            @click="showDeleteModal('overtime')"
                            color="uno-color"
                            class="btn_sm_delete mt-2 mb-4"
                            :disabled="temp_selected_overtime.length == 0"
                          >
                            <mdb-icon icon="trash-alt" size="md" />
                            Delete Selected
                          </mdb-btn>
                        </div>
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col
                        v-for="(approval, index) in approval_list.overtime
                          .approvals"
                        :key="index"
                        md="6"
                      >
                        <!--  -->
                        <div
                          class="approval_info_box mt-3"
                          style="cursor: pointer"
                          @click="
                            checkEmployee(
                              'overtime',
                              approval.overtime_approval_id,
                              index
                            )
                          "
                        >
                          <img
                            :src="approval.profile_image"
                            class="rounded-circle float-left"
                            width="50"
                            height="50"
                            v-if="approval.profile_image"
                            @error="imageLoadError"
                          />

                          <div class="float-left ml-3 mb-3">
                            <div class="employee_name">
                              {{ approval.epinfo_name | cutStringTableCell }}
                            </div>
                            <div class="employee_info_text_sec">
                              {{ approval.jp_name | cutStringTableCell }}
                            </div>
                            <div class="employee_info_text">
                              {{
                                approval.department_name | cutStringTableCell
                              }}
                            </div>
                          </div>

                          <mdb-btn
                            color="uno-color"
                            class="float-right close_btn"
                          >
                            <mdb-icon
                              icon="check-square"
                              size="2x"
                              class="uno-text close_btn_color mr-2"
                              :class="{ selected_employee: approval.selected }"
                            />
                          </mdb-btn>
                        </div>
                        <!--  -->
                      </mdb-col>
                    </mdb-row>
                  </template>
                  <template v-else>
                    <br />No Approvals<br /><br /><br /><br
                  /></template>
                </div>
              </template>
            </mdb-tabs>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
        <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
      </div>
    </template>
  </div>
</template>

<script>
import Employee from "@/apis/Employee";
import ApprovalWorkflow from "@/apis/ApprovalWorkflow";
import { mapActions, mapGetters } from "vuex";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
// import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import {
  mdbCard,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTabs,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTabs,
    ModalDeleteConfimation,
    // EmployeeInfoBoxLayout,
  },
  name: "EmployeeApprovalList",

  data() {
    return {
      // temp_delete_approval_id: "",
      temp_delete_approval_type: "",
      // temp_user_index: "",
      approval_list: [],
      feedback_error: false,
      feedback_message: "",

      temp_selected_attendance: [],
      temp_selected_leave: [],
      temp_selected_overtime: [],
    };
  },
  props: ["employee_id"],
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    selectAll(type) {
      if (type == "attendance") {
        this.temp_selected_attendance = [];
        this.approval_list.attendance.approvals.forEach((element) => {
          element.selected = true;
          this.temp_selected_attendance.push(element.attendance_approval_id);
        });
      } else if (type == "leave") {
        this.temp_selected_leave = [];
        this.approval_list.leave.approvals.forEach((element) => {
          element.selected = true;
          this.temp_selected_leave.push(element.leave_approval_id);
        });
      } else if (type == "overtime") {
        this.temp_selected_overtime = [];
        this.approval_list.overtime.approvals.forEach((element) => {
          element.selected = true;
          this.temp_selected_overtime.push(element.overtime_approval_id);
        });
      }
    },
    unselectAll(type) {
      if (type == "attendance") {
        this.temp_selected_attendance = [];
        this.approval_list.attendance.approvals.forEach((element) => {
          element.selected = false;
        });
      } else if (type == "leave") {
        this.temp_selected_leave = [];
        this.approval_list.leave.approvals.forEach((element) => {
          element.selected = false;
        });
      } else if (type == "overtime") {
        this.temp_selected_overtime = [];
        this.approval_list.overtime.approvals.forEach((element) => {
          element.selected = false;
        });
      }
    },

    checkEmployee(type, val, user_idx_val) {
      if (type == "attendance") {
        this.approval_list.attendance.approvals[user_idx_val].selected =
          !this.approval_list.attendance.approvals[user_idx_val].selected;

        if (
          this.approval_list.attendance.approvals[user_idx_val].selected ===
          true
        ) {
          this.temp_selected_attendance.push(val);
        } else {
          this.temp_selected_attendance.splice(
            this.temp_selected_attendance.indexOf(val),
            1
          );
        }
      } else if (type == "leave") {
        this.approval_list.leave.approvals[user_idx_val].selected =
          !this.approval_list.leave.approvals[user_idx_val].selected;

        if (
          this.approval_list.leave.approvals[user_idx_val].selected === true
        ) {
          this.temp_selected_leave.push(val);
        } else {
          this.temp_selected_leave.splice(
            this.temp_selected_leave.indexOf(val),
            1
          );
        }
      } else if (type == "overtime") {
        this.approval_list.overtime.approvals[user_idx_val].selected =
          !this.approval_list.overtime.approvals[user_idx_val].selected;

        if (
          this.approval_list.overtime.approvals[user_idx_val].selected === true
        ) {
          this.temp_selected_overtime.push(val);
        } else {
          this.temp_selected_overtime.splice(
            this.temp_selected_overtime.indexOf(val),
            1
          );
        }
      }
    },

    showDeleteModal(type) {
      this.temp_delete_approval_type = type;
      this.$store.state.deleteModalVisible = true;
    },

    handleDeleteRecord() {
      this.$store.state.deleteModalVisible = false;
      this.feedback_error = false;

      let payload = {};

      if (this.temp_delete_approval_type != "") {
        if (this.temp_delete_approval_type == "attendance") {
          payload = {
            type: "attendance",
            approval_id: this.temp_selected_attendance,
          };
        } else if (this.temp_delete_approval_type == "leave") {
          payload = {
            type: "leave",
            approval_id: this.temp_selected_leave,
          };
        } else if (this.temp_delete_approval_type == "overtime") {
          payload = {
            type: "overtime",
            approval_id: this.temp_selected_overtime,
          };
        }

        ApprovalWorkflow.delete(payload).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              if (this.temp_delete_approval_type == "attendance") {
                const temp_records =
                  this.approval_list.attendance.approvals.filter((element) => {
                    if (element.selected == false) {
                      return element;
                    }
                  });

                this.approval_list.attendance.approvals = temp_records;
                this.temp_selected_attendance = [];
              } else if (this.temp_delete_approval_type == "leave") {
                const temp_records = this.approval_list.leave.approvals.filter(
                  (element) => {
                    if (element.selected == false) {
                      return element;
                    }
                  }
                );

                this.approval_list.leave.approvals = temp_records;
                this.temp_selected_leave = [];
              } else if (this.temp_delete_approval_type == "overtime") {
                const temp_records =
                  this.approval_list.overtime.approvals.filter((element) => {
                    if (element.selected == false) {
                      return element;
                    }
                  });

                this.approval_list.overtime.approvals = temp_records;
                this.temp_selected_overtime = [];
              }

              //
              this.temp_delete_approval_type = "";

              // this.fetchRecordList();
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }
          }
        });
      }
    },

    // handleDeleteapproval() {},
    imageLoadError(event) {
      event.target.src = "/default-user.png";
    },

    fetchRecordList() {
      this.updateLoading(true);

      if (this.employee_id != undefined) {
        if (Math.sign(this.employee_id) === 1) {
          Employee.viewApprovals(this.employee_id)
            .then((response) => {
              if (response.status === 200) {
                this.approval_list = response.data.data;
                const fetched_data = response.data.data;

                if (fetched_data.attendance.approvals.length > 0) {
                  this.approval_list.attendance.approvals =
                    fetched_data.attendance.approvals.map((fd) => {
                      return { ...fd, selected: false };
                    });
                }

                if (fetched_data.leave.approvals.length > 0) {
                  this.approval_list.leave.approvals =
                    fetched_data.leave.approvals.map((fd) => {
                      return { ...fd, selected: false };
                    });
                }

                if (fetched_data.overtime.approvals.length > 0) {
                  this.approval_list.overtime.approvals =
                    fetched_data.overtime.approvals.map((fd) => {
                      return { ...fd, selected: false };
                    });
                }

                this.updateLoading(false);
              }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.feedback_message = "Could not connected to server";
                this.feedback_error = true;

                this.updateLoading(false);
              } else {
                // console.log(error);
                this.updateLoading(false);
              }
            });
        }
      }
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
<style scoped>
.selected_employee {
  color: rgb(44, 0, 163);
}
</style>