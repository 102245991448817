<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <template v-if="!employee_id">
                <mdb-row class="mb-3">
                  <mdb-col offsetMd="2" md="8">
                    <div class="float-left mt-3 sec_title">Select Employee</div>
                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="float-right btn_outline_style"
                      icon="user-friends"
                      @click.native="modal = true"
                    >
                      {{ $t("payrolls.button.select_employee") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>

                <mdb-row v-if="selectedEmployeeData" class="mb-5">
                  <mdb-col offsetMd="2" md="8">
                    <div class="approval_info_box">
                      <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
                    </div>
                  </mdb-col>
                </mdb-row>
              </template>

              <mdb-row v-if="!isLoading">
                <mdb-col offsetMd="2" md="8">
                  <LeaveTypeAllocationDropdown
                    :employee_id="leave_data.el_employee_id"
                    :required="true"
                    :default_selected_value="leave_data.el_leave_type_id"
                    @updateLeaveTypeID="setLeaveTypeID"
                    :key="leave_type_counter"
                  />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    v-model="leave_data.el_leave_from"
                    label="From"
                    title="Select date"
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-date-picker-2
                    v-model="leave_data.el_leave_to"
                    label="To"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <label>Duration</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="duration-full"
                      name="el_leave_duration"
                      radioValue="full"
                      v-model="leave_data.el_leave_duration"
                      label="Full"
                    />
                    <mdb-input
                      type="radio"
                      id="duration-half"
                      name="el_leave_duration"
                      radioValue="half"
                      v-model="leave_data.el_leave_duration"
                      label="Half"
                    />
                  </mdb-form-inline>
                </mdb-col>
                <mdb-col md="4" v-show="leave_data.el_leave_duration == 'half'">
                  <label>Leave Shift</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="leave_shift-first"
                      name="el_leave_shift"
                      radioValue="first_half"
                      v-model="leave_data.el_leave_shift"
                      label="First Half"
                    />
                    <mdb-input
                      type="radio"
                      id="leave_shift-second"
                      name="el_leave_shift"
                      radioValue="second_half"
                      v-model="leave_data.el_leave_shift"
                      label="Second Half"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="number"
                    :label="$t('leaves.formInput.el_total_leaves')"
                    v-model="leave_data.el_total_leaves"
                    outline
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    :label="$t('general.formInput.description')"
                    v-model="leave_data.el_leave_description"
                    outline
                    required
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8" class="mb-4">
                  <mdb-btn
                    type="button"
                    color="uno-color"
                    class="btn_light_blue"
                    @click.native="showFileUploadModal = true"
                    icon="plus-square"
                    size="md"
                    rounded
                  >
                    Add New Attachment
                  </mdb-btn>
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-row>
                    <mdb-col                      
                      md="6"
                      lg="4"
                      v-for="(
                        attachment, counter
                      ) in leave_atachments"
                      v-bind:key="'svr' + counter"
                      class="mb-4"
                    >
                      <!-- {{attachment}} -->
                      <mdb-card cascade class="attachment_card_style">
                        <mdb-view hover cascade>
                           <a
                            :href="attachment.attachment_file_name"
                            target="_blank"
                          >
                          <template>
                            <mdb-card-image
                              v-if="
                                attachment.file_ext == 'png' ||
                                attachment.file_ext == 'gif' ||
                                attachment.file_ext == 'jpg' ||
                                attachment.file_ext == 'jpeg'
                              "
                              :src="attachment.attachment_file_name"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="
                                attachment.file_ext == 'doc' ||
                                attachment.file_ext == 'docx'
                              "
                              src="/doc.png"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="
                                attachment.file_ext == 'xls' ||
                                attachment.file_ext == 'xlsx'
                              "
                              src="/spreadsheet.png"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="
                                attachment.file_ext == 'ppt' ||
                                attachment.file_ext == 'pptx'
                              "
                              src="/powerpoint.png"                              
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="attachment.file_ext == 'pdf'"
                              src="/pdf.png"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else
                              src="/unknowfile.png"
                              class="attachment_img_style"
                            ></mdb-card-image>
                          </template>
                           </a>
                        </mdb-view>
                        <mdb-card-body class="text-center" cascade>
                          
                          <mdb-btn
                            color="white"
                            class="attachment_card_del_btn"
                            @click="
                              deleteServerAttachment(attachment.attachment_id)
                            "
                            ><mdb-icon icon="trash-alt" size="md" />
                            {{ $t("general.button.delete") }}</mdb-btn
                          >
                        </mdb-card-body>
                      </mdb-card>
                    </mdb-col>
                  </mdb-row>
                </mdb-col>




                <!-- //////////////////////////// -->
                <mdb-col offsetMd="2" md="8">
                  <mdb-row>
                    <mdb-col
                      md="6"
                      lg="4"
                      v-for="(attachment, counter) in attachments"
                      v-bind:key="'lcl' + counter"
                      class="mb-4"
                    >
                      <mdb-card cascade class="attachment_card_style">
                        <mdb-view hover cascade>
                          <template v-if="temp_attachments_images[counter]">
                            <mdb-card-image
                              v-if="
                                temp_attachments_images[counter].type ==
                                  'png' ||
                                temp_attachments_images[counter].type ==
                                  'gif' ||
                                temp_attachments_images[counter].type ==
                                  'jpg' ||
                                temp_attachments_images[counter].type == 'jpeg'
                              "
                              :src="temp_attachments_images[counter].url"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="
                                temp_attachments_images[counter].type ==
                                  'doc' ||
                                temp_attachments_images[counter].type == 'docx'
                              "
                              src="/doc.png"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="
                                temp_attachments_images[counter].type ==
                                  'xls' ||
                                temp_attachments_images[counter].type == 'xlsx'
                              "
                              src="/spreadsheet.png"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="
                                temp_attachments_images[counter].type ==
                                  'ppt' ||
                                temp_attachments_images[counter].type == 'pptx'
                              "
                              src="/powerpoint.png"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="
                                temp_attachments_images[counter].type == 'pdf'
                              "
                              src="/pdf.png"                              
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else
                              src="/unknowfile.png"                              
                              class="attachment_img_style"
                            ></mdb-card-image>
                          </template>
                        </mdb-view>
                        <mdb-card-body class="text-center" cascade>
                          {{temp_attachments_images[counter].name | filename_str_limit}}
                          <mdb-btn
                            color="white"
                            class="attachment_card_del_btn"
                            @click="deleteAttachment(counter)"
                            ><mdb-icon icon="trash-alt" size="md" />
                            {{ $t("general.button.delete") }}</mdb-btn
                          >
                        </mdb-card-body>
                      </mdb-card>
                    </mdb-col>
                  </mdb-row>
                </mdb-col>
                <!-- //////////////////////////// -->

              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="sm"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>

        <template v-if="this.leave_data.ot_id != ''">
          <div
            v-if="
              this.leave_all_data.el_request_by != 'behalf' &&
              this.approved_logs_index.length > 0
            "
          >
            <mdb-card>
              <mdb-card-body>
                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <mdb-card-title>Approved By</mdb-card-title>
                    <div
                      v-for="(obj_key, index) in approved_logs_index"
                      :key="index"
                    >
                      <mdb-row class="mt-5 mb-4">
                        <mdb-col md="2" col="3"> Level {{ obj_key }} </mdb-col>
                        <mdb-col md="10" col="9">
                          <div
                            style="border-top: 1px solid #dedddd"
                            class="mt-2"
                          ></div>
                        </mdb-col>
                      </mdb-row>
                      <ApprovedUserLayout :users="approved_logs[obj_key]" />
                      <hr v-show="approved_logs.length - 1 > index" />
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-card-body>
            </mdb-card>
          </div>

          <div
            v-if="
              this.leave_all_data.el_request_by != 'behalf' &&
              this.left_approval_log_index.length > 0
            "
          >
            <mdb-card>
              <mdb-card-body>
                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <div class="float-left">
                      <mdb-card-title>Pending Approvals</mdb-card-title>
                    </div>
                    <div class="float-right">
                      <mdb-btn
                        class="btn_back"
                        color="uno-color"
                        @click="handleBehalfApproval('cancel')"
                        >Cancel</mdb-btn
                      >
                      <mdb-btn
                        outline="primary"
                        class="btn_outline_style"
                        @click="handleBehalfApproval('level')"
                        >Approve Level {{ left_approval_log_index[0] }}</mdb-btn
                      >
                      <mdb-btn
                        color="uno-color"
                        class="btn_submit"
                        @click="handleBehalfApproval('all')"
                      >
                        Approve all
                      </mdb-btn>
                    </div>
                    <div style="clear: both"></div>
                    <div
                      v-for="(obj_key, index) in left_approval_log_index"
                      :key="index"
                    >
                      <mdb-row class="mt-5 mb-4">
                        <mdb-col md="2" col="3"> Level {{ obj_key }} </mdb-col>
                        <mdb-col md="10" col="9">
                          <div
                            style="border-top: 1px solid #dedddd"
                            class="mt-2"
                          ></div>
                        </mdb-col>
                      </mdb-row>

                      <mdb-row class="mb-3">
                        <mdb-col
                          md="6"
                          v-for="(user, index) in left_approval_log[obj_key]"
                          :key="index"
                        >
                          <div class="approval_info_box">
                            <EmployeeInfoBoxLayout :user="user" />
                          </div>
                        </mdb-col>
                      </mdb-row>
                      <hr v-show="left_approval_log_index.length - 1 > index" />
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-card-body>
            </mdb-card>
          </div>
        </template>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />

    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapSingleSelectTable
          @updateSelectedEmployee="setSelectedEmployee"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>


    <mdb-modal
      size="size"
      :show="showDeleteAttachmentModal"
      @close="showDeleteAttachmentModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          <slot name="header"> Confirmation ! </slot>
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <slot name="body">
          Are you sure to delete this attachment ? This action can not be undo
          after confirmation.
        </slot>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="secondary"
          size="sm"
          @click.native="showDeleteAttachmentModal = false"
          >No</mdb-btn
        >
        <mdb-btn color="primary" @click="handleDeleteServerAttachment" size="sm"
          >Yes</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>



    <mdb-modal :show="showFileUploadModal" @close="showFileUploadModal = false">
      <mdb-modal-header class="text-center">
        <mdb-modal-title tag="h4" bold class="w-100"
          >Select File To Upload</mdb-modal-title
        >
      </mdb-modal-header>
      <mdb-modal-body class="mx-3 grey-text">
        <mdb-file-input @getValue="getFileInputValue" btnColor="light-blue" accept="image/*,application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,
text/plain"/>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn color="primary" @click="handelSelectFileAction"
          >Select File</mdb-btn
        >
        <mdb-btn @click.native="showFileUploadModal = false">Cancel</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

  </div>
</template>
<script>
import Leaves from "@/apis/Leaves";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import LeaveTypeAllocationDropdown from "@/components/LeaveTypeAllocationDropdown";

import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import ApprovedUserLayout from "@/components/ApprovedUserLayout";
import { mapActions, mapGetters } from "vuex";
import { randomString } from "@/utilities/treeBuilder";

import EmployeeListBoostrapSingleSelectTable from "@/components/EmployeeListBoostrapSingleSelectTable";
import {
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  mdbInput,
  mdbFormInline,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  
  mdbFileInput,
  mdbView,
  mdbCardImage,
} from "mdbvue";

export default {
  name: "EmployeeAttendanceForm",
  components: {
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbDatePicker2,
    mdbInput,
    mdbFormInline,
    ModalDeleteConfimation,
    LeaveTypeAllocationDropdown,
    EmployeeInfoBoxLayout,
    ApprovedUserLayout,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeListBoostrapSingleSelectTable,
    
  mdbFileInput,
  mdbView,
  mdbCardImage,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      leave_data: {
        el_id: "",
        el_employee_id: this.employee_id ? this.employee_id : "",
        el_leave_type_id: "",
        el_leave_from: "",
        el_leave_to: "",
        el_leave_duration: "",
        el_leave_shift: "",
        el_total_leaves: "",
        el_leave_description: "",
        unique_key: "",
        el_attachments: [],
      },
      leave_atachments:[],
      logs: [],

      leave_all_data: [],
      approved_logs: [],
      approved_logs_index: [],
      // approver_list: [],
      left_approval_log: [],
      left_approval_log_index: [],

      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",

      leave_type_counter: 0,

      selectedEmployeeData: null,
      modal: false,


      showFileUploadModal: false,
      temp_file_val: {},
      attachments: [],
      temp_attachments_images: [],

      showDeleteAttachmentModal: false,
      temp_delete_attachment_id: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.leave_data.el_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    setSelectedEmployee(val) {
      this.modal = false;

      if (this.leave_data.el_employee_id != val.employee_id) {
        this.selectedEmployeeData = val;
        this.leave_data.el_employee_id = val.employee_id;
        this.leave_data.el_leave_type_id = "";
        this.leave_type_counter++;
      }
    },
    setLeaveTypeID(val) {
      this.leave_data.el_leave_type_id = val;
    },
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },


    deleteServerAttachment(attachment_id) {
      this.temp_delete_attachment_id = attachment_id;
      this.showDeleteAttachmentModal = true;
    },

     handleDeleteServerAttachment() {
      if (
        this.temp_delete_attachment_id != "" &&
        this.temp_delete_attachment_id > 0
      ) {
        Leaves.deleteAttachment({
          attachment_id: this.temp_delete_attachment_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              this.leave_atachments =
                this.leave_atachments.filter((item) => {
                  return item.attachment_id != this.temp_delete_attachment_id;
                });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
      this.showDeleteAttachmentModal = false;
    },

    handelSelectFileAction() {
      this.attachments.push(this.temp_file_val);
      this.temp_attachments_images.push({
        type: this.temp_file_val.name.split(".").pop().toLowerCase(),
        url: URL.createObjectURL(this.temp_file_val),
        name: this.temp_file_val.name
      });
      this.showFileUploadModal = false;
    },

    getFileInputValue(val) {
      // console.log(val[0])
      this.temp_file_val = val[0];
    },


     deleteAttachment(counter) {
      this.attachments.splice(counter, 1);
    },



    handleBehalfApproval(val) {
      this.formSubmitting = true;
      this.deleteSubmitted = true;

      let status_val = "";
      if (val == "all") {
        status_val = "approve_level_all";
      } else if (val == "level") {
        status_val = "approve_one_level";
      } else {
        status_val = "reject";
      }

      Leaves.behalf_approval({
        el_id: this.leave_data.el_id,
        status: status_val,
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            this.fetchRecord();

            this.formSubmitting = false;
            this.deleteSubmitted = false;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }
        }
      });
    },

    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          Leaves.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  //  console.log(response.data.data)

                  let el_id,
                    el_employee_id,
                    el_leave_type_id,
                    el_leave_from,
                    el_leave_to,
                    el_leave_duration,
                    el_leave_shift,
                    el_total_leaves,
                    el_leave_description,
                    unique_key;

                  ({
                    el_id,
                    el_employee_id,
                    el_leave_type_id,
                    el_leave_from,
                    el_leave_to,
                    el_leave_duration,
                    el_leave_shift,
                    el_total_leaves,
                    el_leave_description,
                    unique_key,
                  } = response.data.data);

                  this.leave_data.el_id = el_id;
                  this.leave_data.el_employee_id = el_employee_id;
                  this.leave_data.el_leave_type_id = el_leave_type_id;
                  this.leave_data.el_leave_from = el_leave_from;
                  this.leave_data.el_leave_to = el_leave_to;
                  this.leave_data.el_leave_duration = el_leave_duration;
                  this.leave_data.el_leave_shift = el_leave_shift;
                  this.leave_data.el_total_leaves = el_total_leaves;
                  this.leave_data.el_leave_description = el_leave_description;
                  this.leave_data.unique_key = unique_key;

                  this.leave_atachments = response.data.leave_atachments;

                  // console.log(this.leave_atachments)

                  this.leave_all_data = response.data.data;

                  //preparation
                  this.approved_logs = {};
                  this.approved_logs_index = [];

                  if (response.data.leave_approve_logs.length > 0) {
                    let tmp_data_list = {};

                    for (const tmp_data of response.data.leave_approve_logs) {
                      const temp_key = tmp_data.lpl_level;
                      if (temp_key in tmp_data_list) {
                        tmp_data_list[temp_key].push(tmp_data);
                      } else {
                        this.approved_logs_index.push(temp_key);
                        tmp_data_list[temp_key] = [];
                        tmp_data_list[temp_key].push(tmp_data);
                      }
                    }
                    this.approved_logs = tmp_data_list;
                  } else {
                    this.approved_logs = {};
                  }

                  this.left_approval_log = {};
                  this.left_approval_log_index = [];
                  if (response.data.left_approve_level_to_approve.length > 0) {
                    let tmp_data_list = {};

                    for (const tmp_data of response.data
                      .left_approve_level_to_approve) {
                      const temp_key = tmp_data.leave_approval_level;
                      if (temp_key in tmp_data_list) {
                        tmp_data_list[temp_key].push(tmp_data);
                      } else {
                        this.left_approval_log_index.push(temp_key);
                        tmp_data_list[temp_key] = [];
                        tmp_data_list[temp_key].push(tmp_data);
                      }
                    }

                    this.left_approval_log = tmp_data_list;
                  } else {
                    this.left_approval_log = {};
                  }
                  ////////////

                  if (!this.employee_id) {
                    this.selectedEmployeeData = {
                      epinfo_name: response.data.data.epinfo_name,
                      employee_code: response.data.data.employee_code,
                      profile_image: response.data.data.profile_image,
                      department_name: response.data.data.department_name,
                      jp_name: response.data.data.jp_name,
                      rt_name: response.data.data.rt_name,
                    };
                    //emp info
                  }

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    employeeLeaveList: {
                      employee_id: this.leave_data.el_employee_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "employeeLeaveList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "employeeLeaveList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "employeeLeaveList" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      this.leave_data.unique_key = randomString(32);

      


      ////////////////////////
      let formData = new FormData();
      formData.append("el_id",this.leave_data.el_id);
      formData.append("el_employee_id",this.leave_data.el_employee_id);
      formData.append("el_leave_type_id",this.leave_data.el_leave_type_id);
      formData.append("el_leave_from",this.leave_data.el_leave_from);
      formData.append("el_leave_to",this.leave_data.el_leave_to);
      formData.append("el_leave_duration",this.leave_data.el_leave_duration);

      if (this.leave_data.el_leave_duration != "full") {
        formData.append("el_leave_shift",this.leave_data.el_leave_shift);
      }

      formData.append("el_total_leaves",this.leave_data.el_total_leaves);
      formData.append("el_leave_description",this.leave_data.el_leave_description);
      formData.append("unique_key",this.leave_data.unique_key);

      if (this.attachments.length > 0) {
        for (let i = 0; i < this.attachments.length; i++) {
          formData.append("el_attachments[]", this.attachments[i]);
        }
      }

      ////////////////////////

      Leaves.save(formData).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            // if (this.leave_data.el_id != "") {
            //   this.$notify.success({
            //     message: response.data.message,
            //     position: "top right",
            //     timeOut: 5000,
            //   });
            //   this.$router.push({
            //     name: "employeeLeaveList",
            //     params: { employee_id: this.leave_data.el_employee_id },
            //   });
            // } else {
            //   this.$notify.success({
            //     message: response.data.message,
            //     position: "top right",
            //     timeOut: 5000,
            //   });
            //   this.resetForm();
            // }
            if (this.employee_id) {
              this.$router.push({
                name: "employeeLeaveList",
                params: { employee_id: this.leave_data.el_employee_id },
              });
            } else {
              this.$router.push({ name: "listManageLeaves" });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
    },
    redirectBackAction() {
      // this.$router.push({
      //   name: "employeeLeaveList",
      //   params: { employeeLeaveList: this.leave_data.el_employee_id },
      // });
      if (this.employee_id) {
        this.$router.push({
          name: "employeeLeaveList",
          params: { employee_id: this.leave_data.el_employee_id },
        });
      } else {
        this.$router.push({ name: "listManageLeaves" });
      }
    },
    resetAlert() {
      this.feedback_error = false;
    },
    // resetForm() {
    //   Object.keys(this.leave_data).forEach((key) => {
    //     this.leave_data[key] = "";
    //   });

    //   this.leave_data.el_employee_id = this.employee_id;
    // },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.leave_data.el_id > 0) {
        Leaves.delete({
          el_id: this.leave_data.el_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              // this.$router.push({
              //   name: "employeeLeaveList",
              //   params: {
              //     _id: this.leave_data.el_employee_id,
              //   },
              // });
              if (this.employee_id) {
                this.$router.push({
                  name: "employeeLeaveList",
                  params: { employee_id: this.leave_data.el_employee_id },
                });
              } else {
                this.$router.push({ name: "listManageLeaves" });
              }
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      employeeLeaveList: {
        employee_id: this.leave_data.el_employee_id,
      },
    };

    this.fetchRecord();
  },
};
</script>

<style scoped>
.attachment_img_style{  
  height: 150px !important;  
}
.card.card-cascade .view.view-cascade {
    border-radius: 0.25rem;
    box-shadow: none !important;
    padding: 15px;
}
.attachment_card_style{
  background: rgb(243, 243, 243);
  box-shadow: none !important;
}
.attachment_card_del_btn{
  padding: 8px 0px !important;
  width: 95%;
  border-radius: 5px !important;
  color: red;  
}
.card-body {    
  padding: 10px !important;
}
</style>