<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <mdb-card class="mb-5">
        <mdb-card-body>
          <form @submit.prevent="handleFormSubmit">
            <mdb-row v-if="selectedEmployeeData" class="mb-5">
              <mdb-col offsetMd="2" md="8">
                <div class="approval_info_box">
                  <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
                </div>
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col offsetMd="2" md="4">
                <mdb-date-picker-2
                  v-model="overtime_data.date"
                  label="Date"
                  title="Select date"
                />
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col offsetMd="2" md="4">
                <mdb-time-picker-2
                  id="timePickerFrom"
                  label="Select your time"
                  v-model="overtime_data.start_time"
                />
              </mdb-col>
              <mdb-col md="4">
                <mdb-time-picker-2
                  id="timePickerTo"
                  label="Select your time"
                  v-model="overtime_data.end_time"
                />
              </mdb-col>

              <mdb-col offsetMd="2" md="8">
                <mdb-input
                  type="textarea"
                  :label="$t('general.formInput.description')"
                  v-model="overtime_data.description"
                  outline
                  required
                />
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <mdb-btn
                  type="button"
                  class="btn_back"
                  color="uno-color"
                  @click="redirectBackAction"
                >
                  <mdb-icon icon="arrow-left" size="md" />
                  {{ $t("general.button.go_back") }}
                </mdb-btn>

                <mdb-btn
                  type="Submit"
                  color="uno-color"
                  class="btn_submit"
                  :disabled="formSubmitting"
                >
                  <mdb-icon
                    icon="file-upload"
                    size="md"
                    v-show="!formSubmitting"
                  />

                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="formSubmitting"
                  ></span>
                  {{ $t("general.button.submit") }}
                </mdb-btn>
              </mdb-col>
            </mdb-row>
          </form>
        </mdb-card-body>
      </mdb-card>
    </template>
  </div>
</template>
<script>
import Overtime from "@/apis/Overtime";
import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";

import {
  mdbCard,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  mdbTimePicker2,
  mdbInput,
} from "mdbvue";

export default {
  name: "OvertimeApprovalUpdateForm",
  components: {
    mdbCard,
    mdbCardBody,

    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    mdbDatePicker2,
    mdbTimePicker2,
    mdbInput,
    EmployeeInfoBoxLayout,
  },
  props: ["data"],
  data() {
    return {
      overtime_data: {
        id: "",
        date: "",
        start_time: "",
        end_time: "",
        description: "",
      },

      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",

      selectedEmployeeData: {},
    };
  },
  computed: {},

  methods: {
    // const rst = this.parseDateTimeString(ot_start_from);

    //                 this.overtime_data.ot_start_from = rst.time;

    parseDateString(val) {
      const data = new Date(val);
      let month = data.getMonth() + 1;
      let day = data.getDate();
      let year = data.getFullYear();
      if (day <= 9) day = "0" + day;
      if (month < 10) month = "0" + month;
      return year + "-" + month + "-" + day;
    },

    parseTimeString(val) {
      const data = new Date(val);
      let hour = data.getHours();
      let minutes = data.getMinutes();
      let ampm = "am";
      if (hour > 12) {
        hour = hour % 12;
        ampm = "pm";
      }

      if (hour == 0) {
        hour = 12;
      }

      if (hour <= 9) hour = "0" + hour;
      if (minutes <= 9) minutes = "0" + minutes;
      return hour + ":" + minutes + ampm;
    },

    parseTimeString2(val) {
      if (val != "") {
        let temp_hia =
          val.substring(0, 5) + " " + val.substring(5, 7).toUpperCase();

        return temp_hia;

        // const data = new Date('2021-01-01 '+ temp_hia);
        // let hour = data.getHours();
        // let minutes = data.getMinutes();
        // let ampm = "Am";

        // if (hour > 12) {
        //   hour = hour % 12;
        //   ampm = "Pm";
        // }

        // if (hour == 0 ) {
        //   hour = 12
        // }

        // if (hour <= 9) hour = "0" + hour;
        // if (minutes <= 9) minutes = "0" + minutes;
        // return hour + ":" + minutes + ' ' + ampm;
      }
      return val;
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      const sendData = {
        id: this.overtime_data.id,
        date: this.overtime_data.date,
        start_time: this.parseTimeString2(this.overtime_data.start_time),
        end_time: this.parseTimeString2(this.overtime_data.end_time),
        description: this.overtime_data.description,
      };

      Overtime.submitUpdateInApproval(sendData).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            this.$emit("updateSuccessAction", {
              id: sendData.id,
              date: sendData.date,
              start_from: sendData.start_time,
              end_at: sendData.end_time,
              description: sendData.description,
            });

            // this.$router.push({ name: "listManageOvertime" });
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
    },
    redirectBackAction() {
      this.$emit("updateBackAction");
    },
    resetAlert() {
      this.feedback_error = false;
    },
  },
  created() {
    // this.parseTimeString2('12:46pm');

    this.selectedEmployeeData = {
      epinfo_name: this.data.name,
      jp_name: this.data.position,
      department_name: this.data.department,
      profile_image: this.data.image,
    };

    this.overtime_data = {
      id: this.data.id,
      date: this.parseDateString(this.data.date),
      start_time: this.parseTimeString(
        this.data.date + " " + this.data.start_from
      ),
      end_time: this.parseTimeString(this.data.date + " " + this.data.end_at),
      description: this.data.description,
    };
  },
};
</script>