<template>
  <div>
    <div class="employeeinfo_sm_section mb-4">
      <img
        :src="employee_data.epinfo_image"
        class="rounded-circle float-left mr-3"
        width="40"
        height="40"        
        @error="imageLoadError"
      />
      <div class="employee_info_header">{{employee_data.epinfo_name}}</div>
      <div class="employee_info_grey_smtext">
        {{employee_data.jp_name}}({{employee_data.rt_name}}) 
      </div>
    </div>
    <div style="clear: both"></div>
    <div class="mb-4">
      <!-- <EmployeeButtonGroup /> -->
      <router-link :to="{ name: 'viewEmployeeInfo' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.summary_info")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'viewEmployeePersonalInfo' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.personal_info")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'familyInfoList' }">
        <mdb-btn rounded size="sm">
          {{ $t("employees.button.family_info") }}
        </mdb-btn>
      </router-link>

      <router-link :to="{ name: 'languageInfoList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.lang_info")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'educationInfoList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.education_info")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'trainingInfoList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.training_info")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'occupationInfoList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.occupation_history")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'bankInfoList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.bank_info")
        }}</mdb-btn>
      </router-link>
      <router-link :to="{ name: 'viewTaxSSB' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.tax_ssb")
        }}</mdb-btn></router-link
      >
      <router-link :to="{ name: 'employeeDocumentList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.attachements")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'contractList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.contracts")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'workingHistoryList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.working_history")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'insuranceList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.insurance")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'employeeAttendancePolicyList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.attendance_policy")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'employeeOvertimePolicyList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.overtime_policy")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'employeeAllowancePolicyList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.allowance_policy")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'employeeTimetableList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.timetable")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'employeeAttendanceList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.attendances")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'employeeLeaveList' }">
        <mdb-btn rounded size="sm">{{ $t("employees.button.leaves") }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'employeeLeaveBalance' }">
        <mdb-btn rounded size="sm">{{ $t("employees.button.leave_balance") }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'employeeOvertimeList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.overtimes")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'actionInfoList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.actions")
        }}</mdb-btn>
      </router-link>
      <router-link :to="{ name: 'employeeApproverList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.approvers")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'employeeApprovalList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.approvals")
        }}</mdb-btn>
      </router-link>

      <router-link :to="{ name: 'activityEmployeeList' }">
        <mdb-btn rounded size="sm">My Activity Employees</mdb-btn>
      </router-link>
      <router-link :to="{ name: 'additionList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.addition")
        }}</mdb-btn>
      </router-link>
      <router-link :to="{ name: 'deductionList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.deduction")
        }}</mdb-btn>
      </router-link>
      <router-link :to="{ name: 'employeePayslipList' }">
        <mdb-btn rounded size="sm">{{
          $t("employees.button.payslips")
        }}</mdb-btn>
      </router-link>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
// import EmployeeButtonGroup from "@/components/EmployeeButtonGroup";
import { mdbBtn } from "mdbvue";
import Employee from "@/apis/Employee";
export default {
  name: "EmployeeLayout",
  components: {
    // EmployeeButtonGroup,
    // mdbRow,
    // mdbCol,
    mdbBtn,
  },
  props: ["employee_id"],
  data() {
    return {
      current_employee_id: 0,
      employee_data: {
        employee_id: "-",
        epinfo_name: "-",
        employee_code: "-",
        department_name: "-",
        jp_name: "-",
        rt_name: "-",
        epinfo_image: "/default-user.png",
      },
    };
  },
  created() {
    if (this.current_employee_id != this.employee_id) {
      if (this.employee_id > 0) {
        this.current_employee_id = this.employee_id;

        Employee.viewShortInfoForBreadCrumb(this.current_employee_id).then(
          (response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.employee_data = response.data.data;
              }
            }
          }
        );
      }
    }
  },
  methods: {
    imageLoadError(event) {
      event.target.src = "/default-user.png";
    },
  },
};
</script>
<style scoped>
.btn-default {
  padding: 8px 18px !important;
  color: #253858;
  background-color: #ececf1 !important;
  border: 1px solid #e3e7eb;
}

.router-link-exact-active .btn-default,
.router-link-exact-active .btn-default:focus {
  color: white;
  background-color: #425cc7 !important;
}
/* .btn-default:focus,
.btn-default.focus {
  color: white;
  background-color: #425cc7 !important;
} */
.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  background-color: #ececf1 !important;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}
</style>