<template>
  <tr>
    <td scope="row" style="white-space: nowrap">
      <mdb-icon icon="caret-right" size="sm" class="activity_arrow pr-1" />
      {{ label }} :
    </td>
    <td width="99%">
      <template v-if="old_value_type != '' && old_value_type">
        <span
          @click="showModal(old_value)"
          v-if="old_value && old_value != ''"
          class="act_log_name"
          >Old {{old_value_type}} </span
        >
        <span v-else class="act_log_name">none </span>
      </template>

      <template v-else>
        <span class="act_log_name">
          {{ !old_value || old_value == "" ? "!" : old_value }}
        </span>
      </template>
      <mdb-icon icon="angle-right" size="sm" class="activity_arrow pr-1" />

      <template v-if="change_value_type != '' && change_value_type">
        <span
          @click="showModal(change_value)"
          v-if="change_value  && change_value != ''"
          class="act_log_name"
          >New {{change_value_type}}</span
        >
        <span v-else class="act_log_name">none</span>
      </template>

      <span v-else>
        {{ change_value == "" ? "!" : change_value }}
      </span>
    </td>
  </tr>
</template>

<script>
import { mdbIcon } from "mdbvue";
import moment from "moment";
export default {
  name: "log-item",
  props: ["dataItem"],
  components: {
    mdbIcon,
  },
  data() {
    return {
      label: "",
      old_value: "",
      old_value_type: "",
      change_value: "",
      change_value_type: "",
    };
  },
  methods: {
    showModal(srcval) {
      this.$emit("showModal", srcval);
    },
  },
  created() {
    // console.log(this.dataItem)
    this.label = this.dataItem.field_name;

    this.old_value_type = this.dataItem.type;
    this.change_value_type = this.dataItem.type;

    if (this.dataItem.type && this.dataItem.old_value != "") {
      this.old_value = this.dataItem.old_value;
    } else {
      if (moment(this.dataItem.old_value, "YYYY-MM-DD", true).isValid()) {
        this.old_value = moment(String(this.dataItem.old_value)).format(
          "DD MMM, YYYY"
        );
      } else {
        this.old_value = this.dataItem.old_value;
      }
    }

    if (this.dataItem.type && this.dataItem.change_value != "") {
      this.change_value = this.dataItem.change_value;
    } else {
      if (moment(this.dataItem.change_value, "YYYY-MM-DD", true).isValid()) {
        this.change_value = moment(String(this.dataItem.change_value)).format(
          "DD MMM, YYYY"
        );
      } else {
        this.change_value = this.dataItem.change_value;
      }
    }
  },
};
</script>
<style scoped>
table.table-sm td,
table.table-sm th {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.activity_arrow {
  color: #8590a2;
}
.act_log_name {
  font-family: "poppin_italic";
}
</style>