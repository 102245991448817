<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-4">
          <mdb-card-body>
            <mdb-row>
              <mdb-col md="7" style="margin-top: 25px">
                <img
                  :src="employee.profile_image"
                  class="rounded-circle float-left ml-3"
                  width="120"
                  height="120"
                  v-if="employee.profile_image"
                />

                <div class="float-left ml-4">
                  <!-- <div class="employee_name">{{ employee.epinfo_name }}</div>
                  <div class="employee_info_grey_text">{{ employee.employee_code }}</div>             
                  <div class="employee_info_semi_text">{{ employee.jp_name }}</div>
                  <div class="employee_info_grey_text">{{ employee.department_name }}</div>
                  <div class="employee_info_semi_text">{{ employee.rt_name }}</div> -->

                  <div class="employee_info_box_name">
                    {{ employee.epinfo_name }}
                  </div>
                  <div class="">{{ employee.employee_code }}</div>
                  <br />
                  <div class="">{{ employee.jp_name }}</div>
                  <div class="">{{ employee.department_name }}</div>
                  <div class="">{{ employee.rt_name }}</div>
                </div>
              </mdb-col>

              <mdb-col md="5" class="border-left border-light">
                <dl class="row" style="margin-bottom: 0">
                  <dt class="col-md-4">Employemnt</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{ employee.employee_employment_type }}
                  </dd>

                  <dt class="col-md-4">Join Date</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{ employee.employee_join_date }}
                  </dd>

                  <dt class="col-md-4">Company</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{ employee.company_name }}
                  </dd>

                  <dt class="col-md-4">Branch</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{ employee.cb_name }}
                  </dd>

                  <dt class="col-md-4">Category</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{
                      !employee.employee_category_name ||
                      employee.employee_category_name == ""
                        ? "-"
                        : employee.employee_category_name
                    }}
                  </dd>
                  <dt class="col-md-4">Group</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{
                      !employee.employee_group_name ||
                      employee.employee_group_name == ""
                        ? "-"
                        : employee.employee_group_name
                    }}
                  </dd>
                </dl>
              </mdb-col>
            </mdb-row>
          </mdb-card-body>
        </mdb-card>

        <mdb-row>
          <mdb-col md="6">
            <mdb-card class="mb-4">
              <mdb-card-header class="employee_info_header"
                >Self Service Info</mdb-card-header
              >
              <mdb-card-body>
                <form @submit.prevent="handleSelfServiceFormSubmit">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.email')"
                    v-model="formEmployeeEmail"
                    outline
                    :disabled="editSelfService === false"
                  />

                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.password')"
                    v-model="formEmployeePassword"
                    outline
                    :disabled="editSelfService === false"
                  />

                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="editSelfService = true"
                    v-show="editSelfService === false"
                  >
                    <mdb-icon icon="edit" size="md" />
                    {{ $t("general.button.edit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="handleCancelEditSelfService"
                    v-show="editSelfService === true"
                  >
                    <mdb-icon icon="undo" size="md" />
                    {{ $t("general.button.cancel") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                    v-show="editSelfService === true"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </form>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>

          <mdb-col md="6">
            <mdb-card class="mb-4">
              <mdb-card-header class="employee_info_header"
                >Employee ID/ Code</mdb-card-header
              >
              <mdb-card-body>
                <form @submit.prevent="handleEmployeeCodeFormSubmit">
                  <mdb-input
                    type="text"
                    :label="$t('employees.formInput.employee_id')"
                    v-model="formEmployeeCode"
                    outline
                    :disabled="editEmployeeCode === false"
                  />

                  <div v-show="employee_code_feedback_message!=''" class="text-danger">
                    {{employee_code_feedback_message}}
                  </div>

                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="editEmployeeCode = true"
                    v-show="editEmployeeCode === false"
                  >
                    <mdb-icon icon="edit" size="md" />
                    {{ $t("general.button.edit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="handleCancelEditEmployeeCode"
                    v-show="editEmployeeCode === true"
                  >
                    <mdb-icon icon="undo" size="md" />
                    {{ $t("general.button.cancel") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                    v-show="editEmployeeCode === true"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </form>
              </mdb-card-body>
            </mdb-card>
          <!-- </mdb-col> -->




          <!--  -->
          <!-- <mdb-col md="6"> -->
            <mdb-card class="mb-4">
              <mdb-card-header class="employee_info_header"
                >Join Date Info</mdb-card-header
              >
              <mdb-card-body>
                <form @submit.prevent="handleEmployeeJoinDateFormSubmit">
                  <!-- <mdb-input
                    type="text"
                    label="Join Date"
                    v-model="formEmployeeJoinDate"
                    outline
                    :disabled="editEmployeeJoinDate === false"
                  /> -->

                  <mdb-date-picker-2
                  v-model="formEmployeeJoinDate"
                  label="Join Date"
                  title="Select date"
                  :disabled="editEmployeeJoinDate === false"
                />
                <div v-show="employee_join_date_feedback_message!=''" class="text-danger">
                  {{employee_join_date_feedback_message}}
                </div>

                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="editEmployeeJoinDate = true"
                    v-show="editEmployeeJoinDate === false"
                  >
                    <mdb-icon icon="edit" size="md" />
                    {{ $t("general.button.edit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="handleCancelEditEmployeeJoinDate"
                    v-show="editEmployeeJoinDate === true"
                  >
                    <mdb-icon icon="undo" size="md" />
                    {{ $t("general.button.cancel") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                    v-show="editEmployeeJoinDate === true"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </form>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
          <!--  -->
        </mdb-row>

        <mdb-row>
          <mdb-col md="6">
            <mdb-card class="mb-4">
              <mdb-card-header class="employee_info_header"
                >Personal Information</mdb-card-header
              >
              <mdb-card-body>
                <dl class="row">
                  <dt class="col-md-4">Gender</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{ employee.epinfo_gender }}
                  </dd>

                  <dt class="col-md-4">Date of Birth</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{
                      !employee.epinfo_date_of_birth ||
                      employee.epinfo_date_of_birth == ""
                        ? "-"
                        : employee.epinfo_date_of_birth | formatDateString
                    }}
                  </dd>

                  <dt class="col-md-4">Nationality</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{
                      !employee.epinfo_nationlity ||
                      employee.epinfo_nationlity == ""
                        ? "-"
                        : employee.epinfo_nationlity
                    }}
                  </dd>

                  <dt class="col-md-4">Race</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{
                      !employee.epinfo_race || employee.epinfo_race == ""
                        ? "-"
                        : employee.epinfo_race
                    }}
                  </dd>

                  <dt class="col-md-4">Religion</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{
                      !employee.epinfo_religion ||
                      employee.epinfo_religion == ""
                        ? "-"
                        : employee.epinfo_religion
                    }}
                  </dd>

                  <dt class="col-md-4">Marital Status</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{
                      !employee.epinfo_martial_status ||
                      employee.epinfo_martial_status == ""
                        ? "-"
                        : employee.epinfo_martial_status
                    }}
                  </dd>

                  <dt class="col-md-4">No Of Child</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{
                      !employee.epinfo_no_of_child ||
                      employee.epinfo_no_of_child == ""
                        ? "-"
                        : employee.epinfo_no_of_child
                    }}
                  </dd>
                </dl>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>

          <mdb-col md="6">
            <mdb-card class="mb-4">
              <mdb-card-header class="employee_info_header"
                >Contact Information</mdb-card-header
              >
              <mdb-card-body>
                <dl class="row">
                  <dt class="col-md-4">Phone no</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{
                      !employee.epinfo_phone_no ||
                      employee.epinfo_phone_no == ""
                        ? "-"
                        : employee.epinfo_phone_no
                    }}
                  </dd>
                  <dt class="col-md-4">Personal Email</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{
                      !employee.epinfo_email || employee.epinfo_email == ""
                        ? "-"
                        : employee.epinfo_email
                    }}
                  </dd>
                  <dt class="col-md-4">Current Address</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{
                      (!employee.epinfo_current_address ||
                        employee.epinfo_current_address == "") &&
                      (!employee.epinfo_current_township ||
                        employee.epinfo_current_township == "") &&
                      (!employee.epinfo_current_state ||
                        employee.epinfo_current_state == "") &&
                      (!employee.epinfo_current_country ||
                        employee.epinfo_current_country == "")
                        ? "-"
                        : employee.epinfo_current_address +
                          employee.epinfo_current_township +
                          employee.epinfo_current_state +
                          employee.epinfo_current_country
                    }}
                  </dd>
                  <dt class="col-md-4">Emergency Contact</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{
                      !employee.epinfo_emergency_contact_name ||
                      employee.epinfo_emergency_contact_name == ""
                        ? "-"
                        : employee.epinfo_emergency_contact_name
                    }}
                  </dd>
                  <dt class="col-md-4">Phone No</dt>
                  <dd class="col-md-8 employee_info_grey_text">
                    {{
                      !employee.epinfo_emergency_phone_no ||
                      employee.epinfo_emergency_phone_no == ""
                        ? "-"
                        : employee.epinfo_emergency_phone_no
                    }}
                  </dd>
                </dl>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>

        <mdb-card class="mb-4">
          <mdb-card-body>
            <!-- {{ employee }} -->

            <mdb-row>
              <mdb-col>
                <mdb-btn
                  type="button"
                  color="uno-color"
                  class="btn_delete"
                  :disabled="deleteSubmitted"
                  @click="showDeleteModal"
                >
                  <mdb-icon
                    icon="trash-alt"
                    far
                    size="md"
                    v-show="!deleteSubmitted"
                  />

                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="deleteSubmitted"
                  ></span>
                  {{ $t("general.button.delete") }}
                </mdb-btn>

                <mdb-btn
                  type="button"
                  color="uno-color"
                  class="btn_delete"
                  :disabled="logoutSubmitted"
                  @click="logoutModal = true"
                >
                  <mdb-icon
                    icon="trash-alt"
                    far
                    size="md"
                    v-show="!logoutSubmitted"
                  />

                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="logoutSubmitted"
                  ></span>
                  Logout User
                </mdb-btn>
              </mdb-col>
            </mdb-row>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>

    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />

    <mdb-modal :show="logoutModal" @close="logoutModal = false">
      <mdb-modal-header>
        <mdb-modal-title>Confirmation !</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        Are you sure to do logut user from all login devices ?
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="primary" @click="confirmLogoutAction" size="sm"
          >Yes</mdb-btn
        >

        <mdb-btn color="secondary" size="sm" @click.native="logoutModal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import Employee from "@/apis/Employee";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbInput,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbDatePicker2
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    ModalDeleteConfimation,
    mdbInput,

    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbDatePicker2
  },
  props: ["employee_id"],
  data() {
    return {

      editEmployeeJoinDate: false,
      formEmployeeJoinDate: "",

      editEmployeeCode: false,
      formEmployeeCode: "",
      editSelfService: false,
      formEmployeeEmail: "",
      formEmployeePassword: "",

      employee: null,
      formSubmitting: false,
      feedback_error: "",
      deleteSubmitted: false,
      
      employee_join_date_feedback_message: "",
      employee_code_feedback_message: "",
      employee_email_feedback_message: "",

      logoutModal: false,
      logoutSubmitted: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    confirmLogoutAction() {
      this.logoutSubmitted = true;
      const payload = {
        employee_id: this.employee_id,
      };

      Employee.LogoutUserSession(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: 'User Logout successfully from all devices',
              // message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });
          }
          this.logoutSubmitted = false;
          this.logoutModal = false;
        } else {
          this.$notify.error({
            message: "User Not Found",
            position: "top right",
            timeOut: 5000,
          });
          this.logoutSubmitted = false;
          this.logoutModal = false;
        }
      });
    },

    handleCancelEditSelfService() {
      this.editSelfService = false;
      this.formEmployeeEmail = this.employee.email;
      
      this.formEmployeePassword = '';
    },
    handleSelfServiceFormSubmit() {
      this.formSubmitting = true;
      this.employee_email_feedback_message = "";

      const payload = {
        employee_id: this.employee_id,
        email: this.formEmployeeEmail,
        password: this.formEmployeePassword,
      };

      Employee.updateEmployeeEmailAndPassword(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });
            this.employee.email = this.formEmployeeEmail;
            this.formEmployeePassword = '';
            this.editSelfService = false;
          } else if (response.data.code === 400) {
            let error_text = "";
            if (response.data.error.length > 0) {
              for (let i = 0; i < response.data.error.length; i++) {
                if (i == 0) error_text += response.data.error[i]["message"];
                else {
                  error_text += "<br>" + response.data.error[i]["message"];
                }
              }
            } else {
              error_text = response.data.error;
            }

            this.$notify.error({
              message: error_text,
              position: "top right",
              timeOut: 5000,
            });
          } else if (response.data.code === 500) {
            this.employee_email_feedback_message = "Error";
          }

          this.formSubmitting = false;
        }
      });
    },

    handleCancelEditEmployeeCode() {
      this.editEmployeeCode = false;
      this.formEmployeeCode = this.employee.employee_code;
      this.employee_code_feedback_message = "";
    },
    handleEmployeeCodeFormSubmit() {
      this.formSubmitting = true;
      this.employee_code_feedback_message = "";

      const payload = {
        employee_id: this.employee_id,
        employee_code: this.formEmployeeCode,
      };

      Employee.updateEmployeeCode(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });
            this.employee.employee_code = this.formEmployeeCode;
            this.editEmployeeCode = false;
          } else if (response.data.code === 500) {
            this.employee_code_feedback_message = "Error";
          }else {
            this.employee_code_feedback_message = response.data.error[0].message;
          }

          this.formSubmitting = false;
        }
      });
    },


    
    handleCancelEditEmployeeJoinDate() {
      this.editEmployeeJoinDate = false;
      this.formEmployeeJoinDate = this.employee.employee_join_date;
      this.employee_join_date_feedback_message = "";
    },
    handleEmployeeJoinDateFormSubmit() {
      this.formSubmitting = true;
      this.employee_join_date_feedback_message = "";

      const payload = {
        employee_id: this.employee_id,
        employee_join_date: this.formEmployeeJoinDate,
      };

      Employee.updateEmployeeJoinDate(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });
            this.employee.employee_join_date = this.formEmployeeJoinDate;
            this.editEmployeeJoinDate = false;
          } else if (response.data.code === 500) {
            this.employee_join_date_feedback_message = "Error";
          } else {
            this.employee_join_date_feedback_message = response.data.error[0].message;
          }

          this.formSubmitting = false;
        }
      });
    },

    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    fetchRecord() {
      if (this.employee_id != undefined) {
        if (Math.sign(this.employee_id) === 1) {
          this.updateLoading(true);
          Employee.view(this.employee_id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  this.employee = response.data.data;

                  this.formEmployeeCode = this.employee.employee_code;
                  this.formEmployeeEmail = this.employee.email;

                  this.formEmployeeJoinDate = this.employee.employee_join_date;

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listEmployee" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listEmployee" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listEmployee" });
        }
      } else {
        // redirect employee list
        this.$router.push({ name: "listEmployee" });
      }
    },

    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.employee.employee_id > 0) {
        Employee.delete({ employee_id: this.employee.employee_id }).then(
          (response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({ name: "listEmployee" });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          }
        );
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>
<style scoped>
dt {
  font-weight: 400;
}
</style>