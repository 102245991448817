import Api from './api'

export default{
    login(payload){
        return Api.post('api/auth/login', payload)
    },

    forgotPassword(payload){
        return Api.post('api/mobile_api/users/forgotpassword', payload)
    },
    
    resetPassword(payload){
        return Api.post('api/mobile_api/users/verify', payload)
    },

    verify_check(payload){
        return Api.post('api/mobile_api/users/verify_check', payload)
    },
}