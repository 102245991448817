<template>
  <section class="form-elegant">
    <mdb-row class="d-flex justify-content-center">
      <mdb-col md="6" lg="5">
        <mdb-card>
          <mdb-card-body class="mx-4">
            <form @submit.prevent="validateForm" novalidate>
              <div class="text-center">
                <mdb-card-avatar color="white" class="mx-auto"
                  ><img src="/unohr.png" class="" height="100"
                /></mdb-card-avatar>
              </div>
              <!-- rounded-circle -->
              <div class="text-center">
                <h3 class="dark-grey-text my-4">
                  <strong>Forgot Password</strong>
                </h3>
              </div>
              <div class="text-center">
                 <div class="alert alert-danger" role="alert" v-if="hasErrorFeedback">
                  <div v-if="typeof hasErrorFeedback === 'string'">
                    {{ hasErrorFeedback }}
                  </div>
                  <div
                    v-else
                    v-for="(error, index) in hasErrorFeedback"
                    :key="index"
                  >
                    {{ error.message }}
                  </div>

                </div>
              </div>

              <mdb-input
                type="text"
                label="Organization"
                required
                v-model="formValues.organization"
                :customValidation="validation.organization.validated"
                :isValid="validation.organization.valid"
                @change="validate('organization')"
                invalidFeedback="Please Fill in Organization."
              />

              <mdb-input
                type="email"
                label="Your email"
                required
                v-model="formValues.email"
                :customValidation="validation.email.validated"
                :isValid="validation.email.valid"
                @change="validate('email')"
                invalidFeedback="Please correct."
              />

              <p class="font-small blue-text d-flex justify-content-end pb-3">
                <router-link :to="{ name: 'Login' }" class="blue-text ml-1">
                  Try Login
                </router-link>
              </p>
              <div class="text-center mb-3">
                <mdb-btn
                  type="Submit"
                  gradient="blue"
                  rounded
                  class="btn-block z-depth-1a"
                  :disabled="doesFormSubmitted"
                >
                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="doesFormSubmitted"
                  ></span>

                  Reset Password
                </mdb-btn>
              </div>
            </form>
          </mdb-card-body>
          <mdb-modal-footer class="mx-5 pt-3 mb-1">
            <p class="font-small grey-text d-flex justify-content-end">
              Powered BY <a href="#" class="blue-text ml-1"> SBG</a>
            </p>
          </mdb-modal-footer>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import User from "../../apis/User";

const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

import {
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbBtn,
  mdbModalFooter,
  mdbCardAvatar,
  // mdbAlert,
} from "mdbvue";

export default {
  name: "Login",
  components: {
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbBtn,
    mdbModalFooter,
    mdbCardAvatar,
    // mdbAlert,
  },
  data() {
    return {
      formValues: {
        organization: "",
        email: "",
      },
      formSubmitting: false,
      formErrorFeedback: "",
      formValidation: true,
      validation: {
        organization: {
          valid: false,
          validated: false,
        },
        email: {
          valid: false,
          validated: false,
        },
      },
    };
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  computed: {
    doesFormSubmitted() {
      return this.formSubmitting;
    },
    hasErrorFeedback() {
      return this.formErrorFeedback;
    },
    ...mapGetters("user", ["loggedIn"]),
  },
  methods: {
    handleForgotPasswordSubmit() {
      this.formSubmitting = true;
      User.forgotPassword({
        organization:this.formValues.organization, 
        email: this.formValues.email,
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.code == 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({
                name: "Login",
              });
            } else if (response.data.code == 400) {
              if (response.data.error.length > 0) {
                this.formErrorFeedback = response.data.error;
              }              
              this.error = true;
              this.formSubmitting = false;
            } else if (response.data.code == 402) {
              // console.log(error)
              this.formErrorFeedback = response.data.message;              
              this.error = true;
              this.formSubmitting = false;
            }
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.formErrorFeedback = "Error: Network Error";
          } else if (error.response.status == 402) {
            // console.log(error)
            this.formErrorFeedback = error.response.message;
          }

          this.error = true;
          this.formSubmitting = false;
        });
    },
    validateForm() {
      this.formValidation = true;
      this.formErrorFeedback = "";
      Object.keys(this.formValues).forEach((key) => {
        this.validate(key);
      });

      if (this.formValidation === true) {
        this.handleForgotPasswordSubmit();
      }
    },
    validate(key) {

       if (key === "organization") {
        if (this.formValues[key]) {
          this.validation[key].valid = true;
        } else {
          this.validation[key].valid = false;
          this.formValidation = false;
        }
        this.validation[key].validated = true;
      }

      
      if (key === "email") {
        if (this.formValues[key] && re.test(String(this.formValues[key]).toLowerCase()) ) {
          this.validation[key].valid = true;
        } else {
          this.validation[key].valid = false;
          this.formValidation = false;
        }
        this.validation[key].validated = true;
      }
    },
  },
};
</script>

<style>
.form-elegant .font-small {
  font-size: 0.8rem;
}

.form-elegant .z-depth-1a {
  -webkit-box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26),
    0 4px 12px 0 rgba(121, 155, 254, 0.25);
  box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26),
    0 4px 12px 0 rgba(121, 155, 254, 0.25);
}

.form-elegant .z-depth-1-half,
.form-elegant .btn:hover {
  -webkit-box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28),
    0 4px 15px 0 rgba(36, 133, 255, 0.15);
  box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28),
    0 4px 15px 0 rgba(36, 133, 255, 0.15);
}
</style>