import Api from './api'

export default {
    employeeCount() {
        return Api.get('api/dashboard/employee_count')
    },

    attendanceCount() {
        return Api.get('api/dashboard/attendance_count')
    },
    
    getLateEarlyChartData(payload) {
        return Api.get('api/dashboard/employee_late_early', {params: payload})
    },    
    
    getLeaveChartData(payload) {
        return Api.get('api/dashboard/employee_leaves', {params: payload})
    }, 
    
    getUpcomingHolidays() {
        return Api.get('api/dashboard/upcoming_holidays')
    },

    getAnnouncements() {
        return Api.get('api/dashboard/announcements')
    },
    
}