<template>
  <mdb-select
    outline
    v-model="axiosOptions"
    :label="selectLabel"
    @getValue="updateSelectedValue"
  />
</template>

<script>
import { mdbSelect } from "mdbvue";
export default {
  name: "EmployeeSearchPeriodDropdown",
  components: {
    mdbSelect,
  },
  props: ["default_selected_value", "required", "resetSelected"],
  data() {
    return {
      axiosOptions: [],
      periodOptions: [
        { text: "Day(s)", value: "day", selected: false },
        { text: "Month(s)", value: "month", selected: false },
        { text: "Year(s)", value: "year", selected: false },
      ],
      selectLabel: "Select",
    };
  },
  mounted() {
    for (let option of this.periodOptions) {
      if (this.default_selected_value === option.value) {
        this.axiosOptions.push({
          text: option.text,
          value: option.value,
          selected: true,
        });
      } else {
        this.axiosOptions.push({
          text: option.text,
          value: option.value,
        });
      }
    }
  },
  methods: {
    updateSelectedValue(val) {
      if (val !== null) {
        this.$emit("updatePeriodValue", val);
      }
    },
  },
  watch: {
    resetSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.periodOptions = this.periodOptions.map((val) => ({
          text: val.text,
          value: val.value,
        }));
      }
    },
  },
};
</script>
