<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
<div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
    <template>
      <mdb-card class="mb-5">
        <mdb-card-body>
          <form @submit.prevent="handleFormSubmit">
            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <mdb-input
                  type="text"
                  :label="$t('general.formInput.name')"
                  v-model="allowance_type.at_name"
                  outline
                  required
                />
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <mdb-btn
                  type="button"
                  class="btn_back"
                  color="uno-color"
                  @click="redirectBackAction"
                >
                  <mdb-icon icon="arrow-left" size="md" />
                  {{ $t("general.button.go_back") }}
                </mdb-btn>

                <mdb-btn
                  type="Submit"
                  color="uno-color"
                  class="btn_submit"
                  :disabled="formSubmitting"
                >
                  <mdb-icon
                    icon="file-upload"
                    size="md"
                    v-show="!formSubmitting"
                  />

                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="formSubmitting"
                  ></span>
                  {{ $t("general.button.submit") }}
                </mdb-btn>
                <mdb-btn
                  type="button"            
                  :disabled="deleteSubmitted"
                  @click="showDeleteModal"
                  v-show="doesEditForm"
                  color="uno-color"
                  class="btn_delete float-right"
                >
                  <mdb-icon
                    icon="trash-alt"
                    far
                    size="md"
                    v-show="!deleteSubmitted"
                  />

                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="deleteSubmitted"
                  ></span>
                  {{ $t("general.button.delete") }}
                </mdb-btn>
              </mdb-col>
            </mdb-row>
          </form>          
        </mdb-card-body>
      </mdb-card>
    </template>
    </div>

    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord"/>
  </div>
</template>
<script>
import AllowanceType from "@/apis/AllowanceType";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";	
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    ModalDeleteConfimation
  },
  props: ["_id"],
  data() {
    return {
      allowance_type: {
        at_id: "",
        at_name: "",
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.allowance_type.at_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    showDeleteModal() {
        this.$store.state.deleteModalVisible = true;
      },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          AllowanceType.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)
                  let at_id, at_name;

                  // populate field
                  ({ at_id, at_name } = response.data.data);
                  this.allowance_type.at_id = at_id;
                  this.allowance_type.at_name = at_name;

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listAllowanceType" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listAllowanceType" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listAllowanceType" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      AllowanceType.save(this.allowance_type).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.allowance_type.at_id != "") {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "listAllowanceType" });
            } else {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({ name: "listAllowanceType" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.allowance_type).forEach((key) => {
        this.allowance_type[key] = "";
      });
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.allowance_type.at_id > 0) {
        AllowanceType.delete({ at_id: this.allowance_type.at_id })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({ name: "listAllowanceType" });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

      this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>