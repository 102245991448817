import Api from './api'

export default {
  getAllocations(payload) {
    return Api.get('api/mobile_api/leave/user_leaves/' + payload)
  },


  all(payload) {
    return Api.get('api/manage_leave/list', {params: payload})
  },

  save(payload) {
    return Api.post('api/manage_leave/data_entry', payload)
  },

  delete(payload) {
    return Api.post('api/manage_leave/delete', payload)
  },

  view(payload) {
    return Api.get('api/manage_leave/' + payload)
  },

  behalf_approval(payload){
    return Api.post('api/manage_leave/behalf_approval', payload)
  },


  getApprovalList(payload) {
    return Api.get('api/mobile_api/leaves_approve/list', { params: payload })
  },
  
  // getApprovalDetail(payload) {
  //   return Api.post('api/mobile_api/attendance_approve/detail', payload)
  // },
  
  submitApproval(payload) {
    return Api.post('api/mobile_api/leaves_approve/approval', payload)
  },

  getLeaveCalendarData(payload) {
    return Api.post('api/mobile_api/leaves_approve/calendar', payload)
  },

  
  getleaveBalance(payload) {
    return Api.get('api/mobile_api/leaves/user_leaves/'+ payload)
  },

  deleteAttachment(payload){
    return Api.post('api/manage_leave/delete_file', payload)       
  }

}