<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
    <template>
      <mdb-card class="mb-5">
        
        <mdb-card-body>
          <form @submit.prevent="handleFormSubmit">
            <mdb-row>

              

              <mdb-col offsetMd="2"  md="8">
                <LeaveTypeDropdown
                  @updateLeaveTypeID="setLeaveTypeID"
                  :default_selected_value="
                    leave_rule_detail.lpd_leave_type_id
                  "
                  :required="true"
                />
              </mdb-col>

              <mdb-col offsetMd="2"  md="8">
                <mdb-input
                  type="number"
                  :label=" $t('leave_rule_details.formInput.allocation_days') "
                  v-model="leave_rule_detail.lpd_allocation_days"
                  outline
                />
              </mdb-col>

              

              <mdb-col offsetMd="2"  md="8">
                <mdb-input
                  type="number"
                  :label=" $t('leave_rule_details.formInput.available_service_months') "
                  v-model="leave_rule_detail.lpd_available_service_months"
                  outline
                />
              </mdb-col>


              <mdb-col offsetMd="2"  md="8">
                <mdb-input
                  type="number"
                  :label=" $t('leave_rule_details.formInput.max_day_request') "
                  v-model="leave_rule_detail.lpd_max_day_request"
                  outline
                />
              </mdb-col>

              
              <mdb-col offsetMd="2" md="8" class="mb-4">
                <label class="form-label">
                  {{ $t("leave_rule_details.formInput.reset_on") }}
                </label>
                  <mdb-input
                    type="radio"
                    id="req-opt-calendar_year_end"
                    radioValue="calendar_year_end"
                    v-model="leave_rule_detail.lpd_reset_on"
                    :label="$t('leave_rule_details.formInput.calendar_year_end')"
                  />
                  <mdb-input
                    type="radio"
                    id="req-opt-no_reset"
                    radioValue="no_reset"
                    v-model="leave_rule_detail.lpd_reset_on"
                    :label="$t('leave_rule_details.formInput.no_reset')"
                  />
                  <mdb-input
                    type="radio"
                    id="req-opt-contract_end"
                    radioValue="contract_end"
                    v-model="leave_rule_detail.lpd_reset_on"
                    :label="$t('leave_rule_details.formInput.contract_end')"
                  />
                  <mdb-input
                    type="radio"
                    id="req-opt-every_service_months"
                    radioValue="every_service_months"
                    v-model="leave_rule_detail.lpd_reset_on"
                    :label="$t('leave_rule_details.formInput.every_service_months')"
                  />

                  <mdb-input
                  type="number"
                  :label=" $t('leave_rule_details.formInput.service_months_number') "
                  v-model="leave_rule_detail.lpd_reset_service_months"
                  outline
                   v-if="leave_rule_detail.lpd_reset_on == 'every_service_months'"
                />
              </mdb-col>


              <mdb-col offsetMd="2" md="8">
                <label class="form-label">
                  {{ $t("leave_rule_details.formInput.carry_leave") }}
                </label>
                <mdb-form-inline>
                  <mdb-input
                    type="radio"
                    id="req-opt-yes"
                    radioValue="no"
                    v-model="leave_rule_detail.lpd_carry_leave"
                    :label="$t('general.formInput.no')"
                  />
                  <mdb-input
                    type="radio"
                    id="req-opt-no"
                    radioValue="yes"
                    v-model="leave_rule_detail.lpd_carry_leave"
                    :label="$t('general.formInput.yes')"
                  />
                </mdb-form-inline>
                <br />
              </mdb-col>

              

              <mdb-col offsetMd="2" md="8">
                <mdb-input
                  type="number"
                  :label=" $t('leave_rule_details.formInput.max_carry_leaves') "
                  v-model="leave_rule_detail.lpd_max_carry_leaves"
                  outline
                  :disabled="leave_rule_detail.lpd_carry_leave=='no'"
                />
              </mdb-col>
            </mdb-row>

             <mdb-row>
              <mdb-col offsetMd="2" md="8">

            <mdb-btn
              type="button"
              class="btn_back"
              color="uno-color"
              @click="redirectBackAction"
            >
              <mdb-icon icon="arrow-left" size="md" />
              {{ $t("general.button.go_back") }}
            </mdb-btn>

            <mdb-btn type="Submit" color="uno-color" class="btn_submit" :disabled="formSubmitting">
              <mdb-icon icon="file-upload" size="md" v-show="!formSubmitting" />

              <span
                class="spinner-border-vcenter spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="formSubmitting"
              ></span>
              {{ $t("general.button.submit") }}
            </mdb-btn>


            <mdb-btn
            type="button"            
            :disabled="deleteSubmitted"
            @click="showDeleteModal"
            v-show="doesEditForm"
            color="uno-color"
            class="btn_delete float-right"             
          >
            <mdb-icon
              icon="trash-alt"
              far
              size="md"
              v-show="!deleteSubmitted"
            />

            <span
              class="spinner-border-vcenter spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-show="deleteSubmitted"
            ></span>
            {{ $t("general.button.delete") }}
          </mdb-btn>
              </mdb-col>
             </mdb-row>
          </form>

          
        </mdb-card-body>
      </mdb-card>
    </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord"/>
  </div>
</template>
<script>
import LeaveRuleDetail from "@/apis/LeaveRuleDetail";
import LogList from "@/components/logs/List";
import LeaveTypeDropdown from "@/components/LeaveTypeDropdown";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFormInline
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbFormInline,
    LeaveTypeDropdown,
    ModalDeleteConfimation
  },
  props: ["_id", "leave_policy_id"],
  data() {
    return {
      leave_rule_detail: {
        lpd_id: "",
        lpd_leave_policy_id: this.leave_policy_id != "undefined" ? this.leave_policy_id : "",
        lpd_leave_type_id : "", 
        lpd_status : "enabled", 
        lpd_allocation_days : "", 
        lpd_available_service_months : "", 
        lpd_max_day_request : "", 
        lpd_reset_on : "calendar_year_end", 
        lpd_carry_leave : "no", 
        lpd_max_carry_leaves : "",
        lpd_reset_service_months:""
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.leave_rule_detail.lpd_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
        this.$store.state.deleteModalVisible = true;
      },
    setLeaveTypeID(val) {
      this.leave_rule_detail.lpd_leave_type_id = val;
    },
    fetchRecord() {
      this.updateLoading(true);
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          LeaveRuleDetail.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)
                  let lpd_id,
                    lpd_leave_policy_id,
                    lpd_leave_type_id, 
                     
                    lpd_allocation_days, 
                    lpd_available_service_months, 
                    lpd_max_day_request, 
                    lpd_reset_on, 
                    lpd_carry_leave, 
                    lpd_max_carry_leaves,
                    lpd_reset_service_months;

                  // populate field
                  ({
                    lpd_id,
                    lpd_leave_policy_id,
                    lpd_leave_type_id, 
                     
                    lpd_allocation_days, 
                    lpd_available_service_months, 
                    lpd_max_day_request, 
                    lpd_reset_on, 
                    lpd_carry_leave, 
                    lpd_max_carry_leaves,
                    lpd_reset_service_months

                  } = response.data.data);
                  this.leave_rule_detail.lpd_id = lpd_id;
                  this.leave_rule_detail.lpd_leave_policy_id = lpd_leave_policy_id;
                  this.leave_rule_detail.lpd_leave_type_id = lpd_leave_type_id;
                  this.leave_rule_detail.lpd_allocation_days = lpd_allocation_days;
                  this.leave_rule_detail.lpd_available_service_months = lpd_available_service_months;
                  this.leave_rule_detail.lpd_max_day_request = lpd_max_day_request;
                  this.leave_rule_detail.lpd_reset_on = lpd_reset_on;
                  this.leave_rule_detail.lpd_carry_leave = lpd_carry_leave == '1' ? 'yes' : 'no';
                  this.leave_rule_detail.lpd_max_carry_leaves = lpd_max_carry_leaves;
                  this.leave_rule_detail.lpd_reset_service_months = lpd_reset_service_months;

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    listLeaveRuleDetail: {
                      _id: this.leave_rule_detail.lpd_leave_policy_id,
                    },
                  };

                  
                this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listLeaveRuleDetail" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listLeaveRuleDetail" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listLeaveRuleDetail" });
        }
      } else {
        // add
        this.updateLoading(false);
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      LeaveRuleDetail.save(this.leave_rule_detail).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              if (this.leave_rule_detail.lpd_id != "") {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });
                this.$router.push({ name: "listLeaveRuleDetail" });
              } else {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });
              }

              this.$router.push({
                name: "listLeaveRuleDetail",
                params: { _id: this.leave_rule_detail.lpd_leave_policy_id },
              });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.formSubmitting = false;
          }
        }
      );
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({
        name: "listLeaveRuleDetail",
        params: { _id: this.leave_rule_detail.lpd_leave_policy_id },
      });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.leave_rule_detail).forEach((key) => {
        this.leave_rule_detail[key] = "";
      });
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.leave_rule_detail.lpd_id > 0) {
        LeaveRuleDetail.delete({
          lpd_id: this.leave_rule_detail.lpd_id,
        })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({
                  name: "listLeaveRuleDetail",
                  params: {
                    _id: this.leave_rule_detail.lpd_leave_policy_id,
                  },
                });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      
    },
  },
  mounted() {
    this.$store.state.crumbsParams = {
      listLeaveRuleDetail: {
        _id: this.leave_rule_detail.lpd_leave_policy_id,
      },
    };
    this.fetchRecord();
  },
};
</script>