<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-show="!isLoading">
        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn color="uno-color" class="btn_submit" @click="createNewRecord" size="sm" >
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head >
                <tr>
                  <th @click="sort('detail_from_date')">
                    <i
                      v-show="currentSort === 'detail_from_date'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("general.formInput.from_date") }}
                  </th>
                  <th>
                    <i
                      v-show="currentSort === 'detail_to_date'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("general.formInput.to_date") }}
                  </th>
                  <th>
                    <i
                      v-show="currentSort === 'detail_attendance_rule_id'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("attendance_rules.title.list") }}
                  </th>
                  <th>
                    <i
                      v-show="currentSort === 'detail_hr_work_hours_id'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("working_hours.title.list") }}
                  </th>
                  <th
                    width="120"
                    class="text-right"
                    @click="sort('detail_created')"
                  >
                    <i
                      v-show="currentSort === 'detail_created'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("general.formInput.created") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(list_item, index) in sortedRecordList"
                  :key="index"
                >
                  <td scope="row" @click="rowClickEvent(list_item.detail_id)">
                    {{ list_item.detail_from_date | formatDateString }}
                  </td>
                  <td @click="rowClickEvent(list_item.detail_id)">
                    {{ list_item.detail_to_date | formatDateString }}
                  </td>
                  <td @click="rowClickEvent(list_item.detail_id)">
                    {{ list_item.attendance_rule_name }}
                  </td>
                  <td @click="rowClickEvent(list_item.detail_id)">
                    {{ list_item.hr_work_hours_name }}
                  </td>
                  <td
                    class="text-right"
                    @click="rowClickEvent(list_item.detail_id)"
                  >
                    {{ list_item.detail_created | formatDateString }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import AttendancePolicyDetail from "@/apis/AttendancePolicyDetail";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },

  data() {
    return {
      attendance_policy_details: [],
      currentSort: "detail_created",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  props: ["_id"],
  computed: {
    ...mapGetters(["isLoading"]),

    sortedRecordList() {
      return this.attendance_policy_details.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    rowClickEvent(id) {
      this.$router.push({
        name: "viewAttendancePolicyDetail",
        params: { _id: id },
      });
    },

    createNewRecord() {
      this.$router.push({
        name: "createAttendancePolicyDetail",
        params: { ap_id: this._id },
      });
    },

    fetchRecordList() {
      this.updateLoading(true);

      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          AttendancePolicyDetail.all(this._id)
            .then((response) => {
              if (response.status === 200) {
                this.attendance_policy_details = response.data.data;
                this.updateLoading(false);
              }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.feedback_message = "Could not connected to server";
                this.feedback_error = true;

                this.updateLoading(false);
              } else {
                console.log(error);
                this.updateLoading(false);
              }
            });
        }
      }
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
