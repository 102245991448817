<template>
  <form @submit.prevent="handleFormSubmit">
    <mdb-row>

      <mdb-col md="4" lg="4" class="form-inline-search">
        <mdb-input
          type="text"
          :label="$t('general.formInput.employee_name')"
          v-model="filterForm.employee_name"
          outline
        />
      </mdb-col>  

       <mdb-col md="4" lg="4" class="form-inline-search">
        <mdb-input
          type="text"
          :label="$t('general.formInput.employee_code')"
          v-model="filterForm.employee_code"
          outline
        />
      </mdb-col>  

      <mdb-col md="4" lg="4" class="form-inline-search">
        <mdb-input
          type="text"
          :label="$t('general.formInput.training_title')"
          v-model="filterForm.hrt_title"
          outline
        />
      </mdb-col>
     
     
      <mdb-col md="4" class="form-inline-search">
        <mdb-date-picker-2
          v-model="filterForm.start_date"
          :label="$t('general.formInput.start_date')"
        />
      </mdb-col>

      <mdb-col md="4" class="form-inline-search">
        <mdb-date-picker-2
          v-model="filterForm.end_date"
          :label="$t('general.formInput.end_date')"
        />
      </mdb-col>

    </mdb-row>

    <mdb-row class="mb-4">
      <mdb-col>
        <mdb-btn
          type="button"
          color="danger"
          class="btn_submit"
          size="sm"
          @click="resetForm"
        >
          <mdb-icon icon="undo" size="md" />
          {{ $t("general.button.reset") }}
        </mdb-btn>
        <mdb-btn type="Search" color="uno-color" class="btn_submit" size="sm">
          <mdb-icon icon="file-upload" size="md" />

          {{ $t("general.button.submit") }}
        </mdb-btn>

      </mdb-col>
    </mdb-row>
  </form>
</template>

<script>
import {
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
} from "mdbvue";

export default {
  components: {
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    mdbDatePicker2,
  },
  props: ["filterFormData"],
  data() {
    return {
      filterForm: {
        employee_name:"",
        employee_code:"",
        hrt_title: "",
        start_date: "",
        end_date: "",
      },
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,
    };
  },

   computed: {

  },

  methods: {
 
    handleFormSubmit() {
      this.$emit("submitFilterForm", this.filterForm);
    },

    resetForm() {
      Object.keys(this.filterForm).forEach((key) => {
        this.filterForm[key] = "";
      });
      this.doesFormReset = !this.doesFormReset;
      this.$emit("submitFilterForm", this.filterForm);
    },
  },

  created() {
    this.filterForm = this.filterFormData;
  },
};
</script>