<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="bankInfos.bank_info_bank_name"
                    outline
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('bank_info.formInput.bank_acc')"
                    v-model="bankInfos.bank_info_bank_acc_no"
                    outline
                  />
                </mdb-col>
              </mdb-row>
              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <label>{{ $t("bank_info.formInput.default") }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="default_yes"
                      name="default"
                      radioValue="yes"
                      v-model="bankInfos.bank_info_default"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="default_no"
                      name="default"
                      radioValue="no"
                      v-model="bankInfos.bank_info_default"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"                    
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import EmployeeBankInfo from "@/apis/EmployeeBankInfo";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  // mdbDatePicker2,
  // mdbSelect,
  mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    // mdbDatePicker2,
    // mdbSelect,
    mdbFormInline,
    ModalDeleteConfimation,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      bankInfos: {
        bank_info_id: "",
        bank_info_employee_id:
          this.employee_id != "undefined" ? this.employee_id : "",
        bank_info_bank_name: "",
        bank_info_bank_acc_no: "",
        bank_info_default: "no",
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.bankInfos.bank_info_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },

    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          EmployeeBankInfo.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  let bank_info_id,
                    bank_info_employee_id,
                    bank_info_bank_name,
                    bank_info_bank_acc_no,
                    bank_info_default;
                  // populate field
                  ({
                    bank_info_id,
                    bank_info_employee_id,
                    bank_info_bank_name,
                    bank_info_bank_acc_no,
                    bank_info_default,
                  } = response.data.data);
                  this.bankInfos.bank_info_id = bank_info_id;
                  this.bankInfos.bank_info_employee_id = bank_info_employee_id;
                  this.bankInfos.bank_info_bank_name = bank_info_bank_name;
                  this.bankInfos.bank_info_bank_acc_no = bank_info_bank_acc_no;
                  this.bankInfos.bank_info_default =
                    bank_info_default == "1" ? "yes" : "no";
                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    bankInfoList: {
                      employee_id: this.bankInfos.bank_info_employee_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "bankInfoList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "bankInfoList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "bankInfoList" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      EmployeeBankInfo.save(this.bankInfos).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.bankInfos.bank_info_id != "") {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({
              name: "bankInfoList",
              params: { employee_id: this.bankInfos.bank_info_employee_id },
            });
            } else {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
               this.resetForm();
            }

            
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
    },
    redirectBackAction() {
      this.$router.push({
        name: "bankInfoList",
        params: { employee_id: this.bankInfos.bank_info_employee_id },
      });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.bankInfos).forEach((key) => {
        this.bankInfos[key] = "";
      });
      this.bankInfos.bank_info_employee_id = this.employee_id;
      this.bankInfos.bank_info_default="no";
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.bankInfos.bank_info_id > 0) {
        EmployeeBankInfo.delete({
          bank_info_id: this.bankInfos.bank_info_id,
        })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({
                  name: "bankInfoList",
                  params: {
                    _id: this.bankInfos.bank_info_employee_id,
                  },
                });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          // .catch((error) => {
          //   console.log(error);
          // });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      bankInfoList: {
        employee_id: this.bankInfos.bank_info_employee_id,
      },
    };
    this.fetchRecord();
  },
};
</script>