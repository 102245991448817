<template>
  <form @submit.prevent="handleFormSubmit">
    <mdb-row>
      <mdb-col md="4"  class="form-inline-search">
        <mdb-input
          type="text"
          :label="$t('general.formInput.keywords')"
          v-model="filterForm.keywords"
          outline
        />
      </mdb-col>
      <mdb-col md="4" class="form-inline-search">
        <DepartmentDropdown
          @updateDepartmentID="setDepartmentID"
          :default_selected_value="filterForm.department_id"
          :required="false"
        />
      </mdb-col>
      <mdb-col md="4"  class="form-inline-search">
        <PositionDropdown
          @updatePositionID="setPositionID"
          :default_selected_value="filterForm.position_id"
          :required="false"
          :resetSelected="doesFormReset"
        />
      </mdb-col>
    </mdb-row>

    <mdb-row>
      <mdb-col md="4" class="form-inline-search">
        <mdb-date-picker-2
          v-model="filterForm.date_from"
          :label="$t('general.formInput.start_date')"
        />
      </mdb-col>

      <mdb-col md="4" class="form-inline-search">
        <mdb-date-picker-2
          v-model="filterForm.date_to"
          :label="$t('general.formInput.end_date')"
        />
      </mdb-col>

      <mdb-col md="4" class="form-inline-search">
                  <mdb-select
                    v-model="approvalStatusOption"
                    placeholder=""
                    label="Status"
                    outline
                    @getValue="updateApprovalStatus"
                  />

                </mdb-col>
    </mdb-row>

    <mdb-row class="mb-4">
      <mdb-col>
        <mdb-btn
          type="button"
          color="danger"
          class="btn_submit"
          size="sm"
          @click="resetForm"
        >
          <mdb-icon icon="undo" size="md" />
          {{ $t("general.button.reset") }}
        </mdb-btn>
        <mdb-btn type="Search" color="uno-color" class="btn_submit" size="sm">
          <mdb-icon icon="file-upload" size="md" />

          {{ $t("general.button.submit") }}
        </mdb-btn>
      </mdb-col>
    </mdb-row>
  </form>
</template>

<script>
import DepartmentDropdown from "@/components/DepartmentDropdown";
import PositionDropdown from "@/components/PositionDropdown";
import { approvalStatusOption } from "@/constants/employee.js";
import {
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  mdbSelect
} from "mdbvue";

export default {
  components: {
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    DepartmentDropdown,
    PositionDropdown,
    mdbDatePicker2,
    mdbSelect
  },
  props: ["filterFormData"],
  data() {
    return {
      filterForm: {
        keywords: "",
        department_id: "",
        position_id: "",
        date_from: "",
        date_to: "",
        attendance_status:"",
      },
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,
      
      approvalStatusOption: approvalStatusOption(true, "pending"),
    };
  },

  methods: {
    updateApprovalStatus(val) {
      this.filterForm.attendance_status = val;
    },
    setDepartmentID(val) {
      this.filterForm.department_id = val;
    },
    setPositionID(val) {
      this.filterForm.position_id = val;
    },

    handleFormSubmit() {
      this.$emit("submitFilterForm", this.filterForm);
    },

    resetForm() {
      Object.keys(this.filterForm).forEach((key) => {
        this.filterForm[key] = "";
      });
      this.approvalStatusOption= approvalStatusOption(true, "pending")
      this.doesFormReset = !this.doesFormReset;
      this.$emit("submitFilterForm", this.filterForm);
    },
  },

  created() {
    this.filterForm = this.filterFormData;
  },
};
</script>