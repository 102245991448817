<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-show="!isLoading">
        <mdb-card>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th>
                    {{ $t("general.formInput.name") }}
                  </th>
                  <th class="text-right">
                    {{ $t("general.formInput.date") }}
                  </th>
                  <th class="text-right">
                    {{ $t("general.label.checkin") }}
                  </th>
                  <!-- <th >
                    {{ $t("general.label.checkin_status") }}
                  </th> -->
                  <th class="text-right">
                    {{ $t("general.label.checkout") }}
                  </th>
                  <!-- <th >
                    {{ $t("general.label.checkout_status") }}
                  </th> -->
                  <!-- <th >
                    {{ $t("general.label.source") }}
                  </th> -->
                  <th width="120" class="text-right">
                    {{ $t("general.formInput.date") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(record_item, index) in this.pending_attendance.data"
                  :key="index"
                >
                
                  <td scope="row">
                    
                    <EmployeeInfoTableCellLayout :user="record_item" />
                  </td>

                  <td class="text-right">{{ record_item.attendance_day  | formatDateString }}</td>

                  <td class="text-right">
                    {{ record_item.attendance_checkin_time | formatTimeString }}
                  </td>


                  
                  <td class="text-right">
                    {{ record_item.attendance_checkout_time | formatTimeString }}
                  </td>


                  <td class="text-right">
                    {{ record_item.attendance_created_date | formatDateString }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <pagination
              :data="pending_attendance"
              @pagination-change-page="fetchRecordList"
              :limit="2"
            ></pagination>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
Vue.component("pagination", require("laravel-vue-pagination"));
import Report from "@/apis/Report";
import { mapActions, mapGetters } from "vuex";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    EmployeeInfoTableCellLayout
  },
  props: ["_id"],
  data() {
    return {
      pending_attendance: {},
      currentSort: "department_name",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    sort: function () {},

    // rowClickEvent(id) {
    //   this.$router.push({
    //     name: "PendingAttendanceDetailList",
    //     params: { _id: id },
    //   });
    // },

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      const params = {
        page: page,
        department_id: [this._id],
      };

      Report.pendingAttendanceDetail(params)
        .then((response) => {
          if (response.status === 200) {
            this.pending_attendance = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
