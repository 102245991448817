<template>
  <div>

    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string' ">
        {{feedback_message}}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
         {{ error.message }}
      </div>      
    </div>
  <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
    <template>
      <mdb-card class="mb-5">
        
        <mdb-card-body>
          <form @submit.prevent="handleFormSubmit">
            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <mdb-input
                  type="text"
                  :label=" $t('general.formInput.name') "
                  v-model="attendance_rules.attendance_rule_name"
                  outline
                  required
                />
              </mdb-col>
              
                              
              <mdb-col offsetMd="2" md="8" class="mt-3">
                <mdb-row>
                  <mdb-col md="2">
                    <span>{{$t('general.formInput.mon')}}</span>
                  </mdb-col>
                  <mdb-col md="10">
                <mdb-form-inline>
                  <mdb-input type="radio" id="option1-3" name="attendance_rule_mon_status" radioValue="full_day" v-model="attendance_rules.attendance_rule_mon_status" :label=" $t('general.formInput.full_day') " />
                    <mdb-input type="radio" id="option1-1" name="attendance_rule_mon_status" radioValue="first_half" v-model="attendance_rules.attendance_rule_mon_status" :label=" $t('general.formInput.first_half') " />
                    <mdb-input type="radio" id="option1-2" name="attendance_rule_mon_status" radioValue="second_half" v-model="attendance_rules.attendance_rule_mon_status" :label=" $t('general.formInput.second_half') " />                    
                    <mdb-input type="radio" id="option1-4" name="attendance_rule_mon_status" radioValue="none" v-model="attendance_rules.attendance_rule_mon_status" :label=" $t('general.formInput.none') " />
                </mdb-form-inline>
                  </mdb-col>                                    
                </mdb-row>
              </mdb-col>
              <mdb-col offsetMd="2" md="8">
                <hr />
              </mdb-col>
              <mdb-col offsetMd="2" md="8">
                <mdb-row>
                  <mdb-col md="2">
                    <span>{{$t('general.formInput.tue')}}</span>
                  </mdb-col>
                  <mdb-col md="10">
                <mdb-form-inline>
                  
                    <mdb-input type="radio" id="option2-3" name="attendance_rule_tue_status" radioValue="full_day" v-model="attendance_rules.attendance_rule_tue_status" :label=" $t('general.formInput.full_day') " />
                    <mdb-input type="radio" id="option2-1" name="attendance_rule_tue_status" radioValue="first_half" v-model="attendance_rules.attendance_rule_tue_status" :label=" $t('general.formInput.first_half') " />
                    <mdb-input type="radio" id="option2-2" name="attendance_rule_tue_status" radioValue="second_half" v-model="attendance_rules.attendance_rule_tue_status" :label=" $t('general.formInput.second_half') " />
                    <mdb-input type="radio" id="option2-4" name="attendance_rule_tue_status" radioValue="none" v-model="attendance_rules.attendance_rule_tue_status" :label=" $t('general.formInput.none') " />
                </mdb-form-inline>
                  </mdb-col>                                    
                </mdb-row>
              </mdb-col>
              <mdb-col offsetMd="2" md="8">
                <hr />
              </mdb-col>
              <mdb-col offsetMd="2" md="8">
                <mdb-row>
                  <mdb-col md="2">
                    <span>{{$t('general.formInput.wed')}}</span>
                  </mdb-col>
                  <mdb-col md="10">
                <mdb-form-inline>
                  
                    <mdb-input type="radio" id="option3-3" name="attendance_rule_wed_status" radioValue="full_day" v-model="attendance_rules.attendance_rule_wed_status" :label=" $t('general.formInput.full_day') " />
                    <mdb-input type="radio" id="option3-1" name="attendance_rule_wed_status" radioValue="first_half" v-model="attendance_rules.attendance_rule_wed_status" :label=" $t('general.formInput.first_half') " />
                    <mdb-input type="radio" id="option3-2" name="attendance_rule_wed_status" radioValue="second_half" v-model="attendance_rules.attendance_rule_wed_status" :label=" $t('general.formInput.second_half') " />
                    <mdb-input type="radio" id="option3-4" name="attendance_rule_wed_status" radioValue="none" v-model="attendance_rules.attendance_rule_wed_status" :label=" $t('general.formInput.none') " />
                </mdb-form-inline>
                  </mdb-col>                                    
                </mdb-row>
              </mdb-col>
              <mdb-col offsetMd="2" md="8">
                <hr />
              </mdb-col>
              <mdb-col offsetMd="2" md="8">
                <mdb-row>
                  <mdb-col md="2">
                    <span>{{$t('general.formInput.thu')}}</span>
                  </mdb-col>
                  <mdb-col md="10">
                <mdb-form-inline>
                    <mdb-input type="radio" id="option4-3" name="attendance_rule_thu_status" radioValue="full_day" v-model="attendance_rules.attendance_rule_thu_status" :label=" $t('general.formInput.full_day') " />
                    <mdb-input type="radio" id="option4-1" name="attendance_rule_thu_status" radioValue="first_half" v-model="attendance_rules.attendance_rule_thu_status" :label=" $t('general.formInput.first_half') " />
                    <mdb-input type="radio" id="option4-2" name="attendance_rule_thu_status" radioValue="second_half" v-model="attendance_rules.attendance_rule_thu_status" :label=" $t('general.formInput.second_half') " />
                    <mdb-input type="radio" id="option4-4" name="attendance_rule_thu_status" radioValue="none" v-model="attendance_rules.attendance_rule_thu_status" :label=" $t('general.formInput.none') " />
                </mdb-form-inline>
                  </mdb-col>                                    
                </mdb-row>
              </mdb-col>
              <mdb-col offsetMd="2" md="8">
                <hr />
              </mdb-col>
              <mdb-col offsetMd="2" md="8">
                <mdb-row>
                  <mdb-col md="2">
                    <span>{{$t('general.formInput.fri')}}</span>
                  </mdb-col>
                  <mdb-col md="10">
                <mdb-form-inline>
                  
                    <mdb-input type="radio" id="option5-3" name="attendance_rule_fri_status" radioValue="full_day" v-model="attendance_rules.attendance_rule_fri_status" :label=" $t('general.formInput.full_day') " />
                    <mdb-input type="radio" id="option5-1" name="attendance_rule_fri_status" radioValue="first_half" v-model="attendance_rules.attendance_rule_fri_status" :label=" $t('general.formInput.first_half') " />
                    <mdb-input type="radio" id="option5-2" name="attendance_rule_fri_status" radioValue="second_half" v-model="attendance_rules.attendance_rule_fri_status" :label=" $t('general.formInput.second_half') " />
                    <mdb-input type="radio" id="option5-4" name="attendance_rule_fri_status" radioValue="none" v-model="attendance_rules.attendance_rule_fri_status" :label=" $t('general.formInput.none') " />
                </mdb-form-inline>
                  </mdb-col>                                    
                </mdb-row>
              </mdb-col>
              <mdb-col offsetMd="2" md="8">
                <hr />
              </mdb-col>
              <mdb-col offsetMd="2" md="8">
                <mdb-row>
                  <mdb-col md="2">
                    <span>{{$t('general.formInput.sat')}}</span>
                  </mdb-col>
                  <mdb-col md="10">
                <mdb-form-inline>
                  
                    <mdb-input type="radio" id="option6-3" name="attendance_rule_sat_status" radioValue="full_day" v-model="attendance_rules.attendance_rule_sat_status" :label=" $t('general.formInput.full_day') " />
                    <mdb-input type="radio" id="option6-1" name="attendance_rule_sat_status" radioValue="first_half" v-model="attendance_rules.attendance_rule_sat_status" :label=" $t('general.formInput.first_half') " />
                    <mdb-input type="radio" id="option6-2" name="attendance_rule_sat_status" radioValue="second_half" v-model="attendance_rules.attendance_rule_sat_status" :label=" $t('general.formInput.second_half') " />
                    <mdb-input type="radio" id="option6-4" name="attendance_rule_sat_status" radioValue="none" v-model="attendance_rules.attendance_rule_sat_status" :label=" $t('general.formInput.none') " />
                </mdb-form-inline>
                  </mdb-col>                                    
                </mdb-row>
              </mdb-col>
              <mdb-col offsetMd="2" md="8">
                <hr />
              </mdb-col>
              <mdb-col offsetMd="2" md="8">
                <mdb-row>
                  <mdb-col md="2">
                    <span>{{$t('general.formInput.sun')}}</span>
                  </mdb-col>
                  <mdb-col md="10">
                <mdb-form-inline>
                    <mdb-input type="radio" id="option7-3" name="attendance_rule_sun_status" radioValue="full_day" v-model="attendance_rules.attendance_rule_sun_status" :label=" $t('general.formInput.full_day') " />
                    <mdb-input type="radio" id="option7-1" name="attendance_rule_sun_status" radioValue="first_half" v-model="attendance_rules.attendance_rule_sun_status" :label=" $t('general.formInput.first_half') " />
                    <mdb-input type="radio" id="option7-2" name="attendance_rule_sun_status" radioValue="second_half" v-model="attendance_rules.attendance_rule_sun_status" :label=" $t('general.formInput.second_half') " />
                    <mdb-input type="radio" id="option7-4" name="attendance_rule_sun_status" radioValue="none" v-model="attendance_rules.attendance_rule_sun_status" :label=" $t('general.formInput.none') " />
                </mdb-form-inline>
                  </mdb-col>                                    
                </mdb-row>
              </mdb-col>

            </mdb-row>

            <mdb-row class="mt-5">
              <mdb-col offsetMd="2" md="8">
            <mdb-btn
              type="button"
              class="btn_back"
              color="uno-color"
              @click="redirectBackAction"
            >
              <mdb-icon
                icon="arrow-left"
                size="md"
              />
              {{ $t('general.button.go_back') }}
            </mdb-btn>

            <mdb-btn
              type="Submit"
              color="uno-color"
              class="btn_submit"
              :disabled="formSubmitting"
            >
              <mdb-icon
                icon="file-upload"
                size="md"
                v-show="!formSubmitting"
              />

              <span
                class="spinner-border-vcenter spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="formSubmitting"
              ></span>
              {{ $t('general.button.submit') }}
            </mdb-btn>


            <mdb-btn
            type="button"            
            :disabled="deleteSubmitted"
            @click="showDeleteModal"
            v-show="doesEditForm"
            color="uno-color"
            class="btn_delete float-right"
          >
            <mdb-icon
              icon="trash-alt"
              far
              size="md"
              v-show="!deleteSubmitted"
            />

            <span
              class="spinner-border-vcenter spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-show="deleteSubmitted"
            ></span>
            {{ $t('general.button.delete') }}
          </mdb-btn>
              </mdb-col>
            </mdb-row>
          </form>

          
        </mdb-card-body>
      </mdb-card>
    </template>
    </div>

    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord"/>
  </div>
</template>
<script>
import AttendanceRule from "@/apis/AttendanceRule";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";	
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFormInline 
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbFormInline,
    ModalDeleteConfimation
  },
  props: ["_id"],
  data() {
    return {
      attendance_rules: {
        attendance_rule_id: "",
        attendance_rule_name: "",
        attendance_rule_time_base_on: "shift_schedule",
        attendance_rule_day_setting: "total_days_per_week",
        attendance_rule_total_days: "0",
        attendance_rule_mon_status: "full_day",
        attendance_rule_tue_status: "full_day",
        attendance_rule_wed_status: "full_day",
        attendance_rule_thu_status: "full_day",
        attendance_rule_fri_status: "full_day",
        attendance_rule_sat_status: "none",
        attendance_rule_sun_status: "none",        
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message:""
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.attendance_rules.attendance_rule_id != "" ? true : false;
    },
   
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
        this.$store.state.deleteModalVisible = true;
      },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          AttendanceRule.view(this._id)
            .then((response) => {
              
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)
                  let attendance_rule_name,
                    attendance_rule_id,
                    attendance_rule_time_base_on,
                    attendance_rule_day_setting,
                    attendance_rule_total_days,
                    attendance_rule_mon_status,
                    attendance_rule_tue_status,
                    attendance_rule_wed_status,
                    attendance_rule_thu_status,
                    attendance_rule_fri_status,
                    attendance_rule_sat_status,
                    attendance_rule_sun_status

                  // populate field
                  ({
                    attendance_rule_id,
                    attendance_rule_name,
                    attendance_rule_time_base_on,
                    attendance_rule_day_setting,
                    attendance_rule_total_days,
                    attendance_rule_mon_status,
                    attendance_rule_tue_status,
                    attendance_rule_wed_status,
                    attendance_rule_thu_status,
                    attendance_rule_fri_status,
                    attendance_rule_sat_status,
                    attendance_rule_sun_status,
                  } = response.data.data);
                  this.attendance_rules.attendance_rule_id = attendance_rule_id;
                  this.attendance_rules.attendance_rule_name = attendance_rule_name;
                  this.attendance_rules.attendance_rule_time_base_on = attendance_rule_time_base_on;
                  this.attendance_rules.attendance_rule_day_setting = attendance_rule_day_setting;
                  this.attendance_rules.attendance_rule_total_days = attendance_rule_total_days;
                  this.attendance_rules.attendance_rule_mon_status = attendance_rule_mon_status;
                  this.attendance_rules.attendance_rule_tue_status = attendance_rule_tue_status;
                  this.attendance_rules.attendance_rule_wed_status = attendance_rule_wed_status;
                  this.attendance_rules.attendance_rule_thu_status = attendance_rule_thu_status;
                  this.attendance_rules.attendance_rule_fri_status = attendance_rule_fri_status;
                  this.attendance_rules.attendance_rule_sat_status = attendance_rule_sat_status;
                  this.attendance_rules.attendance_rule_sun_status = attendance_rule_sun_status;

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              }else{
                this.$router.push({ name: "listAttendanceRule" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listAttendanceRule" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listAttendanceRule" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      AttendanceRule.save(this.attendance_rules).then((response) => {
       
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.attendance_rules.attendance_rule_id != "") {
              this.resetForm();
              this.$notify.success({
                  message:   response.data.message ,
                  position: "top right",
                  timeOut: 5000,
                });
              this.$router.push({ name: "listAttendanceRule" });
            } else {
              this.resetForm();
              this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({ name: "listAttendanceRule" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
       Object.keys(this.attendance_rules).forEach((key)=>{ this.attendance_rules[key] = "" });
        this.attendance_rules.attendance_rule_time_base_on= "shift_schedule";
        this.attendance_rules.attendance_rule_day_setting= "total_days_per_week";
        this.attendance_rules.attendance_rule_total_days= "0";
        this.attendance_rules.attendance_rule_mon_status= "full_day";
        this.attendance_rules.attendance_rule_tue_status= "full_day";
        this.attendance_rules.attendance_rule_wed_status= "full_day";
        this.attendance_rules.attendance_rule_thu_status= "full_day";
        this.attendance_rules.attendance_rule_fri_status= "full_day";
        this.attendance_rules.attendance_rule_sat_status= "none";
        this.attendance_rules.attendance_rule_sun_status= "none";
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;
                
      if (this.attendance_rules.attendance_rule_id > 0) {
        AttendanceRule.delete({ attendance_rule_id: this.attendance_rules.attendance_rule_id })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });
                
                this.$router.push({ name: 'listAttendanceRule'});

              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>