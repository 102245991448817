<template>
  <div>

    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string' ">
        {{feedback_message}}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
         {{ error.message }}
      </div>      
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-show="!isLoading">

        <mdb-card>
<mdb-card-header>
<div class="float-left"></div>
<div class="float-right">
<mdb-btn color="uno-color" class="btn_submit" @click="createNewRecord"  size="sm">
          <mdb-icon far icon="plus-square" size="md" />
          {{ $t('general.button.create') }}
        </mdb-btn>
</div>
</mdb-card-header>
<mdb-card-body>
  <mdb-tbl responsive hover>
            <!-- Table head -->
            <mdb-tbl-head >
              <tr>
                <th @click="sort('cb_name')" >                  
                  <i v-show="currentSort==='cb_name'" :class="[ currentSortDir ==='asc' ? 'fas fa-arrow-up ' : 'fas fa-arrow-down ' ]" aria-hidden="true" ></i>
                  {{ $t('general.formInput.branch_name') }}
                  </th>
                <th @click="sort('company_name')" >
                  <i v-show="currentSort==='company_name'" :class="[ currentSortDir ==='asc' ? 'fas fa-arrow-up ' : 'fas fa-arrow-down ' ]" aria-hidden="true" ></i>
                  {{ $t('general.formInput.company_name') }}
                  </th>
                <th @click="sort('cb_country')" >                  
                  <i v-show="currentSort==='cb_country'" :class="[ currentSortDir ==='asc' ? 'fas fa-arrow-up ' : 'fas fa-arrow-down ' ]" aria-hidden="true" ></i>
                  {{ $t('general.formInput.country') }}
                  </th>
                <th @click="sort('cb_state')" >                  
                  <i v-show="currentSort==='cb_state'" :class="[ currentSortDir ==='asc' ? 'fas fa-arrow-up ' : 'fas fa-arrow-down ' ]" aria-hidden="true" ></i>
                  {{ $t('general.formInput.state') }}
                  </th>
                <th @click="sort('cb_township')" >                  
                  <i v-show="currentSort==='cb_township'" :class="[ currentSortDir ==='asc' ? 'fas fa-arrow-up ' : 'fas fa-arrow-down ' ]" aria-hidden="true" ></i>
                  {{ $t('general.formInput.township') }}
                  </th>
                <th>                  
                  <i v-show="currentSort==='cb_phone_no'" :class="[ currentSortDir ==='asc' ? 'fas fa-arrow-up ' : 'fas fa-arrow-down ' ]" aria-hidden="true" ></i>
                  {{ $t('general.formInput.phone_no') }}
                  </th>
                <th width="120" class="text-right"  @click="sort('cb_created')" >
                  <i v-show="currentSort==='cb_created'" :class="[ currentSortDir ==='asc' ? 'fas fa-arrow-up ' : 'fas fa-arrow-down ' ]" aria-hidden="true" ></i>
                  {{ $t('general.formInput.created') }}
                  </th>
              </tr>
            </mdb-tbl-head>

            <!-- Table body -->
            <mdb-tbl-body>
              <tr scope="row" v-for="(document_type, index) in sortedDocumentTypeList" :key="index">
                <td scope="row" @click="rowClickEvent(document_type.cb_id)">{{document_type.cb_name}}</td>
                <td @click="rowClickEvent(document_type.cb_id)">{{document_type.company_name}}</td>
                <td @click="rowClickEvent(document_type.cb_id)">{{document_type.cb_country}}</td>
                <td @click="rowClickEvent(document_type.cb_id)">{{document_type.cb_state}}</td>
                <td @click="rowClickEvent(document_type.cb_id)">{{document_type.cb_township}}</td>
                <td @click="rowClickEvent(document_type.cb_id)">{{document_type.cb_phone_no}}</td>
                <td class="text-right" @click="rowClickEvent(document_type.cb_id)">{{document_type.cb_created | formatDateString}}</td>
              </tr>                  
            </mdb-tbl-body>

          </mdb-tbl> 
</mdb-card-body>
</mdb-card>
            <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import CompanyBranch from "@/apis/CompanyBranch";
import { mapActions, mapGetters } from "vuex";

import {
 mdbCardHeader,
mdbCard,
mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody
} from "mdbvue";


export default {
  components: {
    mdbCardHeader,
mdbCard,
mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody
  },

  data() {
    return {
      company_branchs: [],
      currentSort:'cb_name',
      currentSortDir:'asc',
      feedback_error: false,
      feedback_message:""
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    sortedDocumentTypeList() {
      return this.company_branchs.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
      
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
    },

    rowClickEvent(id){
       this.$router.push({ name: 'viewCompanyBranch', params: { _id: id } });
    },

    createNewRecord() {
      this.$router.push({ name: "createCompanyBranch" });
    },

    fetchRecordList() {
      this.updateLoading(true);

      CompanyBranch.all()
        .then((response) => {
          if (response.status === 200) {
            // console.log( response.data.data)
            this.company_branchs = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
