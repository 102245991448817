import Api from './api'

export default{


    save(payload){
            return Api.post('api/rule_policy/attendance_approval/data_entry', payload)        
    },
    
    delete(payload){
        if(payload.type=="attendance"){
            delete payload.type;
            return Api.post('api/rule_policy/attendance_approval/delete', {attendance_approval_id : payload.approval_id})        
        }
        else if(payload.type=="leave"){
            delete payload.type;
            return Api.post('api/rule_policy/attendance_approval/delete_leave_approval', {leave_approval_id : payload.approval_id})    
        }
        else if(payload.type=="overtime"){
            delete payload.type;     
            return Api.post('api/rule_policy/attendance_approval/delete_overtime_approval', {overtime_approval_id : payload.approval_id})
        }
        
    },
    
    
}