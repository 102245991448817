<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="action_types.hr_eat_name"
                    outline
                    required
                  />
                </mdb-col>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    :label="$t('general.formInput.description')"
                    v-model="action_types.hr_eat_description"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <label>{{ $t("general.formInput.deduction_status") }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="deduction_status_yes"
                      name="deduction_status"
                      radioValue="yes"
                      v-model="action_types.hr_eat_salary_deduction_status"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="deduction_status_no"
                      name="deduction_status"
                      radioValue="no"
                      v-model="action_types.hr_eat_salary_deduction_status"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>

              <div
                v-if="this.action_types.hr_eat_salary_deduction_status == 'yes'"
              >
                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <label>{{ $t("general.formInput.penalty_type") }}</label>
                    <mdb-form-inline>
                      <mdb-input
                        type="radio"
                        id="penalty_type_percentage"
                        name="hr_eat_penalty_type"
                        radioValue="percentage"
                        v-model="action_types.hr_eat_penalty_type"
                        :label="$t('general.formInput.percentage')"
                      />
                      <mdb-input
                        type="radio"
                        id="penalty_type_amount"
                        name="hr_eat_penalty_type"
                        radioValue="amount"
                        v-model="action_types.hr_eat_penalty_type"
                        :label="$t('general.formInput.amount')"
                      />
                    </mdb-form-inline>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <mdb-input
                      type="text"
                      :label="$t('general.formInput.penalty_value')"
                      v-model="action_types.hr_eat_penalty_value"
                      outline
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row
                  v-show="this.action_types.hr_eat_penalty_type == 'percentage'"
                >
                  <mdb-col offsetMd="2" md="8">
                    <label>{{
                      $t("general.formInput.penalty_dependon")
                    }}</label>
                    <mdb-form-inline>
                      <mdb-input
                        type="radio"
                        id="basic_salary"
                        name="penalty_dependon"
                        radioValue="basic_salary"
                        v-model="action_types.hr_eat_penalty_dependon"
                        :label="$t('general.formInput.basic_salary')"
                      />
                      <mdb-input
                        type="radio"
                        id="allowance"
                        name="penalty_dependon"
                        radioValue="allowance"
                        v-model="action_types.hr_eat_penalty_dependon"
                        :label="$t('general.formInput.allowance')"
                      />
                    </mdb-form-inline>
                  </mdb-col>
                </mdb-row>
              </div>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>

    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import ActionType from "@/apis/ActionType";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbFormInline,
    ModalDeleteConfimation,
  },
  props: ["_id"],
  data() {
    return {
      action_types: {
        hr_eat_id: "",
        hr_eat_name: "",
        hr_eat_description: "",
        hr_eat_salary_deduction_status: "no",
        hr_eat_penalty_type: "",
        hr_eat_penalty_value: "",
        hr_eat_penalty_dependon: "",
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.action_types.hr_eat_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          ActionType.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)
                  let hr_eat_name,
                    hr_eat_id,
                    hr_eat_description,
                    hr_eat_salary_deduction_status,
                    hr_eat_penalty_type,
                    hr_eat_penalty_value,
                    hr_eat_penalty_dependon;

                  // populate field
                  ({
                    hr_eat_name,
                    hr_eat_id,
                    hr_eat_description,
                    hr_eat_salary_deduction_status,
                    hr_eat_penalty_type,
                    hr_eat_penalty_value,
                    hr_eat_penalty_dependon,
                  } = response.data.data);
                  this.action_types.hr_eat_id = hr_eat_id;
                  this.action_types.hr_eat_name = hr_eat_name;
                  this.action_types.hr_eat_description = hr_eat_description;
                  // this.action_types.hr_eat_salary_deduction_status = hr_eat_salary_deduction_status;
                  this.action_types.hr_eat_salary_deduction_status =
                    hr_eat_salary_deduction_status == "1" ? "yes" : "no";
                  this.action_types.hr_eat_penalty_type = hr_eat_penalty_type;
                  this.action_types.hr_eat_penalty_value = hr_eat_penalty_value;
                  this.action_types.hr_eat_penalty_dependon =
                    hr_eat_penalty_dependon;

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listActionType" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listActionType" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listActionType" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      if (this.action_types.hr_eat_salary_deduction_status == "no") {
        delete this.action_types.hr_eat_penalty_type;
        delete this.action_types.hr_eat_penalty_value;
        delete this.action_types.hr_eat_penalty_dependon;
      } else if (
        this.action_types.hr_eat_salary_deduction_status == "yes" &&
        this.action_types.hr_eat_penalty_type == "amount"
      ) {
        delete this.action_types.hr_eat_penalty_dependon;
      }

      ActionType.save(this.action_types).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.action_types.hr_eat_id != "") {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "listActionType" });
            } else {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({ name: "listActionType" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.action_types).forEach((key) => {
        this.action_types[key] = "";
      });
      this.hr_eat_salary_deduction_status = "no";
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.action_types.hr_eat_id > 0) {
        ActionType.delete({ hr_eat_id: this.action_types.hr_eat_id })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({ name: "listActionType" });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>