<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2"  md="4">
                  <mdb-date-picker-2
                    v-model="timetable_data.hr_da_date"
                    :label="$t('general.formInput.date')"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <WorkingHourDropdown
                    @updateWorkingHoursID="setWorkingHoursID"
                    :default_selected_value="
                      timetable_data.hr_da_working_hour_id
                    "
                    :required="true"
                    v-if="!isLoading"
                  />
                </mdb-col>
              </mdb-row>


              <!-- ///////////////////////////////// -->
              
              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <label>Required to take attendance in assignment location</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="option-no"
                      radioValue="no"
                      v-model="location_required"
                      label="No"
                    />
                    <mdb-input
                      type="radio"
                      id="option-yes"
                      radioValue="yes"
                      v-model="location_required"
                      label="Yes"
                    />
                  </mdb-form-inline>
                </mdb-col>

                <mdb-col offsetMd="2" md="8" v-show="location_required == 'yes'">
                  <AttendanceLocationDropdown
                    @updateSelectedAttendanceLocationIDs="
                      setSelectedAttendanceLocationIds
                    "
                    :default_selected_value="
                      timetable_data.hr_da_allow_gps_location
                    "
                    
                  />
                </mdb-col>
              </mdb-row>
              <!-- //////////////////////////////// -->

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    outline="default"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="lg" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    outline="primary"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="lg"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    outline="danger"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    class="float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="lg"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import Timetable from "@/apis/Timetable";
import WorkingHourDropdown from "@/components/WorkingHourDropdown";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";

import AttendanceLocationDropdown from "@/components/AttendanceLocationDropdown";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
   mdbInput,
    mdbFormInline
  //   mdbSelect,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbDatePicker2,
    // mdbSelect,
    ModalDeleteConfimation,
    WorkingHourDropdown,
    mdbFormInline,
    AttendanceLocationDropdown,
     mdbInput,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      timetable_data: {
        hr_da_id: "",
        hr_da_user_id: this.employee_id != "undefined" ? this.employee_id : "",
        hr_da_date: "",
        hr_da_working_hour_id: "",
        
        hr_da_allow_gps_location: [],
      },

      location_required: "no",
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.timetable_data.hr_da_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
     setSelectedAttendanceLocationIds(val) {
      this.timetable_data.hr_da_allow_gps_location = val;
    },

    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },

    setWorkingHoursID(val) {
      this.timetable_data.hr_da_working_hour_id = val;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          Timetable.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  //   console.log(response.data);

                  let hr_da_id,
                    hr_da_user_id,
                    hr_da_date,
                    hr_da_working_hour_id,
                    hr_da_allow_gps_location;
                  // populate field
                  ({
                    hr_da_id,
                    hr_da_user_id,
                    hr_da_date,
                    hr_da_working_hour_id,
                    hr_da_allow_gps_location
                  } = response.data.data);
                  this.timetable_data.hr_da_id = hr_da_id;
                  this.timetable_data.hr_da_user_id = hr_da_user_id;
                  this.timetable_data.hr_da_date = hr_da_date;
                  this.timetable_data.hr_da_working_hour_id = hr_da_working_hour_id;
                  this.timetable_data.hr_da_allow_gps_location = hr_da_allow_gps_location;

                  if(hr_da_allow_gps_location && hr_da_allow_gps_location.length > 0){
                    this.location_required = "yes";
                  }
                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "employeeTimetableList" });
              }
            })
            .catch(() => {
              // console.log(error)
              this.$router.push({ name: "employeeTimetableList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "employeeTimetableList" });
        }
      } else {
        // add
        this.$router.push({ name: "employeeTimetableList" });
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      if(this.location_required == "no"){
          this.timetable_data.hr_da_allow_gps_location = [];
        }

      Timetable.update(this.timetable_data).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.timetable_data.hr_da_id != "") {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "employeeTimetableList" });

              this.$router.push({
                name: "employeeTimetableList",
                params: { employee_id: this.timetable_data.hr_da_user_id },
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({
        name: "employeeTimetableList",
        params: { employee_id: this.timetable_data.hr_da_user_id },
      });
    },
    resetAlert() {
      this.feedback_error = false;
    },
 
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;
      if (this.timetable_data.hr_da_id > 0) {
        Timetable.delete({
          hr_da_id: this.timetable_data.hr_da_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              this.$router.push({
                name: "employeeTimetableList",
                params: {
                  _id: this.timetable_data.hr_da_user_id,
                },
              });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>