<template>
  <section class="form-elegant">
    <mdb-row class="d-flex justify-content-center">
      <mdb-col md="6" lg="5">
        <mdb-card>
          <mdb-card-body class="mx-4">
            <form @submit.prevent="validateForm" novalidate>
              <div class="text-center">
                <mdb-card-avatar color="white" class="mx-auto"
                  ><img
                    src="/unohr.png"
                    class=""
                    height="100"
                /></mdb-card-avatar>
              </div>
              <div class="text-center">
                <h3 class="dark-grey-text my-4"><strong>Sign in</strong></h3>
              </div>
              <div class="text-center">
                  
                   <div class="alert alert-danger" role="alert" v-if="formErrorFeedback != false">
                  <div v-if="typeof formErrorFeedback == 'string'">
                    {{ formErrorFeedback }}
                  </div>
                  <div
                    v-else
                    v-for="(error, index) in formErrorFeedback"
                    :key="index"
                  >
                    {{ error.message }}
                  </div>

                </div>
              </div>

              <mdb-input
                type="text"
                label="Organization"
                required
                v-model="formValues.organization"
                :customValidation="validation.organization.validated"
                :isValid="validation.organization.valid"
                @change="validate('organization')"
                invalidFeedback="Please Fill in Organization."
              />

              <mdb-input
                type="email"
                label="Your email"
                required
                v-model="formValues.email"
                :customValidation="validation.email.validated"
                :isValid="validation.email.valid"
                @change="validate('email')"
                invalidFeedback="Please Fill in valid email address."
              />
              <mdb-input
                label="Your password"
                type="password"
                required
                v-model="formValues.password"
                :customValidation="validation.password.validated"
                :isValid="validation.password.valid"
                @change="validate('password')"
                :invalidFeedback="validation.password.invalidFeedback"
                containerClass="mb-0"
              />
              <p class="font-small blue-text d-flex justify-content-end pb-3">
                <router-link
                  :to="{ name: 'ForgotPassword' }"
                  class="blue-text ml-1"
                >
                  Forgot Password?
                </router-link>
              </p>
              <div class="text-center mb-3">
                <mdb-btn
                  type="Submit"
                  gradient="blue"
                  rounded
                  class="btn-block z-depth-1a"
                  :disabled="doesFormSubmitted"
                >
                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="doesFormSubmitted"
                  ></span>

                  Sign in
                </mdb-btn>
              </div>
            </form>
          </mdb-card-body>
          <mdb-modal-footer class="mx-5 pt-3 mb-1">
            <p class="font-small grey-text d-flex justify-content-end">
              Powered BY <a href="#" class="blue-text ml-1"> SBG</a>
            </p>
          </mdb-modal-footer>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbBtn,
  mdbModalFooter,
  mdbCardAvatar,
  // mdbAlert,
} from "mdbvue";

const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  name: "Login",
  components: {
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbBtn,
    mdbModalFooter,
    mdbCardAvatar,
    // mdbAlert,
  },
  data() {
    return {
      formValues: {

        organization: "",
        email: "",
        password: "",
      },
      formSubmitting: false,
      formErrorFeedback: false,
      formValidation: true,
      validation: {
        organization: {
          valid: false,
          validated: false,
        },
        email: {
          valid: false,
          validated: false,
        },
        password: {
          valid: false,
          validated: false,
        },
      },
    };
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  computed: {
    doesFormSubmitted() {
      return this.formSubmitting;
    },
    // hasErrorFeedback() {
    //   return this.formErrorFeedback;
    // },
    ...mapGetters("user", ["loggedIn"]),
  },
  methods: {
    ...mapActions("user", ["loginAction"]),
    handleLoginSubmit() {
      this.formSubmitting = true;
      this.loginAction({
        organization:this.formValues.organization, 
        email: this.formValues.email,
        password: this.formValues.password,
      })
        // .then((success) => {
        .then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          // console.log(error);
          if (!error.response) {
            
            this.formErrorFeedback = "Error: Network Error";
          } else {
            // console.log(error)
            if(error.response == 'error' && error.reason =='permission_denied'){
              this.formErrorFeedback = "Permission denied! Please try login with mobile application.";
            }else{
              this.formErrorFeedback = "Login Failed! Invalid Organization or Invalid Email and Password.";
            }
          }

          this.error = true;
          this.formSubmitting = false;
        });
    },
    validateForm() {
      this.formValidation = true;
      this.formErrorFeedback = false;
      Object.keys(this.formValues).forEach((key) => {
        this.validate(key);
      });

      if (this.formValidation === true) {
        this.handleLoginSubmit();
      }
    },
    validate(key) {

      
      if (key === "organization") {
        if (this.formValues[key]) {
          this.validation[key].valid = true;
        } else {
          this.validation[key].valid = false;
          this.formValidation = false;
        }
        this.validation[key].validated = true;
      }


      if (key === "email") {
        if (this.formValues[key] && re.test(String(this.formValues[key]).toLowerCase()) ) {
          this.validation[key].valid = true;
        } else {
          this.validation[key].valid = false;
          this.formValidation = false;
        }
        this.validation[key].validated = true;
      }

      if (key === "password") {
        // check length
        if (this.formValues[key].length > 5) {
          // check uppercase
          //   for (let character of this.formValues[key].split("")) {
          //     if (character === character.toUpperCase()) {
          //       this.validation[key].valid = true;
          //       break;
          //     }
          //     this.validation[key].valid = false;
          //     this.formValidation=false
          //     this.validation[key].invalidFeedback =
          //       "Password should contain at least one uppercase character."
          //   }
          this.validation[key].valid = true;
        } else {
          this.validation[key].valid = false;
          this.formValidation = false;
          this.validation[key].invalidFeedback =
            "Password too short. Type at least 6 letters.";
        }
        this.validation[key].validated = true;
      }
    },
  },
};
</script>

<style>
.form-elegant .font-small {
  font-size: 0.8rem;
}

.form-elegant .z-depth-1a {
  -webkit-box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26),
    0 4px 12px 0 rgba(121, 155, 254, 0.25);
  box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26),
    0 4px 12px 0 rgba(121, 155, 254, 0.25);
}

.form-elegant .z-depth-1-half,
.form-elegant .btn:hover {
  -webkit-box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28),
    0 4px 15px 0 rgba(36, 133, 255, 0.15);
  box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28),
    0 4px 15px 0 rgba(36, 133, 255, 0.15);
}
</style>