<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-show="!isLoading">
        <div class="list-group">
          <mdb-card>
          <mdb-card-body>
          <mdb-tbl  hover>
            <!-- Table head -->

            <mdb-tbl-head>
              <tr>
                <th @click="sort('epinfo_name')">
                  <div class="name_center">
                    <i
                      v-show="currentSort === 'epinfo_name'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("general.formInput.name") }}
                  </div>
                </th>
                <th @click="sort('employee_code')">
                  <i
                    v-show="currentSort === 'employee_code'"
                    :class="[
                      currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ',
                    ]"
                    aria-hidden="true"
                  ></i>
                  {{ $t("employees.formInput.employee_id") }}
                </th>
                <th>
                  {{ $t("employees.formInput.rank") }}
                </th>

                <th>Status</th>
              </tr>
            </mdb-tbl-head>

            <!-- Table body -->
            <mdb-tbl-body>
              <tr
                scope="row"
                v-for="(record_item, index) in pageOfItems"
                :key="index"
              >
                <td scope="row">
                  <EmployeeInfoTableCellLayout :user="record_item" />
                </td>
                <td class="align-middle">
                  {{ record_item.employee_code }}
                </td>
                <td class="align-middle">
                  {{ record_item.rt_name }}
                </td>

                <td class="align-middle">
                  {{ record_item.status }}
                </td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>
          <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->

          
        <div class="card-footer pb-0 pt-3">
          <jw-pagination
            :items="sortedRecordList"
            @changePage="onChangePage"
            :pageSize="rowsPerPage"
            :disableDefaultStyles="true"
            :labels="customLabels"
          ></jw-pagination>
        </div>
          </mdb-card-body>
          </mdb-card>

        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import JwPagination from "jw-vue-pagination";
Vue.component("jw-pagination", JwPagination);

import Announcement from "@/apis/Announcement";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";
import { mapActions, mapGetters } from "vuex";


import {
    mdbCard,
  mdbCardBody,
  // mdbRow,
  // mdbCol,
  // mdbBtn,
  mdbSpinner,
  // mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

const customLabels = {
    first: '«',
    last: '»',
    previous: '<',
    next: '>'
};

export default {
  components: {
      mdbCard,
  mdbCardBody,
    // mdbRow,
    // mdbCol,
    // mdbBtn,
    mdbSpinner,
    // mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    EmployeeInfoTableCellLayout,
  },
  props: ["_id", "department_id"],
  data() {
    return {
      customLabels,
      pageOfItems: [],
      rowsPerPage : this.$store.state.rowsPerPage,
      employees: [],
      currentSort: "epinfo_name",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    sortedRecordList() {
      return this.employees.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },

    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    fetchRecordList() {
      if (this._id != undefined && this.department_id != undefined) {
        if (Math.sign(this._id) === 1 && Math.sign(this.department_id) === 1) {
          this.updateLoading(true);

          Announcement.viewDetail({
            id: this._id,
            department_id: this.department_id,
          })
            .then((response) => {
              if (response.status === 200) {
                this.employees = response.data.data;
                this.updateLoading(false);
              }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.feedback_message = "Could not connected to server";
                this.feedback_error = true;

                this.updateLoading(false);
              } else {
                console.log(error);
                this.updateLoading(false);
              }
            });
        } else {
          this.$router.push({ name: "listAnnouncement" });
        }
      } else {
        this.$router.push({ name: "listAnnouncement" });
      }
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
<style >
.pagination li.previous {
  display: none;
}
.pagination li.next{
  display: none;
}
</style>