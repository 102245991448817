<template>
  <div>

    <mdb-row>
      <mdb-col
        md="6"
        v-for="(user, index) in users"
        :key="index"
        class="mb-3"
      >
      <div class="info_border_box">
        <!-- <img
          src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img%20(132).jpg"
          class="rounded-circle float-left ml-3"
          width="50"
          height="50"
        /> -->
         <img
          :src="user.profile_image"
          class="rounded-circle float-left  ml-3"
          width="50"
          height="50"
          v-if="user.profile_image"
        />


        <div class="float-left ml-3 mb-3">
          <div class="employee_name">{{ user.epinfo_name }}</div>
          <div class="employee_info_text_sec">{{ user.jp_name }}</div>          
          <div class="employee_info_text">
            {{ user.department_name }}
          </div>          
        </div>

        <div style="clear:both;"></div>

        <div class="behalf_box">{{ user.behalf_by }}</div>
        <div class="">{{ user.remark }}</div>
      </div>        
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import { mdbRow, mdbCol } from "mdbvue";
export default {
  name: "ApprovedUserLayout",
  components: { mdbRow, mdbCol },
  props: ["users"],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
