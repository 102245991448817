<template>
  <div>
    <mdb-modal size="size" :show="modalVisible" @close="closeModal">
      <mdb-modal-header>
        <mdb-modal-title>
          <slot name="header"> Filter Date </slot>
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <slot name="body">
          <mdb-row>
            <mdb-col class="form-inline-search">
              <mdb-select
                search
                outline
                v-model.trim="yearsOptions"
                :label="$t('holidays.formInput.select_year')"
                placeholder=""
                v-on:getValue="getSelectValue"
              />
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col class="form-inline-search">
              <mdb-select
                outline
                v-model.trim="monthOptions"
                :label="$t('holidays.formInput.select_month')"
                placeholder=""
                v-on:getValue="getSelectMonthValue"
              />
            </mdb-col>
          </mdb-row>
        </slot>
      </mdb-modal-body>
      <mdb-modal-footer>

        

        <mdb-btn color="danger" 
        @click="resetForm" 
        size="sm"
        :disabled="formSubmitting"
          >

        <mdb-icon
          icon="file-upload"
          size="md"
          v-show="!formSubmitting"
        />  

        <span
          class="spinner-border-vcenter spinner-border-sm"
          role="status"
          aria-hidden="true"
          v-show="formSubmitting"
        ></span>
          
          Reset</mdb-btn
        >


        <mdb-btn color="secondary" size="sm" @click.native="closeModal"
          >Close</mdb-btn
        >
        <mdb-btn color="primary" 
        @click="confirmAction" 
        size="sm"
        :disabled="formSubmitting"
          >

        <mdb-icon
          icon="file-upload"
          size="md"
          v-show="!formSubmitting"
        />  

        <span
          class="spinner-border-vcenter spinner-border-sm"
          role="status"
          aria-hidden="true"
          v-show="formSubmitting"
        ></span>
          
          Filter</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbSelect,
  mdbRow,
  mdbCol,
} from "mdbvue";

const yearOption = (current, year) => {
  let years = [];
  for (let current_year = current; current_year >= 1900; --current_year) {
    if (year == current_year)
      years.push({ text: current_year, value: current_year, selected: true });
    else years.push({ text: current_year, value: current_year });
  }
  return years;
};

const month_name = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthOption = (month) => {
  let months = [];
  for (let i = 0; i < month_name.length; i++) {
    if (month == month_name[i])
      months.push({
        text: month_name[i],
        value: month_name[i],
        selected: true,
      });
    else months.push({ text: month_name[i], value: month_name[i] });
  }
  return months;
};

export default {
  name: "ModalDashboardFilter",
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbSelect,
    mdbRow,
    mdbCol,
  },

  data() {
    return {
      filterForm: {
        year: "",
        month: "",
      },

      yearsOptions: null,
      monthOptions: null,

      // formSubmitting: false,
      doesFormReset: false,
    };
  },

  props: ["filterFormData", "modalVisible","formSubmitting"],

  computed: {},

  methods: {
    closeModal() {
      this.$emit("closeFilterForm");
    },
    getSelectValue(value) {
      this.filterForm.year = value;
    },
    getSelectMonthValue(value) {
      this.filterForm.month = value;
    },
    confirmAction() {
      this.formSubmitting = true;
      this.$emit("submitFilterForm", this.filterForm);
    },
    resetForm() {
      this.formSubmitting = true;
      this.filterForm.year="";
      this.filterForm.month="";
      //  this.doesFormReset = !this.doesFormReset;

      this.yearsOptions = yearOption(2021, this.filterForm.year);
      this.monthOptions = monthOption(this.filterForm.month);

      this.$emit("submitFilterForm", this.filterForm);
    },
  },


  created() {
    this.modalVisible = false;
    this.filterForm = this.filterFormData;
    this.yearsOptions = yearOption(parseInt(new Date().getFullYear()) +1, this.filterForm.year);
    this.monthOptions = monthOption(this.filterForm.month);
  },
};
</script>
