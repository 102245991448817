<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="educationInfos.edinfo_school_name"
                    outline
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('education_info.formInput.field_of_study')"
                    v-model="educationInfos.edinfo_field_of_study"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('education_info.formInput.degree_type')"
                    v-model="educationInfos.edinfo_degree_type"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    v-model="educationInfos.edinfo_start_from"
                    :label="$t('education_info.formInput.start_from')"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-date-picker-2
                    v-model="educationInfos.edinfo_end_at"
                    :label="$t('education_info.formInput.end_at')"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-select
                    outline
                    search
                    v-model.trim="OptionYear"
                    :label="$t('general.formInput.select_graduation_year')"
                    placeholder=""
                    @getValue="updateYearValue"
                  />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"                    
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import EmployeeEducationInfo from "@/apis/EmployeeEducationInfo";
import LogList from "@/components/logs/List";
import { yearOption } from "@/constants/employee.js";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  mdbSelect,
  // mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbDatePicker2,
    mdbSelect,
    ModalDeleteConfimation,
    // mdbFormInline,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      educationInfos: {
        edinfo_id: "",
        edinfo_employee_id:
          this.employee_id != "undefined" ? this.employee_id : "",
        edinfo_school_name: "",
        edinfo_field_of_study: "",
        edinfo_degree_type: "",
        edinfo_start_from: "",
        edinfo_end_at: "",
        edinfo_graduation_year: "",
      },
      OptionYear: yearOption(true, ""),
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.educationInfos.edinfo_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    updateYearValue(val) {
      if (val != null && val != "") {
        this.educationInfos.edinfo_graduation_year = val.toString();
      } else {
        this.educationInfos.edinfo_graduation_year = "";
      }
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          EmployeeEducationInfo.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  //  console.log(response.data)

                  let edinfo_id,
                    edinfo_employee_id,
                    edinfo_school_name,
                    edinfo_field_of_study,
                    edinfo_degree_type,
                    edinfo_start_from,
                    edinfo_end_at,
                    edinfo_graduation_year;
                  // populate field
                  ({
                    edinfo_id,
                    edinfo_employee_id,
                    edinfo_school_name,
                    edinfo_field_of_study,
                    edinfo_degree_type,
                    edinfo_start_from,
                    edinfo_end_at,
                    edinfo_graduation_year,
                  } = response.data.data);
                  this.educationInfos.edinfo_id = edinfo_id;
                  this.educationInfos.edinfo_employee_id = edinfo_employee_id;
                  this.educationInfos.edinfo_school_name = edinfo_school_name;
                  this.educationInfos.edinfo_field_of_study = edinfo_field_of_study;
                  this.educationInfos.edinfo_degree_type = edinfo_degree_type;
                  this.educationInfos.edinfo_start_from = edinfo_start_from;
                  this.educationInfos.edinfo_end_at = edinfo_end_at;
                  this.educationInfos.edinfo_graduation_year = edinfo_graduation_year;
                  //make selected option again
                  this.OptionYear = yearOption(true, edinfo_graduation_year);
                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    educationInfoList: {
                      employee_id: this.educationInfos.edinfo_employee_id,
                    },
                  };
                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "educationInfoList" });
              }
            })
            .catch(() => {
              // console.log(error)
              this.$router.push({ name: "educationInfoList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "educationInfoList" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      EmployeeEducationInfo.save(this.educationInfos).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.educationInfos.edinfo_id != "") {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({
              name: "educationInfoList",
              params: { _id: this.educationInfos.edinfo_employee_id },
            });
            } else {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.resetForm();
            }
            
            
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({
        name: "educationInfoList",
        params: { employee_id: this.educationInfos.edinfo_employee_id },
      });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.educationInfos).forEach((key) => {
        this.educationInfos[key] = "";
      });
      this.educationInfos.edinfo_employee_id = this.employee_id;
      this.OptionYear = yearOption(true, "");
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.educationInfos.edinfo_id > 0) {
        EmployeeEducationInfo.delete({
          edinfo_id: this.educationInfos.edinfo_id,
        })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({
                  name: "educationInfoList",
                  params: {
                    _id: this.educationInfos.edinfo_employee_id,
                  },
                });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      educationInfoList: {
        employee_id: this.educationInfos.edinfo_employee_id,
      },
    };
    this.fetchRecord();
  },
};
</script>