<template>
  <mdb-pie-chart datalabels :data="pieChartData" :options="pieChartOptions" />
</template>

<script>
import { mdbPieChart } from "mdbvue";

export default {
  name: "PieChartView",
  components: {
    mdbPieChart,
  },
  props: ["attendanceData"],
  data() {
    return {
      pieChartData: {
        labels: ["Present", "Leave", "Absent"],
        datasets: [
          {
            data: this.attendanceData,
            backgroundColor: ["#46BFBD", "#FDB45C", "#F7464A"],
            hoverBackgroundColor: ["#5AD3D1", "#FFC870", "#FF5A5E"],
          },
        ],
      },
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
        },
        plugins: {
          datalabels: {
            color: "white",
            align: "center",
            font: {
              size: 16,
            },
            formatter: (value) => {
              const [dataset] = this.pieChartData.datasets;
              const setValue = dataset.data.reduce((a, b) => a + b);

              return `${Math.round((value / setValue) * 100)}%`;
            },
          },
        },
      },
    };
  },
};
</script>