<template>
  <mdb-select
    outline
    v-model="axiosOptions"
    :label="selectLabel"
    @getValue="updateSelectedValue"
  />
</template>

<script>
import { mdbSelect } from "mdbvue";
export default {
  name: "ApprovalStatusDropdown",
  components: {
    mdbSelect,
  },
  props: ["default_selected_value", "required", "resetSelected", "rejectStatus"],
  data() {
    return {
      axiosOptions: [],
      StatusOptions: [
        { text: "All", value: "", selected: false },
        { text: "Pending", value: "pending", selected: false },
        { text: "Approve", value: "approve", selected: false },
        { text: "Cancel", value: "cancel", selected: false },
      ],
      selectLabel: "Select",
    };
  },
  mounted() {

    if(this.rejectStatus){
        this.StatusOptions = [
        { text: "All", value: "", selected: false },
        { text: "Pending", value: "pending", selected: false },
        { text: "Approve", value: "approve_and_fix", selected: false },
        { text: "Reject", value: "reject", selected: false },
        { text: "Cancel", value: "cancel", selected: false },
      ]
    }

    for (let option of this.StatusOptions) {
      if (this.default_selected_value === option.value) {
        this.axiosOptions.push({
          text: option.text,
          value: option.value,
          selected: true,
        });
      } else {
        this.axiosOptions.push({
          text: option.text,
          value: option.value,
        });
      }
    }
  },
  methods: {
    updateSelectedValue(val) {
      if (val !== null) {
        this.$emit("updateStatusValue", val);
      }
    },
  },
  watch: {
    resetSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.StatusOptions = this.StatusOptions.map((val) => ({
          text: val.text,
          value: val.value,
        }));
      }
    },
  },
};
</script>
