<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="leave_types.leave_type_name"
                    outline
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('leave_type.formInput.code')"
                    v-model="leave_types.leave_type_code"
                    outline
                    required
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <label>{{ $t("leave_type.formInput.isunpaid") }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="leave_type_isunpaid_yes"
                      name="leave_type_isunpaid"
                      radioValue="yes"
                      v-model="leave_types.leave_type_isunpaid"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="leave_type_isunpaid_no"
                      name="leave_type_isunpaid"
                      radioValue="no"
                      v-model="leave_types.leave_type_isunpaid"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>

              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <label>{{
                    $t("leave_type.formInput.calculate_continuous")
                  }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="leave_type_calculate_continuous_yes"
                      name="leave_type_calculate_continuous"
                      radioValue="yes"
                      v-model="leave_types.leave_type_calculate_continuous"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="leave_type_calculate_continuous_no"
                      name="leave_type_calculate_continuous"
                      radioValue="no"
                      v-model="leave_types.leave_type_calculate_continuous"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label>{{
                    $t("leave_type.formInput.allow_next_year")
                  }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="leave_type_allow_next_year_yes"
                      name="leave_type_allow_next_year"
                      radioValue="yes"
                      v-model="leave_types.leave_type_allow_next_year"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="leave_type_allow_next_year_no"
                      name="leave_type_allow_next_year"
                      radioValue="no"
                      v-model="leave_types.leave_type_allow_next_year"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                  <br />
                </mdb-col>
              </mdb-row>



              

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label>Calculate for Top Leave Employee</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="calculate_top_yes"
                      name="leave_type_calculate_on_top_leave_report"
                      radioValue="yes"
                      v-model="leave_types.leave_type_calculate_on_top_leave_report"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="calculate_top_no"
                      name="leave_type_calculate_on_top_leave_report"
                      radioValue="no"
                      v-model="leave_types.leave_type_calculate_on_top_leave_report"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                  <br />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                  <mdb-btn
                    type="button"              
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"              
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>            
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>

    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import LeaveType from "@/apis/LeaveType";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbFormInline,
    ModalDeleteConfimation,
  },
  props: ["_id"],
  data() {
    return {
      leave_types: {
        leave_type_id: "",
        leave_type_name: "",
        leave_type_code: "",
        leave_type_isunpaid: "no",
        leave_type_calculate_continuous: "no",
        leave_type_allow_next_year: "no",
        leave_type_calculate_on_top_leave_report:"no"
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.leave_types.leave_type_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          LeaveType.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)
                  let leave_type_id,
                    leave_type_name,
                    leave_type_code,
                    leave_type_isunpaid,
                    leave_type_calculate_continuous,
                    leave_type_allow_next_year,
                    leave_type_calculate_on_top_leave_report;
                  // populate field
                  ({
                    leave_type_id,
                    leave_type_name,
                    leave_type_code,
                    leave_type_isunpaid,
                    leave_type_calculate_continuous,
                    leave_type_allow_next_year,
                    leave_type_calculate_on_top_leave_report
                  } = response.data.data);
                  this.leave_types.leave_type_id = leave_type_id;
                  this.leave_types.leave_type_name = leave_type_name;
                  this.leave_types.leave_type_code = leave_type_code;
                  this.leave_types.leave_type_isunpaid =
                    leave_type_isunpaid == "1" ? "yes" : "no";
                  this.leave_types.leave_type_calculate_continuous =
                    leave_type_calculate_continuous == "1" ? "yes" : "no";
                  this.leave_types.leave_type_allow_next_year =
                    leave_type_allow_next_year == "1" ? "yes" : "no";

                  this.leave_types.leave_type_calculate_on_top_leave_report = 
                  leave_type_calculate_on_top_leave_report == "1" ? "yes" : "no";  
                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listLeaveType" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listLeaveType" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listLeaveType" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      LeaveType.save(this.leave_types).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.leave_types.leave_type_id != "") {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "listLeaveType" });
            } else {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({ name: "listLeaveType" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.leave_types).forEach((key) => {
        this.leave_types[key] = "";
      });
      
        this.leave_types.leave_type_isunpaid= "no";
        this.leave_types.leave_type_calculate_continuous= "no";
        this.leave_types.leave_type_allow_next_year= "no";
        this.leave_types.leave_type_calculate_on_top_leave_report = "no";
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.leave_types.leave_type_id > 0) {
        LeaveType.delete({ leave_type_id: this.leave_types.leave_type_id })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({ name: "listLeaveType" });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>