import Api from './api'

export default {

  getActivityViewerList(payload) {
    return Api.get('api/employees/activity_viewer_right/list/' + payload)
  },

  saveActivityViewer(payload) {
    return Api.post('api/employees/activity_viewer_right/data_entry', payload)
  },

  deleteActivityViewer(payload) {
    return Api.post('api/employees/activity_viewer_right/delete', payload)
  },
  
  getActivityList(payload) {
    return Api.get('api/activity/list', {params: payload})
  },


}