<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="permission.permission_name"
                    outline
                    required
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <!-- <mdb-form-inline> -->
                  <mdb-input
                    type="checkbox"
                    id="dashboard"
                    v-model="permission_options.dashboard"
                    label="Dashboard"
                  />

                  <mdb-input
                    type="checkbox"
                    id="announcement_management"
                    v-model="permission_options.announcement_management"
                    label="Manage Announcements"
                  />

                  

                  <mdb-input
                    type="checkbox"
                    id="web_approvals"
                    v-model="permission_options.web_approvals"
                    label="Web Approvals"
                  />

                  <mdb-input
                    type="checkbox"
                    id="imports"
                    v-model="permission_options.imports"
                    label="Imports"
                  />
                  

                  <mdb-input
                    type="checkbox"
                    id="setting"
                    v-model="permission_options.setting"
                    label="Manage Settings"
                  />
                  <!-- </mdb-form-inline> -->
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8"> <hr /></mdb-col
              ></mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label>Employees Database</label>
                  <!-- <mdb-form-inline> -->
                  <mdb-input
                    type="checkbox"
                    id="employee_management"
                    v-model="permission_options.employee_management"
                    label="Manage Employee Database"
                  />

                  <mdb-input
                    type="checkbox"
                    id="employee_view"
                    v-model="permission_options.employee_view"
                    label="View Employee Database"
                  />
                  <!-- </mdb-form-inline> -->
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8"> <hr /></mdb-col
              ></mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label>Attendance Database</label>
                  <!-- <mdb-form-inline> -->
                  <mdb-input
                    type="checkbox"
                    id="attendance_management"
                    v-model="permission_options.attendance_management"
                    label="Manage Employee Attendance"
                  />

                  <mdb-input
                    type="checkbox"
                    id="attendance_view"
                    v-model="permission_options.attendance_view"
                    label="View Employee Attendance"
                  />
                  <!-- </mdb-form-inline> -->
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8"> <hr /></mdb-col
              ></mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label>Leave Database</label>
                  <!-- <mdb-form-inline> -->
                  <mdb-input
                    type="checkbox"
                    id="leave_management"
                    v-model="permission_options.leave_management"
                    label="Manage Employee Leave"
                  />

                  <mdb-input
                    type="checkbox"
                    id="leave_view"
                    v-model="permission_options.leave_view"
                    label="View Employee Leave"
                  />
                  <!-- </mdb-form-inline> -->
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8"> <hr /></mdb-col
              ></mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label>Overtime Database</label>
                  <!-- <mdb-form-inline> -->
                  <mdb-input
                    type="checkbox"
                    id="overtime_management"
                    v-model="permission_options.overtime_management"
                    label="Manage Employee Overtime"
                  />

                  <mdb-input
                    type="checkbox"
                    id="overtime_view"
                    v-model="permission_options.overtime_view"
                    label="View Employee Overtime"
                  />
                  <!-- </mdb-form-inline> -->
                </mdb-col>
              </mdb-row>


              
              <mdb-row>
                <mdb-col offsetMd="2" md="8"> <hr /></mdb-col
              ></mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label>HR Trainings</label>
                  <!-- <mdb-form-inline> -->
                  <mdb-input
                    type="checkbox"
                    id="training_management"
                    v-model="permission_options.training_management"
                    label="Manage HR Training"
                  />

                  <mdb-input
                    type="checkbox"
                    id="training_view"
                    v-model="permission_options.training_view"
                    label="View HR Training"
                  />
                  <!-- </mdb-form-inline> -->
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8"> <hr /></mdb-col
              ></mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label>Manage Payroll</label>
                  <!-- <mdb-form-inline> -->
                  <mdb-input
                    type="checkbox"
                    id="payroll_manage"
                    v-model="permission_options.payroll_manage"
                    label="Manage Payroll"
                  />
                  <!-- </mdb-form-inline> -->

                  <div>
                    <RankMultiSelectDropdown
                      @updateSelectedRankIDs="setSelectedRankIds"
                      :default_selected_value="permission.permisson_rank_salary"
                    />
                  </div>
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8"> <hr /></mdb-col
              ></mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label>Report</label>
                  <!-- <mdb-form-inline> -->

                  <mdb-input
                    type="checkbox"
                    id="general_report"
                    v-model="permission_options.general_report"
                    label="General Report"
                  />

                  <mdb-input
                    type="checkbox"
                    id="special_report"
                    v-model="permission_options.special_report"
                    label="Special Report"
                  />

                  <mdb-input
                    type="checkbox"
                    id="payslips_report"
                    v-model="permission_options.payslips_report"
                    label="Payslips Report"
                  />

                  <mdb-input
                    type="checkbox"
                    id="bank_report"
                    v-model="permission_options.bank_report"
                    label="Bank Report"
                  />

                  <mdb-input
                    type="checkbox"
                    id="tax_report"
                    v-model="permission_options.tax_report"
                    label="Tax Report"
                  />

                  <mdb-input
                    type="checkbox"
                    id="ssb_report"
                    v-model="permission_options.ssb_report"
                    label="SSB Report"
                  />

                  <mdb-input
                    type="checkbox"
                    id="pending_report"
                    v-model="permission_options.pending_report"
                    label="Pending Report"
                  />

                  <mdb-input
                    type="checkbox"
                    id="leave_taken_balance"
                    v-model="permission_options.leave_taken_balance"
                    label="Leave Taken/balance"
                  />

                  <mdb-input
                    type="checkbox"
                    id="attendance_fixing"
                    v-model="permission_options.attendance_fixing"
                    label="Attendance Fixing Report"
                  />

                  <mdb-input
                    type="checkbox"
                    id="late_report"
                    v-model="permission_options.late_report"
                    label="Late Report"
                  />

                  <mdb-input
                    type="checkbox"
                    id="account_report"
                    v-model="permission_options.account_report"
                    label="Account Report"
                  />
                  <!-- </mdb-form-inline> -->
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8"><br /></mdb-col
              ></mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"                    
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>

    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import Permission from "@/apis/Permission";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";

import RankMultiSelectDropdown from "@/components/RankMultiSelectDropdown";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  // mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    // mdbFormInline,
    ModalDeleteConfimation,
    RankMultiSelectDropdown,
  },
  props: ["_id"],
  data() {
    return {
      permission: {
        permission_id: "",
        permission_name: "",
        permission_key: [],
        permisson_rank_salary: [],
      },
      permission_options: {
        employee_management: false,
        employee_view: false,
        attendance_management: false,
        attendance_view: false,
        leave_management: false,
        leave_view: false,
        overtime_management: false,
        overtime_view: false,
        payroll_manage: false,
        account_report: false,
        general_report:false,
        special_report: false,
        payslips_report: false,
        bank_report: false,
        tax_report: false,
        ssb_report: false,
        pending_report: false,
        leave_taken_balance: false,
        attendance_fixing: false,
        late_report: false,
        allow_to_view_salary: false,
        dashboard: false,
        announcement_management: false,
        imports:false,
        setting: false,
        web_approvals:false,

        training_view:false,
        training_management:false

      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.permission.permission_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    setSelectedRankIds(val) {
      this.permission.permisson_rank_salary = val;
    },
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          Permission.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)
                  let permission_id,
                    permission_name,
                    permission_key,
                    permisson_rank_salary;

                  // populate field
                  ({
                    permission_id,
                    permission_name,
                    permission_key,
                    permisson_rank_salary,
                  } = response.data.data);

                  this.permission.permission_id = permission_id;
                  this.permission.permission_name = permission_name;
                  this.permission.permission_key = permission_key
                    ? permission_key
                    : [];
                  this.permission.permisson_rank_salary = permisson_rank_salary
                    ? permisson_rank_salary
                    : [];

                  if (permission_key && permission_key.length > 0) {
                    for (const tmp_p_key of permission_key) {
                      this.permission_options[tmp_p_key] = true;
                    }
                  }

                  // logs
                  if (response.data.logs.length > 0) {

                    if( response.data.logs.length > 0){
                      this.logs = response.data.logs.map(x =>{
                        delete x.log_text
                        return x
                      });
                    }
                  }
                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listPermission" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listPermission" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listPermission" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      this.permission.permission_key = [];
      for (const [key, value] of Object.entries(this.permission_options)) {
        if (value === true) {
          this.permission.permission_key.push(key);
        }
      }

      Permission.save(this.permission).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.permission.permission_id != "") {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "listPermission" });
            } else {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({ name: "listPermission" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      this.permission = {
        permission_id: "",
        permission_name: "",
        permission_key: [],
        permisson_rank_salary: [],
      };

      this.permission_options = [];
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.permission.permission_id > 0) {
        Permission.delete({ permission_id: this.permission.permission_id })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({ name: "listPermission" });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>