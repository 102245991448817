<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">
        <AttendanceSearchForm @submitFilterForm="handleSubmitFilterForm" :filterFormData="searchFormData"/>

        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn
                color="uno-color"
                class="btn_submit"
                @click="createNewRecord"
                size="sm"
              >
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th></th>
                  <th>
                    {{ $t("general.label.date") }}
                  </th>
                  <th>
                    {{ $t("general.label.status") }}
                  </th>
                  <th>
                    {{ $t("general.label.checkin") }}
                  </th>
                  <th>
                    {{ $t("general.label.checkout") }}
                  </th>
                  <th>
                    {{ $t("general.label.overtime") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(recordlist, index) in attendance_list.data"
                  :key="index"
                >
                  <td
                    scope="row"
                    @click="rowClickEvent(recordlist.attendance_id)"
                  >
                    <EmployeeInfoTableCellLayout :user="recordlist" />
                  </td>
                  <td
                    @click="rowClickEvent(recordlist.attendance_id)"
                  >
                    {{ recordlist.attendance_day | formatDateString }}
                  </td>
                  <td @click="rowClickEvent(recordlist.attendance_id)">
                    {{ recordlist.attendance_checkin_status }}
                    <template
                      v-if="
                        recordlist.attendance_checkin_status &&
                        recordlist.attendance_checkout_status
                      "
                    >
                      /
                    </template>
                    {{ recordlist.attendance_checkout_status }}
                  </td>
                  <td @click="rowClickEvent(recordlist.attendance_id)">
                    {{
                      recordlist.attendance_checkin_time
                        | formatDateTimeString
                    }}
                    <br />
                    {{ recordlist.attendance_checkin_type }}
                  </td>
                  <td @click="rowClickEvent(recordlist.attendance_id)">
                    {{
                      recordlist.attendance_checkout_time
                        | formatDateTimeString
                    }}
                    <br />
                    {{ recordlist.attendance_checkout_type }}
                  </td>
                  <td
                    @click="rowClickEvent(recordlist.attendance_id)"
                  ></td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <pagination :data="attendance_list" @pagination-change-page="fetchRecordList" :limit="2"></pagination>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
Vue.component('pagination', require('laravel-vue-pagination'))

import Attendance from "@/apis/Attendance";
import { mapActions, mapGetters } from "vuex";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";

import AttendanceSearchForm from "@/components/search/AttendanceSearchForm";
import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    EmployeeInfoTableCellLayout,
    AttendanceSearchForm
  },

  data() {
    return {
      attendance_list: [],
      searchFormData: {
        keywords: "",
        department_id: "",
        jp_id: "",
        from_date:"",
        to_date:"",
        status:""
      },
      currentSort: "contract_reference",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    rowClickEvent(id) {
      this.$router.push({
        name: "viewManageAttendance",
        params: { _id: id },
      });
    },

    handleSubmitFilterForm(val){
      this.searchFormData = val
      this.fetchRecordList()
    },

    createNewRecord() {
      this.$router.push({
        name: "createManageAttendance"
      });
    },

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      const params = {
        page: page,
        keywords: this.searchFormData.keywords,
        department_id: this.searchFormData.department_id,
        jp_id: this.searchFormData.jp_id,        
        from_date:this.searchFormData.from_date,
        to_date:this.searchFormData.to_date,
        status:this.searchFormData.status
      };
      Attendance.employeeAttendance(params)
        .then((response) => {
          if (response.status === 200) {
            this.attendance_list = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
