<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-show="!isLoading">
        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn color="uno-color"
                class="btn_submit" @click="createNewRecord" size="sm">
                <mdb-icon far icon="plus-square" size="lg" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th @click="sort('otd_category')">
                    <i
                      v-show="currentSort === 'otd_category'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("deduction.formInput.category") }}
                  </th>
                  <th>
                    {{ $t("deduction.formInput.amount") }}
                  </th>
                  <th>
                    {{ $t("deduction.formInput.description") }}
                  </th>
                  <th
                    width="120"
                    class="text-right"
                    @click="sort('otd_created')"
                  >
                    <i
                      v-show="currentSort === 'otd_created'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("general.formInput.created") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(recordlist, index) in sortedBankInfoList"
                  :key="index"
                >
                  <td scope="row" @click="rowClickEvent(recordlist.otd_id)">
                    <span v-if="recordlist.otd_category == 'hp'">
                      Hire Purchase
                    </span>
                    <span v-else-if="recordlist.otd_category == 'loan'">
                      Loan
                    </span>
                    <span v-else-if="recordlist.otd_category == 'saving'">
                      Saving
                    </span>
                    <span v-else-if="recordlist.otd_category == 'hostel_charges'">
                      Hostel Charges
                    </span>
                    <span v-else-if="recordlist.otd_category == 'tax_adjustment'">
                      Tax Adjustment
                    </span>
                    <span v-else-if="recordlist.otd_category == 'others'">
                      Others
                    </span>
                    <span v-else>
                      {{recordlist.otd_category}}
                    </span>

                  </td>
                  <td @click="rowClickEvent(recordlist.otd_id)">
                    {{ recordlist.otd_total_amount }}
                  </td>
                  
                  <td @click="rowClickEvent(recordlist.otd_id)">
                    {{ recordlist.otd_description | cutStringTableCell }}
                  </td>
                  <td
                    class="text-right"
                    @click="rowClickEvent(recordlist.otd_id)"
                  >
                    {{ recordlist.otd_created | formatDateString }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import Deduction from "@/apis/Deduction";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },

  data() {
    return {
      bankInfos: [],
      currentSort: "otd_category",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  props: ["employee_id"],
  computed: {
    ...mapGetters(["isLoading"]),

    sortedBankInfoList() {
      return this.bankInfos.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    rowClickEvent(id) {
      this.$router.push({
        name: "viewDeduction",
        params: { employee_id: this.employee_id, _id: id },
      });
    },

    createNewRecord() {
      this.$router.push({
        name: "createDeduction",
        params: { employee_id: this.employee_id },
      });
    },

    fetchRecordList() {
      this.updateLoading(true);

      // if (this.employee_id != undefined) {
      //   if (Math.sign(this.employee_id) === 1) {
        const params = {
            employee_id: this.employee_id,
          };
          Deduction.all(params)
            .then((response) => {
              if (response.status === 200) {
                this.bankInfos = response.data.data;
                this.updateLoading(false);
              }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.feedback_message = "Could not connected to server";
                this.feedback_error = true;

                this.updateLoading(false);
              } else {
                console.log(error);
                this.updateLoading(false);
              }
            });
      //   }
      // }
    },
  },
  created() {
    if (!this.employee_id || Math.sign(this.employee_id) !== 1) {
      this.$router.push({ name: "listEmployee" });
    } else {
      this.fetchRecordList();
    }
  },
};
</script>
