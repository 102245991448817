<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">
        <WorkingHistorySearchForm @submitFilterForm="handleSubmitFilterForm" :filterFormData="searchFormData"/>

        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn
                color="uno-color"
                class="btn_submit"
                @click="createNewRecord"
                size="sm"
              >
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th></th>
                  <th >
                    {{ $t("deduction.formInput.category") }}
                  </th>
                  <th>
                    {{ $t("deduction.formInput.amount") }}
                  </th>
                  <th>
                    {{ $t("deduction.formInput.description") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(recordlist, index) in other_deduction_list.data"
                  :key="index"
                >
                  <td
                    scope="row"
                    @click="rowClickEvent(recordlist.otd_id)"
                  >
                    <EmployeeInfoTableCellLayout :user="recordlist" />
                  </td>
                  <td @click="rowClickEvent(recordlist.otd_id)">

                    <span v-if="recordlist.otd_category == 'hp'">
                      Hire Purchase
                    </span>
                    <span v-else-if="recordlist.otd_category == 'loan'">
                      Loan
                    </span>
                    <span v-else-if="recordlist.otd_category == 'saving'">
                      Saving
                    </span>
                    <span v-else-if="recordlist.otd_category == 'hostel_charges'">
                      Hostel Charges
                    </span>
                    <span v-else-if="recordlist.otd_category == 'tax_adjustment'">
                      Tax Adjustment
                    </span>
                    <span v-else-if="recordlist.otd_category == 'others'">
                      Others
                    </span>
                    <span v-else>
                      {{recordlist.otd_category}}
                    </span>


                  </td>
                  <td @click="rowClickEvent(recordlist.otd_id)">
                    {{ recordlist.otd_total_amount }}
                  </td>
                  
                  <td @click="rowClickEvent(recordlist.otd_id)">
                    {{ recordlist.otd_description | cutStringTableCell }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <pagination :data="other_deduction_list" @pagination-change-page="fetchRecordList" :limit="2"></pagination>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
Vue.component('pagination', require('laravel-vue-pagination'))

import Deduction from "@/apis/Deduction";
import { mapActions, mapGetters } from "vuex";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";

import WorkingHistorySearchForm from "@/components/search/WorkingHistorySearchForm";
import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    EmployeeInfoTableCellLayout,
    WorkingHistorySearchForm
  },

  data() {
    return {
      other_deduction_list: [],
      searchFormData: {
        keywords: "",
        department_id: "",
        jp_id: "",
      },
      currentSort: "contract_reference",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    rowClickEvent(id) {
      this.$router.push({
        name: "viewManageOtherDeduction",
        params: { _id: id },
      });
    },

    handleSubmitFilterForm(val){
      this.searchFormData = val
      this.fetchRecordList()
    },

    createNewRecord() {
      this.$router.push({
        name: "createManageOtherDeduction"
      });
    },

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      const params = {
        page: page,
        keywords: this.searchFormData.keywords,
        department_id: this.searchFormData.department_id,
        jp_id: this.searchFormData.jp_id,
      };
      Deduction.all(params)
        .then((response) => {
          if (response.status === 200) {
            this.other_deduction_list = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
