<template>

    <mdb-select
      outline
      v-model="axiosOptions"
      :label="selectLabel"
      @getValue="updateSelectedValue"
    />

</template>

<script>
import { mdbSelect } from "mdbvue";
export default {
  name: "EmployeeSearchOperatorDropdown",
  components: {
    mdbSelect,
  },
  props: ["default_selected_value", "required", "resetSelected"],
  data() {
    return {
      axiosOptions: [],
      operatorOptions: [
        { text: "Less Than", value: "less_than", selected: false },
        {
          text: "Less Than and Equal",
          value: "less_than_equal",
          selected: false,
        },
        { text: "Greater Than", value: "greater_than", selected: false },
        {
          text: "Greater Than and Equal",
          value: "greater_than_equal",
          selected: false,
        },
        { text: "Equal", value: "equal", selected: false },
        { text: "Not Equal", value: "not_equal", selected: false },
        { text: "Between", value: "between", selected: false },
      ],
      selectLabel: "Select",
    };
  },
  mounted() {
    for (let option of this.operatorOptions) {
      if (this.default_selected_value === option.value) {
        this.axiosOptions.push({
          text: option.text,
          value: option.value,
          selected: true,
        });
      } else {
        this.axiosOptions.push({
          text: option.text,
          value: option.value,
        });
      }
    }
  },
  methods: {
    updateSelectedValue(val) {
      if (val !== null) {
        this.$emit("updateOperatorValue", val);
      }
    },
  },
  watch: {
    resetSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.operatorOptions = this.operatorOptions.map((val) => ({
          text: val.text,
          value: val.value,
        }));
      }
    },
  },
};
</script>
