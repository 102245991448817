<template>
  <div>
    <mdb-bar-chart
      :data="barChartData"
      :options="barChartOptions"
      :width="1040"
      :height="300"
    >
    </mdb-bar-chart>
  </div>
</template>

<script>
import { mdbBarChart } from "mdbvue";

export default {
  name: "VerticalBarChartView",
  components: {
    mdbBarChart,
  },
  props: ["barGraphData", "ylabel", "xlabel"],
  data() {
    return {
      /////////////
      barChartData: {
        labels: this.barGraphData.label,
        datasets: [
          {
            label: (this.barGraphData.dataLabel != undefined ? this.barGraphData.dataLabel : ""),
            data: this.barGraphData.dataSet,
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
        ],
      },
      barChartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
          display:false
        },
        scales: {
          xAxes: [
            {
              barPercentage: 1,
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)",
              },
              scaleLabel: {
                display: true,
                labelString: this.xlabel!=undefined ? this.xlabel : ''
              }
            },
          ],
          yAxes: [
            {
               ticks: {
                    beginAtZero: true,
                    userCallback: function(label){
                      if(Math.floor(label) === label){
                        return label;
                      }
                    }
                },
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)",
              },
              scaleLabel: {
                display: true,
                labelString: this.ylabel!=undefined ? this.ylabel : ''
              }
            },
          ],
        },
      },

      ////////////
    };
  },
};
</script>