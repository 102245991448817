<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">
        <template v-if="!employee_id">
          <mdb-row v-if="selectedEmployeeData" class="mb-5">
            <mdb-col>
              <div class="approval_info_box">
                <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
              </div>
            </mdb-col>
          </mdb-row>
        </template>

        <mdb-row>
          <mdb-col>
            <mdb-btn
              type="button"
              @click="downloadPayslip"
              color="uno-color"
              class="btn_submit float-right"
            >
            <mdb-icon icon="download" size="md"  />
             
              Download
            </mdb-btn>

            <mdb-btn
              type="button"
              :disabled="deleteSubmitted"
              @click="showDeleteModal"
              color="uno-color"
              class="btn_delete float-right"
            >
              <mdb-icon
                icon="trash-alt"
                far
                size="md"
                v-show="!deleteSubmitted"
              />

              <span
                class="spinner-border-vcenter spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="deleteSubmitted"
              ></span>
              {{ $t("general.button.delete") }}
            </mdb-btn>
          </mdb-col>
        </mdb-row>

        <div class="classic-tabs">
        
          <mdb-tabs
            :active="0"
            border
            class="mb-5"
            color="primary"
            :links="detail_tab"
          >
          
            <template :slot="'Summary'">
              <dl
                class="row"
                v-for="(
                  summary_data_val, key_name, index
                ) in payslip.summary_data"
                :key="index"
              >
                <dt class="col-md-6">
                  {{ $t("payslips.label." + key_name) }}
                </dt>
                <dd class="col-md-6 employee_info_grey_text">
                  {{ summary_data_val }}
                </dd>
              </dl>

              <dl class="row">
                <dt class="col-md-6">
                  {{ $t("payslips.label.public_holidays") }}
                </dt>
                <dd class="col-md-6 employee_info_grey_text">
                  <div
                    v-for="(holiday, index) in payslip.public_holidays"
                    :key="index"
                  >
                    {{ holiday }}
                  </div>
                </dd>
              </dl>
            </template>
            <template :slot="'Attendance'">
              <EmployeeAttendanceTable :attendances="payslip.attendance_data" />
            </template>
            <template :slot="'Overtime'">

              <div v-if="!Object.keys(payslip.overtime_logs).includes('lable')">
              <h6>Overtime Data does not exists</h6>
              </div>
              <div v-else>
                <h6>{{ payslip.overtime_logs.lable }}</h6>
                

                <div v-if="payslip.overtime_logs.detail.length > 0">
                  
                  <div
                    v-for="(overtime_data, index) in payslip.overtime_logs.detail"
                    :key="index"
                  >
                  <br /><br />
                    <div>Overtime Rule: {{ overtime_data.overtime_rule }}</div>
                  <div>Overtime Type: {{ overtime_data.overtime_type }}</div>
                  <br />
                  <div v-if="overtime_data.detail.length > 0">
                    <mdb-tbl responsive hover>
                      <mdb-tbl-head>
                          <tr>
                            <th>Date</th>                        
                        <th>Overtime Minutes</th>
                        <th>From - To</th>      
                        </tr>
                  
                      </mdb-tbl-head>
                      <mdb-tbl-body>

                       
                      <tr v-for="(overtime_detail, index) in overtime_data.detail"
                    :key="index">
                        <td>{{ overtime_detail.date }}</td>                        
                        <td>{{ overtime_detail.total_minutes }}</td>
                        <td>{{ overtime_detail.duration }}</td>
                      </tr>     
                      </mdb-tbl-body>               
                  
                    </mdb-tbl>
                </div>

                  </div>
                </div>
              </div>
                
                

              <!-- {{ payslip.overtime_logs }} -->
            </template>
            <template :slot="'Addition'">
              <div v-if="payslip.additions.length > 0">
                <dl
                  class="row"
                  v-for="(addition, index) in payslip.additions"
                  :key="index"
                >
                  <dt class="col-md-8">
                    {{ addition.description }} ({{ addition.type }})
                  </dt>
                  <dd class="col-md-4 employee_info_grey_text">
                    {{ addition.amount }}
                  </dd>
                </dl>
              </div>
            </template>
            <template :slot="'Deduction'">
              <div v-if="payslip.deductions.length > 0">
                <dl
                  class="row"
                  v-for="(deduction, index) in payslip.deductions"
                  :key="index"
                >
                  <dt class="col-md-8">
                    {{ deduction.description }} ({{ deduction.type }})
                  </dt>
                  <dd class="col-md-4 employee_info_grey_text">
                    {{ deduction.amount }}
                  </dd>
                </dl>
              </div>
            </template>
            <template :slot="'Holiday Rate'" v-if="payslip.wd_holiday_ot_logs!=null">
              
              <div v-if="payslip.wd_holiday_ot_logs.length > 0">
                <dl
                  class="row"
                  v-for="(log, index) in payslip.wd_holiday_ot_logs"
                  :key="index"
                >
                  <dt class="col-md-8">
                    {{ log.date }} 
                  </dt>
                  <dd class="col-md-4 employee_info_grey_text">
                    {{ log.amount }}
                  </dd>
                </dl>
              </div>
            </template>
            <template :slot="'Detail'">
              <!-- {{salary_computing_rule}}
              {{total_salary_computing_rule}}
              {{salaray_computing_other_amount}} -->
              <EmployeePayrollDetailTable
                :salary_computing_rule="payslip.salary_computing_rule"
                :total_salary_computing_rule="
                  payslip.total_salary_computing_rule
                "
                :salaray_computing_other_amount="
                  payslip.salaray_computing_other_amount
                "
                :payroll_date="
                  payslip.date
                "
              />
            </template>
          </mdb-tabs>
        </div>
        <!-- {{payslip}} -->
      </div>
    </template>

    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>

<script>
import Payroll from "@/apis/Payroll";
import { mapActions, mapGetters } from "vuex";
import EmployeeAttendanceTable from "@/components/EmployeeAttendanceTable";
import EmployeePayrollDetailTable from "@/components/EmployeePayrollDetailTable";
import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";

import {
  // mdbCard,
  // mdbCardBody,
  mdbRow,
  mdbCol,
  mdbSpinner,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbTabs,
  mdbBtn,
  mdbIcon,
} from "mdbvue";

export default {
  components: {
    // mdbCard,
    // mdbCardBody,
    mdbRow,
    mdbCol,
    mdbSpinner,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbTabs,
    EmployeeAttendanceTable,
    EmployeePayrollDetailTable,
    EmployeeInfoBoxLayout,
    ModalDeleteConfimation,
    mdbBtn,
    mdbIcon,
  },
  name: "EmployeePayslipDetail",

  data() {
    return {
      payslip: [],
      feedback_error: false,
      feedback_message: "",
      deleteSubmitted: false,

      selectedEmployeeData: null,

      detail_tab:[]
    };
  },
  props: ["employee_id", "_id"],
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    rowClickEvent(id) {
      this.$router.push({
        name: "viewEmployeePayslipDetail",
        params: { employee_id: this.employee_id, _id: id },
      });
    },

    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },

    downloadPayslip() {
      Payroll.downloadPayslip({ payroll_id: this._id })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              //
              window.open ( response.data.file_url, '_blank')
            }
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            this.updateLoading(false);
          }
        });
    },

    fetchRecord() {
      this.updateLoading(true);
      let params = {
        payroll_id: this._id,
      };

      Payroll.getPayslipDetail(params)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.payslip = response.data.data;

              if(this.payslip.wd_holiday_ot_logs){
                this.detail_tab = [
                  { text: 'Summary' },
                  { text: 'Attendance' },
                  { text: 'Overtime' },
                  { text: 'Addition' },
                  { text: 'Deduction' },
                  { text: 'Holiday Rate' },
                  { text: 'Detail' },
                ]
              }else{
                this.detail_tab = [
                  { text: 'Summary' },
                  { text: 'Attendance' },
                  { text: 'Overtime' },
                  { text: 'Addition' },
                  { text: 'Deduction' },
                  { text: 'Detail' },
                ]
              }
              

              if (!this.employee_id) {
                this.selectedEmployeeData = {
                  epinfo_name: response.data.data.employee_info.name,
                  employee_code: response.data.data.employee_info.employee_code,
                  profile_image: response.data.data.employee_info.profile_image,
                  department_name: response.data.data.employee_info.department,
                  jp_name: response.data.data.employee_info.position,
                  rt_name: response.data.data.employee_info.rank,
                };
                //emp info
              }

              this.updateLoading(false);
            } else {
              this.updateLoading(false);

              this.$notify.error({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              this.$router.push({ name: "payrollList" });
            }
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            this.updateLoading(false);
          }
        });
    },

    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this._id > 0) {
        Payroll.deletePayslip({ id: this._id })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({ name: "payrollList" });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    if (!this.employee_id || Math.sign(this.employee_id) !== 1) {
      //employee id does not exists
    } else {
      //employee id exists
      this.$store.state.crumbsParams = {
        employeePayslipList: {
          employee_id: this.employee_id,
        },
      };
    }

    if (!this._id || Math.sign(this._id) !== 1) {
      if (!this.employee_id || Math.sign(this.employee_id) !== 1) {
        this.$router.push({
          name: "employeePayslipList",
          params: { employee_id: this.employee_id },
        });
      } else {
        this.$router.push({
          name: "payrollList",
        });
      }
    } else {
      this.fetchRecord();
    }
  },
};
</script>
