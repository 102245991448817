<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">
        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn color="uno-color" class="btn_submit" @click="createNewRecord" size="sm">
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head >
                <tr>
                  <th >
                    
                    {{ $t("general.formInput.name") }}
                  </th>
                  <th
                   
                    width="120"
                    class="text-right"
                  >
                    
                    {{ $t("general.formInput.created") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(list_item, index) in isos.data"
                  :key="index"
                >
                  <td scope="row" @click="rowClickEvent(list_item)">
                    {{ list_item.iso_name }}
                  </td>
                  <td @click="rowClickEvent(list_item)" class="text-right">
                    {{ list_item.iso_created | formatDateString }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <pagination :data="isos" @pagination-change-page="fetchRecordList" :limit="2"></pagination>
          </mdb-card-body>
        </mdb-card>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
Vue.component('pagination', require('laravel-vue-pagination'))
import ISO from "@/apis/ISO";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },

  data() {
    return {
      isos: [],
      currentSort: "iso_name",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    // sortedRecordList() {
    //   return this.isos.data.slice().sort((a, b) => {
    //     let modifier = 1;
    //     if (this.currentSortDir === "desc") modifier = -1;
    //     if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
    //     if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
    //     return 0;
    //   });
    // },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    // sort: function (s) {
    //   //if s == current sort, reverse
    //   if (s === this.currentSort) {
    //     this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
    //   }
    //   this.currentSort = s;
    // },

    rowClickEvent(item) {
     
        this.$router.push({
          name: "viewISO",
          params: { _id: item.iso_id },
        });
     
    },

    createNewRecord() {
      this.$router.push({ name: "createISO" });
    },

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      ISO.all(page)
        .then((response) => {
          if (response.status === 200) {
            this.isos = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
