<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-4" v-if="training_data.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Training Report</h6>

                <mdb-row>
                  <mdb-col>
                    <div>
                      <mdb-tbl responsive striped bordered sm>
                        <mdb-tbl-head style="background: #96a6bf; color: #fff">
                          <tr>
                            <td>Training Name</td>
                            <td>Type</td>
                            <td>Trainer</td>
                            <td>Start Date</td>
                            <td>End Date</td>
                            <td>Duration (Hr)</td>
                            <td>Duration (Day)</td>

                            <td
                              v-for="(
                                training_department, index2
                              ) in training_departments"
                              :key="index2"
                            >
                              {{ training_department }}
                            </td>

                            <td>No of Trainee</td>
                            <td>Total Manday</td>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr
                            v-for="(item, index) in training_data"
                            :key="index"
                          >
                            <td>{{ item["training_name"] }}</td>
                            <td>{{ item["type"] }}</td>
                            <td>{{ item["trainer"] }}</td>
                            <td>{{ item["start_date"] }}</td>
                            <td>{{ item["end_date"] }}</td>
                            <td>{{ item["duration_hr"] }}</td>
                            <td>{{ item["duration_day"] }}</td>

                            <template v-if="item['departments'].length > 0">
                              <td
                                v-for="(
                                  item_detail, index2
                                ) in data_column_sorter(index)"
                                :key="index2"
                              >
                                {{ item_detail }}
                              </td>
                            </template>

                            <td>{{ item["no_of_trainee"] }}</td>
                            <td>{{ item["total_manday"] }}</td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <!-- ///////////////////////// -->

        <mdb-row
          v-if="
            training_data.length == 0
          "
        >
          <mdb-col>
            <mdb-card>
              <mdb-card-body>
                <div class="d-flex justify-content-center">
                  <h5 style="margin-top: 60px">No Record Found.</h5>
                </div>
                <div class="d-flex justify-content-center">
                  <img src="/empty_item.svg" alt="Empty Record" />
                </div>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>

      </template>
    </div>
  </div>
</template>
<script>
import ManagementReport from "@/apis/ManagementReport";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },
  data() {
    return {
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",

      ////////////////////

      training_departments: [],
      training_data: [],

      /////////////////////
    };
  },
  props: ["year", "month"],
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    data_column_sorter(index) {
      let final_data = [];

      for (let training_department of this.training_departments) {
        let temp_data = false;

        temp_data = this.training_data[index]["departments"].find(
          ({ department }) => department === training_department
        );

        // console.log(temp_data);

        if (temp_data) {
          final_data.push(temp_data.no_of_employee);
        } else {
          final_data.push(0);
        }
      }

      return final_data;

      // return [index, index+1,index+2];
    },

    fetchData() {
      this.updateLoading(true);

      const payload = {
        date: this.month + "-" + this.year,
      };

      // const response = {
      //   status: 200,
      //   data: {
      //     training_departments: ["abc", "def", "ghi", "jkl"],

      //     training_data: [
      //       {
      //         training_name: "lorum ipsuam",
      //         type: "Internal",
      //         trainer: "U Mya",
      //         start_date: "23 April, 2022",
      //         end_date: "23 April, 2022",
      //         duration_hr: 5,
      //         duration_day: 1,
      //         no_of_trainee: 12,
      //         total_manday: 12,
      //         departments: [
      //           { department: "abc", no_of_employee: 2 },
      //           { department: "def", no_of_employee: 3 },
      //           { department: "ghi", no_of_employee: 4 },
      //           { department: "jkl", no_of_employee: 5 },
      //         ],
      //       },
      //       {
      //         training_name: "lorum ipsuam",
      //         type: "Internal",
      //         trainer: "U Mya",
      //         start_date: "23 April, 2022",
      //         end_date: "23 April, 2022",
      //         duration_hr: 5,
      //         duration_day: 1,
      //         no_of_trainee: 12,
      //         total_manday: 12,
      //         departments: [
      //           { department: "abc", no_of_employee: 12 },
      //           { department: "def", no_of_employee: 13 },
      //           { department: "ghi", no_of_employee: 24 },
      //           { department: "jkl", no_of_employee: 35 },
      //         ],
      //       },
      //       {
      //         training_name: "lorum ipsuam",
      //         type: "Internal",
      //         trainer: "U Mya",
      //         start_date: "23 April, 2022",
      //         end_date: "23 April, 2022",
      //         duration_hr: 5,
      //         duration_day: 1,
      //         no_of_trainee: 12,
      //         total_manday: 12,
      //         departments: [
      //           { department: "abc", no_of_employee: 2 },
      //           { department: "jkl", no_of_employee: 5 },
      //         ],
      //       },
      //       {
      //         training_name: "lorum ipsuam",
      //         type: "Internal",
      //         trainer: "U Mya",
      //         start_date: "23 April, 2022",
      //         end_date: "23 April, 2022",
      //         duration_hr: 5,
      //         duration_day: 1,
      //         no_of_trainee: 12,
      //         total_manday: 12,
      //         departments: [{ department: "jkl", no_of_employee: 5 }],
      //       },
      //       {
      //         training_name: "lorum ipsuam",
      //         type: "Internal",
      //         trainer: "U Mya",
      //         start_date: "23 April, 2022",
      //         end_date: "23 April, 2022",
      //         duration_hr: 5,
      //         duration_day: 1,
      //         no_of_trainee: 12,
      //         total_manday: 12,
      //         departments: [
      //           { department: "def", no_of_employee: 13 },
      //           { department: "ghi", no_of_employee: 24 },
      //         ],
      //       },
      //     ],
      //   },
      // };

      // this.training_departments = response.data.training_departments;
      // this.training_data = response.data.training_data;

      ManagementReport.trainingData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.training_departments = response.data.data.training_departments;
            this.training_data = response.data.data.training_data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });
    },

    validateParams() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (
        !months.find((month) => month.toLowerCase() == this.month.toLowerCase())
      ) {
        this.$router.push({ name: "managementReport" });
      }

      if (!/^\d{4}$/.test(this.year)) {
        this.$router.push({ name: "managementReport" });
      }

      this.fetchData();
    },
  },
  created() {
    this.validateParams();
  },
};
</script>

<style scoped>

</style>