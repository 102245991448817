<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <template v-if="!employee_id">
                <mdb-row class="mb-3">
                  <mdb-col offsetMd="2" md="8">
                    <div class="float-left mt-3 sec_title">Select Employee</div>
                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="float-right btn_outline_style"
                      icon="user-friends"
                      @click.native="modal = true"
                    >
                      {{ $t("payrolls.button.select_employee") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>

                <mdb-row v-if="selectedEmployeeData" class="mb-5">
                  <mdb-col offsetMd="2" md="8">
                    <div class="approval_info_box">
                      <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
                    </div>
                  </mdb-col>
                </mdb-row>
              </template>

              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    v-model="overtime_data.ot_date"
                    label="Date"
                    title="Select date"
                  />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-time-picker-2
                    id="timePickerFrom"
                    label="Select your time"
                    v-model="overtime_data.ot_start_from"
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-time-picker-2
                    id="timePickerTo"
                    label="Select your time"
                    v-model="overtime_data.ot_end_at"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    :label="$t('general.formInput.description')"
                    v-model="overtime_data.ot_description"
                    outline
                    required
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>

        <template v-if="this.overtime_data.ot_id != ''">
          <div
            v-if="
              this.overtime_all_data.ot_request_by != 'behalf' &&
              this.overtime_approve_logs_index.length > 0
            "
          >
            <mdb-card>
              <mdb-card-body>
                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <mdb-card-title>Approved By</mdb-card-title>
                    <div
                      v-for="(obj_key, index) in overtime_approve_logs_index"
                      :key="index"
                    >
                      Level {{ obj_key }}
                      <ApprovedUserLayout
                        :users="overtime_approve_logs[obj_key]"
                      />
                      <hr v-show="overtime_approve_logs.length - 1 > index" />
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-card-body>
            </mdb-card>
          </div>

          <div
            v-if="
              this.overtime_all_data.ot_request_by != 'behalf' &&
              this.left_approve_level_to_approve_index.length > 0
            "
          >
            <mdb-card>
              <mdb-card-body>
                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <div class="float-left">
                      <mdb-card-title>Pending Approvals</mdb-card-title>
                    </div>
                    <div class="mb-3 float-right">
                      <mdb-btn
                        class="btn_back"
                        color="uno-color"
                        @click="handleBehalfApproval('cancel')"
                      >
                        Cancel
                      </mdb-btn>
                      <mdb-btn
                        outline="primary"
                        class="btn_outline_style"
                        @click="handleBehalfApproval('level')"
                      >
                        Approve Level
                        {{ left_approve_level_to_approve_index[0] }}
                      </mdb-btn>
                      <mdb-btn
                        color="uno-color"
                        class="btn_submit"
                        @click="handleBehalfApproval('all')"
                      >
                        Approve all
                      </mdb-btn>
                    </div>
                    <div style="clear: both"></div>
                    <div
                      v-for="(
                        obj_key, index
                      ) in left_approve_level_to_approve_index"
                      :key="index"
                    >
                      <mdb-row class="mt-5 mb-4">
                        <mdb-col md="2" col="3"> Level {{ obj_key }} </mdb-col>
                        <mdb-col md="10" col="9">
                          <div
                            style="border-top: 1px solid #dedddd"
                            class="mt-2"
                          ></div>
                        </mdb-col>
                      </mdb-row>

                      <!-- <mdb-row class="mb-3">
                        <mdb-col
                          md="6"
                          v-for="(user, index) in left_approve_level_to_approve[
                            obj_key
                          ]"
                          :key="index"
                          class="info_border_box"
                        >
                          <EmployeeInfoBoxLayout :user="user" />
                        </mdb-col>
                      </mdb-row> -->
                      <mdb-row class="mb-3">
                        <mdb-col
                          md="6"
                          class="mb-3"
                          v-for="(user, index) in left_approve_level_to_approve[
                            obj_key
                          ]"
                          :key="index"
                        >
                          <div class="approval_info_box">
                            <EmployeeInfoBoxLayout :user="user" />
                          </div>
                        </mdb-col>
                      </mdb-row>

                      <hr
                        v-show="
                          left_approve_level_to_approve_index.length - 1 > index
                        "
                      />
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-card-body>
            </mdb-card>
          </div>
        </template>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />

    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapSingleSelectTable
          @updateSelectedEmployee="setSelectedEmployee"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import Overtime from "@/apis/Overtime";
import LogList from "@/components/logs/List";
import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import ApprovedUserLayout from "@/components/ApprovedUserLayout";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";

import EmployeeListBoostrapSingleSelectTable from "@/components/EmployeeListBoostrapSingleSelectTable";

import {
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  mdbTimePicker2,
  mdbInput,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

export default {
  name: "EmployeeAttendanceForm",
  components: {
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbDatePicker2,
    mdbTimePicker2,
    mdbInput,
    ModalDeleteConfimation,
    EmployeeInfoBoxLayout,
    ApprovedUserLayout,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeListBoostrapSingleSelectTable,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      overtime_data: {
        ot_id: "",
        ot_employee_id: this.employee_id ? this.employee_id : "",
        ot_date: "",
        ot_start_from: "",
        ot_end_at: "",
        ot_description: "",
      },
      logs: [],

      overtime_all_data: [],
      overtime_approve_logs: [],
      overtime_approve_logs_index: [],
      // approver_list: [],
      left_approve_level_to_approve: [],
      left_approve_level_to_approve_index: [],

      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",

      selectedEmployeeData: null,
      modal: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.overtime_data.ot_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    setSelectedEmployee(val) {
      this.modal = false;
      this.selectedEmployeeData = val;
      this.overtime_data.ot_employee_id = val.employee_id;
    },
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },

    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          Overtime.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  //  console.log(response.data.data)

                  let ot_id,
                    ot_employee_id,
                    ot_date,
                    ot_start_from,
                    ot_end_at,
                    ot_description;

                  ({
                    ot_id,
                    ot_employee_id,
                    ot_date,
                    ot_start_from,
                    ot_end_at,
                    ot_description,
                  } = response.data.data);

                  if (
                    ot_start_from != "" &&
                    ot_start_from != "NULL" &&
                    ot_start_from != "0000-00-00 00:00:00"
                  ) {
                    const rst = this.parseDateTimeString(ot_start_from);

                    this.overtime_data.ot_start_from = rst.time;
                  }

                  if (
                    ot_end_at != "" &&
                    ot_end_at != "NULL" &&
                    ot_end_at != "0000-00-00 00:00:00"
                  ) {
                    const rst = this.parseDateTimeString(ot_end_at);

                    this.overtime_data.ot_end_at = rst.time;
                  }

                  this.overtime_data.ot_id = ot_id;
                  this.overtime_data.ot_employee_id = ot_employee_id;
                  this.overtime_data.ot_date = ot_date;
                  this.overtime_data.ot_description = ot_description;

                  this.overtime_all_data = response.data.data;

                  // this.approver_list = response.data.approver_list;

                  //preparation
                  this.overtime_approve_logs = {};
                  this.overtime_approve_logs_index = [];

                  if (response.data.overtime_approve_logs.length > 0) {
                    let tmp_data_list = {};

                    for (const tmp_data of response.data
                      .overtime_approve_logs) {
                      const temp_key = tmp_data.opl_level;
                      if (temp_key in tmp_data_list) {
                        tmp_data_list[temp_key].push(tmp_data);
                      } else {
                        this.overtime_approve_logs_index.push(temp_key);
                        tmp_data_list[temp_key] = [];
                        tmp_data_list[temp_key].push(tmp_data);
                      }
                    }
                    this.overtime_approve_logs = tmp_data_list;
                  } else {
                    this.overtime_approve_logs = {};
                  }

                  this.left_approve_level_to_approve = {};
                  this.left_approve_level_to_approve_index = [];
                  if (response.data.left_approve_level_to_approve.length > 0) {
                    let tmp_data_list = {};

                    for (const tmp_data of response.data
                      .left_approve_level_to_approve) {
                      const temp_key = tmp_data.overtime_approval_level;
                      if (temp_key in tmp_data_list) {
                        tmp_data_list[temp_key].push(tmp_data);
                      } else {
                        this.left_approve_level_to_approve_index.push(temp_key);
                        tmp_data_list[temp_key] = [];
                        tmp_data_list[temp_key].push(tmp_data);
                      }
                    }

                    this.left_approve_level_to_approve = tmp_data_list;
                  } else {
                    this.left_approve_level_to_approve = {};
                  }

                  if (!this.employee_id) {
                    this.selectedEmployeeData = {
                      epinfo_name: response.data.data.epinfo_name,
                      employee_code: response.data.data.employee_code,
                      profile_image: response.data.data.profile_image,
                      department_name: response.data.data.department_name,
                      jp_name: response.data.data.jp_name,
                      rt_name: response.data.data.rt_name,
                    };
                    //emp info
                  }

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    employeeOvertimeList: {
                      employee_id: this.overtime_data.ot_employee_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "employeeOvertimeList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "employeeOvertimeList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "employeeOvertimeList" });
        }
      } else {
        // add
      }
    },

    parseDateTimeString(val) {
      const data = new Date(val);
      let month = data.getMonth() + 1;
      let day = data.getDate();
      let year = data.getFullYear();
      if (day <= 9) day = "0" + day;
      if (month < 10) month = "0" + month;

      let hour = data.getHours();
      let minutes = data.getMinutes();
      let ampm = "am";
      if (hour > 12) {
        hour = hour % 12;
        ampm = "pm";
      }

      if (hour <= 9) hour = "0" + hour;
      if (minutes <= 9) minutes = "0" + minutes;
      return {
        date: year + "-" + month + "-" + day,
        time: hour + ":" + minutes + ampm,
      };
    },

    handleBehalfApproval(val) {
      this.formSubmitting = true;
      this.deleteSubmitted = true;

      let status_val = "";
      if (val == "all") {
        status_val = "approve_level_all";
      } else if (val == "level") {
        status_val = "approve_one_level";
      } else {
        status_val = "reject";
      }

      Overtime.behalf_approval({
        ot_id: this.overtime_data.ot_id,
        status: status_val,
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            this.fetchRecord();

            this.formSubmitting = false;
            this.deleteSubmitted = false;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }
        }
      });
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      Overtime.save(this.overtime_data).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            // if (this.overtime_data.ot_id != "") {
            //   this.$notify.success({
            //     message: response.data.message,
            //     position: "top right",
            //     timeOut: 5000,
            //   });
            //   this.$router.push({
            //     name: "employeeOvertimeList",
            //     params: { employee_id: this.overtime_data.ot_employee_id },
            //   });
            // } else {
            //   this.$notify.success({
            //     message: response.data.message,
            //     position: "top right",
            //     timeOut: 5000,
            //   });
            //   this.resetForm();
            // }
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });
            if (this.employee_id) {
              this.$router.push({
                name: "employeeOvertimeList",
                params: { employee_id: this.overtime_data.ot_employee_id },
              });
            } else {
              this.$router.push({ name: "listManageOvertime" });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
    },
    redirectBackAction() {
      // this.$router.push({
      //   name: "employeeOvertimeList",
      //   params: { employeeOvertimeList: this.overtime_data.ot_employee_id },
      // });
      if (this.employee_id) {
        this.$router.push({
          name: "employeeOvertimeList",
          params: { employee_id: this.overtime_data.ot_employee_id },
        });
      } else {
        this.$router.push({ name: "listManageOvertime" });
      }
    },
    resetAlert() {
      this.feedback_error = false;
    },
    // resetForm() {
    //   Object.keys(this.overtime_data).forEach((key) => {
    //     this.overtime_data[key] = "";
    //   });

    //   this.overtime_data.ot_employee_id = this.employee_id;
    // },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.overtime_data.ot_id > 0) {
        Overtime.delete({
          ot_id: this.overtime_data.ot_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              // this.$router.push({
              //   name: "employeeOvertimeList",
              //   params: {
              //     _id: this.overtime_data.ot_employee_id,
              //   },
              // });
              if (this.employee_id) {
              this.$router.push({
                name: "employeeOvertimeList",
                params: { employee_id: this.overtime_data.ot_employee_id },
              });
            } else {
              this.$router.push({ name: "listManageOvertime" });
            }

            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      employeeOvertimeList: {
        employee_id: this.overtime_data.ot_employee_id,
      },
    };

    this.fetchRecord();
  },
};
</script>