<template>
  <div>
    <img
      :src="user.profile_image"
      class="rounded-circle float-left"
      width="50"
      height="50"
      v-if="user.profile_image"
      @error="imageLoadError"
    />

    
        <mdb-btn
         v-if="showDelete"
          class="float-right"
          tag="a"
          gradient="peach"
          floating
          size="sm"
          @click="deleteEmployee"
          ><mdb-icon icon="trash-alt"
        /></mdb-btn>

    <div class="float-left ml-3 mb-3">
      <div class="employee_name">
        {{ user.epinfo_name | cutStringTableCell }}
      </div>
      <div class="employee_info_text_sec">
        {{ user.jp_name | cutStringTableCell }}
      </div>
      <div class="employee_info_text">
        {{ user.department_name | cutStringTableCell }}
      </div>
      
    </div>
  </div>
</template>

<script>
import { mdbBtn, mdbIcon } from "mdbvue";
export default {
  name: "EmployeeInfoBoxLayout",
  components: { mdbBtn, mdbIcon },
  props: ["user", "showDelete"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    imageLoadError(event) {
      event.target.src = "/default-user.png";
    },

    deleteEmployee() {
      this.$emit("deleteEmployee");
    },
  },
};
</script>
<style >
.approval_info_box {
  padding-left: 10px !important;
  margin-bottom: 5px;
}
</style>
