<template>
  <div>
    <mdb-select
      outline
      multiple
      selectAll
      @getValue="updateSelectedValue"
      :options="axiosOptions"
      :label="selectLabel"
      :disabled="loading"
    />
  </div>
</template>

<script>
import { mdbSelect } from "mdbvue";
import Rank from "@/apis/Rank";
export default {
  name: "RankMultiSelectDropdown",
  components: {
    mdbSelect,
  },
  props: ["default_selected_value", "resetSelected"],
  data() {
    return {
      axiosOptions: [],
      loading: true,
      selectLabel: "Loading Rank List",
    };
  },
  mounted() {
    this.fetchRankList(false);
  },
  methods: {
    updateSelectedValue(val) {
      if (val !== null) {
        this.$emit("updateSelectedRankIDs", val);
      }
    },
    fetchRankList(doesFormReset) {
      Rank.all().then((response) => {
        if (response.status === 200) {
          const ranks = response.data.data;
          this.axiosOptions = [];
// console.log(this.default_selected_value)
          for (let rank of ranks) {
            if (doesFormReset===false && this.default_selected_value.length > 0) {
              if (this.default_selected_value.includes(rank.rt_id)) {
                this.axiosOptions.push({
                  text: rank.rt_name,
                  value: rank.rt_id,
                  selected: true,
                });
              } else {
                this.axiosOptions.push({
                  text: rank.rt_name,
                  value: rank.rt_id,
                });
              }
            } else {
              this.axiosOptions.push({
                text: rank.rt_name,
                value: rank.rt_id,
              });
            }
          }
          this.loading = false;
          this.selectLabel = "Select rank";
        }
      });
    },
  },

  watch: {
    resetSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchRankList(true);
      }
    },
  },
};
</script>
