import Api from './api'

export default{
    all(){
            return Api.get('api/rule_policy/allowance_rule/list')
    },

    save(payload){
            return Api.post('api/rule_policy/allowance_rule/data_entry', payload)        
    },
    
    delete(payload){
        return Api.post('api/rule_policy/allowance_rule/delete', payload)        
    },
    
    view(payload){
        return Api.get('api/rule_policy/allowance_rule/' + payload)        
    },
    
}