import { render, staticRenderFns } from "./EmployeeAllowancePolicyList.vue?vue&type=template&id=1ab67e47&"
import script from "./EmployeeAllowancePolicyList.vue?vue&type=script&lang=js&"
export * from "./EmployeeAllowancePolicyList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports