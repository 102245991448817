<template>
  <div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div class="dataTables_wrapper" v-show="!isLoading">
      <mdb-row>
        <mdb-col md="3">
          <mdb-input
            type="text"
            :label="$t('general.formInput.name')"
            v-model="filter_form.epinfo_name"
            outline
          />
        </mdb-col>
        <mdb-col md="3">
          <mdb-input
            type="text"
            :label="$t('employees.formInput.employee_id')"
            v-model="filter_form.employee_code"
            outline
          />
        </mdb-col>
        <mdb-col md="3">
          <DepartmentTreeDropdown
            @updateDepartmentID="setDepartmentID"
            :required="false"
          />
        </mdb-col>

        <mdb-col md="3">
          <PositionDropdown
            @updatePositionID="setPositionID"
            :required="false"
          />
        </mdb-col>
      </mdb-row>

      <div class="list-group">
        <mdb-tbl responsive hover>
          <!-- Table head -->
          <mdb-tbl-head>
            <tr>
              <th>
                <!-- Material unchecked -->
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="trmc_all"
                    @change="checkAllEmployees"
                    v-model="isCheckAll"
                  />
                  <label class="form-check-label" for="trmc_all"></label>
                </div>
              </th>

              <th @click="sort('epinfo_name')">
                <i
                  v-show="currentSort === 'epinfo_name'"
                  :class="[
                    currentSortDir === 'asc'
                      ? 'fas fa-arrow-up '
                      : 'fas fa-arrow-down ',
                  ]"
                  aria-hidden="true"
                ></i>
                {{ $t("general.formInput.name") }}
              </th>
              <th @click="sort('employee_code')">
                <i
                  v-show="currentSort === 'employee_code'"
                  :class="[
                    currentSortDir === 'asc'
                      ? 'fas fa-arrow-up '
                      : 'fas fa-arrow-down ',
                  ]"
                  aria-hidden="true"
                ></i>
                {{ $t("employees.formInput.employee_id") }}
              </th>

              <th @click="sort('department_name')">
                <i
                  v-show="currentSort === 'department_name'"
                  :class="[
                    currentSortDir === 'asc'
                      ? 'fas fa-arrow-up '
                      : 'fas fa-arrow-down ',
                  ]"
                  aria-hidden="true"
                ></i>
                {{ $t("general.formInput.department_name") }}
              </th>

              <th @click="sort('jp_name')">
                <i
                  v-show="currentSort === 'jp_name'"
                  :class="[
                    currentSortDir === 'asc'
                      ? 'fas fa-arrow-up '
                      : 'fas fa-arrow-down ',
                  ]"
                  aria-hidden="true"
                ></i>
                {{ $t("general.formInput.jp_name") }}
              </th>
            </tr>
          </mdb-tbl-head>
          <!-- Table head -->

          <!-- Table body -->
          <mdb-tbl-body>
            <tr v-for="(employee, index) in pageOfItems" :key="index">
              <th scope="row">
                <!-- Material unchecked -->
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    :id="'trmc_' + employee.employee_id"
                    :value="employee"
                    v-model="selectedEmployees"
                    @change="updateCheckall"
                  />
                  <label
                    class="form-check-label"
                    :for="'trmc_' + employee.employee_id"
                  ></label>
                </div>
              </th>
              <td @click="rowClickEvent(employee)">
                {{ employee.epinfo_name }}
              </td>
              <td @click="rowClickEvent(employee)">
                {{ employee.employee_code }}
              </td>
              <td @click="rowClickEvent(employee)">
                {{ employee.department_name }}
              </td>
              <td @click="rowClickEvent(employee)">
                {{ employee.jp_name }}
              </td>
            </tr>
          </mdb-tbl-body>
          <!-- Table body -->
        </mdb-tbl>
        <div class="card-footer pb-0 pt-3">
          <jw-pagination
            :items="filteredRecord"
            @changePage="onChangePage"
            :pageSize="rowsPerPage"
          ></jw-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import JwPagination from "jw-vue-pagination";
Vue.component("jw-pagination", JwPagination);

import Employee from "@/apis/Employee";
import {
  mdbSpinner,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbInput,
  mdbRow,
  mdbCol,
} from "mdbvue";
import DepartmentTreeDropdown from "@/components/DepartmentTreeDropdown";
import PositionDropdown from "@/components/PositionDropdown";
export default {
  name: "EmployeeListBoostrapCheckboxTable",
  components: {
    mdbSpinner,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbRow,
    mdbCol,
    mdbInput,
    DepartmentTreeDropdown,
    PositionDropdown,
  },
  props: ["default_selected_value", "source_params"],
  data() {
    return {
      pageOfItems: [],
      rowsPerPage : this.$store.state.rowsPerPage,
      currentSort: "holiday_name",
      currentSortDir: "asc",
      loading: true,
      employees: [],
      selectedEmployees: [],
      isCheckAll: false,
      filter_form: {
        employee_code: "",
        epinfo_name: "",
        employee_department: "",
        employee_position: "",
      },
    };
  },
  mounted() {
    this.loading = true;
    Employee.activeUsers().then((response) => {
      if (response.status === 200) {

        this.employees = response.data.data;

        this.selectedEmployees = this.employees.filter((item) => {
          
            if(this.default_selected_value.includes(item.employee_id)){
              return item
            }
        });

        this.loading = false;
      }
    });
  },
  computed: {
    isLoading() {
      return this.loading;
    },

    filteredRecord() {
      let tempRecords = this.employees;
      if (this.filter_form.epinfo_name != "" && this.filter_form.epinfo_name) {
        tempRecords = tempRecords.filter((item) => {
          return item.epinfo_name
            .toUpperCase()
            .includes(this.filter_form.epinfo_name.toUpperCase());
        });
      }

      if (
        this.filter_form.employee_code != "" &&
        this.filter_form.employee_code
      ) {
        tempRecords = tempRecords.filter((item) => {
          return item.employee_code
            .toUpperCase()
            .includes(this.filter_form.employee_code.toUpperCase());
        });
      }

      if (
        this.filter_form.employee_department != "" &&
        this.filter_form.employee_department
      ) {
        tempRecords = tempRecords.filter((item) => {
          return (
            item.employee_department == this.filter_form.employee_department
          );
        });
      }

      if (
        this.filter_form.employee_position != "" &&
        this.filter_form.employee_position
      ) {
        tempRecords = tempRecords.filter((item) => {
          return item.employee_position == this.filter_form.employee_position;
        });
      }

      return tempRecords.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },
  methods: {
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    rowClickEvent(val) {
      // console.log(val)
      const found = this.selectedEmployees.find(function (e) {
        if (e.employee_id == val.employee_id) return true;
      });

      if (found == undefined) {
        this.selectedEmployees.push(val);
      } else {
        const index = this.selectedEmployees.indexOf(val);
        if (index > -1) {
          this.selectedEmployees.splice(index, 1);
        }
      }
      this.updateCheckall();
    },
    checkAllEmployees() {
      this.selectedEmployees = [];
      if (this.isCheckAll) {
        for (let employee of this.filteredRecord) {
          this.selectedEmployees.push(employee);
        }
      }
    },
    updateCheckall() {
      // console.log(this.employees)
      if (this.employees.length == this.selectedEmployees.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    emitNewSelectedVals(val) {
      // console.log('emit')
      if (this.source_params) {
        this.$emit("updateSelectedEmployeeIDs", [val, this.source_params]);
      } else {
        this.$emit("updateSelectedEmployeeIDs", val);
      }
    },
    setDepartmentID(val) {
      this.filter_form.employee_department = val;
    },
    setPositionID(val) {
      this.filter_form.employee_position = val;
    },
  },

  watch: {
    selectedEmployees(newValue, oldValue) {
      // console.log(oldValue)
      // console.log(newValue)
      if (newValue !== oldValue) {
        this.emitNewSelectedVals(newValue);
      }
      // this.emitNewSelectedVals(newValue)
    },
  },
};
</script>