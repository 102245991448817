

export default {
    
    updateLoading ({commit}, payload) {
        commit('UPDATE_LOADING', payload)
    },
    swithLangAction({commit}, newLocale){
        localStorage.setItem('locale', newLocale) 
        commit('UPDATE_LANGUAGE', newLocale)
      },
}