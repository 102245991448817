import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import store from '@/store';

Vue.use(Router);

// configure router
const router = new Router({
  routes, // short for routes: routes
  mode:'history',
  linkActiveClass: 'activeLink',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {

  if (to.name != from.name){
  store.state.pageTitle = to.meta.title
  store.state.crumbs = to.meta.crumbs
  store.state.crumbsParams = to.meta.crumbsParams
}
  next()
})

export default router;
