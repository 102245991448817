<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <mdb-card>
        <mdb-card-header>
          <div class="float-left"></div>
          <div class="float-right">
            <mdb-btn color="uno-color" class="btn_submit" @click="createNewRecord" size="sm">
              <mdb-icon far icon="plus-square" size="md" />
              {{ $t("general.button.create") }}
            </mdb-btn>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <mdb-tbl responsive hover>
            <!-- Table head -->
            <mdb-tbl-head>
              <tr>
                <th @click="sort('ht_name')">
                  <i
                    v-show="currentSort === 'ht_name'"
                    :class="[
                      currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down',
                    ]"
                    aria-hidden="true"
                  ></i>
                  {{ $t("general.formInput.name") }}
                </th>
                <th width="120" class="text-right" @click="sort('ht_created')">
                  <i
                    v-show="currentSort === 'ht_created'"
                    :class="[
                      currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ',
                    ]"
                    aria-hidden="true"
                  ></i>
                  {{ $t("general.formInput.created") }}
                </th>
              </tr>
            </mdb-tbl-head>

            <!-- Table body -->
            <mdb-tbl-body>
              <tr  scope="row"
                v-for="(document_type, index) in sortedDocumentTypeList"
                :key="index"
              >
                <td  scope="row" @click="rowClickEvent(document_type.ht_id)">
                  {{ document_type.ht_name }}
                </td>
                <td
                  class="text-right"
                  @click="rowClickEvent(document_type.ht_created)"
                >
                  {{ document_type.ht_created | formatDateString }}
                </td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>
        </mdb-card-body>
      </mdb-card>

      <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
    </div>
  </div>
</template>

<script>
import TransactionType from "@/apis/TransactionType";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },

  data() {
    return {
      transaction_types: [],
      currentSort: "ht_name",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    sortedDocumentTypeList() {
      return this.transaction_types.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    rowClickEvent(id) {
      this.$router.push({ name: "viewTransactionType", params: { _id: id } });
    },

    createNewRecord() {
      this.$router.push({ name: "createTransactionType" });
    },

    fetchRecordList(payload = "") {
      this.updateLoading(true);

      TransactionType.all(payload)
        .then((response) => {
          if (response.status === 200) {
            this.transaction_types = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
