export default {
    SAVE_LOGIN(state, response){
        state.user_permission = response.permission_key
        state.user_permission_rank = response.permisson_rank_salary
        state.token = response.token
        state.is_admin = response.is_admin,
        
        state.organization = response.org_key
    }
    
}