<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>
      <div v-if="!isLoading">
        <dl class="row">
          <dt class="col-sm-3">Title</dt>
          <dd class="col-sm-9">{{ announcement.title }}</dd>

          <dt class="col-sm-3">Description</dt>
          <dd class="col-sm-9">{{ announcement.description }}</dd>

          <dt class="col-sm-3">Rank / Grade</dt>
          <dd class="col-sm-9">{{ announcement.grades }}</dd>

          <dt class="col-sm-3">Attachments</dt>
          <dd class="col-sm-9">
            <div v-if="announcement.attachments.length > 0">
              <div
                v-for="(attachment_item, index) in announcement.attachments"
                :key="index"
              >
                <div class="float-left attachments_text_style">
                  <a
                    :href="attachment_item.attachment_file_name"
                    target="_blank"
                  >
                    <img
                      v-if="
                        attachment_item.file_ext == 'png' ||
                        attachment_item.file_ext == 'gif' ||
                        attachment_item.file_ext == 'jpg' ||
                        attachment_item.file_ext == 'jpeg'
                      "
                      :src="attachment_item.attachment_file_name"
                      height="100"
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else-if="
                        attachment_item.file_ext == 'doc' ||
                        attachment_item.file_ext == 'docx'
                      "
                      src="/doc.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else-if="
                        attachment_item.file_ext == 'xls' ||
                        attachment_item.file_ext == 'xlsx'
                      "
                      src="/spreadsheet.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else-if="
                        attachment_item.file_ext == 'ppt' ||
                        attachment_item.file_ext == 'ppt'
                      "
                      src="/powerpoint.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else-if="
                        attachment_item.file_ext == 'pdf'
                      "
                      src="/pdf.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else
                      src="/unknowfile.png"
                      height="100"
                      class="announcement_thumbnail_image"
                    />
                    <br />
                    {{ attachment_item.attachment_original_file_name | filename_str_limit}}
                  </a>
                </div>
              </div>
            </div>
          </dd>
        </dl>
        <!-- {{ announcement }} -->

        <div class="text-right">
         <mdb-btn type="button" color="primary" class="btn_outline_style" icon="edit" @click="redirectUpdateForm">

          {{ $t("general.button.edit") }}
        </mdb-btn>
        </div>

        <mdb-card>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th @click="sort('department_name')">
                    <i
                      v-show="currentSort === 'department_name'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("general.formInput.name") }}
                  </th>
                  <th width="120" class="text-right">Total</th>
                  <th width="120" class="text-right">Read</th>
                  <th width="120" class="text-right">Unread</th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  v-for="(list_item, index) in sortedRecordList"
                  :key="index"
                  scope="row"
                >
                  <template v-if="list_item.employee_count.total > 0">
                    <td
                      scope="row"
                      @click="rowClickEvent(list_item.department_id)"
                    >
                      {{ list_item.department_name }}
                    </td>
                    <td
                      @click="rowClickEvent(list_item.department_id)"
                      class="text-right"
                    >
                      {{ list_item.employee_count.total }}
                    </td>
                    <td
                      @click="rowClickEvent(list_item.department_id)"
                      class="text-right"
                    >
                      {{ list_item.employee_count.read }}
                    </td>
                    <td
                      @click="rowClickEvent(list_item.department_id)"
                      class="text-right"
                    >
                      {{ list_item.employee_count.unread }}
                    </td>
                  </template>

                  <template v-else>
                    <td scope="row">
                      {{ list_item.department_name }}
                    </td>
                    <td class="text-right">
                      {{ list_item.employee_count.total }}
                    </td>
                    <td class="text-right">
                      {{ list_item.employee_count.read }}
                    </td>
                    <td class="text-right">
                      {{ list_item.employee_count.unread }}
                    </td>
                  </template>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </mdb-card-body>
        </mdb-card>
      </div>
    </template>
  </div>
</template>

<script>
import Announcement from "@/apis/Announcement";
import { mapActions, mapGetters } from "vuex";

import {
  // mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  // mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbBtn,
} from "mdbvue";

export default {
  components: {
    // mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    // mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbBtn,
  },
  props: ["_id"],
  data() {
    return {
      announcement: {},
      department_list: [],
      currentSort: "department_name",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    sortedRecordList() {
      return this.department_list.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    redirectUpdateForm() {
      this.$router.push({
        name: "viewAnnouncement",
        params: { _id: this._id },
      });
    },

    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    rowClickEvent(id) {
      this.$router.push({
        name: "viewAnnouncementEmployees",
        params: { _id: this._id, department_id: id },
      });
    },

    fetchRecordList() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          Announcement.viewSummary(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.data.type == "department") {
                  this.announcement = response.data.data;
                  this.department_list = response.data.detail;
                  this.updateLoading(false);
                } else {
                  this.$router.push({
                    name: "viewAnnouncementEmployeeSummary",
                    params: { _id: this._id },
                  });
                }
              }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.feedback_message = "Could not connected to server";
                this.feedback_error = true;

                this.updateLoading(false);
              } else {
                console.log(error);
                this.updateLoading(false);
              }
            });
        } else {
          this.$router.push({ name: "listAnnouncement" });
        }
      } else {
        this.$router.push({ name: "listAnnouncement" });
      }
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
<style scoped>
.announcement_thumbnail_image {
  border: 2px solid #c0c0c0;
  padding: 5px;
  margin-top: 10px;
  max-width: 150px;
  max-height: 100px;
}
.attachments_text_style{
  text-align: center;
  margin-right: 20px;
}
</style>