<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>
    <div v-if="!isLoading">
      <template v-if="show_reset_detail === false">
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-select outline v-model.trim="yearsOptions" :label="$t('holidays.formInput.select_year')"
                    placeholder="" v-on:getValue="getSelectValue" />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-btn type="Submit" color="uno-color" class="btn_submit" :disabled="formSubmitting" size="sm"
                    style="margin-top: 1.4rem">
                    <mdb-icon icon="file-upload" size="md" v-show="!formSubmitting" />

                    <span class="spinner-border-vcenter spinner-border-sm" role="status" aria-hidden="true"
                      v-show="formSubmitting"></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>

        <h6>{{ year }}</h6>

        <mdb-row v-if="year != '' && reports.length == 0">
          <mdb-col>
            <mdb-card>
              <mdb-card-body>
                <div class="d-flex justify-content-center">
                  <h5 style="margin-top: 60px">
                    There is no report for year {{ year }}.
                  </h5>
                </div>
                <div class="d-flex justify-content-center">
                  <img src="/empty_item.svg" alt="Empty Record" />
                </div>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>

        <mdb-row v-if="reports.length > 0">
          <mdb-col col="4" v-for="(item, index) in reports" :key="index">
            <mdb-card class="mb-4">
              <!-- <router-link
                    :to="{
                      name: 'managementSummaryReport',
                      params: { year: item['year'], month: item['month'] },
                    }"> -->
              <mdb-card-body>
                <!-- <a
                  class="activator mr-4"
                  :href="item['link']"
                  style="font-size: 0.8rem; padding-top: 30px"
                  target="_blank"
                  ><mdb-icon icon="download" /> Download</a
                > -->

                <template v-if="item['reset'] == 0">
                <mdb-card-title style="font-size: 1.1rem" class="">
                  <router-link :to="{
                    name: 'managementSummaryReport',
                    params: { year: item['year'], month: item['month'] },
                  }" style="color: #000">
                    {{ item["month"] }}
                  </router-link>
                </mdb-card-title>

                <h6 sub class="mb-2" style="font-size: 0.8rem">
                  <router-link :to="{
                    name: 'managementSummaryReport',
                    params: { year: item['year'], month: item['month'] },
                  }" class="text-muted">
                    {{ item["year"] }}
                  </router-link>
                </h6>

                
                  <mdb-btn type="button" color="uno-color" class="btn_submit" size="sm" style="margin-top: 1.4rem"
                     v-on:click="resetManagementReport(item['month'], item['year'], index)">
                    <mdb-icon icon="file-upload" size="md" />
                    Reset
                  </mdb-btn>

                  <a v-if="item['pdf_url'] != ''" :href="item['pdf_url']" target="_blank">
                    <mdb-btn type="button" color="uno-color" class="btn_submit" size="sm" style="margin-top: 1.4rem">
                      <mdb-icon icon="file-upload" size="md" />
                      Download
                    </mdb-btn>
                  </a>
                </template>
                <template v-if="item['reset'] >  0">


                  <mdb-card-title style="font-size: 1.1rem" class="">
                  <a style="color: #000" v-on:click="showResetDetail(item)">
                    {{ item["month"] }}
                  </a>
                </mdb-card-title>

                <h6 sub class="mb-2" style="font-size: 0.8rem">
                  <a class="text-muted"  v-on:click="showResetDetail(item)">
                    {{ item["year"] }}
                  </a>
                </h6>


                  <h6 sub class="mb-2 text-muted" style="font-size: 0.8rem; padding-top:3px; padding-bottom:6px; margin-bottom:0px !important;text-align: right; ">{{ item['complete_report'] }} / {{ item['total_report'] }}</h6>

                  <div style="color: #000 !important;  background-color: #F0F0F2 !important;">
                  <div style="color: #000!important;   background-color: #425CC7 !important; height:6px;" :style="{width: (item['complete_report'] / item['total_report'] * 100) + '%'}"></div>
                </div>

                  <h6 sub class="mb-2" style="font-size: 0.8rem;padding:9px 0px 0px">Report is generating. Please check back soon.</h6>
                </template>

              </mdb-card-body>
              <!-- </router-link> -->
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </template>


      <template v-if="show_reset_detail === true">
        <mdb-card >
          <mdb-card-title style="font-size: 1.1rem" class="">
             <h6 style="padding:1.25rem 1.25rem 0 1.25rem">Management Report Status for {{ getCrrentItem.month }} {{ getCrrentItem.year }}
              <mdb-btn type="button" color="uno-color" class="btn_submit" size="sm" style="float:right;margin:0;" v-on:click="hideResetDetail">Back
              </mdb-btn></h6>
             
            </mdb-card-title>
            
              <mdb-card-body >
        <mdb-row class="no-gutters" >
        <mdb-col col="6" v-for="(item, index) in getCrrentItem.detail" :key="index" :style="index %2 == 0 ? 'padding-right:1rem' :''">
            <mdb-card class="mb-4" style=" margin-bottom: 0.9rem !important;">
              <mdb-card-body :class="item.complete == 1 ? 'complete' : 'pending' " style="border-radius: 10px;">
                <div style="float:right;margin-top:15px;">
                  
                  <mdb-icon far icon="check-circle"  v-if="item.complete == 1"/>
                  <mdb-icon far icon="times-circle" v-if="item.complete == 0" />
                
                </div>
                <h6 sub class="mb-2" style="font-size: 1rem;font-weight: bold;">
                  {{ item.title }}
                </h6>

                <h6 sub class="mb-2" style="font-size: 0.8rem;color:#253858;">
                  {{ item.complete == 1 ? 'Complete' : 'Pending' }}
                </h6>
                
               
                
                
                </mdb-card-body>
            </mdb-card>
            </mdb-col>

          </mdb-row>
        
        </mdb-card-body>
            </mdb-card>
      </template>
    </div>
  </div>
</template>
<script>
import ManagementReport from "@/apis/ManagementReport";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbSelect,
  mdbCardTitle,
} from "mdbvue";

const yearOption = (current, year) => {
  let years = [];
  for (let current_year = current; current_year >= 2022; --current_year) {
    if (year == current_year)
      years.push({ text: current_year, value: current_year, selected: true });
    else years.push({ text: current_year, value: current_year });
  }
  return years;
};

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    mdbSelect,

    mdbCardTitle,
  },
  data() {
    return {
      yearsOptions: null,
      filter_year: "",
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",

      show_reset_detail: false,
      detail_item:{},

      year: "",
      reports: [],
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    getCrrentItem(){
      return this.detail_item;
    }
  },

  methods: {
    ...mapActions(["updateLoading"]),

    getSelectValue(value) {
      this.filter_year = value;
    },

    showResetDetail(item){
      this.show_reset_detail = true;
      this.detail_item  = item;
    },
    hideResetDetail(){
      this.show_reset_detail = false;
      this.detail_item  = {};
    },

    resetManagementReport(month, year, index) {
      if (month != "" && year != "") {

        const payload = {
          date: month + "-" + this.filter_year,
        };

        ManagementReport.resetManagementReport(payload).then((response) => {
          if (response.status === 200) {

            this.reports[index]['reset'] = 1;
            this.reports[index]['total_report'] =  parseInt(response.data.total_report);
            this.reports[index]['complete_report'] = 0;
            console.log('reset success');
            // this.$router.push({ name: "managementReport" });

          }
        });
      }
    },

    handleFormSubmit() {
      if (this.filter_year != "") {
        this.resetAlert();
        this.formSubmitting = true;

        const payload = {
          year: this.filter_year,
        };

        // const response = {
        //   status: 200,
        //   data: {
        //     year: 2022,
        //     reports: [
        //       { month: "January", year: 2022, link: "https://google.com" },
        //       { month: "February", year: 2022, link: "https://google.com" },
        //       { month: "March", year: 2022, link: "https://google.com" },
        //     ],
        //   },
        // };

        // this.year = response.data.year;
        // this.reports = response.data.reports;

        ManagementReport.reportList(payload).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.year = response.data.data.year;
              this.reports = response.data.data.reports;
            } else {
              this.$router.push({ name: "managementReport" });
            }
            this.updateLoading(false);
            this.formSubmitting = false;
          }
        });
      } else {
        this.feedback_message = "Select Year";
        this.feedback_error = true;
      }
    },

    resetAlert() {
      this.feedback_error = false;
    },
  },
  created() {
    this.yearsOptions = yearOption(parseInt(new Date().getFullYear()), parseInt(new Date().getFullYear()));

    this.filter_year = parseInt(new Date().getFullYear());
    this.handleFormSubmit()
  },
};
</script>

<style scoped="local">
.complete{
  background-color: #F3F8FF;
  color:#425CC7;

}

.pending{
  background-color: #F5F5F9;
  color:#253858;
}

.card {
    box-shadow: 0;
}


</style>