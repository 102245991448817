<template>
  <div>
    <mdb-select
      outline
      v-model="axiosOptions"
      :label="label"
      @getValue="updateSelectedValue"
    />
  </div>
</template>

<script>
import Leaves from "@/apis/Leaves";
import { mdbSelect } from "mdbvue";
export default {
  name: "LeaveTypeAllocationDropdown",
  components: {
    mdbSelect,
  },
  props: ["default_selected_value", "required", "employee_id"],
  data() {
    return {
      label:"Loading Leave Type",
      axiosOptions: [],
    };
  },

  methods: {
    updateSelectedValue(val) {
      if (val !== null) {
        this.$emit("updateLeaveTypeID", val);
      }
    },
    fetchLeaveAllocation() {
      Leaves.getAllocations(this.employee_id).then((response) => {
        if (response.status === 200) {

          if (this.required === false) {
            this.axiosOptions.push({
              text: "All",
              value: "",
            });
          }

          for (let leaveType of response.data) {
            let leavetypeitem = {};

            leavetypeitem = {
              text: leaveType.leave_type_name,
              value: leaveType.leave_type_id,
              selected: false,
            };

            if (this.default_selected_value == leaveType.leave_type_id) {
              leavetypeitem.selected = true;
            }

            if (
              leaveType.leave_type_code == "EL" ||
              leaveType.lpd_allocation_days > 0
            ) {
              if (leaveType.max_allocation_days > 0) {
                leavetypeitem.text =
                  leavetypeitem.text +
                  " - " +
                  leaveType.max_allocation_days +
                  " day(s)  available";
              }
              //  else {
              //   leavetypeitem.text = leavetypeitem.text + " is not avaliable";
              // }
            }
            // console.log(leavetypeitem)

            this.axiosOptions.push(leavetypeitem);
          }

          this.label="Select Leave Type"
        }
      });
    },
  },
  created() {
    if (this.employee_id && this.employee_id != "") {
      this.fetchLeaveAllocation()
    }else{
      this.label="Select Employee first to Choose Leave Type"
    }
  },
};
</script>
