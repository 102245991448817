import Api from './api'

export default{
    all(payload){
        // return Api.get('api/settings/public_holidays/list', {params:payload})
        if(payload!=""){
            return Api.get('api/settings/public_holidays/list/' + payload)
        }else{
            return Api.get('api/settings/public_holidays/list')
        }
    },

    save(payload){
            return Api.post('api/settings/public_holidays/data_entry', payload)        
    },

    
    delete(payload){
        return Api.post('api/settings/public_holidays/delete', payload)        
    },

    delete_multiple(payload){
        return Api.post('api/settings/public_holidays/multiple_delete', payload)        
    },
    
    view(payload){
        return Api.get('api/settings/public_holidays/' + payload)        
    },

    


    
}