<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Summary Report</h6>
                <!-- for {{ month }} {{ year }} -->

                <div v-if="summary_data.length > 0">
                  <mdb-tbl responsive striped bordered sm>
                    <mdb-tbl-head style="background: #96a6bf; color: #fff">
                      <tr>
                        <td>Performance Area</td>
                        <td class="text-end">Total</td>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body>
                      <tr
                        v-for="(summary_item, index) in summary_data"
                        :key="index"
                      >
                        <td scope="row">{{ summary_item.label }}</td>
                        <td class="text-end">{{ summary_item.value | formatNumbers}}</td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </div>

                <div v-if="year != '' && summary_data.length == 0">
                  <div class="d-flex justify-content-center">
                    <h5 style="margin-top: 60px">No Record Found.</h5>
                  </div>
                  <div class="d-flex justify-content-center">
                    <img src="/empty_item.svg" alt="Empty Record" />
                  </div>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
  </div>
</template>
<script>
import ManagementReport from "@/apis/ManagementReport";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },
  data() {
    return {
      feedback_error: false,
      feedback_message: "",

      summary_data: [],
    };
  },
  props: ["year", "month"],
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    fetchData() {
      this.updateLoading(true);

      const payload = {
        date: this.month + "-" + this.year,
      };

      // const response = {
      //   status: 200,
      //   data: {
      //     code: 200,
      //     summary_data: [
      //       { label: "aa", value: 123 },
      //       { label: "bb", value: 234 },
      //       { label: "cc", value: 345 },
      //     ],
      //   },
      // };

      // this.summary_data = response.data.summary_data;

      ManagementReport.summaryReport(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.summary_data = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        } else {
          this.$router.push({ name: "managementReport" });
        }
      });
    },

    validateParams() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const month = months.find(
        (month) => month.toLowerCase() == this.month.toLowerCase()
      );
      if (!month) {
        this.$router.push({ name: "managementReport" });
      } else {
        this.month = month;
      }

      if (!/^\d{4}$/.test(this.year)) {
        this.$router.push({ name: "managementReport" });
      }

      this.fetchData();
    },
  },
  created() {
    this.validateParams();
  },
};
</script>

<style scoped>
/* thead tr:first-child td:first-child { border-top-left-radius: 10px; border-top:0; }
thead tr:first-child td:last-child { border-top-right-radius: 10px; border-top:0; } */
td {
  padding-left: 10px;
}

.text-end{
  text-align:right;
  padding-right:10px;
}
</style>