import User from '../../../apis/User'

export default {


    loginAction({ commit }, payload) {

        return new Promise((resolve, reject) => {
            User.login(payload)
                .then(response => {

                    if (response.status === 200) {

                        if (response.data.is_admin === "1") {

                            localStorage.setItem('organization', response.data.org_key)
                            localStorage.setItem('token', response.data.token)
                            localStorage.setItem('is_admin', response.data.is_admin)
                            localStorage.setItem('user_permission', JSON.stringify(response.data.permission_key))
                            localStorage.setItem('user_permission_rank', JSON.stringify(response.data.permisson_rank_salary))
                            commit('SAVE_LOGIN', response.data)
                            resolve(true)

                        } else {

                            if (response.data.permission_key && response.data.permission_key.length > 0) {
                                localStorage.setItem('organization', response.data.org_key)
                                localStorage.setItem('token', response.data.token)
                                localStorage.setItem('is_admin', response.data.is_admin)
                                localStorage.setItem('user_permission', JSON.stringify(response.data.permission_key))
                                localStorage.setItem('user_permission_rank', JSON.stringify(response.data.permisson_rank_salary))
                                commit('SAVE_LOGIN', response.data)
                                resolve(true)

                            } else {
                                reject({ response: 'error', 'reason': 'permission_denied' })
                            }

                        }


                    }
                }).catch(error => {
                    reject(error)
                })
        })
    }

}