<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="company_info.company_name"
                    outline
                    required
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="email"
                    :label="$t('general.formInput.email')"
                    v-model="company_info.company_email"
                    outline
                  />
                </mdb-col>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.phone_no')"
                    v-model="company_info.company_phone_no"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.website')"
                    v-model="company_info.company_website"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.country')"
                    v-model="company_info.company_country"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.state')"
                    v-model="company_info.company_state"
                    outline
                  />
                </mdb-col>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.township')"
                    v-model="company_info.company_township"
                    outline
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.address')"
                    v-model="company_info.company_address"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  
                  <mdb-file-input
                    @getValue="getFileInputValue"
                    :width="200"
                    btnColor="mdb-color"
                    rounded
                    accept="image/*"
                    btn-title="Change Company Logo"                    
                    :key="logoFileInputKey"
                    img
                    :src="company_info.company_logo!='' ? company_info.company_logo : '/placeholder.jpg'"
                  />
               

                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    :label="$t('general.formInput.remark')"
                    v-model="company_info.company_remark"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"                    
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>

    <log-list :logs="logs"></log-list>

    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import CompanyInfo from "@/apis/CompanyInfo";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFileInput,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbFileInput,
    ModalDeleteConfimation,
  },
  props: ["_id"],
  data() {
    return {
      countries: ["Myanmar"],
      states: ["Yangon"],
      townships: [""],
      company_info: {
        company_id: "",
        company_country: "",
        company_name: "",
        company_logo: "",
        company_state: "",
        company_township: "",
        company_address: "",
        company_phone_no: "",
        company_email: "",
        company_website: "",
        company_remark: "",
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      logoFileInputKey: 0,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.company_info.company_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    getFileInputValue(val) {
      this.company_info.company_logo = val[0];
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          CompanyInfo.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  let company_name,
                    company_id,
                    company_country,
                    company_logo,
                    company_state,
                    company_township,
                    company_address,
                    company_phone_no,
                    company_email,
                    company_website,
                    company_remark;

                  let fetch_data = response.data.data;
                  Object.keys(fetch_data).forEach(function (key) {
                    if (fetch_data[key] === null) {
                      fetch_data[key] = "";
                    }
                  });

                  // populate field
                  ({
                    company_name,
                    company_id,
                    company_country,
                    company_logo,
                    company_state,
                    company_township,
                    company_address,
                    company_phone_no,
                    company_email,
                    company_website,
                    company_remark,
                  } = fetch_data);
                  this.company_info.company_id = company_id;
                  this.company_info.company_name = company_name;
                  this.company_info.company_country = company_country;
                  this.company_info.company_logo = company_logo;
                  this.company_info.company_state = company_state;
                  this.company_info.company_township = company_township;
                  this.company_info.company_address = company_address;
                  this.company_info.company_phone_no = company_phone_no;
                  this.company_info.company_email = company_email;
                  this.company_info.company_website = company_website;
                  this.company_info.company_remark = company_remark;

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                }
                this.updateLoading(false);
              } else {
                this.$router.push({ name: "listCompanyInfo" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listCompanyInfo" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listCompanyInfo" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      let formData = new FormData();
      // for (const [key, value] of Object.entries(this.company_info)) {
      //   formData.append(key, value)
      // }

      formData.append("company_id", this.company_info.company_id);
      formData.append("company_name", this.company_info.company_name);
      formData.append("company_country", this.company_info.company_country);
      formData.append("company_logo", this.company_info.company_logo);
      formData.append("company_state", this.company_info.company_state);
      formData.append("company_township", this.company_info.company_township);
      formData.append("company_address", this.company_info.company_address);
      formData.append("company_phone_no", this.company_info.company_phone_no);
      formData.append("company_email", this.company_info.company_email);
      formData.append("company_website", this.company_info.company_website);
      formData.append("company_remark", this.company_info.company_remark);

      CompanyInfo.save(formData).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.company_info.company_id != "") {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "listCompanyInfo" });
            } else {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({ name: "listCompanyInfo" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.company_info).forEach((key) => {
        this.company_info[key] = "";
      });
      this.logoFileInputKey += 1;
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.company_info.company_id > 0) {
        CompanyInfo.delete({ company_id: this.company_info.company_id })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({ name: "listCompanyInfo" });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>