<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <template v-if="!employee_id">
                <mdb-row class="mb-3">
                  <mdb-col offsetMd="2" md="8">
                    <div class="float-left mt-3 sec_title">Select Employee</div>
                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="float-right btn_outline_style"
                      icon="user-friends"
                      @click.native="modal = true"
                    >
                      {{ $t("payrolls.button.select_employee") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>

                <mdb-row v-if="selectedEmployeeData" class="mb-5">
                  <mdb-col offsetMd="2" md="8">
                    <div class="approval_info_box">
                      <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
                    </div>
                  </mdb-col>
                </mdb-row>
              </template>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('insurance.formInput.eins_title')"
                    v-model="insurances.eins_title"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    :label="$t('insurance.formInput.eins_description')"
                    v-model="insurances.eins_description"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="number"
                    :label="$t('insurance.formInput.eins_monthly_amount')"
                    v-model="insurances.eins_monthly_amount"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="10">
                  <label for="">Start Date</label>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="4" class="form-inline-search">
                  <mdb-select
                    search
                    outline
                    v-model.trim="yearsOptions"
                    :label="$t('holidays.formInput.select_year')"
                    placeholder=""
                    v-on:getValue="getSelectValue"
                  />
                </mdb-col>
                <mdb-col md="4" class="form-inline-search">
                  <mdb-select
                    outline
                    v-model.trim="monthOptions"
                    :label="$t('holidays.formInput.select_month')"
                    placeholder=""
                    v-on:getValue="getSelectMonthValue"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="10">
                  <label for="">End Date</label>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="4" class="form-inline-search">
                  <mdb-select
                    search
                    outline
                    v-model.trim="endYearsOptions"
                    :label="$t('holidays.formInput.select_year')"
                    placeholder=""
                    v-on:getValue="getSelectValueEndDate"
                  />
                </mdb-col>
                <mdb-col md="4" class="form-inline-search">
                  <mdb-select
                    outline
                    v-model.trim="endMonthOptions"
                    :label="$t('holidays.formInput.select_month')"
                    placeholder=""
                    v-on:getValue="getSelectMonthValueEndDate"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapSingleSelectTable
          @updateSelectedEmployee="setSelectedEmployee"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import EmployeeInsurance from "@/apis/EmployeeInsurance";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import EmployeeListBoostrapSingleSelectTable from "@/components/EmployeeListBoostrapSingleSelectTable";
import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  //   mdbDatePicker2,
  mdbSelect,
  //   mdbFormInline,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

const yearOption = (current, year) => {
  let years = [];
  for (
    let current_year = parseInt(current) + 10;
    current_year >= 2000;
    --current_year
  ) {
    if (year == current_year)
      years.push({ text: current_year, value: current_year, selected: true });
    else years.push({ text: current_year, value: current_year });
  }
  return years;
};

const month_name = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthOption = (month) => {
  let months = [];
  for (let i = 0; i < month_name.length; i++) {
    if (month == month_name[i])
      months.push({
        text: month_name[i],
        value: month_name[i],
        selected: true,
      });
    else months.push({ text: month_name[i], value: month_name[i] });
  }
  return months;
};

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    // mdbDatePicker2,
    mdbSelect,
    // mdbFormInline,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeInfoBoxLayout,

    ModalDeleteConfimation,
    EmployeeListBoostrapSingleSelectTable,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      insurances: {
        eins_id: "",
        eins_employee_id: this.employee_id ? this.employee_id : "",
        eins_title: "",
        eins_description: "",
        eins_monthly_amount: "",
        eins_start_date: "",
        eins_end_date: "",
      },

      start_date_year_data: "",
      start_date_month_data: "",
      end_date_year_data: "",
      end_date_month_data: "",

      yearsOptions: null,
      monthOptions: null,
      
      endYearsOptions: null,
      endMonthOptions: null,

      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,
      selectedEmployeeData: null,
      modal: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.insurances.eins_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    setSelectedEmployee(val) {
      this.modal = false;
      this.selectedEmployeeData = val;
      this.insurances.eins_employee_id = val.employee_id;
    },
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },

    getSelectValue(value) {
      this.start_date_year_data = value;
    },
    getSelectMonthValue(value) {
      this.start_date_month_data = value;
    },

    getSelectValueEndDate(value) {
      this.end_date_year_data = value;
    },
    getSelectMonthValueEndDate(value) {
      this.end_date_month_data = value;
    },

    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          EmployeeInsurance.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data);

                  let eins_id,
                    eins_employee_id,
                    eins_title,
                    eins_description,
                    eins_monthly_amount,
                    eins_start_date,
                    eins_end_date;
                  // populate field
                  ({
                    eins_id,
                    eins_employee_id,
                    eins_title,
                    eins_description,
                    eins_monthly_amount,
                    eins_start_date,
                    eins_end_date,
                  } = response.data.data);
                  this.insurances.eins_id = eins_id;
                  this.insurances.eins_employee_id = eins_employee_id;
                  this.insurances.eins_title = eins_title;
                  this.insurances.eins_description = eins_description;
                  this.insurances.eins_monthly_amount = eins_monthly_amount;
                  this.insurances.eins_start_date =
                    eins_start_date != "0000-00-00" ? eins_start_date : "";
                  this.insurances.eins_end_date =
                    eins_end_date != "0000-00-00" ? eins_end_date : "";

                  if (this.insurances.eins_start_date != "") {
                    const c = new Date();
                    const d = new Date(this.insurances.eins_start_date);
                    this.yearsOptions = yearOption(
                      parseInt(c.getFullYear()),
                      parseInt(d.getFullYear())
                    );
                    this.monthOptions = monthOption(month_name[d.getMonth()]);

                    
                    this.start_date_year_data = d.getFullYear();
                    this.start_date_month_data = month_name[d.getMonth()];
                  } else {
                    const c = new Date();

                    this.yearsOptions = yearOption(
                      parseInt(c.getFullYear()),
                      parseInt(c.getFullYear())
                    );
                    this.monthOptions = monthOption(month_name[c.getMonth()]);

                    
                    this.start_date_year_data = c.getFullYear();
                    this.start_date_month_data = month_name[c.getMonth()];
                  }


                  if (this.insurances.eins_end_date != "") {
                    const c = new Date();
                    const d = new Date(this.insurances.eins_end_date);
                    this.endYearsOptions = yearOption(
                      parseInt(c.getFullYear()),
                      parseInt(d.getFullYear())
                    );
                    this.endMonthOptions = monthOption(month_name[d.getMonth()]);

                    
                    this.end_date_year_data = d.getFullYear();
                    this.end_date_month_data = month_name[d.getMonth()];
                    
                  } else {
                    const c = new Date();

                    this.endYearsOptions = yearOption(
                      parseInt(c.getFullYear()),
                      parseInt(c.getFullYear())
                    );
                    this.endMonthOptions = monthOption(month_name[c.getMonth()]);

                    
                    
                    this.end_date_year_data = c.getFullYear();
                    this.end_date_month_data = month_name[c.getMonth()];
                  }

                  if (!this.employee_id) {
                    this.selectedEmployeeData = {
                      epinfo_name: response.data.data.epinfo_name,
                      employee_code: response.data.data.employee_code,
                      profile_image: response.data.data.profile_image,
                      department_name: response.data.data.department_name,
                      jp_name: response.data.data.jp_name,
                      rt_name: response.data.data.rt_name,
                    };
                    //emp info
                  }
                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "insuranceList" });
              }
            })
            .catch(() => {
              // console.log(error)
              this.$router.push({ name: "insuranceList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "insuranceList" });
        }
      } else {
        // add
        const c = new Date();

        this.yearsOptions = yearOption(
          parseInt(c.getFullYear()),
          parseInt(c.getFullYear())
        );
        this.monthOptions = monthOption(month_name[c.getMonth()]);

        this.start_date_year_data = c.getFullYear();
        this.start_date_month_data = month_name[c.getMonth()];

        
        this.endYearsOptions = yearOption(
          parseInt(c.getFullYear()),
          parseInt(c.getFullYear())
        );
        this.endMonthOptions = monthOption(month_name[c.getMonth()]);

        
        this.end_date_year_data = c.getFullYear();
        this.end_date_month_data = month_name[c.getMonth()];
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;


      this.insurances.eins_start_date = this.start_date_month_data + "-"+this.start_date_year_data;
      this.insurances.eins_end_date = this.end_date_month_data + "-"+this.end_date_year_data;


      EmployeeInsurance.save(this.insurances).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            if (this.employee_id) {
              this.$router.push({
                name: "insuranceList",
                params: { employee_id: this.insurances.eins_employee_id },
              });
            } else {
              //   this.$router.push({ name: "contractManageList" });
              this.$router.push({
                name: "insuranceList",
                params: { employee_id: this.insurances.eins_employee_id },
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      if (this.employee_id) {
        this.$router.push({
          name: "insuranceList",
          params: { employee_id: this.insurances.eins_employee_id },
        });
      } else {
        // this.$router.push({ name: "contractManageList" });
        this.$router.push({
          name: "insuranceList",
          params: { employee_id: this.insurances.eins_employee_id },
        });
      }
    },
    resetAlert() {
      this.feedback_error = false;
    },
    // resetForm() {
    //   Object.keys(this.contracts).forEach((key) => {
    //     this.contracts[key] = "";
    //   });
    //   this.contracts.contract_employee_id = this.employee_id;
    //   this.contracts.contract_employment_type = "permanent";
    //   this.doesFormReset = !this.doesFormReset;
    // },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;
      if (this.insurances.eins_id > 0) {
        EmployeeInsurance.delete({
          eins_id: this.insurances.eins_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              if (this.employee_id) {
                this.$router.push({
                  name: "insuranceList",
                  params: { employee_id: this.insurances.eins_employee_id },
                });
              } else {
                // this.$router.push({ name: "contractManageList" });
                this.$router.push({
                  name: "insuranceList",
                  params: { employee_id: this.insurances.eins_employee_id },
                });
              }
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>