import Api from './api'

export default {
    all(payload) {
        if (payload.employee_name == '') {
            delete payload.employee_name;
        }
        if (payload.employee_code == '') {
            delete payload.employee_code;
        }

        return Api.get('api/trainings/list' , {params: payload})
    },

    save(payload) {
        return Api.post('api/trainings/data_entry', payload)
    },

    delete(payload) {
        return Api.post('api/trainings/delete', payload)
    },

    view(payload) {
        return Api.get('api/trainings/' + payload)
    }

}