<template>
  <div>
    <mdb-tbl responsive hover>
      <!-- Table head -->
      <mdb-tbl-head>
        <tr>
          <th>
            {{ $t("general.label.date") }}
          </th>
          <th>
            {{ $t("general.label.status") }}
          </th>
          <th>
            {{ $t("general.label.checkin") }}
          </th>
          <th>
            {{ $t("general.label.checkout") }}
          </th>
          <th>
            {{ $t("general.label.late") }}
          </th>
          <th>
            {{ $t("general.label.early") }}
          </th>
        </tr>
      </mdb-tbl-head>

      <!-- Table body -->
      <mdb-tbl-body>
        <tr
          scope="row"
          v-for="(attendance_item, index) in attendances"
          :key="index"
        >
          <td scope="row">
            {{ attendance_item.date | formatDateString }}
          </td>
          <td>
            {{ attendance_item.status }}
          </td>
          <td>
            {{ attendance_item.attendance_checkin_time | formatDateTimeString }}
          </td>
          <td>
            {{
              attendance_item.attendance_checkout_time | formatDateTimeString
            }}
          </td>
          
          <td>
            {{ attendance_item.late_check_in }}
          </td>
          <td>
            {{ attendance_item.early_check_out }}
          </td>
        </tr>
      </mdb-tbl-body>
    </mdb-tbl>
  </div>
</template>

<script>
import { mdbTbl, mdbTblHead, mdbTblBody } from "mdbvue";
export default {
  name: "EmployeeAttendanceTable",
  components: { mdbTbl, mdbTblHead, mdbTblBody },
  props: ["attendances"],
  data() {
    return {};
  },
};
</script>
