<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <mdb-card>
        <mdb-card-header>
          <div class="float-left"></div>

          <div class="float-right">
            <mdb-btn
              size="sm"
              color="uno-color"
              class="btn_delete"
              v-show="shouldDeleteButtonShow"
              @click.native="showDeleteModal"
              :disabled="deleteSubmitted"
            >
              <mdb-icon
                icon="trash-alt"
                far
                size="md"
                v-show="!deleteSubmitted"
              />

              <span
                class="spinner-border-vcenter spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="deleteSubmitted"
              ></span>

              {{ $t("general.button.delete_selected") }}
            </mdb-btn>
            <mdb-btn
              size="sm"
              class="btn_submit"
              color="uno-color"
              @click="addNewEmployee"
            >
              <mdb-icon icon="plus" />
              Add New Employee
            </mdb-btn>
          </div>
        </mdb-card-header>

        <mdb-card-body>
          <mdb-tbl hover responsive>
            <!-- Table head -->
            <mdb-tbl-head>
              <tr>
                <th width="50">
                  <!-- Material unchecked -->
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="trmc_all"
                      @change="checkAllHolidays"
                      v-model="isCheckAll"
                    />
                    <label class="form-check-label" for="trmc_all"></label>
                  </div>
                </th>
                <th @click="sort('epinfo_name')">
                  <i
                    v-show="currentSort === 'epinfo_name'"
                    :class="[
                      currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ',
                    ]"
                    aria-hidden="true"
                  ></i>
                </th>
                <th
                  @click="sort('eavr_created')"
                  width="120"
                  class="text-right"
                >
                  <i
                    v-show="currentSort === 'eavr_created'"
                    :class="[
                      currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ',
                    ]"
                    aria-hidden="true"
                  ></i>
                  {{ $t("general.formInput.created") }}
                </th>
              </tr>
            </mdb-tbl-head>
            <!-- Table head -->

            <!-- Table body -->
            <mdb-tbl-body>
              <tr
                scope="row"
                v-for="(record_item, index) in sortedRecordList"
                :key="index"
              >
                <th scope="row">
                  <!-- Material unchecked -->
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      :id="'trmc_' + record_item.eavr_id"
                      :value="record_item.eavr_id"
                      v-model="selectedEmployees"
                      @change="updateCheckall"
                    />
                    <label
                      class="form-check-label"
                      :for="'trmc_' + record_item.eavr_id"
                    ></label>
                  </div>
                </th>
                <td>
                  <EmployeeInfoTableCellLayout :user="record_item" />
                </td>
                <td class="text-right">
                  {{ record_item.eavr_created | formatDateString }}
                  <br />
                  {{ record_item.formatTimeString | formatTimeString }}
                </td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>
        </mdb-card-body>
      </mdb-card>
    </div>

    <ModalDeleteConfimation @comfirmAction="deleteEmployees" />
  </div>
</template>

<script>
import EmployeeActivity from "@/apis/EmployeeActivity";
import { mapActions, mapGetters } from "vuex";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";

import {
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
} from "mdbvue";

export default {
  components: {
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    ModalDeleteConfimation,
    EmployeeInfoTableCellLayout,
  },

  props: ["employee_id"],
  data() {
    return {
      currentSort: "epinfo_name",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
      employees: [],
      formSubmitting: false,
      isCheckAll: false,
      selectedEmployees: [],
      deleteSubmitted: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    sortedRecordList() {
      return this.employees.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },

    doesFormSubmitted() {
      return this.formSubmitting;
    },
    doesCheckAll() {
      return this.isCheckAll;
    },
    shouldDeleteButtonShow() {
      return this.selectedEmployees.length > 0 ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },

    checkAllHolidays() {
      this.selectedEmployees = [];
      if (this.isCheckAll) {
        for (let record_item of this.employees) {
          this.selectedEmployees.push(record_item.eavr_id);
        }
      }
    },
    updateCheckall() {
      if (this.employees.length == this.selectedEmployees.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },

    deleteEmployees() {
      this.$store.state.deleteModalVisible = false;
      this.deleteSubmitted = true;
      if (this.selectedEmployees.length > 0) {
        EmployeeActivity.deleteActivityViewer({
          eavr_id: this.selectedEmployees
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.formSubmitting = true;
              this.fetchRecordList();
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
      }
    },

    addNewEmployee() {
      this.$router.push({ name: "addActivityLeader" });
    },

    fetchRecordList() {
      this.updateLoading(true);

      EmployeeActivity.getActivityViewerList(this.employee_id)
        .then((response) => {
          if (response.status === 200) {
            this.employees = response.data.data;

            this.updateLoading(false);
            this.formSubmitting = false;
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
            this.formSubmitting = false;
          } else {
            this.feedback_message = error;
            this.feedback_error = true;

            this.updateLoading(false);
            this.formSubmitting = false;
          }
        });
    },
  },
  created() {
    if (!this.employee_id || Math.sign(this.employee_id) !== 1) {
      this.$router.push({ name: "listEmployee" });
    } else {
      this.fetchRecordList();
    }
  },
};
</script>