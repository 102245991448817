import Api from './api'

export default{
    get(){
            return Api.get('api/report/account_report/columns')
    },

    save(payload){
            return Api.post('api/report/account_report/submit_show_columns', payload)        
    },

}