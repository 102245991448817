<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <template v-if="!employee_id">
                <mdb-row class="mb-3" v-if="_id == undefined">
                  <mdb-col offsetMd="2" md="8">
                    <div class="float-left mt-3 sec_title">
                      Select Employees
                    </div>
                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="float-right btn_outline_style"
                      icon="user-friends"
                      @click.native="modalOpen()"
                    >
                      {{ $t("payrolls.button.select_employee") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>

                <mdb-row class="mb-3">
                  <mdb-col offsetMd="2" md="8">
                    <div v-if="selected_employee_ids.length > 0">
                      <mdb-chip
                        v-for="(e, counter) in selected_employee_ids"
                        v-bind:key="counter"
                        waves
                      >
                        {{ e.epinfo_name }}
                      </mdb-chip>
                    </div>

                    <hr />
                  </mdb-col>
                </mdb-row>

                <!-- //////////////////////// -->

                <mdb-row class="mb-3" v-if="_id != undefined">
                  <mdb-col offsetMd="2" md="8">
                    <div class="float-left mt-3 sec_title">Select Employee</div>
                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="float-right btn_outline_style"
                      icon="user-friends"
                      @click.native="modal = true"
                    >
                      {{ $t("payrolls.button.select_employee") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>

                <mdb-row v-if="selectedEmployeeData" class="mb-5">
                  <mdb-col offsetMd="2" md="8">
                    <div class="approval_info_box">
                      <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
                    </div>
                  </mdb-col>
                </mdb-row>
              </template>

              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="4">
                  <ActionTypeDropdown
                    @updateActionTypeID="setActionTypeID"
                    :default_selected_value="actionInfos.hr_ea_type_id"
                    :required="true"
                    :resetSelected="doesFormReset"
                    v-if="!isLoading"
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-date-picker-2
                    :label="$t('action_info.formInput.date')"
                    v-model="actionInfos.hr_ea_date"
                    title="Select date"
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    :label="$t('action_info.formInput.description')"
                    v-model="actionInfos.hr_ea_description"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <label>{{
                    $t("action_info.formInput.deduction_status")
                  }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="option-not_deduct"
                      name="option_hr_ea_salary_deduction_status"
                      radioValue="not_deduct"
                      v-model="actionInfos.hr_ea_salary_deduction_status"
                      :label="$t('action_info.formInput.not_deduct')"
                    />
                    <mdb-input
                      type="radio"
                      id="option-deduct"
                      name="option_hr_ea_salary_deduction_status"
                      radioValue="deduct"
                      v-model="actionInfos.hr_ea_salary_deduction_status"
                      :label="$t('action_info.formInput.deduct')"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>

              <div
                v-show="
                  this.actionInfos.hr_ea_salary_deduction_status == 'deduct'
                "
              >
                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <label>{{
                      $t("action_info.formInput.penalty_type")
                    }}</label>
                    <mdb-form-inline>
                      <mdb-input
                        type="radio"
                        id="option-amount"
                        name="option_hr_ea_penalty_type"
                        radioValue="amount"
                        v-model="actionInfos.hr_ea_penalty_type"
                        :label="$t('action_info.formInput.amount')"
                      />
                      <mdb-input
                        type="radio"
                        id="option-percentage"
                        name="option_hr_ea_penalty_type"
                        radioValue="percentage"
                        v-model="actionInfos.hr_ea_penalty_type"
                        :label="$t('action_info.formInput.percentage')"
                      />
                    </mdb-form-inline>
                  </mdb-col>
                </mdb-row>
                <!-- ///////////////////////// -->
                <!-- ///////////////////////// -->
                <!-- ///////////////////////// -->

                <mdb-row v-if="_id == undefined">
                  <mdb-col offsetMd="2" md="8">
                    <label></label>
                    <mdb-form-inline>
                      <mdb-input
                        type="radio"
                        id="penalty_value_type-different_amount"
                        name="penalty_value_type"
                        radioValue="different_amount"
                        v-model="actionInfos.penalty_value_type"
                        label="Different Amount"
                      />
                      <mdb-input
                        type="radio"
                        id="penalty_value_type-same_amount"
                        name="penalty_value_type"
                        radioValue="same_amount"
                        v-model="actionInfos.penalty_value_type"
                        label="Same Amount"
                      />
                    </mdb-form-inline>
                  </mdb-col>
                </mdb-row>
                <br />

                <div
                  v-show="actionInfos.penalty_value_type == 'different_amount'"
                >
                  <mdb-row v-if="_id == undefined">
                    <mdb-col offsetMd="2" md="8">
                      <mdb-btn
                        type="button"
                        class="btn_sm_light_blue"
                        color="uno-color"
                        @click="addActionAmount('', '', '')"
                        size="sm"
                        rounded
                      >
                        <mdb-icon icon="plus-square" size="md" />
                        Add More
                      </mdb-btn>
                    </mdb-col>
                  </mdb-row>
                  <div
                    v-for="(action_amount, counter) in action_amount_list"
                    v-bind:key="counter"
                  >
                    <mdb-row>
                      <mdb-col offsetMd="2" md="4">
                        <mdb-input
                          type="number"
                          :label="$t('addition.formInput.amount')"
                          v-model="action_amount.hr_ea_penalty_value"
                          outline
                        />
                      </mdb-col>

                      <mdb-col md="4">
                        <MonthDropdown
                          @updateMonth="setMonth"
                          :selected_date="action_amount.effective_month"
                          :parent_index="counter"
                          v-if="!isLoading"
                          :key="rerenderIndex"
                        />

                        <YearDropdown
                          @updateYear="setYear"
                          :selected_date="action_amount.effective_year"
                          :parent_index="counter"
                          v-if="!isLoading"
                          
                          :key="rerenderIndex"
                        />
                      </mdb-col>

                      <mdb-col offsetMd="2" md="8">
                        <mdb-btn
                          type="button"
                          rounded
                          size="sm"
                          @click="deleteActionAmount(counter)"
                          color="uno-color"
                          class="btn_sm_delete mt-2 mb-4"
                          :disabled="shouldDisabledDeleteActionAmountListButton"
                          v-if="_id == undefined"
                        >
                          <mdb-icon icon="trash-alt" size="md" />
                          {{ $t("general.button.delete") }}
                        </mdb-btn>
                      </mdb-col>
                    </mdb-row>

                    <mdb-row v-if="counter < action_amount_list.length - 1">
                      <mdb-col offsetMd="2" md="8">
                        <hr />
                      </mdb-col>
                    </mdb-row>
                  </div>
                </div>

                <div v-show="actionInfos.penalty_value_type == 'same_amount'">
                  <mdb-row>
                    <mdb-col offsetMd="2" md="4">
                      <mdb-input
                        type="number"
                        label="Amount"
                        v-model="temp_same_amount.hr_ea_penalty_value"
                        outline
                      />
                    </mdb-col>
                  </mdb-row>

                  <mdb-row><mdb-col offsetMd="2" md="8">From</mdb-col></mdb-row>
                  <mdb-row>
                    <mdb-col offsetMd="2" md="4">
                      <MonthDropdown
                        @updateMonth="setFromMonth"
                        :selected_date="''"
                      />
                    </mdb-col>
                    <mdb-col md="4">
                      <YearDropdown
                        @updateYear="setFromYear"
                        :selected_date="''"
                      />
                    </mdb-col>
                  </mdb-row>

                  <mdb-row><mdb-col offsetMd="2" md="8">To</mdb-col></mdb-row>
                  <mdb-row>
                    <mdb-col offsetMd="2" md="4">
                      <MonthDropdown
                        @updateMonth="setToMonth"
                        :selected_date="''"
                      />
                    </mdb-col>
                    <mdb-col md="4">
                      <YearDropdown
                        @updateYear="setToYear"
                        :selected_date="''"
                      />
                    </mdb-col>
                  </mdb-row>
                </div>
                <!-- ///////////////////////// -->
                <!-- ///////////////////////// -->
                <!-- ///////////////////////// -->
              </div>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />

    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapCheckboxTable
          @updateSelectedEmployeeIDs="setSelectedEmployeeIDs"
          :default_selected_value="source_default_selected_value"
          v-if="_id == undefined"
        />

        <EmployeeListBoostrapSingleSelectTable
          @updateSelectedEmployee="setSelectedEmployee"
          v-if="_id != undefined"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import EmployeeActionInfo from "@/apis/EmployeeActionInfo";
import ActionTypeDropdown from "@/components/ActionTypeDropdown";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";

import EmployeeListBoostrapSingleSelectTable from "@/components/EmployeeListBoostrapSingleSelectTable";
import EmployeeListBoostrapCheckboxTable from "@/components/EmployeeListBoostrapCheckboxTable";
import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";

import YearDropdown from "@/components/YearDropdown";
import MonthDropdown from "@/components/MonthDropdown";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  // mdbSelect,
  mdbFormInline,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbChip,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbDatePicker2,
    // mdbSelect,
    mdbFormInline,
    ModalDeleteConfimation,
    ActionTypeDropdown,

    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeListBoostrapSingleSelectTable,
    EmployeeListBoostrapCheckboxTable,
    EmployeeInfoBoxLayout,
    YearDropdown,
    MonthDropdown,
    mdbChip,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      actionInfos: {
        hr_ea_id: "",
        hr_ea_employee_id: this.employee_id ? [this.employee_id] : "",
        hr_ea_type_id: "",
        hr_ea_description: "",
        hr_ea_date: "",
        hr_ea_salary_deduction_status: "deduct",
        hr_ea_penalty_type: "",
        hr_ea_penalty_value: "",
        hr_ea_penalty_effective_month: "",
        penalty_value_type: "different_amount",
        month_from: "",
        month_to: "",
      },
      action_amount_list: [
        {
          hr_ea_penalty_value: "",
          effective_month: "",
          effective_year: "",
        },
      ],
      temp_same_amount: {
        hr_ea_penalty_value: "",
        from_month: "",
        from_year: "",
        to_month: "",
        to_year: "",
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,

      selectedEmployeeData: null,
      selected_employee_ids: [],
      source_default_selected_value: "",
      modal: false,
      rerenderIndex:0
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.actionInfos.hr_ea_id != "" ? true : false;
    },
    shouldDisabledDeleteActionAmountListButton() {
      return this.action_amount_list.length > 1 ? false : true;
    },
  },

  methods: {
    setMonth({ value, parent_index }) {
      this.action_amount_list[parent_index].effective_month = value;
    },

    setYear({ value, parent_index }) {
      this.action_amount_list[parent_index].effective_year = value;
    },

    setFromMonth(value) {
      this.temp_same_amount.from_month = value;
    },
    setFromYear(value) {
      this.temp_same_amount.from_year = value;
    },
    setToMonth(value) {
      this.temp_same_amount.to_month = value;
    },
    setToYear(value) {
      this.temp_same_amount.to_year = value;
    },

    modalOpen() {
      this.modal = true;

      this.source_default_selected_value = this.selected_employee_ids.map(
        (e) => {
          return e.employee_id;
        }
      );
    },

    setSelectedEmployeeIDs(val) {
      this.selected_employee_ids = val;

      // this.selectedEmployeeData = null;
    },

    setActionTypeID(val) {
      this.actionInfos.hr_ea_type_id = val;
    },
    setSelectedEmployee(val) {
      this.modal = false;
      this.selectedEmployeeData = val;
      this.actionInfos.hr_ea_employee_id = val.employee_id;
    },

    addActionAmount(
      hr_ea_penalty_value = "",
      effective_month = "",
      effective_year = ""
    ) {
      this.rerenderIndex++;
      this.action_amount_list.push({
        hr_ea_penalty_value,
        effective_month,
        effective_year,
      });
    },
    deleteActionAmount(counter) {
      
      this.rerenderIndex++;
      this.action_amount_list.splice(counter, 1);
    },

    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          EmployeeActionInfo.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)

                  let hr_ea_id,
                    hr_ea_employee_id,
                    hr_ea_type_id,
                    hr_ea_description,
                    hr_ea_date,
                    hr_ea_salary_deduction_status,
                    hr_ea_penalty_type,
                    hr_ea_penalty_value,
                    hr_ea_penalty_effective_month;
                  // populate field
                  ({
                    hr_ea_id,
                    hr_ea_employee_id,
                    hr_ea_type_id,
                    hr_ea_description,
                    hr_ea_date,
                    hr_ea_salary_deduction_status,
                    hr_ea_penalty_type,
                    hr_ea_penalty_value,
                    hr_ea_penalty_effective_month,
                  } = response.data.data);
                  this.actionInfos.hr_ea_id = hr_ea_id;
                  this.actionInfos.hr_ea_employee_id = hr_ea_employee_id;
                  this.actionInfos.hr_ea_type_id = hr_ea_type_id;
                  this.actionInfos.hr_ea_description = hr_ea_description;
                  this.actionInfos.hr_ea_date = hr_ea_date;
                  this.actionInfos.hr_ea_salary_deduction_status =
                    hr_ea_salary_deduction_status == "1"
                      ? "deduct"
                      : "not_deduct";
                  this.actionInfos.hr_ea_penalty_type = hr_ea_penalty_type;
                  this.actionInfos.hr_ea_penalty_value = hr_ea_penalty_value;
                  this.actionInfos.hr_ea_penalty_effective_month =
                    hr_ea_penalty_effective_month;

                  if (hr_ea_penalty_effective_month && hr_ea_penalty_effective_month != "") {
                    this.action_amount_list = [];

                    const parts = hr_ea_penalty_effective_month.split("-");

                    this.addActionAmount(
                      hr_ea_penalty_value,
                      parts[1],
                      parts[0]
                    );

                    // this.action_amount_list = [
                    //   {
                    //     hr_ea_penalty_value: hr_ea_penalty_value,
                    //     effective_month: parts[1],
                    //     effective_year: parts[0],
                    //   },
                    // ];
                  }

                  if (!this.employee_id) {
                    this.selectedEmployeeData = {
                      epinfo_name: response.data.data.epinfo_name,
                      employee_code: response.data.data.employee_code,
                      profile_image: response.data.data.profile_image,
                      department_name: response.data.data.department_name,
                      jp_name: response.data.data.jp_name,
                      rt_name: response.data.data.rt_name,
                    };
                    //emp info
                  }

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    actionInfoList: {
                      _id: this.employee_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "actionInfoList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "actionInfoList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "actionInfoList" });
        }
      } else {
        // add
      }
    },

    prepareActionList() {
      this.actionInfos.hr_ea_penalty_value = [];
      this.actionInfos.hr_ea_penalty_effective_month = [];
      this.actionInfos.month_from = "";
      this.actionInfos.month_to = "";

      if (this.actionInfos.penalty_value_type == "different_amount") {
        if (this.action_amount_list.length > 0) {
          if (this._id == undefined) {
            for (let action_amount of this.action_amount_list) {
              this.actionInfos.hr_ea_penalty_value.push(
                action_amount.hr_ea_penalty_value
              );
              this.actionInfos.hr_ea_penalty_effective_month.push(
                action_amount.effective_year +
                  "-" +
                  (parseInt(action_amount.effective_month) >=10 ? action_amount.effective_month : '0'+parseInt(action_amount.effective_month)) +
                  "-01"
              );
            }
          } else {
            this.actionInfos.hr_ea_penalty_value =
              this.action_amount_list[0].hr_ea_penalty_value;
            this.actionInfos.hr_ea_penalty_effective_month =
              this.action_amount_list[0].effective_year +
              "-" +
              (parseInt(this.action_amount_list[0].effective_month) >= 0 ? this.action_amount_list[0].effective_month : '0'+parseInt(this.action_amount_list[0].effective_month)) +
              "-01";
          }
        }
      } else {
        this.actionInfos.hr_ea_penalty_value.push(
          this.temp_same_amount.hr_ea_penalty_value
        );
        this.actionInfos.month_from =
          this.temp_same_amount.from_year +
          "-" +
          (parseInt(this.temp_same_amount.from_month)>=10 ? this.temp_same_amount.from_month : '0'+parseInt(this.temp_same_amount.from_month)) +
          "-01";
        this.actionInfos.month_to =
          this.temp_same_amount.to_year +
          "-" +
          (parseInt(this.temp_same_amount.to_month) >=10 ? this.temp_same_amount.to_month : '0'+parseInt(this.temp_same_amount.to_month)) +
          "-01";
      }
    },

    handleFormSubmit() {
      if (this.actionInfos.hr_ea_salary_deduction_status == "deduct") {
        if (
          this.actionInfos.penalty_value_type == "same_amount" &&
          (this.temp_same_amount.from_month == "" ||
            this.temp_same_amount.from_year == "" ||
            this.temp_same_amount.to_month == "" ||
            this.temp_same_amount.to_year == "")
        ) {
          //show error
          this.feedback_message =
            'Please fill both  of "From" and "To" month and year.';
          this.feedback_error = true;
          return;
        } else if (
          this.actionInfos.penalty_value_type == "different_amount" &&
          (this.temp_effective_month == "" || this.temp_effective_year == "")
        ) {
          //show error
          this.feedback_message = "Please fill both Effective month and year.";
          this.feedback_error = true;
          return;
        }
      }

      if (this.actionInfos.hr_ea_salary_deduction_status == "not_deduct") {
        delete this.actionInfos.hr_ea_penalty_type;
        delete this.actionInfos.hr_ea_penalty_value;
        delete this.actionInfos.hr_ea_penalty_effective_month;
      } else {
        this.prepareActionList();

        // this.actionInfos.hr_ea_penalty_effective_month =
        //   this.temp_effective_year + "-" + this.temp_effective_month + "-01";
      }

      this.resetAlert();
      this.formSubmitting = true;

      if (this.selected_employee_ids.length > 0) {
        this.actionInfos.hr_ea_employee_id = this.selected_employee_ids.map(
          (e) => {
            return e.employee_id;
          }
        );
      }

      // console.log(this.actionInfos);

      EmployeeActionInfo.save(this.actionInfos).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            if (this.employee_id) {
              this.$router.push({
                name: "actionInfoList",
                params: { employee_id: this.employee_id },
              });
            } else {
              this.$router.push({ name: "actionsManageList" });
            }

            // if (this.actionInfos.hr_ea_id != "") {
            //   this.$notify.success({
            //     message: response.data.message,
            //     position: "top right",
            //     timeOut: 5000,
            //   });
            //   this.$router.push({
            //     name: "actionInfoList",
            //     params: { employee_id: this.actionInfos.hr_ea_employee_id },
            //   });
            // } else {
            //   this.$notify.success({
            //     message: response.data.message,
            //     position: "top right",
            //     timeOut: 5000,
            //   });
            //   this.resetForm();
            // }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      // this.$router.push({
      //   name: "actionInfoList",
      //   params: { employee_id: this.actionInfos.hr_ea_employee_id },
      // });
      if (this.employee_id) {
        this.$router.push({
          name: "actionInfoList",
          params: { employee_id: this.employee_id },
        });
      } else {
        this.$router.push({ name: "actionsManageList" });
      }
    },
    resetAlert() {
      this.feedback_error = false;
    },
    // resetForm() {
    //   Object.keys(this.actionInfos).forEach((key) => {
    //     this.actionInfos[key] = "";
    //   });
    //   this.actionInfos.hr_ea_employee_id = this.employee_id;
    // },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.actionInfos.hr_ea_id > 0) {
        EmployeeActionInfo.delete({
          hr_ea_id: this.actionInfos.hr_ea_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              if (this.employee_id) {
                this.$router.push({
                  name: "actionInfoList",
                  params: { employee_id: this.employee_id },
                });
              } else {
                this.$router.push({ name: "actionsManageList" });
              }
              // this.$router.push({
              //   name: "actionInfoList",
              //   params: {
              //     _id: this.actionInfos.hr_ea_employee_id,
              //   },
              // });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      actionInfoList: {
        employee_id: this.employee_id,
      },
    };
    this.fetchRecord();
  },
};
</script>