export default {

    loggedIn(state){
        return state.token !== null
    }, 
    apiAuthorizationToken(state){
        return state.token
    },
    
    apiOrganizationValue(state){
        return state.organization
    },

    
    userPermission(state){
        return state.token
    },
}