var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.feedback_error)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[(typeof _vm.feedback_message === 'string')?_c('div',[_vm._v(" "+_vm._s(_vm.feedback_message)+" ")]):_vm._l((_vm.feedback_message),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error.message)+" ")])})],2):_vm._e(),[_c('div',{staticClass:"d-flex justify-content-center"},[_c('mdb-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"big":"","color":"blue"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}]},[_c('mdb-card',[_c('mdb-card-header',[_c('div',{staticClass:"float-left"}),_c('div',{staticClass:"float-right"},[_c('mdb-btn',{staticClass:"btn_submit",attrs:{"color":"uno-color","size":"sm"},on:{"click":_vm.createNewRecord}},[_c('mdb-icon',{attrs:{"far":"","icon":"plus-square","size":"md"}}),_vm._v(" "+_vm._s(_vm.$t("general.button.create"))+" ")],1)],1)]),_c('mdb-card-body',[_c('mdb-tbl',{attrs:{"responsive":"","hover":""}},[_c('mdb-tbl-head',[_c('tr',[_c('th',{on:{"click":function($event){return _vm.sort('hr_work_hours_name')}}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentSort === 'hr_work_hours_name'),expression:"currentSort === 'hr_work_hours_name'"}],class:[
                      _vm.currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ' ],attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t("general.formInput.name"))+" ")]),_c('th',[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentSort === 'hr_work_hours_from'),expression:"currentSort === 'hr_work_hours_from'"}],class:[
                      _vm.currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ' ],attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t("general.formInput.hour_from"))+" ")]),_c('th',[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentSort === 'hr_work_hours_to'),expression:"currentSort === 'hr_work_hours_to'"}],class:[
                      _vm.currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ' ],attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t("general.formInput.hour_to"))+" ")]),_c('th',{on:{"click":function($event){return _vm.sort('hr_work_hours_total_hours')}}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentSort === 'hr_work_hours_total_hours'),expression:"currentSort === 'hr_work_hours_total_hours'"}],class:[
                      _vm.currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ' ],attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t("general.formInput.total_hour"))+" ")]),_c('th',[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentSort === 'hr_work_hours_half_day_to'),expression:"currentSort === 'hr_work_hours_half_day_to'"}],class:[
                      _vm.currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ' ],attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t("general.formInput.hours_half_day_to"))+" ")]),_c('th',{staticClass:"text-right",attrs:{"width":"120"},on:{"click":function($event){return _vm.sort('hr_work_hours_created')}}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentSort === 'hr_work_hours_created'),expression:"currentSort === 'hr_work_hours_created'"}],class:[
                      _vm.currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ' ],attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t("general.formInput.created"))+" ")])])]),_c('mdb-tbl-body',_vm._l((_vm.sortedDocumentTypeList),function(document_type,index){return _c('tr',{key:index,attrs:{"scope":"row"}},[_c('td',{attrs:{"scope":"row"},on:{"click":function($event){return _vm.rowClickEvent(document_type.hr_work_hours_id)}}},[_vm._v(" "+_vm._s(document_type.hr_work_hours_name)+" ")]),_c('td',{on:{"click":function($event){return _vm.rowClickEvent(document_type.hr_work_hours_id)}}},[_vm._v(" "+_vm._s(document_type.hr_work_hours_from)+" ")]),_c('td',{on:{"click":function($event){return _vm.rowClickEvent(document_type.hr_work_hours_id)}}},[_vm._v(" "+_vm._s(document_type.hr_work_hours_to)+" ")]),_c('td',{on:{"click":function($event){return _vm.rowClickEvent(document_type.hr_work_hours_id)}}},[_vm._v(" "+_vm._s(document_type.hr_work_hours_total_hours)+" ")]),_c('td',{on:{"click":function($event){return _vm.rowClickEvent(document_type.hr_work_hours_id)}}},[_vm._v(" "+_vm._s(document_type.hr_work_hours_half_day_to)+" ")]),_c('td',{staticClass:"text-right",on:{"click":function($event){return _vm.rowClickEvent(document_type.hr_work_hours_id)}}},[_vm._v(" "+_vm._s(_vm._f("formatDateString")(document_type.hr_work_hours_created))+" ")])])}),0)],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }