<template>
  <div>
    <mdb-select
      outline
      search
      v-model="axiosOptions"
      :label="selectLabel"
      @getValue="updateSelectedValue"
      :disabled="loading"
    />
  </div>
</template>

<script>
import { mdbSelect } from "mdbvue";
import ApprovalGroup from "@/apis/ApprovalGroup";
export default {
  name: "ApprovalGroupDropdown",
  components: {
    mdbSelect,
  },
  props: ["default_selected_value", "required", "resetSelected", "counter"],
  data() {
    return {
      axiosOptions: [],
      loading: true,
      selectLabel: "Loading Approval Groups",
      approval_groups: [],
    };
  },
  mounted() {
    ApprovalGroup.all().then((response) => {
      if (response.status === 200) {
        this.approval_groups = response.data.data;
        if (this.required === false) {
          this.axiosOptions.push({
            text: "All",
            value: "",
          });
        }

        for (let apg of this.approval_groups) {
          if (this.default_selected_value === apg.apg_id) {
            this.axiosOptions.push({
              text: apg.apg_name,
              value: apg.apg_id,
              selected: true,
            });
          } else {
            this.axiosOptions.push({
              text: apg.apg_name,
              value: apg.apg_id,
            });
          }
        }
        this.loading = false;
        this.selectLabel = "Select Approval Group";
      }
    });
  },
  methods: {
    updateSelectedValue(val) {
      if (val !== null) {
        this.$emit("updateApprovalGroupID", {
          value: val,
          counter: this.counter,
        });
      }
    },
  },
  watch: {
    resetSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.axiosOptions = this.axiosOptions.map((val) => ({
          text: val.text,
          value: val.value,
        }));
      }
    },
    // default_selected_value() {
    //   this.axiosOption = [];
    //   for (let apg of this.approval_groups) {
    //     if (this.default_selected_value === apg.apg_id) {
    //       this.axiosOptions.push({
    //         text: apg.apg_name,
    //         value: apg.apg_id,
    //         selected: true,
    //       });
    //     } else {
    //       this.axiosOptions.push({
    //         text: apg.apg_name,
    //         value: apg.apg_id,
    //       });
    //     }
    //   }
    // },
  },
};
</script>
