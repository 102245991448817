<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>
    <div class="text-right">
      <mdb-btn
        type="button"
        class="btn_back"
        color="uno-color"
        @click="redirectBackAction"
      >
        <mdb-icon icon="arrow-left" size="md" />
        {{ $t("general.button.go_back") }}
      </mdb-btn>
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <mdb-row v-if="selectedEmployeeData" class="mb-5">
              <mdb-col offsetMd="2" md="8">
                <div class="approval_info_box">
                  <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
                </div>
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <dl class="row">
                  <dt class="col-sm-3">Attendance Day:</dt>
                  <dd class="col-sm-9">
                    {{ attendance.attendance_day }}
                  </dd>

                  <dt class="col-sm-3">Attendance Type:</dt>
                  <dd class="col-sm-9">
                    <template v-if="attendance.attendance_type == 'check_in'">
                      Check in
                    </template>
                    <template v-else> Check Out </template>
                  </dd>

                  <dt class="col-sm-3">
                    <template v-if="attendance.attendance_type == 'check_in'">
                      Office Check in
                    </template>
                    <template v-else> OfficeCheck Out </template>
                  </dt>
                  <dd class="col-sm-9">
                    {{ attendance.attendance_office_time }}
                  </dd>

                  <dt class="col-sm-3">
                    <template v-if="attendance.attendance_type == 'check_in'">
                      Check in Time
                    </template>
                    <template v-else> Check Out Time </template>
                  </dt>
                  <dd class="col-sm-9">
                    {{ attendance.attendance_check_time }}
                  </dd>

                  <template v-if="attendance.attendance_remark != ''">
                    <dt class="col-sm-3">remark</dt>
                    <dd class="col-sm-9">
                      {{ attendance.attendance_remark }}
                    </dd>
                  </template>

                  <dt class="col-sm-3">
                    <template v-if="attendance.attendance_type == 'check_in'">
                      Late
                    </template>
                    <template v-else> Early </template>
                  </dt>
                  <dd class="col-sm-9">
                    {{ attendance.attendance_late_early }}
                  </dd>

                  <template v-if="attendance.attendance_image != ''">
                    <dt class="col-sm-3">Image</dt>
                    <dd class="col-sm-9">
                      <img :src="attendance.attendance_image" width="200" />
                    </dd>
                  </template>

                  <dt class="col-sm-3">Status</dt>
                  <dd class="col-sm-9">
                    {{ attendance.attendance_status }}
                  </dd>

                  <dt class="col-sm-3"></dt>
                  <dd
                    class="col-sm-9"
                    v-if="attendance.attendance_complete != '1'"
                  >
                    Waiting Approval
                  </dd>
                </dl>

                <hr />
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <div v-if="approve_user.length > 0">
                  <mdb-card class="mb-5">
                    <mdb-card-body>
                      <mdb-row>
                        <mdb-col>
                          <div class="title_header mb-3">Approved By</div>
                          <div
                            v-for="(user, index) in approve_user"
                            :key="index"
                          >
                            <ApprovedUserLayout
                              :users="{
                                user: {
                                  epinfo_name: user.name,
                                  jp_name: user.position,
                                  department_name: user.department,
                                  profile_image: user.image,
                                },
                              }"
                            />
                            <!-- <hr v-show="this.attendance.approve_user.length - 1 > index" /> -->
                          </div>
                        </mdb-col>
                      </mdb-row>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <template v-if="attendance.reject_button_status == true">
                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                    @click="approvalAction('approve_and_fix')"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.approve") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                    @click="approvalAction('approve')"
                    v-if="attendance.reject_button_status"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.reject") }}
                  </mdb-btn>
                </template>

                <template v-else>
                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                    @click="approvalAction('approve')"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.approve") }}
                  </mdb-btn>
                </template>

                <mdb-btn
                  type="Submit"
                  color="uno-color"
                  class="btn_submit"
                  :disabled="formSubmitting"
                  @click="approvalAction('reject')"
                >
                  <mdb-icon
                    icon="file-upload"
                    size="md"
                    v-show="!formSubmitting"
                  />

                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="formSubmitting"
                  ></span>
                  {{ $t("general.button.cancel") }}
                </mdb-btn>
              </mdb-col>
            </mdb-row>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
  </div>
</template>
<script>
import Attendance from "@/apis/Attendance";

import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import ApprovedUserLayout from "@/components/ApprovedUserLayout";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  // mdbCardTitle,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
} from "mdbvue";

export default {
  name: "ApprovalForm",
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    EmployeeInfoBoxLayout,
    ApprovedUserLayout,
  },
  props: ["_id"],
  data() {
    return {
      attendance: {},
      approve_user: [],
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
      selectedEmployeeData: false,
      approval_description: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    approvalAction(status_val) {
      this.formSubmitting = true;

      Attendance.submitApproval({
        oal_id: this.attendance.oal_id,
        status: status_val,
        description: this.approval_description,
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            this.formSubmitting = false;
            this.$router.push({ name: "listAttendanceApproval" });
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }
        }
      });
    },

    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          Attendance.getApprovalDetail({ oal_id: this._id })
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  this.attendance = response.data.data;
                  this.approve_user = response.data.approve_user;

                  this.selectedEmployeeData = {
                    epinfo_name: this.attendance.employee_name,
                    jp_name: this.attendance.employee_position,
                    department_name: this.attendance.employee_department,
                    profile_image: this.attendance.employee_image,
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listAttendanceApproval" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listAttendanceApproval" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listAttendanceApproval" });
        }
      } else {
        // add
      }
    },

    redirectBackAction() {
      this.$router.push({
        name: "listAttendanceApproval",
      });
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>