<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>
    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label>{{
                    $t(
                      "employee_attendance_policy.formInput.required_attendance"
                    )
                  }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="option-required_attendance-yes"
                      name="required_attendance"
                      radioValue="yes"
                      v-model="attendancePolicy.ap_required_attendance"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="option-required_attendance-no"
                      name="required_attendance"
                      radioValue="no"
                      v-model="attendancePolicy.ap_required_attendance"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    :label="$t('general.formInput.from_date')"
                    v-model="attendancePolicy.ap_from_date"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-date-picker-2
                    :label="$t('general.formInput.to_date')"
                    v-model="attendancePolicy.ap_to_date"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col
                  class="mb-3"
                  offsetMd="2"
                  md="8"
                  v-if="attendancePolicy.ap_required_attendance == 'yes'"
                >
                  <label>{{
                    $t("employee_attendance_policy.formInput.ap_type")
                  }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="option-ap_type-static"
                      name="ap_type"
                      radioValue="static"
                      v-model="attendancePolicy.ap_type"
                      :label="$t('general.formInput.static')"
                    />
                    <mdb-input
                      type="radio"
                      id="option-ap_type-dynamic"
                      name="ap_type"
                      radioValue="dynamic"
                      v-model="attendancePolicy.ap_type"
                      :label="$t('general.formInput.dynamic')"
                    />
                  </mdb-form-inline>
                </mdb-col>

                <mdb-col
                  offsetMd="2"
                  md="4"
                  v-if="
                    attendancePolicy.ap_required_attendance == 'yes' &&
                    attendancePolicy.ap_type == 'static'
                  "
                >
                  <AttendancePolicyDropdown
                    @updateAttendancePolicyID="setAttendancePolicyID"
                    :default_selected_value="attendancePolicy.ap_hr_ap_id"
                    :required="true"
                    :resetSelected="doesFormReset"
                    v-if="!isLoading"
                  />
                </mdb-col>
              </mdb-row>
              <mdb-row
                v-if="
                  attendancePolicy.ap_required_attendance == 'yes' 
                "
              >
                <mdb-col offsetMd="2" md="8">
                  <label
                    >Required to take attendance in assignment location</label
                  >
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="option-no"
                      radioValue="no"
                      v-model="location_required"
                      label="No"
                    />
                    <mdb-input
                      type="radio"
                      id="option-yes"
                      radioValue="yes"
                      v-model="location_required"
                      label="Yes"
                    />
                  </mdb-form-inline>
                </mdb-col>

                <mdb-col
                  offsetMd="2"
                  md="8"
                  v-if="location_required == 'yes'"
                >
                  <AttendanceLocationDropdown
                    @updateSelectedAttendanceLocationIDs="
                      setSelectedAttendanceLocationIds
                    "
                    :default_selected_value="attendancePolicy.ap_allow_location"
                    :key="counter"
                    :resetSelected="doesFormReset"
                  />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col
                  offsetMd="2"
                  md="8"
                  class="mt-4"
                  v-if="
                    attendancePolicy.ap_required_attendance == 'yes' &&
                    attendancePolicy.ap_type == 'dynamic'
                  "
                >
                  <label> Holiday Pay Rate </label>
                  <mdb-input
                    type="radio"
                    id="option-pay_rate-normal_rate"
                    name="ap_holiday_pay_rate"
                    radioValue="normal_rate"
                    v-model="attendancePolicy.ap_holiday_pay_rate"
                    label="Normal Rate"
                  />
                  <mdb-input
                    type="radio"
                    id="option-pay_rate-ot_rate_attendance_time"
                    name="ap_holiday_pay_rate"
                    radioValue="ot_rate_attendance_time"
                    v-model="attendancePolicy.ap_holiday_pay_rate"
                    label="Depend on Attendance Time"
                  />

                  <mdb-input
                    type="radio"
                    id="option-pay_rate-ot_rate_schedule_time"
                    name="ap_holiday_pay_rate"
                    radioValue="ot_rate_schedule_time"
                    v-model="attendancePolicy.ap_holiday_pay_rate"
                    label="Depend on Schedule Time"
                  />
                </mdb-col>

                <mdb-col
                  offsetMd="2"
                  md="4"
                  v-if="
                    attendancePolicy.ap_required_attendance == 'yes' &&
                    attendancePolicy.ap_type == 'dynamic' &&
                    attendancePolicy.ap_holiday_pay_rate == 'ot_rate_attendance_time'
                  "
                >
                  <mdb-input
                    type="number"
                    label="Break Time (in Minutes)"
                    v-model="attendancePolicy.ap_break_time"
                    outline
                  />
                </mdb-col>
              </mdb-row>
              <br />
              <mdb-row
                v-if="
                  attendancePolicy.ap_required_attendance == 'yes' &&
                  attendancePolicy.ap_type == 'dynamic' &&
                  (attendancePolicy.ap_holiday_pay_rate ==
                    'ot_rate_attendance_time' ||
                    attendancePolicy.ap_holiday_pay_rate ==
                      'ot_rate_schedule_time')
                "
              >
                <mdb-col offsetMd="2" md="8">
                  <label> Rate Hour Type </label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="ap_rate_type-schedule_hours"
                      name="ap_rate_type"
                      radioValue="schedule_hours"
                      v-model="attendancePolicy.ap_rate_type"
                      label="Schedule Hour"
                    />
                    <mdb-input
                      type="radio"
                      id="ap_rate_type-custom_fix_hours"
                      name="ap_rate_type"
                      radioValue="custom_fix_hours"
                      v-model="attendancePolicy.ap_rate_type"
                      label="Custom Define Hours"
                    />
                  </mdb-form-inline>
                </mdb-col>
                <mdb-col
                  offsetMd="2"
                  md="4"
                  v-if="attendancePolicy.ap_rate_type == 'custom_fix_hours'"
                >
                  <mdb-input
                    type="number"
                    label="Hours"
                    v-model="attendancePolicy.ap_rate_hours"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <br />
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import EmployeeAttendancePolicy from "@/apis/EmployeeAttendancePolicy";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import AttendancePolicyDropdown from "@/components/AttendancePolicyDropdown";

import AttendanceLocationDropdown from "@/components/AttendanceLocationDropdown";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbDatePicker2,
    mdbFormInline,
    ModalDeleteConfimation,
    AttendancePolicyDropdown,
    AttendanceLocationDropdown,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      attendancePolicy: {
        ap_id: "",
        ap_employee_id: this.employee_id != "undefined" ? this.employee_id : "",
        ap_required_attendance: "yes",
        ap_type: "static",
        ap_from_date: "",
        ap_to_date: "",
        ap_hr_ap_id: "",
        ap_holiday_pay_rate: "",
        ap_break_time: "",
        ap_rate_type: "",
        ap_rate_hours: "",
        ap_allow_location: [],
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,

      location_required: "no",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.attendancePolicy.ap_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    setSelectedAttendanceLocationIds(val) {
      this.attendancePolicy.ap_allow_location = val;
    },

    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    setAttendancePolicyID(val) {
      this.attendancePolicy.ap_hr_ap_id = val;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          EmployeeAttendancePolicy.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)

                  let ap_id,
                    ap_employee_id,
                    ap_required_attendance,
                    ap_type,
                    ap_from_date,
                    ap_to_date,
                    ap_hr_ap_id,
                    ap_holiday_pay_rate,
                    ap_break_time,
                    ap_rate_type,
                    ap_rate_hours,
                    ap_allow_location;
                  // populate field
                  ({
                    ap_id,
                    ap_employee_id,
                    ap_required_attendance,
                    ap_type,
                    ap_from_date,
                    ap_to_date,
                    ap_hr_ap_id,
                    ap_holiday_pay_rate,
                    ap_break_time,
                    ap_rate_type,
                    ap_rate_hours,
                    ap_allow_location,
                  } = response.data.data);
                  this.attendancePolicy.ap_id = ap_id;
                  this.attendancePolicy.ap_employee_id = ap_employee_id;
                  this.attendancePolicy.ap_required_attendance =
                    ap_required_attendance == "1" ? "yes" : "no";
                  this.attendancePolicy.ap_type = ap_type;
                  this.attendancePolicy.ap_from_date = ap_from_date;
                  this.attendancePolicy.ap_to_date = ap_to_date;
                  this.attendancePolicy.ap_hr_ap_id = ap_hr_ap_id;
                  this.attendancePolicy.ap_holiday_pay_rate =
                    ap_holiday_pay_rate;
                  this.attendancePolicy.ap_break_time = ap_break_time;

                  this.attendancePolicy.ap_rate_type = ap_rate_type;
                  this.attendancePolicy.ap_rate_hours = ap_rate_hours;

                  this.attendancePolicy.ap_allow_location = ap_allow_location;

                  if (ap_allow_location.length > 0) {
                    this.location_required = "yes";
                  }

                  //make selected option again

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    employeeAttendancePolicyList: {
                      _id: this.attendancePolicy.ap_employee_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "employeeAttendancePolicyList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "employeeAttendancePolicyList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "employeeAttendancePolicyList" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      if (this.attendancePolicy.ap_required_attendance == "no") {
        delete this.attendancePolicy.ap_type;
      }

      if (this.location_required == "no") {
        this.attendancePolicy.ap_allow_location = [];
      }

      if(this.attendancePolicy.ap_holiday_pay_rate != 'ot_rate_attendance_time'){
        this.attendancePolicy.ap_break_time = "0";
      }

      EmployeeAttendancePolicy.save(this.attendancePolicy).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.attendancePolicy.ap_id != "") {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({
                name: "employeeAttendancePolicyList",
                params: { employee_id: this.attendancePolicy.ap_employee_id },
              });
            } else {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.resetForm();
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({
        name: "employeeAttendancePolicyList",
        params: { employee_id: this.attendancePolicy.ap_employee_id },
      });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.attendancePolicy).forEach((key) => {
        this.attendancePolicy[key] = "";
      });
      this.attendancePolicy.ap_employee_id = this.employee_id;
      this.attendancePolicy.ap_required_attendance = "yes";
      this.attendancePolicy.ap_type = "static";

      this.attendancePolicy.ap_allow_location = [];
      this.location_required = "no";

      this.doesFormReset = !this.doesFormReset;
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.attendancePolicy.ap_id > 0) {
        EmployeeAttendancePolicy.delete({
          ap_id: this.attendancePolicy.ap_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              this.$router.push({
                name: "employeeAttendancePolicyList",
                params: {
                  _id: this.attendancePolicy.ap_employee_id,
                },
              });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      employeeAttendancePolicyList: {
        employee_id: this.attendancePolicy.ap_employee_id,
      },
    };
    this.fetchRecord();
  },
};
</script>