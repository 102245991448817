<template>
  <div class="body-wrapper">
    <!--Navbar-->
    <mdb-navbar color="uno-primary" dark position="top">
      <mdb-navbar-brand href="#">
        <img
          src="@/assets/Logo.svg"
          alt="UNO HR"
          height="40"
          width="113"
        />
      </mdb-navbar-brand>
      <mdb-navbar-toggler>
        <mdb-navbar-nav left>
          <mdb-nav-item :to="{ name: 'dashboard' }" v-can="['dashboard']"
            >Dashboard</mdb-nav-item
          >

          <mdb-nav-item
            :to="{ name: 'listAnnouncement' }"
            v-can="['announcement_management']"
            >Announcements</mdb-nav-item
          >

          <!-- Report -->
          <mdb-dropdown
            tag="li"
            megaMenu
            class="nav-item"
            v-can="[
              'employee_view',
              'employee_management',
              'attendance_view',
              'attendance_management',
              'leave_view',
              'leave_management',
              'overtime_view',
              'overtime_management',
              'payroll_manage',
            ]"
          >
            <mdb-dropdown-toggle
              slot="toggle"
              tag="a"
              transparent
              navLink
              waves-fixed
              v-can="[
              'employee_view',
              'employee_management',
              'attendance_view',
              'attendance_management',
              'leave_view',
              'leave_management',
              'overtime_view',
              'overtime_management',
              'payroll_manage',
            ]"
              >Core HR</mdb-dropdown-toggle
            >
            <mdb-dropdown-menu
              class="mega-menu v-2 z-depth-1 primary-color-dark py-5 px-3"
            >
              <div class="row">
                <div
                  class="col-md-5 col-xl-4 sub-menu mb-xl-0 mb-5"
                  v-can="['employee_view', 'employee_management']"
                >
                  <h6
                    class="
                      sub-title
                      text-uppercase
                      font-weight-bold
                      menu-text-color
                    "
                  >
                    {{ $t("menu.core_hr.employees") }}
                  </h6>
                  <ul class="list-unstyled">
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listEmployee' }"
                      >
                        {{ $t("menu.core_hr.listEmployees") }}
                      </router-link>
                    </li>

                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'contractManageList' }"
                      >
                        {{ $t("menu.core_hr.listContracts") }}
                      </router-link>
                    </li>

                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'workingHistoryManageList' }"
                      >
                        {{ $t("menu.core_hr.listWorkingHistory") }}
                      </router-link>
                    </li>

                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'actionsManageList' }"
                      >
                        {{ $t("menu.core_hr.listActions") }}
                      </router-link>
                    </li>

                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'additionsManageList' }"
                      >
                        {{ $t("menu.core_hr.listOtherAdditions") }}
                      </router-link>
                    </li>

                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'deductionsManageList' }"
                      >
                        {{ $t("menu.core_hr.listOtherDeductions") }}
                      </router-link>
                    </li>

                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listHrTraining' }"
                      >
                        Training
                      </router-link>
                    </li>


                    
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listISO' }"
                      >
                        ISO 
                      </router-link>
                    </li>

                    <!--                     

                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listEmployeeActivity' }"
                      >
                        Activity List
                      </router-link>
                    </li> -->
                  </ul>

                  <br />

                  <!--                   
                  <div v-can="['training_view', 'training_management']">
                  <h6
                    class="
                      sub-title
                      text-uppercase
                      font-weight-bold
                      menu-text-color
                    "
                  >
                    Training
                  </h6>
                  <ul class="list-unstyled">
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listHrTraining' }"
                      >
                        Training Management
                      </router-link>
                    </li>
                  </ul>
                  </div> -->
                </div>

                <div class="col-md-5 col-xl-4 sub-menu mb-xl-0 mb-5">
                  <div v-can="['attendance_view', 'attendance_management']">
                    <h6
                      class="
                        sub-title
                        text-uppercase
                        font-weight-bold
                        menu-text-color
                      "
                    >
                      {{ $t("menu.core_hr.attendances") }}
                    </h6>
                    <ul class="list-unstyled">
                      <!-- <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listManageAttendance' }"
                        >
                        Attendance Management
                      </router-link>
                    </li> -->
                      <li class="">
                        <router-link
                          class="menu-item-text-color menu-item pl-1 mt-2"
                          :to="{ name: 'listManageAttendances' }"
                          >{{ $t("menu.core_hr.listAttendances") }}</router-link
                        >
                      </li>

                      <li class="">
                        <router-link
                          class="menu-item-text-color menu-item pl-1 mt-2"
                          :to="{ name: 'createManualDutyAssignment' }"
                          >{{ $t("menu.core_hr.dutyAssignment") }}
                        </router-link>
                      </li>
                      <li class="">
                        <router-link
                          class="menu-item-text-color menu-item pl-1 mt-2"
                          :to="{ name: 'listPendingAttendance' }"
                          >{{
                            $t("menu.core_hr.pendingAttendance")
                          }}</router-link
                        >
                      </li>
                      <li class="">
                        <router-link
                          class="menu-item-text-color menu-item pl-1 mt-2"
                          :to="{ name: 'fixedAttendanceReport' }"
                          >{{
                            $t("menu.core_hr.listFixingAttendance")
                          }}</router-link
                        >
                      </li>

                      <li class="">
                        <router-link
                          class="menu-item-text-color menu-item pl-1 mt-2"
                          :to="{ name: 'checkMonthlyAttendanceList' }"
                        >
                          Check Monthly Attendance
                        </router-link>
                      </li>
                    </ul>
                    <br />
                  </div>

                  <div v-can="['leave_view', 'leave_management']">
                    <h6
                      class="
                        sub-title
                        text-uppercase
                        font-weight-bold
                        menu-text-color
                      "
                    >
                      {{ $t("menu.core_hr.leaves") }}
                    </h6>
                    <ul class="list-unstyled">
                      <li class="">
                        <router-link
                          class="menu-item-text-color menu-item pl-1 mt-2"
                          :to="{ name: 'listManageLeaves' }"
                          >{{ $t("menu.core_hr.listLeaves") }}</router-link
                        >
                      </li>
                      <li class="">
                        <router-link
                          class="menu-item-text-color menu-item pl-1 mt-2"
                          :to="{ name: 'listPendingLeaves' }"
                          >{{ $t("menu.core_hr.pendingLeaves") }}</router-link
                        >
                      </li>
                    </ul>
                    <br />
                  </div>
                </div>

                <div class="col-md-5 col-xl-4 sub-menu mb-xl-0 mb-5">
                  <div v-can="['overtime_view', 'overtime_management']">
                    <h6
                      class="
                        sub-title
                        text-uppercase
                        font-weight-bold
                        menu-text-color
                      "
                    >
                      {{ $t("menu.core_hr.overtime") }}
                    </h6>
                    <ul class="list-unstyled">
                      <li class="">
                        <router-link
                          class="menu-item-text-color menu-item pl-1 mt-2"
                          :to="{ name: 'listManageOvertime' }"
                          >{{ $t("menu.core_hr.listOvertime") }}</router-link
                        >
                      </li>
                      <li class="">
                        <router-link
                          class="menu-item-text-color menu-item pl-1 mt-2"
                          :to="{ name: 'listPendingOvertime' }"
                          >{{ $t("menu.core_hr.pendingOvertime") }}</router-link
                        >
                      </li>
                    </ul>
                    <br />
                  </div>
                  <div v-can="['payroll_manage']">
                    <h6
                      class="
                        sub-title
                        text-uppercase
                        font-weight-bold
                        menu-text-color
                      "
                    >
                      Payroll
                    </h6>
                    <ul class="list-unstyled">
                      <li class="">
                        <router-link
                          class="menu-item-text-color menu-item pl-1 mt-2"
                          :to="{ name: 'createPayroll' }"
                        >
                          {{ $t("menu.core_hr.createPayroll") }}</router-link
                        >
                      </li>
                      <li class="">
                        <router-link
                          class="menu-item-text-color menu-item pl-1 mt-2"
                          :to="{ name: 'payrollRequestQueueList' }"
                        >
                          {{
                            $t("menu.core_hr.payrollQueueResult")
                          }}</router-link
                        >
                      </li>
                      
                      <li class="">
                        <router-link
                          class="menu-item-text-color menu-item pl-1 mt-2"
                          :to="{ name: 'payrollPublishing' }"
                        >
                          Payroll Publishing</router-link
                        >
                      </li>
                      <li class="">
                        <router-link
                          class="menu-item-text-color menu-item pl-1 mt-2"
                          :to="{ name: 'payrollList' }"
                        >
                          Payroll List</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </mdb-dropdown-menu>
          </mdb-dropdown>

          <!-- Report -->
          <mdb-dropdown
            tag="li"
            megaMenu
            class="nav-item"
            v-can="['web_approvals']"
          >
            <mdb-dropdown-toggle
              slot="toggle"
              tag="a"
              transparent
              navLink
              waves-fixed
              >{{ $t("menu.approval.title") }}</mdb-dropdown-toggle
            >
            <mdb-dropdown-menu
              class="mega-menu v-2 z-depth-1 primary-color-dark py-5 px-3"
            >
              <div class="row">
                <div class="col-md-5 col-xl-4 sub-menu mb-xl-0 mb-5">
                  <h6
                    class="
                      sub-title
                      text-uppercase
                      font-weight-bold
                      menu-text-color
                    "
                  >
                    {{ $t("menu.approval.title") }}
                  </h6>
                  <ul class="list-unstyled">
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listAttendanceApproval' }"
                        >{{ $t("menu.approval.attendance") }}</router-link
                      >
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listLeaveApproval' }"
                        >{{ $t("menu.approval.leaves") }}</router-link
                      >
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listOvertimeApproval' }"
                        >{{ $t("menu.approval.overtime") }}</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </mdb-dropdown-menu>
          </mdb-dropdown>

          <!-- Report -->
          <mdb-dropdown
            tag="li"
            megaMenu
            class="nav-item"
            v-can="[
              'late_report',
              'leave_taken_balance',
              'account_report',
              'payslips_report',
              'bank_report',
              'tax_report',
              'ssb_report',
              'special_report',
            ]"
          >
            <mdb-dropdown-toggle
              slot="toggle"
              tag="a"
              transparent
              navLink
              waves-fixed
              >Report</mdb-dropdown-toggle
            >
            <mdb-dropdown-menu
              class="mega-menu v-2 z-depth-1 primary-color-dark py-5 px-3"
            >
              <div class="row">
                <div
                  class="col-md-6 col-xl-3 sub-menu mb-xl-0 mb-5"
                  v-can="['late_report', 'leave_taken_balance']"
                >
                  <h6
                    class="
                      sub-title
                      text-uppercase
                      font-weight-bold
                      menu-text-color
                    "
                  >
                    {{ $t("menu.report.general_report") }}
                  </h6>
                  <ul class="list-unstyled">

                    <!-- <li class="" v-can="['document_download']"> -->
                    
                      <!-- <li class="" >
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'documentDownloadReport' }"
                        >{{
                          $t("menu.report.documentDownloadReport")
                        }}</router-link
                      >
                    </li> -->

                    <li class="" v-can="['special_report']" >
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'managementReport' }"
                        >{{ $t("menu.report.management_report") }}</router-link
                      >
                    </li>

                    <li class="" v-can="['special_report']" >
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'employeeUsageReport' }"
                        >{{ $t("menu.report.employeeUsageReport") }}</router-link
                      >
                    </li>

                    <li class="" v-can="['late_report']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'attendanceLateEarlyReport' }"
                        >{{ $t("menu.report.late_report") }}</router-link
                      >
                    </li>
                    <!-- <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'fixedAttendanceReport' }"
                        >{{
                          $t("menu.report.fixing_attendance_report")
                        }}</router-link
                      >
                    </li> -->

                    <li class="" v-can="['leave_taken_balance']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'leaveTakenBalanceReport' }"
                        >{{
                          $t("menu.report.leave_taken_balance_report")
                        }}</router-link
                      >
                    </li>

                    <li class="" v-can="['leave_taken_balance']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'summaryLeaveTakenBalanceReport' }"
                        >Summary Leave Taken/ Balance Report</router-link
                      >
                    </li>

                    <li class="" v-can="['leave_taken_balance']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'currentLeaveReport' }"
                        >Current Leave Report</router-link
                      >
                    </li>

                    <li class="" v-can="['leave_taken_balance']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'newHiringReport' }"
                        >New Hiring Report</router-link
                      >
                    </li>


                    <li class="" v-can="['general_report']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'fixedAttendanceUserReport' }"
                        >Fixed Attendance User Report</router-link
                      >
                    </li>

                    
                    <li class="" v-can="['general_report']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'workingHistoryReport' }"
                        >Working History Report</router-link
                      >
                    </li>

                    <li class="" v-can="['general_report']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'additionReport' }"
                        >Other Addition Report</router-link
                      >
                    </li>


                    <li class="" v-can="['general_report']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'attendancePolicyReport' }"
                        >Attendance Policy Report</router-link
                      >
                    </li>
                    <!-- <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'dashboard' }"
                        >{{ $t("menu.report.overtime_report") }}</router-link
                      >
                    </li> -->
                  </ul>
                </div>


                <!-- //////////////////////////////////////////////////////// -->
                <div
                  class="col-md-6 col-xl-3 sub-menu mb-xl-0 mb-5"
                >
                  <h6
                    class="
                      sub-title
                      text-uppercase
                      font-weight-bold
                      menu-text-color
                    "
                  >
                    {{ $t("menu.report.general_report") }}
                  </h6>
                  <ul class="list-unstyled">
                    <li class="" v-can="['special_report']" >
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'employeesReport' }"
                        >Employees Report</router-link
                      >
                    </li>
                    <li class="" v-can="['special_report']" >
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'permanentEmployeeReport' }"
                        >Permanent Employees Report</router-link
                      >
                    </li>
                    <li class="" v-can="['special_report']" >
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'employeeTimeTableReport' }"
                        >Employee's Timetable Report</router-link
                      >
                    </li>
                    <li class="" v-can="['special_report']" >
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'employeeTimeTableMenpowerReport' }"
                        >Menpower Duty Roster Report</router-link
                      >
                    </li>
                    <li class="" v-can="['special_report']" >
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'staffMovementReport' }"
                        >Employee Movement Report</router-link
                      >
                    </li>
                    <li class="" v-can="['special_report']" >
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'separationReport' }"
                        >Separation Report</router-link
                      >
                    </li>
                    <li class="" v-can="['special_report']" >
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'salarybyEachDepartmentsReport' }"
                        >Salary Report</router-link
                      >
                    </li>

                    
                    
                    <li class="" v-can="['general_report']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'employeeTrainingReport' }"
                        >{{ $t("menu.report.employee_training_report") }}</router-link
                      >
                    </li>


                    
                    
                    <li class="" v-can="['general_report']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'employeeEducationReport' }"
                        >{{ $t("menu.report.employee_education_report") }}</router-link
                      >
                    </li>
                    
                    <li class="" v-can="['general_report']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'deductionReport' }"
                        >Other Deduction Report</router-link
                      >
                    </li>
                  </ul>
                </div>
                <!-- //////////////////////////////////////////////////////// -->

                <div
                  class="col-md-6 col-xl-3 sub-menu mb-xl-0 mb-5"
                  v-can="[
                    'account_report',
                    'payslips_report',
                    'bank_report',
                    'tax_report',
                    'ssb_report',
                  ]"
                >
                  <h6
                    class="
                      sub-title
                      text-uppercase
                      font-weight-bold
                      menu-text-color
                    "
                  >
                    {{ $t("menu.report.payroll_report") }}
                  </h6>
                  <ul class="list-unstyled">
                    <li class="" v-can="['account_report', 'payslips_report']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'accountReport' }"
                        >{{ $t("menu.report.account_report") }}</router-link
                      >
                    </li>
                    <li class="" v-can="['bank_report']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'bankReport' }"
                        >{{ $t("menu.report.bank_report") }}</router-link
                      >
                    </li>
                    <li class="" v-can="['tax_report']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'taxReport' }"
                        >{{ $t("menu.report.tax_report") }}</router-link
                      >
                    </li>
                    <li class="" v-can="['ssb_report']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'ssbReport' }"
                        >{{ $t("menu.report.ssb_report") }}</router-link
                      >
                    </li>

                    
                    <li class="" v-can="['special_report']">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'overtimeReport' }"
                        >{{ $t("menu.report.overtime_report") }}</router-link
                      >
                    </li>


                  </ul>
                </div>

                <div
                  class="col-md-6 col-xl-3 sub-menu mb-xl-0 mb-5"
                  v-can="['special_report']"
                >
                  <h6
                    class="
                      sub-title
                      text-uppercase
                      font-weight-bold
                      menu-text-color
                    "
                  >
                    {{ $t("menu.report.special_report") }}
                  </h6>
                  <ul class="list-unstyled">
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'executiveReport' }"
                        >{{ $t("menu.report.executive_summary") }}</router-link
                      >
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'salaryReport' }"
                        >{{ $t("menu.report.salary_summary") }}</router-link
                      >
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'headCountReport' }"
                        >{{ $t("menu.report.head_count") }}</router-link
                      >
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'actionsReport' }"
                        >{{ $t("menu.report.actions") }}</router-link
                      >
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'leavesReport' }"
                        >{{ $t("menu.report.leaves") }}</router-link
                      >
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'turnoverReport' }"
                        >{{ $t("menu.report.turnover") }}</router-link
                      >
                    </li>

                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'trainingReport' }"
                        >{{ $t("menu.report.training") }}</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </mdb-dropdown-menu>
          </mdb-dropdown>

          <mdb-nav-item :to="{ name: 'imports' }" v-can="['imports']"
            >Imports</mdb-nav-item
          >

          <!-- Settings -->
          <!--      
          <div v-can="['setting']">it should fsadf sadf</div> -->

          <mdb-dropdown tag="li" megaMenu class="nav-item" v-can="['setting']">
            <mdb-dropdown-toggle
              slot="toggle"
              tag="a"
              transparent
              navLink
              waves-fixed
              >{{ $t("menu.settings.config") }}</mdb-dropdown-toggle
            >
            <mdb-dropdown-menu
              class="mega-menu v-2 z-depth-1 primary-color-dark py-5 px-3"
            >
              <div class="row">
                <div class="col-md-5 col-xl-3 sub-menu mb-xl-0 mb-5">
                  <h6
                    class="
                      sub-title
                      text-uppercase
                      font-weight-bold
                      menu-text-color
                    "
                  >
                    {{ $t("menu.settings.general_config") }}
                  </h6>
                  <ul class="list-unstyled">
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listHoliday' }"
                      >
                        {{ $t("menu.settings.holidays") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listDocumentType' }"
                      >
                        {{ $t("menu.settings.document_types") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'accountReportConfiguration' }"
                      >
                        {{ $t("menu.settings.account_report_configuration") }}
                      </router-link>
                    </li>
                  </ul>
                </div>

                <div class="col-md-5 col-xl-3 sub-menu mb-xl-0 mb-5">
                  <h6
                    class="
                      sub-title
                      text-uppercase
                      font-weight-bold
                      menu-text-color
                    "
                  >
                    {{ $t("menu.settings.company_config") }}
                  </h6>
                  <ul class="list-unstyled">
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listCompanyInfo' }"
                      >
                        {{ $t("menu.settings.company_info") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listCompanyBranch' }"
                      >
                        {{ $t("menu.settings.company_branch") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listDepartment' }"
                      >
                        {{ $t("menu.settings.departments") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listTransactionType' }"
                      >
                        {{ $t("menu.settings.transactions") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listContractStatus' }"
                      >
                        {{ $t("menu.settings.contract_status") }}
                      </router-link>
                    </li>



                    
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listApprovalGroup' }"
                      >
                        {{ $t("menu.settings.approvalGroup") }}
                      </router-link>
                    </li>


                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'approvalWorkflow' }"
                      >
                        {{ $t("menu.settings.approvalWorkflow") }}
                      </router-link>
                    </li>

                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listPermission' }"
                      >
                        {{ $t("menu.settings.listPermission") }}
                      </router-link>
                    </li>

                    

                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listAttendanceLocation' }"
                      >
                        Attendance Location
                      </router-link>
                    </li>
                  </ul>
                </div>

                <div class="col-md-5 col-xl-3 sub-menu mb-xl-0 mb-5">
                  <h6
                    class="
                      sub-title
                      text-uppercase
                      font-weight-bold
                      menu-text-color
                    "
                  >
                    {{ $t("menu.settings.employee_config") }}
                  </h6>
                  <ul class="list-unstyled">
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listPosition' }"
                      >
                        {{ $t("menu.settings.position") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listRank' }"
                      >
                        {{ $t("menu.settings.rank") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listEmployeeCategory' }"
                      >
                        {{ $t("menu.settings.employee_category") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listEmployeeGroup' }"
                      >
                        {{ $t("menu.settings.employee_group") }}
                      </router-link>
                    </li>
                  </ul>
                </div>

                <div class="col-md-5 col-xl-3 sub-menu mb-xl-0 mb-5">
                  <h6
                    class="
                      sub-title
                      text-uppercase
                      font-weight-bold
                      menu-text-color
                    "
                  >
                    {{ $t("menu.settings.rule_n_policy_config") }}
                  </h6>
                  <ul class="list-unstyled">
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listWorkingHours' }"
                      >
                        {{ $t("menu.settings.working_hours") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listAttendanceRule' }"
                      >
                        {{ $t("menu.settings.attendance_rule") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listAttendancePolicy' }"
                      >
                        {{ $t("menu.settings.attendance_policy") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listLateDeductionPolicy' }"
                      >
                        {{ $t("menu.settings.late_deduction_policy") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listAllowanceType' }"
                      >
                        {{ $t("menu.settings.allowance_type") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listAllowanceRule' }"
                      >
                        {{ $t("menu.settings.allowance_rule") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listLeaveType' }"
                      >
                        {{ $t("menu.settings.leave_types") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listLeaveRule' }"
                      >
                        {{ $t("menu.settings.leave_rule") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listOvertimeRule' }"
                      >
                        {{ $t("menu.settings.overtime_rule") }}
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'listActionType' }"
                      >
                        {{ $t("menu.settings.action_type") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </mdb-dropdown-menu>
          </mdb-dropdown>
        </mdb-navbar-nav>
        <mdb-navbar-nav right class="mr-5">
          <!-- 
          <div class="lang_hide">
            <mdb-dropdown class="navbar-nav nav-flex-icons lang_dropdown">
              <mdb-dropdown-toggle slot="toggle" class="navlink">English</mdb-dropdown-toggle>
              <mdb-dropdown-menu>
                <mdb-dropdown-item @click="swithLang('en')">
                  <img
                    src="@/assets/english.png"
                    alt="triangle with all three sides equal"
                    height="18"
                    width="28"
                  />
                  English
                </mdb-dropdown-item>
                <mdb-dropdown-item @click="swithLang('my')">
                  <img
                    src="@/assets/myanmar.jpg"
                    alt="triangle with all three sides equal"
                    height="18"
                    width="28"
                  />
                  Myanmar</mdb-dropdown-item
                >
              </mdb-dropdown-menu>
            </mdb-dropdown>
          </div> 
          -->

          <mdb-nav-item @click="handleLogout" class="logout_btn"
            >Logout</mdb-nav-item
          >

          <hr class="lang_top_border" />

          <div class="lang_show">
            <mdb-nav-item>
              <mdb-btn>
                <img
                  src="@/assets/english.png"
                  alt="triangle with all three sides equal"
                  height="18"
                  width="28"
                />
                English
              </mdb-btn>
              <mdb-btn>
                <img
                  src="@/assets/myanmar.jpg"
                  alt="triangle with all three sides equal"
                  height="18"
                  width="28"
                />
                Myanmar
              </mdb-btn>
            </mdb-nav-item>
          </div>

          <!-- <mdb-dropdown class="navbar-nav nav-flex-icons">          
          <mdb-dropdown-toggle slot="toggle" icon="user"> Admin</mdb-dropdown-toggle>
          <mdb-dropdown-menu>
            <mdb-dropdown-item>My Profile</mdb-dropdown-item>
            <mdb-dropdown-item to="/"
            waves-fixed
            far            
            @click="handleLogout">Logout</mdb-dropdown-item>                        
          </mdb-dropdown-menu>
        </mdb-dropdown> -->

          <!-- Links -->
          <!-- <img
          src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img%20(132).jpg"
          class="rounded-circle mt-1 ml-3"
          width="30"
          height="30"          
        /> -->
        </mdb-navbar-nav>
      </mdb-navbar-toggler>
    </mdb-navbar>
    <!--/.Navbar-->

    <mdb-container>
      <div class="main-container">
        <div class="page_title">{{ $t(getPageTitle) }}</div>

        <Breadcrumb
          :crumbs="getCrumbs"
          :crubmParams="getCrumbsParams"
          @selectedCrumb="selectedCrumb"
        />

        <!-- {{$t('message')}} -->
        <router-view></router-view>
        <footer class="my-5 text-center">
          Copyright © 2021 UNO HR. All Rights Reserved.
        </footer>
      </div>
    </mdb-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import i18n from "@/i18n";

import Breadcrumb from "@/components/Breadcrumb.vue";
import {
  mdbContainer,
  // mdbRow,
  // mdbIcon,
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
  // mdbInput,
  mdbBtn,
  mdbDropdown,
  mdbDropdownToggle,
  mdbDropdownMenu,
  // mdbDropdownItem,
} from "mdbvue";

export default {
  name: "DashboardLayout",
  components: {
    Breadcrumb,
    mdbContainer,
    // mdbRow,
    // mdbIcon,
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    // mdbInput,
    mdbBtn,
    mdbDropdown,
    mdbDropdownToggle,
    mdbDropdownMenu,
    // mdbDropdownItem,
  },
  data() {
    return {};
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    i18n.locale = this.$store.state.locale;
    // console.log(localStorage.getItem("user_permission"));
  },
  computed: {
    ...mapGetters(["isLoading"]),
    ...mapGetters("user", ["loggedIn"]),
    ...mapGetters(["getCrumbs", "getCrumbsParams", "getPageTitle"]),
  },
  methods: {
    ...mapActions(["swithLangAction"]),

    selectedCrumb(crumb) {
      console.log(crumb);
    },

    swithLang(lang) {
      localStorage.setItem("locale", lang);
      this.swithLangAction(lang);
      i18n.locale = this.$store.state.locale;
    },
    handleLogout() {
      localStorage.removeItem("organization");
      localStorage.removeItem("token");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("user_permission");
      localStorage.removeItem("user_permission_rank");
      this.$store.state.user.user = null;
      this.$store.state.user.is_admin = null;
      this.$store.state.user.token = null;
      this.$store.state.user.organization = null;
      this.$store.state.user.user_permission = [];
      this.$store.state.user.user_permission_rank = [];


      this.$router.push("/login");
    },
  },
};
</script>

<style lang="css" scoped>
.btn {
  box-shadow: none !important;
  color: white !important;
  padding: 10px !important;
  text-transform: none !important;
  font-size: 14px !important;
}

.btn-default {
  background: none !important;
}

.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #e5e9ee !important;
  color: blue !important;
  box-shadow: none !important;
}
.dropdown-menu {
  min-width: 9rem !important;
}
.logout_btn {
  padding: 2px 0;
}
</style>
