<template>
  <div>
    <mdb-select
    outline
      search
      v-model="axiosOptions"
      :label="selectLabel"
      @getValue="updateSelectedValue"
      :disabled="loading"
    />
  </div>
</template>

<script>
import { mdbSelect } from "mdbvue";
import EmployeeGroup from "@/apis/EmployeeGroup";
export default {
  name: "EmployeeGroupDropdown",
  components: {
    mdbSelect,
  },
  props: ["default_selected_value", "required","resetSelected"],
  data() {
    return {
      axiosOptions: [],
      loading:true,
      selectLabel : "Loading Employee Group List"
    };
  },
  mounted() {
       EmployeeGroup.all()
        .then((response) => {
          if (response.status === 200) {
            const employee_groups = response.data.data;
            if(this.required === false){
                this.axiosOptions.push({
                    text: "All",
                    value: "",
                });
            }            

            for (let employee_group of employee_groups) {
                if (this.default_selected_value === employee_group.employee_group_id) {
                    this.axiosOptions.push({
                    text: employee_group.employee_group_name,
                    value: employee_group.employee_group_id,
                    selected:true
                    });
                } else {
                    this.axiosOptions.push({
                    text: employee_group.employee_group_name,
                    value: employee_group.employee_group_id,
                    });
                }
            }    
            this.loading = false
            this.selectLabel = "Select employee group"

          }
        })
  },
  methods: {
    updateSelectedValue(val) {
      if (val !== null) {
        this.$emit("updateEmployeeGroupID", val);
      }
    },
  },
    watch:{
    resetSelected(newValue, oldValue){
      if (newValue !== oldValue){
        this.axiosOptions = this.axiosOptions.map(val => ({text: val.text, value: val.value})) 
      }
    }
  }
};
</script>
