<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">
        <mdb-card>
          <mdb-card-body>
            <mdb-tabs
              :active="0"
              tabs
              card
              class="mb-5"
              color="primary"
              justify
              :links="[
                { text: 'Attendance' },
                { text: 'Leave' },
                { text: 'Overtime' },
              ]"
            >
              <template :slot="'Attendance'">
                <div v-if="approver_list.attendance != ''">
                  <div
                    v-if="
                      approver_list.attendance.attendance_auto_approval == 'yes'
                    "
                  >
                    <label
                      >Does not required approval for attendance request</label
                    >
                  </div>
                  <div v-else>
                    <template
                      v-if="approver_list.attendance.approvers.length > 0"
                    >
                      <div
                        v-for="(approvers, index) in approver_list.attendance
                          .approvers"
                        :key="index"
                      >
                        Level {{ approvers[0].attendance_approval_level }}

                        <mdb-row>
                          <mdb-col
                            v-for="(approver, index2) in approvers"
                            :key="index2"
                            md="6"
                          >
                            <!--  -->
                            <div class="approval_info_box mt-3">
                              <img
                                :src="approver.profile_image"
                                class="rounded-circle float-left"
                                width="50"
                                height="50"
                                v-if="approver.profile_image"
                                @error="imageLoadError"
                              />

                              <div class="float-left ml-3 mb-3">
                                <div class="employee_name">
                                  {{
                                    approver.epinfo_name | cutStringTableCell
                                  }}
                                </div>
                                <div class="employee_info_text_sec">
                                  {{ approver.jp_name | cutStringTableCell }}
                                </div>
                                <div class="employee_info_text">
                                  {{
                                    approver.department_name
                                      | cutStringTableCell
                                  }}
                                </div>
                              </div>

                              <mdb-btn
                                color="uno-color"
                                class="float-right close_btn"
                                v-if="
                                  approvers.length > 1 ||
                                  (approver_list.attendance.approvers.length !=
                                    1 &&
                                    approver_list.attendance.approvers.length ==
                                      approver.attendance_approval_level)
                                "
                                @click="
                                  showDeleteModal(
                                    'attendance',
                                    approver.attendance_approval_id,
                                    index,
                                    index2
                                  )
                                "
                              >
                                <mdb-icon
                                  icon="times-circle"
                                  size="2x"
                                  class="uno-text close_btn_color mr-2"
                                />
                              </mdb-btn>
                            </div>
                            <!--  -->

                            <!-- {{ approver }} -->
                          </mdb-col>
                        </mdb-row>
                        <hr />
                      </div>
                    </template>
                  </div>
                </div>
              </template>
              <template :slot="'Leave'">
                <div v-if="approver_list.leave != ''">
                  <div v-if="approver_list.leave.leave_auto_approval == 'yes'">
                    <label>Does not required approval for leave request</label>
                  </div>
                  <div v-else>
                    <template v-if="approver_list.leave.approvers.length > 0">
                      <div
                        v-for="(approvers, index) in approver_list.leave
                          .approvers"
                        :key="index"
                      >
                        Level {{ approvers[0].leave_approval_level }}

                        <mdb-row>
                          <mdb-col
                            v-for="(approver, index2) in approvers"
                            :key="index2"
                            md="6"
                          >
                            <!--  -->
                            <div class="approval_info_box mt-3">
                              <img
                                :src="approver.profile_image"
                                class="rounded-circle float-left"
                                width="50"
                                height="50"
                                v-if="approver.profile_image"
                                @error="imageLoadError"
                              />

                              <div class="float-left ml-3 mb-3">
                                <div class="employee_name">
                                  {{
                                    approver.epinfo_name | cutStringTableCell
                                  }}
                                </div>
                                <div class="employee_info_text_sec">
                                  {{ approver.jp_name | cutStringTableCell }}
                                </div>
                                <div class="employee_info_text">
                                  {{
                                    approver.department_name
                                      | cutStringTableCell
                                  }}
                                </div>
                              </div>

                              <mdb-btn
                                color="uno-color"
                                class="float-right close_btn"
                                v-if="
                                  approvers.length > 1 ||
                                  (approver_list.leave.approvers.length != 1 &&
                                    approver_list.leave.approvers.length ==
                                      approver.leave_approval_level)
                                "
                                @click="
                                  showDeleteModal(
                                    'leave',
                                    approver.leave_approval_id,
                                    index,
                                    index2
                                  )
                                "
                              >
                                <mdb-icon
                                  icon="times-circle"
                                  size="2x"
                                  class="uno-text close_btn_color mr-2"
                                />
                              </mdb-btn>
                            </div>
                            <!--  -->
                            <!-- {{ approver }} -->
                          </mdb-col>
                        </mdb-row>
                        <hr />
                      </div>
                    </template>
                  </div>
                </div>
              </template>
              <template :slot="'Overtime'">
                <div v-if="approver_list.overtime != ''">
                  <div
                    v-if="
                      approver_list.overtime.overtime_auto_approval == 'yes'
                    "
                  >
                    <label
                      >Does not required approval for overtime request</label
                    >
                  </div>
                  <div v-else>
                    <template
                      v-if="approver_list.overtime.approvers.length > 0"
                    >
                      <div
                        v-for="(approvers, index) in approver_list.overtime
                          .approvers"
                        :key="index"
                      >
                        Level {{ approvers[0].overtime_approval_level }}

                        <mdb-row>
                          <mdb-col
                            v-for="(approver, index2) in approvers"
                            :key="index2"
                            md="6"
                          >
                            <!--  -->
                            <div class="approval_info_box mt-3">
                              <img
                                :src="approver.profile_image"
                                class="rounded-circle float-left"
                                width="50"
                                height="50"
                                v-if="approver.profile_image"
                                @error="imageLoadError"
                              />

                              <div class="float-left ml-3 mb-3">
                                <div class="employee_name">
                                  {{
                                    approver.epinfo_name | cutStringTableCell
                                  }}
                                </div>
                                <div class="employee_info_text_sec">
                                  {{ approver.jp_name | cutStringTableCell }}
                                </div>
                                <div class="employee_info_text">
                                  {{
                                    approver.department_name
                                      | cutStringTableCell
                                  }}
                                </div>
                              </div>

                              <mdb-btn
                                color="uno-color"
                                class="float-right close_btn"
                                v-if="
                                  approvers.length > 1 ||
                                  (approver_list.overtime.approvers.length !=
                                    1 &&
                                    approver_list.overtime.approvers.length ==
                                      approver.overtime_approval_level)
                                "
                                @click="
                                  showDeleteModal(
                                    'overtime',
                                    approver.overtime_approval_id,
                                    index,
                                    index2
                                  )
                                "
                              >
                                <mdb-icon
                                  icon="times-circle"
                                  size="2x"
                                  class="uno-text close_btn_color mr-2"
                                />
                              </mdb-btn>
                            </div>
                            <!--  -->
                          </mdb-col>
                        </mdb-row>
                        <hr />
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </mdb-tabs>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
        <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
      </div>
    </template>
  </div>
</template>

<script>
import Employee from "@/apis/Employee";
import ApprovalWorkflow from "@/apis/ApprovalWorkflow";
import { mapActions, mapGetters } from "vuex";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
// import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import {
  mdbCard,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTabs,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTabs,
    ModalDeleteConfimation,
    // EmployeeInfoBoxLayout,
  },
  name: "EmployeeApproverList",

  data() {
    return {
      temp_delete_approval_id: "",
      temp_delete_approval_type: "",
      temp_level_index: "",
      temp_user_index: "",
      approver_list: [],
      feedback_error: false,
      feedback_message: "",
    };
  },
  props: ["employee_id"],
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal(type, val, lvl_idx_val, user_idx_val) {
      this.temp_delete_approval_type = type;
      this.temp_delete_approval_id = val;
      this.temp_level_index = lvl_idx_val;
      this.temp_user_index = user_idx_val;

      // console.log(type, val, lvl_idx_val, user_idx_val);
      this.$store.state.deleteModalVisible = true;
    },

    handleDeleteRecord() {
      this.$store.state.deleteModalVisible = false;
      this.feedback_error = false;

      if (
        this.temp_delete_approval_id != "" &&
        this.temp_delete_approval_id > 0
      ) {
        ApprovalWorkflow.delete({
          type: this.temp_delete_approval_type,
          approval_id: [this.temp_delete_approval_id],
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              if (this.temp_level_index !== "" && this.temp_user_index !== "") {
                if (this.temp_delete_approval_type == "attendance") {
                  if (
                    this.approver_list.attendance.approvers[
                      this.temp_level_index
                    ].length > 1
                  ) {
                    this.approver_list.attendance.approvers[
                      this.temp_level_index
                    ].splice(this.temp_user_index, 1);
                  } else {
                    this.approver_list.attendance.approvers.splice(
                      this.temp_level_index,
                      1
                    );
                  }
                } else if (this.temp_delete_approval_type == "leave") {
                  if (
                    this.approver_list.leave.approvers[this.temp_level_index]
                      .length > 1
                  ) {
                    this.approver_list.leave.approvers[
                      this.temp_level_index
                    ].splice(this.temp_user_index, 1);
                  } else {
                    this.approver_list.leave.approvers.splice(
                      this.temp_level_index,
                      1
                    );
                  }
                } else if (this.temp_delete_approval_type == "overtime") {
                  if (
                    this.approver_list.overtime.approvers[this.temp_level_index]
                      .length > 1
                  ) {
                    this.approver_list.overtime.approvers[
                      this.temp_level_index
                    ].splice(this.temp_user_index, 1);
                  } else {
                    this.approver_list.overtime.approvers.splice(
                      this.temp_level_index,
                      1
                    );
                  }
                }
              }

              //
              this.temp_delete_approval_id = "";
              this.temp_delete_approval_type = "";
              this.temp_level_index = "";
              this.temp_user_index = "";

              // this.fetchRecordList();
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }
          }
        });
      }
    },

    imageLoadError(event) {
      event.target.src = "/default-user.png";
    },

    fetchRecordList() {
      this.updateLoading(true);

      if (this.employee_id != undefined) {
        if (Math.sign(this.employee_id) === 1) {
          Employee.viewApprovers(this.employee_id)
            .then((response) => {
              if (response.status === 200) {
                this.approver_list = response.data.data;
                this.updateLoading(false);
              }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.feedback_message = "Could not connected to server";
                this.feedback_error = true;

                this.updateLoading(false);
              } else {
                console.log(error);
                this.updateLoading(false);
              }
            });
        }
      }
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
