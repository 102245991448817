<template>
  <div>
    <mdb-modal size="size" :show="isModalVisible" @close="closeModal">
      <mdb-modal-header>
        <mdb-modal-title>
          <slot name="header">
            {{ modalTitle }}
          </slot>
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <slot name="body">
          {{ modalBody }}
        </slot>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="closeModal"
          >No</mdb-btn
        >
        <mdb-btn color="primary" @click="confirmAction" size="sm"
          >Yes</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import i18n from "@/i18n";
import {
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
} from "mdbvue";
export default {
  name: "ModalDeleteConfimation",
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
  },

  props: {
    size: {
      type: String,
      default: "sm",
    },
    modalTitle: {
      type: String,
      default: i18n.t("general.text.confirmation"),
    },
    modalBody: {
      type: String,
      default: i18n.t("general.text.delete_confirmation_text"),
    },
  },

  computed:{
    isModalVisible(){
      return this.$store.state.deleteModalVisible
    }
  },

  methods: {
    closeModal(){
      this.$store.state.deleteModalVisible = false
    },
    confirmAction() {
      this.$emit("comfirmAction", true);
    },
  },

};
</script>
