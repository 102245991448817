<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>
    <div class="text-right"></div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <!-- <mdb-row v-if="selectedEmployeeData" class="mb-5">
              <mdb-col offsetMd="2" md="8">
                <div class="approval_info_box">
                  <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
                </div>
              </mdb-col>
            </mdb-row> -->

            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <dl class="row">
                  <dt class="col-sm-4">Employee Info</dt>
                  <dd class="col-sm-8">
                    <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
                  </dd>

                  <dt class="col-sm-4">Attendance Day</dt>
                  <dd class="col-sm-8">
                    {{ attendance.attendance_day }}
                  </dd>

                  <dt class="col-sm-4">Attendance Type</dt>
                  <dd class="col-sm-8">
                    <template v-if="attendance.attendance_type == 'check_in'">
                      Check in
                    </template>
                    <template v-else> Check Out </template>
                  </dd>

                  <dt class="col-sm-4">
                    <template v-if="attendance.attendance_type == 'check_in'">
                      Office Check in
                    </template>
                    <template v-else> OfficeCheck Out </template>
                  </dt>
                  <dd class="col-sm-8">
                    {{ attendance.attendance_office_time }}
                  </dd>

                  <dt class="col-sm-4">
                    <template v-if="attendance.attendance_type == 'check_in'">
                      Check in Time
                    </template>
                    <template v-else> Check Out Time </template>
                  </dt>
                  <dd class="col-sm-8">
                    {{ attendance.attendance_check_time }}
                  </dd>

                  <template v-if="attendance.attendance_remark != ''">
                    <dt class="col-sm-4">remark</dt>
                    <dd class="col-sm-8">
                      {{ attendance.attendance_remark }}
                    </dd>
                  </template>

                  <dt class="col-sm-4">
                    <template v-if="attendance.attendance_type == 'check_in'">
                      Late
                    </template>
                    <template v-else> Early </template>
                  </dt>
                  <dd class="col-sm-8">
                    {{ attendance.attendance_late_early }}
                  </dd>

                  <template v-if="attendance.attendance_image != ''">
                    <dt class="col-sm-4">Image</dt>
                    <dd class="col-sm-8">
                      <a :href="attendance.attendance_image" target="_blank">
                      <img :src="attendance.attendance_image" width="200" />
                      </a>
                    </dd>
                  </template>

                  <dt class="col-sm-4">Location</dt>
                  <dd class="col-sm-8">
                    <gmap-map
                      v-if="getLocation"
                      :center="getLocation"
                      :zoom="14"
                      map-type-id="terrain"
                      style="width: 500px; height: 300px"
                    >
                      <gmap-marker
                        :position="getLocation"
                        @click="center = getLocation"
                      ></gmap-marker>
                    </gmap-map>
                    <div v-else>
                      location not available
                    </div>

                    
                  </dd>

                  <dt class="col-sm-4">Status</dt>
                  <dd class="col-sm-8">
                    {{ attendance.attendance_status }}
                  </dd>

                  <dt class="col-sm-4"></dt>
                  <dd
                    class="col-sm-8"
                    v-if="attendance.attendance_complete != '1'"
                  >
                    Waiting Approval
                  </dd>
                </dl>

                <hr />
              </mdb-col>
            </mdb-row>

            <mdb-row v-if="leaves_data.date">
              <mdb-col offsetMd="2" md="8">
                <h4>Leave Info</h4>
                <br />
                <dl class="row">
                  <dt class="col-sm-4">Leave Date</dt>
                  <dd class="col-sm-8">
                    {{ leaves_data.date }}
                  </dd>

                  <dt class="col-sm-4">Leave Type</dt>
                  <dd class="col-sm-8">
                    {{ leaves_data.type }}
                  </dd>

                  <dt class="col-sm-4">Description</dt>
                  <dd class="col-sm-8">
                    {{ leaves_data.description }}
                  </dd>
                </dl>
                <hr />
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <div v-if="approve_user.length > 0">
                  <mdb-card class="mb-5">
                    <mdb-card-body>
                      <mdb-row>
                        <mdb-col>
                          <div class="title_header mb-3">Approved By</div>
                          <div
                            v-for="(user, index) in approve_user"
                            :key="index"
                          >
                            <ApprovedUserLayout
                              :users="{
                                user: {
                                  epinfo_name: user.name,
                                  jp_name: user.position,
                                  department_name: user.department,
                                  profile_image: user.image,
                                },
                              }"
                            />
                            <!-- <hr v-show="this.attendance.approve_user.length - 1 > index" /> -->
                          </div>
                        </mdb-col>
                      </mdb-row>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <template v-if="attendance.reject_button_status == true">
                  <label class="form-label">
                    {{ $t("approvals.formInput.approval_status") }}
                  </label>
                  <!-- <mdb-input
                    type="radio"
                    id="req-opt-approve_and_fix"
                    radioValue="approve_and_fix"
                    v-model="approval_status"
                    :label="$t('approvals.formInput.approve')"
                    :disabled="attendance.attendance_status == 'approve'"
                  />
                  <mdb-input
                    type="radio"
                    id="req-opt-approve"
                    radioValue="approve"
                    v-model="approval_status"
                    :label="$t('approvals.formInput.approve_but_reject')"
                    :disabled="attendance.attendance_status == 'rejected'"
                  /> -->
                  <mdb-input
                    type="radio"
                    id="req-opt-approve_and_fix"
                    radioValue="approve_and_fix"
                    v-model="approval_status"
                    :label="$t('approvals.formInput.approve')"
                  />
                  <mdb-input
                    type="radio"
                    id="req-opt-approve"
                    radioValue="approve"
                    v-model="approval_status"
                    :label="$t('approvals.formInput.approve_but_reject')"
                  />
                </template>

                <template v-else>
                  <!-- <mdb-input
                    type="radio"
                    id="req-opt-approve"
                    radioValue="approve"
                    v-model="approval_status"
                    :label="$t('approvals.formInput.approve')"
                    :disabled="attendance.attendance_status == 'approve'"
                  /> -->
                  <mdb-input
                    type="radio"
                    id="req-opt-approve"
                    radioValue="approve"
                    v-model="approval_status"
                    :label="$t('approvals.formInput.approve')"
                  />
                </template>

                <!-- <mdb-input
                  type="radio"
                  id="req-opt-reject"
                  radioValue="reject"
                  v-model="approval_status"
                  :label="$t('approvals.formInput.reject')"
                  :disabled="attendance.attendance_status == 'reject'"
                /> -->
                <mdb-input
                  type="radio"
                  id="req-opt-reject"
                  radioValue="reject"
                  v-model="approval_status"
                  :label="$t('approvals.formInput.reject')"
                />

                <mdb-input
                  type="textarea"
                  :label="$t('approvals.formInput.description')"
                  v-model="approval_description"
                  outline
                />
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <mdb-btn
                  type="button"
                  class="btn_back"
                  color="uno-color"
                  @click="redirectBackAction"
                >
                  <mdb-icon icon="arrow-left" size="md" />
                  {{ $t("general.button.go_back") }}
                </mdb-btn>

                <mdb-btn
                  type="Submit"
                  color="uno-color"
                  class="btn_submit"
                  :disabled="showDisplaySubmit"
                  @click="approvalAction()"
                >
                  <mdb-icon
                    icon="file-upload"
                    size="md"
                    v-show="!formSubmitting"
                  />

                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="formSubmitting"
                  ></span>
                  {{ $t("general.button.submit") }}
                </mdb-btn>
              </mdb-col>
            </mdb-row>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
  </div>
</template>
<script>
import Attendance from "@/apis/Attendance";

import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import ApprovedUserLayout from "@/components/ApprovedUserLayout";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  // mdbCardTitle,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbInput,
} from "mdbvue";

export default {
  name: "ApprovalForm",
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    mdbInput,
    EmployeeInfoBoxLayout,
    ApprovedUserLayout,
  },
  props: ["_id"],
  data() {
    return {
      attendance: {},
      approve_user: [],
      leaves_data: {},
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
      selectedEmployeeData: false,
      approval_status: "",
      approval_description: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    getLocation(){
      if(this.attendance.longitude && this.attendance.latitude){
        return {
          lat: Number(this.attendance.latitude),
          lng: Number(this.attendance.longitude)
        }
      }
      return false;
    },

    showDisplaySubmit() {
      if (this.formSubmitting == true) {
        return true;
      } else if (this.approval_status == "") {
        return true;
      } else if (
        this.approval_status == "reject" &&
        this.approval_description == ""
      ) {
        return true;
      } else if (
        this.attendance.reject_button_status == true &&
        this.approval_status == "approve" &&
        this.approval_description == ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    approvalAction() {
      this.formSubmitting = true;

      Attendance.submitApproval({
        oal_id: [this.attendance.oal_id],
        status: this.approval_status,
        description: this.approval_description,
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            this.formSubmitting = false;
            this.$router.push({ name: "listAttendanceApproval" });
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }
        }
      });
    },

    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          Attendance.getApprovalDetail({ oal_id: this._id })
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  this.attendance = response.data.data;
                  this.approve_user = response.data.approve_user;

                  this.leaves_data = response.data.leaves_data;

                  this.selectedEmployeeData = {
                    epinfo_name: this.attendance.employee_name,
                    jp_name: this.attendance.employee_position,
                    department_name: this.attendance.employee_department,
                    profile_image: this.attendance.employee_image,
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listAttendanceApproval" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listAttendanceApproval" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listAttendanceApproval" });
        }
      } else {
        // add
      }
    },

    redirectBackAction() {
      this.$router.push({
        name: "listAttendanceApproval",
      });
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>