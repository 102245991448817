<template>
  <div>

    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string' ">
        {{feedback_message}}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
         {{ error.message }}
      </div>      
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-show="!isLoading">
        
        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>

          <div class="float-right">
            <mdb-btn class="btn_submit" color="uno-color" @click="createNewAttendanceLocation" size="sm">
          <mdb-icon far icon="plus-square"/>
          {{ $t('general.button.create') }}
        </mdb-btn>
          </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl hover responsive>
            <!-- Table head -->
            <mdb-tbl-head >
              <tr>
                <th @click="sort('loc_name')" >                  
                  <i v-show="currentSort==='loc_name'" :class="[ currentSortDir ==='asc' ? 'fas fa-arrow-up ' : 'fas fa-arrow-down ' ]" aria-hidden="true" ></i>
                  {{ $t('general.formInput.name') }}
                  </th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th width="120" class="text-right" @click="sort('loc_created')" >
                  <i v-show="currentSort==='loc_created'" :class="[ currentSortDir ==='asc' ? 'fas fa-arrow-up ' : 'fas fa-arrow-down ' ]" aria-hidden="true" ></i>
                  {{ $t('general.formInput.created') }}
                  </th>
              </tr>
            </mdb-tbl-head>

            <!-- Table body -->
            <mdb-tbl-body>
              <tr scope="row" v-for="(attendance_location, index) in sortedAttendanceLocationList" :key="index">
                <td scope="row" @click="rowClickEvent(attendance_location.loc_id)">{{attendance_location.loc_name}}</td>
                <td @click="rowClickEvent(attendance_location.loc_id)">{{attendance_location.loc_latitude}}</td>
                <td @click="rowClickEvent(attendance_location.loc_id)">{{attendance_location.loc_longitude}}</td>
                <td class="text-right" @click="rowClickEvent(attendance_location.loc_created)">{{attendance_location.loc_created | formatDateString}}</td>
              </tr>                  
            </mdb-tbl-body>

          </mdb-tbl> 
          </mdb-card-body>
        </mdb-card>      
      </div>
    </template>
  </div>
</template>

<script>
import AttendanceLocation from "@/apis/AttendanceLocation";
import { mapActions, mapGetters } from "vuex";

import {
  // mdbRow,
  // mdbCol,
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody
} from "mdbvue";


export default {
  components: {
    // mdbRow,
    // mdbCol,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
  },

  data() {
    return {
      attendance_locations: [],
      currentSort:'loc_name',
      currentSortDir:'asc',
      feedback_error: false,
      feedback_message:""
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    sortedAttendanceLocationList() {
      return this.attendance_locations.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
      
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
    },

    rowClickEvent(id){
       this.$router.push({ name: 'viewAttendanceLocation', params: { _id: id } });
    },

    createNewAttendanceLocation() {
      this.$router.push({ name: "createAttendanceLocation" });
    },

    fetchAttendanceLocationList(payload = "") {
      this.updateLoading(true);

      AttendanceLocation.all(payload)
        .then((response) => {
          if (response.status === 200) {
            this.attendance_locations = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchAttendanceLocationList();
  },
};
</script>
