<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row class="mb-4">
                <mdb-col offsetMd="2" md="8">
                  <h6 class="mb-4">Core HR</h6>
                </mdb-col>
                <mdb-col offsetMd="2" md="4">
                  <!-- <mdb-form-inline> -->
                  <mdb-input
                    type="radio"
                    id="option-employee"
                    radioValue="employee"
                    v-model="importType"
                    label="Essential Employee Info"
                  />

                  <mdb-input
                    type="radio"
                    id="option-attendance"
                    radioValue="attendance"
                    v-model="importType"
                    label="Attendances"
                  />

                  <mdb-input
                    type="radio"
                    id="option-leave"
                    radioValue="leave"
                    v-model="importType"
                    label="Leaves"
                  />

                  <mdb-input
                    type="radio"
                    id="option-overtime"
                    radioValue="overtime"
                    v-model="importType"
                    label="Overtimes"
                  />

                  <mdb-input
                    type="radio"
                    id="option-payroll"
                    radioValue="payroll"
                    v-model="importType"
                    label="Payrolls"
                  />
                  <!-- </mdb-form-inline> -->
                </mdb-col>

                <mdb-col md="4">
                  <!-- <mdb-form-inline> -->

                  <mdb-input
                    type="radio"
                    id="option-employee_allowance"
                    radioValue="employee_allowance"
                    v-model="importType"
                    label="Employee's Allowance"
                  />

                  <mdb-input
                    type="radio"
                    id="option-addition"
                    radioValue="addition"
                    v-model="importType"
                    label="Addition Payments"
                  />

                  <mdb-input
                    type="radio"
                    id="option-deduction"
                    radioValue="deduction"
                    v-model="importType"
                    label="Deduction Payments"
                  />

                  <mdb-input
                    type="radio"
                    id="option-action"
                    radioValue="action"
                    v-model="importType"
                    label="Actions"
                  />

                  <mdb-input
                    type="radio"
                    id="option-hrtraining"
                    radioValue="hrtraining"
                    v-model="importType"
                    label="HR Training"
                  />

                  <mdb-input
                    type="radio"
                    id="option-duty_roster"
                    radioValue="duty_roster"
                    v-model="importType"
                    label="Duty Roster"
                  />

                  <!-- </mdb-form-inline> -->
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8" class="mb-4">
                  <h6 class="mb-4">Settings</h6>
                  <!-- <mdb-form-inline> -->
                  <mdb-input
                    type="radio"
                    id="option-department"
                    radioValue="department"
                    v-model="importType"
                    label="Departments"
                  />

                  <mdb-input
                    type="radio"
                    id="option-position"
                    radioValue="posiitiion"
                    v-model="importType"
                    label="Positions"
                  />
                  <!-- </mdb-form-inline> -->
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8" class="mb-4">
                  <h6 class="mb-4">Personal Infos</h6>
                  <!-- <mdb-form-inline> -->
                  <mdb-input
                    type="radio"
                    id="option-bankinfo"
                    radioValue="bankinfo"
                    v-model="importType"
                    label="Bank Info"
                  />

                  <mdb-input
                    type="radio"
                    id="option-ssbinfo"
                    radioValue="ssbinfo"
                    v-model="importType"
                    label="SSB Info"
                  />

                  <mdb-input
                    type="radio"
                    id="option-taxinfo"
                    radioValue="taxinfo"
                    v-model="importType"
                    label="Tax Info"
                  />

                  <mdb-input
                    type="radio"
                    id="option-personalinfo"
                    radioValue="personalinfo"
                    v-model="importType"
                    label="Personal Info"
                  />

                  <mdb-input
                    type="radio"
                    id="option-familyinfo"
                    radioValue="familyinfo"
                    v-model="importType"
                    label="Family Info"
                  />

                  <mdb-input
                    type="radio"
                    id="option-educationinfo"
                    radioValue="educationinfo"
                    v-model="importType"
                    label="Education Info"
                  />

                  <mdb-input
                    type="radio"
                    id="option-personaltraininginfo"
                    radioValue="personaltraininginfo"
                    v-model="importType"
                    label="Personal Training Info"
                  />

                  <mdb-input
                    type="radio"
                    id="option-occupationinfo"
                    radioValue="occupationinfo"
                    v-model="importType"
                    label="Occupation Info"
                  />
                  <!-- </mdb-form-inline> -->
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    color="uno-color"
                    class="btn_light_blue"
                    :disabled="this.importType == ''"
                    @click="downloadTemplateFile"
                    ><mdb-icon icon="file-download" class="mr-1" /> Download
                    Template File</mdb-btn
                  >
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-file-input
                    multiple
                    sm
                    btnColor="primary"
                    @getValue="getFileInputValue"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
  </div>
</template>
<script>
import CsvImports from "@/apis/CsvImports";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbFileInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  // mdbFormInline,
  mdbInput,
  // mdbSelect,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbFileInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    // mdbFormInline,
    mdbInput,
    // mdbSelect,
  },
  data() {
    return {
      importType: "",
      fileValue: null,
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    getFileInputValue(val) {
      this.fileValue = val[0];
    },

    downloadTemplateFile() {
      if (this.importType != "") {
        const baseurl = "https://hrm01.sbg.red/download/import/";
        // const baseurl = 'http://localhost:8080/download/import/';

        if (
          this.importType == "employee" ||
          this.importType == "attendance" ||
          this.importType == "leave" ||
          this.importType == "overtime" ||
          this.importType == "payroll" ||
          this.importType == "department" ||
          this.importType == "position" ||
          this.importType == "bankinfo" ||
          this.importType == "ssbinfo" ||
          this.importType == "taxinfo" ||
          this.importType == "personalinfo" ||
          this.importType == "familyinfo" ||
          this.importType == "educationinfo" ||
          this.importType == "personaltraininginfo" ||
          this.importType == "occupationinfo" ||

          this.importType == "addition" ||
          this.importType == "deduction" ||
          this.importType == "hrtraining" ||
          this.importType == "duty_roster" ||
          this.importType == "action" ||
          this.importType == "employee_allowance" 

        ) {
          window.open(baseurl + this.importType + ".xlsx", "_blank");
        }
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      if (this.importType != "" && this.fileValue) {
        let formData = new FormData();
        formData.append("file", this.fileValue);

        if (this.importType == "employee") {
          CsvImports.employeeImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "attendance") {
          CsvImports.attendanceImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "leave") {
          CsvImports.leaveImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "overtime") {
          CsvImports.overtimeImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "payroll") {
          CsvImports.payrollImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "department") {
          CsvImports.departmentImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "position") {
          CsvImports.positionImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "bankinfo") {
          CsvImports.bankinfoImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "ssbinfo") {
          CsvImports.ssbinfoImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "taxinfo") {
          CsvImports.taxinfoImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "personalinfo") {
          CsvImports.personalinfoImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "familyinfo") {
          CsvImports.familyinfoImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "educationinfo") {
          CsvImports.educationinfoImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "personaltraininginfo") {
          CsvImports.personaltraininginfoImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "occupationinfo") {
          CsvImports.occupationinfoImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        }


        else if (this.importType == "addition") {
          CsvImports.additionPaymentImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "deduction") {
          CsvImports.deductionPaymentImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "hrtraining") {
          CsvImports.hrTrainingImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "duty_roster") {
          CsvImports.dutyRosterImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "action") {
          CsvImports.actionImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        } else if (this.importType == "employee_allowance") {
          CsvImports.allowanceImport(formData).then((response) => {
            this.handlingUploadFeedback(response);
          });
        }


        // .catch((error) => {
        //   console.log(error);
        // });
      } else {
        this.feedback_message = "Error";
        this.feedback_error = true;
        this.formSubmitting = false;
      }
    },

    handlingUploadFeedback(response) {
      if (response.status === 200) {
        if (response.data.code === 200) {
          this.resetForm();
          this.$notify.success({
            message: response.data.message,
            position: "top right",
            timeOut: 5000,
          });
        } else if (response.data.code === 400) {
          if (response.data.error.length > 0) {
            this.feedback_message = response.data.error;
          }
          this.feedback_error = true;
        } else if (response.data.code === 500) {
          this.feedback_message = response.data.error;
          this.feedback_error = true;
        }

        this.formSubmitting = false;
      }
    },

    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {},
  },
  created() {},
};
</script>