<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                               

                <mdb-col offsetMd="2" md="4">

                  <mdb-input
                  type="text"
                  :label="$t('general.formInput.name')"
                  v-model="employee_document.emp_document_name"
                  outline
                  required
                />
                </mdb-col>

                <mdb-col  md="4">
                  <DocumentTypeDropdown
                    @updateDocumentTypeID="setDocumentTypeID"
                    :default_selected_value="employee_document.emp_dt_id"
                    :required="true"
                    :resetSelected="doesFormReset"
                  />
                </mdb-col>
                
                <mdb-col offsetMd="2" md="8" class="my-2 text-center" v-show="employee_document.emp_file_name!=''">

                  <a
                    :href="employee_document.emp_file_name"
                    target="_blank"
                  >
                    <img
                      v-if="
                      employee_document.emp_document_id!='' &&
                      (
                        file_ext == 'png' ||
                        file_ext == 'gif' ||
                        file_ext == 'jpg' ||
                        file_ext == 'jpeg'
                      )
                      "
                      :src="employee_document.emp_file_name"
                  
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else-if="
                      employee_document.emp_document_id=='' &&
                      (
                        file_ext == 'png' ||
                        file_ext == 'gif' ||
                        file_ext == 'jpg' ||
                        file_ext == 'jpeg'
                      )
                      "
                      :src="temp_doc_file_url"
                  
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else-if="
                        file_ext == 'doc' ||
                        file_ext == 'docx'
                      "
                      src="/doc.png"
                  
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else-if="
                        file_ext == 'xls' ||
                        file_ext == 'xlsx'
                      "
                      src="/spreadsheet.png"
                  
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else-if="
                        file_ext == 'ppt' ||
                        file_ext == 'ppt'
                      "
                      src="/powerpoint.png"
                  
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else-if="
                        file_ext == 'pdf'
                      "
                      src="/pdf.png"
                  
                      class="announcement_thumbnail_image"
                    />
                    <img
                      v-else
                      src="/unknowfile.png"
                  
                      class="announcement_thumbnail_image"
                    />
                  </a>
                  
               
                </mdb-col>

                 <mdb-col offsetMd="2" md="8" class="mb-4">
                   <mdb-file-input
                    @getValue="getFileInputValue"
                    icon="cloud-upload-alt"
                    outline="info"
                    accept="*"
                    textFieldTitle="Upload your document"
                  />

                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"                    
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import EmployeeDocument from "@/apis/EmployeeDocument";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import DocumentTypeDropdown from "@/components/DocumentTypeDropdown";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFileInput,
  
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbFileInput,
    ModalDeleteConfimation,
    DocumentTypeDropdown,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      employee_document: {
        emp_document_id: "",
        emp_employee_id: this.employee_id != "undefined" ? this.employee_id : "",
        emp_document_name: "",
        emp_file_name: "",
        emp_dt_id: "",
      },
      temp_doc_file_url:"",
      file_ext:"",
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.employee_document.emp_document_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    setDocumentTypeID(val) {
      this.employee_document.emp_dt_id = val;
    },
    getFileInputValue(val) {
      this.employee_document.emp_file_name = val[0];

      this.file_ext = val[0].name.split(".").pop().toLowerCase()
      this.temp_doc_file_url = URL.createObjectURL(val[0])

    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          EmployeeDocument.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)

                  let emp_document_id,
                    emp_employee_id,
                    emp_document_name,
                    emp_file_name,
                    emp_dt_id;
                  // populate field
                  ({
                    emp_document_id,
                    emp_employee_id,
                    emp_document_name,
                    emp_file_name,
                    emp_dt_id,
                  } = response.data.data);
                  this.employee_document.emp_document_id = emp_document_id;
                  this.employee_document.emp_employee_id = emp_employee_id;
                  this.employee_document.emp_document_name = emp_document_name;
                  this.employee_document.emp_file_name = emp_file_name;
                  this.employee_document.emp_dt_id = emp_dt_id;

                  this.file_ext = response.data.data.file_ext.toLowerCase();
                  //make selected option again

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    employeeDocumentList: {
                      _id: this.employee_document.emp_employee_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "employeeDocumentList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "employeeDocumentList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "employeeDocumentList" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      let formData = new FormData();
      formData.append("emp_document_id", this.employee_document.emp_document_id);
      formData.append("emp_document_name", this.employee_document.emp_document_name);
      formData.append("emp_employee_id", this.employee_document.emp_employee_id);
      formData.append("emp_dt_id", this.employee_document.emp_dt_id);
      formData.append("emp_file_name", this.employee_document.emp_file_name);

      EmployeeDocument.save(formData).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.employee_document.emp_document_id != "") {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({
                name: "employeeDocumentList",
                params: { employee_id: this.employee_document.emp_employee_id },
              });
            } else {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.resetForm();
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({
        name: "employeeDocumentList",
        params: { employee_id: this.employee_document.emp_employee_id },
      });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.employee_document).forEach((key) => {
        this.employee_document[key] = "";
      });
      this.employee_document.emp_employee_id = this.employee_id;
      this.doesFormReset = !this.doesFormReset;
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.employee_document.emp_document_id > 0) {
        EmployeeDocument.delete({
          emp_document_id: this.employee_document.emp_document_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              this.$router.push({
                name: "employeeDocumentList",
                params: {
                  _id: this.employee_document.emp_employee_id,
                },
              });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      employeeDocumentList: {
        employee_id: this.employee_document.emp_employee_id,
      },
    };
    this.fetchRecord();
  },
};
</script>
<style scoped>
.announcement_thumbnail_image {
  border: 2px solid #c0c0c0;
  padding: 5px;
  margin: 10px;
  max-height: 400px;
}
</style>