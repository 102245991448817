<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>

        <mdb-row class="mb-4">
          <mdb-col>
            <mdb-card>
              <mdb-card-body>
                <form @submit.prevent="handleFormSubmit">
                  <div class="float-right">
                    <mdb-select
                      search
                      outline
                      v-model.trim="yearsOptions"
                      :label="$t('holidays.formInput.select_year')"
                      placeholder=""
                      v-on:getValue="getSelectValue"
                      style="width: 200px; float: left"
                    />

                    <mdb-select
                      outline
                      v-model.trim="monthOptions"
                      :label="$t('holidays.formInput.select_month')"
                      placeholder=""
                      v-on:getValue="getSelectMonthValue"
                      style="width: 200px; float: left"
                    />

                    <mdb-btn
                      type="Submit"
                      color="uno-color"
                      class="btn_submit"
                      :disabled="formSubmitting"
                      style="float: right; margin-top: 18px"
                    >
                      <mdb-icon
                        icon="file-upload"
                        size="md"
                        v-show="!formSubmitting"
                      />

                      <span
                        class="spinner-border-vcenter spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        v-show="formSubmitting"
                      ></span>
                      {{ $t("general.button.submit") }}
                    </mdb-btn>
                  </div>
                </form>
                <br style="clear: both" />
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>

        <mdb-card
          class="mb-4"
          v-if="graphData.label.length > 0 || usageData.length > 0"
        >
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Monthly Usage Report</h6>

                <div>
                  <VerticalBarChartView
                    v-if="graphData && graphData.label.length > 0"
                    :barGraphData="graphData"
                    :xlabel="'No of Employees'"
                    :ylabel="'Companies'"
                  />
                </div>
                <br />
                <br />

                <mdb-row v-if="usageData.length > 0">
                  <mdb-col>
                    <div>
                      <mdb-tbl responsive striped bordered sm>
                        <mdb-tbl-head style="background: #96a6bf; color: #fff">
                          <tr>
                            <td>Company Name</td>
                            <td>Total Employees</td>
                            <td>Invoiced Employees</td>
                            <td>Amount Per Employee</td>
                            <td>Total</td>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr v-for="(item, index) in usageData" :key="index">
                            <td>{{ item.company }}</td>
                            <td>{{ item.total_employee }}</td>
                            <td>{{ item.invoice_employee }}</td>
                            <td>{{ item.amount_per_employee }}</td>
                            <td>{{ item.total }}</td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >
        <mdb-row
          v-if="
            graphData.label.length == 0 &&
            usageData.length == 0 &&
            comparision_data.length > 0
          "
          class="mb-4"
        >
          <mdb-col>
            <mdb-card>
              <mdb-card-body>
                <div class="d-flex justify-content-center">
                  <h5 style="margin-top: 60px">No Record Found.</h5>
                </div>
                <div class="d-flex justify-content-center">
                  <img src="/empty_item.svg" alt="Empty Record" />
                </div>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>

        <mdb-card class="mb-4" v-if="comparision_data.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Monthly Usage Analysis</h6>

                <mdb-row>
                  <mdb-col>
                    <div>
                      <mdb-tbl responsive striped bordered sm>
                        <mdb-tbl-head style="background: #96a6bf; color: #fff">
                          <tr>
                            <td
                              v-for="(item_value, index) in comparision_header"
                              :key="index"
                            >
                              {{ item_value }}
                            </td>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr
                            v-for="(item, index) in comparision_data"
                            :key="index"
                          >
                            <td
                              v-for="(item_value, index2) in item"
                              :key="index2"
                            >
                              {{ item_value }}
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <!-- ///////////////////////////////////////// -->

        <mdb-row
          v-if="
            graphData.label.length == 0 &&
            usageData.length == 0 &&
            comparision_data.length == 0
          "
        >
          <mdb-col>
            <mdb-card>
              <mdb-card-body>
                <div class="d-flex justify-content-center">
                  <h5 style="margin-top: 60px">No Record Found.</h5>
                </div>
                <div class="d-flex justify-content-center">
                  <img src="/empty_item.svg" alt="Empty Record" />
                </div>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </template>
    </div>
  </div>
</template>
<script>
import Report from "@/apis/Report";
import { mapActions, mapGetters } from "vuex";

import VerticalBarChartView from "@/components/VerticalBarChartView";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbBtn,
  mdbIcon,
  mdbSelect,
} from "mdbvue";

const yearOption = (current, year) => {
  let years = [];
  for (let current_year = current; current_year >= 1900; --current_year) {
    if (year == current_year)
      years.push({ text: current_year, value: current_year, selected: true });
    else years.push({ text: current_year, value: current_year });
  }
  return years;
};

const month_name = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthOption = (month) => {
  let months = [];
  for (let i = 0; i < month_name.length; i++) {
    if (month == month_name[i])
      months.push({
        text: month_name[i],
        value: month_name[i],
        selected: true,
      });
    else months.push({ text: month_name[i], value: month_name[i] });
  }
  return months;
};

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    VerticalBarChartView,
    mdbBtn,
    mdbIcon,
    mdbSelect,
  },
  data() {
    return {
      yearsOptions: null,
      monthOptions: null,
      year: "",
      month: "",
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",

      graphData: false,
      usageData: [],

      comparision_header: [],
      comparision_data: [],

      /////////////////////
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    getSelectValue(value) {
      this.year = value;
    },
    getSelectMonthValue(value) {
      this.month = value;
    },

    resetAlert() {
      this.feedback_error = false;
    },

    handleFormSubmit() {
      if (this.year != "" && this.month != "") {
        this.fetchData();
      } else {
        this.feedback_message = "Select Month and Year";
        this.feedback_error = true;
      }
    },

    fetchData() {
      this.updateLoading(true);

      this.graphData= false;
      this.usageData= [];
      this.comparision_header= [];
      this.comparision_data= [];

      let payload = {};
      if (this.year != "" && this.month != "") {
        payload = {
          date: this.month + "-" + this.year,
        };
      } else {
        payload = { date: "" };
      }

      Report.SBGusageGraphData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.graphData = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      Report.SBGusageData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.usageData = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      const analysis_payload = {
        year: this.year,
      };

      Report.SBGusageAnalysis(analysis_payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.comparision_header = response.data.data.comparision_header;
            this.comparision_data = response.data.data.comparision_data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });
    },
  },
  created() {
    this.yearsOptions = yearOption(parseInt(new Date().getFullYear()) + 1, "");
    this.monthOptions = monthOption("");
    this.fetchData();
  },
};
</script>
