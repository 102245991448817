<template>
  <div>
    <img
      :src="user.profile_image"
      class="rounded-circle float-left"
      width="50"
      height="50"
      v-if="user.profile_image"
      @error="imageLoadError"
    />

    <div class="float-left ml-3">
      <div class="employee_name">
        {{ user.epinfo_name | cutStringTableCell }}
      </div>

      <div class="employee_info_text_sec">
        {{ user.jp_name | cutStringTableCell }}
      </div>
      <div class="employee_info_text">
        {{ user.department_name | cutStringTableCell }}
      </div>
    </div>
  </div>
</template>

<script>
import {} from "mdbvue";
export default {
  name: "EmployeeInfoTableCellLayout",
  components: {},
  props: ["user"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    imageLoadError(event) {
      event.target.src = "/default-user.png";
    },
  },
};
</script>
