<template>
    <mdb-container class="mt-5">
        
          <router-view></router-view>
      </mdb-container>
</template>
<script>
import {mdbContainer} from 'mdbvue';
export default {
    name:"AuthLayout",
    components: {
        mdbContainer
    },
}
</script>
