<template>
  <div>
    <mdb-select
    outline
      search
      v-model="axiosOptions"
      :label="selectLabel"
      @getValue="updateSelectedValue"
      :disabled="loading"
    />
  </div>
</template>

<script>
import { mdbSelect } from "mdbvue";
import Rank from "@/apis/Rank";
export default {
  name: "RankDropdown",
  components: {
    mdbSelect,
  },
  props: ["default_selected_value", "required","resetSelected"],
  data() {
    return {
      axiosOptions: [],
      loading:true,
      selectLabel : "Loading Rank List"
    };
  },
  mounted() {
       Rank.all()
        .then((response) => {
          if (response.status === 200) {
            const ranks = response.data.data;
            if(this.required === false){
                this.axiosOptions.push({
                    text: "All",
                    value: "",
                });
            }            

            for (let rank of ranks) {
                if (this.default_selected_value === rank.rt_id) {
                    this.axiosOptions.push({
                    text: rank.rt_name,
                    value: rank.rt_id,
                    selected:true
                    });
                } else {
                    this.axiosOptions.push({
                    text: rank.rt_name,
                    value: rank.rt_id,
                    });
                }
            }    
            this.loading = false
            this.selectLabel = "Select rank"

          }
        })
  },
  methods: {
    updateSelectedValue(val) {
      if (val !== null) {
        this.$emit("updateRankID", val);
      }
    },
  },
    watch: {
    resetSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.axiosOptions = this.axiosOptions.map((val) => ({
          text: val.text,
          value: val.value,
        }));
      }
    },
  },
};
</script>
