<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <template v-if="!employee_id">
                <mdb-row class="mb-3" v-if="_id == undefined">
                  <mdb-col offsetMd="2" md="8">
                    <div class="float-left mt-3 sec_title">
                      Select Employees
                    </div>
                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="float-right btn_outline_style"
                      icon="user-friends"
                      @click.native="modalOpen()"
                    >
                      {{ $t("payrolls.button.select_employee") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>
                <mdb-row class="mb-3">
                  <mdb-col offsetMd="2" md="8">
                    <div v-if="selected_employee_ids.length > 0">
                      <mdb-chip
                        v-for="(e, counter) in selected_employee_ids"
                        v-bind:key="counter"
                        waves
                      >
                        {{ e.epinfo_name }}
                      </mdb-chip>
                    </div>

                    <hr />
                  </mdb-col>
                </mdb-row>

                <!-- //////////////////////// -->

                <mdb-row class="mb-3" v-if="_id != undefined">
                  <mdb-col offsetMd="2" md="8">
                    <div class="float-left mt-3 sec_title">Select Employee</div>
                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="float-right btn_outline_style"
                      icon="user-friends"
                      @click.native="modal = true"
                    >
                      {{ $t("payrolls.button.select_employee") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>

                <mdb-row v-if="selectedEmployeeData" class="mb-5">
                  <mdb-col offsetMd="2" md="8">
                    <div class="approval_info_box">
                      <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
                    </div>
                  </mdb-col>
                </mdb-row>
              </template>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-select
                    v-model="deductionCategoryOption"
                    placeholder="Select Deduction Category"
                    label=""
                    outline
                    @getValue="updateDeductionCategory"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="number"
                    :label="$t('addition.formInput.amount')"
                    v-model="deductions.otd_total_amount"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    :label="$t('deduction.formInput.description')"
                    v-model="deductions.otd_description"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <!-- ///////////////////////// -->

              <mdb-row  v-if="_id == undefined">
                <mdb-col offsetMd="2" md="8">
                  <label></label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="otd_type-different_amount"
                      name="otd_type"
                      radioValue="different_amount"
                      v-model="deductions.otd_type"
                      label="Different Amount"
                    />
                    <mdb-input
                      type="radio"
                      id="otd_type-same_amount"
                      name="otd_type"
                      radioValue="same_amount"
                      v-model="deductions.otd_type"
                      label="Same Amount"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>
              <br />
              <!-- ///////////////////////// -->

              <mdb-row
                v-show="
                  !doesEditForm && deductions.otd_type == 'different_amount'
                "
              >
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    outline="primary"
                    @click="addDeductionDetail('', '', '', '')"
                    size="sm"
                    rounded
                  >
                    <mdb-icon icon="plus-square" size="lg" />
                    Add More
                  </mdb-btn>
                </mdb-col>
              </mdb-row>

              <div
                v-for="(deduction_item, counter) in deduction_amount_list"
                v-bind:key="counter"
                v-show="deductions.otd_type == 'different_amount'"
              >
                <mdb-row>
                  <mdb-col offsetMd="2" md="4">
                    <mdb-input
                      type="number"
                      :label="$t('addition.formInput.amount')"
                      v-model="deduction_item.amount"
                      outline
                    />
                  </mdb-col>

                  <mdb-col md="4">
                    <!-- <mdb-date-picker-2
                      :label="$t('addition.formInput.effective_month')"
                      v-model="deduction_item.effective_month"
                      title="Select date"
                      key="counter"
                    /> -->

                    <MonthDropdown
                      @updateMonth="setMonth"
                      :selected_date="deduction_item.effective_month"
                      :parent_index="counter"
                      :key="rerenderIndex"
                    />

                    <YearDropdown
                      @updateYear="setYear"
                      :selected_date="deduction_item.effective_year"
                      :parent_index="counter"
                      :key="rerenderIndex"
                    />
                  </mdb-col>
                  <mdb-col offsetMd="2" md="8">
                    <mdb-btn
                      type="button"
                      outline="danger"
                      rounded
                      size="sm"
                      @click="deleteDeductionDetail(counter)"
                      class="mt-4"
                      :disabled="shouldDisabledDeleteDeductionListButton"
                      v-show="!doesEditForm"
                    >
                      <mdb-icon icon="trash-alt" size="lg" />
                      {{ $t("general.button.delete") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>
              </div>

              <div v-show="deductions.otd_type == 'same_amount'">
                <mdb-row>
                  <mdb-col offsetMd="2" md="4">
                    <mdb-input
                      type="number"
                      label="Amount"
                      v-model="temp_same_amount.odd_amount"
                      outline
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row><mdb-col offsetMd="2" md="8">From</mdb-col></mdb-row>
                <mdb-row>
                  <mdb-col offsetMd="2" md="4">
                    <MonthDropdown
                      @updateMonth="setFromMonth"
                      :selected_date="''"
                    />
                  </mdb-col>
                  <mdb-col md="4">
                    <YearDropdown
                      @updateYear="setFromYear"
                      :selected_date="''"
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row><mdb-col offsetMd="2" md="8">To</mdb-col></mdb-row>
                <mdb-row>
                  <mdb-col offsetMd="2" md="4">
                    <MonthDropdown
                      @updateMonth="setToMonth"
                      :selected_date="''"
                    />
                  </mdb-col>
                  <mdb-col md="4">
                    <YearDropdown @updateYear="setToYear" :selected_date="''" />
                  </mdb-col>
                </mdb-row>
              </div>

              <mdb-row>
                <mdb-col offsetMd="2" md="8" class="mt-4">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="lg" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="lg"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    outline="danger"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="lg"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />

    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapCheckboxTable
          @updateSelectedEmployeeIDs="setSelectedEmployeeIDs"
          :default_selected_value="source_default_selected_value"
          v-if="_id == undefined"
        />

        <EmployeeListBoostrapSingleSelectTable
          @updateSelectedEmployee="setSelectedEmployee"
          v-if="_id != undefined"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import Deduction from "@/apis/Deduction";
import LogList from "@/components/logs/List";
import { deductionCategoryOption } from "@/constants/employee.js";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";

import EmployeeListBoostrapSingleSelectTable from "@/components/EmployeeListBoostrapSingleSelectTable";
import EmployeeListBoostrapCheckboxTable from "@/components/EmployeeListBoostrapCheckboxTable";
import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";

import YearDropdown from "@/components/YearDropdown";
import MonthDropdown from "@/components/MonthDropdown";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbChip,
  // mdbDatePicker2,
  mdbSelect,
  mdbFormInline,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    // mdbDatePicker2,
    mdbChip,
    mdbSelect,
    ModalDeleteConfimation,
    mdbFormInline,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeInfoBoxLayout,
    EmployeeListBoostrapCheckboxTable,
    EmployeeListBoostrapSingleSelectTable,

    YearDropdown,
    MonthDropdown,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      deductions: {
        otd_id: "",
        otd_employee_id: this.employee_id ? [this.employee_id] : "",
        otd_category: "",
        otd_description: "",
        otd_total_amount: "",
        odd_id: [],
        odd_amount: [],
        odd_effective_month: [],
        otd_type: "different_amount",
      },
      deductionCategoryOption: deductionCategoryOption(true, ""),
      deduction_amount_list: [
        {
          d_id: "",
          amount: "",
          effective_month: "",
          effective_year: "",
        },
      ],
      temp_same_amount: {
        odd_amount: "",
        from_month: "",
        from_year: "",
        to_month: "",
        to_year: "",
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",

      selectedEmployeeData: null,
      modal: false,

      selected_employee_ids: [],
      source_default_selected_value: "",

      rerenderIndex:0
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.deductions.otd_id != "" ? true : false;
    },
    shouldDisabledDeleteDeductionListButton() {
      return this.deduction_amount_list.length > 1 ? false : true;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    setMonth({ value, parent_index }) {
      this.deduction_amount_list[parent_index].effective_month = value;
      // console.log('val'+value)
      // console.log('counter'+parent_index)
    },

    setYear({ value, parent_index }) {
      this.deduction_amount_list[parent_index].effective_year = value;
      // console.log('val'+value)
      // console.log('counter'+parent_index)
    },

    setFromMonth(value) {
      this.temp_same_amount.from_month = value;
    },
    setFromYear(value) {
      this.temp_same_amount.from_year = value;
    },
    setToMonth(value) {
      this.temp_same_amount.to_month = value;
    },
    setToYear(value) {
      this.temp_same_amount.to_year = value;
    },

    modalOpen() {
      this.modal = true;

      this.source_default_selected_value = this.selected_employee_ids.map(
        (e) => {
          return e.employee_id;
        }
      );
    },

    setSelectedEmployeeIDs(val) {
      this.selected_employee_ids = val;

      // this.selectedEmployeeData = null;
    },

    setSelectedEmployee(val) {
      this.modal = false;
      this.selectedEmployeeData = val;
      this.deductions.otd_employee_id = val.employee_id;
    },
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    updateDeductionCategory(val) {
      this.deductions.otd_category = val;
    },
    addDeductionDetail(
      d_id = "",
      amount = "",
      effective_month = "",
      effective_year = ""
    ) {
      this.rerenderIndex++;
      this.deduction_amount_list.push({
        d_id,
        amount,
        effective_month,
        effective_year,
      });
    },
    deleteDeductionDetail(counter) {
      this.rerenderIndex++;
      this.deduction_amount_list.splice(counter, 1);
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          Deduction.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  let otd_id,
                    otd_employee_id,
                    otd_category,
                    otd_description,
                    otd_total_amount;
                  // populate field
                  ({
                    otd_id,
                    otd_employee_id,
                    otd_category,
                    otd_description,
                    otd_total_amount,
                  } = response.data.data);
                  this.deductions.otd_id = otd_id;
                  this.deductions.otd_employee_id = otd_employee_id;
                  this.deductions.otd_category = otd_category;
                  this.deductions.otd_description = otd_description;
                  this.deductions.otd_total_amount = otd_total_amount;
                  //make selected option again
                  this.deductionCategoryOption = deductionCategoryOption(
                    true,
                    otd_category
                  );

                  if (response.data.detail_list.length > 0) {
                    //remove constructor
                    this.deduction_amount_list = [];
                    for (let detail_item of response.data.detail_list) {
                      if (detail_item.odd_effective_month != "") {
                        const parts =
                          detail_item.odd_effective_month.split("-");

                        this.addDeductionDetail(
                          detail_item.odd_id,
                          detail_item.odd_amount,
                          parts[1],
                          parts[0]
                        );
                      } else {
                        this.addDeductionDetail(
                          detail_item.odd_id,
                          detail_item.odd_amount,
                          "",
                          ""
                        );
                      }

                      // this.addDeductionDetail(
                      //   detail_item.odd_id,
                      //   detail_item.odd_amount,
                      //   detail_item.odd_effective_month,
                      //   detail_item.odd_effective_month
                      // );
                    }
                  }

                  if (!this.employee_id) {
                    this.selectedEmployeeData = {
                      epinfo_name: response.data.data.epinfo_name,
                      employee_code: response.data.data.employee_code,
                      profile_image: response.data.data.profile_image,
                      department_name: response.data.data.department_name,
                      jp_name: response.data.data.jp_name,
                      rt_name: response.data.data.rt_name,
                    };
                    //emp info
                  }

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    deductionList: {
                      employee_id: this.employee_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "deductionList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "deductionList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "deductionList" });
        }
      } else {
        // add
      }
    },

    prepareDeductionList() {
      this.deductions.odd_id = [];
      this.deductions.odd_amount = [];
      this.deductions.odd_effective_month = [];
      this.deductions.otd_from = "";
      this.deductions.otd_to = "";

      if (this.deductions.otd_type == "different_amount") {
        if (this.deduction_amount_list.length > 0) {
          for (let deduction_item of this.deduction_amount_list) {
            this.deductions.odd_id.push(deduction_item.d_id);
            this.deductions.odd_amount.push(deduction_item.amount);
            this.deductions.odd_effective_month.push(
              deduction_item.effective_year +
                "-" +
                (parseInt(deduction_item.effective_month) >= 10 ? deduction_item.effective_month : '0'+parseInt(deduction_item.effective_month)) +
                "-01"
            );
          }
        }
      }else{
        this.deductions.odd_amount.push(this.temp_same_amount.odd_amount);
        this.deductions.otd_from = this.temp_same_amount.from_year + "-" + (parseInt(this.temp_same_amount.from_month) >= 10 ? this.temp_same_amount.from_month : '0'+parseInt(this.temp_same_amount.from_month))  + "-01";
        this.deductions.otd_to = this.temp_same_amount.to_year + "-" + (parseInt(this.temp_same_amount.to_month) >= 10 ? this.temp_same_amount.to_month : '0'+parseInt(this.temp_same_amount.to_month))  + "-01";
      
      }
    },

    handleFormSubmit() {

      if (
        this.deductions.otd_type == "same_amount" &&
        (this.temp_same_amount.from_month == "" || this.temp_same_amount.from_year =="" || 
        this.temp_same_amount.to_month == "" || this.temp_same_amount.to_year =="" )
      ) {
        //show error
        this.feedback_message = "Please fill both  of \"From\" and \"To\" month and year.";
        this.feedback_error = true;
        return;
      }   

      this.prepareDeductionList();
      this.resetAlert();
      this.formSubmitting = true;

      if (this.selected_employee_ids.length > 0) {
        this.deductions.otd_employee_id = this.selected_employee_ids.map(
          (e) => {
            return e.employee_id;
          }
        );
      }

      Deduction.save(this.deductions).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            // if (this.deductions.otd_id != "") {
            //   this.$notify.success({
            //     message: response.data.message,
            //     position: "top right",
            //     timeOut: 5000,
            //   });

            //   this.$router.push({
            //     name: "deductionList",
            //     params: { employee_id: this.deductions.otd_employee_id },
            //   });
            // } else {
            //   this.$notify.success({
            //     message: response.data.message,
            //     position: "top right",
            //     timeOut: 5000,
            //   });

            //   this.resetForm();
            // }

            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            if (this.employee_id) {
              this.$router.push({
                name: "deductionList",
                params: { employee_id: this.employee_id },
              });
            } else {
              this.$router.push({ name: "deductionsManageList" });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
    },
    redirectBackAction() {
      // this.$router.push({
      //   name: "deductionList",
      //   params: { employee_id: this.deductions.otd_employee_id },
      // });
      if (this.employee_id) {
        this.$router.push({
          name: "deductionList",
          params: { employee_id: this.employee_id },
        });
      } else {
        this.$router.push({ name: "deductionsManageList" });
      }
    },
    resetAlert() {
      this.feedback_error = false;
    },
    // resetForm() {
    //   Object.keys(this.deductions).forEach((key) => {
    //     this.deductions[key] = "";
    //   });
    //   this.deductions.otd_employee_id = this.employee_id;
    //   this.deduction_amount_list = [
    //     {
    //       id: "",
    //       no_of_day: "",
    //       amount: "",
    //     },
    //   ];
    // },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.deductions.otd_id > 0) {
        Deduction.delete({
          otd_id: this.deductions.otd_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              // this.$router.push({
              //   name: "deductionList",
              //   params: {
              //     employee_id: this.deductions.otd_employee_id,
              //   },
              // });

              if (this.employee_id) {
                this.$router.push({
                  name: "contractList",
                  params: { employee_id: this.employee_id },
                });
              } else {
                this.$router.push({ name: "deductionsManageList" });
              }
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      deductionList: {
        employee_id: this.employee_id,
      },
    };
    this.fetchRecord();
  },
};
</script>