var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.feedback_error)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[(typeof _vm.feedback_message === 'string')?_c('div',[_vm._v(" "+_vm._s(_vm.feedback_message)+" ")]):_vm._l((_vm.feedback_message),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error.message)+" ")])})],2):_vm._e(),[_c('div',{staticClass:"d-flex justify-content-center"},[_c('mdb-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"big":"","color":"blue"}})],1),(!_vm.isLoading)?_c('div',[_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-3"},[_vm._v("Title")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v(_vm._s(_vm.announcement.title))]),_c('dt',{staticClass:"col-sm-3"},[_vm._v("Description")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v(_vm._s(_vm.announcement.description))]),_c('dt',{staticClass:"col-sm-3"},[_vm._v("Total")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v(_vm._s(_vm.announcement.employee_count.total))]),_c('dt',{staticClass:"col-sm-3"},[_vm._v("Read")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v(_vm._s(_vm.announcement.employee_count.read))]),_c('dt',{staticClass:"col-sm-3"},[_vm._v("Unread")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v(_vm._s(_vm.announcement.employee_count.unread))]),_c('dt',{staticClass:"col-sm-3"},[_vm._v("Attachments")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.announcement.attachments.length > 0)?_c('div',_vm._l((_vm.announcement.attachments),function(attachment_item,index){return _c('div',{key:index},[_c('div',{staticClass:"float-left attachments_text_style"},[_c('a',{attrs:{"href":attachment_item.attachment_file_name,"target":"_blank"}},[_c('mdb-card',{staticClass:"attachment_card_style",attrs:{"cascade":""}},[_c('mdb-view',{attrs:{"hover":"","cascade":""}},[[(
                      attachment_item.file_ext == 'png' ||
                      attachment_item.file_ext == 'gif' ||
                      attachment_item.file_ext == 'jpg' ||
                      attachment_item.file_ext == 'jpeg'
                    )?_c('mdb-card-image',{staticClass:"announcement_thumbnail_image",attrs:{"src":attachment_item.attachment_file_name}}):(
                      attachment_item.file_ext == 'doc' ||
                      attachment_item.file_ext == 'docx'
                    )?_c('mdb-card-image',{staticClass:"announcement_thumbnail_image",attrs:{"src":"/doc.png","height":"100"}}):(
                      attachment_item.file_ext == 'xls' ||
                      attachment_item.file_ext == 'xlsx'
                    )?_c('mdb-card-image',{staticClass:"announcement_thumbnail_image",attrs:{"src":"/spreadsheet.png","height":"100"}}):(
                      attachment_item.file_ext == 'ppt' ||
                      attachment_item.file_ext == 'ppt'
                    )?_c('mdb-card-image',{staticClass:"announcement_thumbnail_image",attrs:{"src":"/powerpoint.png","height":"100"}}):(
                      attachment_item.file_ext == 'pdf'
                    )?_c('mdb-card-image',{staticClass:"announcement_thumbnail_image",attrs:{"src":"/pdf.png","height":"100"}}):_c('mdb-card-image',{staticClass:"announcement_thumbnail_image",attrs:{"src":"/unknowfile.png","height":"100"}})]],2),_c('mdb-card-body',{staticClass:"text-center em_attachments_card_body",attrs:{"cascade":""}},[_vm._v(" "+_vm._s(_vm._f("filename_str_limit")(attachment_item.attachment_original_file_name))+" ")])],1),_c('br')],1)])])}),0):_vm._e()])]),_c('div',{staticClass:"text-right"},[_c('mdb-btn',{staticClass:"btn_outline_style",attrs:{"type":"button","color":"primary","icon":"edit"},on:{"click":_vm.redirectUpdateForm}},[_vm._v(" "+_vm._s(_vm.$t("general.button.edit"))+" ")])],1),_c('mdb-card',[_c('mdb-card-body',[_c('mdb-tbl',{attrs:{"responsive":"","hover":""}},[_c('mdb-tbl-head',[_c('tr',[_c('th',{attrs:{"colspan":""},on:{"click":function($event){return _vm.sort('epinfo_name')}}},[_c('div',{staticClass:"name_center"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentSort === 'epinfo_name'),expression:"currentSort === 'epinfo_name'"}],class:[
                        _vm.currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ' ],attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t("general.formInput.name"))+" ")])]),_c('th',{on:{"click":function($event){return _vm.sort('employee_code')}}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentSort === 'employee_code'),expression:"currentSort === 'employee_code'"}],class:[
                      _vm.currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ' ],attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t("employees.formInput.employee_id"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("employees.formInput.rank"))+" ")]),_c('th',[_vm._v("Status")])])]),_c('mdb-tbl-body',_vm._l((_vm.pageOfItems),function(record_item,index){return _c('tr',{key:index,attrs:{"scope":"row"}},[_c('td',{staticClass:"align-middle",attrs:{"scope":"row"}},[_c('EmployeeInfoTableCellLayout',{attrs:{"user":record_item}})],1),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(record_item.employee_code)+" ")]),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(record_item.rt_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(record_item.status)+" ")])])}),0)],1),_c('div',{staticClass:"card-footer pb-0 pt-3"},[_c('jw-pagination',{attrs:{"items":_vm.sortedRecordList,"pageSize":_vm.rowsPerPage},on:{"changePage":_vm.onChangePage}})],1)],1)],1)],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }