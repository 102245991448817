import Api from './api'

export default {

    requestQueuelist(payload) {
        return Api.get('api/payroll/request_payroll/list', { params: payload })
    },

    create(payload) {
        return Api.post('api/payroll/request_payroll/data_entry', payload)
    },


    getPayslips(payload) {
        return Api.get('api/payroll/payslip/list', { params: payload })
    },


    getPayslipDetail(payload) {
        return Api.get('api/payroll/payslip/detail', { params: payload })
    },

    deletePayslip(payload) {
        return Api.post('api/payroll/payslip/delete', payload)
    },

    
    deleteMultiplePayslip(payload) {
        return Api.post('api/payroll/payslip/multi_delete', payload)
    },

    
    downloadPayslip(payload) {
        return Api.post('api/payroll/payslip/download', payload)
    },


    
    publishingList(payload) {
        return Api.get('api/payroll/payslip/publish/list', { params: payload })
    },

    
    publishingAction(payload) {
        return Api.post('api/payroll/payslip/publish', payload)
    },

}