<template>
  <div class="select-wrapper">
    <div class="md-form select-dropdown">
      <ejs-dropdowntree
        placeholder="Select Department"
        ref="ddtInstance"
        id="dropdowntree"
        :fields="fields"
        :changeOnBlur="false"
        @change="onchange"
        :value="defaultVal"
        :showCheckBox="true"
        :treeSettings="treeSettings"
        :showSelectAll="true"
        :selectAllText="selectText"
        :unSelectAllText="unSelectText"
      ></ejs-dropdowntree>
    </div>
  </div>
</template>

<script>
import Department from "@/apis/Department";
import { treeBuilderDropdown } from "@/utilities/treeBuilder";

import Vue from "vue";
import { DropDownTreePlugin } from "@syncfusion/ej2-vue-dropdowns";
Vue.use(DropDownTreePlugin);

export default {
  name: "DepartmentTreeDropdown",
  props: ["default_selected_value", "required", "resetSelected"],
  data() {
    return {
      fields: null,
      loading: true,
      defaultVal: [],
      treeSettings: { autoCheck: true },
      selectText: "Check All",
      unSelectText: "UnCheck All",
    };
  },

  methods: {
    onchange: function () {
      let defaultObj = this.$refs.ddtInstance.ej2Instances;
      // var value = document.getElementById('value');
      // var text = document.getElementById('text');
      // value.innerHTML = defaultObj.value.toString();
      // text.innerHTML = defaultObj.text;
      // this.$emit("updateDepartmentID", defaultObj.value.toString());
      
      this.$emit("updateDepartmentID", defaultObj.value);
    },

    fetchDepartmentList(doesFormReset) {
      Department.all().then((response) => {
        if (response.status === 200) {
          const axiosOptions = response.data.data;

          if (doesFormReset === false) {
            if (this.default_selected_value) {
              this.defaultVal = [this.default_selected_value.toString()];
            }
          } else {
            this.defaultVal = [];
          }

          this.fields = {
            dataSource: treeBuilderDropdown(
              axiosOptions,
              this.default_selected_value
            ),
            value: "department_id",
            parentValue: "department_parent_id",
            text: "department_name",
            hasChildren: "hasChild",
          };

          this.loading = false;
        }
      });
    },
  },

  mounted() {
    this.fetchDepartmentList(false);
  },

  watch: {
    resetSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchDepartmentList(true);
      }
    },
  },
};
</script>

<style>
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left) {
  box-sizing: border-box;
  background-color: transparent;
  border: 1px solid #dadce0;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  transition: all 0.3s;
  padding: 0 0.75rem;
}
</style>