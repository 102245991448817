<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

<div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
    <template>
      <mdb-card class="mb-5">
        <mdb-card-body>
          <form @submit.prevent="handleFormSubmit">
            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <mdb-input
                  type="text"
                  :label="$t('general.formInput.name')"
                  v-model="contract_status.ct_name"
                  outline
                  required
                />
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <label class="form-label">
                  {{ $t("general.formInput.active_contract") }}
                </label>
                <mdb-form-inline>
                  <mdb-input
                    type="radio"
                    id="req-opt-yes"
                    radioValue="no"
                    v-model="contract_status.ct_active"
                    :label="$t('general.formInput.no')"
                  />
                  <mdb-input
                    type="radio"
                    id="req-opt-no"
                    radioValue="yes"
                    v-model="contract_status.ct_active"
                    :label="$t('general.formInput.yes')"
                  />
                </mdb-form-inline>
                <br />
              </mdb-col>
            </mdb-row>


            <mdb-row v-show="contract_status.ct_active == 'no'">
              <mdb-col offsetMd="2" md="8">
                <label class="form-label">
                  Separation
                </label>
                <mdb-form-inline>
                  <mdb-input
                    type="radio"
                    id="ct_quit-yes"
                    radioValue="no"
                    v-model="contract_status.ct_quit"
                    :label="$t('general.formInput.no')"
                  />
                  <mdb-input
                    type="radio"
                    id="ct_quit-no"
                    radioValue="yes"
                    v-model="contract_status.ct_quit"
                    :label="$t('general.formInput.yes')"
                  />
                </mdb-form-inline>
                <br />
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col offsetMd="2" md="8"> 
                <mdb-btn
              type="button"
              class="btn_back"
              color="uno-color"
              @click="redirectBackAction"
            >
              <mdb-icon icon="arrow-left" size="md" />
              {{ $t("general.button.go_back") }}
            </mdb-btn>

            <mdb-btn type="Submit" color="uno-color" class="btn_submit" :disabled="formSubmitting">
              <mdb-icon icon="file-upload" size="md" v-show="!formSubmitting" />

              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="formSubmitting"
              ></span>
              {{ $t("general.button.submit") }}
            </mdb-btn>

            
          <mdb-btn
            type="button"            
            :disabled="deleteSubmitted"
            @click="showDeleteModal"
            v-show="doesEditForm"
            color="uno-color"
            class="btn_delete float-right"
          >
            <mdb-icon
              icon="trash-alt"
              far
              size="md"
              v-show="!deleteSubmitted"
            />

            <span
              class="spinner-border-vcenter spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-show="deleteSubmitted"
            ></span>
            {{ $t("general.button.delete") }}
          </mdb-btn>

              </mdb-col>
            </mdb-row>

            
          </form>
         

        </mdb-card-body>
      </mdb-card>
    </template>
    </div>

    <log-list :logs="logs"></log-list>

    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord"/>
  </div>
</template>
<script>
import ContractStatus from "@/apis/ContractStatus";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbSpinner,
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbSpinner,
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbFormInline,
    ModalDeleteConfimation
  },
  props: ["_id"],
  data() {
    return {
      contract_status: {
        ct_id: "",
        ct_name: "",
        ct_active: "no",
        ct_quit: "no"
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.contract_status.ct_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
        this.$store.state.deleteModalVisible = true;
      },
    fetchContractStatus() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          ContractStatus.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)
                  let ct_name, ct_active, ct_id, ct_quit;

                  // populate field
                  ({ ct_name, ct_active, ct_id, ct_quit } = response.data.data);
                  this.contract_status.ct_id = ct_id;
                  this.contract_status.ct_name = ct_name;
                  this.contract_status.ct_active =
                    ct_active == "1" ? "yes" : "no";
                  this.contract_status.ct_quit = ct_quit == "1" ? "yes" : "no";  

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              } else {
                this.updateLoading(false);
                this.$router.push({ name: "listContractStatus" });
              }
            })
            .catch(() => {
              this.updateLoading(false);
              this.$router.push({ name: "listContractStatus" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listContractStatus" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      ContractStatus.save(this.contract_status).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.contract_status.ct_id != "") {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "listContractStatus" });
            } else {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({ name: "listContractStatus" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.contract_status).forEach((key) => {
        this.contract_status[key] = "";
      });
      this.contract_status.ct_active= "no"
      this.contract_status.ct_quit = "no"
    },
    handleDeleteRecord() {
      this.$store.state.deleteModalVisible = false;
      this.deleteSubmitted = true;

      if (this.contract_status.ct_id > 0) {
        ContractStatus.delete({ ct_id: this.contract_status.ct_id })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: "Successfully Deleted.",
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({ name: "listContractStatus" });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          // .catch((error) => {
          //   console.log(error);
          // });
      }

      
    },
  },
  created() {
    this.fetchContractStatus();
  },
};
</script>