<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>


    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">

    <template>
      <mdb-card class="mb-5">
        <mdb-card-body>
          <form @submit.prevent="handleFormSubmit">
            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <mdb-input
                  type="text"
                  :label="$t('general.formInput.name')"
                  v-model="departments.department_name"
                  outline
                  required
                />
              </mdb-col>

              <mdb-col offsetMd="2" md="8">
                <DepartmentTreeDropdown
                  @updateDepartmentID="setDepartmentID"
                  :default_selected_value="departments.department_parent_id"
                  :required="true"
                  :resetSelected="doesFormReset"
                />
              </mdb-col>


              <mdb-col offsetMd="2" md="8">
                <mdb-input
                  type="text"
                  label="Department Group"
                  v-model="departments.department_group_name"
                  outline
                  required
                />
              </mdb-col>

              <!-- <mdb-col offsetMd="2" md="8">
                <mdb-input
                  type="number"
                  :label="$t('general.formInput.sort')"
                  v-model="departments.department_sort"
                  outline
                />
              </mdb-col> -->

              <mdb-col offsetMd="2" md="8">
                <mdb-btn
                  type="button"
                  class="btn_back"
                  color="uno-color"
                  @click="redirectBackAction"
                >
                  <mdb-icon icon="arrow-left" size="md" />
                  {{ $t("general.button.go_back") }}
                </mdb-btn>

                <mdb-btn
                  type="Submit"
                  color="uno-color"
                  class="btn_submit"
                  :disabled="formSubmitting"
                >
                  <mdb-icon
                    icon="file-upload"
                    size="md"
                    v-show="!formSubmitting"
                  />

                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="formSubmitting"
                  ></span>
                  {{ $t("general.button.submit") }}
                </mdb-btn>

                <mdb-btn
                  type="button"
                  color="uno-color"
                  class="btn_delete float-right"
                  :disabled="deleteSubmitted"
                  @click="showDeleteModal"
                  v-show="doesEditForm"
                >
                  <mdb-icon
                    icon="trash-alt"
                    far
                    size="md"
                    v-show="!deleteSubmitted"
                  />

                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="deleteSubmitted"
                  ></span>
                  {{ $t("general.button.delete") }}
                </mdb-btn>
              </mdb-col>
            </mdb-row>
          </form>
          <hr />
        </mdb-card-body>
      </mdb-card>
    </template>
    </div>

    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord"/>
  </div>
</template>
<script>
import Department from "@/apis/Department";
import LogList from "@/components/logs/List";
import DepartmentTreeDropdown from "@/components/DepartmentTreeDropdown";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";	
import {
  mdbSpinner,
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
} from "mdbvue";

export default {
  components: {
    mdbSpinner,
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    DepartmentTreeDropdown,
    ModalDeleteConfimation
  },
  props: ["_id"],
  data() {
    return {
      departments: {
        department_id: "",
        department_name: "",
        department_group_name:"",
        department_parent_id: "",
        department_sort: "",
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.departments.department_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    setDepartmentID(val) {
      this.departments.department_parent_id = val;
    },
      showDeleteModal() {
        this.$store.state.deleteModalVisible = true;
      },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
           this.updateLoading(true);
          Department.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)
                  let department_id,
                    department_name,
                    department_group_name,
                    department_parent_id,
                    department_sort;

                  // populate field
                  ({
                    department_id,
                    department_name,
                    department_group_name,
                    department_parent_id,
                    department_sort,
                  } = response.data.data);
                  this.departments.department_id = department_id;
                  this.departments.department_name = department_name;
                  this.departments.department_group_name = department_group_name;
                  this.departments.department_parent_id = department_parent_id;
                  this.departments.department_sort = department_sort;



                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              } else {
                this.updateLoading(false);
                this.$router.push({ name: "listDepartment" });
              }
            })
            .catch(() => {
              this.updateLoading(false);
              this.$router.push({ name: "listDepartment" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listDepartment" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      Department.save(this.departments).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.departments.department_id != "") {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "listDepartment" });
            } else {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({ name: "listDepartment" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.departments).forEach((key) => {
        this.departments[key] = "";
      });
      this.doesFormReset = !this.doesFormReset;
    },
    handleDeleteRecord() {
      this.$store.state.deleteModalVisible = false;
      this.deleteSubmitted = true;

      if (this.departments.department_id > 0) {
        Department.delete({
          department_id: this.departments.department_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              this.$router.push({ name: "listDepartment" });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }

      // this.deleteSubmitted = false;
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>