<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('training_info.formInput.title')"
                    v-model="trainingInfos.hrt_title"
                    outline
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('training_info.formInput.trainer')"
                    v-model="trainingInfos.hrt_trainer"
                    outline
                    required
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-select
                    v-model="hrTrainingTypeOptions"
                    placeholder="Select Training Type"
                    label=""
                    outline
                    @getValue="updateTrainingTypeValue"
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('training_info.formInput.school')"
                    v-model="trainingInfos.hrt_school"
                    outline
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('training_info.formInput.location')"
                    v-model="trainingInfos.hrt_location"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    :label="$t('training_info.formInput.start_date')"
                    v-model="trainingInfos.hrt_start_date"
                    title="Select date"
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-date-picker-2
                    v-model="trainingInfos.hrt_end_date"
                    :label="$t('training_info.formInput.end_date')"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('training_info.formInput.category')"
                    v-model="trainingInfos.hrt_category"
                    outline
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-input
                    type="number"
                    :label="$t('training_info.formInput.cost')"
                    v-model="trainingInfos.hrt_cost"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="number"
                    :label="$t('training_info.formInput.duration_days')"
                    v-model="trainingInfos.hrt_days"
                    outline
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-input
                    type="number"
                    :label="$t('training_info.formInput.duration_hours')"
                    v-model="trainingInfos.hrt_hours"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label class="form-label">
                    {{ $t("training_info.formInput.hrt_contract") }}
                  </label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="req-opt-no"
                      radioValue="no"
                      v-model="trainingInfos.hrt_contract"
                      :label="$t('general.formInput.no')"
                    />
                    <mdb-input
                      type="radio"
                      id="req-opt-yes"
                      radioValue="yes"
                      v-model="trainingInfos.hrt_contract"
                      :label="$t('general.formInput.yes')"
                    />
                  </mdb-form-inline>
                  <br />
                </mdb-col>
              </mdb-row>

              <mdb-row v-show="trainingInfos.hrt_contract == 'yes'">
                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    :label="$t('training_info.formInput.contract_start_date')"
                    v-model="trainingInfos.hrt_contract_start_date"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-date-picker-2
                    v-model="trainingInfos.hrt_contract_end_date"
                    :label="$t('training_info.formInput.contract_end_date')"
                    title="Select date"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    outline="primary"
                    @click.native="selectEmployeeModalOpen()"
                  >
                    Select Employee
                  </mdb-btn>

                  <mdb-tbl responsive v-if="selectedEmployeeIDs.length > 0">
                    <!-- Table head -->
                    <mdb-tbl-head>
                      <tr>
                        <th>Employee Info</th>
                        <th>Completion & Remark</th>
                      </tr>
                    </mdb-tbl-head>
                    <!-- Table head -->

                    <!-- Table body -->
                    <mdb-tbl-body>
                      <tr
                        v-for="(employee, index) in selectedEmployeeIDs"
                        :key="index"
                      >
                        <td>
                          <EmployeeInfoTableCellLayout :user="employee" />
                        </td>
                        <td>
                          <label>Completion</label>
                          <mdb-form-inline>
                            <mdb-input
                              type="radio"
                              :name="'tre_status' + index"
                              :id="'req-opt-complete' + index"
                              radioValue="complete"
                              v-model="selectedEmployeeIDs[index].tre_status"
                              :label="$t('general.formInput.yes')"
                            />
                            <!-- @change="
                                updateCompleteStatus($event.target.value, index)
                              " -->
                            <mdb-input
                              type="radio"
                              :name="'tre_status' + index"
                              :id="'req-opt-incomplete' + index"
                              radioValue="incomplete"
                              v-model="selectedEmployeeIDs[index].tre_status"
                              :label="$t('general.formInput.no')"
                            />
                            <!-- @change="
                                updateCompleteStatus($event.target.value, index)
                              " -->
                          </mdb-form-inline>
                        
                          <mdb-input
                            type="textarea"
                            :label="$t('general.formInput.remark')"
                            v-model="selectedEmployeeIDs[index].tre_remark"
                            outline
                          />
                          <!-- @change="updateRemark($event.target.value, index)" -->
                        </td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    outline="default"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="lg" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    outline="primary"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="lg"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    outline="danger"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    class="float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="lg"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <!-- {{ selectedEmployeeIDs }} -->
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />

    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapCheckboxTable
          @updateSelectedEmployeeIDs="setSelectedEmployeeIDs"
          :default_selected_value="defaultSelectedEmployeeIDs"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import HrTraining from "@/apis/HrTraining";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { hrTrainingType } from "@/constants/employee.js";
import EmployeeListBoostrapCheckboxTable from "@/components/EmployeeListBoostrapCheckboxTable";

import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  mdbSelect,
  mdbFormInline,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbDatePicker2,
    mdbSelect,
    mdbFormInline,
    ModalDeleteConfimation,
    EmployeeListBoostrapCheckboxTable,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    EmployeeInfoTableCellLayout,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      trainingInfos: {
        hrt_id: "",
        hrt_title: "",
        hrt_trainer: "",
        hrt_type: "",
        hrt_start_date: "",
        hrt_end_date: "",
        hrt_days: "",
        hrt_hours: "",
        hrt_cost: "",
        hrt_school: "",
        hrt_location: "",
        hrt_contract: "no",
        hrt_contract_start_date: "",
        hrt_contract_end_date: "",
        hrt_category: "",
        employe_ids: "",
        employe_status: "",
        employe_remark: "",
      },
      trainees: [],
      hrTrainingTypeOptions: hrTrainingType(true, ""),
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",

      modal: false,
      selectedEmployeeIDs: [],
      defaultSelectedEmployeeIDs: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.trainingInfos.hrt_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    updateTrainingTypeValue(val) {
      this.trainingInfos.hrt_type = val;
    },
    updateCompleteStatus(val, index) {
      if (this.selectedEmployeeIDs.length >= index) {
        this.selectedEmployeeIDs[index].tre_status = val;
      }
    },
    updateRemark(val, index) {
      if (this.selectedEmployeeIDs.length >= index) {
        this.selectedEmployeeIDs[index].tre_remark = val;
      }
    },

    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          HrTraining.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)

                  let hrt_id,
                    hrt_title,
                    hrt_trainer,
                    hrt_type,
                    hrt_start_date,
                    hrt_end_date,
                    hrt_days,
                    hrt_hours,
                    hrt_cost,
                    hrt_school,
                    hrt_location,
                    hrt_contract,
                    hrt_contract_start_date,
                    hrt_contract_end_date,
                    hrt_category;
                  // employe_ids,
                  // employe_status,
                  // employe_remark;
                  // populate field
                  ({
                    hrt_id,
                    hrt_title,
                    hrt_trainer,
                    hrt_type,
                    hrt_start_date,
                    hrt_end_date,
                    hrt_days,
                    hrt_hours,
                    hrt_cost,
                    hrt_school,
                    hrt_location,
                    hrt_contract,
                    hrt_contract_start_date,
                    hrt_contract_end_date,
                    hrt_category,
                    // employe_ids,
                    // employe_status,
                    // employe_remark,
                  } = response.data.data);

                  this.trainingInfos.hrt_id = hrt_id;
                  this.trainingInfos.hrt_title = hrt_title;
                  this.trainingInfos.hrt_trainer = hrt_trainer;
                  this.trainingInfos.hrt_type = hrt_type;
                  this.trainingInfos.hrt_start_date = hrt_start_date;
                  this.trainingInfos.hrt_end_date = hrt_end_date;
                  this.trainingInfos.hrt_days = hrt_days;
                  this.trainingInfos.hrt_hours = hrt_hours;
                  this.trainingInfos.hrt_cost = hrt_cost;
                  this.trainingInfos.hrt_school = hrt_school;
                  this.trainingInfos.hrt_location = hrt_location;
                  this.trainingInfos.hrt_contract = hrt_contract;
                  this.trainingInfos.hrt_contract_start_date =
                    hrt_contract_start_date;
                  this.trainingInfos.hrt_contract_end_date =
                    hrt_contract_end_date;
                  this.trainingInfos.hrt_category = hrt_category;
                  // this.trainingInfos.employe_ids = employe_ids;
                  // this.trainingInfos.employe_status = employe_status;
                  // this.trainingInfos.employe_remark = employe_remark;

                  this.hrTrainingTypeOptions = hrTrainingType(true, hrt_type);

                  if (response.data.trainees.length > 0) {
                    this.trainees = response.data.trainees;

                    this.defaultSelectedEmployeeIDs = this.trainees.map((e) => {
                      return e.employee_id;
                    });

                    this.selectedEmployeeIDs = this.trainees.map((e) => {
                      return e;
                    });
                  }

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listHrTraining" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listHrTraining" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listHrTraining" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      if (this.selectedEmployeeIDs.length < 1) {
        this.feedback_message = "Select at least one employee";
        this.feedback_error = true;
        this.formSubmitting = false;

        this.$notify.error({
          message: this.feedback_message,
          position: "top right",
          timeOut: 5000,
        });
      } else {
        this.trainingInfos.employe_ids = this.selectedEmployeeIDs.map((e) => {
          return e.employee_id;
        });

        this.trainingInfos.employe_status = this.selectedEmployeeIDs.map(
          (e) => {
            if (!e.tre_status || e.tre_status == "") {
              return "complete";
            } else {
              return e.tre_status;
            }
          }
        );

        this.trainingInfos.employe_remark = this.selectedEmployeeIDs.map(
          (e) => {
            return e.tre_remark;
          }
        );

        HrTraining.save(this.trainingInfos).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              if (this.trainingInfos.hrt_id != "") {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });
                this.$router.push({
                  name: "listHrTraining",
                });
              } else {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });
                this.resetForm();
              }
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.formSubmitting = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },

    setSelectedEmployeeIDs(val) {
      // if (this.trainees.length > 0) {
      //   this.selectedEmployeeIDs = val.map((e) => {
      //     let existing_trainee = this.trainees.find(
      //       (x) => x.employee_id == e.employee_id
      //     );
      //     if (existing_trainee) {
      //       e.tre_remark = existing_trainee.tre_remark;
      //       e.tre_status = existing_trainee.tre_status;
      //       return e;
      //     } else {
      //       e.tre_remark = null;
      //       e.tre_status = null;
      //       return e;
      //     }
      //   });
      // } else {

      // }

      if (val.length > 0) {
        let temp_selectedEmployeeIDs = this.selectedEmployeeIDs;
        temp_selectedEmployeeIDs = val.map((e) => {
          if (temp_selectedEmployeeIDs.length > 0) {
            let existing_trainee = temp_selectedEmployeeIDs.find(
              (x) => x.employee_id == e.employee_id
            );

            if (existing_trainee) {
              e.tre_remark = existing_trainee.tre_remark;
              e.tre_status = existing_trainee.tre_status;
              return e;
            } else {
              e.tre_remark = null;
              e.tre_status = null;
              return e;
            }
          } else {
            e.tre_remark = null;
            e.tre_status = null;
            return e;
          }
        });

        this.selectedEmployeeIDs = temp_selectedEmployeeIDs;
      } else {
        this.selectedEmployeeIDs = val;
      }

      console.log(this.selectedEmployeeIDs);
    },

    selectEmployeeModalOpen() {
      this.modal = true;

      this.defaultSelectedEmployeeIDs = this.selectedEmployeeIDs.map((e) => {
        return e.employee_id;
      });
    },

    redirectBackAction() {
      this.$router.push({
        name: "listHrTraining",
      });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.trainingInfos).forEach((key) => {
        this.trainingInfos[key] = "";
      });
      this.trainingInfos.hrt_contract = "no";

      this.trainees = [];
      this.hrTrainingTypeOptions = hrTrainingType(true, "");

      this.selectedEmployeeIDs = [];
      this.defaultSelectedEmployeeIDs = "";
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.trainingInfos.hrt_id > 0) {
        HrTraining.delete({
          hrt_id: this.trainingInfos.hrt_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              this.$router.push({
                name: "listHrTraining",
              });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>