<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="overtime_rules.ot_rule_name"
                    outline
                    required
                  />
                </mdb-col>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    :label="$t('general.formInput.description')"
                    v-model="overtime_rules.ot_rule_description"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <label>{{ $t("general.formInput.status") }}</label>

                  <mdb-input
                    type="radio"
                    id="ot_rule_status_no_ot"
                    name="ot_rule_status"
                    radioValue="no_ot"
                    v-model="overtime_rules.ot_rule_status"
                    :label="$t('overtime_rule.formInput.no_ot')"
                  />
                  <mdb-input
                    type="radio"
                    id="ot_rule_status_fixedotpermonth"
                    name="ot_rule_status"
                    radioValue="fixedotpermonth"
                    v-model="overtime_rules.ot_rule_status"
                    :label="$t('overtime_rule.formInput.fixedotpermonth')"
                  />
                  <mdb-input
                    type="radio"
                    id="ot_rule_status_fixedotperday"
                    name="ot_rule_status"
                    radioValue="fixedotperday"
                    v-model="overtime_rules.ot_rule_status"
                    :label="$t('overtime_rule.formInput.fixedotperday')"
                  />
                  <mdb-input
                    type="radio"
                    id="ot_rule_status_timebaseot"
                    name="ot_rule_status"
                    radioValue="timebaseot"
                    v-model="overtime_rules.ot_rule_status"
                    :label="$t('overtime_rule.formInput.timebaseot')"
                  />
                  <mdb-form-inline></mdb-form-inline>
                </mdb-col>
              </mdb-row>

              <div v-if="this.overtime_rules.ot_rule_status == 'fixedotperday'">
                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <label>OT Request Type</label>
                    <mdb-input
                      class="mb-2"
                      type="radio"
                      id="calculate_ot_request"
                      name="ot_rule_calculated_type"
                      radioValue="calculate_ot_request"
                      v-model="overtime_rules.ot_rule_calculated_type"
                      :label="$t('overtime_rule.formInput.request_ot_time')"
                    />
                    <mdb-input
                      type="radio"
                      id="calculate_attendance_time"
                      name="ot_rule_calculated_type"
                      radioValue="calculate_attendance_time"
                      v-model="overtime_rules.ot_rule_calculated_type"
                      :label="$t('overtime_rule.formInput.request_ot_chk_time')"
                    />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <mdb-input
                      type="text"
                      :label="$t('overtime_rule.formInput.ot_min_hourper_day')"
                      v-model="overtime_rules.ot_rule_min_hours_fixed_per_day"
                      outline
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col offsetMd="2" md="4">
                    <mdb-input
                      type="text"
                      :label="$t('overtime_rule.formInput.otstarting_before')"
                      v-model="overtime_rules.ot_start_before_office_time"
                      outline
                    />
                  </mdb-col>
                  <mdb-col md="4">
                    <mdb-input
                      type="text"
                      :label="$t('overtime_rule.formInput.otstarting_after')"
                      v-model="overtime_rules.ot_start_after_office_time"
                      outline
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <mdb-input
                      type="text"
                      :label="$t('overtime_rule.formInput.amount')"
                      v-model="overtime_rules.ot_rule_amount"
                      outline
                    />
                  </mdb-col>
                </mdb-row>
              </div>

              <div
                v-else-if="this.overtime_rules.ot_rule_status == 'timebaseot'"
              >
                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <label>OT Request Type</label>
                    <mdb-input
                      class="mb-2"
                      type="radio"
                      id="timebaseot_calculate_ot_request"
                      name="ot_rule_calculated_type"
                      radioValue="calculate_ot_request"
                      v-model="overtime_rules.ot_rule_calculated_type"
                      :label="$t('overtime_rule.formInput.request_ot_time')"
                    />
                    <mdb-input
                      class="mb-2"
                      type="radio"
                      id="timebaseot_calculate_attendance_time"
                      name="ot_rule_calculated_type"
                      radioValue="calculate_attendance_time"
                      v-model="overtime_rules.ot_rule_calculated_type"
                      :label="$t('overtime_rule.formInput.request_ot_chk_time')"
                    />

                     <mdb-input
                      class="mb-2"
                      type="radio"
                      id="calculate_attendance_time_or_ot_request"
                      name="ot_rule_calculated_type"
                      radioValue="calculate_attendance_time_or_ot_request"
                      v-model="overtime_rules.ot_rule_calculated_type"
                      :label="$t('overtime_rule.formInput.calculate_attendance_time_or_ot_request')"
                    />

                    <mdb-input
                      type="radio"
                      id="timebaseot_no_ot_request"
                      name="ot_rule_calculated_type"
                      radioValue="no_ot_request"
                      v-model="overtime_rules.ot_rule_calculated_type"
                      :label="$t('overtime_rule.formInput.no_ot_request')"
                    />

                  </mdb-col>
                </mdb-row>
                <!-- <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <mdb-input
                      type="text"
                      :label="$t('overtime_rule.formInput.ot_min_hourper_day')"
                      v-model="overtime_rules.ot_rule_min_hours_fixed_per_day"
                      outline
                    />
                  </mdb-col>
                </mdb-row> -->

                <mdb-row>
                  <mdb-col offsetMd="2" md="4">
                    <mdb-input
                      type="text"
                      :label="$t('overtime_rule.formInput.otstarting_before')"
                      v-model="overtime_rules.ot_start_before_office_time"
                      outline
                    />
                  </mdb-col>

                  <mdb-col md="4">
                    <mdb-input
                      type="text"
                      :label="$t('overtime_rule.formInput.otstarting_after')"
                      v-model="overtime_rules.ot_start_after_office_time"
                      outline
                    />
                  </mdb-col>
                </mdb-row>

                <!-- Start Working Day Rules -->

                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <label>{{
                      $t("overtime_rule.formInput.working_day_rule")
                    }}</label>

                    <mdb-row>
                      <mdb-col md="6">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="fixed_amount_hrs"
                          name="ot_working_day_rule"
                          radioValue="fixed_amount_hrs"
                          v-model="overtime_rules.ot_working_day_rule"
                          :label="$t('overtime_rule.formInput.base_fix_amount')"
                        />
                      </mdb-col>
                      <mdb-col md="6">
                        <mdb-input
                          class="mt-0 mb-0"
                          type="text"
                          v-model="overtime_rules.ot_wd_fix_amount_hrs"
                          outline
                          :disabled="
                            overtime_rules.ot_working_day_rule !=
                            'fixed_amount_hrs'
                          "
                        />
                      </mdb-col>
                    </mdb-row>
                    <mdb-row>
                      <mdb-col md="6">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="formula"
                          name="ot_working_day_rule"
                          radioValue="formula"
                          v-model="overtime_rules.ot_working_day_rule"
                          :label="$t('overtime_rule.formInput.base_on_formula')"
                        />
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col md="6" lg="6">
                        <mdb-input
                          type="radio"
                          class="mb-2"
                          id="formula_monthly_multiply"
                          name="ot_working_day_rule"
                          radioValue="formula_monthly_multiply"
                          v-model="overtime_rules.ot_working_day_rule"
                          :label="
                            $t('overtime_rule.formInput.base_formula_with')
                          "
                        />
                      </mdb-col>
                      <mdb-col md="6" lg="6">
                        <mdb-input
                          type="text"
                          class="mt-0"
                          v-model="
                            overtime_rules.ot_wd_formula_monthly_multiplyer
                          "
                          outline
                          :disabled="
                            overtime_rules.ot_working_day_rule !=
                            'formula_monthly_multiply'
                          "
                        />
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col md="12">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="basic_salary_hourly_total_days"
                          name="ot_working_day_rule"
                          radioValue="basic_salary_hourly_total_days"
                          v-model="overtime_rules.ot_working_day_rule"
                          :label="
                            $t(
                              'overtime_rule.formInput.basic_salary_hourly_total_days'
                            )
                          "
                        />
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col md="12">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="basic_salary_hourly_working_days"
                          name="ot_working_day_rule"
                          radioValue="basic_salary_hourly_working_days"
                          v-model="overtime_rules.ot_working_day_rule"
                          :label="
                            $t(
                              'overtime_rule.formInput.basic_salary_hourly_working_days'
                            )
                          "
                        />
                      </mdb-col>
                    </mdb-row>


                    <mdb-row>
                      <mdb-col md="12">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="basic_salary_minutes_total_days"
                          name="ot_working_day_rule"
                          radioValue="basic_salary_minutes_total_days"
                          v-model="overtime_rules.ot_working_day_rule"
                          :label="
                            $t(
                              'overtime_rule.formInput.basic_salary_minutes_total_days'
                            )
                          "
                        />
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col md="12">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="basic_salary_minutes_working_days"
                          name="ot_working_day_rule"
                          radioValue="basic_salary_minutes_working_days"
                          v-model="overtime_rules.ot_working_day_rule"
                          :label="
                            $t(
                              'overtime_rule.formInput.basic_salary_minutes_working_days'
                            )
                          "
                        />
                      </mdb-col>
                    </mdb-row>


                    
                    <mdb-row>
                      <mdb-col md="12">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="depend_on_schedule_time"
                          name="ot_working_day_rule"
                          radioValue="depend_on_schedule_time"
                          v-model="overtime_rules.ot_working_day_rule"
                          :label="
                            $t(
                              'overtime_rule.formInput.depend_on_schedule_time'
                            )
                          "
                        />
                      </mdb-col>
                    </mdb-row>

                    
                    <mdb-row v-if="overtime_rules.ot_working_day_rule == 'depend_on_schedule_time' ">
                      <mdb-col md="6">
                        <mdb-input
                          class="mt-0 mb-0"
                          type="text"
                          v-model="overtime_rules.ot_wd_schedule_time_min_hrs"
                          outline
                          label="Min Working Hours Per Month to calculate OT"
                        />
                      </mdb-col>

                      <mdb-col md="6">
                        <mdb-input
                          class="mt-0 mb-0"
                          type="text"
                          v-model="overtime_rules.ot_wd_break_time_mins"
                          outline
                          label="Break Time per day (In Minute)"
                        />
                      </mdb-col>
                    </mdb-row>





                    <mdb-row v-if="overtime_rules.ot_rule_status == 'timebaseot' && overtime_rules.ot_rule_calculated_type ==  'no_ot_request' ">
                      <mdb-col md="12">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="depend_on_schedule_time_hourly_fixed_amount"
                          name="ot_working_day_rule"
                          radioValue="depend_on_schedule_time_hourly_fixed_amount"
                          v-model="overtime_rules.ot_working_day_rule"
                          label="Depend on Attendance Schedule Time (with Fixed Hourly Amount)
                          "
                        />
                      </mdb-col>
                    </mdb-row>

                    
                    <mdb-row v-if="overtime_rules.ot_rule_status == 'timebaseot' && overtime_rules.ot_rule_calculated_type ==  'no_ot_request' && overtime_rules.ot_working_day_rule == 'depend_on_schedule_time_hourly_fixed_amount' ">
                      <mdb-col md="6">
                        <mdb-input
                          class="mt-0 mb-0"
                          type="text"
                          v-model="overtime_rules.ot_wd_schedule_time_min_hrs"
                          outline
                          label="Min Working Hours Per Month to calculate OT"
                        />
                      </mdb-col>

                      <mdb-col md="6">
                        <mdb-input
                          class="mt-0 mb-0"
                          type="text"
                          v-model="overtime_rules.ot_wd_break_time_mins"
                          outline
                          label="Break Time per day (In Minute)"
                        />
                      </mdb-col>


                      <mdb-col md="6">
                        <mdb-input
                          class="mt-0 mb-0"
                          type="text"
                          v-model="overtime_rules.ot_wd_schedule_fix_amount_hrs"
                          outline
                          label="Fixed Amount per hour"
                        />
                      </mdb-col>


                       
                    </mdb-row>




                  </mdb-col>
                </mdb-row>
                <!-- End Working Day Rules -->

                <!-- Start Off Day Rules -->

                <mdb-row>
                  <mdb-col offsetMd="2" md="8" class="mt-4">
                    <label>{{
                      $t("overtime_rule.formInput.off_day_rule")
                    }}</label>

                    <mdb-row>
                      <mdb-col md="6">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="off-fixed_amount_hrs"
                          name="ot_off_day_rule"
                          radioValue="fixed_amount_hrs"
                          v-model="overtime_rules.ot_off_day_rule"
                          :label="$t('overtime_rule.formInput.base_fix_amount')"
                        />
                      </mdb-col>
                      <mdb-col md="6">
                        <mdb-input
                          class="mt-0 mb-0"
                          type="text"
                          v-model="overtime_rules.ot_off_fix_amount_hrs"
                          outline
                          :disabled="
                            overtime_rules.ot_off_day_rule != 'fixed_amount_hrs'
                          "
                        />
                      </mdb-col>
                    </mdb-row>
                    <mdb-row>
                      <mdb-col md="6">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="off-formula"
                          name="ot_off_day_rule"
                          radioValue="formula"
                          v-model="overtime_rules.ot_off_day_rule"
                          :label="$t('overtime_rule.formInput.base_on_formula')"
                        />
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col md="6" lg="6">
                        <mdb-input
                          type="radio"
                          class="mb-2"
                          id="off-formula_monthly_multiply"
                          name="ot_off_day_rule"
                          radioValue="formula_monthly_multiply"
                          v-model="overtime_rules.ot_off_day_rule"
                          :label="
                            $t('overtime_rule.formInput.base_formula_with')
                          "
                        />
                      </mdb-col>
                      <mdb-col md="6" lg="6">
                        <mdb-input
                          type="text"
                          class="mt-0"
                          v-model="
                            overtime_rules.ot_off_formala_monthly_multiplyer
                          "
                          outline
                          :disabled="
                            overtime_rules.ot_off_day_rule !=
                            'formula_monthly_multiply'
                          "
                        />
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col md="12">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="off-basic_salary_hourly_total_days"
                          name="ot_off_day_rule"
                          radioValue="basic_salary_hourly_total_days"
                          v-model="overtime_rules.ot_off_day_rule"
                          :label="
                            $t(
                              'overtime_rule.formInput.basic_salary_hourly_total_days'
                            )
                          "
                        />
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col md="12">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="off-basic_salary_hourly_working_days"
                          name="ot_off_day_rule"
                          radioValue="basic_salary_hourly_working_days"
                          v-model="overtime_rules.ot_off_day_rule"
                          :label="
                            $t(
                              'overtime_rule.formInput.basic_salary_hourly_working_days'
                            )
                          "
                        />
                      </mdb-col>
                    </mdb-row>


                     <mdb-row>
                      <mdb-col md="12">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="off-basic_salary_minutes_total_days"
                          name="ot_off_day_rule"
                          radioValue="basic_salary_minutes_total_days"
                          v-model="overtime_rules.ot_off_day_rule"
                          :label="
                            $t(
                              'overtime_rule.formInput.basic_salary_minutes_total_days'
                            )
                          "
                        />
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col md="12">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="off-basic_salary_minutes_working_days"
                          name="ot_off_day_rule"
                          radioValue="basic_salary_minutes_working_days"
                          v-model="overtime_rules.ot_off_day_rule"
                          :label="
                            $t(
                              'overtime_rule.formInput.basic_salary_minutes_working_days'
                            )
                          "
                        />
                      </mdb-col>
                    </mdb-row>

                  </mdb-col>
                </mdb-row>
                <!-- End Off Day Rules -->

                <!-- Start holiday Rule -->

                <mdb-row>
                  <mdb-col offsetMd="2" md="8" class="mt-4">
                    <label>{{
                      $t("overtime_rule.formInput.holiday_day_rule")
                    }}</label>

                    <mdb-row>
                      <mdb-col md="6">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="holiday-fixed_amount_hrs"
                          name="ot_holiday_rule"
                          radioValue="fixed_amount_hrs"
                          v-model="overtime_rules.ot_holiday_rule"
                          :label="$t('overtime_rule.formInput.base_fix_amount')"
                        />
                      </mdb-col>
                      <mdb-col md="6">
                        <mdb-input
                          class="mt-0 mb-0"
                          type="text"
                          v-model="overtime_rules.ot_holiday_fix_amount_hrs"
                          outline
                          :disabled="
                            overtime_rules.ot_holiday_rule !=
                            'fixed_amount_hrs'
                          "
                        />
                      </mdb-col>
                    </mdb-row>
                    <mdb-row>
                      <mdb-col md="6">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="holiday-formula"
                          name="ot_holiday_rule"
                          radioValue="formula"
                          v-model="overtime_rules.ot_holiday_rule"
                          :label="$t('overtime_rule.formInput.base_on_formula')"
                        />
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col md="6" lg="6">
                        <mdb-input
                          type="radio"
                          class="mb-2"
                          id="holiday-formula_monthly_multiply"
                          name="ot_holiday_rule"
                          radioValue="formula_monthly_multiply"
                          v-model="overtime_rules.ot_holiday_rule"
                          :label="
                            $t('overtime_rule.formInput.base_formula_with')
                          "
                        />
                      </mdb-col>
                      <mdb-col md="6" lg="6">
                        <mdb-input
                          type="text"
                          class="mt-0"
                          v-model="
                            overtime_rules.ot_holiday_formula_monthly_multiplyer
                          "
                          outline
                          :disabled="
                            overtime_rules.ot_holiday_rule !=
                            'formula_monthly_multiply'
                          "
                        />
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col md="12">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="holiday-basic_salary_hourly_total_days"
                          name="ot_holiday_rule"
                          radioValue="basic_salary_hourly_total_days"
                          v-model="overtime_rules.ot_holiday_rule"
                          :label="
                            $t(
                              'overtime_rule.formInput.basic_salary_hourly_total_days'
                            )
                          "
                        />
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col md="12">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="holiday-basic_salary_hourly_working_days"
                          name="ot_holiday_rule"
                          radioValue="basic_salary_hourly_working_days"
                          v-model="overtime_rules.ot_holiday_rule"
                          :label="
                            $t(
                              'overtime_rule.formInput.basic_salary_hourly_working_days'
                            )
                          "
                        />
                      </mdb-col>
                    </mdb-row>

                    
                    <mdb-row>
                      <mdb-col md="12">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="holiday-basic_salary_minutes_total_days"
                          name="ot_holiday_rule"
                          radioValue="basic_salary_minutes_total_days"
                          v-model="overtime_rules.ot_holiday_rule"
                          :label="
                            $t(
                              'overtime_rule.formInput.basic_salary_minutes_total_days'
                            )
                          "
                        />
                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col md="12">
                        <mdb-input
                          class="mb-2"
                          type="radio"
                          id="holiday-basic_salary_minutes_working_days"
                          name="ot_holiday_rule"
                          radioValue="basic_salary_minutes_working_days"
                          v-model="overtime_rules.ot_holiday_rule"
                          :label="
                            $t(
                              'overtime_rule.formInput.basic_salary_minutes_working_days'
                            )
                          "
                        />
                      </mdb-col>
                    </mdb-row>

                  </mdb-col>
                </mdb-row>

                <!-- End Holiday Rule -->
              </div>

              <mdb-row
                v-else-if="
                  this.overtime_rules.ot_rule_status == 'fixedotpermonth'
                "
              >
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('overtime_rule.formInput.amount')"
                    v-model="overtime_rules.ot_rule_amount"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>

    <log-list :logs="logs"></log-list>

    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import OvertimeRule from "@/apis/OvertimeRule";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbFormInline,
    ModalDeleteConfimation,
  },
  props: ["_id"],
  data() {
    return {
      overtime_rules: {
        ot_rule_id: "",
        ot_rule_name: "",
        ot_rule_status: "no_ot",
        ot_rule_amount: "",
        ot_rule_calculated_type: "calculate_ot_request",
        ot_rule_min_hours_fixed_per_day: "",
        ot_start_before_office_time: "",
        ot_start_after_office_time: "",
        ot_working_day_rule: "",
        ot_wd_fix_amount_hrs: "",
        ot_wd_formula_monthly_multiplyer: "",

        ot_off_day_rule: "",
        ot_off_fix_amount_hrs: "",
        ot_off_formala_monthly_multiplyer: "",

        ot_holiday_rule: "",
        ot_holiday_fix_amount_hrs: "",
        ot_holiday_formula_monthly_multiplyer: "",

        ot_rule_description: "",

        ot_wd_schedule_time_min_hrs:"",
        ot_wd_break_time_mins:"",
        ot_wd_schedule_fix_amount_hrs:""
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.overtime_rules.ot_rule_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          OvertimeRule.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data);
                  let ot_rule_name,
                    ot_rule_id,
                    ot_rule_status,
                    ot_rule_amount,
                    ot_rule_calculated_type,
                    ot_rule_min_hours_fixed_per_day,
                    ot_start_before_office_time,
                    ot_start_after_office_time,
                    ot_working_day_rule,
                    ot_wd_fix_amount_hrs,
                    ot_wd_formula_monthly_multiplyer,
                    ot_off_day_rule,
                    ot_off_fix_amount_hrs,
                    ot_off_formala_monthly_multiplyer,
                    ot_holiday_rule,
                    ot_holiday_fix_amount_hrs,
                    ot_holiday_formula_monthly_multiplyer,
                    ot_rule_description,
                    
                    ot_wd_schedule_time_min_hrs,
                    ot_wd_break_time_mins,
                    ot_wd_schedule_fix_amount_hrs;

                  // populate field
                  ({
                    ot_rule_name,
                    ot_rule_id,
                    ot_rule_status,
                    ot_rule_amount,
                    ot_rule_calculated_type,
                    ot_rule_min_hours_fixed_per_day,
                    ot_start_before_office_time,
                    ot_start_after_office_time,

                    ot_working_day_rule,
                    ot_wd_fix_amount_hrs,
                    ot_wd_formula_monthly_multiplyer,

                    ot_off_day_rule,
                    ot_off_fix_amount_hrs,
                    ot_off_formala_monthly_multiplyer,

                    ot_holiday_rule,
                    ot_holiday_fix_amount_hrs,
                    ot_holiday_formula_monthly_multiplyer,

                    ot_rule_description,
                    
                    ot_wd_schedule_time_min_hrs,
                    ot_wd_break_time_mins,
                    ot_wd_schedule_fix_amount_hrs
                  } = response.data.data);
                  this.overtime_rules.ot_rule_id = ot_rule_id;
                  this.overtime_rules.ot_rule_name = ot_rule_name;
                  this.overtime_rules.ot_rule_status = ot_rule_status;
                  this.overtime_rules.ot_rule_amount = ot_rule_amount;
                  this.overtime_rules.ot_rule_calculated_type =
                    ot_rule_calculated_type;
                  this.overtime_rules.ot_rule_min_hours_fixed_per_day =
                    ot_rule_min_hours_fixed_per_day;
                  this.overtime_rules.ot_start_before_office_time =
                    ot_start_before_office_time;
                  this.overtime_rules.ot_start_after_office_time =
                    ot_start_after_office_time;
                  this.overtime_rules.ot_working_day_rule = ot_working_day_rule;
                  this.overtime_rules.ot_wd_fix_amount_hrs =
                    ot_wd_fix_amount_hrs;
                  this.overtime_rules.ot_wd_formula_monthly_multiplyer =
                    ot_wd_formula_monthly_multiplyer;

                  this.overtime_rules.ot_off_day_rule = ot_off_day_rule;
                  this.overtime_rules.ot_off_fix_amount_hrs = ot_off_fix_amount_hrs;
                  this.overtime_rules.ot_off_formala_monthly_multiplyer = ot_off_formala_monthly_multiplyer;
                  this.overtime_rules.ot_holiday_rule = ot_holiday_rule;
                  this.overtime_rules.ot_holiday_fix_amount_hrs = ot_holiday_fix_amount_hrs;
                  this.overtime_rules.ot_holiday_formula_monthly_multiplyer = ot_holiday_formula_monthly_multiplyer;

                  this.overtime_rules.ot_rule_description = ot_rule_description;

                  
                  this.overtime_rules.ot_wd_schedule_time_min_hrs = ot_wd_schedule_time_min_hrs;
                  this.overtime_rules.ot_wd_break_time_mins = ot_wd_break_time_mins;
                  this.overtime_rules.ot_wd_schedule_fix_amount_hrs = ot_wd_schedule_fix_amount_hrs;
                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listOvertimeRule" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listOvertimeRule" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listOvertimeRule" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      if (this.overtime_rules.ot_rule_status == "no_ot") {
        this.overtime_rules.ot_rule_amount = "";
        this.overtime_rules.ot_rule_calculated_type = "";
        this.overtime_rules.ot_rule_min_hours_fixed_per_day = "";
        this.overtime_rules.ot_start_before_office_time = "";
        this.overtime_rules.ot_start_after_office_time = "";
        this.overtime_rules.ot_working_day_rule = "";
        this.overtime_rules.ot_wd_fix_amount_hrs = "";
        this.overtime_rules.ot_wd_formula_monthly_multiplyer = "";
      } else if (this.overtime_rules.ot_rule_status == "fixedotpermonth") {
        this.overtime_rules.ot_rule_calculated_type = "";
        this.overtime_rules.ot_rule_min_hours_fixed_per_day = "";
        this.overtime_rules.ot_start_before_office_time = "";
        this.overtime_rules.ot_start_after_office_time = "";
        this.overtime_rules.ot_working_day_rule = "";
        this.overtime_rules.ot_wd_fix_amount_hrs = "";
        this.overtime_rules.ot_wd_formula_monthly_multiplyer = "";
      } else if (this.overtime_rules.ot_rule_status == "fixedotperday") {
        this.overtime_rules.ot_working_day_rule = "";
        this.overtime_rules.ot_wd_fix_amount_hrs = "";
        this.overtime_rules.ot_wd_formula_monthly_multiplyer;
      } else if (this.overtime_rules.ot_rule_status == "timebaseot") {
        this.overtime_rules.ot_rule_amount = "";
        this.overtime_rules.ot_rule_min_hours_fixed_per_day = "";

        //start working day rule

        if (this.overtime_rules.ot_working_day_rule == "fixed_amount_hrs") {
          this.overtime_rules.ot_wd_formula_monthly_multiplyer = "";
        } else if (
          this.overtime_rules.ot_working_day_rule == "formula_monthly_multiply"
        ) {
          this.overtime_rules.ot_wd_fix_amount_hrs = "";
        }else{
          this.overtime_rules.ot_wd_fix_amount_hrs = "";
          this.overtime_rules.ot_wd_formula_monthly_multiplyer = "";
        }

        //start Off day Rule
        if (this.overtime_rules.ot_off_day_rule == "fixed_amount_hrs") {
          this.overtime_rules.ot_off_formala_monthly_multiplyer = "";
        } else if (
          this.overtime_rules.ot_off_day_rule == "formula_monthly_multiply"
        ) {
          this.overtime_rules.ot_off_fix_amount_hrs = "";
        } else {
          this.overtime_rules.ot_off_fix_amount_hrs = "";
          this.overtime_rules.ot_off_formala_monthly_multiplyer = "";
        }


        //start holiday rule
        if (this.overtime_rules.ot_holiday_rule == "fixed_amount_hrs") {
          this.overtime_rules.ot_holiday_formula_monthly_multiplyer = "";
        } else if (
          this.overtime_rules.ot_holiday_rule == "formula_monthly_multiply"
        ) {
          this.overtime_rules.ot_holiday_fix_amount_hrs = "";
        }else {
          this.overtime_rules.ot_holiday_fix_amount_hrs = "";
          this.overtime_rules.ot_holiday_formula_monthly_multiplyer = "";
        } 


      }

      OvertimeRule.save(this.overtime_rules).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.overtime_rules.ot_rule_id != "") {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "listOvertimeRule" });
            } else {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({ name: "listOvertimeRule" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.overtime_rules).forEach((key) => {
        this.overtime_rules[key] = "";
      });
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.overtime_rules.ot_rule_id > 0) {
        OvertimeRule.delete({ ot_rule_id: this.overtime_rules.ot_rule_id })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({ name: "listOvertimeRule" });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }
              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>