<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row class="mt-3">
                <mdb-col offsetMd="2" md="8">
                  <p class="h6 text-color-primary">
                    {{ $t("employees.text.ssb_info") }}
                  </p>
                </mdb-col>
              </mdb-row>

              <mdb-row>

                
                
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.employee_name_mm')"
                    v-model="employee.epinfo_name_mm"
                    outline
                  />
                </mdb-col>

                
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.employee_ssb_office_no')"
                    v-model="employee.employee_ssb_office_no"
                    outline
                  />
                </mdb-col>
                
                <mdb-col md="4">
                  <mdb-date-picker-2
                    v-model="employee.employee_ssb_registration_date"
                    :label="$t('general.formInput.employee_ssb_registration_date')"
                    title="Select date"
                  />

                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.ssb_card_no')"
                    v-model="employee.employee_ssb_card_no"
                    outline
                  />
                </mdb-col>
                
                
                <mdb-col md="4">
                  <mdb-select
                    v-model="ssbCardTypeOption"
                    placeholder=""
                    label="Select SSB Card Type"
                    outline
                    @getValue="updateSSBCardTypeValue"
                  />

                </mdb-col>

                 <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="textarea"
                    label="Address in Burmese"
                    v-model="employee.epinfo_current_address_mm"
                    outline
                  />
                </mdb-col>
                

              </mdb-row>

              <mdb-row class="mt-3">
                <mdb-col offsetMd="2" md="8">
                  <p class="h6 text-color-primary">
                    {{ $t("employees.text.tax_info") }}
                  </p>
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.tax_book_no')"
                    v-model="employee.employee_tax_book_no"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.tax_no')"
                    v-model="employee.employee_tax_no"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.tax_relief_no_of_child')"
                    v-model="employee.employee_number_of_child"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="4" class="mb-3">
                  <label>{{ $t("general.formInput.father_relief") }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="employee_father_relief-yes"
                      name="employee_father_relief"
                      radioValue="yes"
                      v-model="employee.employee_father_relief"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="employee_father_relief-no"
                      name="employee_father_relief"
                      radioValue="no"
                      v-model="employee.employee_father_relief"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                </mdb-col>

                <mdb-col md="4" class="mb-3">
                  <label>{{ $t("general.formInput.mother_relief") }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="employee_mother_relief-yes"
                      name="employee_mother_relief"
                      radioValue="yes"
                      v-model="employee.employee_mother_relief"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="employee_mother_relief-no"
                      name="employee_mother_relief"
                      radioValue="no"
                      v-model="employee.employee_mother_relief"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                </mdb-col>

                <mdb-col offsetMd="2" md="4" class="mb-3">
                  <label>{{
                    $t("general.formInput.father_in_law_relief")
                  }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="employee_father_in_law_relief-yes"
                      name="employee_father_in_law_relief"
                      radioValue="yes"
                      v-model="employee.employee_father_in_law_relief"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="employee_father_in_law_relief-no"
                      name="employee_father_in_law_relief"
                      radioValue="no"
                      v-model="employee.employee_father_in_law_relief"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                </mdb-col>

                <mdb-col md="4" class="mb-3">
                  <label>{{
                    $t("general.formInput.mother_in_law_relief")
                  }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="employee_mother_in_law_relief-yes"
                      name="employee_mother_in_law_relief"
                      radioValue="yes"
                      v-model="employee.employee_mother_in_law_relief"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="employee_mother_in_law_relief-no"
                      name="employee_mother_in_law_relief"
                      radioValue="no"
                      v-model="employee.employee_mother_in_law_relief"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                </mdb-col>

                <mdb-col offsetMd="2" md="4" class="mb-4">
                  <label>{{ $t("general.formInput.employee_spouse") }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="employee_employee_spouse-yes"
                      name="employee_employee_spouse"
                      radioValue="yes"
                      v-model="employee.employee_spouse"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="employee_employee_spouse-no"
                      name="employee_employee_spouse"
                      radioValue="no"
                      v-model="employee.employee_spouse"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
  </div>
</template>
<script>
import Employee from "@/apis/Employee";
import LogList from "@/components/logs/List";
import { mapActions, mapGetters } from "vuex";
import { ssbCardTypeOption } from "@/constants/employee.js";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbSelect,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFormInline,
  mdbDatePicker2
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbSelect,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbFormInline,
    
  mdbDatePicker2
  },
  props: ["employee_id"],
  data() {
    return {
      employee: {},
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      ssbCardTypeOption: ssbCardTypeOption(true, ""),
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    updateSSBCardTypeValue(val) {
      this.employee.employee_ssb_card_type = val;
    },

    fetchRecord() {
      if (this.employee_id != undefined) {
        if (Math.sign(this.employee_id) === 1) {
          this.updateLoading(true);
          Employee.viewTaxSSBInfo(this.employee_id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  let fetch_data = response.data.data;
                  Object.keys(fetch_data).forEach(function (key) {
                    if (!fetch_data[key]) {
                      fetch_data[key] = "";
                    }
                  });
                  this.employee = fetch_data;
                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.ssbCardTypeOption = ssbCardTypeOption(
                    true,
                    response.data.data.employee_ssb_card_type
                  );

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listEmployee" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listEmployee" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listEmployee" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.formSubmitting = true;
      Employee.updateTaxSSBInfo(this.employee).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>