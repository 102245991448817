<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="apg_name"
                    outline
                    disabled
                  />
                </mdb-col>
              </mdb-row>
              <hr />

              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <div class="float-left mt-3 sec_title">
                    Employee Assignment
                  </div>
                  <mdb-btn
                    type="button"
                    outline="primary"
                    class="float-right btn_outline_style"
                    icon="user-friends"
                    @click.native="modalOpen('request_from')"
                  >
                    {{ $t("approval_workflow.button.select_employee") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>

              <mdb-row class="mb-3">
                <mdb-col offsetMd="2" md="8">
                  <div v-if="request_from_tab.length > 0">
                    <mdb-row>
                      <mdb-col
                        class="mb-3"
                        xl="6"
                        col="12"
                        v-for="(request_from_item, counter) in request_from_tab"
                        v-bind:key="counter"
                        waves
                      >
                        <div class="approval_info_box">
                          <EmployeeInfoBoxLayout
                            :user="request_from_item"
                            :showDelete="true"
                            @deleteEmployee="
                              deleteFromEmployee(request_from_item)
                            "
                          />
                        </div>
                      </mdb-col>
                    </mdb-row>
                  </div>
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>

    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapCheckboxTable
          @updateSelectedEmployeeIDs="setSelectedEmployeeIDs"
          :default_selected_value="source_default_selected_value"
          :source_params="source_params"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import ApprovalGroup from "@/apis/ApprovalGroup";
import EmployeeListBoostrapCheckboxTable from "@/components/EmployeeListBoostrapCheckboxTable";

import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  //   mdbFormInline,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    // mdbFormInline,

    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeListBoostrapCheckboxTable,
    EmployeeInfoBoxLayout,
  },
  data() {
    return {
      apg_name: "",

      apge_data: {
        apge_apg_id: "",
        apge_employee_id: [],
      },

      employees:[],
      request_from_tab: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      modal: false,
      source_default_selected_value: "",
      source_params: "",
    };
  },
  props: ["apge_apg_id"],
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    deleteFromEmployee(emp) {
      this.request_from_tab = this.request_from_tab.filter((val) => {
        return val !== emp;
      });
    },

    modalOpen(param) {
      this.modal = true;
      this.source_params = param;

      if (param == "request_from") {
        this.source_default_selected_value = this.request_from_tab.map((e) => {
          return e.employee_id;
        });
      }
    },

    setSelectedEmployeeIDs(val) {
      if (val[1] == "request_from") {
        this.request_from_tab = val[0];
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      // validation

      if (this.formSubmitting == true) {
        this.apge_data = {
          apge_apg_id: this.apge_apg_id,
          apge_employee_id: this.request_from_tab.map((e) => {
            return e.employee_id;
          }),
        };

        ApprovalGroup.addEmployee(this.apge_data).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({
                name: "viewApprovalGroup",
                params: { _id: this.apge_apg_id },
              });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.formSubmitting = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
    redirectBackAction() {
      this.$router.push({
        name: "viewApprovalGroup",
        params: { _id: this.apge_apg_id },
      });
    },

    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      this.apge_data = {
        apge_apg_id: this.apge_apg_id,
        apge_employee_id: [],
      };

      this.request_from_tab = [];
      this.source_default_selected_value = "";
      this.source_params = "";
    },

    fetchRecord() {
      if (this.apge_apg_id != undefined) {
        if (Math.sign(this.apge_apg_id) === 1) {
          this.updateLoading(true);
          ApprovalGroup.view(this.apge_apg_id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)
                  let apg_name;

                  // populate field
                  ({ apg_name } = response.data.data);
                  this.apg_name = apg_name;
                  
                //   this.employees = response.data.employees;

                  this.request_from_tab = response.data.employees

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listApprovalGroup" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listApprovalGroup" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listApprovalGroup" });
        }
      } else {
        // add
      }
    },
  },

  created() {
    this.fetchRecord();
  },
};
</script>