import Api from "./api";

export default {
  reportList(payload) {
    return Api.get("api/v2/management/report/dashboard" ,{ params: payload});

  },

  
  resetManagementReport(payload) {
    return Api.post("api/v2/management/report/reset" , payload);
  },

  summaryReport(payload) {
    return Api.post("api/management/report/summary" , payload);
  },


  headcountGraphData(payload) {
    return Api.post("api/management/report/headcount/graph" , payload);
  },
  headcountHeaderAndData(payload) {
    return Api.post("api/management/report/headcount/data" , payload);
  },
  headcountMonthlyComparison(payload) {
    return Api.post("api/management/report/headcount/analysis" , payload);
  },
  headcountServiceYear(payload) {
    return Api.post("api/management/report/headcount/servicebyyear" , payload);
  },

  

  turnoverGraphData(payload) {
    return Api.post("api/management/report/turnover/graph", payload);
  },
  turnoverHeaderAndData(payload) {
    return Api.post("api/management/report/turnover/data", payload);
  },
  turnoverMonthlyComparison(payload) {
    return Api.post("api/management/report/turnover/analysis", payload);
  },
  turnoverSeparation(payload) {
    return Api.post("api/management/report/turnover/separtionbyfactor", payload);
  },
  turnoverNewHire(payload) {
    return Api.post("api/management/report/turnover/new_hire", payload);
  },


  
  overtimeGraphData(payload) {
    return Api.post("api/management/report/overtime/graph", payload);
  },
  overtimeData(payload) {
    return Api.post("api/management/report/overtime/data", payload);
  },
  overtimeMonthlyComparison(payload) {
    return Api.post("api/management/report/overtime/analysis", payload);
  },
  overtimeTopEmployee(payload) {
    return Api.post("api/management/report/overtime/top/employee", payload);
  },
  overtimeTopMonthlyEmployee(payload) {
    return Api.post("api/management/report/overtime/top/analysis", payload);
  },

  lateGraphData(payload) {
    return Api.post("api/management/report/lateearly/graph", payload);
  },  
  lateData(payload) {
    return Api.post("api/management/report/lateearly/data", payload);
  },
  lateMonthlyComparison(payload) {
    return Api.post("api/management/report/lateearly/analysis", payload);
  },
  lateTopEmployee(payload) {
    return Api.post("api/management/report/lateearly/top/employee", payload);
  },
  lateTopMonthlyEmployee(payload) {
    return Api.post("api/management/report/lateearly/top/analysis", payload);
  },


  actionData(payload) {
    return Api.post("api/management/report/action/data", payload);
  },
  actionMonthlyComparison(payload) {
    return Api.post("api/management/report/action/analysis", payload);
  },


  salaryGraphData(payload) {
    return Api.post("api/management/report/salary/graph", payload);
  },
  salaryData(payload) {
    return Api.post("api/management/report/salary/data", payload);
  },
  salaryMonthlyComparison(payload) {
    return Api.post("api/management/report/salary/analysis", payload);
  },


  leaveGraphData(payload) {
    return Api.post("api/management/report/leave/graph", payload);
  },  
  leaveRateData(payload) {
    return Api.post("api/management/report/leave/leave_rate", payload);
  },
  leaveData(payload) {
    return Api.post("api/management/report/leave/data", payload);
  },
  leaveTopEmployee(payload) {
    return Api.post("api/management/report/leave/top/employee", payload);
  },
  leaveMonthlyComparison(payload) {
    return Api.post("api/management/report/leave/analysis", payload);
  },
  leaveTopMonthlyEmployee(payload) {
    return Api.post("api/management/report/leave/top/analysis", payload);
  },


  
  trainingData(payload) {
    return Api.post("api/management/report/training/data", payload);
  },

};
