<template>
  <div>
    <mdb-select
    outline
      search
      v-model="axiosOptions"
      :label="selectLabel"
      @getValue="updateSelectedValue"
      :disabled="loading"
    />
  </div>
</template>

<script>
import { mdbSelect } from "mdbvue";
import ContractStatus from "@/apis/ContractStatus";
export default {
  name: "ContractStatusDropdown",
  components: {
    mdbSelect,
  },
  props: ["default_selected_value", "required","resetSelected"],
  data() {
    return {
      axiosOptions: [],
      loading:true,
      selectLabel : "Loading Contract Status List"
    };
  },
  mounted() {
       ContractStatus.all()
        .then((response) => {
          if (response.status === 200) {
            const ContractStatus = response.data.data;
            if(this.required === false){
                this.axiosOptions.push({
                    text: "All",
                    value: "",
                });
            }            

            for (let item of ContractStatus) {
                if (this.default_selected_value == item.ct_id) {
                    this.axiosOptions.push({
                    text: item.ct_name,
                    value: item.ct_id,
                    selected:true,
                    is_active: item.ct_active
                    });
                } else {
                    this.axiosOptions.push({
                    text: item.ct_name,
                    value: item.ct_id,
                    is_active: item.ct_active
                    });
                }
            }    
            this.loading = false
            this.selectLabel = "Select Contract Status"

          }
        })
  },
  methods: {
    updateSelectedValue(val) {
      if (val !== null) {


        let response = this.axiosOptions.find(option => {
          return option.selected === true;
        });
        
        if (response) {
          this.$emit("updateContractStatusID", val, response.is_active)
        }
      }
    },
  },
    watch: {
    resetSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.axiosOptions = this.axiosOptions.map((val) => ({
          text: val.text,
          value: val.value,
        }));
      }
    },
  },
};
</script>
