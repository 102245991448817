<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                               

                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    :label="$t('general.formInput.from_date')"
                    v-model="allowancePolicy.alp_from_date"
                    title="Select date"
                    required
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-date-picker-2
                    :label="$t('general.formInput.to_date')"
                    v-model="allowancePolicy.alp_to_date"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <AllowanceRuleDropdown
                    @updateAllowanceRuleID="setAllowanceRuleID"
                    :default_selected_value="allowancePolicy.alp_ar_id"
                    :required="true"
                    :resetSelected="doesFormReset"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"                    
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import EmployeeAllowancePolicy from "@/apis/EmployeeAllowancePolicy";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import AllowanceRuleDropdown from "@/components/AllowanceRuleDropdown";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbDatePicker2,
    
    ModalDeleteConfimation,
    AllowanceRuleDropdown,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      allowancePolicy: {
        alp_id: "",
        alp_employee_id: this.employee_id != "undefined" ? this.employee_id : "",
        alp_from_date: "",
        alp_to_date: "",
        alp_ar_id: "",
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.allowancePolicy.alp_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    setAllowanceRuleID(val) {
      this.allowancePolicy.alp_ar_id = val;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          EmployeeAllowancePolicy.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)

                  let alp_id,
                    alp_employee_id,
                    alp_from_date,
                    alp_to_date,
                    alp_ar_id;
                  // populate field
                  ({
                    alp_id,
                    alp_employee_id,
                    alp_from_date,
                    alp_to_date,
                    alp_ar_id,
                  } = response.data.data);
                  this.allowancePolicy.alp_id = alp_id;
                  this.allowancePolicy.alp_employee_id = alp_employee_id;
                  this.allowancePolicy.alp_from_date = alp_from_date;
                  this.allowancePolicy.alp_to_date = alp_to_date;
                  this.allowancePolicy.alp_ar_id = alp_ar_id;
                  //make selected option again

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    employeeAllowancePolicyList: {
                      _id: this.allowancePolicy.alp_employee_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "employeeAllowancePolicyList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "employeeAllowancePolicyList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "employeeAllowancePolicyList" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      EmployeeAllowancePolicy.save(this.allowancePolicy).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.allowancePolicy.alp_id != "") {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({
                name: "employeeAllowancePolicyList",
                params: { employee_id: this.allowancePolicy.alp_employee_id },
              });
            } else {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.resetForm();
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({
        name: "employeeAllowancePolicyList",
        params: { employee_id: this.allowancePolicy.alp_employee_id },
      });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.allowancePolicy).forEach((key) => {
        this.allowancePolicy[key] = "";
      });
      this.allowancePolicy.alp_employee_id = this.employee_id;
      this.doesFormReset = !this.doesFormReset;
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.allowancePolicy.alp_id > 0) {
        EmployeeAllowancePolicy.delete({
          alp_id: this.allowancePolicy.alp_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              this.$router.push({
                name: "employeeAllowancePolicyList",
                params: {
                  _id: this.allowancePolicy.alp_employee_id,
                },
              });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      employeeAllowancePolicyList: {
        employee_id: this.allowancePolicy.alp_employee_id,
      },
    };
    this.fetchRecord();
  },
};
</script>