import Api from './api'

export default{
    all(payload){
            return Api.get('api/employees/employee_occupation/list/' + payload)
    },

    save(payload){
            return Api.post('api/employees/employee_occupation/data_entry', payload)        
    },
    
    delete(payload){
        return Api.post('api/employees/employee_occupation/delete', payload)        
    },
    
    view(payload){
        return Api.get('api/employees/employee_occupation/' + payload)        
    },
    
}