<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="late_policy.ldr_name"
                    outline
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-date-picker-2
                    inline
                    v-model="late_policy.ldr_from_date"
                    :label="$t('general.formInput.start_date')"
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-date-picker-2
                    inline
                    v-model="late_policy.ldr_to_date"
                    :label="$t('general.formInput.end_date')"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <label> Deduction Type </label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="option-basic_salary_per_min"
                      name="ldr_deduction_type"
                      radioValue="basic_salary_per_min"
                      v-model="late_policy.ldr_deduction_type"
                      label="Basic salary per minute"
                    />
                    <mdb-input
                      type="radio"
                      id="option-fixed_amount"
                      name="ldr_deduction_type"
                      radioValue="fixed_amount"
                      v-model="late_policy.ldr_deduction_type"
                      label="Fixed amount per minute range"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8" class="mt-4">
                  <label class="mt-2"> Time Range:</label>
                  <mdb-btn
                    type="button"
                    class="btn_sm_light_blue"
                    color="uno-color"
                    @click="addLdrdTime('', '', '', '')"
                    size="sm"
                    rounded
                  >
                    <mdb-icon icon="plus-square" size="md" />
                    Add New
                  </mdb-btn>
                </mdb-col>
              </mdb-row>

              <div
                v-for="(time_item, counter) in time_list"
                v-bind:key="counter"
              >
                <mdb-row>
                  <mdb-col offsetMd="2" md="4">
                    <mdb-input
                      type="number"
                      :label="$t('general.formInput.time_from')"
                      v-model="time_item.time_from"
                      outline
                    />
                  </mdb-col>

                  <mdb-col md="4">
                    <mdb-input
                      type="number"
                      :label="$t('general.formInput.time_to')"
                      v-model="time_item.time_to"
                      outline
                    />
                  </mdb-col>
                  <mdb-col
                    offsetMd="2"
                    md="4"
                    v-show="
                      late_policy.ldr_deduction_type == 'basic_salary_per_min'
                    "
                  >
                    <mdb-input
                      type="number"
                      :label="$t('general.formInput.multiplyer')"
                      v-model="time_item.multiplyer"
                      outline
                    />
                  </mdb-col>
                  <mdb-col
                    offsetMd="2"
                    md="4"
                    v-show="late_policy.ldr_deduction_type == 'fixed_amount'"
                  >
                    <mdb-input
                      type="number"
                      :label="$t('general.formInput.ldrd_fixed_amount')"
                      v-model="time_item.fixed_amount"
                      outline
                    />
                  </mdb-col>

                  <mdb-col md="4">
                    <mdb-btn
                      type="button"
                      color="uno-color"
                      class="btn_sm_delete mt-4"
                      rounded
                      size="sm"
                      @click="deleteLdrdTime(counter)"
                      :disabled="shouldDisabledDeleteTimeRangeButton"
                    >
                      <mdb-icon icon="trash-alt" size="md" />
                      {{ $t("general.button.remove") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>
                <mdb-row v-if="counter < time_list.length - 1">
                  <mdb-col offsetMd="2" md="8">
                    <hr />
                  </mdb-col>
                </mdb-row>
              </div>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>

    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import LateDeductionPolicy from "@/apis/LateDeductionPolicy";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbDatePicker2,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbDatePicker2,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    ModalDeleteConfimation,
    mdbFormInline,
  },
  props: ["_id"],
  data() {
    return {
      late_policy: {
        ldr_id: "",
        ldr_name: "",
        ldr_from_date: "",
        ldr_to_date: "",
        ldr_caculation_type: "total_late_mins",
        ldr_deduction_type: "basic_salary_per_min",
        ldrd_id: [],
        ldrd_time_from: [],
        ldrd_time_to: [],
        ldrd_multiplyer: [],
        ldrd_fixed_amount: [],
      },
      time_list: [
        {
          id: "",
          time_from: "",
          time_to: "",
          multiplyer: "",
          fixed_amount: "",
        },
      ],
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.late_policy.ldr_id != "" ? true : false;
    },
    shouldDisabledDeleteTimeRangeButton() {
      return this.time_list.length > 1 ? false : true;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    addLdrdTime(
      id = "",
      time_from = "",
      time_to = "",
      multiplyer = "",
      fixed_amount = ""
    ) {
      this.time_list.push({
        id: id,
        time_from: time_from,
        time_to: time_to,
        multiplyer: multiplyer,
        fixed_amount: fixed_amount,
      });
    },
    deleteLdrdTime(counter) {
      this.time_list.splice(counter, 1);
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          LateDeductionPolicy.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)
                  let ldr_id,
                    ldr_name,
                    ldr_from_date,
                    ldr_to_date,
                    ldr_caculation_type,
                    ldr_deduction_type;

                  // populate field
                  ({
                    ldr_id,
                    ldr_name,
                    ldr_from_date,
                    ldr_to_date,
                    ldr_caculation_type,
                    ldr_deduction_type,
                  } = response.data.data);
                  this.late_policy.ldr_id = ldr_id;
                  this.late_policy.ldr_name = ldr_name;
                  this.late_policy.ldr_from_date = ldr_from_date;
                  this.late_policy.ldr_to_date = ldr_to_date;
                  this.late_policy.ldr_caculation_type = ldr_caculation_type;
                  this.late_policy.ldr_deduction_type = ldr_deduction_type;

                  if (ldr_caculation_type == "total_late_mins") {
                    if (response.data.detail_list.length > 0) {
                      console.log(response.data.detail_list);
                      //remove constructor
                      this.time_list = [];
                      for (let time_item of response.data.detail_list) {
                        Object.keys(time_item).forEach(function (key) {
                          if (time_item[key] === null) {
                            time_item[key] = "";
                          }
                        });

                        this.addLdrdTime(
                          time_item.ldrd_id,
                          time_item.ldrd_time_from,
                          time_item.ldrd_time_to,
                          time_item.ldrd_multiplyer,
                          time_item.ldrd_fixed_amount
                        );
                      }
                    }
                  }

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listLateDeductionPolicy" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listLateDeductionPolicy" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listLateDeductionPolicy" });
        }
      } else {
        // add
      }
    },

    prepareTimeRangeList() {
      this.late_policy.ldrd_id = [];
      this.late_policy.ldrd_time_from = [];
      this.late_policy.ldrd_time_to = [];
      this.late_policy.ldrd_multiplyer = [];
      this.late_policy.ldrd_fixed_amount = [];

      if (this.late_policy.ldr_deduction_type == "basic_salary_per_min") {
        if (this.time_list.length > 0) {
          for (let time_item of this.time_list) {
            this.late_policy.ldrd_id.push(time_item.id);
            this.late_policy.ldrd_time_from.push(time_item.time_from);
            this.late_policy.ldrd_time_to.push(time_item.time_to);
            this.late_policy.ldrd_multiplyer.push(time_item.multiplyer);
            // this.late_policy.ldrd_fixed_amount.push(time_item.fixed_amount);
          }
        }
      } else if (this.late_policy.ldr_deduction_type == "fixed_amount") {
        if (this.time_list.length > 0) {
          for (let time_item of this.time_list) {
            this.late_policy.ldrd_id.push(time_item.id);
            this.late_policy.ldrd_time_from.push(time_item.time_from);
            this.late_policy.ldrd_time_to.push(time_item.time_to);
            // this.late_policy.ldrd_multiplyer.push(time_item.multiplyer);
            this.late_policy.ldrd_fixed_amount.push(time_item.fixed_amount);
          }
        }
      }
    },

    handleFormSubmit() {
      this.prepareTimeRangeList();
      this.resetAlert();
      this.formSubmitting = true;
      LateDeductionPolicy.save(this.late_policy).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.late_policy.ldr_id != "") {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "listLateDeductionPolicy" });
            } else {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({ name: "listLateDeductionPolicy" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.late_policy).forEach((key) => {
        this.late_policy[key] = "";
      });
      this.time_list = [
        {
          time_from: "",
          time_to: "",
          multiplyer: "",
          fixed_amount: "",
        },
      ];
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.late_policy.ldr_id > 0) {
        LateDeductionPolicy.delete({ ldr_id: this.late_policy.ldr_id })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({ name: "listLateDeductionPolicy" });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }
              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>