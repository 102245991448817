<template>

    <mdb-breadcrumb class="breadcrumb-with-mdb-icon transparent pl-0">
      <mdb-breadcrumb-item v-for="(crumb, ci) in crumbs" :key="ci">
        <a v-if="!isLast(ci)" @click.prevent="selected(crumb)">{{ $t('routeNameLabel.'+crumb) }}</a>
        <span style="vertical-align: top;" v-else>{{ $t('routeNameLabel.'+crumb) }}</span>
      </mdb-breadcrumb-item>
    </mdb-breadcrumb>

</template>

<script>
import { mdbBreadcrumb, mdbBreadcrumbItem } from "mdbvue";
export default {
  name: "Breadcrumb",
  components: {
    mdbBreadcrumb,
    mdbBreadcrumbItem,
  },
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
    crubmParams:{
      type: Object,
      required: false,
    }
  },
  methods: {
    isLast(index) {
      return index === this.crumbs.length - 1;
    },
    selected(crumb) {
      if(this.crubmParams!=undefined ){
        if( crumb in this.crubmParams ){
          this.$router.push({ name: crumb, params: this.crubmParams[crumb] });
        }else{
          this.$router.push({ name: crumb });
        }
      }else{
        this.$router.push({ name: crumb });
      }      
     
      // this.$emit('selectedCrumb', crumb);
    },
  },
};
</script>
 
<style scoped>
.transparent {
  background: none;
  font-family: "poppin_regular";
  font-size: 14px;
  color: #8590A2;
}

.breadcrumb{
  padding: 0rem 1rem 1em;
}
.breadcrumb-with-mdb-icon .breadcrumb-item + .breadcrumb-item::before {  
   font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
}
.breadcrumb-item+.breadcrumb-item::before {    
  color: #8590A2 !important;  
}
</style>