<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <form @submit.prevent="handleFilterSubmit">
      <mdb-row>
        <mdb-col md="4" lg="3" class="form-inline-search">
          <mdb-date-picker-2
            v-model="filterForm.filter_start_date"
            :label="$t('general.formInput.start_date')"
            required
          />
        </mdb-col>
        <mdb-col md="4" lg="3" class="form-inline-search">
          <mdb-date-picker-2
            v-model="filterForm.filter_end_date"
            :label="$t('general.formInput.end_date')"
            required
          />
        </mdb-col>
        <mdb-col md="4" lg="3" class="my-3">
          <mdb-btn
            type="Search"
            color="uno-color"
            class="btn_submit"
            :disabled="doesFormSubmitted"
            size="sm"
          >
            <mdb-icon
              icon="file-upload"
              size="md"
              v-show="!doesFormSubmitted"
            />

            <span
              class="spinner-border-vcenter spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-show="doesFormSubmitted"
            ></span>
            {{ $t("general.button.submit") }}
          </mdb-btn>
        </mdb-col>
      </mdb-row>
    </form>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-show="!isLoading">
        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn
                color="uno-color"
                class="btn_submit"
                @click="createNewRecord"
                size="sm"
              >
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th>
                    {{ $t("general.formInput.date") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.hour_from") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.hour_to") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.duration") }}
                  </th>
                  <th>
                    {{ $t("general.formInput.status") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(overtime_item, index) in employee_overtime"
                  :key="index"
                >
                  <td scope="row" @click="rowClickEvent(overtime_item.ot_id)">
                    {{ overtime_item.ot_date  | formatDateString}}
                  </td>
                  <td @click="rowClickEvent(overtime_item.ot_id)">
                    {{ overtime_item.ot_start_from  | formatDateTimeString}}
                  </td>
                  <td @click="rowClickEvent(overtime_item.ot_id)">
                    {{ overtime_item.ot_end_at | formatDateTimeString }}
                  </td>
                  <td @click="rowClickEvent(overtime_item.ot_id)">
                    {{ overtime_item.ot_total_minutes }} mins
                  </td>
                  <td @click="rowClickEvent(overtime_item.ot_id)">
                    <template v-if="overtime_item.ot_request_by == 'behalf'">
                      Approved
                    </template>
                    <template
                      v-else-if="
                        overtime_item.ot_request_by != 'behalf' &&
                        overtime_item.ot_status == 'approve' &&
                        overtime_item.ot_complete == '0'
                      "
                    >
                      Pending Approval
                    </template>
                    <template v-else>
                      {{ overtime_item.ot_status }}
                    </template>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import Overtime from "@/apis/Overtime";
import { mapActions, mapGetters } from "vuex";

import {
  mdbRow,
  mdbCol,
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbDatePicker2,
} from "mdbvue";

export default {
  components: {
    mdbRow,
  mdbCol,
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbDatePicker2,
  },
  name: "EmployeeOvertimeList",

  data() {
    return {
      employee_overtime: [],
      currentSort: "ot_date",
      currentSortDir: "desc",
      feedback_error: false,
      feedback_message: "",
      
      formSubmitting: false,
      filterForm:{
        filter_start_date:"",
        filter_end_date:""
      }
    };
  },
  props: ["employee_id"],
  computed: {
    ...mapGetters(["isLoading"]),

    doesFormSubmitted() {
      return this.formSubmitting;
    },

    sortedDocumentTypeList() {
      return this.action_types.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),


    handleFilterSubmit() {
      this.formSubmitting = true;      
      this.fetchRecordList();      
    },

    rowClickEvent(id) {
      this.$router.push({
        name: "viewEmployeeOvertime",
        params: { _id: id },
      });
    },

    createNewRecord() {
      this.$router.push({ name: "createEmployeeOvertime" });
    },

    fetchRecordList() {
      this.updateLoading(true);

      // if (payload.employee_id != undefined) {
      //   if (Math.sign(payload.employee_id) === 1) {
          const params = {
            employee_id: this.employee_id,
            filter_start_date : this.filterForm.filter_start_date, 
            filter_end_date : this.filterForm.filter_end_date 
          };

          Overtime.all(params)
            .then((response) => {
              if (response.status === 200) {
                this.employee_overtime = response.data.data;
                this.updateLoading(false);

                if( this.filterForm.filter_start_date != ''){
                  this.formSubmitting = false;
                }

                this.filterForm.filter_start_date = response.data.filter_start_date
                this.filterForm.filter_end_date = response.data.filter_end_date
              }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.feedback_message = "Could not connected to server";
                this.feedback_error = true;

                this.updateLoading(false);
              } else {
                console.log(error);
                this.updateLoading(false);
              }
            });
      //   }
      // }
    },
  },
  created() {
    if (!this.employee_id || Math.sign(this.employee_id) !== 1) {
      this.$router.push({name: "listEmployee"});
    }else{
      this.fetchRecordList();
    }
    
  },
};
</script>
