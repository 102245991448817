<template>
  <form @submit.prevent="handleFormSubmit">
    <mdb-row>
      <mdb-col md="4"  class="form-inline-search">
        <mdb-input
          type="text"
          :label="$t('general.formInput.keywords')"
          v-model="filterForm.keywords"
          outline
        />
      </mdb-col>
      <mdb-col md="4"  class="form-inline-search">
        <DepartmentDropdown
          @updateDepartmentID="setDepartmentID"
          :default_selected_value="filterForm.department_id"
          :required="false"
        />
      </mdb-col>
      <mdb-col md="4"  class="form-inline-search">
        <PositionDropdown
          @updatePositionID="setPositionID"
          :default_selected_value="filterForm.jp_id"
          :required="false"
          :resetSelected="doesFormReset"
        />
      </mdb-col>
      <mdb-col md="4"  class="form-inline-search">
        <ContractStatusDropdown
          @updateContractStatusID="setContractStatusID"
          :required="false"
          :resetSelected="doesFormReset"
          :default_selected_value="filterForm.status"
        />
      </mdb-col>
      <mdb-col md="4"  class="form-inline-search">
        <mdb-select
          v-model="OptionEmploymentType"
          placeholder=""
          label="Select Employment Type"
          outline
          @getValue="updateEmployementTypeValue"
        />
      </mdb-col>


    </mdb-row>
    <mdb-row>

      <mdb-col md="4" lg="3" class="form-inline-search">
        <mdb-date-picker-2
          v-model="filterForm.from_date"
          label="Contract Start Date From"
        />
      </mdb-col>

      <mdb-col md="4" lg="3" class="form-inline-search">
        <mdb-date-picker-2
          v-model="filterForm.to_date"
          label="Contract Start Date to"
        />
      </mdb-col>


      <mdb-col md="4" lg="3" class="form-inline-search">
        <mdb-date-picker-2
          v-model="filterForm.end_from_date"
          label="Contract End Date From"
        />
      </mdb-col>

      <mdb-col md="4" lg="3" class="form-inline-search">
        <mdb-date-picker-2
          v-model="filterForm.end_to_date"
          label="Contract End Date to"
        />
      </mdb-col>

    </mdb-row>
    <mdb-row class="mb-4">
      <mdb-col>
        <mdb-btn
          type="button"
          color="danger"
          class="btn_submit"
          size="sm"
          @click="resetForm"
        >
          <mdb-icon icon="undo" size="md" />
          {{ $t("general.button.reset") }}
        </mdb-btn>
        <mdb-btn type="Search" color="uno-color" class="btn_submit" size="sm">
          <mdb-icon icon="file-upload" size="md" />

          {{ $t("general.button.submit") }}
        </mdb-btn>
      </mdb-col>
    </mdb-row>
  </form>
</template>

<script>
import DepartmentDropdown from "@/components/DepartmentDropdown";
import PositionDropdown from "@/components/PositionDropdown";
import ContractStatusDropdown from "@/components/ContractStatusDropdown";
import { employmentTypeOption } from "@/constants/employee.js";
import { mdbInput, mdbRow, mdbCol, mdbBtn, mdbIcon, mdbSelect, mdbDatePicker2 } from "mdbvue";

export default {
  components: {
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    mdbSelect,
    DepartmentDropdown,
    PositionDropdown,
    ContractStatusDropdown,
    mdbDatePicker2,
  },
  props: ["filterFormData"],
  data() {
    return {
      filterForm: {
        keywords: "",
        department_id: "",
        jp_id: "",
        employment_type: "",
        status: "",
        from_date: "",
        to_date: "",
        end_from_date: "",
        end_to_date: "",
      },
      OptionEmploymentType: employmentTypeOption(true, ""),
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,
    };
  },

  methods: {
    setContractStatusID(val, is_active) {
      this.filterForm.status = val;
      this.is_active_contract = is_active;
    },
    setDepartmentID(val) {
      this.filterForm.department_id = val;
    },
    setPositionID(val) {
      this.filterForm.jp_id = val;
    },
    updateEmployementTypeValue(val) {
      this.filterForm.employment_type = val;
    },

    handleFormSubmit() {
      this.$emit("submitFilterForm", this.filterForm);
    },

    resetForm() {
      Object.keys(this.filterForm).forEach((key) => {
        this.filterForm[key] = "";
      });
      this.doesFormReset = !this.doesFormReset;
      this.$emit("submitFilterForm", this.filterForm);
    },
  },
  created(){
    this.filterForm = this.filterFormData
    this.OptionEmploymentType= employmentTypeOption(true, this.filterFormData.employment_type)
  }
};
</script>