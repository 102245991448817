<template>
  <div>

    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>


    
    

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">



        <form @submit.prevent="handleFormSubmit">
      <mdb-row>
        <mdb-col md="4"  class="form-inline-search">
          <mdb-input
            type="text"
            :label="$t('general.formInput.keywords')"
            v-model="filterForm.keywords"
            outline
          />
        </mdb-col>
    
        <mdb-col md="4" class="form-inline-search">
          <mdb-date-picker-2
            v-model="filterForm.start_date"
            :label="$t('general.formInput.start_date')"
          />
        </mdb-col>
        <mdb-col md="4" class="form-inline-search">
          <mdb-date-picker-2
            v-model="filterForm.end_date"
            :label="$t('general.formInput.end_date')"
          />
        </mdb-col>

        
      </mdb-row>

      <mdb-row class="mb-4">
      <mdb-col>
        <mdb-btn
          type="button"
          color="danger"
          class="btn_submit"
          size="sm"
          @click="resetForm"
        >
          <mdb-icon icon="undo" size="md" />
          {{ $t("general.button.reset") }}
        </mdb-btn>
        <mdb-btn type="Search" color="uno-color" class="btn_submit" size="sm">
          <mdb-icon icon="file-upload" size="md" />

          {{ $t("general.button.submit") }}
        </mdb-btn>
      </mdb-col>
    </mdb-row>
    </form>



        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn color="uno-color" class="btn_submit" @click="createNewRecord" size="sm">
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head >
                <tr>
                  <th >
                    
                    {{ $t("general.formInput.name") }}
                  </th>
                  <th
                   
                    width="120"
                    class="text-right"
                  >
                    
                    {{ $t("general.formInput.created") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(list_item, index) in announcements.data"
                  :key="index"
                >
                  <td scope="row" @click="rowClickEvent(list_item)">
                    {{ list_item.hr_announcement_title }}
                  </td>
                  <td @click="rowClickEvent(list_item)" class="text-right">
                    {{ list_item.hr_announcement_created | formatDateString }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <pagination :data="announcements" @pagination-change-page="fetchRecordList" :limit="2"></pagination>
          </mdb-card-body>
        </mdb-card>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
Vue.component('pagination', require('laravel-vue-pagination'))
import Announcement from "@/apis/Announcement";
import { mapActions, mapGetters } from "vuex";

import { 
  mdbRow,
  mdbCol,
  mdbInput,
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbDatePicker2
} from "mdbvue";

export default {
  components: {
    mdbRow,
    mdbCol,
    mdbInput,
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbDatePicker2
  },

  data() {
    return {
      filterForm: {
        keywords: "",
        start_date: "",
        end_date: "",
      },
      announcements: {},
      currentSort: "hr_announcement_title",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",

      
      formSubmitting: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    // sortedRecordList() {
    //   return this.announcements.data.slice().sort((a, b) => {
    //     let modifier = 1;
    //     if (this.currentSortDir === "desc") modifier = -1;
    //     if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
    //     if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
    //     return 0;
    //   });
    // },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    // sort: function (s) {
    //   //if s == current sort, reverse
    //   if (s === this.currentSort) {
    //     this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
    //   }
    //   this.currentSort = s;
    // },

    rowClickEvent(item) {
      if (item.hr_announcement_type == "department") {
        this.$router.push({
          name: "viewAnnouncementDepartmentSummary",
          params: { _id: item.hr_announcement_id },
        });
      } else {
        this.$router.push({
          name: "viewAnnouncementEmployeesSummary",
          params: { _id: item.hr_announcement_id },
        });
      }
    },

    createNewRecord() {
      this.$router.push({ name: "createAnnouncement" });
    },

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      const params = {
        page: page,
        keywords: this.filterForm.keywords,
        start_date: this.filterForm.start_date,
        end_date: this.filterForm.end_date,
      };

      Announcement.all(params)
        .then((response) => {
          if (response.status === 200) {
            this.announcements = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },



    
    handleFormSubmit() {
          this.fetchRecordList();
        
      },

      resetForm() {
      Object.keys(this.filterForm).forEach((key) => {
        this.filterForm[key] = "";
      });
    },
  
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
