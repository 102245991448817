<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-show="!isLoading">
        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn color="uno-color" class="btn_submit" @click="createNewRecord" size="sm">
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th @click="sort('efinfo_name')">
                    <i
                      v-show="currentSort === 'efinfo_name'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("general.formInput.name") }}
                  </th>
                  <th>
                    {{ $t("employee_info.formInput.relationship") }}
                  </th>
                  <th>
                    {{ $t("employee_info.formInput.date_of_birth") }}
                  </th>
                  <th>
                    {{ $t("employee_info.formInput.place_of_birth") }}
                  </th>
                  <th>
                    {{ $t("employee_info.formInput.occupation") }}
                  </th>
                  <th>
                    {{ $t("employee_info.formInput.transfer_insurance") }}
                  </th>
                  <th>
                    {{ $t("employee_info.formInput.behalf_ssb") }}
                  </th>
                  <th
                    width="120"
                    class="text-right"
                    @click="sort('efinfo_created')"
                  >
                    <i
                      v-show="currentSort === 'efinfo_created'"
                      :class="[
                        currentSortDir === 'asc'
                          ? 'fas fa-arrow-up '
                          : 'fas fa-arrow-down ',
                      ]"
                      aria-hidden="true"
                    ></i>
                    {{ $t("general.formInput.created") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(document_type, index) in sortedDocumentTypeList"
                  :key="index"
                >
                  <td
                    scope="row"
                    @click="rowClickEvent(document_type.efinfo_id)"
                  >
                    {{ document_type.efinfo_name }}
                  </td>
                  <td @click="rowClickEvent(document_type.efinfo_id)">
                    {{ document_type.efinfo_relationship | capitalizeFirstLetter}}
                  </td>
                  <td @click="rowClickEvent(document_type.efinfo_id)">
                    {{ document_type.efinfo_date_of_birth | formatDateString}}
                  </td>
                  <td @click="rowClickEvent(document_type.efinfo_id)">
                    {{ document_type.efinfo_place_of_birth }}
                  </td>
                  <td @click="rowClickEvent(document_type.efinfo_id)">
                    {{ document_type.efinfo_occupation }}
                  </td>
                  <td @click="rowClickEvent(document_type.efinfo_id)">
                    {{ document_type.efinfo_transfer_insurance=='1' ? $t("general.formInput.yes") :  $t("general.formInput.no") }}
                  </td>
                  <td @click="rowClickEvent(document_type.efinfo_id)">
                    {{ document_type.efinfo_behalf_ssb=='1' ? $t("general.formInput.yes") :  $t("general.formInput.no") }}
                  </td>
                  <td
                    class="text-right"
                    @click="rowClickEvent(document_type.efinfo_id)"
                  >
                    {{ document_type.efinfo_created | formatDateString }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import EmployeeFamilyInfo from "@/apis/EmployeeFamilyInfo";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },

  data() {
    return {
      familyInfos: [],
      currentSort: "efinfo_name",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  props: ["employee_id"],
  computed: {
    ...mapGetters(["isLoading"]),

    sortedDocumentTypeList() {
      return this.familyInfos.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    rowClickEvent(id) {
      this.$router.push({ name: "viewFamilyInfo", params: { _id: id } });
    },

    createNewRecord() {
      this.$router.push({
        name: "createFamilyInfo",
        params: { employee_id: this.employee_id },
      });
    },

    fetchRecordList() {
      this.updateLoading(true);

      if (this.employee_id != undefined) {
        if (Math.sign(this.employee_id) === 1) {
          EmployeeFamilyInfo.all(this.employee_id)
            .then((response) => {
              if (response.status === 200) {
                this.familyInfos = response.data.data;
                this.updateLoading(false);
              }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.feedback_message = "Could not connected to server";
                this.feedback_error = true;

                this.updateLoading(false);
              } else {
                console.log(error);
                this.updateLoading(false);
              }
            });
        }
      }
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
