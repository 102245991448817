import Api from "./api";

export default {
  all() {
    return Api.get("api/settings/approver_group/list");
  },

  save(payload) {
    return Api.post("api/settings/approver_group/data_entry", payload);
  },

  delete(payload) {
    return Api.post("api/settings/approver_group/delete", payload);
  },

  view(payload) {
    return Api.get("api/settings/approver_group/" + payload);
  },

  addEmployee(payload){
    return Api.post("api/settings/approver_group_employee/data_entry", payload);
  },

  deleteEmployees(payload){
    return Api.post("api/settings/approver_group_employee/delete", payload);
  }
};
