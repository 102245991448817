<template>
  <div>
    <mdb-select
      outline
      multiple 
      selectAll 
      v-model="axiosOptions"
      :label="selectLabel"
      @getValue="updateSelectedValue"
      :disabled="loading"
    />
  </div>
</template>

<script>
import { mdbSelect } from "mdbvue";
import TransactionType from "@/apis/TransactionType";
export default {
  name: "TransactionTypeDropdown",
  components: {
    mdbSelect,
  },
  props: ["default_selected_value", "required","resetSelected"],
  data() {
    return {
      axiosOptions: [],
      loading: true,
      selectLabel: "Loading TransactionType List",
    };
  },
  mounted() {
    TransactionType.all().then((response) => {
      if (response.status === 200) {
        const transaction_types = response.data.data;

        for (let transaction_type of transaction_types) {
          if (this.default_selected_value === transaction_type.ht_id) {
            this.axiosOptions.push({
              text: transaction_type.ht_name,
              value: transaction_type.ht_id,
              selected: true,
            });
          } else {
            this.axiosOptions.push({
              text: transaction_type.ht_name,
              value: transaction_type.ht_id,
            });
          }
        }
        this.loading = false;
        this.selectLabel = "Select TransactionType";
      }
    });
  },
  methods: {
    updateSelectedValue(val) {
      if (val !== null) {
        this.$emit("updateTransactionTypeID", val);
      }
    },
  },
  watch: {
    resetSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.axiosOptions = this.axiosOptions.map((val) => ({
          text: val.text,
          value: val.value,
        }));
      }
    },
  },
};
</script>
