<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
    <template>
      <mdb-card class="mb-5">
        
        <mdb-card-body>
          <form @submit.prevent="handleFormSubmit">
            <mdb-row>
              <mdb-col offsetMd="2" md="4">
                <mdb-date-picker-2
                  v-model="attendance_policy_details.detail_from_date"
                  :label="$t('general.formInput.from_date')"
                  title="Select date"
                />
              </mdb-col>

              <mdb-col md="4">
                <mdb-date-picker-2
                  v-model="attendance_policy_details.detail_to_date"
                  :label="$t('general.formInput.to_date')"
                  title="Select date"
                />
              </mdb-col>

              <mdb-col offsetMd="2" md="4">
                <AttendanceRuleDropdown
                  @updateAttendanceRuleID="setAttendanceRuleID"
                  :default_selected_value="
                    attendance_policy_details.detail_attendance_rule_id
                  "
                  :required="true"
                />
              </mdb-col>

              <mdb-col md="4">
                <WorkingHourDropdown
                  @updateWorkingHoursID="setWorkingHoursID"
                  :default_selected_value="
                    attendance_policy_details.detail_hr_work_hours_id
                  "
                  :required="true"
                />
                <br />
              </mdb-col>
            </mdb-row>

             <mdb-row>
              <mdb-col offsetMd="2" md="8">

            <mdb-btn
              type="button"
              class="btn_back"
              color="uno-color"
              @click="redirectBackAction"
            >
              <mdb-icon icon="arrow-left" size="md" />
              {{ $t("general.button.go_back") }}
            </mdb-btn>

            <mdb-btn type="Submit" color="uno-color" class="btn_submit" :disabled="formSubmitting">
              <mdb-icon icon="file-upload" size="md" v-show="!formSubmitting" />

              <span
                class="spinner-border-vcenter spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="formSubmitting"
              ></span>
              {{ $t("general.button.submit") }}
            </mdb-btn>


            <mdb-btn
            type="button"          
            :disabled="deleteSubmitted"
            @click="showDeleteModal"
            v-show="doesEditForm"
            color="uno-color"
            class="btn_delete float-right"              
          >
            <mdb-icon
              icon="trash-alt"
              far
              size="md"
              v-show="!deleteSubmitted"
            />

            <span
              class="spinner-border-vcenter spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-show="deleteSubmitted"
            ></span>
            {{ $t("general.button.delete") }}
          </mdb-btn>
              </mdb-col>
             </mdb-row>
          </form>

          
        </mdb-card-body>
      </mdb-card>
    </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord"/>
  </div>
</template>
<script>
import AttendancePolicyDetail from "@/apis/AttendancePolicyDetail";
import LogList from "@/components/logs/List";
import AttendanceRuleDropdown from "@/components/AttendanceRuleDropdown";
import WorkingHourDropdown from "@/components/WorkingHourDropdown";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  // mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    // mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    AttendanceRuleDropdown,
    WorkingHourDropdown,
    mdbDatePicker2,
    ModalDeleteConfimation
  },
  props: ["_id", "ap_id"],
  data() {
    return {
      attendance_policy_details: {
        detail_id: "",
        detail_hr_ap_id: this.ap_id != "undefined" ? this.ap_id : "",
        detail_from_date: "",
        detail_to_date: "",
        detail_attendance_rule_id: "",
        detail_hr_work_hours_id: "",
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.attendance_policy_details.detail_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
        this.$store.state.deleteModalVisible = true;
      },
    setAttendanceRuleID(val) {
      this.attendance_policy_details.detail_attendance_rule_id = val;
    },
    setWorkingHoursID(val) {
      this.attendance_policy_details.detail_hr_work_hours_id = val;
    },
    fetchRecord() {
      this.updateLoading(true);
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          AttendancePolicyDetail.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)
                  let detail_id,
                    detail_hr_ap_id,
                    detail_from_date,
                    detail_to_date,
                    detail_attendance_rule_id,
                    detail_hr_work_hours_id;

                  // populate field
                  ({
                    detail_id,
                    detail_hr_ap_id,
                    detail_from_date,
                    detail_to_date,
                    detail_attendance_rule_id,
                    detail_hr_work_hours_id,
                  } = response.data.data);
                  this.attendance_policy_details.detail_id = detail_id;
                  this.attendance_policy_details.detail_hr_ap_id = detail_hr_ap_id;
                  this.attendance_policy_details.detail_from_date = detail_from_date;
                  this.attendance_policy_details.detail_to_date = detail_to_date;
                  this.attendance_policy_details.detail_attendance_rule_id = detail_attendance_rule_id;
                  this.attendance_policy_details.detail_hr_work_hours_id = detail_hr_work_hours_id;

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    listAttendancePolicyDetail: {
                      _id: this.attendance_policy_details.detail_hr_ap_id,
                    },
                  };

                  
                this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listAttendancePolicyDetail" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listAttendancePolicyDetail" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listAttendancePolicyDetail" });
        }
      } else {
        // add
        this.updateLoading(false);
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      AttendancePolicyDetail.save(this.attendance_policy_details).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              if (this.attendance_policy_details.detail_id != "") {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });
                this.$router.push({ name: "listAttendancePolicyDetail" });
              } else {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });
              }

              this.$router.push({
                name: "listAttendancePolicyDetail",
                params: { _id: this.attendance_policy_details.detail_hr_ap_id },
              });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.formSubmitting = false;
          }
        }
      );
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({
        name: "listAttendancePolicyDetail",
        params: { _id: this.attendance_policy_details.detail_hr_ap_id },
      });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.attendance_policy_details).forEach((key) => {
        this.attendance_policy_details[key] = "";
      });
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.attendance_policy_details.detail_id > 0) {
        AttendancePolicyDetail.delete({
          detail_id: this.attendance_policy_details.detail_id,
        })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({
                  name: "listAttendancePolicyDetail",
                  params: {
                    _id: this.attendance_policy_details.detail_hr_ap_id,
                  },
                });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      
    },
  },
  mounted() {
    this.$store.state.crumbsParams = {
      listAttendancePolicyDetail: {
        _id: this.attendance_policy_details.detail_hr_ap_id,
      },
    };
    this.fetchRecord();
  },
};
</script>