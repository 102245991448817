<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">
        <ActionSearchForm @submitFilterForm="handleSubmitFilterForm" :filterFormData="searchFormData"/>

        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn
                color="uno-color"
                class="btn_submit"
                @click="createNewRecord"
                size="sm"
              >
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th></th>
                  <th>
                    {{ $t("action_info.formInput.type") }}
                  </th>
                  <th>
                    {{ $t("action_info.formInput.date") }}
                  </th>
                  <th>
                    {{ $t("action_info.text.deduction_status") }}
                  </th>
                  <th>
                    {{ $t("action_info.formInput.penalty_value") }}
                  </th>
                  <th>
                    {{ $t("action_info.formInput.effective_month") }}
                  </th> 
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(recordlist, index) in action_list.data"
                  :key="index"
                >
                  <td
                    scope="row"
                    @click="rowClickEvent(recordlist.hr_ea_id)"
                  >
                    <EmployeeInfoTableCellLayout :user="recordlist" />
                  </td>

                  <td @click="rowClickEvent(recordlist.hr_ea_id)">
                    {{ recordlist.hr_eat_name }}
                  </td>
                  <td @click="rowClickEvent(recordlist.hr_ea_id)">
                    {{ recordlist.hr_ea_date | formatDateString}}
                  </td>
                  <td @click="rowClickEvent(recordlist.hr_ea_id)">
                    {{
                      recordlist.hr_ea_salary_deduction_status == "1"
                        ?  $t("general.formInput.yes") 
                        : $t("general.formInput.no") 
                    }}
                  </td>
                  <td @click="rowClickEvent(recordlist.hr_ea_id)">
                    <template v-if="recordlist.hr_ea_salary_deduction_status =='1'">
                    {{ recordlist.hr_ea_penalty_value }}
                    <template v-if="recordlist.hr_ea_penalty_type != 'amount'">
                      %
                    </template>
                    </template>
                  </td>
                  <td @click="rowClickEvent(recordlist.hr_ea_id)">
                    {{ recordlist.hr_ea_penalty_effective_month | formatDateString}}
                  </td> 
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <pagination :data="action_list" @pagination-change-page="fetchRecordList" :limit="2"></pagination>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
Vue.component('pagination', require('laravel-vue-pagination'))

import EmployeeActionInfo from "@/apis/EmployeeActionInfo";
import { mapActions, mapGetters } from "vuex";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";

import ActionSearchForm from "@/components/search/ActionSearchForm";
import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    EmployeeInfoTableCellLayout,
    ActionSearchForm
  },

  data() {
    return {
      action_list: [],
      searchFormData: {
        keywords: "",
        department_id: "",
        jp_id: "",
        filter_year: "",
        filter_month: "",
      },
      currentSort: "contract_reference",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    rowClickEvent(id) {
      this.$router.push({
        name: "viewManageAction",
        params: { _id: id },
      });
    },

    handleSubmitFilterForm(val){
      this.searchFormData = val
      this.fetchRecordList()
    },

    createNewRecord() {
      this.$router.push({
        name: "createManageAction"
      });
    },

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      const params = {
        page: page,
        keywords: this.searchFormData.keywords,
        department_id: this.searchFormData.department_id,
        jp_id: this.searchFormData.jp_id,
        date: (this.searchFormData.filter_month!=''&& this.searchFormData.filter_year ? this.searchFormData.filter_month + '-' +this.searchFormData.filter_year : ''),
      };
      EmployeeActionInfo.all(params)
        .then((response) => {
          if (response.status === 200) {
            this.action_list = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
