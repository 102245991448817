<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">
        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn
                color="uno-color"
                class="btn_submit"
                @click="createNewRecord"
                size="sm"
              >
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th>Created By</th>
                  <th>Attendance Month</th>

                  <th></th>
                  <th width="120" class="text-right">
                    {{ $t("general.formInput.created") }}
                  </th>
                </tr>
              </mdb-tbl-head>
              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(list_item, index) in result_data.data"
                  :key="index"
                >
              
                  <td scope="row">
                    <EmployeeInfoTableCellLayout
                      :user="{
                        profile_image: list_item.profile_image,
                        epinfo_name: list_item.created_by,
                        jp_name: list_item.position,
                        department_name: list_item.department,
                      }"
                    />
                  </td>

                  <td>
                    {{ list_item.formatted_date }}
                  </td>
                  <td>
                    <mdb-btn
                      type="Submit"
                      color="uno-color"
                      class="btn_submit"
                      @click="downloadMonthlyAtatendance(list_item)"
                      :disabled="list_item.file_downloading"
                    >
                      <mdb-icon
                        icon="file-upload"
                        size="md"
                        v-show="!list_item.file_downloading"
                      />

                      <span
                        class="spinner-border-vcenter spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        v-show="list_item.file_downloading"
                      ></span>
                      {{ $t("general.button.download_csv") }}
                    </mdb-btn>
                  </td>
                  
                  <td class="text-right">
                    {{ (list_item.updated_at ? list_item.updated_at : list_item.created_at) | formatDateString }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <pagination
              :data="result_data"
              @pagination-change-page="fetchRecordList"
              :limit="2"
            ></pagination>
          </mdb-card-body>
        </mdb-card>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
Vue.component("pagination", require("laravel-vue-pagination"));
import Attendance from "@/apis/Attendance";
import { mapActions, mapGetters } from "vuex";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";

import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    EmployeeInfoTableCellLayout,
  },

  data() {
    return {
      result_data: [],
      currentSort: "date",
      currentSortDir: "desc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    downloadMonthlyAtatendance(item) {
      if (item.date != "") {
        const payload = {
          date: item.date,
        };

        item.file_downloading = true;

        let foundIndex = this.result_data.data.findIndex(
          (x) => x.id == item.id
        );

        this.result_data.data[foundIndex] = item;
        Attendance.downloadMonthlyAttendance(payload).then((response) => {
          if (response.status === 200) {
            // let today = new Date();
            // let [month, date, year] = today
            //   .toLocaleDateString("en-US")
            //   .split("/");
            // let [hour, minute, second] = today
            //   .toLocaleTimeString("en-US")
            //   .split(/:| /);
            // let today_string =
            //   year + month + date + "_" + hour + minute + second;

            var blob = new Blob([response.data]);
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              "MonthlyAttendanceReport" + "_" + item.date + ".csv";
            link.click();

            item.file_downloading = false;
              this.result_data.data[foundIndex] = item;
          }else{
              item.file_downloading = false;
              this.result_data.data[foundIndex] = item;
          }
        });
      }
    },

    createNewRecord() {
      this.$router.push({ name: "checkMonthlyAttendanceForm" });
    },

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      const params = {
        page: page,
      };

      Attendance.getCheckMonthlyAttendance(params)
        .then((response) => {
          if (response.status === 200) {
            const prep_data = response.data.data.data.map((item) => {
              item.file_downloading = false;
              return item;
            });

            response.data.data.data = prep_data;

            this.result_data = response.data.data;

            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
