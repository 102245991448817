<template>
  <div>
    <div>
      <mdb-btn-group style="margin-bottom:20px;width: 100%; background-color:#4285f4 !important;" class="button_wrapper">
        <router-link :to="{ name: 'managementSummaryReport' }">
          <mdb-btn
            color="primary"
            @click.native="toggleActive('summary')"
            :active="summary"
            >Summary</mdb-btn
          >
        </router-link>
        <router-link :to="{ name: 'managementHeadcountReport' }">
          <mdb-btn
            color="primary"
            @click.native="toggleActive('headcount')"
            :active="headcount"
            >Headcount</mdb-btn
          >
        </router-link>
        <router-link :to="{ name: 'managementTurnoverReport' }">
        <mdb-btn
          color="primary"
          @click.native="toggleActive('turnover')"
          :active="turnover"
          >Turnover</mdb-btn
        >
        </router-link>
        <router-link :to="{ name: 'managementLeaveReport' }">
        <mdb-btn
          color="primary"
          @click.native="toggleActive('leave')"
          :active="leave"
          >Leave</mdb-btn
        >
        </router-link>
        <router-link :to="{ name: 'managementOvertimeReport' }">
        <mdb-btn
          color="primary"
          @click.native="toggleActive('overtime')"
          :active="overtime"
          >Overtime</mdb-btn
        >
        </router-link>
        <router-link :to="{ name: 'managementLateEarlyReport' }">
        <mdb-btn
          color="primary"
          @click.native="toggleActive('late_early')"
          :active="late_early"
          >Late/Early</mdb-btn
        >
        </router-link>
        <router-link :to="{ name: 'managementActionReport' }">
        <mdb-btn
          color="primary"
          @click.native="toggleActive('action')"
          :active="action"
          >Action</mdb-btn
        >
        </router-link>
        <router-link :to="{ name: 'managementSalaryReport' }">
        <mdb-btn
          color="primary"
          @click.native="toggleActive('salary')"
          :active="salary"
          >Salary</mdb-btn
        >
        </router-link>
        <router-link :to="{ name: 'managementTrainingReport' }">
        <mdb-btn
          color="primary"
          @click.native="toggleActive('training')"
          :active="training"
          >Training</mdb-btn
        >
        </router-link>
      </mdb-btn-group>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mdbBtn, mdbBtnGroup } from "mdbvue";

export default {
  name: "ManagementReportNavigation",
  components: {
    mdbBtn,
    mdbBtnGroup,
  },
  data() {
    return {
      summary: false,
      headcount: false,
      turnover: false,
      leave: false,
      overtime: false,
      late_early: false,
      action: false,
      salary: false,
      training: false,
    };
  },


  methods: {
    toggleActive(activebtn) {
      this.summary = activebtn == "summary" ? true : false;
      this.headcount = activebtn == "headcount" ? true : false;
      this.turnover = activebtn == "turnover" ? true : false;
      this.leave = activebtn == "leave" ? true : false;
      this.overtime = activebtn == "overtime" ? true : false;
      this.late_early = activebtn == "late_early" ? true : false;
      this.action = activebtn == "action" ? true : false;
      this.salary = activebtn == "salary" ? true : false;
      this.training = activebtn == "training" ? true : false;

    },

    // this.$route.name
  },
  created(){

    switch (this.$route.name) {

      
    case 'managementSummaryReport':
      this.summary = true
      break;  

    case 'managementHeadcountReport':
      this.headcount = true
      break;  

    case 'managementTurnoverReport':
      this.turnover = true
      break;  

    case 'managementLeaveReport':
      this.leave = true
      break;  

    case 'managementOvertimeReport':
      this.overtime = true
      break;  

    case 'managementLateEarlyReport':
      this.late_early = true
      break;  

    case 'managementActionReport':
      this.action = true
      break;  

    case 'managementSalaryReport':
      this.salary = true
      break;  
    
    case 'managementTrainingReport':
      this.training = true
      break;  
     
    default:
      this.summary = true
    }
  }
};
</script>      
<style scoped>
.button_wrapper a button{
    margin: 0;
    border-radius: 0;
}
</style>