<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.keywords')"
                    v-model="keywords"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <PositionDropdown
                    @updatePositionID="setPositionID"
                    :default_selected_value="jp_id"
                    :required="false"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <DepartmentDropdown
                    @updateDepartmentID="setDepartmentID"
                    :default_selected_value="department_id"
                    :required="false"
                  />
                </mdb-col>
                <mdb-col offsetMd="2" md="8">
                  <RankMultiSelectDropdown
                    @updateSelectedRankIDs="setSelectedRankIds"
                    :default_selected_value="rank"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <label class="col-form-label mt-4">Export Type</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="status-active"
                      name="status"
                      radioValue="active"
                      v-model="status"
                      label="Export employee training"
                    />
                    <mdb-input
                      type="radio"
                      id="status-inactive"
                      name="status"
                      radioValue="inactive"
                      v-model="status"
                      label="Export employees who do not have any training"
                    />

                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>

              <br />
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
  </div>
</template>
<script>
import Report from "@/apis/Report";
import DepartmentDropdown from "@/components/DepartmentDropdown";
import PositionDropdown from "@/components/PositionDropdown";
import RankMultiSelectDropdown from "@/components/RankMultiSelectDropdown";

import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFormInline,
  mdbInput,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    DepartmentDropdown,
    PositionDropdown,
    RankMultiSelectDropdown,
    mdbFormInline,
    mdbInput,
  },
  data() {
    return {
      keywords: "",
      jp_id: "",
      department_id: "",
      rank: "",
      status: "active",
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    setDepartmentID(val) {
      this.department_id = val;
    },

    setSelectedRankIds(val) {
      this.rank = val;
    },
    setPositionID(val) {
      this.jp_id = val;
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      const payload = {
        keywords: this.keywords,
        jp_id: this.jp_id,
        department_id: this.department_id,
        rank: this.rank,
        status: this.status,
      };

      Report.EmployeeTrainingReport(payload).then((response) => {
        if (response.status === 200) {
          let today = new Date();
          let [month, date, year] = today
            .toLocaleDateString("en-US")
            .split("/");
          let [hour, minute, second] = today
            .toLocaleTimeString("en-US")
            .split(/:| /);
          let today_string = year + month + date + "_" + hour + minute + second;

          var blob = new Blob(["\ufeff", response.data]);
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          if(this.status == 'active'){
            link.download =
            "EmployeeTrainingReport" + "_" + today_string + ".csv";
          }else{
          link.download =
            "EmployeeEmptyTrainingReport" + "_" + today_string + ".csv";
          }

          link.click();

          this.formSubmitting = false;
        }
      });
    },

    resetAlert() {
      this.feedback_error = false;
    },
  },
  created() {},
};
</script>