<template>
  <div>
    <ejs-treeview
      id="treeview"
      :fields="fields"
      :showCheckBox="true"
      :checkedNodes="checkedNodes"
      :nodeChecked="nodeChecked"
    ></ejs-treeview>
  </div>
</template>

<script>
import Department from "@/apis/Department";
import { treeBuilder } from "@/utilities/treeBuilder";

import Vue from "vue";
import { TreeViewPlugin } from "@syncfusion/ej2-vue-navigations";
Vue.use(TreeViewPlugin);

export default {
  name: "DepartmentTreeViewCheckbox",
  props: ["default_selected_value"],
  data() {
    return {
      fields: null,
      checkedNodes: [],
      loading: true,
    };
  },

  mounted() {
    Department.all().then((response) => {
      if (response.status === 200) {
        const axiosOptions = response.data.data;

        this.fields = {
          dataSource: treeBuilder(axiosOptions, this.default_selected_value),
          id: "department_id",
          parentID: "department_parent_id",
          text: "department_name",
          hasChildren: "hasChild",
        };

        this.loading = false;
      }
    });
  },
  methods: {
    nodeChecked: function () {
      // nodeChecked: function(args) {
      var treeObj = document.getElementById("treeview").ej2_instances[0];
      // console.log("The checked node's id: " + treeObj.checkedNodes); // To alert the checked node's id.

      let selected_node = []
      if(treeObj.checkedNodes.length > 0){
        selected_node = treeObj.checkedNodes.map(val => { return parseInt(val) })
      }

      // console.log(selected_node)
      this.$emit("updateDepartmentIDsValue", selected_node);
    },
  },
};
</script>
<style >
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";

.control_wrapper {
  display: block;
  max-width: 350px;
  max-height: 350px;
  margin: auto;
  overflow: auto;
  border: 1px solid #dddddd;
  border-radius: 3px;
}
</style>