<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <mdb-card class="mb-5">
        <mdb-card-body>
          <form @submit.prevent="handleFormSubmit">
            <mdb-row class="mb-5">
              <mdb-col offsetMd="2" md="8">
                <mdb-input
                  type="text"
                  :label="$t('general.formInput.name')"
                  v-model="employees.employee_name"
                  outline
                  required
                />
              </mdb-col>

              <mdb-col offsetMd="2" md="8">
                <mdb-input
                  type="text"
                  :label="$t('employees.formInput.employee_id')"
                  v-model="employees.employee_code"
                  outline
                  required
                />
              </mdb-col>

              <mdb-col offsetMd="2" md="8">
                <label>{{ $t("general.formInput.gender") }}</label>
                <mdb-form-inline>
                  <mdb-input
                    type="radio"
                    id="employee_gender-male"
                    name="employee_gender"
                    radioValue="male"
                    v-model="employees.employee_gender"
                    :label="$t('employees.formInput.male')"
                  />
                  <mdb-input
                    type="radio"
                    id="employee_gender-female"
                    name="employee_gender"
                    radioValue="female"
                    v-model="employees.employee_gender"
                    :label="$t('employees.formInput.female')"
                  />
                </mdb-form-inline>
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                <p class="h6 text-color-primary">
                  {{ $t("employees.text.employment_type") }}
                </p>
              </mdb-col>
            </mdb-row>

            <mdb-row class="mt-3">
              <mdb-col offsetMd="2" md="8">
                <label>{{ $t("employees.formInput.employment_type") }}</label>
                <mdb-form-inline>
                  <mdb-input
                    type="radio"
                    id="employment_type-contract"
                    name="employment_type"
                    radioValue="contract"
                    v-model="employees.employee_employment_type"
                    :label="$t('employees.formInput.contract')"
                  />
                  <mdb-input
                    type="radio"
                    id="employment_type-permanent"
                    name="employment_type"
                    radioValue="permanent"
                    v-model="employees.employee_employment_type"
                    :label="$t('employees.formInput.permanent')"
                  />
                </mdb-form-inline>
              </mdb-col>
            </mdb-row>

            <mdb-row>
              <mdb-col offsetMd="2" md="4">
                <ContractStatusDropdown
                  @updateContractStatusID="setContractStatusID"
                  :required="true"
                  :resetSelected="doesFormReset"
                />
              </mdb-col>

              <mdb-col md="4">
                <LeaveRuleDropdown
                  @updateLeaveRuleID="setLeaveRuleID"
                  :required="true"
                  :resetSelected="doesFormReset"
                />
              </mdb-col>
            </mdb-row>

            <mdb-row
              v-show="this.employees.employee_employment_type == 'contract'"
            >
              <mdb-col offsetMd="2" md="4">
                <mdb-date-picker-2
                  v-model="employees.employee_service_start_date"
                  :label="$t('employees.formInput.service_start_date')"
                  title="Select date"
                />
              </mdb-col>
              <mdb-col md="4">
                <mdb-date-picker-2
                  v-model="employees.employee_join_date"
                  :label="$t('employees.formInput.join_date')"
                  title="Select date"
                />
              </mdb-col>

              <mdb-col offsetMd="2" md="4">
                <mdb-input
                  type="number"
                  :label="$t('employees.formInput.duration')"
                  v-model="employees.employee_duration"
                  outline
                />
              </mdb-col>

              <mdb-col md="4">
                <mdb-date-picker-2
                  v-model="employees.employee_end_date"
                  :label="$t('employees.formInput.end_date')"
                  title="Select date"
                />
              </mdb-col>

              <mdb-col offsetMd="2" md="4">
                <mdb-input
                  type="number"
                  :label="$t('employees.formInput.salary')"
                  v-model="employees.employee_salary"
                  outline
                />
              </mdb-col>
            </mdb-row>

            <mdb-row
              v-show="this.employees.employee_employment_type == 'permanent'"
            >
              <mdb-col offsetMd="2" md="4">
                <mdb-date-picker-2
                  v-model="employees.employee_join_date"
                  :label="$t('employees.formInput.join_date')"
                  title="Select date"
                />
              </mdb-col>

              <mdb-col md="4">
                <mdb-input
                  type="number"
                  :label="$t('employees.formInput.probation_period')"
                  v-model="employees.employee_probation_period"
                  outline
                />
              </mdb-col>

              <mdb-col offsetMd="2" md="4">
                <mdb-date-picker-2
                  v-model="employees.employee_permanent_date"
                  :label="$t('employees.formInput.employee_permanent_date')"
                  title="Select date"
                />
              </mdb-col>

              <mdb-col md="4">
                <mdb-input
                  type="number"
                  :label="$t('employees.formInput.salary')"
                  v-model="employees.employee_salary"
                  outline
                />
              </mdb-col>

              <mdb-col offsetMd="2" md="4">
                <mdb-input
                  type="number"
                  :label="$t('employees.formInput.probation_salary')"
                  v-model="employees.employee_probation_salary"
                  outline
                />
              </mdb-col>
            </mdb-row>

            <mdb-row class="mt-5">
              <mdb-col offsetMd="2" md="8">
                <p class="h6 text-color-primary">
                  {{ $t("employees.text.organization_jobinfo") }}
                </p>
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col offsetMd="2" md="4">
                <CompanyDropdown
                  @updateCompanyID="setCompanyID"
                  :default_selected_value="employees.employee_company"
                  :required="true"
                  :resetSelected="doesFormReset"
                />
              </mdb-col>

              <mdb-col md="4">
                <CompanyBranchDropdown
                  @updateCompanyBranchID="setCompanyBranchID"
                  :default_selected_value="employees.employee_branch"
                  :required="true"
                  :resetSelected="doesFormReset"
                  :company_id="employees.employee_company"
                />
              </mdb-col>

              <mdb-col offsetMd="2" md="4">
                <DepartmentTreeDropdown
                  @updateDepartmentID="setDepartmentID"
                  :default_selected_value="employees.employee_department"
                  :required="true"
                />
              </mdb-col>

              <mdb-col md="4">
                <PositionDropdown
                  @updatePositionID="setPositionID"
                  :default_selected_value="employees.employee_position"
                  :required="true"
                  :resetSelected="doesFormReset"
                />
              </mdb-col>

              <mdb-col offsetMd="2" md="4">
                <mdb-date-picker-2
                  v-model="employees.employee_position_start_date"
                  :label="$t('employees.formInput.position_start_date')"
                  title="Select date"
                />
              </mdb-col>

              <mdb-col md="4">
                <!-- <mdb-input
                  type="number"
                  :label="$t('employees.formInput.employee_permission')"
                  v-model="employees.employee_permission"
                  outline
                /> -->


                <PermissionDropdown
                    @updatePermissionID="setPermissionID"
                    :default_selected_value="
                      employees.employee_permission
                    "
                    :required="true"
                    :resetSelected="doesFormReset"
                  />

              </mdb-col>

              <mdb-col offsetMd="2" md="4">
                <TransactionTypeDropdown
                  @updateTransactionTypeID="setTranscationTypeID"
                  :default_selected_value="
                    employees.employee_latest_history_type
                  "
                  :required="true"
                  :resetSelected="doesFormReset"
                />
              </mdb-col>
              <mdb-col md="4">
                <RankDropdown
                  @updateRankID="setRankID"
                  :default_selected_value="employees.employee_rank"
                  :required="true"
                  :resetSelected="doesFormReset"
                />
              </mdb-col>

              <mdb-col offsetMd="2" md="4">
                <EmployeeCategoryDropdown
                  @updateEmployeeCategoryID="setEmployeeCategoryID"
                  :default_selected_value="employees.employee_category"
                  :required="true"
                  :resetSelected="doesFormReset"
                />
              </mdb-col>

              <mdb-col md="4">
                <EmployeeGroupDropdown
                  @updateEmployeeGroupID="setEmployeeGroupID"
                  :default_selected_value="employees.employee_group"
                  :required="true"
                  :resetSelected="doesFormReset"
                />
              </mdb-col>
            </mdb-row>

            <mdb-row class="mt-5">
              <mdb-col offsetMd="2" md="8">
                <p class="h6 text-color-primary">
                  {{ $t("employees.text.self_service_info") }}
                </p>
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col offsetMd="2" md="4">
                <mdb-input
                  type="email"
                  :label="$t('general.formInput.email')"
                  v-model="employees.email"
                  outline
                />
              </mdb-col>

              <mdb-col md="4">
                <mdb-input
                  type="password"
                  :label="$t('general.formInput.password')"
                  v-model="employees.employee_password"
                  outline
                />
              </mdb-col>
            </mdb-row>

            <mdb-row calss="mt-5">
              <mdb-col offsetMd="2" md="8">
                <mdb-btn
                  type="button"
                  class="btn_back"
                  color="uno-color"
                  @click="redirectBackAction"
                >
                  <mdb-icon icon="arrow-left" size="md" />
                  {{ $t("general.button.go_back") }}
                </mdb-btn>

                <mdb-btn
                  type="Submit"
                  color="uno-color"
                  class="btn_submit"
                  :disabled="formSubmitting"
                >
                  <mdb-icon
                    icon="file-upload"
                    size="md"
                    v-show="!formSubmitting"
                  />

                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="formSubmitting"
                  ></span>
                  {{ $t("general.button.submit") }}
                </mdb-btn>
              </mdb-col>
            </mdb-row>
          </form>
        </mdb-card-body>
      </mdb-card>
    </template>
  </div>
</template>
<script>
import Employee from "@/apis/Employee";
import CompanyDropdown from "@/components/CompanyDropdown";
import CompanyBranchDropdown from "@/components/CompanyBranchDropdown";
import DepartmentTreeDropdown from "@/components/DepartmentTreeDropdown";
import PositionDropdown from "@/components/PositionDropdown";
import TransactionTypeDropdown from "@/components/TransactionTypeDropdown";
import EmployeeGroupDropdown from "@/components/EmployeeGroupDropdown";
import EmployeeCategoryDropdown from "@/components/EmployeeCategoryDropdown";
import ContractStatusDropdown from "@/components/ContractStatusDropdown";
import LeaveRuleDropdown from "@/components/LeaveRuleDropdown";
import RankDropdown from "@/components/RankDropdown";

import PermissionDropdown from "@/components/PermissionDropdown";
import {
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFormInline,
  mdbDatePicker2,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    mdbFormInline,
    mdbDatePicker2,
    CompanyDropdown,
    CompanyBranchDropdown,
    DepartmentTreeDropdown,
    PositionDropdown,
    TransactionTypeDropdown,
    EmployeeGroupDropdown,
    EmployeeCategoryDropdown,
    RankDropdown,
    ContractStatusDropdown,
    LeaveRuleDropdown,
    PermissionDropdown,
  },
  data() {
    return {
      employees: {
        employee_id: "",
        employee_code: "",
        employee_name: "",
        employee_gender: "male",
        employee_employment_type: "permanent",
        employee_service_start_date: "",
        employee_join_date: "",
        employee_duration: "",
        employee_end_date: "",
        employee_probation_period: "",
        employee_permanent_date: "",
        employee_salary: "",
        employee_probation_salary: "",
        employee_company: "",
        employee_branch: "",
        employee_department: "",
        employee_position: "",
        employee_latest_history_type: "",
        employee_position_start_date: "",
        employee_permission: "",
        employee_category: "",
        employee_group: "",
        employee_rank: "",
        email: "",
        employee_password: "",

        employee_leave_policy_id: "",
        employee_contract_status: "",
      },
      is_active_contract: "0",
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,
    };
  },
  computed: {
    doesEditForm() {
      return this.employees.employee_id != "" ? true : false;
    },
  },
  
  watch: {
    'employees.employee_join_date': function () {
      if(this.employees.employee_employment_type == 'contract' && 
         this.employees.employee_duration!='' && 
         this.employees.employee_duration!=undefined && 
         this.employees.employee_join_date!='' && 
         this.employees.employee_join_date!=undefined 
        //  && (this.employees.employee_end_date==undefined || this.employees.employee_end_date=='')
         
         ){
        let joindate_timestamp  =Date.parse(this.employees.employee_join_date)
        let date_start_date = new Date(joindate_timestamp);
        
        date_start_date.setMonth(date_start_date.getMonth()+parseInt(this.employees.employee_duration));

        this.employees.employee_end_date = date_start_date.getFullYear() +"-"+
                                         ('0' + (date_start_date.getMonth()+1)).slice(-2)+"-"+
                                         ('0' + date_start_date.getDate()).slice(-2);

      }else if(this.employees.employee_employment_type == 'contract' && this.employees.employee_duration!='' &&  this.employees.employee_duration!=undefined && (this.employees.employee_join_date=='' ||  this.employees.employee_join_date==undefined)){
        this.employees.employee_end_date = '' 
      }else if(
        this.employees.employee_employment_type == 'permanent' && 
        this.employees.employee_probation_period!='' && 
        this.employees.employee_probation_period!=undefined && 
        this.employees.employee_join_date!='' && 
        this.employees.employee_join_date!=undefined
      ){
        
        let joindate_timestamp  =Date.parse(this.employees.employee_join_date)
        let date_start_date = new Date(joindate_timestamp);
        
        date_start_date.setMonth(date_start_date.getMonth()+parseInt(this.employees.employee_probation_period));

        this.employees.employee_permanent_date = date_start_date.getFullYear() +"-"+
                                         ('0' + (date_start_date.getMonth()+1)).slice(-2)+"-"+
                                         ('0' + date_start_date.getDate()).slice(-2);

      } else{
        this.employees.employee_permanent_date = '' 
      }
      
    },
    'employees.employee_duration': function () {
      if(this.employees.employee_employment_type == 'contract' && 
         this.employees.employee_join_date!='' && 
         this.employees.employee_join_date != undefined && 
         this.employees.employee_duration!='' && 
         this.employees.employee_duration!=undefined 
        //  && (this.employees.employee_end_date==undefined || this.employees.employee_end_date=='')
         
         ){
      let joindate_timestamp  =Date.parse(this.employees.employee_join_date)
      let date_start_date = new Date(joindate_timestamp);
      date_start_date.setMonth(date_start_date.getMonth()+parseInt(this.employees.employee_duration));

      this.employees.employee_end_date = date_start_date.getFullYear() +"-"+
                                         ('0' + (date_start_date.getMonth()+1)).slice(-2)+"-"+
                                         ('0' + date_start_date.getDate()).slice(-2);


      }else if(this.employees.employee_employment_type == 'contract' && this.employees.employee_join_date!='' && this.employees.employee_join_date != undefined && (this.employees.employee_duration=='' || this.employees.employee_duration==undefined)){

      this.employees.employee_end_date = ''
      
      }
    },
    'employees.employee_probation_period': function () {
      if(this.employees.employee_employment_type == 'permanent' && 
         this.employees.employee_join_date!='' && 
         this.employees.employee_join_date != undefined && 
         this.employees.employee_probation_period!='' && 
         this.employees.employee_probation_period!=undefined 
        //  && (this.employees.employee_end_date==undefined || this.employees.employee_end_date=='')
         
         ){
      let joindate_timestamp  =Date.parse(this.employees.employee_join_date)
      let date_start_date = new Date(joindate_timestamp);
      date_start_date.setMonth(date_start_date.getMonth()+parseInt(this.employees.employee_probation_period));

      this.employees.employee_permanent_date = date_start_date.getFullYear() +"-"+
                                         ('0' + (date_start_date.getMonth()+1)).slice(-2)+"-"+
                                         ('0' + date_start_date.getDate()).slice(-2);


      }else if(this.employees.employee_employment_type == 'permanent' && this.employees.employee_join_date!='' && this.employees.employee_join_date != undefined && (this.employees.employee_probation_period=='' || this.employees.employee_probation_period==undefined)){

      this.employees.employee_permanent_date = ''
      
      }
    }

  },
  

  methods: {


    setContractStatusID(val, is_active) {
      this.employees.employee_contract_status = val;
      this.is_active_contract = is_active;
    },
    setLeaveRuleID(val) {
      this.employees.employee_leave_policy_id = val;
    },
    setCompanyID(val) {
      this.employees.employee_company = val;
    },
    setCompanyBranchID(val) {
      this.employees.employee_branch = val;
    },
    setDepartmentID(val) {
      this.employees.employee_department = val;
    },
    setPositionID(val) {
      this.employees.employee_position = val;
    },
    setTranscationTypeID(val) {
      this.employees.employee_latest_history_type = val;
    },
    setEmployeeGroupID(val) {
      this.employees.employee_group = val;
    },
    setEmployeeCategoryID(val) {
      this.employees.employee_category = val;
    },
    setRankID(val) {
      this.employees.employee_rank = val;
    },
    
    setPermissionID(val) {
      this.employees.employee_permission = val;
    },

    handleFormSubmit() {
      // console.log(this.employees);
      if (this.is_active_contract == "0") {
        this.feedback_message =
          "Please Select Active Contract in Contract Status";
        this.feedback_error = true;
      } else {
        this.resetAlert();
        this.formSubmitting = true;

        if (this.employees.employee_employment_type == "permanent") {
          delete this.employees.employee_duration;
          delete this.employees.employee_end_date;
          this.employees.employee_service_start_date = this.employees.employee_join_date;
        }

        Employee.save(this.employees).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              if (this.employees.employee_id != "") {
                this.resetForm();
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });
                this.$router.push({ name: "listEmployee" });
              } else {
                this.resetForm();
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });
              }
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.formSubmitting = false;
          }
        });
      }
    },
    redirectBackAction() {
      this.$router.push({ name: "listEmployee" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.employees).forEach((key) => {
        this.employees[key] = "";
      });

      this.employees.employee_duration = "";
      this.employees.employee_end_date = "";
      this.mployee_gender= "male"
      this.employees.employee_employment_type = "permanent";
      this.doesFormReset = !this.doesFormReset;
    },
  },
};
</script>