<template>
  <div>
    <mdb-select
    outline
      search
      v-model="axiosOptions"
      :label="selectLabel"
      @getValue="updateSelectedValue"
      :disabled="loading"
    />
  </div>
</template>

<script>
import { mdbSelect } from "mdbvue";
import OvertimeRule from "@/apis/OvertimeRule";
export default {
  name: "OvertimeRuleDropdown",
  components: {
    mdbSelect,
  },
  props: ["default_selected_value", "required","resetSelected"],
  data() {
    return {
      axiosOptions: [],
      loading:true,
      selectLabel : "Loading Overtime Rule List"
    };
  },
  mounted() {
       OvertimeRule.all()
        .then((response) => {
          if (response.status === 200) {
            const overtime_rules = response.data.data;
            if(this.required === false){
                this.axiosOptions.push({
                    text: "All",
                    value: "",
                });
            }            

            for (let overtime_rule of overtime_rules) {
                if (this.default_selected_value == overtime_rule.ot_rule_id) {
                    this.axiosOptions.push({
                    text: overtime_rule.ot_rule_name,
                    value: overtime_rule.ot_rule_id,
                    selected:true
                    });
                } else {
                    this.axiosOptions.push({
                    text: overtime_rule.ot_rule_name,
                    value: overtime_rule.ot_rule_id,
                    });
                }
            }    
            this.loading = false
            this.selectLabel = "Select Overtime Rule"

          }
        })
  },
  methods: {
    updateSelectedValue(val) {
      if (val !== null) {
        this.$emit("updateOvertimeRuleID", val);
      }
    },
  },
    watch:{
    resetSelected(newValue, oldValue){
      if (newValue !== oldValue){
        this.axiosOptions = this.axiosOptions.map(val => ({text: val.text, value: val.value})) 
      }
    }
  }
};
</script>
