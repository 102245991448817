<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <template v-if="!employee_id">
                <mdb-row class="mb-3">
                  <mdb-col offsetMd="2" md="8">
                    <div class="float-left mt-3 sec_title">Select Employee</div>
                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="float-right btn_outline_style"
                      icon="user-friends"
                      @click.native="modal = true"
                    >
                      {{ $t("payrolls.button.select_employee") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>

                <mdb-row v-if="selectedEmployeeData" class="mb-5">
                  <mdb-col offsetMd="2" md="8">
                    <div class="approval_info_box">
                      <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
                    </div>
                  </mdb-col>
                </mdb-row>
              </template>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label>Contract Type</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="contract_type_contract_extend"
                      name="contract_type"
                      radioValue="contract_extend"
                      v-model="contracts.contract_type"
                      label="Extension Contract"
                    />
                    <mdb-input
                      type="radio"
                      id="contract_type_new_replacement"
                      name="contract_type"
                      radioValue="new_replacement"
                      v-model="contracts.contract_type"
                      label="Replacement Contract"
                    />
                    <mdb-input
                      type="radio"
                      id="contract_type_new_hire"
                      name="contract_type"
                      radioValue="new_hire"
                      v-model="contracts.contract_type"
                      label="New Hiring Contract"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>
              <br /><br />

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label>{{ $t("contract.formInput.employment_type") }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="employment_type_permanent"
                      name="employment_type"
                      radioValue="permanent"
                      v-model="contracts.contract_employment_type"
                      :label="$t('employees.formInput.permanent')"
                    />
                    <mdb-input
                      type="radio"
                      id="employment_type_contract"
                      name="employment_type"
                      radioValue="contract"
                      v-model="contracts.contract_employment_type"
                      :label="$t('employees.formInput.contract')"
                    />
                  </mdb-form-inline>
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('contract.formInput.reference')"
                    v-model="contracts.contract_reference"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-date-picker-2
                    v-model="contracts.contract_start_date"
                    :label="$t('general.formInput.start_date')"
                    title="Select date"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row
                v-show="this.contracts.contract_employment_type == 'contract'"
              >
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('employees.formInput.duration')"
                    v-model="contracts.contract_duration"
                    outline
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-date-picker-2
                    v-model="contracts.contract_end_date"
                    :label="$t('general.formInput.end_date')"
                    title="Select date"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row
                v-show="this.contracts.contract_employment_type == 'permanent'"
              >
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="number"
                    :label="$t('employees.formInput.probation_period')"
                    v-model="contracts.contract_probation_period"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-date-picker-2
                    v-model="contracts.contract_permanent_date"
                    :label="$t('contract.formInput.permanent_date')"
                    title="Select date"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <ContractStatusDropdown
                    @updateContractStatusID="setContractStatusID"
                    :default_selected_value="contracts.contract_status"
                    :required="true"
                    :resetSelected="doesFormReset"
                    v-if="!isLoading"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <LeaveRuleDropdown
                    @updateLeaveRuleID="setLeaveRuleID"
                    :default_selected_value="contracts.contract_leave_policy_id"
                    :required="true"
                    :resetSelected="doesFormReset"
                    v-if="!isLoading"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row class="mt-3" v-show="is_active == '0'">
                <mdb-col offsetMd="2" md="8">
                  <p class="h6 text-color-primary">Ending Info</p>
                </mdb-col>
              </mdb-row>

              <mdb-row v-show="is_active == '0'">
                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    v-model="contracts.contract_quit_date"
                    :label="$t('general.formInput.quit_date')"
                    title="Select date"
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-input
                    type="textarea"
                    :label="$t('general.formInput.remark')"
                    v-model="contracts.contract_remark"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row v-show="is_active == '0'">
                <mdb-col offsetMd="2" md="8">
                  <label>Quit Factor</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="contract_quit_factor_organization"
                      name="contract_quit_factor"
                      radioValue="organization"
                      v-model="contracts.contract_quit_factor"
                      label="Organization"
                    />
                    <mdb-input
                      type="radio"
                      id="contract_quit_factor_employee"
                      name="contract_quit_factor"
                      radioValue="employee"
                      v-model="contracts.contract_quit_factor"
                      label="Employee"
                    />
                  </mdb-form-inline>
                  <br />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    label="Exit Interview"
                    v-model="contracts.contract_exit_interview"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapSingleSelectTable
          @updateSelectedEmployee="setSelectedEmployee"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import EmployeeContract from "@/apis/EmployeeContract";
import ContractStatusDropdown from "@/components/ContractStatusDropdown";
import LeaveRuleDropdown from "@/components/LeaveRuleDropdown";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import EmployeeListBoostrapSingleSelectTable from "@/components/EmployeeListBoostrapSingleSelectTable";
import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  //   mdbSelect,
  mdbFormInline,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbDatePicker2,
    // mdbSelect,
    mdbFormInline,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeInfoBoxLayout,

    ModalDeleteConfimation,
    ContractStatusDropdown,
    LeaveRuleDropdown,
    EmployeeListBoostrapSingleSelectTable,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      contracts: {
        contract_id: "",
        contract_employee_id: this.employee_id ? this.employee_id : "",
        contract_reference: "",
        contract_employment_type: "permanent",
        contract_start_date: "",
        contract_end_date: "",
        contract_duration: "",
        contract_probation_period: "",
        contract_permanent_date: "",
        contract_status: "",
        contract_remark: "",
        contract_quit_date: "",
        contract_leave_policy_id: "",

        contract_type: "",
        contract_quit_factor: "",
        contract_exit_interview:""
      },
      is_active: "1",
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,
      selectedEmployeeData: null,
      modal: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.contracts.contract_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    setSelectedEmployee(val) {
      this.modal = false;
      this.selectedEmployeeData = val;
      this.contracts.contract_employee_id = val.employee_id;
    },
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    setContractStatusID(val, is_active) {
      this.contracts.contract_status = val;
      this.is_active = is_active;
    },
    setLeaveRuleID(val) {
      this.contracts.contract_leave_policy_id = val;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          EmployeeContract.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data);

                  let contract_id,
                    contract_employee_id,
                    contract_reference,
                    contract_employment_type,
                    contract_start_date,
                    contract_end_date,
                    contract_duration,
                    contract_probation_period,
                    contract_permanent_date,
                    contract_status,
                    contract_remark,
                    contract_quit_date,
                    contract_leave_policy_id,
                    contract_type,
                    contract_quit_factor,
                    contract_exit_interview;
                  // populate field
                  ({
                    contract_id,
                    contract_employee_id,
                    contract_reference,
                    contract_employment_type,
                    contract_start_date,
                    contract_end_date,
                    contract_duration,
                    contract_probation_period,
                    contract_permanent_date,
                    contract_status,
                    contract_remark,
                    contract_quit_date,
                    contract_leave_policy_id,
                    contract_type,
                    contract_quit_factor,
                    contract_exit_interview
                  } = response.data.data);
                  this.contracts.contract_id = contract_id;
                  this.contracts.contract_employee_id = contract_employee_id;
                  this.contracts.contract_reference = contract_reference;
                  this.contracts.contract_employment_type =
                    contract_employment_type;
                  this.contracts.contract_start_date =
                    contract_start_date != "0000-00-00"
                      ? contract_start_date
                      : "";
                  this.contracts.contract_end_date =
                    contract_end_date != "0000-00-00" ? contract_end_date : "";
                  this.contracts.contract_duration = contract_duration;
                  this.contracts.contract_probation_period =
                    contract_probation_period;
                  this.contracts.contract_permanent_date =
                    contract_permanent_date != "0000-00-00"
                      ? contract_permanent_date
                      : "";
                  this.contracts.contract_status = contract_status;
                  this.contracts.contract_remark = contract_remark;
                  this.contracts.contract_quit_date =
                    contract_quit_date != "0000-00-00"
                      ? contract_quit_date
                      : "";
                  this.contracts.contract_leave_policy_id =
                    contract_leave_policy_id;

                  this.contracts.contract_type = contract_type;
                  this.contracts.contract_quit_factor = contract_quit_factor;
                  this.contract_exit_interview = contract_exit_interview;

                  if (!this.employee_id) {
                    this.selectedEmployeeData = {
                      epinfo_name: response.data.data.epinfo_name,
                      employee_code: response.data.data.employee_code,
                      profile_image: response.data.data.profile_image,
                      department_name: response.data.data.department_name,
                      jp_name: response.data.data.jp_name,
                      rt_name: response.data.data.rt_name,
                    };
                    //emp info
                  }
                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "contractList" });
              }
            })
            .catch(() => {
              // console.log(error)
              this.$router.push({ name: "contractList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "contractList" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      if (this.contracts.contract_employment_type == "permanent") {
        delete this.contracts.contract_duration;
        delete this.contracts.contract_end_date;
      } else {
        delete this.contracts.contract_permanent_date;
        delete this.contracts.contract_probation_period;
      }

      EmployeeContract.save(this.contracts).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            if (this.employee_id) {
              this.$router.push({
                name: "contractList",
                params: { employee_id: this.contracts.contract_employee_id },
              });
            } else {
              this.$router.push({ name: "contractManageList" });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      if (this.employee_id) {
        this.$router.push({
          name: "contractList",
          params: { employee_id: this.contracts.contract_employee_id },
        });
      } else {
        this.$router.push({ name: "contractManageList" });
      }
    },
    resetAlert() {
      this.feedback_error = false;
    },
    // resetForm() {
    //   Object.keys(this.contracts).forEach((key) => {
    //     this.contracts[key] = "";
    //   });
    //   this.contracts.contract_employee_id = this.employee_id;
    //   this.contracts.contract_employment_type = "permanent";
    //   this.doesFormReset = !this.doesFormReset;
    // },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;
      if (this.contracts.contract_id > 0) {
        EmployeeContract.delete({
          contract_id: this.contracts.contract_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              if (this.employee_id) {
                this.$router.push({
                  name: "contractList",
                  params: { employee_id: this.contracts.contract_employee_id },
                });
              } else {
                this.$router.push({ name: "contractManageList" });
              }
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>