<template>
  <div class="report_page">
    <div class="row">
      <div class="col-md-5 col-xl-4 sub-menu mb-xl-0 mb-5">
        <h6 class="sub-title text-uppercase font-weight-bold menu-text-color">
          {{ $t("menu.report.general_report") }}
        </h6>
        <ul class="list-unstyled">


          <li class="" v-can="['special_report']">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'managementReport' }"
              >{{ $t("menu.report.management_report") }}</router-link
            >
          </li>


          <li class="">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'attendanceLateEarlyReport' }"
              >{{ $t("menu.report.late_report") }}</router-link
            >
          </li>
          <!-- <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'fixedAttendanceReport' }"
                        >{{
                          $t("menu.report.fixing_attendance_report")
                        }}</router-link
                      >
                    </li> -->

          <li class=""  v-can="['leave_taken_balance']">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'leaveTakenBalanceReport' }"
              >{{ $t("menu.report.leave_taken_balance_report") }}</router-link
            >
          </li>


          <li class=""  v-can="['leave_taken_balance']">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'currentLeaveReport' }"
              >Current Leave Report</router-link
            >
          </li>
          <!-- <li class="">
                      <router-link
                        class="menu-item-text-color menu-item pl-1 mt-2"
                        :to="{ name: 'dashboard' }"
                        >{{ $t("menu.report.overtime_report") }}</router-link
                      >
                    </li> -->
        </ul>
      </div>

      <div class="col-md-5 col-xl-4 sub-menu mb-xl-0 mb-5">
        <h6 class="sub-title text-uppercase font-weight-bold menu-text-color">
          {{ $t("menu.report.payroll_report") }}
        </h6>
        <ul class="list-unstyled">
          <li class="">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'accountReport' }"
              >{{ $t("menu.report.account_report") }}</router-link
            >
          </li>
          <li class="">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'bankReport' }"
              >{{ $t("menu.report.bank_report") }}</router-link
            >
          </li>
          <li class="">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'taxReport' }"
              >{{ $t("menu.report.tax_report") }}</router-link
            >
          </li>
          <li class="">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'ssbReport' }"
              >{{ $t("menu.report.ssb_report") }}</router-link
            >
          </li>
        </ul>
      </div>

      <div class="col-md-5 col-xl-4 sub-menu mb-xl-0 mb-5">
        <h6 class="sub-title text-uppercase font-weight-bold menu-text-color">
          {{ $t("menu.report.special_report") }}
        </h6>
        <ul class="list-unstyled">
          <li class="">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'executiveReport' }"
              >{{ $t("menu.report.executive_summary") }}</router-link
            >
          </li>
          <li class="">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'salaryReport' }"
              >{{ $t("menu.report.salary_summary") }}</router-link
            >
          </li>
          <li class="">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'headCountReport' }"
              >{{ $t("menu.report.head_count") }}</router-link
            >
          </li>
          <li class="">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'actionsReport' }"
              >{{ $t("menu.report.actions") }}</router-link
            >
          </li>
          <li class="">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'leavesReport' }"
              >{{ $t("menu.report.leaves") }}</router-link
            >
          </li>
          <li class="">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'turnoverReport' }"
              >{{ $t("menu.report.turnover") }}</router-link
            >
          </li>

          <li class="">
            <router-link
              class="menu-item-text-color menu-item pl-1 mt-2"
              :to="{ name: 'trainingReport' }"
              >{{ $t("menu.report.training") }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
body, .body-wrapper {    
    background: white !important;    
}
.sub-title {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
}
/* li{
  padding: 10px 0;  
} */
ul li a {
  width: 100%;
  transition: 0.3s;
  display: inline-block;
  padding: 10px 0;
}
ul li a:hover {
  padding-left: 15px !important;
  background: #e6e6e6;
}
</style>