<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card
          class="mb-4"
          v-if="headcountGraphData && headcountGraphData.label.length > 0"
        >
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <!-- Headcount {{ year }} -->

                <h6 class="mb-3">Headcount By Department</h6>

                <div>
                  <!-- <HeadCountStackedBarChart
                    v-if="headcountGraphData"
                    :headcountGraphData="headcountGraphData"
                  /> -->

                  <mdb-tbl responsive striped bordered sm>
                    <mdb-tbl-head style="background: #96a6bf; color: #fff">
                      <tr>
                        <td>Department</td>
                        <td class="text-end">Permanent</td>
                        <td class="text-end">Contract</td>
                        <td class="text-end">Menpower</td>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body>
                      <tr
                        v-for="(item, index) in headcountGraphData.label"
                        :key="index"
                      >
                        <td>
                          {{ item }}
                        </td>

                        <td class="text-end">
                          {{ headcountGraphData.permanentDataset[index] }}
                        </td>

                        <td  class="text-end">
                          {{ headcountGraphData.contractDataset[index] }}
                        </td>

                        <td class="text-end">
                          {{
                            parseInt(
                              headcountGraphData.permanentDataset[index]
                            ) +
                            parseInt(headcountGraphData.contractDataset[index])
                          }}
                        </td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </div>
              </mdb-col>
            </mdb-row></mdb-card-body
          ></mdb-card
        >

        <mdb-card class="mb-4" v-if="headcount_data.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Headcount Report</h6>
                <div>
                  <mdb-tbl responsive striped bordered sm>
                    <mdb-tbl-head style="background: #96a6bf; color: #fff">
                      <tr>
                        <td
                          v-for="(item_value, index2) in headcount_header"
                          :key="index2"
                          class="rotate_text"
                          style="writing-mode: vertical-rl"
                        >
                          {{ item_value }}
                        </td>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body>
                      <tr
                        v-for="(headcount_item, index) in headcount_data"
                        :key="index"
                      >
                        <td
                          v-for="(item_value, index2) in headcount_item"
                          :key="index2"
                        >
                          {{ item_value }}
                        </td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </div>
              </mdb-col>
            </mdb-row></mdb-card-body
          ></mdb-card
        >

        <mdb-card class="mb-4" v-if="headcountComparisionData && headcountComparisionData.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <!-- /////////////////// -->

                <h6 class="mb-3">Monthly Headcount Analysis</h6>
                <div>
                  <mdb-tbl responsive striped bordered sm>
                    <mdb-tbl-head style="background: #96a6bf; color: #fff">
                      <tr>
                        <td>Description</td>
                        <td
                          v-for="(
                            item_value, index
                          ) in headcountComparisionData"
                          :key="index"
                           class="text-end"
                        >
                          {{ item_value.date }}
                        </td>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body>
                      <tr>
                        <td>Active Headcount</td>
                        <td
                          v-for="(
                            item_value, index
                          ) in headcountComparisionData"
                          :key="index"
                           class="text-end"
                        >
                          {{ item_value.active }}
                        </td>
                      </tr>

                      <tr>
                        <td>New Hire</td>
                        <td
                          v-for="(
                            item_value, index
                          ) in headcountComparisionData"
                          :key="index"
                           class="text-end"
                        >
                          {{ item_value.new_hire }}
                        </td>
                      </tr>

                      <tr>
                        <td>Separation</td>
                        <td
                          v-for="(
                            item_value, index
                          ) in headcountComparisionData"
                          :key="index"
                           class="text-end"
                        >
                          {{ item_value.separation }}
                        </td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </div>
              </mdb-col>
            </mdb-row></mdb-card-body
          ></mdb-card
        >

        <mdb-card class="mb-4" v-if="headcountServiceYearData && headcountServiceYearData.label.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <!-- ////////////////////////// -->

                <h6 class="mb-3">Headcount By Length Of Service</h6>
                <div>
                  <HorizontalBarChartView
                    v-if="headcountServiceYearData"
                    :barGraphData="headcountServiceYearData"
                    :ylabel="'Service Year'"
                    :xlabel="'No of Employees'"
                  />
                </div>
                <!-- ////////////////////////// -->
              </mdb-col>
            </mdb-row>
          </mdb-card-body>
        </mdb-card>

        <!-- ///////////////////////////////////////// -->

        <mdb-row
          v-if="
            headcountGraphData &&
            headcountGraphData.label.length == 0 &&
            headcount_data &&
            headcount_data.length == 0 &&
            headcountComparisionData &&
            headcountComparisionData.length == 0 &&
            headcountServiceYearData &&
            headcountServiceYearData.label.length == 0
          "
        >
          <mdb-col>
            <mdb-card>
              <mdb-card-body>
                <div class="d-flex justify-content-center">
                  <h5 style="margin-top: 60px">No Record Found.</h5>
                </div>
                <div class="d-flex justify-content-center">
                  <img src="/empty_item.svg" alt="Empty Record" />
                </div>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </template>
    </div>
  </div>
</template>
<script>
import ManagementReport from "@/apis/ManagementReport";
import { mapActions, mapGetters } from "vuex";

// import HeadCountStackedBarChart from "@/components/HeadCountStackedBarChart";

import HorizontalBarChartView from "@/components/HorizontalBarChartView";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    HorizontalBarChartView,
    // HeadCountStackedBarChart,
  },
  data() {
    return {
      feedback_error: false,
      feedback_message: "",

      headcountGraphData: false,
      headcount_header: [],
      headcount_data: [],
      headcountComparisionData: [],
      headcountServiceYearData: false,
    };
  },
  props: ["year", "month"],
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    fetchData() {
      this.updateLoading(true);

      const payload = {
        date: this.month + "-" + this.year,
      };

      // const response = {
      //   status: 200,
      //   data: {
      //     code: 200,
      //     headcountGraphData: {
      //       label: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
      //       permanentDataset: [12, 19, 3, 5, 2, 3],
      //       contractDataset: [12, 19, 3, 5, 2, 3],
      //     },
      //     headcountServiceYearData: {
      //       label: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
      //       dataLabel: "No of Employee",
      //       dataSet: [12, 19, 3, 5, 2, 3],
      //     },
      //     headcount_header: [
      //       "position",
      //       "department 1",
      //       "department 2",
      //       "department 3",
      //       "department 4",
      //     ],
      //     headcount_data: [
      //       ["lorum ipsuam", 1, 2, 3, 4],
      //       ["lorum ipsuam", 1, 2, 3, 4],
      //     ],

      //     headcountComparisionData: [
      //       { date: "January", active: "1", new_hire: "1", separation: "2" },
      //       { date: "February", active: "2", new_hire: "2", separation: "3" },
      //       { date: "March", active: "3", new_hire: "3", separation: "4" },
      //       { date: "April", active: "4", new_hire: "4", separation: "5" },
      //     ],
      //   },
      // };

      // this.headcountGraphData = response.data.headcountGraphData;
      // this.headcountServiceYearData = response.data.headcountServiceYearData;
      // this.headcount_header = response.data.headcount_header;
      // this.headcount_data = response.data.headcount_data;
      // this.headcountComparisionData = response.data.headcountComparisionData;

      ManagementReport.headcountGraphData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            // console.log(response.data);
            this.headcountGraphData = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.headcountHeaderAndData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.headcount_header = response.data.data.headcount_header;
            this.headcount_data = response.data.data.headcount_data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.headcountMonthlyComparison(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.headcountComparisionData = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.headcountServiceYear(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.headcountServiceYearData = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });
    },

    validateParams() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const month = months.find(
        (month) => month.toLowerCase() == this.month.toLowerCase()
      );
      if (!month) {
        this.$router.push({ name: "managementReport" });
      } else {
        this.month = month;
      }

      if (!/^\d{4}$/.test(this.year)) {
        this.$router.push({ name: "managementReport" });
      }

      this.fetchData();
    },
  },
  created() {
    this.validateParams();
  },
};
</script>

<style scoped>
.rotate_text {
  transform: rotate(180deg);

  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(180deg);

  /* Firefox */
  -moz-transform: rotate(180deg);

  /* IE */
  -ms-transform: rotate(180deg);

  /* Opera */
  -o-transform: rotate(180deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.text-end{
  text-align:right;
  padding-right:10px;
}
</style>