<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-if="!isLoading">

        <HrTrainingSearchForm @submitFilterForm="handleSubmitFilterForm" :filterFormData="searchFormData"/>


        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn outline="primary" @click="createNewRecord">
                <mdb-icon far icon="plus-square" size="lg" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head >
                <tr>
                  <th >
                    {{ $t("training_info.formInput.title") }}
                  </th>
                  <th >
                    {{ $t("training_info.formInput.trainer") }}
                  </th>
                  <th >
                    {{ $t("training_info.formInput.start_date") }}
                  </th>
                  <th >
                    {{ $t("training_info.formInput.end_date") }}
                  </th>
                  <th >
                    {{ $t("training_info.formInput.duration_hours") }}
                  </th>   
                  <th >
                    {{ $t("training_info.formInput.attendees") }}
                  </th>   
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(list_item, index) in trainings.data"
                  :key="index"
                >
                  <td scope="row" @click="rowClickEvent(list_item)">
                    {{ list_item.hrt_title }}
                  </td>
                  <td @click="rowClickEvent(list_item)">
                    {{ list_item.hrt_trainer }}
                  </td>
                  <td @click="rowClickEvent(list_item)" class="text-right">
                    {{ list_item.hrt_start_date | formatDateString }}
                  </td>
                  <td @click="rowClickEvent(list_item)" class="text-right">
                    {{ list_item.hrt_end_date | formatDateString }}
                  </td>
                  <td @click="rowClickEvent(list_item)">
                    {{ list_item.hrt_hours }}
                  </td>
                  <td @click="rowClickEvent(list_item)">
                    {{ list_item.attendees }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <pagination :data="trainings" @pagination-change-page="fetchRecordList" :limit="2"></pagination>
          </mdb-card-body>
        </mdb-card>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
Vue.component('pagination', require('laravel-vue-pagination'))
import HrTraining from "@/apis/HrTraining";


import HrTrainingSearchForm from "@/components/search/HrTrainingSearchForm";
import { mapActions, mapGetters } from "vuex";


import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    HrTrainingSearchForm
  },

  data() {
    return {
      trainings: {},
      searchFormData:{
        employee_name:"",
        employee_code:"",
        hrt_title:"",
        start_date:"",
        end_date:""
      },
      currentSort: "hrt_title",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    rowClickEvent(item) {
      this.$router.push({
          name: "viewHrTraining",
          params: { _id: item.hrt_id },
        });
    },

    createNewRecord() {
      this.$router.push({ name: "createHrTraining" });
    },

    handleSubmitFilterForm(val){
      this.searchFormData = val
      // console.log(this.searchFormData)
      this.fetchRecordList()
    },

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      const params = {
        page:page,
        employee_name: this.searchFormData.employee_name,
        employee_code: this.searchFormData.employee_code,
        hrt_title: this.searchFormData.hrt_title,
        start_date: this.searchFormData.start_date,
        end_date: this.searchFormData.end_date
};

      HrTraining.all(params)
        .then((response) => {
          if (response.status === 200) {
            this.trainings = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>
