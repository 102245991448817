<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <template>
      <mdb-card class="mb-5">
        <mdb-card-body>
          <form @submit.prevent="handleFormSubmit" id="employeeAdvSearch">
            <mdb-row>
              <mdb-col offsetMd="0" offsetLg="2" lg="8" md="12">
                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">Keywords</label>
                  </mdb-col>
                  <mdb-col md="8">
                    <mdb-input
                      type="text"
                      v-model="serachFormData.keywords"
                      label="Keywords"
                      outline
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">Position</label>
                  </mdb-col>
                  <mdb-col md="8">
                    <PositionDropdown
                      @updatePositionID="setPositionID"
                      :default_selected_value="serachFormData.jp_id"
                      :required="false"
                      :resetSelected="doesFormReset"
                    />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">Deparment</label>
                  </mdb-col>
                  <mdb-col md="8">
                    <DepartmentDropdown
                      @updateDepartmentID="setDepartmentID"
                      :default_selected_value="serachFormData.department_id"
                      :required="false"
                      :resetSelected="doesFormReset"
                    />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">User Group</label>
                  </mdb-col>
                  <mdb-col md="8">
                    <EmployeeGroupDropdown
                      @updateEmployeeGroupID="setEmployeeGroupID"
                      :default_selected_value="serachFormData.employee_group"
                      :required="false"
                      :resetSelected="doesFormReset"
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4"
                      >Contract Expire within</label
                    >
                  </mdb-col>
                  <mdb-col md="4">
                    <mdb-input
                      type="number"
                      v-model="serachFormData.contract_expire"
                      outline
                    />
                  </mdb-col>
                  <mdb-col md="4">
                    <EmployeeSearchPeriodDropdown
                      @updatePeriodValue="setContractExpirePeriod"
                      :default_selected_value="
                        serachFormData.contract_expire_dmy
                      "
                      :required="false"
                      :resetSelected="doesFormReset"
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">Service</label>
                  </mdb-col>
                  <mdb-col md="8">
                    <mdb-row>
                      <mdb-col md="6">
                        <EmployeeSearchPeriodDropdown
                          @updatePeriodValue="setServicePeriod"
                          :default_selected_value="serachFormData.service_dmy"
                          :required="false"
                          :resetSelected="doesFormReset"
                        />
                      </mdb-col>
                      <mdb-col md="6">
                        <EmployeeSearchOperatorDropdown
                          @updateOperatorValue="setServiceOperator"
                          :default_selected_value="
                            serachFormData.service_status
                          "
                          :required="false"
                          :resetSelected="doesFormReset"
                        />
                      </mdb-col>
                      <mdb-col md="6">
                        <mdb-input
                          type="text"
                          v-model="serachFormData.service_txt1"
                          label="From"
                          outline
                        />
                      </mdb-col>
                      <mdb-col md="6">
                        <mdb-input
                          type="text"
                          v-model="serachFormData.service_txt2"
                          label="To"
                          outline
                          :disabled="serachFormData.service_status != 'between'"
                        />
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">Employee Status</label>
                  </mdb-col>
                  <mdb-col md="8" class="mt-4">
                    <mdb-form-inline>
                      <mdb-input
                        type="radio"
                        id="employee_status-active"
                        name="employee_status"
                        radioValue="active"
                        v-model="serachFormData.employee_status"
                        label="Active"
                      />
                      <mdb-input
                        type="radio"
                        id="employee_status-inactive"
                        name="employee_status"
                        radioValue="inactive"
                        v-model="serachFormData.employee_status"
                        label="Inactive"
                      />

                      <mdb-input
                        type="radio"
                        id="employee_status-all"
                        name="employee_status"
                        radioValue=""
                        v-model="serachFormData.employee_status"
                        label="All"
                      />
                    </mdb-form-inline>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">Employee Type</label>
                  </mdb-col>
                  <mdb-col md="8" class="mt-4">
                    <mdb-form-inline>
                      <mdb-input
                        type="radio"
                        id="employee_type-permanent"
                        name="employee_type"
                        radioValue="permanent"
                        v-model="serachFormData.employee_type"
                        label="Permanent"
                      />
                      <mdb-input
                        type="radio"
                        id="employee_type-contract"
                        name="employee_type"
                        radioValue="contract"
                        v-model="serachFormData.employee_type"
                        label="Contract"
                      />
                    </mdb-form-inline>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">Gender</label>
                  </mdb-col>
                  <mdb-col md="8" class="mt-4">
                    <mdb-form-inline>
                      <mdb-input
                        type="radio"
                        id="employee_gender-male"
                        name="employee_gender"
                        radioValue="male"
                        v-model="serachFormData.employee_gender"
                        label="Male"
                      />
                      <mdb-input
                        type="radio"
                        id="employee_gender-female"
                        name="employee_gender"
                        radioValue="female"
                        v-model="serachFormData.employee_gender"
                        label="Female"
                      />
                       <mdb-input
                        type="radio"
                        id="employee_gender-all"
                        name="employee_gender"
                        radioValue="all"
                        v-model="serachFormData.employee_gender"
                        label="All"
                      />
                    </mdb-form-inline>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">Rank</label>
                  </mdb-col>
                  <mdb-col md="8">
                    <RankMultiSelectDropdown
                      @updateSelectedRankIDs="setSelectedRankIds"
                      :default_selected_value="serachFormData.employee_grades"
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">Employee Age</label>
                  </mdb-col>
                  <mdb-col md="8">
                    <mdb-row>
                      <mdb-col md="6">
                        <EmployeeSearchOperatorDropdown
                          @updateOperatorValue="setAgeOperator"
                          :default_selected_value="
                            serachFormData.employee_age_status
                          "
                          :required="false"
                          :resetSelected="doesFormReset"
                        />
                      </mdb-col>
                      <mdb-col md="3">
                        <mdb-input
                          type="text"
                          v-model="serachFormData.employee_age_txt1"
                          label="From"
                          outline
                        />
                      </mdb-col>
                      <mdb-col md="3">
                        <mdb-input
                          type="text"
                          v-model="serachFormData.employee_age_txt2"
                          outline
                          label="To"
                          :disabled="
                            serachFormData.employee_age_status != 'between'
                          "
                        />
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">Date of Birth</label>
                  </mdb-col>
                  <mdb-col md="8">
                    <mdb-row>
                      <mdb-col md="6">
                        <EmployeeSearchOperatorDropdown
                          @updateOperatorValue="setDOBOperator"
                          :default_selected_value="serachFormData.dob_status"
                          :required="false"
                          :resetSelected="doesFormReset"
                        />
                      </mdb-col>
                      <mdb-col md="6">
                        <mdb-date-picker-2
                          v-model="serachFormData.dob_from_date"
                          label="Select date"
                        />
                      </mdb-col>
                      <mdb-col md="6">
                        <mdb-date-picker-2
                          v-model="serachFormData.dob_to_date"
                          label="Select To date"
                          v-show="serachFormData.dob_status == 'between'"
                        />
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">Contract Status</label>
                  </mdb-col>
                  <mdb-col md="8">
                    <mdb-row>
                      <mdb-col md="6">
                        <ContractStatusDropdown
                          @updateContractStatusID="setContractStatusID"
                          :required="false"
                          :resetSelected="doesFormReset"
                        />
                      </mdb-col>
                      <mdb-col md="6">
                        <EmployeeSearchOperatorDropdown
                          @updateOperatorValue="setTerminationOperator"
                          :default_selected_value="
                            serachFormData.termination_date_status
                          "
                          :required="false"
                          :resetSelected="doesFormReset"
                        />
                      </mdb-col>
                      <mdb-col md="6">
                        <mdb-date-picker-2
                          v-model="serachFormData.termination_from_date"
                          label="Select date"
                        />
                      </mdb-col>
                      <mdb-col md="6">
                        <mdb-date-picker-2
                          v-model="serachFormData.termination_to_date"
                          label="Select To date"
                          v-show="
                            serachFormData.termination_date_status == 'between'
                          "
                        />
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">Action Count</label>
                  </mdb-col>
                  <mdb-col md="8">
                    <mdb-row>
                      <mdb-col md="6">
                        <EmployeeSearchOperatorDropdown
                          @updateOperatorValue="setActionCountOperator"
                          :default_selected_value="
                            serachFormData.action_count_status
                          "
                          :required="false"
                          :resetSelected="doesFormReset"
                        />
                      </mdb-col>
                      <mdb-col md="3">
                        <mdb-input
                          type="text"
                          v-model="serachFormData.action_count_txt1"
                          label="From"
                          outline
                        />
                      </mdb-col>
                      <mdb-col md="3">
                        <mdb-input
                          type="text"
                          v-model="serachFormData.action_count_txt2"
                          outline
                          label="To"
                          :disabled="
                            serachFormData.action_count_status != 'between'
                          "
                        />
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">Action Year</label>
                  </mdb-col>
                  <mdb-col md="4">
                    <mdb-select
                      outline
                      search
                      v-model.trim="OptionYear"
                      :label="$t('holidays.formInput.select_year')"
                      placeholder=""
                      @getValue="updateYearValue"
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">SSB Amount</label>
                  </mdb-col>
                  <mdb-col md="8">
                    <mdb-row>
                      <mdb-col md="6">
                        <EmployeeSearchOperatorDropdown
                          @updateOperatorValue="setSSBAmountOperator"
                          :default_selected_value="serachFormData.ssb_status"
                          :required="false"
                          :resetSelected="doesFormReset"
                        />
                      </mdb-col>
                      <mdb-col md="3">
                        <mdb-input
                          type="text"
                          v-model="serachFormData.ssb_txt1"
                          label="From"
                          outline
                        />
                      </mdb-col>
                      <mdb-col md="3">
                        <mdb-input
                          type="text"
                          v-model="serachFormData.ssb_txt2"
                          outline
                          label="To"
                          :disabled="serachFormData.ssb_status != 'between'"
                        />
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">Income Tax</label>
                  </mdb-col>
                  <mdb-col md="8">
                    <mdb-row>
                      <mdb-col md="6">
                        <EmployeeSearchOperatorDropdown
                          @updateOperatorValue="setTaxOperator"
                          :default_selected_value="serachFormData.tax_status"
                          :required="false"
                          :resetSelected="doesFormReset"
                        />
                      </mdb-col>
                      <mdb-col md="3">
                        <mdb-input
                          type="text"
                          v-model="serachFormData.tax_txt1"
                          label="From"
                          outline
                        />
                      </mdb-col>
                      <mdb-col md="3">
                        <mdb-input
                          type="text"
                          v-model="serachFormData.tax_txt2"
                          outline
                          label="To"
                          :disabled="serachFormData.tax_status != 'between'"
                        />
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4"
                      >Staff Movement Times</label
                    >
                  </mdb-col>
                  <mdb-col md="8">
                    <mdb-row>
                      <mdb-col md="6">
                        <EmployeeSearchOperatorDropdown
                          @updateOperatorValue="setMovementTimesOperator"
                          :default_selected_value="
                            serachFormData.movement_times_status
                          "
                          :required="false"
                          :resetSelected="doesFormReset"
                        />
                      </mdb-col>
                      <mdb-col md="3">
                        <mdb-input
                          type="text"
                          v-model="serachFormData.movement_times_txt1"
                          label="From"
                          outline
                        />
                      </mdb-col>
                      <mdb-col md="3">
                        <mdb-input
                          type="text"
                          v-model="serachFormData.movement_times_txt2"
                          outline
                          label="To"
                          :disabled="
                            serachFormData.movement_times_status != 'between'
                          "
                        />
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4">No Of Child</label>
                  </mdb-col>
                  <mdb-col md="8">
                    <mdb-row>
                      <mdb-col md="6">
                        <EmployeeSearchOperatorDropdown
                          @updateOperatorValue="setNoOfChildOperator"
                          :default_selected_value="
                            serachFormData.no_child_status
                          "
                          :required="false"
                          :resetSelected="doesFormReset"
                        />
                      </mdb-col>
                      <mdb-col md="3">
                        <mdb-input
                          type="text"
                          v-model="serachFormData.no_child_txt1"
                          label="From"
                          outline
                        />
                      </mdb-col>
                      <mdb-col md="3">
                        <mdb-input
                          type="text"
                          v-model="serachFormData.no_child_txt2"
                          outline
                          label="To"
                          :disabled="
                            serachFormData.no_child_status != 'between'
                          "
                        />
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4"
                      >Training Name</label
                    ></mdb-col
                  >
                  <mdb-col md="8">
                    <mdb-input
                          type="text"
                          v-model="serachFormData.traning_name"
                          outline
                          label=""
                        />
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col md="4">
                    <label class="col-form-label mt-4"
                      >Training Date</label
                    >
                  </mdb-col>
                  <mdb-col md="8">
                    <mdb-row>
                      <mdb-col md="6">
                        <EmployeeSearchOperatorDropdown
                          @updateOperatorValue="setTrainingDateOperator"
                          :default_selected_value="
                            serachFormData.training_date_status
                          "
                          :required="false"
                          :resetSelected="doesFormReset"
                        />
                      </mdb-col>
                      <mdb-col md="6">
                        <mdb-date-picker-2
                          v-model="serachFormData.training_from_date"
                          label="Select date"
                        />
                      </mdb-col>
                      <mdb-col md="6">
                        <mdb-date-picker-2
                          v-model="serachFormData.training_to_date"
                          label="Select To date"
                          v-show="
                            serachFormData.training_date_status == 'between'
                          "
                        />
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>

                <mdb-btn
                  type="button"
                  outline="default"
                  @click="redirectBackAction"
                >
                  <mdb-icon icon="arrow-left" size="lg" />
                  {{ $t("general.button.go_back") }}
                </mdb-btn>

                <mdb-btn
                  type="Submit"
                  outline="primary"
                  :disabled="formSubmitting"
                >
                  <mdb-icon
                    icon="file-upload"
                    size="lg"
                    v-show="!formSubmitting"
                  />

                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="formSubmitting"
                  ></span>
                  {{ $t("general.button.submit") }}
                </mdb-btn>
              </mdb-col>
            </mdb-row>
          </form>
        </mdb-card-body>
      </mdb-card>
    </template>

    
  </div>
</template>
<script>
import PositionDropdown from "@/components/PositionDropdown";
import DepartmentDropdown from "@/components/DepartmentDropdown";
import EmployeeGroupDropdown from "@/components/EmployeeGroupDropdown";
import RankMultiSelectDropdown from "@/components/RankMultiSelectDropdown";
import EmployeeSearchOperatorDropdown from "@/components/EmployeeSearchOperatorDropdown";
import EmployeeSearchPeriodDropdown from "@/components/EmployeeSearchPeriodDropdown";
import ContractStatusDropdown from "@/components/ContractStatusDropdown";
import { yearOption } from "@/constants/employee.js";
import {
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbBtn,
  mdbIcon,
  mdbFormInline,
  mdbDatePicker2,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbBtn,
    mdbIcon,
    mdbFormInline,
    mdbDatePicker2,
    PositionDropdown,
    DepartmentDropdown,
    EmployeeGroupDropdown,
    RankMultiSelectDropdown,
    EmployeeSearchOperatorDropdown,
    EmployeeSearchPeriodDropdown,
    ContractStatusDropdown,
  },
  props: ["_id"],
  data() {
    return {
      serachFormData: {
        keywords: "",
        jp_id: "",
        department_id: "",
        contract_expire: "",
        contract_expire_dmy: "",
        service_dmy: "",
        service_status: "",
        service_txt1: "",
        service_txt2: "",
        employee_status: "",
        employee_type: "",
        employee_gender: "",
        employee_grades: "",
        employee_age_status: "",
        employee_age_txt1: "",
        employee_age_txt2: "",
        dob_status: "",
        dob_from_date: "",
        dob_to_date: "",
        termination_date_status: "",
        termination_from_date: "",
        termination_to_date: "",
        resign_date_status: "",
        resign_from_date: "",
        resign_to_date: "",
        dismissal_date_status: "",
        dismissal_from_date: "",
        dismissal_to_date: "",
        retrenchment_date_status: "",
        retrenchment_from_date: "",
        retrenchment_to_date: "",
        action_count_status: "",
        action_count_txt1: "",
        action_count_txt2: "",
        action_year: "",
        movement_times_status: "",
        movement_times_txt1: "",
        movement_times_txt2: "",
        no_child_status: "",
        no_child_txt1: "",
        no_child_txt2: "",
        training_name: "",
        training_from_date: "",
        training_to_date: "",

        employee_contract_status:"",
        training_date_status:"",
        ssb_status:"",
        ssb_txt1:"",
        ssb_txt2:"",
        tax_status:"",
        tax_txt1:"",
        tax_txt2:"",
      },
      OptionYear: yearOption(true, ""),
      formSubmitting: false,
      feedback_error: false,
      doesFormReset: false,
      feedback_message: "",
    };
  },

  methods: {
    setDepartmentID(val) {
      this.serachFormData.department_id = val;
    },
    setPositionID(val) {
      this.serachFormData.jp_id = val;
    },
    setEmployeeGroupID(val) {
      this.serachFormData.employee_group = val;
    },
    setSelectedRankIds(val) {
      this.serachFormData.employee_grades = val;
    },
    setServiceOperator(val) {
      this.serachFormData.service_status = val;
    },
    setContractExpirePeriod(val) {
      this.serachFormData.contract_expire_dmy = val;
    },
    setServicePeriod(val) {
      this.serachFormData.service_dmy = val;
    },
    setDOBOperator(val) {
      this.serachFormData.dob_status = val;
    },
    setAgeOperator(val){
      this.serachFormData.employee_age_status = val;
    },
    setTerminationOperator(val) {
      this.serachFormData.termination_date_status = val;
    },
    setContractStatusID(val, is_active) {
      this.serachFormData.employee_contract_status = val;
      this.serachFormData.is_active_contract = is_active;
    },
    setActionCountOperator(val) {
      this.serachFormData.action_count_status = val;
    },    
    setSSBAmountOperator(val) {
      this.serachFormData.ssb_status = val;
    },    
    setTaxOperator(val) {
      this.serachFormData.tax_status = val;
    },    
    setMovementTimesOperator(val) {
      this.serachFormData.movement_times_status = val;
    },    
    setNoOfChildOperator(val) {
      this.serachFormData.no_child_status = val;
    }, 
    setTrainingDateOperator(val) {
      this.serachFormData.training_date_status = val;
    },

    updateYearValue(val) {
      if (val != null && val != "") {
        this.serachFormData.action_year = val.toString();
      } else {
        this.serachFormData.action_year = "";
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      console.log(this.serachFormData);
      this.formSubmitting = false;
    },
    redirectBackAction() {
      this.$router.push({ name: "listEmployee" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.serachFormData).forEach((key) => {
        this.serachFormData[key] = "";
      });
      this.doesFormReset = true;
    },
  },
};
</script>

<style >
/* .md-form.md-outline {
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.md-form {
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
} */
#employeeAdvSearch input[type="text"]:disabled {
  background-color: #ddd !important;
}
</style>