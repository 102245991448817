<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <form @submit.prevent="handleFilterSubmit">
      <mdb-row>
        <mdb-col md="4" lg="3" class="form-inline-search">
          <mdb-select
            search
            outline
            v-model.trim="yearsOptions"
            :label="$t('holidays.formInput.select_year')"
            placeholder=""
            v-on:getValue="getSelectValue"
          />
        </mdb-col>
        <mdb-col md="4" lg="3" class="mt-4 form-inline-search" >
          <mdb-btn
            type="Search"
            color="uno-color"
            class="btn_submit"
            :disabled="doesFormSubmitted"
            size="sm"
          >
            <mdb-icon
              icon="file-upload"
              size="md"
              v-show="!doesFormSubmitted"
            />

            <span
              class="spinner-border-vcenter spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-show="doesFormSubmitted"
            ></span>
            {{ $t("general.button.submit") }}
          </mdb-btn>
        </mdb-col>
      </mdb-row>
    </form>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <mdb-card>
        <mdb-card-header>
          <div class="float-left"></div>

          <div class="float-right">
            <mdb-btn
              size="sm"
              color="uno-color"
              class="btn_delete"
              v-show="shouldDeleteButtonShow"
              @click.native="showDeleteModal"
              :disabled="deleteSubmitted"
            >
              <mdb-icon
                icon="trash-alt"
                far
                size="md"
                v-show="!deleteSubmitted"
              />

              <span
                class="spinner-border-vcenter spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="deleteSubmitted"
              ></span>

              {{ $t("general.button.delete_selected") }}
            </mdb-btn>
            <mdb-btn size="sm" class="btn_submit" color="uno-color" @click="createNewHoliday">
              <mdb-icon icon="plus" />
              {{ $t("general.button.create") }}
            </mdb-btn>
          </div>
        </mdb-card-header>

        <mdb-card-body>
          <mdb-tbl hover responsive>
            <!-- Table head -->
            <mdb-tbl-head>
              <tr>
                <th width="50">
                  <!-- Material unchecked -->
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="trmc_all"
                      @change="checkAllHolidays"
                      v-model="isCheckAll"
                    />
                    <label class="form-check-label" for="trmc_all"></label>
                  </div>
                </th>
                <th @click="sort('holiday_name')">
                  <i
                    v-show="currentSort === 'holiday_name'"
                    :class="[
                      currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ',
                    ]"
                    aria-hidden="true"
                  ></i>
                  {{ $t("general.formInput.name") }}
                </th>
                <th @click="sort('holiday_start_date')" width="120" class="text-right">
                  <i
                    v-show="currentSort === 'holiday_start_date'"
                    :class="[
                      currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ',
                    ]"
                    aria-hidden="true"
                  ></i>
                  {{ $t("general.formInput.start_date") }}
                </th>
                <th @click="sort('holiday_end_date')" width="120" class="text-right">
                  <i
                    v-show="currentSort === 'holiday_end_date'"
                    :class="[
                      currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ',
                    ]"
                    aria-hidden="true"
                  ></i>
                  {{ $t("general.formInput.end_date") }}
                </th>
              </tr>
            </mdb-tbl-head>
            <!-- Table head -->

            <!-- Table body -->
            <mdb-tbl-body>
              <tr
                scope="row"
                v-for="(holiday, index) in sortedRecordList"
                :key="index"
              >
                <th scope="row">
                  <!-- Material unchecked -->
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      :id="'trmc_' + holiday.holiday_id"
                      :value="holiday.holiday_id"
                      v-model="selectedHolidays"
                      @change="updateCheckall"
                    />
                    <label
                      class="form-check-label"
                      :for="'trmc_' + holiday.holiday_id"
                    ></label>
                  </div>
                </th>
                <td @click="rowClickEvent(holiday.holiday_id)">
                  {{ holiday.holiday_name }}
                </td>
                <td
                  @click="rowClickEvent(holiday.holiday_id)"
                  class="text-right"
                >
                  {{ holiday.holiday_start_date | formatDateString }}
                </td>
                <td
                  @click="rowClickEvent(holiday.holiday_id)"
                  class="text-right"
                >
                  {{ holiday.holiday_end_date | formatDateString }}
                </td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>
        </mdb-card-body>
      </mdb-card>
    </div>

    <ModalDeleteConfimation @comfirmAction="deleteHolidays" />
  </div>
</template>

<script>
import Holiday from "@/apis/Holiday";
import { mapActions, mapGetters } from "vuex";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";

import {
  mdbSelect,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
} from "mdbvue";

const yearOption = (year) => {
  let years = [];
  for (
    let current_year = parseInt(new Date().getFullYear()) +1;
    current_year >= 1900;
    --current_year
  ) {
    if (year == current_year)
      years.push({ text: current_year, value: current_year, selected: true });
    else years.push({ text: current_year, value: current_year });
  }
  return years;
};

export default {
  components: {
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    ModalDeleteConfimation,
  },

  data() {
    return {
      currentSort: "holiday_start_date",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
      holidays: [],
      filter_form: {
        filter_year: "",
      },
      yearsOptions: null,
      formSubmitting: false,
      isCheckAll: false,
      selectedHolidays: [],
      deleteSubmitted: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    sortedRecordList() {
      return this.holidays.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    getHolidays() {
      return this.holidays;
    },
    doesFormSubmitted() {
      return this.formSubmitting;
    },
    doesCheckAll() {
      return this.isCheckAll;
    },
    shouldDeleteButtonShow() {
      return this.selectedHolidays.length > 0 ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    rowClickEvent(id) {
      this.$router.push({ name: "viewHoliday", params: { _id: id } });
    },

    checkAllHolidays() {
      this.selectedHolidays = [];
      if (this.isCheckAll) {
        for (let holiday of this.getHolidays) {
          this.selectedHolidays.push(holiday.holiday_id);
        }
      }
    },
    updateCheckall() {
      if (this.getHolidays.length == this.selectedHolidays.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },

    deleteHolidays() {
      this.$store.state.deleteModalVisible = false;
      this.deleteSubmitted = true;
      if (this.selectedHolidays.length > 0) {
        Holiday.delete_multiple({ holiday_id: this.selectedHolidays }).then(
          (response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });
                this.formSubmitting = true;
                this.fetchHolidayList(this.filter_form.filter_year);
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          }
        );
      }
    },

    createNewHoliday() {
      this.$router.push({ name: "createHoliday" });
    },

    getSelectValue(value) {
      this.filter_form.filter_year = value;
    },

    fetchHolidayList(payload = "") {
      this.updateLoading(true);

      Holiday.all(payload)
        .then((response) => {
          if (response.status === 200) {
            this.holidays = response.data.data;

            this.yearsOptions = yearOption(parseInt(new Date().getFullYear()));
            this.updateLoading(false);
            this.formSubmitting = false;
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
            this.formSubmitting = false;
          } else {
            this.feedback_message = error;
            this.feedback_error = true;

            this.updateLoading(false);
            this.formSubmitting = false;
          }
        });
    },
    handleFilterSubmit() {
      this.formSubmitting = true;
      this.fetchHolidayList(this.filter_form.filter_year);
    },
  },
  created() {
    this.fetchHolidayList();
  },
};
</script>