<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="0" offsetLg="2" lg="8" md="12">
                  <mdb-row class="mb-3">
                    <mdb-col md="12">
                      <label class="form-input-label">{{
                        $t("payrolls.formInput.calculationType")
                      }}</label>
                      <mdb-form-inline>
                        <mdb-input
                          type="radio"
                          id="rq_calculate_type_batch"
                          name="rq_calculate_type"
                          radioValue="batch"
                          v-model="payroll.rq_calculate_type"
                          :label="$t('payrolls.formInput.batch')"
                        />
                        <mdb-input
                          type="radio"
                          id="rq_calculate_type_single"
                          name="rq_calculate_type"
                          radioValue="single"
                          v-model="payroll.rq_calculate_type"
                          :label="$t('payrolls.formInput.single')"
                        />
                      </mdb-form-inline>
                    </mdb-col>
                  </mdb-row>

                  <mdb-row>
                    <mdb-col md="6">
                      <mdb-select
                        search
                        outline
                        v-model.trim="yearsOptions"
                        :label="$t('holidays.formInput.select_year')"
                        placeholder=""
                        v-on:getValue="getSelectValue"
                      />
                    </mdb-col>
                    <mdb-col md="6">
                      <mdb-select
                        outline
                        v-model.trim="monthOptions"
                        :label="$t('holidays.formInput.select_month')"
                        placeholder=""
                        v-on:getValue="getSelectMonthValue"
                      />
                    </mdb-col>
                  </mdb-row>

                  <div v-show="payroll.rq_calculate_type == 'single'">
                    <mdb-row class="mb-3">
                      <mdb-col>
                        <div class="float-left mt-3 sec_title">
                          Select Employee
                        </div>
                        <mdb-btn
                          type="button"
                          outline="primary"
                          class="float-right btn_outline_style"
                          icon="user-friends"
                          @click.native="modalOpen()"
                        >
                          {{ $t("payrolls.button.select_employee") }}
                        </mdb-btn>
                      </mdb-col>
                    </mdb-row>

   

                    <mdb-row class="mb-3">
                      <mdb-col >
                        <div v-if="selectedEmployeeData.length > 0">
                          <mdb-row>
                            <mdb-col
                              class="mb-3"
                              xl="6"
                              col="12"
                              v-for="(request_from_item, counter) in selectedEmployeeData"
                              v-bind:key="counter"
                              waves
                            >
                              <div class="approval_info_box">
                                <EmployeeInfoBoxLayout
                                  :user="request_from_item"
                                  :showDelete="true"
                                  @deleteEmployee="
                                    deleteFromEmployee(request_from_item)
                                  "
                                />
                              </div>
                            </mdb-col>
                          </mdb-row>
                        </div>
                      </mdb-col>
                    </mdb-row>
                  </div>

                  <div v-show="payroll.rq_calculate_type == 'batch'">
                    <mdb-row>
                      <mdb-col>
                        <DepartmentDropdown
                          @updateDepartmentID="setDepartmentID"
                          :default_selected_value="payroll.rq_department_id"
                          :required="false"
                          :resetSelected="doesFormReset"
                        />
                      </mdb-col>
                    </mdb-row>
                    <mdb-row>
                      <mdb-col>
                        <RankMultiSelectDropdown
                          @updateSelectedRankIDs="setSelectedRankIds"
                          :default_selected_value="payroll.rq_rank_types"
                          :resetSelected="doesFormReset"
                        />
                      </mdb-col>
                    </mdb-row>
                  </div>

                  <mdb-row>
                    <mdb-col>
                      <mdb-btn
                        type="Submit"
                        color="uno-color"
                        class="btn_submit"
                        :disabled="formSubmitting"
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                          v-show="formSubmitting"
                        ></span>
                        {{ $t("general.button.submit") }}
                      </mdb-btn>
                    </mdb-col>
                  </mdb-row>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>


    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapCheckboxTable
          @updateSelectedEmployeeIDs="setSelectedEmployeeIDs"
          :default_selected_value="source_default_selected_value"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>

  </div>
</template>
<script>
import Payroll from "@/apis/Payroll";
import EmployeeListBoostrapCheckboxTable from "@/components/EmployeeListBoostrapCheckboxTable";
import RankMultiSelectDropdown from "@/components/RankMultiSelectDropdown";

import DepartmentDropdown from "@/components/DepartmentDropdown";
import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  // mdbIcon,
  mdbFormInline,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbSelect,
} from "mdbvue";

const yearOption = (current, year) => {
  let years = [];
  for (let current_year = current; current_year >= 1900; --current_year) {
    if (year == current_year)
      years.push({ text: current_year, value: current_year, selected: true });
    else years.push({ text: current_year, value: current_year });
  }
  return years;
};

const month_name = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthOption = (month) => {
  let months = [];
  for (let i = 0; i < month_name.length; i++) {
    if (month == month_name[i])
      months.push({
        text: month_name[i],
        value: month_name[i],
        selected: true,
      });
    else months.push({ text: month_name[i], value: month_name[i] });
  }
  return months;
};

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    // mdbIcon,
    mdbFormInline,

    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeListBoostrapCheckboxTable,
    EmployeeInfoBoxLayout,
    RankMultiSelectDropdown,
    DepartmentDropdown,
    mdbSelect,
  },
  data() {
    return {
      payroll: {
        rq_calculate_type: "batch",
        rq_department_id: "",
        rq_rank_types: [],
        rq_payroll_user_id: "",
        rq_payroll_date: "",
      },

      filter_year: "",
      filter_month: "",
      selectedEmployeeData: [],
      yearsOptions: null,
      monthOptions: null,
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      modal: false,
      doesFormReset: false,
      
      source_default_selected_value: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    setDepartmentID(val) {
      this.payroll.rq_department_id = val;
    },

    setSelectedRankIds(val) {
      this.payroll.rq_rank_types = val;
    },

    getSelectValue(value) {
      this.filter_year = value;
    },
    getSelectMonthValue(value) {
      this.filter_month = value;
    },

    setSelectedEmployeeIDs(val) {
      this.selectedEmployeeData = val;
    },

    deleteFromEmployee(emp) {
      this.selectedEmployeeData = this.selectedEmployeeData.filter((val) => {
        return val !== emp;
      });
    },

    modalOpen() {
      this.modal = true;

        this.source_default_selected_value = this.selectedEmployeeData.map((e) => {
          return e.employee_id;
        });
       
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      if (this.filter_year != "" && this.filter_month != "") {
        this.payroll.rq_payroll_date =
          this.filter_month + "-" + this.filter_year;

        if (this.payroll.rq_calculate_type == "batch") {
          if (this.payroll.rq_rank_types.length < 1) {
            this.feedback_message = "Select Rank Type";
            this.feedback_error = true;
          } else {
            delete this.payroll.rq_payroll_user_id;

            if (this.payroll.rq_department_id == "") {
              this.payroll.rq_department_id = 'all';
            }
          }
        } else {
          if (this.selectedEmployeeData.length==0) {
            this.feedback_message = "Select Employee";
            this.feedback_error = true;
          } else {
            delete this.payroll.rq_department_id;
            delete this.payroll.rq_rank_types;
            this.payroll.rq_payroll_user_id = this.selectedEmployeeData.map((e) => {
          return e.employee_id;
        });
          }
        }
      } else {
        this.feedback_message = "Select Month and Year";
        this.feedback_error = true;
      }

      if (this.formSubmitting == true && this.feedback_error == false) {
        Payroll.create(this.payroll).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.formSubmitting = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      } else {
        this.formSubmitting = false;
      }
    },

    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      this.payroll = {
        rq_calculate_type: "batch",
        rq_department_id: "",
        rq_rank_types: [],
        rq_payroll_user_id: "",
        rq_payroll_date: "",
      };

      this.selectedEmployeeData= [];
      this.yearsOptions = yearOption(parseInt(new Date().getFullYear()) +1, "");
      this.monthOptions = monthOption("");
      this.source_default_selected_value = "";

      this.doesFormReset = !this.doesFormReset;
    },
  },
  created() {
    this.yearsOptions = yearOption(parseInt(new Date().getFullYear()) +1, "");
    this.monthOptions = monthOption("");
  },
};
</script>