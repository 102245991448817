<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <template v-if="!employee_id">
                <mdb-row class="mb-3">
                  <mdb-col offsetMd="2" md="8">
                    <div class="float-left mt-3 sec_title">Select Employee</div>
                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="float-right btn_outline_style"
                      icon="user-friends"
                      @click.native="modal = true"
                    >
                      {{ $t("payrolls.button.select_employee") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>

                <mdb-row v-if="selectedEmployeeData" class="mb-5">
                  <mdb-col offsetMd="2" md="8">
                    <div class="approval_info_box">
                      <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
                    </div>
                  </mdb-col>
                </mdb-row>
              </template>

              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <TransactionTypeDropdown
                    @updateTransactionTypeID="setTranscationTypeID"
                    :default_selected_value="
                      workingHistories.ewh_transaction_type
                    "
                    :required="true"
                    :resetSelected="doesFormReset"
                    v-if="!isLoading"
                  />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <CompanyDropdown
                    @updateCompanyID="setCompanyID"
                    :default_selected_value="workingHistories.ewh_company"
                    :required="true"
                    :resetSelected="doesFormReset"
                    v-if="!isLoading"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <CompanyBranchDropdown
                    @updateCompanyBranchID="setCompanyBranchID"
                    :default_selected_value="workingHistories.ewh_branch"
                    :required="true"
                    :resetSelected="doesFormReset"                    
                    :company_id="workingHistories.ewh_company"
                    v-if="!isLoading"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <DepartmentTreeDropdown
                    @updateDepartmentID="setDepartmentID"
                    :default_selected_value="workingHistories.ewh_department"
                    :required="true"
                    v-if="!isLoading"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <PositionDropdown
                    @updatePositionID="setPositionID"
                    :default_selected_value="workingHistories.ewh_position"
                    :required="true"
                    :resetSelected="doesFormReset"
                    v-if="!isLoading"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <EmployeeCategoryDropdown
                    @updateEmployeeCategoryID="setEmployeeCategoryID"
                    :default_selected_value="
                      workingHistories.ewh_employee_categroy
                    "
                    :required="true"
                    :resetSelected="doesFormReset"
                    v-if="!isLoading"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <EmployeeGroupDropdown
                    @updateEmployeeGroupID="setEmployeeGroupID"
                    :default_selected_value="
                      workingHistories.ewh_employee_group
                    "
                    :required="true"
                    :resetSelected="doesFormReset"
                    v-if="!isLoading"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <RankDropdown
                    @updateRankID="setRankID"
                    :default_selected_value="workingHistories.ewh_employee_rank"
                    :required="true"
                    :resetSelected="doesFormReset"
                    v-if="!isLoading"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <PermissionDropdown
                    @updatePermissionID="setPermissionID"
                    :default_selected_value="
                      workingHistories.ewh_employee_permission
                    "
                    :required="true"
                    :resetSelected="doesFormReset"
                    v-if="!isLoading"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="number"
                    :label="$t('working_history.formInput.salary')"
                    v-model="workingHistories.ewh_employee_salary"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="number"
                    :label="$t('working_history.formInput.probation_salary')"
                    v-model="workingHistories.ewh_employee_probation_salary"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    v-model="workingHistories.ewh_employee_start_date"
                    :label="$t('general.formInput.start_date')"
                    title="Select date"
                    required
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-date-picker-2
                    v-model="workingHistories.ewh_employee_end_date"
                    :label="$t('general.formInput.end_date')"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    :label="$t('general.formInput.remark')"
                    v-model="workingHistories.ewh_employee_remark"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />

    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapSingleSelectTable
          @updateSelectedEmployee="setSelectedEmployee"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import EmployeeWorkingHistory from "@/apis/EmployeeWorkingHistory";
import CompanyDropdown from "@/components/CompanyDropdown";
import CompanyBranchDropdown from "@/components/CompanyBranchDropdown";
import DepartmentTreeDropdown from "@/components/DepartmentTreeDropdown";
import PositionDropdown from "@/components/PositionDropdown";
import TransactionTypeDropdown from "@/components/TransactionTypeDropdown";
import EmployeeGroupDropdown from "@/components/EmployeeGroupDropdown";
import EmployeeCategoryDropdown from "@/components/EmployeeCategoryDropdown";
import RankDropdown from "@/components/RankDropdown";
import PermissionDropdown from "@/components/PermissionDropdown";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";

import EmployeeListBoostrapSingleSelectTable from "@/components/EmployeeListBoostrapSingleSelectTable";
import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  // mdbSelect,
  //   mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbDatePicker2,
    // mdbSelect,
    // mdbFormInline,
    ModalDeleteConfimation,
    CompanyDropdown,
    CompanyBranchDropdown,
    DepartmentTreeDropdown,
    PositionDropdown,
    TransactionTypeDropdown,
    EmployeeGroupDropdown,
    EmployeeCategoryDropdown,
    RankDropdown,
    PermissionDropdown,

    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeListBoostrapSingleSelectTable,
    EmployeeInfoBoxLayout
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      workingHistories: {
        ewh_id: "",
        ewh_employee_id: this.employee_id ? this.employee_id : "",
        ewh_transaction_type: "",
        ewh_company: "",
        ewh_branch: "",
        ewh_department: "",
        ewh_position: "",
        ewh_employee_permission: "",
        ewh_employee_categroy: "",
        ewh_employee_group: "",
        ewh_employee_rank: "",
        ewh_employee_salary: "",
        ewh_employee_probation_salary: "",
        ewh_employee_start_date: "",
        ewh_employee_end_date: "",
        ewh_employee_remark: "",
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,

      selectedEmployeeData: null,
      modal: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.workingHistories.ewh_id != "" ? true : false;
    },
  },

  methods: {
    setSelectedEmployee(val) {
      this.modal = false;
      this.selectedEmployeeData = val;
      this.workingHistories.ewh_employee_id = val.employee_id;
    },

    setCompanyID(val) {
      this.workingHistories.ewh_company = val;
    },
    setCompanyBranchID(val) {
      this.workingHistories.ewh_branch = val;
    },
    setDepartmentID(val) {
      this.workingHistories.ewh_department = val;
    },
    setPositionID(val) {
      this.workingHistories.ewh_position = val;
    },
    setTranscationTypeID(val) {
      this.workingHistories.ewh_transaction_type = val;
    },
    setEmployeeGroupID(val) {
      this.workingHistories.ewh_employee_group = val;
    },
    setEmployeeCategoryID(val) {
      this.workingHistories.ewh_employee_categroy = val;
    },
    setRankID(val) {
      this.workingHistories.ewh_employee_rank = val;
    },
    setPermissionID(val) {
      this.workingHistories.ewh_employee_permission = val;
    },
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          EmployeeWorkingHistory.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  //   console.log(response.data)

                  let ewh_id,
                    ewh_employee_id,
                    ewh_transaction_type,
                    ewh_company,
                    ewh_branch,
                    ewh_department,
                    ewh_position,
                    ewh_employee_permission,
                    ewh_employee_categroy,
                    ewh_employee_group,
                    ewh_employee_rank,
                    ewh_employee_salary,
                    ewh_employee_probation_salary,
                    ewh_employee_start_date,
                    ewh_employee_end_date,
                    ewh_employee_remark;
                  // populate field
                  ({
                    ewh_id,
                    ewh_employee_id,
                    ewh_transaction_type,
                    ewh_company,
                    ewh_branch,
                    ewh_department,
                    ewh_position,
                    ewh_employee_permission,
                    ewh_employee_categroy,
                    ewh_employee_group,
                    ewh_employee_rank,
                    ewh_employee_salary,
                    ewh_employee_probation_salary,
                    ewh_employee_start_date,
                    ewh_employee_end_date,
                    ewh_employee_remark,
                  } = response.data.data);
                  this.workingHistories.ewh_id = ewh_id;
                  this.workingHistories.ewh_employee_id = ewh_employee_id;
                  this.workingHistories.ewh_transaction_type =
                    ewh_transaction_type;
                  this.workingHistories.ewh_company = ewh_company;
                  this.workingHistories.ewh_branch = ewh_branch;
                  this.workingHistories.ewh_department = ewh_department;
                  this.workingHistories.ewh_position = ewh_position;
                  this.workingHistories.ewh_employee_permission =
                    parseInt(ewh_employee_permission);
                  this.workingHistories.ewh_employee_categroy =
                    ewh_employee_categroy;
                  this.workingHistories.ewh_employee_group = ewh_employee_group;
                  this.workingHistories.ewh_employee_rank = ewh_employee_rank;
                  this.workingHistories.ewh_employee_salary =
                    ewh_employee_salary;
                  this.workingHistories.ewh_employee_probation_salary =
                    ewh_employee_probation_salary;
                  this.workingHistories.ewh_employee_start_date =
                    (ewh_employee_start_date!='0000-00-00' ? ewh_employee_start_date : '');
                  this.workingHistories.ewh_employee_end_date =
                    (ewh_employee_end_date!='0000-00-00' ? ewh_employee_end_date : '');
                  this.workingHistories.ewh_employee_remark =
                    ewh_employee_remark;

                  if (!this.employee_id) {
                    this.selectedEmployeeData = {
                      epinfo_name: response.data.data.epinfo_name,
                      employee_code: response.data.data.employee_code,
                      profile_image: response.data.data.profile_image,
                      department_name: response.data.data.department_name,
                      jp_name: response.data.data.jp_name,
                      rt_name: response.data.data.rt_name,
                    };
                    //emp info
                  }

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    workingHistoryList: {
                      _id: this.workingHistories.ewh_employee_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "workingHistoryList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "workingHistoryList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "workingHistoryList" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      console.log(this.workingHistories);
      this.resetAlert();
      this.formSubmitting = true;
      EmployeeWorkingHistory.save(this.workingHistories).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            if (this.employee_id) {
              this.$router.push({
                name: "workingHistoryList",
                params: { employee_id: this.workingHistories.ewh_employee_id },
              });
            } else {
              this.$router.push({ name: "workingHistoryManageList" });
            }

            // if (this.workingHistories.ewh_id != "") {
            //   this.$notify.success({
            //     message: response.data.message,
            //     position: "top right",
            //     timeOut: 5000,
            //   });
            //   this.$router.push({
            //     name: "workingHistoryList",
            //     params: {
            //       employee_id: this.workingHistories.ewh_employee_id,
            //     },
            //   });
            // } else {
            //   this.$notify.success({
            //     message: response.data.message,
            //     position: "top right",
            //     timeOut: 5000,
            //   });
            //   this.resetForm();
            // }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      if (this.employee_id) {
        this.$router.push({
          name: "workingHistoryList",
          params: { employee_id: this.workingHistories.ewh_employee_id },
        });
      } else {
        this.$router.push({ name: "workingHistoryManageList" });
      }

      // this.$router.push({
      //   name: "workingHistoryList",
      //   params: { employee_id: this.workingHistories.ewh_employee_id },
      // });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    // resetForm() {
    //   Object.keys(this.workingHistories).forEach((key) => {
    //     this.workingHistories[key] = "";
    //   });
    //   this.workingHistories.ewh_employee_id = this.employee_id;
    //   this.doesFormReset = !this.doesFormReset;
    // },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.workingHistories.ewh_id > 0) {
        EmployeeWorkingHistory.delete({
          ewh_id: this.workingHistories.ewh_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              // this.$router.push({
              //   name: "workingHistoryList",
              //   params: {
              //     _id: this.workingHistories.ewh_employee_id,
              //   },
              // });

              if (this.employee_id) {
                this.$router.push({
                  name: "workingHistoryList",
                  params: {
                    employee_id: this.workingHistories.ewh_employee_id,
                  },
                });
              } else {
                this.$router.push({ name: "workingHistoryManageList" });
              }
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      workingHistoryList: {
        employee_id: this.workingHistories.ewh_employee_id,
      },
    };
    this.fetchRecord();
  },
};
</script>