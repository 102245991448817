var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.feedback_error)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[(typeof _vm.feedback_message === 'string')?_c('div',[_vm._v(" "+_vm._s(_vm.feedback_message)+" ")]):_vm._l((_vm.feedback_message),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error.message)+" ")])})],2):_vm._e(),[_c('div',{staticClass:"d-flex justify-content-center"},[_c('mdb-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"big":"","color":"blue"}})],1),(!_vm.isLoading)?_c('div',[_c('mdb-card',[_c('mdb-card-body',[_c('mdb-tabs',{staticClass:"mb-5",attrs:{"active":0,"tabs":"","card":"","color":"primary","justify":"","links":[
              { text: 'Attendance' },
              { text: 'Leave' },
              { text: 'Overtime' } ]}},[_c('template',{slot:'Attendance'},[(_vm.approval_list.attendance != '')?_c('div',[(_vm.approval_list.attendance.approvals.length > 0)?[_c('mdb-row',[_c('mdb-col',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('mdb-btn',{staticClass:"btn_submit mt-2 mb-4",attrs:{"type":"button","rounded":"","size":"sm","color":"uno-color","disabled":_vm.temp_selected_attendance.length > 0 &&
                            _vm.temp_selected_attendance.length ==
                              _vm.approval_list.attendance.approvals.length},on:{"click":function($event){return _vm.selectAll('attendance')}}},[_c('mdb-icon',{attrs:{"icon":"check-square","size":"md"}}),_vm._v(" Select All ")],1),_c('mdb-btn',{staticClass:"btn_submit mt-2 mb-4",attrs:{"type":"button","rounded":"","size":"sm","color":"uno-color","disabled":_vm.temp_selected_attendance.length == 0},on:{"click":function($event){return _vm.unselectAll('attendance')}}},[_c('mdb-icon',{attrs:{"far":"","icon":"check-square","size":"md"}}),_vm._v(" Unselect All ")],1),_c('mdb-btn',{staticClass:"btn_sm_delete mt-2 mb-4",attrs:{"type":"button","rounded":"","size":"sm","color":"uno-color","disabled":_vm.temp_selected_attendance.length == 0},on:{"click":function($event){return _vm.showDeleteModal('attendance')}}},[_c('mdb-icon',{attrs:{"icon":"trash-alt","size":"md"}}),_vm._v(" Delete Selected ")],1)],1)])],1),_c('mdb-row',_vm._l((_vm.approval_list.attendance
                        .approvals),function(approval,index){return _c('mdb-col',{key:index,attrs:{"md":"6"}},[_c('div',{staticClass:"approval_info_box mt-3",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.checkEmployee(
                            'attendance',
                            approval.attendance_approval_id,
                            index
                          )}}},[(approval.profile_image)?_c('img',{staticClass:"rounded-circle float-left",attrs:{"src":approval.profile_image,"width":"50","height":"50"},on:{"error":_vm.imageLoadError}}):_vm._e(),_c('div',{staticClass:"float-left ml-3 mb-3"},[_c('div',{staticClass:"employee_name"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approval.epinfo_name))+" ")]),_c('div',{staticClass:"employee_info_text_sec"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approval.jp_name))+" ")]),_c('div',{staticClass:"employee_info_text"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approval.department_name))+" ")])]),_c('mdb-btn',{staticClass:"float-right close_btn",attrs:{"color":"uno-color"}},[_c('mdb-icon',{staticClass:"uno-text close_btn_color mr-2",class:{ selected_employee: approval.selected },attrs:{"icon":"check-square","size":"2x"}})],1)],1)])}),1)]:[_c('br'),_vm._v("No Approvals"),_c('br'),_c('br'),_c('br'),_c('br')]],2):_vm._e()]),_c('template',{slot:'Leave'},[(_vm.approval_list.leave != '')?_c('div',[(_vm.approval_list.leave.approvals.length > 0)?[_c('mdb-row',[_c('mdb-col',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('mdb-btn',{staticClass:"btn_submit mt-2 mb-4",attrs:{"type":"button","rounded":"","size":"sm","color":"uno-color","disabled":_vm.temp_selected_leave.length > 0 &&
                            _vm.temp_selected_leave.length ==
                              _vm.approval_list.leave.approvals.length},on:{"click":function($event){return _vm.selectAll('leave')}}},[_c('mdb-icon',{attrs:{"icon":"check-square","size":"md"}}),_vm._v(" Select All ")],1),_c('mdb-btn',{staticClass:"btn_submit mt-2 mb-4",attrs:{"type":"button","rounded":"","size":"sm","color":"uno-color","disabled":_vm.temp_selected_leave.length == 0},on:{"click":function($event){return _vm.unselectAll('leave')}}},[_c('mdb-icon',{attrs:{"far":"","icon":"check-square","size":"md"}}),_vm._v(" Unselect All ")],1),_c('mdb-btn',{staticClass:"btn_sm_delete mt-2 mb-4",attrs:{"type":"button","rounded":"","size":"sm","color":"uno-color","disabled":_vm.temp_selected_leave.length == 0},on:{"click":function($event){return _vm.showDeleteModal('leave')}}},[_c('mdb-icon',{attrs:{"icon":"trash-alt","size":"md"}}),_vm._v(" Delete Selected ")],1)],1)])],1),_c('mdb-row',_vm._l((_vm.approval_list.leave
                        .approvals),function(approval,index){return _c('mdb-col',{key:index,attrs:{"md":"6"}},[_c('div',{staticClass:"approval_info_box mt-3",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.checkEmployee(
                            'leave',
                            approval.leave_approval_id,
                            index
                          )}}},[(approval.profile_image)?_c('img',{staticClass:"rounded-circle float-left",attrs:{"src":approval.profile_image,"width":"50","height":"50"},on:{"error":_vm.imageLoadError}}):_vm._e(),_c('div',{staticClass:"float-left ml-3 mb-3"},[_c('div',{staticClass:"employee_name"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approval.epinfo_name))+" ")]),_c('div',{staticClass:"employee_info_text_sec"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approval.jp_name))+" ")]),_c('div',{staticClass:"employee_info_text"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approval.department_name))+" ")])]),_c('mdb-btn',{staticClass:"float-right close_btn",attrs:{"color":"uno-color"}},[_c('mdb-icon',{staticClass:"uno-text close_btn_color mr-2",class:{ selected_employee: approval.selected },attrs:{"icon":"check-square","size":"2x"}})],1)],1)])}),1)]:[_c('br'),_vm._v("No Approvals"),_c('br'),_c('br'),_c('br'),_c('br')]],2):_vm._e()]),_c('template',{slot:'Overtime'},[(_vm.approval_list.overtime != '')?_c('div',[(_vm.approval_list.overtime.approvals.length > 0)?[_c('mdb-row',[_c('mdb-col',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('mdb-btn',{staticClass:"btn_submit mt-2 mb-4",attrs:{"type":"button","rounded":"","size":"sm","color":"uno-color","disabled":_vm.temp_selected_overtime.length > 0 &&
                            _vm.temp_selected_overtime.length ==
                              _vm.approval_list.overtime.approvals.length},on:{"click":function($event){return _vm.selectAll('overtime')}}},[_c('mdb-icon',{attrs:{"icon":"check-square","size":"md"}}),_vm._v(" Select All ")],1),_c('mdb-btn',{staticClass:"btn_submit mt-2 mb-4",attrs:{"type":"button","rounded":"","size":"sm","color":"uno-color","disabled":_vm.temp_selected_overtime.length == 0},on:{"click":function($event){return _vm.unselectAll('overtime')}}},[_c('mdb-icon',{attrs:{"far":"","icon":"check-square","size":"md"}}),_vm._v(" Unselect All ")],1),_c('mdb-btn',{staticClass:"btn_sm_delete mt-2 mb-4",attrs:{"type":"button","rounded":"","size":"sm","color":"uno-color","disabled":_vm.temp_selected_overtime.length == 0},on:{"click":function($event){return _vm.showDeleteModal('overtime')}}},[_c('mdb-icon',{attrs:{"icon":"trash-alt","size":"md"}}),_vm._v(" Delete Selected ")],1)],1)])],1),_c('mdb-row',_vm._l((_vm.approval_list.overtime
                        .approvals),function(approval,index){return _c('mdb-col',{key:index,attrs:{"md":"6"}},[_c('div',{staticClass:"approval_info_box mt-3",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.checkEmployee(
                            'overtime',
                            approval.overtime_approval_id,
                            index
                          )}}},[(approval.profile_image)?_c('img',{staticClass:"rounded-circle float-left",attrs:{"src":approval.profile_image,"width":"50","height":"50"},on:{"error":_vm.imageLoadError}}):_vm._e(),_c('div',{staticClass:"float-left ml-3 mb-3"},[_c('div',{staticClass:"employee_name"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approval.epinfo_name))+" ")]),_c('div',{staticClass:"employee_info_text_sec"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approval.jp_name))+" ")]),_c('div',{staticClass:"employee_info_text"},[_vm._v(" "+_vm._s(_vm._f("cutStringTableCell")(approval.department_name))+" ")])]),_c('mdb-btn',{staticClass:"float-right close_btn",attrs:{"color":"uno-color"}},[_c('mdb-icon',{staticClass:"uno-text close_btn_color mr-2",class:{ selected_employee: approval.selected },attrs:{"icon":"check-square","size":"2x"}})],1)],1)])}),1)]:[_c('br'),_vm._v("No Approvals"),_c('br'),_c('br'),_c('br'),_c('br')]],2):_vm._e()])],2)],1)],1),_c('ModalDeleteConfimation',{on:{"comfirmAction":_vm.handleDeleteRecord}})],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }