<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <form @submit.prevent="handleFilterSubmit">
      <mdb-row>
        <mdb-col md="4" lg="3" class="form-inline-search">
          <mdb-select
            search
            v-model.trim="yearsOptions"
            :label="$t('holidays.formInput.select_year')"
            placeholder=""
            v-on:getValue="getSelectValue"
            outline
          />
        </mdb-col>
        <mdb-col md="4" lg="3" class="form-inline-search">
          <mdb-select
            v-model.trim="monthOptions"
            :label="$t('holidays.formInput.select_month')"
            placeholder=""
            v-on:getValue="getSelectMonthValue"
            outline
          />
        </mdb-col>
        <mdb-col md="4" lg="3" class="mt-4">
          <mdb-btn
            type="Search"
            color="uno-color"
            class="btn_submit"
            :disabled="doesFormSubmitted"
            size="sm"
          >
            <mdb-icon
              icon="file-upload"
              size="md"
              v-show="!doesFormSubmitted"
            />

            <span
              class="spinner-border-vcenter spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-show="doesFormSubmitted"
            ></span>
            {{ $t("general.button.submit") }}
          </mdb-btn>
        </mdb-col>
      </mdb-row>
    </form>

    <template>
      <div class="d-flex justify-content-center">
        <mdb-spinner big color="blue" v-show="isLoading" />
      </div>

      <div v-show="!isLoading">
        <mdb-card>
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn
                color="uno-color"
                class="btn_submit"
                @click="createNewRecord"
                size="sm"
              >
                <mdb-icon far icon="plus-square" size="md" />
                {{ $t("general.button.create") }}
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <mdb-tbl responsive hover>
              <!-- Table head -->
              <mdb-tbl-head>
                <tr>
                  <th>
                    {{ $t("general.label.date") }}
                  </th>
                  <th>
                    {{ $t("general.label.status") }}
                  </th>
                  <th>
                    {{ $t("general.label.approvals") }}
                  </th>
                  <th>
                    {{ $t("general.label.checkin") }}
                  </th>
                  <th>
                    {{ $t("general.label.checkout") }}
                  </th>
                  <th>
                    {{ $t("general.label.overtime") }}
                  </th>
                </tr>
              </mdb-tbl-head>

              <!-- Table body -->
              <mdb-tbl-body>
                <tr
                  scope="row"
                  v-for="(attendance_item, index) in attendances"
                  :key="index"
                  :class="(attendance_item.complete ==true ||attendance_item.status=='absent'|| attendance_item.status=='off'|| attendance_item.status=='open') ? attendance_item.status : 'pending'"
                >
                  <td
                    scope="row"
                    @click="rowClickEvent(attendance_item.attendance_id)"
                  >
                    {{ attendance_item.date | formatDateString }}
                  </td>
                  <td @click="rowClickEvent(attendance_item.attendance_id)">
                    {{ attendance_item.status }}
                    
                  </td>
                  <td @click="rowClickEvent(attendance_item.attendance_id)">
                    {{ attendance_item.attendance_checkin_status }}
                    <template
                      v-if="
                        attendance_item.attendance_checkin_status &&
                        attendance_item.attendance_checkout_status
                      "
                    >
                      /
                    </template>
                    {{ attendance_item.attendance_checkout_status }}
                  </td>
                  <td @click="rowClickEvent(attendance_item.attendance_id)">
                    {{
                      attendance_item.attendance_checkin_time
                        | formatDateTimeString
                    }}
                    <br />
                    {{ attendance_item.attendance_checkin_type }}

                    <div v-if="attendance_item.attendance_checkin_late_mins" class="red-text">
                      {{ attendance_item.attendance_checkin_late_mins }} mins
                    </div>

                  </td>
                  <td @click="rowClickEvent(attendance_item.attendance_id)">
                    {{
                      attendance_item.attendance_checkout_time
                        | formatDateTimeString
                    }}
                    <br />
                    {{ attendance_item.attendance_checkout_type }}
                    
                    <div v-if="attendance_item.attendance_checkout_early_mins" class="red-text">
                      {{ attendance_item.attendance_checkout_early_mins}} mins
                    </div>
                  </td>
                  <td
                    @click="rowClickEvent(attendance_item.attendance_id)"
                  ></td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </mdb-card-body>
        </mdb-card>

        <!-- debug: sort={{currentSort}}, dir={{currentSortDir}}          -->
      </div>
    </template>
  </div>
</template>

<script>
import Attendance from "@/apis/Attendance";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSpinner,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbRow,
  mdbCol,
  mdbSelect,
} from "mdbvue";

const yearOption = (current, year) => {
  let years = [];
  for (let current_year = (parseInt(current)+1); current_year >= 1900; --current_year) {
    if (year == current_year)
      years.push({ text: current_year, value: current_year, selected: true });
    else years.push({ text: current_year, value: current_year });
  }
  return years;
};

const month_name = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthOption = (month) => {
  let months = [];
  for (let i = 0; i < month_name.length; i++) {
    if (month == month_name[i])
      months.push({
        text: month_name[i],
        value: month_name[i],
        selected: true,
      });
    else months.push({ text: month_name[i], value: month_name[i] });
  }
  return months;
};

export default {
  components: {
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSpinner,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbRow,
    mdbCol,
    mdbSelect,
  },
  name: "EmployeeAttendanceList",

  data() {
    return {
      filter_form: {
        filter_year: "",
        filter_month: "",
      },
      yearsOptions: null,
      monthOptions: null,
      formSubmitting: false,

      attendances: [],
      currentSort: "dt_name",
      currentSortDir: "asc",
      feedback_error: false,
      feedback_message: "",
    };
  },
  props: ["employee_id"],
  computed: {
    ...mapGetters(["isLoading"]),
    doesFormSubmitted() {
      return this.formSubmitting;
    },
    sortedDocumentTypeList() {
      return this.action_types.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    rowClickEvent(id) {
      this.$router.push({
        name: "viewEmployeeAttendanceInfo",
        params: { _id: id },
      });
    },
    getSelectValue(value) {
      this.filter_form.filter_year = value;
    },
    getSelectMonthValue(value) {
      this.filter_form.filter_month = value;
    },
    handleFilterSubmit() {
      this.formSubmitting = true;
      if (
        this.filter_form.filter_year != "" &&
        this.filter_form.filter_month != ""
      ) {
        this.fetchRecordList({
          employee_id: this.employee_id,
          filter_month:
            this.filter_form.filter_month + "-" + this.filter_form.filter_year,
        });
      }
    },

    createNewRecord() {
      this.$router.push({ name: "createEmployeeAttendance" });
    },

    fetchRecordList(payload = "") {
      this.updateLoading(true);

      if (payload.employee_id != undefined) {
        if (Math.sign(payload.employee_id) === 1) {
          const params = {
            employee_id: payload.employee_id,
            filter_month: payload.filter_month ? payload.filter_month : "",
          };

          Attendance.employeeAttendance(params)
            .then((response) => {
              if (response.status === 200) {
                this.attendances = response.data.data;

                this.yearsOptions = yearOption(
                  response.data.filter.current_year,
                  response.data.filter.year
                );
                this.monthOptions = monthOption(response.data.filter.month);

                this.updateLoading(false);
                this.formSubmitting = false;
              }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.feedback_message = "Could not connected to server";
                this.feedback_error = true;

                this.updateLoading(false);
              } else {
                console.log(error);
                this.updateLoading(false);
              }
            });
        }
      }
    },
  },
  created() {
    this.fetchRecordList({ employee_id: this.employee_id });
  },
};
</script>
<style>
tr.present{
background: #a5d6a7 ;
}
tr.absent{
  
background: #ff5722 ;
}
tr.off{
 background: #b1bace;
}
tr.pending{
  background: #f5b46a;
}
tr.leave{
background: #fff59d ;
}
</style>