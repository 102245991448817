<template>
  <section class="form-elegant">
    <mdb-row class="d-flex justify-content-center">
      <mdb-col md="6" lg="5">
        <mdb-card>
          <mdb-card-body class="mx-4">
            <form @submit.prevent="validateForm" novalidate>
              <div class="text-center">
                <mdb-card-avatar color="white" class="mx-auto"
                  ><img src="/unohr.png" class="" height="100"
                /></mdb-card-avatar>
              </div>
              <!-- rounded-circle -->
              <div class="text-center">
                <h3 class="dark-grey-text my-4">
                  <strong>Reset Password</strong>
                </h3>
              </div>
              <div class="text-center">
                 <div class="alert alert-danger" role="alert" v-if="ec_data_error">
                  {{ ec_data_error }}
                </div>
                 <div class="alert alert-danger" role="alert" v-if="hasErrorFeedback">
                  <div v-if="typeof hasErrorFeedback === 'string'">
                    {{ hasErrorFeedback }}
                  </div>
                  <div
                    v-else
                    v-for="(error, index) in hasErrorFeedback"
                    :key="index"
                  >
                    {{ error.message }}
                  </div>
                </div>
              </div>
              <mdb-input
                label="New password"
                type="password"
                required
                v-model="password"
                :customValidation="validation.password.validated"
                :isValid="validation.password.valid"
                @change="validate('password')"
                :invalidFeedback="validation.password.invalidFeedback"
                containerClass="mb-0"
                :disabled="shouldDisabled"
              />

              <mdb-input
                label="Confrim password"
                type="password"
                required
                v-model="password_confirm"
                :customValidation="validation.password_confirm.validated"
                :isValid="validation.password_confirm.valid"
                @change="validate('password_confirm')"
                :invalidFeedback="validation.password_confirm.invalidFeedback"
                containerClass="mb-0"
                :disabled="shouldDisabled"
              />

              <div class="text-center mb-3">
                <mdb-btn
                  type="Submit"
                  gradient="blue"
                  rounded
                  class="btn-block z-depth-1a"
                  :disabled="shouldDisabled"
                >
                  <span
                    class="spinner-border-vcenter spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="doesFormSubmitted"
                  ></span>

                  Reset Password
                </mdb-btn>
              </div>
            </form>
          </mdb-card-body>
          <mdb-modal-footer class="mx-5 pt-3 mb-1">
            <p class="font-small grey-text d-flex justify-content-end">
              Powered BY <a href="#" class="blue-text ml-1"> SBG</a>
            </p>
          </mdb-modal-footer>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import User from "../../apis/User";

const re_alphanumeric = /^[a-z0-9]+$/i;

import {
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbBtn,
  mdbModalFooter,
  mdbCardAvatar,
  // mdbAlert,
} from "mdbvue";

export default {
  name: "ResetPassword",
  components: {
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbBtn,
    mdbModalFooter,
    mdbCardAvatar,
    // mdbAlert,
  },
  data() {
    return {
      e_data: "",
      c_data: "",
      password: "",
      password_confirm: "",

      loading_ec_data: true,
      ec_data_error: false,

      formSubmitting: false,
      formErrorFeedback: "",
      formValidation: true,
      validation: {
        password: {
          valid: false,
          validated: false,
        },
        password_confirm: {
          valid: false,
          validated: false,
        },
      },
    };
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  computed: {
    shouldDisabled() {
      if (this.formSubmitting) {
        return this.formSubmitting;
      } else if (
        this.loading_ec_data === false &&
        this.ec_data_error !== false
      ) {
        return true;
      } else {
        return false;
      }
    },
    doesFormSubmitted() {
      return this.formSubmitting;
    },
    hasErrorFeedback() {
      return this.formErrorFeedback;
    },
    ...mapGetters("user", ["loggedIn"]),
  },
  methods: {
    handleResetPasswordSubmit() {
      this.formSubmitting = true;
      User.resetPassword({
        e: this.e_data,
        c: this.c_data,
        o: this.o_data,
        password: this.password,
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.code == 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({
                name: "ResetSuccess",
              });
            } else if (response.data.code == 400) {
              if (response.data.error.length > 0) {
                this.formErrorFeedback = response.data.error;
              }
              this.error = true;
              this.formSubmitting = false;
            } else if (response.data.code == 402) {
              // console.log(error)
              this.formErrorFeedback = response.data.message;
              this.error = true;
              this.formSubmitting = false;
            }
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.formErrorFeedback = "Error: Network Error";
          }

          this.error = true;
          this.formSubmitting = false;
        });
    },
    validateForm() {
      this.formValidation = true;
      this.formErrorFeedback = "";

      this.validate("password");
      this.validate("password_confirm");

      if (this.formValidation === true) {
        this.handleResetPasswordSubmit();
      }
    },
    validate(key) {
      if (key === "password") {
        // check length
        if (this.password.length > 5) {
          this.validation[key].valid = true;
        } else {
          this.validation[key].valid = false;
          this.formValidation = false;
          this.validation[key].invalidFeedback =
            "Password too short. Type at least 6 letters.";
        }
        this.validation[key].validated = true;
      }

      if (key === "password_confirm") {
        // check length
        if (this.password == this.password_confirm) {
          this.validation[key].valid = true;
        } else if (this.password_confirm.length == 0) {
          this.validation[key].valid = false;
          this.formValidation = false;
          this.validation[key].invalidFeedback = "Confirm Password Required.";
        } else {
          this.validation[key].valid = false;
          this.formValidation = false;
          this.validation[key].invalidFeedback = "Password does not match.";
        }
        this.validation[key].validated = true;
      }
    },
  },
  mounted() {
    if (
      this.$route.query &&
      this.$route.query.e &&
      this.$route.query.c &&
      this.$route.query.o &&
      this.$route.query.e != "" &&
      this.$route.query.c != "" &&
      this.$route.query.o != "" &&
      re_alphanumeric.test(String(this.$route.query.e).toLowerCase()) &&
      re_alphanumeric.test(String(this.$route.query.c).toLowerCase()) &&
      re_alphanumeric.test(String(this.$route.query.o).toLowerCase())
    ) {
      this.e_data = this.$route.query.e;
      this.c_data = this.$route.query.c;
      this.o_data = this.$route.query.o;

      User.verify_check({
        e: this.e_data,
        c: this.c_data,
        o: this.o_data
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.code == 200) {
              //   loading done
              this.loading_ec_data = false;
              this.ec_data_error = false;
            } else {
              this.loading_ec_data = false;
              this.ec_data_error = response.data.message;
            }
          }
        })
        .catch((error) => {
          this.loading_ec_data = false;
          if (!error.response) {
            this.ec_data_error = "Error: Network Error";
          } else {
            this.ec_data_error = error;
          }
        });
    } else {
      //error
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>

<style>
.form-elegant .font-small {
  font-size: 0.8rem;
}

.form-elegant .z-depth-1a {
  -webkit-box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26),
    0 4px 12px 0 rgba(121, 155, 254, 0.25);
  box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26),
    0 4px 12px 0 rgba(121, 155, 254, 0.25);
}

.form-elegant .z-depth-1-half,
.form-elegant .btn:hover {
  -webkit-box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28),
    0 4px 15px 0 rgba(36, 133, 255, 0.15);
  box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28),
    0 4px 15px 0 rgba(36, 133, 255, 0.15);
}
</style>