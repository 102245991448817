<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <!-- {{this.announcement}} -->
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="announcement.hr_announcement_title"
                    outline
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    :label="$t('general.formInput.remark')"
                    v-model="announcement.hr_announcement_description"
                    outline
                    required
                  />
                </mdb-col>
              </mdb-row>
              <!-- @click="addNewAttachment" -->
              <mdb-row>
                <mdb-col offsetMd="2" md="8" class="mb-4">
                  <mdb-btn
                    type="button"
                    color="uno-color"
                    class="btn_light_blue"
                    @click.native="showFileUploadModal = true"
                    icon="plus-square"
                    size="md"
                    rounded
                  >
                    Add New Attachment
                  </mdb-btn>
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-row>
                    <mdb-col                      
                      md="6"
                      lg="4"
                      v-for="(
                        attachment, counter
                      ) in announcement.hr_announcement_attachments"
                      v-bind:key="'svr' + counter"
                      class="mb-4"
                    >
                      <!-- {{attachment}} -->
                      <mdb-card cascade class="attachment_card_style">
                        <mdb-view hover cascade>
                          <template>
                            <mdb-card-image
                              v-if="
                                attachment.file_ext == 'png' ||
                                attachment.file_ext == 'gif' ||
                                attachment.file_ext == 'jpg' ||
                                attachment.file_ext == 'jpeg'
                              "
                              :src="attachment.attachment_file_name"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="
                                attachment.file_ext == 'doc' ||
                                attachment.file_ext == 'docx'
                              "
                              src="/doc.png"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="
                                attachment.file_ext == 'xls' ||
                                attachment.file_ext == 'xlsx'
                              "
                              src="/spreadsheet.png"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="
                                attachment.file_ext == 'ppt' ||
                                attachment.file_ext == 'pptx'
                              "
                              src="/powerpoint.png"                              
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="attachment.file_ext == 'pdf'"
                              src="/pdf.png"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else
                              src="/unknowfile.png"
                              class="attachment_img_style"
                            ></mdb-card-image>
                          </template>
                        </mdb-view>
                        <mdb-card-body class="text-center" cascade>
                          {{ attachment.attachment_original_file_name | filename_str_limit}}
                          <mdb-btn
                            color="white"
                            class="attachment_card_del_btn"
                            @click="
                              deleteServerAttachment(attachment.attachment_id)
                            "
                            ><mdb-icon icon="trash-alt" size="md" />
                            {{ $t("general.button.delete") }}</mdb-btn
                          >
                        </mdb-card-body>
                      </mdb-card>
                    </mdb-col>
                  </mdb-row>
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-row>
                    <mdb-col
                      md="6"
                      lg="4"
                      v-for="(attachment, counter) in attachments"
                      v-bind:key="'lcl' + counter"
                      class="mb-4"
                    >
                      <mdb-card cascade class="attachment_card_style">
                        <mdb-view hover cascade>
                          <template v-if="temp_attachments_images[counter]">
                            <mdb-card-image
                              v-if="
                                temp_attachments_images[counter].type ==
                                  'png' ||
                                temp_attachments_images[counter].type ==
                                  'gif' ||
                                temp_attachments_images[counter].type ==
                                  'jpg' ||
                                temp_attachments_images[counter].type == 'jpeg'
                              "
                              :src="temp_attachments_images[counter].url"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="
                                temp_attachments_images[counter].type ==
                                  'doc' ||
                                temp_attachments_images[counter].type == 'docx'
                              "
                              src="/doc.png"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="
                                temp_attachments_images[counter].type ==
                                  'xls' ||
                                temp_attachments_images[counter].type == 'xlsx'
                              "
                              src="/spreadsheet.png"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="
                                temp_attachments_images[counter].type ==
                                  'ppt' ||
                                temp_attachments_images[counter].type == 'pptx'
                              "
                              src="/powerpoint.png"
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else-if="
                                temp_attachments_images[counter].type == 'pdf'
                              "
                              src="/pdf.png"                              
                              class="attachment_img_style"
                            ></mdb-card-image>
                            <mdb-card-image
                              v-else
                              src="/unknowfile.png"                              
                              class="attachment_img_style"
                            ></mdb-card-image>
                          </template>
                        </mdb-view>
                        <mdb-card-body class="text-center" cascade>
                          {{temp_attachments_images[counter].name | filename_str_limit}}
                          <mdb-btn
                            color="white"
                            class="attachment_card_del_btn"
                            @click="deleteAttachment(counter)"
                            ><mdb-icon icon="trash-alt" size="md" />
                            {{ $t("general.button.delete") }}</mdb-btn
                          >
                        </mdb-card-body>
                      </mdb-card>
                    </mdb-col>
                  </mdb-row>
                </mdb-col>
              </mdb-row>
              <mdb-row class="mt-3 mb-3">
                <mdb-col offsetMd="2" md="8">
                  <label>{{
                    $t("announcements.formInput.distribution_type")
                  }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="option-individual"
                      name="option_hr_announcement_type"
                      radioValue="individual"
                      v-model="announcement.hr_announcement_type"
                      :label="$t('announcements.formInput.individual')"
                    />
                    <mdb-input
                      type="radio"
                      id="option-department"
                      name="option_hr_announcement_type"
                      radioValue="department"
                      v-model="announcement.hr_announcement_type"
                      :label="$t('announcements.formInput.department')"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>

              <div
                v-show="this.announcement.hr_announcement_type == 'individual'"
              >
                <mdb-row class="mb-4">
                  <mdb-col offsetMd="2" md="8">
                    <!-- <EmployeeListBoostrapCheckboxTable
                      @updateSelectedEmployeeIDs="setSelectedEmployeeIDs"
                      :default_selected_value="
                        announcement.hr_announcement_employee
                      "
                    /> -->

                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="btn_outline_style mt-2"
                      icon="user-friends"
                      @click.native="modalOpen()"
                    >
                      Select Employee
                    </mdb-btn>
                    {{selectedEmployeeIDs.length}} Employees Selected
                  </mdb-col>
                </mdb-row>
                
              </div>
              <div
                v-show="this.announcement.hr_announcement_type == 'department'"
              >
                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <div>
                      <DepartmentTreeViewCheckbox
                        @updateDepartmentIDsValue="setSelectedDepartmentIDs"
                        :default_selected_value="
                          announcement.hr_announcement_departments
                        "
                        v-if="!isLoading"
                      />
                    </div>
                    <div>
                      <RankMultiSelectDropdown
                        @updateSelectedRankIDs="setSelectedRankIds"
                        :default_selected_value="
                          announcement.hr_announcement_grade
                        "
                        v-if="!isLoading"
                      />
                    </div>
                  </mdb-col>
                </mdb-row>
              </div>

              <mdb-row class="mt-2">
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>


    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapCheckboxTable
          @updateSelectedEmployeeIDs="setSelectedEmployeeIDs"
          :default_selected_value="defaultSelectedEmployeeIDs"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>

    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />

    <mdb-modal :show="showFileUploadModal" @close="showFileUploadModal = false">
      <mdb-modal-header class="text-center">
        <mdb-modal-title tag="h4" bold class="w-100"
          >Select File To Upload</mdb-modal-title
        >
      </mdb-modal-header>
      <mdb-modal-body class="mx-3 grey-text">
        <mdb-file-input @getValue="getFileInputValue" btnColor="light-blue" accept="image/*,application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,
text/plain"/>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn color="primary" @click="handelSelectFileAction"
          >Select File</mdb-btn
        >
        <mdb-btn @click.native="showFileUploadModal = false">Cancel</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <mdb-modal
      size="size"
      :show="showDeleteAttachmentModal"
      @close="showDeleteAttachmentModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          <slot name="header"> Confirmation ! </slot>
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <slot name="body">
          Are you sure to delete this attachment ? This action can not be undo
          after confirmation.
        </slot>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="secondary"
          size="sm"
          @click.native="showDeleteAttachmentModal = false"
          >No</mdb-btn
        >
        <mdb-btn color="primary" @click="handleDeleteServerAttachment" size="sm"
          >Yes</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import Announcement from "@/apis/Announcement";
import LogList from "@/components/logs/List";
import DepartmentTreeViewCheckbox from "@/components/DepartmentTreeViewCheckbox";
import RankMultiSelectDropdown from "@/components/RankMultiSelectDropdown";
import EmployeeListBoostrapCheckboxTable from "@/components/EmployeeListBoostrapCheckboxTable";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFormInline,
  mdbSpinner,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbFileInput,
  mdbView,
  mdbCardImage,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbFormInline,
    mdbSpinner,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    DepartmentTreeViewCheckbox,
    RankMultiSelectDropdown,
    EmployeeListBoostrapCheckboxTable,
    ModalDeleteConfimation,
    mdbFileInput,
    mdbView,
    mdbCardImage,
  },
  props: ["_id"],
  data() {
    return {
      announcement: {
        hr_announcement_id: "",
        hr_announcement_title: "",
        hr_announcement_description: "",
        hr_announcement_type: "individual",
        hr_announcement_employee: [],
        hr_announcement_departments: [],
        hr_announcement_grade: [],
        hr_announcement_attachments: [],
      },

      logs: [],
      modal: false,
      selectedEmployeeIDs: [],
      defaultSelectedEmployeeIDs: "",
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",

      showFileUploadModal: false,
      temp_file_val: {},
      attachments: [],
      temp_attachments_images: [],

      showDeleteAttachmentModal: false,
      temp_delete_attachment_id: "",

      current_distribution_type: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.announcement.hr_announcement_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    setSelectedRankIds(val) {
      this.announcement.hr_announcement_grade = val;
    },
    setSelectedDepartmentIDs(val) {
      this.announcement.hr_announcement_departments = val;
    },
    handelSelectFileAction() {
      this.attachments.push(this.temp_file_val);
      this.temp_attachments_images.push({
        type: this.temp_file_val.name.split(".").pop().toLowerCase(),
        url: URL.createObjectURL(this.temp_file_val),
        name: this.temp_file_val.name
      });
      this.showFileUploadModal = false;
    },

    getFileInputValue(val) {
      // console.log(val[0])
      this.temp_file_val = val[0];
    },

    deleteServerAttachment(attachment_id) {
      this.temp_delete_attachment_id = attachment_id;
      this.showDeleteAttachmentModal = true;
    },

    handleDeleteServerAttachment() {
      if (
        this.temp_delete_attachment_id != "" &&
        this.temp_delete_attachment_id > 0
      ) {
        Announcement.deleteAttachment({
          attachment_id: this.temp_delete_attachment_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              this.announcement.hr_announcement_attachments =
                this.announcement.hr_announcement_attachments.filter((item) => {
                  return item.attachment_id != this.temp_delete_attachment_id;
                });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
      this.showDeleteAttachmentModal = false;
    },

    deleteAttachment(counter) {
      this.attachments.splice(counter, 1);
    },

    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    setSelectedEmployeeIDs(val) {
      this.selectedEmployeeIDs = val;
    },

    modalOpen() {
      this.modal = true;

      this.defaultSelectedEmployeeIDs = this.selectedEmployeeIDs.map((e) => {
        return e.employee_id;
      });
    },

    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          Announcement.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  console.log(response.data);
                  let hr_announcement_id,
                    hr_announcement_title,
                    hr_announcement_description,
                    hr_announcement_type,
                    hr_announcement_employee,
                    hr_announcement_departments,
                    hr_announcement_grade,
                    hr_announcement_attachments;

                  // populate field
                  ({
                    hr_announcement_id,
                    hr_announcement_title,
                    hr_announcement_description,
                    hr_announcement_type,
                    hr_announcement_employee,
                    hr_announcement_departments,
                    hr_announcement_grade,
                    hr_announcement_attachments,
                  } = response.data.data);
                  // console.log(response.data);
                  this.announcement.hr_announcement_id = hr_announcement_id;
                  this.announcement.hr_announcement_title =
                    hr_announcement_title;
                  this.announcement.hr_announcement_description =
                    hr_announcement_description;
                  this.announcement.hr_announcement_type = hr_announcement_type;
                  this.announcement.hr_announcement_attachments =
                    hr_announcement_attachments;

                  this.current_distribution_type = hr_announcement_type;

                  if (hr_announcement_type == "department") {
                    this.announcement.hr_announcement_departments =
                      hr_announcement_departments
                        ? hr_announcement_departments.map((n) => parseInt(n))
                        : [];

                    this.announcement.hr_announcement_grade =
                      hr_announcement_grade
                        ? hr_announcement_grade.map((n) => parseInt(n))
                        : [];

                    this.defaultSelectedEmployeeIDs = [];
                    this.selectedEmployeeIDs = [];
                  } else {
                    this.defaultSelectedEmployeeIDs = hr_announcement_employee
                      ? hr_announcement_employee.map(n=> parseInt(n))
                      : [];

                    // this.selectedEmployeeIDs = hr_announcement_employee.map(
                    //   (e) => {
                    //     return { employee_id: parseInt(e) };
                    //   }
                    // );

                    this.selectedEmployeeIDs = hr_announcement_employee;

                    this.announcement.hr_announcement_departments = [];
                    this.announcement.hr_announcement_grade = [];
                  }

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listAnnouncement" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listAnnouncement" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listAnnouncement" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;

      let formData = new FormData();
      formData.append(
        "hr_announcement_id",
        this.announcement.hr_announcement_id
      );
      formData.append(
        "hr_announcement_title",
        this.announcement.hr_announcement_title
      );
      formData.append(
        "hr_announcement_description",
        this.announcement.hr_announcement_description
      );
      formData.append(
        "hr_announcement_type",
        this.announcement.hr_announcement_type
      );

      if (this.announcement.hr_announcement_type == "individual") {
        this.announcement.hr_announcement_grade = [];
        this.announcement.hr_announcement_departments = [];

        this.announcement.hr_announcement_employee =
          this.selectedEmployeeIDs.map((e) => {
            return e.employee_id;
          });

        if (this.announcement.hr_announcement_employee.length > 0) {
          for (
            let i = 0;
            i < this.announcement.hr_announcement_employee.length;
            i++
          ) {
            formData.append(
              "hr_announcement_employee[]",
              this.announcement.hr_announcement_employee[i]
            );
          }
        }
      } else {
        this.announcement.hr_announcement_employee = [];

        if (this.announcement.hr_announcement_grade.length > 0) {
          for (
            let i = 0;
            i < this.announcement.hr_announcement_grade.length;
            i++
          ) {
            formData.append(
              "hr_announcement_grade[]",
              this.announcement.hr_announcement_grade[i]
            );
          }
        }

        if (this.announcement.hr_announcement_departments.length > 0) {
          for (
            let i = 0;
            i < this.announcement.hr_announcement_departments.length;
            i++
          ) {
            formData.append(
              "hr_announcement_departments[]",
              this.announcement.hr_announcement_departments[i]
            );
          }
        }
      }

      if (this.attachments.length > 0) {
        for (let i = 0; i < this.attachments.length; i++) {
          formData.append("hr_announcement_attachments[]", this.attachments[i]);
        }
      }

      // this.announcement.hr_announcement_attachments

      Announcement.save(formData).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.announcement.hr_announcement_id != "") {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "listAnnouncement" });
            } else {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          if (this.current_distribution_type == "individual") {
            this.$router.push({
              name: "viewAnnouncementEmployeesSummary",
              params: { _id: this._id },
            });
          } else if (this.current_distribution_type == "department") {
            this.$router.push({
              name: "viewAnnouncementDepartmentSummary",
              params: { _id: this._id },
            });
          } else {
            this.$router.push({ name: "listAnnouncement" });
          }
        }
      } else {
        this.$router.push({ name: "listAnnouncement" });
      }
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.announcement).forEach((key) => {
        this.announcement[key] = "";
      });
      this.selectedEmployeeIDs = [];
      this.defaultSelectedEmployeeIDs = "";
      this.hr_announcement_type = "individual";

      this.temp_file_val = {};
      this.attachments = [];
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.announcement.hr_announcement_id > 0) {
        Announcement.delete({
          hr_announcement_id: this.announcement.hr_announcement_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              this.$router.push({ name: "listAnnouncement" });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }

      this.deleteSubmitted = false;
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>
<style scoped>
.attachment_img_style{  
  height: 150px !important;  
}
.card.card-cascade .view.view-cascade {
    border-radius: 0.25rem;
    box-shadow: none !important;
    padding: 15px;
}
.attachment_card_style{
  background: rgb(243, 243, 243);
  box-shadow: none !important;
}
.attachment_card_del_btn{
  padding: 8px 0px !important;
  width: 95%;
  border-radius: 5px !important;
  color: red;  
}
.card-body {    
  padding: 10px !important;
}
</style>