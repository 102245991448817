import Api from "./api";

export default {
  all() {
    return Api.get("api/settings/gps_location/list");
  },

  save(payload) {
    return Api.post("api/settings/gps_location/data_entry", payload);
  },

  delete(payload) {
    return Api.post("api/settings/gps_location/delete", payload);
  },

  view(payload) {
    return Api.get("api/settings/gps_location/" + payload);
  },
};
