<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.keywords')"
                    v-model="formData.keywords"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-date-picker-2
                    v-model="formData.from_date"
                    :label="$t('general.formInput.start_date')"
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-date-picker-2
                    v-model="formData.to_date"
                    :label="$t('general.formInput.end_date')"
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <DepartmentDropdown
                    @updateDepartmentID="setDepartmentID"
                    :default_selected_value="formData.department_id"
                    :required="true"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">

                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="resetFilter"
                  >
                    <mdb-icon icon="undo" size="md" />
                    {{ $t("general.button.reset") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    outline="primary"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="lg"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>

      <mdb-card>
        <mdb-card-body>
          <mdb-tbl responsive hover>
            <!-- Table head -->
            <mdb-tbl-head>
              <tr>
                <th></th>
                <th>
                  {{ $t("employee_attendances.label.attendance_day") }}
                </th>
                <th>
                  {{ $t("employee_attendances.label.actual_check_in") }}
                </th>
                <th>
                  {{ $t("employee_attendances.label.checkin") }}
                </th>
                <!-- <th>
                  {{ $t("general.formInput.status") }}
                </th> -->
                <th>
                  {{ $t("employee_attendances.label.actual_check_out") }}
                </th>
                <th>
                  {{ $t("employee_attendances.label.checkout") }}
                </th>
                <!-- <th>
                  {{ $t("general.formInput.status") }}
                </th> -->
              </tr>
            </mdb-tbl-head>

            <!-- Table body -->
            <mdb-tbl-body>
              <tr
                scope="row"
                v-for="(recordlist, index) in pending_attendance.data"
                :key="index"
              >
                <td scope="row" @click="rowClickEvent(recordlist.attendance_id)">
                  <EmployeeInfoTableCellLayout :user="recordlist" />
                </td>
                <td @click="rowClickEvent(recordlist.attendance_id)">
                  {{ recordlist.attendance_day | formatDateString }}
                </td>
                <td @click="rowClickEvent(recordlist.attendance_id)">
                  {{ recordlist.attendance_actual_checkin_time | formatDateTimeString }}
                </td>
                <td @click="rowClickEvent(recordlist.attendance_id)">
                  {{ recordlist.attendance_checkin_time | formatDateTimeString }}
                </td>
                <!-- <td>
                  {{ recordlist.attendance_checkin_status }}
                </td> -->
                <td @click="rowClickEvent(recordlist.attendance_id)">
                  {{ recordlist.attendance_actual_checkout_time | formatDateTimeString }}
                </td>
                <td @click="rowClickEvent(recordlist.attendance_id)">
                  {{ recordlist.attendance_checkout_time | formatDateTimeString }}
                </td>
                <!-- <td>
                  {{ recordlist.attendance_checkout_status }}
                </td> -->
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>
          <pagination
            :data="pending_attendance"
            @pagination-change-page="fetchRecordList"
            :limit="2"
          ></pagination>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
Vue.component("pagination", require("laravel-vue-pagination"));
import Report from "@/apis/Report";
import DepartmentDropdown from "@/components/DepartmentDropdown";
import { mapActions, mapGetters } from "vuex";
import EmployeeInfoTableCellLayout from "@/components/EmployeeInfoTableCellLayout";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  mdbInput,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    mdbDatePicker2,
    mdbInput,
    DepartmentDropdown,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    EmployeeInfoTableCellLayout,
  },
  data() {
    return {
      formData: {
        from_date: "",
        to_date: "",
        keywords: "",
        department_id: "",
      },
      pending_attendance: { data: [] },
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    rowClickEvent(id) {

      let routeData = this.$router.resolve({name: 'viewManageAttendance', params: { _id: id }});
      window.open(routeData.href, '_blank');

      // this.$router.push({
      //   name: "viewManageAttendance",
      //   params: { _id: id },
      // });
    },

    setDepartmentID(val) {
      this.formData.department_id = val;
    },

    fetchRecordList(page = 1) {
      this.updateLoading(true);

      const params = {
        page: page,
        keywords: this.formData.keywords,
        department_id: this.formData.department_id,
        from_date: this.formData.from_date,
        to_date: this.formData.to_date,
      };

      Report.fixedAttendance(params)
        .then((response) => {
          if (response.status === 200) {
            this.pending_attendance = response.data.data;
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.feedback_message = "Could not connected to server";
            this.feedback_error = true;

            this.updateLoading(false);
          } else {
            console.log(error);
            this.updateLoading(false);
          }
        });
    },

    handleFormSubmit() {
      this.fetchRecordList();
    },

    resetAlert() {
      this.feedback_error = false;
    },
    resetFilter() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = "";
      });
      this.fetchRecordList();
    },
  },
  created() {
    this.fetchRecordList();
  },
};
</script>