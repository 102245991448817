<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div>
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <template v-if="!employee_id">
                <mdb-row class="mb-3">
                  <mdb-col offsetMd="2" md="8">
                    <div class="float-left mt-3 sec_title">Select Employee</div>
                    <mdb-btn
                      type="button"
                      outline="primary"
                      class="float-right btn_outline_style"
                      icon="user-friends"
                      @click.native="modalOpen()"
                    >
                      {{ $t("payrolls.button.select_employee") }}
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>

                <mdb-row class="mb-3">
                  <mdb-col offsetMd="2" md="8">
                    <div v-if="selected_employee_ids.length > 0">
                      <mdb-chip
                        v-for="(e, counter) in selected_employee_ids"
                        v-bind:key="counter"
                        waves
                      >
                        {{ e.epinfo_name }}
                      </mdb-chip>
                    </div>

                    <hr />
                  </mdb-col>
                </mdb-row>
              </template>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-select
                    v-model="OptionAddition"
                    placeholder="Select Additional Category"
                    label=""
                    outline
                    @getValue="updateAdditionValue"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    :label="$t('addition.formInput.description')"
                    v-model="additions.ota_description"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <!-- ///////////////////////// -->

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label></label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="ota_type-different_amount"
                      name="ota_type"
                      radioValue="different_amount"
                      v-model="additions.ota_type"
                      label="Different Amount"
                    />
                    <mdb-input
                      type="radio"
                      id="ota_type-same_amount"
                      name="ota_type"
                      radioValue="same_amount"
                      v-model="additions.ota_type"
                      label="Same Amount"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>
              <br />
              <!-- ///////////////////////// -->

              <div v-show="additions.ota_type == 'different_amount'">
                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <mdb-btn
                      type="button"
                      class="btn_sm_light_blue"
                      color="uno-color"
                      @click="addAdditionAmount('', '', '', '')"
                      size="sm"
                      rounded
                    >
                      <mdb-icon icon="plus-square" size="md" />
                      Add More
                    </mdb-btn>
                  </mdb-col>
                </mdb-row>

                <div
                  v-for="(addition_amount, counter) in addition_amount_list"
                  v-bind:key="counter"
                >
                  <mdb-row>
                    <mdb-col offsetMd="2" md="4">
                      <mdb-input
                        type="number"
                        :label="$t('addition.formInput.amount')"
                        v-model="addition_amount.amount"
                        outline
                      />
                    </mdb-col>

                    <mdb-col md="4">
                      <!-- <mdb-date-picker-2
                      :label="$t('addition.formInput.effective_month')"
                      v-model="addition_amount.effective_month"
                      title="Select date"
                    /> -->
                      <MonthDropdown
                        @updateMonth="setMonth"
                        :selected_date="addition_amount.effective_month"
                        :parent_index="counter"
                          :key="rerenderIndex"
                      />

                      <YearDropdown
                        @updateYear="setYear"
                        :selected_date="addition_amount.effective_year"
                        :parent_index="counter"
                          :key="rerenderIndex"
                      />
                    </mdb-col>

                    <mdb-col offsetMd="2" md="8">
                      <mdb-btn
                        type="button"
                        rounded
                        size="sm"
                        @click="deleteAdditionAmount(counter)"
                        color="uno-color"
                        class="btn_sm_delete mt-2 mb-4"
                        :disabled="shouldDisabledDeleteAdditionAmountListButton"
                      >
                        <mdb-icon icon="trash-alt" size="md" />
                        {{ $t("general.button.delete") }}
                      </mdb-btn>
                    </mdb-col>
                  </mdb-row>

                  <mdb-row v-if="counter < addition_amount_list.length - 1">
                    <mdb-col offsetMd="2" md="8">
                      <hr />
                    </mdb-col>
                  </mdb-row>
                </div>
              </div>

              <div v-show="additions.ota_type == 'same_amount'">
                <mdb-row>
                  <mdb-col offsetMd="2" md="4">
                    <mdb-input
                      type="number"
                      label="Amount"
                      v-model="temp_same_amount.ota_amount"
                      outline
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row><mdb-col offsetMd="2" md="8">From</mdb-col></mdb-row>
                <mdb-row>
                  <mdb-col offsetMd="2" md="4">
                    <MonthDropdown
                      @updateMonth="setFromMonth"
                      :selected_date="''"
                    />
                  </mdb-col>
                  <mdb-col md="4">
                    <YearDropdown
                      @updateYear="setFromYear"
                      :selected_date="''"
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row><mdb-col offsetMd="2" md="8">To</mdb-col></mdb-row>
                <mdb-row>
                  <mdb-col offsetMd="2" md="4">
                    <MonthDropdown
                      @updateMonth="setToMonth"
                      :selected_date="''"
                    />
                  </mdb-col>
                  <mdb-col md="4">
                    <YearDropdown @updateYear="setToYear" :selected_date="''" />
                  </mdb-col>
                </mdb-row>
              </div>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <mdb-modal size="lg" :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Select Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <EmployeeListBoostrapCheckboxTable
          @updateSelectedEmployeeIDs="setSelectedEmployeeIDs"
          :default_selected_value="source_default_selected_value"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import Addition from "@/apis/Addition";
import { additionalCategoryOption } from "@/constants/employee.js";

import YearDropdown from "@/components/YearDropdown";
import MonthDropdown from "@/components/MonthDropdown";

import EmployeeListBoostrapCheckboxTable from "@/components/EmployeeListBoostrapCheckboxTable";

import {
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbChip,
  // mdbDatePicker2,
  mdbSelect,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    mdbChip,
    // mdbDatePicker2,
    mdbSelect,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    EmployeeListBoostrapCheckboxTable,
    mdbFormInline,
    YearDropdown,
    MonthDropdown,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      additions: {
        ota_id: "",
        ota_employee_id: this.employee_id ? [this.employee_id] : "",
        ota_category: "",
        ota_description: "",
        ota_amount: [],
        ota_effective_month: [],
        ota_type: "different_amount",
        ota_from: "",
        ota_to: "",
      },
      OptionAddition: additionalCategoryOption(true, ""),
      addition_amount_list: [
        {
          d_id: "",
          amount: "",
          effective_month: "",
          effective_year: "",
        },
      ],
      temp_same_amount: {
        ota_amount: "",
        from_month: "",
        from_year: "",
        to_month: "",
        to_year: "",
      },
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",

      selectedEmployeeData: null,
      modal: false,

      selected_employee_ids: [],
      source_default_selected_value: "",
      
      rerenderIndex:0
    };
  },
  computed: {
    shouldDisabledDeleteAdditionAmountListButton() {
      return this.addition_amount_list.length > 1 ? false : true;
    },
  },

  methods: {
    setSelectedEmployee(val) {
      this.modal = false;
      this.selectedEmployeeData = val;
      this.additions.ota_employee_id = val.employee_id;
    },

    setMonth({ value, parent_index }) {
      this.addition_amount_list[parent_index].effective_month = value;
      // console.log('val'+value)
      // console.log('counter'+parent_index)
    },

    setYear({ value, parent_index }) {
      this.addition_amount_list[parent_index].effective_year = value;
      // console.log('val'+value)
      // console.log('counter'+parent_index)
    },

    setFromMonth(value) {
      this.temp_same_amount.from_month = value;
    },
    setFromYear(value) {
      this.temp_same_amount.from_year = value;
    },
    setToMonth(value) {
      this.temp_same_amount.to_month = value;
    },
    setToYear(value) {
      this.temp_same_amount.to_year = value;
    },

    modalOpen() {
      this.modal = true;

      this.source_default_selected_value = this.selected_employee_ids.map(
        (e) => {
          return e.employee_id;
        }
      );
    },

    setSelectedEmployeeIDs(val) {
      this.selected_employee_ids = val;
    },

    updateAdditionValue(val) {
      this.additions.ota_category = val;
    },
    addAdditionAmount(
      d_id = "",
      amount = "",
      effective_month = "",
      effective_year = ""
    ) {
      
      this.rerenderIndex++;
      this.addition_amount_list.push({
        d_id,
        amount,
        effective_month,
        effective_year,
      });
    },
    deleteAdditionAmount(counter) {
      
      this.rerenderIndex++;
      this.addition_amount_list.splice(counter, 1);
    },

    prepareAdditionList() {
      this.additions.ota_id = [];
      this.additions.ota_amount = [];
      this.additions.ota_effective_month = [];
      this.additions.ota_from = "";
      this.additions.ota_to = "";

      if (this.additions.ota_type == "different_amount") {
        if (this.addition_amount_list.length > 0) {
          for (let addition_amount of this.addition_amount_list) {
            this.additions.ota_id.push(addition_amount.d_id);
            this.additions.ota_amount.push(addition_amount.amount);
            this.additions.ota_effective_month.push(
              addition_amount.effective_year +
                "-" +
                (parseInt(addition_amount.effective_month) >= 10 ? addition_amount.effective_month : '0'+parseInt(addition_amount.effective_month)) +
                "-01"
            );
          }
        }
      } else {
        this.additions.ota_amount.push(this.temp_same_amount.ota_amount);
        this.additions.ota_from = this.temp_same_amount.from_year + "-" + (parseInt(this.temp_same_amount.from_month) >= 10 ? this.temp_same_amount.from_month : '0'+parseInt(this.temp_same_amount.from_month))  + "-01";
        this.additions.ota_to = this.temp_same_amount.to_year + "-" + (parseInt(this.temp_same_amount.to_month) >= 10 ? this.temp_same_amount.to_month : '0'+parseInt(this.temp_same_amount.to_month))  + "-01";
      }
    },

    handleFormSubmit() {

      if (
        this.additions.ota_type == "same_amount" &&
        (this.temp_same_amount.from_month == "" || this.temp_same_amount.from_year =="" || 
        this.temp_same_amount.to_month == "" || this.temp_same_amount.to_year =="" )
      ) {
        //show error
        this.feedback_message = "Please fill both  of \"From\" and \"To\" month and year.";
        this.feedback_error = true;
        return;
      }  


      this.prepareAdditionList();
      this.resetAlert();
      this.formSubmitting = true;

      if (this.selected_employee_ids.length > 0) {
        this.additions.ota_employee_id = this.selected_employee_ids.map((e) => {
          return e.employee_id;
        });
      }

      // console.log(this.additions)

      Addition.save(this.additions).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });
            // this.resetForm();
            if (this.employee_id) {
              this.$router.push({
                name: "additionList",
                params: { employee_id: this.employee_id },
              });
            } else {
              this.$router.push({ name: "additionsManageList" });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
    },
    redirectBackAction() {
      // this.$router.push({
      //   name: "additionList",
      //   params: { employee_id: this.additions.ota_employee_id },
      // });

      if (this.employee_id) {
        this.$router.push({
          name: "additionList",
          params: { employee_id: this.employee_id },
        });
      } else {
        this.$router.push({ name: "additionsManageList" });
      }
    },
    resetAlert() {
      this.feedback_error = false;
    },
    // resetForm() {
    //   Object.keys(this.additions).forEach((key) => {
    //     this.additions[key] = "";
    //   });
    //   this.addition_amount_list = [
    //     {
    //       d_id: "",
    //       amount: "",
    //       effective_month: "",
    //     },
    //   ];
    //   this.additions.ota_employee_id = this.employee_id
    // },
  },
  created() {
    this.$store.state.crumbsParams = {
      additionList: {
        employee_id: this.employee_id,
      },
    };
  },
};
</script>