<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    v-model="filter_date"
                    label="Date"
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.keywords')"
                    v-model="keywords"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <DepartmentDropdown
                    @updateDepartmentID="setDepartmentID"
                    :default_selected_value="department_id"
                    :required="false"
                  />
                </mdb-col>




                <mdb-col offsetMd="2" md="8">
                  <label>Type</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="option-all"
                      name="option_type"
                      radioValue="all"
                      v-model="type"
                      label="All"
                    />
                    <mdb-input
                      type="radio"
                      id="option-static"
                      name="option_type"
                      radioValue="static"
                      v-model="type"
                      label="Static"
                    />
                    <mdb-input
                      type="radio"
                      id="option-dynamic"
                      name="option_type"
                      radioValue="dynamic"
                      v-model="type"
                      label="Dynamic/Duty Roster"
                    />
                  </mdb-form-inline>
                </mdb-col>


              </mdb-row>

              <br />
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
  </div>
</template>
<script>
import Report from "@/apis/Report";
import DepartmentDropdown from "@/components/DepartmentDropdown";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
    mdbInput,
    mdbFormInline
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    DepartmentDropdown,
    mdbDatePicker2,
    mdbInput,
    mdbFormInline
  },
  data() {
    return {
      department_id: "",
      filter_date:"",
      keywords:"",
      type: "all",
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    setDepartmentID(val) {
      this.department_id = val;
    },

    handleFormSubmit() {
        this.resetAlert();
        this.formSubmitting = true;

        const payload = {
          department_id: this.department_id,
          filter_date: this.filter_date,
          keywords:this.keywords,
          type:this.type
        };

        Report.attendancePolicyExport(payload).then((response) => {
          if (response.status === 200) {
            let today = new Date();
            let [month, date, year] = today
              .toLocaleDateString("en-US")
              .split("/");
            let [hour, minute, second] = today
              .toLocaleTimeString("en-US")
              .split(/:| /);
            let today_string =
              year + month + date + "_" + hour + minute + second;

            var blob = new Blob(["\ufeff",response.data]);
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "AttendancePolicyReport" + "_" + today_string + ".csv";
            link.click();

            this.formSubmitting = false;
          }
        });
      
    },

    resetAlert() {
      this.feedback_error = false;
    },
  },
  created() {
  },
};
</script>