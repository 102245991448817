import Api from './api'

export default{
    all(payload){
            return Api.get('api/settings/company_branch/list', { params: payload })
    },

    save(payload){
            return Api.post('api/settings/company_branch/data_entry', payload)        
    },
    
    delete(payload){
        return Api.post('api/settings/company_branch/delete', payload)        
    },
    
    view(payload){
        return Api.get('api/settings/company_branch/' + payload)        
    },
    
}