<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-4" v-if="action_employee.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Action Report</h6>

                <div>
                  <mdb-tbl responsive striped bordered sm>
                    <mdb-tbl-head style="background: #96a6bf; color: #fff">
                      <tr>
                        <td>Name</td>
                        <td>Position</td>
                        <td>Department</td>
                        <td>Type</td>
                        <td>Date</td>
                        <td>Reason</td>
                        <td>Deduction</td>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body>
                      <tr v-for="(item, index) in action_employee" :key="index">
                        <td>{{ item["name"] }}</td>
                        <td>{{ item["position"] }}</td>
                        <td>{{ item["department"] }}</td>
                        <td>{{ item["type"] }}</td>
                        <td>{{ item["date"] }}</td>
                        <td>{{ item["reason"] }}</td>
                        <td>{{ item["deduction"] }}</td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <!-- ///////////////////// -->

        <mdb-card class="mb-4" v-if="action_comparision_data.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Monthly Action Analysis</h6>

                <mdb-row>
                  <mdb-col>
                    <div>
                      <mdb-tbl responsive striped bordered sm>
                        <mdb-tbl-head style="background: #96a6bf; color: #fff">
                          <tr>
                            <td
                              v-for="(
                                item_value, index
                              ) in action_comparision_months"
                              :key="index"
                            >
                              {{ item_value }}
                            </td>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr
                            v-for="(
                              action_item, index
                            ) in action_comparision_data"
                            :key="index"
                          >
                            <td
                              v-for="(item_value, index2) in action_item"
                              :key="index2"
                            >
                              {{ item_value }}
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <!-- ///////////////////// -->

        <!-- ///////////////////////// -->
         <mdb-row
          v-if="
            action_employee.length == 0 &&
            action_comparision_data.length == 0 
          "
        >
          <mdb-col>
            <mdb-card>
              <mdb-card-body>
                <div class="d-flex justify-content-center">
                  <h5 style="margin-top: 60px">No Record Found.</h5>
                </div>
                <div class="d-flex justify-content-center">
                  <img src="/empty_item.svg" alt="Empty Record" />
                </div>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>

        <!-- ///////////////////////// -->
      </template>
    </div>
  </div>
</template>
<script>
import ManagementReport from "@/apis/ManagementReport";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },
  data() {
    return {
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",

      ////////////////////

      action_employee: [],
      action_comparision_months: [],
      action_comparision_data: [],

      /////////////////////
    };
  },
  props: ["year", "month"],
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    fetchData() {
      this.updateLoading(true);

      const payload = {
        date: this.month + "-" + this.year,
      };

      // const response = {
      //   status: 200,
      //   data: {
      //     action_employee: [
      //       {
      //         name: "lorum ipsuam",
      //         position: "lorum ipsuam",
      //         department: "lorum ipsuam",
      //         type: "lorum ipsuam",
      //         date: "April 20, 2022",
      //         reason: "lorum ipsuam",
      //         deduction: "10%",
      //       },
      //       {
      //         name: "lorum ipsuam",
      //         position: "lorum ipsuam",
      //         department: "lorum ipsuam",
      //         type: "lorum ipsuam",
      //         date: "April 20, 2022",
      //         reason: "lorum ipsuam",
      //         deduction: "20%",
      //       },
      //       {
      //         name: "lorum ipsuam",
      //         position: "lorum ipsuam",
      //         department: "lorum ipsuam",
      //         type: "lorum ipsuam",
      //         date: "April 20, 2022",
      //         reason: "lorum ipsuam",
      //         deduction: "30%",
      //       },
      //     ],

      //     action_comparision_months: ["January", "February", "March"],

      //     action_comparision_data: [
      //       ["lorum ipsuam", 1, 2, 3],
      //       ["lorum ipsuam", 1, 2, 3],
      //       ["lorum ipsuam", 1, 2, 3],
      //     ],
      //   },
      // };
      
      // this.action_employee = response.data.action_employee;
      // this.action_comparision_months = response.data.action_comparision_months;
      // this.action_comparision_data = response.data.action_comparision_data;

      ManagementReport.actionData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.action_employee = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.actionMonthlyComparison(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.action_comparision_months = response.data.data.comparision_header;
            this.action_comparision_data = response.data.data.comparision_data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });
    },

    validateParams() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (
        !months.find((month) => month.toLowerCase() == this.month.toLowerCase())
      ) {
        this.$router.push({ name: "managementReport" });
      }

      if (!/^\d{4}$/.test(this.year)) {
        this.$router.push({ name: "managementReport" });
      }

      this.fetchData();
    },
  },
  created() {
    this.validateParams();
  },
};
</script>

<style scoped>
.rotate_text {
  transform: rotate(180deg);

  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(180deg);

  /* Firefox */
  -moz-transform: rotate(180deg);

  /* IE */
  -ms-transform: rotate(180deg);

  /* Opera */
  -o-transform: rotate(180deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
</style>