<template>
  <div>
    <!-- search -->
    <mdb-select
      outline
      v-model.trim="yearsOptions"
      label="Select Year"
      placeholder=""
      v-on:getValue="getSelectValue"
    />
  </div>
</template>

<script>
import { mdbSelect } from "mdbvue";

const yearOption = (current, year) => {
  let years = [];
  for (let current_year = current; current_year >= 2000; --current_year) {
    if (year == current_year)
      years.push({ text: current_year, value: current_year, selected: true });
    else years.push({ text: current_year, value: current_year });
  }
  return years;
};

export default {
  name: "YearDropdown",
  components: {
    mdbSelect,
  },
  props: ["selected_date", "parent_index"],
  data() {
    return {
      yearsOptions: null,
      filter_year: "",
    };
  },

  methods: {
    getSelectValue(val) {
      this.filter_year = val;

      if (typeof this.parent_index == "undefined") {
        this.$emit("updateYear", val);
      } else {
        this.$emit("updateYear", {
          value: val,
          parent_index: this.parent_index,
        });
      }
    },
  },

  created() {
    if (this.selected_date != "") {
    //   const parts = this.selected_date.split("-");
    //   // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    //   // January - 0, February - 1, etc.
    //   const mydate = new Date(parts[0], parts[1] - 1, parts[2]);
    //   this.filter_year = mydate.getFullYear();
        this.filter_year  = this.selected_date;
    }

    this.yearsOptions = yearOption(
      parseInt(new Date().getFullYear()) + 1,
      this.filter_year
    );
  },
};
</script>