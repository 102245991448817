import Api from './api'

export default {

  

    departmentImport(payload) {
        return Api.post('api/excel_input/department/import', payload)
    },

    positionImport(payload) {
        return Api.post('api/excel_input/position/import', payload)
    },

    payrollImport(payload) {
        return Api.post('api/excel_input/payroll/import', payload)
    },

    employeeImport(payload) {
        return Api.post('api/excel_input/employee/import', payload)
    },    

    attendanceImport(payload) {
        return Api.post('api/excel_input/attendance/import', payload)
    },

    leaveImport(payload) {
        return Api.post('api/excel_input/leave/import', payload)
    },

    overtimeImport(payload) {
        return Api.post('api/excel_input/overtime/import', payload)
    },


    
    bankinfoImport(payload) {
        return Api.post('api/excel_input/bankinfo/import', payload)
    },
    ssbinfoImport(payload) {
        return Api.post('api/excel_input/ssb/import', payload)
    },
    taxinfoImport(payload) {
        return Api.post('api/excel_input/taxinfo/import', payload)
    },
    personalinfoImport(payload) {
        return Api.post('api/excel_input/personalinfo/import', payload)
    },
    familyinfoImport(payload) {
        return Api.post('api/excel_input/family/import', payload)
    },
    educationinfoImport(payload) {
        return Api.post('api/excel_input/education/import', payload)
    },
    personaltraininginfoImport(payload) {
        return Api.post('api/excel_input/personal_training/import', payload)
    },
    occupationinfoImport(payload) {
        return Api.post('api/excel_input/occupation/import', payload)
    },

    
    additionPaymentImport(payload) {
        return Api.post('api/excel_input/addition/import', payload)
    },    
    deductionPaymentImport(payload) {
        return Api.post('api/excel_input/deduction/import', payload)
    },    
    hrTrainingImport(payload) {
        return Api.post('api/excel_input/hrtraining/import', payload)
    },
    dutyRosterImport(payload) {
        return Api.post('api/excel_input/duty_roster/import', payload)
    },
    actionImport(payload) {
        return Api.post('api/excel_input/action/import', payload)
    },
    allowanceImport(payload) {
        return Api.post('api/excel_input/employee_allowance/import', payload)
    },
    
}