<template>
  <div>
    <mdb-select
      outline
      search
      v-model="axiosOptions"
      :label="selectLabel"
      @getValue="updateSelectedValue"
      :disabled="loading"
    />
  </div>
</template>

<script>
import { mdbSelect } from "mdbvue";
import WorkingHours from "@/apis/WorkingHours";
export default {
  name: "WorkingHourDropdown",
  components: {
    mdbSelect,
  },
  props: ["default_selected_value", "required", "resetSelected"],
  data() {
    return {
      axiosOptions: [],
      loading: true,
      selectLabel: "Loading Working Hours List",
    };
  },
  mounted() {
    WorkingHours.all().then((response) => {
      if (response.status === 200) {
        const working_hours = response.data.data;
        if (this.required === false) {
          this.axiosOptions.push({
            text: "All",
            value: "",
          });
        }

        for (let working_hour of working_hours) {
          if (this.default_selected_value == working_hour.hr_work_hours_id) {
            this.axiosOptions.push({
              text: working_hour.hr_work_hours_name,
              value: working_hour.hr_work_hours_id,
              selected: true,
            });
          } else {
            this.axiosOptions.push({
              text: working_hour.hr_work_hours_name,
              value: working_hour.hr_work_hours_id,
            });
          }
        }
        this.loading = false;
        this.selectLabel = "Select Working Hours";
      }
    });
  },
  methods: {
    updateSelectedValue(val) {
      if (val !== null) {
        this.$emit("updateWorkingHoursID", val);
      }
    },
  },
  watch: {
    resetSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.axiosOptions = this.axiosOptions.map((val) => ({
          text: val.text,
          value: val.value,
        }));
      }
    },
  },
};
</script>
