import Api from './api'

export default{
    all(payload){
            return Api.get('api/employees/overtime/list/' + payload)
    },

    save(payload){
            return Api.post('api/employees/overtime/data_entry', payload)        
    },
    
    delete(payload){
        return Api.post('api/employees/overtime/delete', payload)        
    },
    
    view(payload){
        return Api.get('api/employees/overtime/' + payload)        
    },
    
}
