export default {
    getCrumbs(state){
        return state.crumbs
    },

    getCrumbsParams(state){
        return state.crumbsParams
    },
    
    getPageTitle(state){
        return state.pageTitle
    },

    isLoading(state){
        return state.loading
    },

}