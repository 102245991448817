<template>
  <div>
    <mdb-row>
      <mdb-col
      >
      <div class="info_border_box" style="padding-top:0;">

        
        <div class="approval_date_box">Approval Date : {{ user.date }}</div>
        <!-- <img
          src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img%20(132).jpg"
          class="rounded-circle float-left ml-3"
          width="50"
          height="50"
        /> -->
         <img
          :src="user.profile_image"
          class="rounded-circle float-left  ml-3"
          width="50"
          height="50"
          v-if="user.profile_image"
        />


        <div class="float-left ml-3 mb-3">
          <div class="employee_name">{{ user.epinfo_name }}</div>
          <div class="employee_info_text_sec">{{ user.jp_name }}</div>          
          <div class="employee_info_text">
            {{ user.department_name }}
          </div>    
          <div class="employee_info_text" v-if=" user.description!= ''">
            Remark : {{ user.description }}
          </div>           
        </div>

        <div style="clear:both;"></div>

      </div>        
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import { mdbRow, mdbCol } from "mdbvue";
export default {
  name: "ApprovalApprovedUserLayout",
  components: { mdbRow, mdbCol },
  props: ["user"],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style>
.approval_date_box{
background: #f3f0f0;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 12px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    line-height: 30px;
    text-align: right;
    margin-bottom:15px;
}
</style>