import Api from './api'

export default{
    all(payload){
            return Api.get('api/employees/contract/list', {params: payload})
    },

    save(payload){
            return Api.post('api/employees/contract/data_entry', payload)        
    },
    
    delete(payload){
        return Api.post('api/employees/contract/delete', payload)        
    },
    
    view(payload){
        return Api.get('api/employees/contract/' + payload)        
    },
    
}