import axios from 'axios'
import store from '../store/index'



var host = window.location.host;
//  var host = window.location.origin;

if (host.search("localhost") >= 0) {
  host = "http://localhost:8000"
  //  host = "http://192.168.5.170/unohr_laravel_rev1"
  // host = "https://hrmapi01.sbg.red"
} else if (host.search("127.0.0.1") >= 0) {
  host = "http://127.0.0.1:8000"
} else if (host.search("192.168.100.9") >= 0) {
  host = "http://192.168.100.9:8000"
} else if (host.search("pr1.sbg.red") >= 0) {
  host = "https://pr0.sbg.red"
} else if (host.search("192.168.1.101") >= 0) {
  host = "http://192.168.1.101:8000"
}else{
  host = "https://hrmapi01.sbg.red"
}

/*
else if (host.search("hrm01.sbg.red") >= 0) {
  host = "https://hrmapi01.sbg.red/"
} else if (host.search("hrm") >= 0) {
  // host = tmp_host.substr(0, tmp_host.search("hrm")) + "hrmapi." + tmp_host.substr(tmp_host.search("hrm") + 4 )
  host = location.protocol.concat("//hrmapi.").concat(window.location.host.substr(window.location.host.search("hrm") + 4 ));  
} else {
  host = location.protocol.concat("//hrmapi.").concat(window.location.host);
}
*/

const Api = axios.create({
  baseURL: host,
  // headers:{
  //     'Authorization': localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : ''
  // }


})

Api.interceptors.request.use((config) => {
  // We are importing store before it is populated.
  // We intercept the request and use the current apiKey
  if(store.getters['user/apiAuthorizationToken']){
    config.headers = { 'Authorization': 'Bearer ' + store.getters['user/apiAuthorizationToken'], 'Content-Organization': store.getters['user/apiOrganizationValue']  };
  }else{
    config.headers = {};
  }
  
  return config;
});

export default Api;