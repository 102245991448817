<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="approval_group.apg_name"
                    outline
                    required
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>

        <mdb-row v-if="_id != undefined">
          <mdb-col>
            <div class="float-right">
              <mdb-btn
                type="button"
                class="btn_submit"
                color="uno-color"
                @click="addEmployeeToGroup"
              >
                <mdb-icon icon="plus" size="md" />
                Assign Employees into Group
              </mdb-btn>
            </div>
          </mdb-col>
        </mdb-row>

        <!-- ////////////////////////////////////////// -->
        <!-- ////////////////////////////////////////// -->

        <mdb-card
          class="mb-5"
          v-if="_id != undefined && this.employees.length == 0"
        >
          <mdb-card-body>
            <h4>No record found.</h4>
            <p>
              Click the button above to assign employees into Approval Group.
            </p>
          </mdb-card-body>
        </mdb-card>

        <mdb-card
          class="mb-5"
          v-if="_id != undefined && this.employees.length > 0"
        >
          <mdb-card-header>
            <div class="float-left"></div>
            <div class="float-right">
              <mdb-btn
                size="sm"
                color="uno-color"
                class="btn_delete"
                @click.native="modalOpen()"
                :disabled="!shouldActionButtonShow"
              >
                <mdb-icon
                  icon="trash-alt"
                  far
                  size="md"
                  v-show="!actionSubmitted"
                />

                <span
                  class="spinner-border-vcenter spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-show="actionSubmitted"
                ></span>

                Delete Selected
              </mdb-btn>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <!-- ////////////// -->
            <div class="list-group">
              <mdb-tbl responsive hover>
                <!-- Table head -->
                <mdb-tbl-head>
                  <tr>
                    <th>
                      <!-- Material unchecked -->
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="trmc_all"
                          @change="checkAllEmployees"
                          v-model="isCheckAll"
                        />
                        <label class="form-check-label" for="trmc_all"></label>
                      </div>
                    </th>
                    <th></th>
                    <th @click="sort('name')">
                      <i
                        v-show="currentSort === 'name'"
                        :class="[
                          currentSortDir === 'asc'
                            ? 'fas fa-arrow-up '
                            : 'fas fa-arrow-down ',
                        ]"
                        aria-hidden="true"
                      ></i>
                      {{ $t("general.formInput.name") }}
                    </th>
                    <th @click="sort('position')">
                      <i
                        v-show="currentSort === 'position'"
                        :class="[
                          currentSortDir === 'asc'
                            ? 'fas fa-arrow-up '
                            : 'fas fa-arrow-down ',
                        ]"
                        aria-hidden="true"
                      ></i>
                      {{ $t("general.formInput.jp_name") }}
                    </th>

                    <th @click="sort('department')">
                      <i
                        v-show="currentSort === 'department'"
                        :class="[
                          currentSortDir === 'asc'
                            ? 'fas fa-arrow-up '
                            : 'fas fa-arrow-down ',
                        ]"
                        aria-hidden="true"
                      ></i>
                      {{ $t("general.formInput.department_name") }}
                    </th>
                    <th>Rank</th>
                  </tr>
                </mdb-tbl-head>
                <!-- Table head -->

                <!-- Table body -->
                <mdb-tbl-body>
                  <tr v-for="(employee, index) in pageOfItems" :key="index">
                    <th scope="row">
                      <!-- Material unchecked -->
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          :id="'trmc_' + employee.apge_id"
                          :value="employee"
                          v-model="selectedEmployees"
                          @change="updateCheckall"
                        />
                        <label
                          class="form-check-label"
                          :for="'trmc_' + employee.apge_id"
                        ></label>
                      </div>
                    </th>

                    <td @click="rowClickEvent(employee)">
                      <img
                        :src="employee.profile_image"
                        class="rounded-circle float-left"
                        width="50"
                        height="50"
                        v-if="employee.profile_image"
                        @error="imageLoadError"
                      />
                    </td>
                    <td @click="rowClickEvent(employee)">
                      {{ employee.epinfo_name }}
                    </td>
                    <td @click="rowClickEvent(employee)">
                      {{ employee.jp_name }}
                    </td>
                    <td @click="rowClickEvent(employee)">
                      {{ employee.department_name }}
                    </td>

                    <td @click="rowClickEvent(employee)">
                      {{ employee.rt_name }}
                    </td>
                  </tr>
                </mdb-tbl-body>
                <!-- Table body -->
              </mdb-tbl>
              <div class="card-footer pb-0 pt-3">
                <jw-pagination
                  :items="employees"
                  @changePage="onChangePage"
                  :pageSize="rowsPerPage"
                ></jw-pagination>
              </div>
            </div>
            <!-- ////////////// -->
          </mdb-card-body>
        </mdb-card>
        <!-- ////////////////////////////////////////// -->
        <!-- ////////////////////////////////////////// -->
      </template>
    </div>

    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />

    <mdb-modal :show="modal" @close="modal = false">
      <mdb-modal-header>
        <mdb-modal-title>Confirmation !</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <p>Are you sure to delete all selected record ?</p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="modal = false"
          >Close</mdb-btn
        >
        <mdb-btn color="primary" @click="confirmAction" size="sm">Yes</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import Vue from "vue";
import JwPagination from "jw-vue-pagination";
Vue.component("jw-pagination", JwPagination);

import ApprovalGroup from "@/apis/ApprovalGroup";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

export default {
  components: {
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    ModalDeleteConfimation,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
  },
  props: ["_id"],
  data() {
    return {
      approval_group: {
        apg_id: "",
        apg_name: "",
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",

      pageOfItems: [],
      rowsPerPage: this.$store.state.rowsPerPage,
      employees: [],
      selectedEmployees: [],
      isCheckAll: false,
      modal: false,
      actionSubmitted: false,
      currentSort: "holiday_name",
      currentSortDir: "asc",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.approval_group.apg_id != "" ? true : false;
    },

    shouldActionButtonShow() {
      return this.selectedEmployees.length > 0 ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),

    modalOpen() {
      this.modal = true;
    },

    imageLoadError(event) {
      event.target.src = "/default-user.png";
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    rowClickEvent(val) {
      // console.log(val)
      const found = this.selectedEmployees.find(function (e) {
        if (e.apge_id == val.apge_id) return true;
      });

      if (found == undefined) {
        this.selectedEmployees.push(val);
      } else {
        const index = this.selectedEmployees.indexOf(val);
        if (index > -1) {
          this.selectedEmployees.splice(index, 1);
        }
      }
      this.updateCheckall();
    },
    checkAllEmployees() {
      this.selectedEmployees = [];
      if (this.isCheckAll) {
        for (let employee of this.employees) {
          this.selectedEmployees.push(employee);
        }
      }
    },
    updateCheckall() {
      // console.log(this.employees)
      if (this.employees.length == this.selectedEmployees.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    ////////////////////

    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          ApprovalGroup.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)
                  let apg_name, apg_id;

                  // populate field
                  ({ apg_name, apg_id } = response.data.data);
                  this.approval_group.apg_id = apg_id;
                  this.approval_group.apg_name = apg_name;
                  this.employees = response.data.employees;

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listApprovalGroup" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listApprovalGroup" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listApprovalGroup" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      ApprovalGroup.save(this.approval_group).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.approval_group.apg_id != "") {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "listApprovalGroup" });
            } else {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "addEmployeeIntoApprovalGroup" , params: { apge_apg_id: response.data.id },});
              
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    addEmployeeToGroup() {
      this.$router.push({
        name: "addEmployeeIntoApprovalGroup",
        params: { apge_apg_id: this._id },
      });
    },
    redirectBackAction() {
      this.$router.push({ name: "listApprovalGroup" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.approval_group).forEach((key) => {
        this.approval_group[key] = "";
      });
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.approval_group.apg_id > 0) {
        ApprovalGroup.delete({ apg_id: this.approval_group.apg_id }).then(
          (response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({ name: "listApprovalGroup" });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          }
        );
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },

    confirmAction() {
      this.actionSubmitted = true;

      let payload = {
        //filter data
        apge_id: [],
      };
      for (let employee of this.selectedEmployees) {
        payload.apge_id.push(employee.apge_id);
      }

      ApprovalGroup.deleteEmployees(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            this.actionSubmitted = false;

            this.selectedEmployees = [];
            this.isCheckAll = false;
            this.logs = [];

            let temp_emps = [];
            for (let employee of this.employees) {
              if (!payload.apge_id.includes(employee.apge_id)) {
                temp_emps.push(employee);
              }
            }

            this.employees = temp_emps;


            // this.fetchRecord();

            this.modal = false;
          } else if (response.data.code === 500) {
            this.actionSubmitted = false;
            this.feedback_message = "Error";
            this.feedback_error = true;

            this.modal = false;
          }
        }
      });
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>