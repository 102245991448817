<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>
    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="familyInfos.efinfo_name"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-select
                    v-model="OptionRelationship"
                    placeholder="Select Relationship"
                    label=""
                    outline
                    @getValue="updateRelationshipValue"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('employee_info.formInput.occupation')"
                    v-model="familyInfos.efinfo_occupation"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    v-model="familyInfos.efinfo_date_of_birth"
                    :label="$t('employee_info.formInput.date_of_birth')"
                    title="Select date"
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('employee_info.formInput.place_of_birth')"
                    v-model="familyInfos.efinfo_place_of_birth"
                    outline
                  />
                </mdb-col>

                

                <mdb-col offsetMd="2" md="8">
                  <label>{{
                    $t("employee_info.formInput.transfer_insurance")
                  }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="familyInfo-transfer_insurance-yes"
                      name="efinfo_transfer_insurance"
                      radioValue="yes"
                      v-model="familyInfos.efinfo_transfer_insurance"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="familyInfo-transfer_insurance-no"
                      name="efinfo_transfer_insurance"
                      radioValue="no"
                      v-model="familyInfos.efinfo_transfer_insurance"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                  
                <br />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <label>{{ $t("employee_info.formInput.behalf_ssb") }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="familyInfo-efinfo_behalf_ssb-yes"
                      name="efinfo_efinfo_behalf_ssb"
                      radioValue="yes"
                      v-model="familyInfos.efinfo_behalf_ssb"
                      :label="$t('general.formInput.yes')"
                    />
                    <mdb-input
                      type="radio"
                      id="familyInfo-efinfo_behalf_ssb-no"
                      name="efinfo_efinfo_behalf_ssb"
                      radioValue="no"
                      v-model="familyInfos.efinfo_behalf_ssb"
                      :label="$t('general.formInput.no')"
                    />
                  </mdb-form-inline>
                  <br />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"                    
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import EmployeeFamilyInfo from "@/apis/EmployeeFamilyInfo";
import LogList from "@/components/logs/List";
import { relationshipOption } from "@/constants/employee.js";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  mdbSelect,
  mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbDatePicker2,
    mdbSelect,
    mdbFormInline,
    ModalDeleteConfimation,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      familyInfos: {
        efinfo_id: "",
        efinfo_employee_id:
          this.employee_id != "undefined" ? this.employee_id : "",
        efinfo_name: "",
        efinfo_relationship: "",
        efinfo_date_of_birth: "",
        efinfo_place_of_birth: "",
        efinfo_occupation: "",
        efinfo_transfer_insurance: "no",
        efinfo_behalf_ssb: "no",
      },
      OptionRelationship: relationshipOption(true, ""),
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.familyInfos.efinfo_id != "" ? true : false;
    },
    // populateRelationshipOption() {
    //   return relationshipOption(true, "");
    // },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    updateRelationshipValue(val) {
      this.familyInfos.efinfo_relationship = val;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          EmployeeFamilyInfo.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  console.log(response.data)

                  let efinfo_id,
                    efinfo_employee_id,
                    efinfo_name,
                    efinfo_relationship,
                    efinfo_date_of_birth,
                    efinfo_place_of_birth,
                    efinfo_occupation,
                    efinfo_transfer_insurance,
                    efinfo_behalf_ssb;

                  // populate field
                  ({
                    efinfo_id,
                    efinfo_employee_id,
                    efinfo_name,
                    efinfo_relationship,
                    efinfo_date_of_birth,
                    efinfo_place_of_birth,
                    efinfo_occupation,
                    efinfo_transfer_insurance,
                    efinfo_behalf_ssb,
                  } = response.data.data);
                  this.familyInfos.efinfo_id = efinfo_id;
                  this.familyInfos.efinfo_employee_id = efinfo_employee_id;
                  this.familyInfos.efinfo_name = efinfo_name;
                  this.familyInfos.efinfo_relationship = efinfo_relationship;
                  this.familyInfos.efinfo_date_of_birth = efinfo_date_of_birth;
                  this.familyInfos.efinfo_place_of_birth = efinfo_place_of_birth;
                  this.familyInfos.efinfo_occupation = efinfo_occupation;
                  this.familyInfos.efinfo_transfer_insurance = (efinfo_transfer_insurance =='0' ? 'no':'yes');
                  this.familyInfos.efinfo_behalf_ssb = (efinfo_behalf_ssb =='0' ? 'no':'yes');

                  //make selected option again
                  this.OptionRelationship = relationshipOption(
                    true,
                    efinfo_relationship
                  );

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  
                  this.$store.state.crumbsParams = {
                    familyInfoList: {
                      _id: this.familyInfos.efinfo_employee_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "familyInfoList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "familyInfoList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "familyInfoList" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      EmployeeFamilyInfo.save(this.familyInfos).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.familyInfos.efinfo_id != "") {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "familyInfoList", params: { employee_id: this.familyInfos.efinfo_employee_id } });
            } else {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              this.resetForm();
            }

            
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({
        name: "familyInfoList",
        params: { employee_id: this.familyInfos.efinfo_employee_id },
      });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.familyInfos).forEach((key) => {
        this.familyInfos[key] = "";
      });
        this.familyInfos.efinfo_employee_id = this.employee_id;
        this.familyInfos.efinfo_transfer_insurance= "no";
        this.familyInfos.efinfo_behalf_ssb= "no";
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.familyInfos.efinfo_id > 0) {
        EmployeeFamilyInfo.delete({
          efinfo_id: this.familyInfos.efinfo_id,
        })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({
                  name: "familyInfoList",
                  params: {
                    employee_id: this.familyInfos.efinfo_employee_id,
                  },
                });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      familyInfoList: {
        employee_id: this.familyInfos.efinfo_employee_id,
      },
    };
    this.fetchRecord();
  },
};
</script>