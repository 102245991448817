import AuthLayout from '@/components/layouts/AuthLayout';
import DashboardLayout from '@/components/layouts/DashboardLayout';

import EmployeeLayout from '@/components/layouts/EmployeeLayout';

import ManagementReportNavigation from '@/components/layouts/ManagementReportNavigation';



import Login from '@/pages/user/Login';
import ForgotPassword from '@/pages/user/ForgotPassword';
import ResetPassword from '@/pages/user/ResetPassword';
import ResetSuccess from '@/pages/user/ResetSuccess';
import NotFound from '@/pages/errors/NotFound';

import Dashboard from '@/pages/Dashboard';

import Imports from '@/pages/Imports';

import Settings from '@/pages/settings/Settings';

import AdditionReport from '@/pages/report/AdditionReport';
import DeductionReport from '@/pages/report/DeductionReport';


import AttendancePolicyReport from '@/pages/report/AttendancePolicyReport';


import ISOList from '@/pages/iso/ISOList';
import ISOForm from '@/pages/iso/ISOForm';

import AnnouncementList from '@/pages/announcements/AnnouncementList';
import AnnouncementForm from '@/pages/announcements/AnnouncementForm';
import AnnouncementDepartmentSummaryList from '@/pages/announcements/AnnouncementDepartmentSummaryList';
import AnnouncementEmployeesSummaryList from '@/pages/announcements/AnnouncementEmployeesSummaryList';

import ActivityLeaderForm from '@/pages/activity/ActivityLeaderForm';

import MonthlyAttendanceForm from '@/pages/attendances/MonthlyAttendanceForm';
import MonthlyAttendanceLogList from '@/pages/attendances/MonthlyAttendanceLogList';

import AttendanceApprovalList from '@/pages/attendances/ApprovalList';
import AttendanceApprovalForm from '@/pages/attendances/ApprovalForm';

import LeaveApprovalList from '@/pages/leaves/ApprovalList';
import LeaveApprovalForm from '@/pages/leaves/ApprovalForm';


import OvertimeApprovalList from '@/pages/overtime/ApprovalList';
import OvertimeApprovalForm from '@/pages/overtime/ApprovalForm';



import AnnouncementEmployeesList from '@/pages/announcements/AnnouncementEmployeesList';


import PayrollForm from '@/pages/payrolls/PayrollForm';
import PayrollRequestQueueList from '@/pages/payrolls/PayrollRequestQueueList';


import PayslipList from '@/pages/payrolls/PayslipList';

import PayslipPublishing from '@/pages/payrolls/PayslipPublishing';




import ContractManageList from '@/pages/core_hr_management/ContractList';
import WorkingHistoryManageList from '@/pages/core_hr_management/WorkingHistoryList';
import ActionsManageList from '@/pages/core_hr_management/ActionsList';
import OtherAdditionsManageList from '@/pages/core_hr_management/OtherAdditionsList';
import OtherDeductionsManageList from '@/pages/core_hr_management/OtherDeductionsList';
import AttendanceManageList from '@/pages/attendances/AttendanceList';
import LeaveManageList from '@/pages/leaves/LeaveList';
// import LeaveManageForm from '@/pages/leaves/LeaveForm';
import OvertimeManageList from '@/pages/overtime/OvertimeList';



import HrTrainingList from '@/pages/training/HrTrainingList';
import HrTrainingForm  from '@/pages/training/HrTrainingForm';



import HolidayForm from '@/pages/settings/holidays/HolidayForm';
import HolidayList from '@/pages/settings/holidays/HolidayList';

import ApprovalWorkflowForm from '@/pages/settings/approvalWorkflow/ApprovalWorkflowForm';

import ManualDutyAssignmentEntryForm from '@/pages/attendances/ManualDutyAssignmentEntryForm';

import Report from '@/pages/report/Report';


import SBGUsageReport from '@/pages/report/SBGUsageReport';

import EmployeeUsageReport from '@/pages/report/EmployeeUsageReport';

import ManagementReport from '@/pages/report/ManagementReport';
import ManagementSummaryReport from '@/pages/report/ManagementSummaryReport';
import ManagementHeadcountReport from '@/pages/report/ManagementHeadcountReport';
import ManagementTurnoverReport from '@/pages/report/ManagementTurnoverReport';
import ManagementLeaveReport from '@/pages/report/ManagementLeaveReport';
import ManagementOvertimeReport from '@/pages/report/ManagementOvertimeReport';
import ManagementLateEarlyReport from '@/pages/report/ManagementLateEarlyReport';
import ManagementActionReport from '@/pages/report/ManagementActionReport';
import ManagementSalaryReport from '@/pages/report/ManagementSalaryReport';
import ManagementTrainingReport from '@/pages/report/ManagementTrainingReport';


import EmployeesReport from '@/pages/report/EmployeesReport';
import PermanentEmployeeReport from '@/pages/report/PermanentEmployeeReport';
import EmployeeTimeTableReport from "@/pages/report/EmployeeTimeTableReport";
import EmployeeTimeTableMenpowerReport from "@/pages/report/EmployeeTimeTableMenpowerReport";

import SeparationReport from "@/pages/report/SeparationReport";
import StaffMovementReport from "@/pages/report/StaffMovementReport";

import SalarybyEachDepartments from "@/pages/report/SalarybyEachDepartments";




import ExecutiveReport from '@/pages/report/ExecutiveReport';
import ActionsReport from '@/pages/report/ACtionsReport';
import HeadCountReport from '@/pages/report/HeadCountReport';
import LeavesReport from '@/pages/report/LeavesReport';
import SalaryReport from '@/pages/report/SalaryReport';
import TurnoverReport from '@/pages/report/TurnoverReport';
import TrainingReport from '@/pages/report/TrainingReport';

import FixedAttendanceReport from '@/pages/attendances/FixedAttendanceReport';
import AccountReport from '@/pages/report/AccountReport';
import BankReport from '@/pages/report/BankReport';
import TaxReport from '@/pages/report/TaxReport';
import SSBReport from '@/pages/report/SSBReport';
import OvertimeReport from '@/pages/report/OvertimeReport';


import EmployeeTrainingReport from '@/pages/report/EmployeeTrainingReport';
import EmployeeEducationReport from '@/pages/report/EmployeeEducationReport';
import FixedAttendanceUserReport from '@/pages/report/FixedAttendanceUserReport';



import DocumentDownloadReport from '@/pages/report/DocumentDownloadReport';
import AttendanceLateEarlyReport from '@/pages/report/AttendanceLateEarlyReport';
import LeaveTakenBalanceReport from '@/pages/report/LeaveTakenBalanceReport';
import SummaryLeaveTakenBalanceReport from '@/pages/report/SummaryLeaveTakenBalanceReport';


import CurrentLeaveReport from '@/pages/report/CurrentLeaveReport';
import NewHiringReport from '@/pages/report/NewHiringReport';


import WorkingHistoryReport from '@/pages/report/WorkingHistoryReport';


// Core HR Component

import AdvanceSearchForm from '@/pages/employees/AdvanceSearchForm';

import EmployeeForm from '@/pages/employees/EmployeeForm';
import EmployeeList from '@/pages/employees/EmployeeList';
import EmployeeInfo from '@/pages/employees/EmployeeInfo';
import PersonalInfoForm from '@/pages/employees/PersonalInfoForm';
import TaxSSBForm from '@/pages/employees/TaxSSBForm';

import FamilyInfoList from '@/pages/employees/FamilyInfoList';
import FamilyInfoForm from '@/pages/employees/FamilyInfoForm';

import LanguageInfoList from '@/pages/employees/LanguageInfoList';
import LanguageInfoForm from '@/pages/employees/LanguageInfoForm';

import EducationInfoList from '@/pages/employees/EducationInfoList';
import EducationInfoForm from '@/pages/employees/EducationInfoForm';

import TrainingInfoList from '@/pages/employees/TrainingInfoList';
import TrainingInfoForm from '@/pages/employees/TrainingInfoForm';

import OccupationInfoList from '@/pages/employees/OccupationInfoList';
import OccupationInfoForm from '@/pages/employees/OccupationInfoForm';

import AdditionList from '@/pages/employees/AdditionList';
import AdditionForm from '@/pages/employees/AdditionForm';
import AdditionUpdateForm from '@/pages/employees/AdditionUpdateForm';

import DeductionList from '@/pages/employees/DeductionList';
import DeductionForm from '@/pages/employees/DeductionForm';

import EmployeeAttendancePolicyList from '@/pages/employees/EmployeeAttendancePolicyList';
import EmployeeAttendancePolicyForm from '@/pages/employees/EmployeeAttendancePolicyForm';

import EmployeeOvertimePolicyList from '@/pages/employees/EmployeeOvertimePolicyList';
import EmployeeOvertimePolicyForm from '@/pages/employees/EmployeeOvertimePolicyForm';

import EmployeeAllowancePolicyList from '@/pages/employees/EmployeeAllowancePolicyList';
import EmployeeAllowancePolicyForm from '@/pages/employees/EmployeeAllowancePolicyForm';

import EmployeeDocumentList from '@/pages/employees/EmployeeDocumentList';
import EmployeeDocumentForm from '@/pages/employees/EmployeeDocumentForm';

import BankInfoList from '@/pages/employees/BankInfoList';
import BankInfoForm from '@/pages/employees/BankInfoForm';

import ContractList from '@/pages/employees/ContractList';
import ContractForm from '@/pages/employees/ContractForm';

import WorkingHistoryList from '@/pages/employees/WorkingHistoryList';
import WorkingHistoryForm from '@/pages/employees/WorkingHistoryForm';


import InsuranceList from '@/pages/employees/InsuranceList';
import InsuranceForm from '@/pages/employees/InsuranceForm';

import ActionInfoList from '@/pages/employees/ActionInfoList';
import ActionInfoForm from '@/pages/employees/ActionInfoForm';

import EmployeeTimetableList from '@/pages/employees/TimetableList';
import EmployeeTimetableForm from '@/pages/employees/TimetableForm';



import EmployeeAttendanceList from '@/pages/employees/AttendanceList';
import EmployeeAttendanceForm from '@/pages/employees/AttendanceForm';
import EmployeeAttendanceInfo from '@/pages/employees/AttendanceInfo';

import EmployeeLeaveList from '@/pages/employees/LeaveList';
import EmployeeLeaveForm from '@/pages/employees/LeaveForm';
import LeaveBalance from '@/pages/employees/LeaveBalance';


import EmployeeOvertimeList from '@/pages/employees/OvertimeList';
import EmployeeOvertimeForm from '@/pages/employees/OvertimeForm';

import EmployeePayslipList from '@/pages/employees/PayslipList';
import EmployeePayslipDetail from '@/pages/employees/PayslipDetail';

import EmployeeApproverList from '@/pages/employees/ApproverList';
import EmployeeApprovalList from '@/pages/employees/ApprovalList';
import ActivityEmployeeList from '@/pages/employees/ActivityEmployeeList';


import AccountReportConfigurationForm from '@/pages/settings/accountReportConfiguration/AccountReportConfigurationForm';

import DocumentTypeForm from '@/pages/settings/documentTypes/DocumentTypeForm';
import DocumentTypeList from '@/pages/settings/documentTypes/DocumentTypeList';

import AttendanceLocationForm from '@/pages/settings/attendanceLocations/AttendanceLocationForm';
import AttendanceLocationList from '@/pages/settings/attendanceLocations/AttendanceLocationList';

import ContractStatusForm from '@/pages/settings/contractStatus/ContractStatusForm';
import ContractStatusList from '@/pages/settings/contractStatus/ContractStatusList';

import TransactionTypeForm from '@/pages/settings/transactionTypes/TransactionTypeForm';
import TransactionTypeList from '@/pages/settings/transactionTypes/TransactionTypeList';

import CompanyInfoForm from '@/pages/settings/companyInfo/CompanyInfoForm';
import CompanyInfoList from '@/pages/settings/companyInfo/CompanyInfoList';

import CompanyBranchForm from '@/pages/settings/companyBranch/CompanyBranchForm';
import CompanyBranchList from '@/pages/settings/companyBranch/CompanyBranchList';

import DepartmentForm from '@/pages/settings/departments/DepartmentForm';
import DepartmentList from '@/pages/settings/departments/DepartmentList';

import PositionForm from '@/pages/settings/positions/PositionForm';
import PositionList from '@/pages/settings/positions/PositionList';

import RankForm from '@/pages/settings/ranks/RankForm';
import RankList from '@/pages/settings/ranks/RankList';

import EmployeeCategoryForm from '@/pages/settings/employeeCategory/EmployeeCategoryForm';
import EmployeeCategoryList from '@/pages/settings/employeeCategory/EmployeeCategoryList';

import EmployeeGroupForm from '@/pages/settings/employeeGroup/EmployeeGroupForm';
import EmployeeGroupList from '@/pages/settings/employeeGroup/EmployeeGroupList';

import WorkingHoursForm from '@/pages/settings/workingHours/WorkingHoursForm';
import WorkingHoursList from '@/pages/settings/workingHours/WorkingHoursList';

import AttendanceRuleForm from '@/pages/settings/attendanceRule/AttendanceRuleForm';
import AttendanceRuleList from '@/pages/settings/attendanceRule/AttendanceRuleList';

import AttendancePolicyForm from '@/pages/settings/attendancePolicy/AttendancePolicyForm';
import AttendancePolicyList from '@/pages/settings/attendancePolicy/AttendancePolicyList';

import AttendancePolicyDetailForm from '@/pages/settings/attendancePolicy/AttendancePolicyDetailForm';
import AttendancePolicyDetailList from '@/pages/settings/attendancePolicy/AttendancePolicyDetailList';

import LateDeductionPolicyForm from '@/pages/settings/lateDeductionPolicy/LateDeductionPolicyForm';
import LateDeductionPolicyList from '@/pages/settings/lateDeductionPolicy/LateDeductionPolicyList';

import AllowanceTypeForm from '@/pages/settings/allowanceType/AllowanceTypeForm';
import AllowanceTypeList from '@/pages/settings/allowanceType/AllowanceTypeList';

import AllowanceRuleForm from '@/pages/settings/allowanceRule/AllowanceRuleForm';
import AllowanceRuleList from '@/pages/settings/allowanceRule/AllowanceRuleList';

import LeaveTypeForm from '@/pages/settings/leaveType/LeaveTypeForm';
import LeaveTypeList from '@/pages/settings/leaveType/LeaveTypeList';

import LeaveRuleForm from '@/pages/settings/leaveRule/LeaveRuleForm';
import LeaveRuleList from '@/pages/settings/leaveRule/LeaveRuleList';
import LeaveRuleDetailForm from '@/pages/settings/leaveRule/LeaveRuleDetailForm';
import LeaveRuleDetailList from '@/pages/settings/leaveRule/LeaveRuleDetailList';

import ActionTypeForm from '@/pages/settings/actionType/ActionTypeForm';
import ActionTypeList from '@/pages/settings/actionType/ActionTypeList';

import OvertimeRuleForm from '@/pages/settings/overtimeRule/OvertimeRuleForm';
import OvertimeRuleList from '@/pages/settings/overtimeRule/OvertimeRuleList';

import PermissionForm from '@/pages/settings/permissions/PermissionForm';
import PermissionList from '@/pages/settings/permissions/PermissionList';


import PendingLeavesList from '@/pages/leaves/PendingLeavesList';
import PendingLeavesDetailList from '@/pages/leaves/PendingLeavesDetailList';

import PendingOvertimeList from '@/pages/overtime/PendingOvertimeList';
import PendingOvertimeDetailList from '@/pages/overtime/PendingOvertimeDetailList';

import PendingAttendanceList from '@/pages/attendances/PendingAttendanceList';
import PendingAttendanceDetailList from '@/pages/attendances/PendingAttendanceDetailList';


import ApprovalGroupList from '@/pages/settings/approvalGroup/ApprovalGroupList';
import ApprovalGroupForm from '@/pages/settings/approvalGroup/ApprovalGroupForm';
import EmployeeApprovalGroupForm from "@/pages/settings/approvalGroup/EmployeeApprovalGroupForm"


// component: () => import(/* webpackChunkName: "demo" */ HolidayList)
const routes = [
    {
        path: '/',
        redirect: 'dashboard',
        component: DashboardLayout,
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: Dashboard,
                meta: {
                    title: 'dashboard',
                    crumbs: [
                        'dashboard'
                    ]
                }
            },
            
            {
                path: '/imports',
                name: 'imports',
                component: Imports,
                meta: {
                    title: 'imports.title.imports',
                    crumbs: [
                        'imports'
                    ]
                }
            },

            {
                path: '/announcements',
                name: 'listAnnouncement',
                component: AnnouncementList,
                meta: {
                    title: 'announcements.title.list',
                    crumbs: [
                        'listAnnouncement'
                    ]
                }
            },
            {
                path: '/announcements/add',
                name: 'createAnnouncement',
                component: AnnouncementForm,
                meta: {
                    title: 'announcements.title.create',
                    crumbs: [
                        'listAnnouncement', 'createAnnouncement'
                    ]
                }
            },
            {
                path: '/announcements/edit/:_id',
                name: 'viewAnnouncement',
                component: AnnouncementForm,
                props: true,
                meta: {
                    title: 'announcements.title.update',
                    crumbs: [
                        'listAnnouncement', 'viewAnnouncement'
                    ]
                }
            },

            {
                path: '/announcements/department/:_id',
                name: 'viewAnnouncementDepartmentSummary',
                component: AnnouncementDepartmentSummaryList,
                props: true,
                meta: {
                    title: 'announcements.title.summary',
                    crumbs: [
                        'listAnnouncement', 'viewAnnouncementDepartmentSummary'
                    ]
                }
            },

            {
                path: '/announcements/department/:_id/:department_id',
                name: 'viewAnnouncementEmployees',
                component: AnnouncementEmployeesList,
                props: true,
                meta: {
                    title: 'announcements.title.summary',
                    crumbs: [
                        'listAnnouncement', 'viewAnnouncementDepartmentSummary', 'viewAnnouncementEmployees'
                    ]
                }
            },

            {
                path: '/announcements/employees/:_id',
                name: 'viewAnnouncementEmployeesSummary',
                component: AnnouncementEmployeesSummaryList,
                props: true,
                meta: {
                    title: 'announcements.title.summary',
                    crumbs: [
                        'listAnnouncement', 'viewAnnouncementEmployeesSummary'
                    ]
                }
            },




            {
                path: '/trainings',
                name: 'listHrTraining',
                component: HrTrainingList,
                meta: {
                    title: 'hr_training.title.list',
                    crumbs: [
                        'listHrTraining'
                    ]
                }
            },
            {
                path: '/trainings/add',
                name: 'createHrTraining',
                component: HrTrainingForm,
                meta: {
                    title: 'hr_training.title.create',
                    crumbs: [
                        'listHrTraining', 'createHrTraining'
                    ]
                }
            },
            {
                path: '/trainings/:_id',
                name: 'viewHrTraining',
                component: HrTrainingForm,
                props: true,
                meta: {
                    title: 'hr_training.title.update',
                    crumbs: [
                        'listHrTraining', 'viewHrTraining'
                    ]
                }
            },
            

            {
                path: '/employees/search',
                name: 'employeeAdvanceSearch',
                component: AdvanceSearchForm,
                meta: {
                    title: 'employees.title.advance_serach',
                    crumbs: [
                        'listEmployee', 'employeeAdvanceSearch'
                    ]
                }
            },

            {
                path: '/employees',
                name: 'listEmployee',
                component: EmployeeList,
                meta: {
                    title: 'employees.title.list',
                    crumbs: [
                        'listEmployee'
                    ]
                }
            },
            {
                path: '/employees/add',
                name: 'createEmployee',
                component: EmployeeForm,
                meta: {
                    title: 'employees.title.create',
                    crumbs: [
                        'listEmployee', 'createEmployee'
                    ]
                }
            },


            {
                path: '/contracts',
                name: 'contractManageList',
                component: ContractManageList,
                meta: {
                    title: 'contracts.title.list',
                    crumbs: [
                        'contractManageList'
                    ]
                }
            },
            {
                path: '/contracts/add',
                name: 'createManageContract',
                component: ContractForm,
                meta: {
                    title: 'contracts.title.create',
                    crumbs: [
                        'contractManageList', 'createContract'
                    ]
                }
            },
            {
                path: '/contracts/:_id',
                name: 'viewManageContract',
                component: ContractForm,
                props: true,
                meta: {
                    title: 'contracts.title.update',
                    crumbs: [
                        'contractManageList', 'viewContract'
                    ]
                }
            },

            {
                path: '/working-history',
                name: 'workingHistoryManageList',
                component: WorkingHistoryManageList,
                meta: {
                    title: 'working_history.title.list',
                    crumbs: [
                        'workingHistoryManageList'
                    ]
                }
            },

            {
                path: '/working-history/add',
                name: 'createManageWorkingHistory',
                component: WorkingHistoryForm,
                meta: {
                    title: 'working_history.title.create',
                    crumbs: [
                        'workingHistoryManageList', 'createWorkingHistory'
                    ]
                }
            },
            {
                path: '/working-history/:_id',
                name: 'viewManageWorkingHistory',
                component: WorkingHistoryForm,
                props: true,
                meta: {
                    title: 'working_history.title.update',
                    crumbs: [
                        'workingHistoryManageList', 'viewWorkingHistory'
                    ]
                }
            },

            
            {
                path: '/actions',
                name: 'actionsManageList',
                component: ActionsManageList,
                meta: {
                    title: 'action_info.title.list',
                    crumbs: [
                        'actionsManageList'
                    ]
                }
            },
            
            {
                path: '/actions/add',
                name: 'createManageAction',
                component: ActionInfoForm,
                meta: {
                    title: 'action_info.title.create',
                    crumbs: [
                        'actionsManageList', 'createActionInfo'
                    ]
                }
            },
            {
                path: '/actions/:_id',
                name: 'viewManageAction',
                component: ActionInfoForm,
                props: true,
                meta: {
                    title: 'action_info.title.update',
                    crumbs: [
                        'actionsManageList', 'viewActionInfo'
                    ]
                }
            },

            {
                path: '/additions',
                name: 'additionsManageList',
                component: OtherAdditionsManageList,
                meta: {
                    title: 'addition.title.list',
                    crumbs: [
                        'additionsManageList'
                    ]
                }
            },
            {
                path: '/additions/add',
                name: 'createManageOtherAddition',
                component: AdditionForm,
                meta: {
                    title: 'addition.title.create',
                    crumbs: [
                        'additionsManageList', 'createAddition'
                    ]
                }
            },
            {
                path: '/additions/:_id',
                name: 'viewManageOtherAddition',
                component: AdditionUpdateForm,
                props: true,
                meta: {
                    title: 'addition.title.update',
                    crumbs: [
                        'additionsManageList', 'viewAddition'
                    ]
                }
            },

            

            {
                path: '/deductions',
                name: 'deductionsManageList',
                component: OtherDeductionsManageList,
                meta: {
                    title: 'deduction.title.list',
                    crumbs: [
                        'deductionsManageList'
                    ]
                }
            },
            
            {
                path: '/deductions/add',
                name: 'createManageOtherDeduction',
                component: DeductionForm,
                meta: {
                    title: 'deduction.title.create',
                    crumbs: [
                        'deductionsManageList', 'createDeduction'
                    ]
                }
            },
            {
                path: '/deductions/:_id',
                name: 'viewManageOtherDeduction',
                component: DeductionForm,
                props: true,
                meta: {
                    title: 'deduction.title.update',
                    crumbs: [
                        'deductionsManageList', 'viewDeduction'
                    ]
                }
            },


            {
                path: '/attendances',
                name: 'listManageAttendances',
                component: AttendanceManageList,
                meta: {
                    title: 'employee_attendances.title.list',
                    crumbs: [
                        'listManageAttendances'
                    ]
                }
            },
            {
                path: '/attendances/view/:_id',
                name: 'viewManageAttendance',
                component: EmployeeAttendanceInfo,
                props: true,
                meta: {
                    title: 'employee_attendances.title.list',
                    crumbs: [
                        'listManageAttendances','viewManageAttendance'
                    ]
                }
            },
            {
                path: '/attendances/add',
                name: 'createManageAttendance',
                component: EmployeeAttendanceForm,
                meta: {
                    title: 'employee_attendances.title.create',
                    crumbs: [
                        'listManageAttendances', 'createAttendance'
                    ]
                }
            },
            {
                path: '/attendances/update/:_id',
                name: 'viewAttendanceForUpdate',
                component: EmployeeAttendanceForm,
                props: true,
                meta: {
                    title: 'employee_attendances.title.update',
                    crumbs: [
                        'listManageAttendances', 'viewAttendance'
                    ]
                }
            },
            
            
            {
                path: '/check_attendance_list',
                name: 'checkMonthlyAttendanceList',
                component: MonthlyAttendanceLogList,
                meta: {
                    title: 'employee_attendances.title.checkMonthlyAttendanceList',
                    crumbs: [
                        'checkMonthlyAttendanceList'
                    ]
                }
            },
            {
                path: '/check_attendance',
                name: 'checkMonthlyAttendanceForm',
                component: MonthlyAttendanceForm,
                meta: {
                    title: 'employee_attendances.title.checkMonthlyAttendanceForm',
                    crumbs: [
                        'checkMonthlyAttendanceForm'
                    ]
                }
            },


            {
                path: '/leaves',
                name: 'listManageLeaves',
                component: LeaveManageList,
                meta: {
                    title: 'leaves.title.list',
                    crumbs: [
                        'listManageLeaves'
                    ]
                }
            },
            
            {
                path: '/leaves/add',
                name: 'createManageLeave',
                component: EmployeeLeaveForm,
                meta: {
                    title: 'leaves.title.create',
                    crumbs: [
                        'listManageLeaves', 'createEmployeeLeave'
                    ]
                }
            },
            {
                path: '/leaves/:_id',
                name: 'viewManageLeave',
                component: EmployeeLeaveForm,
                props: true,
                meta: {
                    title: 'leaves.title.update',
                    crumbs: [
                        'listManageLeaves', 'viewEmployeeLeave'
                    ]
                }
            },

            {
                path: '/overtime',
                name: 'listManageOvertime',
                component: OvertimeManageList,
                meta: {
                    title: 'employee_overtime.title.list',
                    crumbs: [
                        'listManageOvertime'
                    ]
                }
            },
            
            
            {
                path: '/overtime/add',
                name: 'createManageOvertime',
                component: EmployeeOvertimeForm,
                meta: {
                    title: 'employee_overtime.title.create',
                    crumbs: [
                        'listManageOvertime', 'createEmployeeOvertime'
                    ]
                }
            },
            {
                path: '/overtime/:_id',
                name: 'viewManageOvertime',
                component: EmployeeOvertimeForm,
                props: true,
                meta: {
                    title: 'employee_overtime.title.update',
                    crumbs: [
                        'listManageOvertime', 'viewEmployeeOvertime'
                    ]
                }
            },


            {
                path: '/employees/:employee_id',
                component: EmployeeLayout,
                props: true,
                children: [
                    {
                        path: '/employees/:employee_id',
                        name: 'viewEmployeeInfo',
                        component: EmployeeInfo,
                        props: true,
                        meta: {
                            title: 'employee_info.title.view',
                            crumbs: [
                                'listEmployee', 'viewEmployeeInfo'
                            ]
                        }
                    },

                    {
                        path: '/employees/:employee_id/personal-info',
                        name: 'viewEmployeePersonalInfo',
                        component: PersonalInfoForm,
                        props: true,
                        meta: {
                            title: 'personal_info.title.update',
                            crumbs: [
                                'listEmployee', 'viewEmployeePersonalInfo'
                            ]
                        }
                    },


                    {
                        path: '/employees/:employee_id/tax-ssb',
                        name: 'viewTaxSSB',
                        component: TaxSSBForm,
                        props: true,
                        meta: {
                            title: 'tax_ssb.title.update',
                            crumbs: [
                                'listEmployee', 'viewTaxSSB'
                            ]
                        }
                    },




                    {
                        path: '/employees/:employee_id/FamilyInfo',
                        name: 'familyInfoList',
                        component: FamilyInfoList,
                        props: true,
                        meta: {
                            title: 'family_info.title.list',
                            crumbs: [
                                'listEmployee', 'familyInfoList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/FamilyInfo/add',
                        name: 'createFamilyInfo',
                        component: FamilyInfoForm,
                        props: true,
                        meta: {
                            title: 'family_info.title.create',
                            crumbs: [
                                'listEmployee', 'familyInfoList', 'createFamilyInfo'
                            ],
                            crumbsParams: { 'familyInfoList': { employee_id: "int" } }

                        }
                    },
                    {
                        path: '/employees/:employee_id/FamilyInfo/:_id',
                        name: 'viewFamilyInfo',
                        component: FamilyInfoForm,
                        props: true,
                        meta: {
                            title: 'family_info.title.update',
                            crumbs: [
                                'listEmployee', 'familyInfoList', 'viewFamilyInfo'
                            ],
                            crumbsParams: { 'familyInfoList': { employee_id: "int" } }
                        }
                    },

                    {
                        path: '/employees/:employee_id/LanguageInfo',
                        name: 'languageInfoList',
                        component: LanguageInfoList,
                        props: true,
                        meta: {
                            title: 'lang_info.title.list',
                            crumbs: [
                                'listEmployee', 'languageInfoList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/LanguageInfo/add',
                        name: 'createLanguageInfo',
                        component: LanguageInfoForm,
                        props: true,
                        meta: {
                            title: 'lang_info.title.list',
                            crumbs: [
                                'listEmployee', 'languageInfoList', 'createLanguageInfo'
                            ],
                            crumbsParams: { 'languageInfoList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/LanguageInfo/:_id',
                        name: 'viewLanguageInfo',
                        component: LanguageInfoForm,
                        props: true,
                        meta: {
                            title: 'lang_info.title.list',
                            crumbs: [
                                'listEmployee', 'languageInfoList', 'viewLanguageInfo'
                            ],
                            crumbsParams: { 'languageInfoList': { employee_id: "int" } }
                        }
                    },

                    {
                        path: '/employees/:employee_id/EducationInfo',
                        name: 'educationInfoList',
                        component: EducationInfoList,
                        props: true,
                        meta: {
                            title: 'education_info.title.list',
                            crumbs: [
                                'listEmployee', 'educationInfoList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/EducationInfo/add',
                        name: 'createEducationInfo',
                        component: EducationInfoForm,
                        props: true,
                        meta: {
                            title: 'education_info.title.create',
                            crumbs: [
                                'listEmployee', 'educationInfoList', 'createEducationInfo'
                            ],
                            crumbsParams: { 'educationInfoList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/EducationInfo/:_id',
                        name: 'viewEducationInfo',
                        component: EducationInfoForm,
                        props: true,
                        meta: {
                            title: 'education_info.title.update',
                            crumbs: [
                                'listEmployee', 'educationInfoList', 'viewEducationInfo'
                            ],
                            crumbsParams: { 'educationInfoList': { employee_id: "int" } }
                        }
                    },


                    {
                        path: '/employees/:employee_id/TrainingInfo',
                        name: 'trainingInfoList',
                        component: TrainingInfoList,
                        props: true,
                        meta: {
                            title: 'training_info.title.list',
                            crumbs: [
                                'listEmployee', 'trainingInfoList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/TrainingInfo/add',
                        name: 'createTrainingInfo',
                        component: TrainingInfoForm,
                        props: true,
                        meta: {
                            title: 'training_info.title.create',
                            crumbs: [
                                'listEmployee', 'trainingInfoList', 'createTrainingInfo'
                            ],
                            crumbsParams: { 'trainingInfoList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/TrainingInfo/:_id',
                        name: 'viewTrainingInfo',
                        component: TrainingInfoForm,
                        props: true,
                        meta: {
                            title: 'training_info.title.update',
                            crumbs: [
                                'listEmployee', 'trainingInfoList', 'viewTrainingInfo'
                            ],
                            crumbsParams: { 'trainingInfoList': { employee_id: "int" } }
                        }
                    },

                    {
                        path: '/employees/:employee_id/OccupationInfo',
                        name: 'occupationInfoList',
                        component: OccupationInfoList,
                        props: true,
                        meta: {
                            title: 'employee_occupation.title.list',
                            crumbs: [
                                'listEmployee', 'occupationInfoList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/OccupationInfo/add',
                        name: 'createOccupationInfo',
                        component: OccupationInfoForm,
                        props: true,
                        meta: {
                            title: 'employee_occupation.title.list',
                            crumbs: [
                                'listEmployee', 'occupationInfoList', 'createOccupationInfo'
                            ],
                            crumbsParams: { 'occupationInfoList': { employee_id: "int" } }
                        }

                    },
                    {
                        path: '/employees/:employee_id/OccupationInfo/:_id',
                        name: 'viewOccupationInfo',
                        component: OccupationInfoForm,
                        props: true,
                        meta: {
                            title: 'employee_occupation.title.list',
                            crumbs: [
                                'listEmployee', 'occupationInfoList', 'viewOccupationInfo'
                            ],
                            crumbsParams: { 'occupationInfoList': { employee_id: "int" } }
                        }
                    },

                    {
                        path: '/employees/:employee_id/BankInfo',
                        name: 'bankInfoList',
                        component: BankInfoList,
                        props: true,
                        meta: {
                            title: 'bank_info.title.list',
                            crumbs: [
                                'listEmployee', 'bankInfoList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/BankInfo/add',
                        name: 'createBankInfo',
                        component: BankInfoForm,
                        props: true,
                        meta: {
                            title: 'bank_info.title.list',
                            crumbs: [
                                'listEmployee', 'bankInfoList', 'createBankInfo'
                            ],
                            crumbsParams: { 'bankInfoList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/BankInfo/:_id',
                        name: 'viewBankInfo',
                        component: BankInfoForm,
                        props: true,
                        meta: {
                            title: 'bank_info.title.list',
                            crumbs: [
                                'listEmployee', 'bankInfoList', 'viewBankInfo'
                            ],
                            crumbsParams: { 'bankInfoList': { employee_id: "int" } }
                        }
                    },

                    {
                        path: '/employees/:employee_id/Contract',
                        name: 'contractList',
                        component: ContractList,
                        props: true,
                        meta: {
                            title: 'contracts.title.list',
                            crumbs: [
                                'listEmployee', 'contractList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/Contract/add',
                        name: 'createContract',
                        component: ContractForm,
                        props: true,
                        meta: {
                            title: 'contracts.title.create',
                            crumbs: [
                                'listEmployee', 'contractList', 'createContract'
                            ],
                            crumbsParams: { 'contractList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/Contract/:_id',
                        name: 'viewContract',
                        component: ContractForm,
                        props: true,
                        meta: {
                            title: 'contracts.title.update',
                            crumbs: [
                                'listEmployee', 'contractList', 'viewContract'
                            ],
                            crumbsParams: { 'contractList': { employee_id: "int" } }
                        }
                    },

                    



                    {
                        path: '/employee/:employee_id/WorkingHistory',
                        name: 'workingHistoryList',
                        component: WorkingHistoryList,
                        props: true,
                        meta: {
                            title: 'working_history.title.list',
                            crumbs: [
                                'listEmployee', 'workingHistoryList'
                            ]
                        }
                    },
                    {
                        path: '/employee/:employee_id/WorkingHistory/add',
                        name: 'createWorkingHistory',
                        component: WorkingHistoryForm,
                        props: true,
                        meta: {
                            title: 'working_history.title.create',
                            crumbs: [
                                'listEmployee', 'workingHistoryList', 'createWorkingHistory'
                            ],
                            crumbsParams: { 'workingHistoryList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employee/:employee_id/WorkingHistory/:_id',
                        name: 'viewWorkingHistory',
                        component: WorkingHistoryForm,
                        props: true,
                        meta: {
                            title: 'working_history.title.update',
                            crumbs: [
                                'listEmployee', 'workingHistoryList', 'viewWorkingHistory'
                            ],
                            crumbsParams: { 'workingHistoryList': { employee_id: "int" } }
                        }
                    },

                    //////////////

                    {
                        path: '/employee/:employee_id/insurance',
                        name: 'insuranceList',
                        component: InsuranceList,
                        props: true,
                        meta: {
                            title: 'insurance.title.list',
                            crumbs: [
                                'listEmployee', 'insuranceList'
                            ]
                        }
                    },
                    {
                        path: '/employee/:employee_id/insurance/add',
                        name: 'createInsurance',
                        component: InsuranceForm,
                        props: true,
                        meta: {
                            title: 'insurance.title.create',
                            crumbs: [
                                'listEmployee', 'insuranceList', 'createInsurance'
                            ],
                            crumbsParams: { 'insuranceList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employee/:employee_id/insurance/:_id',
                        name: 'viewInsurance',
                        component: InsuranceForm,
                        props: true,
                        meta: {
                            title: 'insurance.title.update',
                            crumbs: [
                                'listEmployee', 'insuranceList', 'viewInsurance'
                            ],
                            crumbsParams: { 'insuranceList': { employee_id: "int" } }
                        }
                    },

                    ////////////////

                    {
                        path: '/employee/:employee_id/ActionInfo',
                        name: 'actionInfoList',
                        component: ActionInfoList,
                        props: true,
                        meta: {
                            title: 'action_info.title.list',
                            crumbs: [
                                'listEmployee', 'actionInfoList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/ActionInfo/add',
                        name: 'createActionInfo',
                        component: ActionInfoForm,
                        props: true,
                        meta: {
                            title: 'action_info.title.list',
                            crumbs: [
                                'listEmployee', 'actionInfoList', 'createActionInfo'
                            ],
                            crumbsParams: { 'actionInfoList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/ActionInfo/:_id',
                        name: 'viewActionInfo',
                        component: ActionInfoForm,
                        props: true,
                        meta: {
                            title: 'action_info.title.list',
                            crumbs: [
                                'listEmployee', 'actionInfoList', 'viewActionInfo'
                            ],
                            crumbsParams: { 'actionInfoList': { employee_id: "int" } }
                        }
                    },


                    {
                        path: '/employees/:employee_id/additional',
                        name: 'additionList',
                        component: AdditionList,
                        props: true,
                        meta: {
                            title: 'addition.title.list',
                            crumbs: [
                                'listEmployee', 'additionList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/Addition/add',
                        name: 'createAddition',
                        component: AdditionForm,
                        props: true,
                        meta: {
                            title: 'addition.title.create',
                            crumbs: [
                                'listEmployee', 'additionList', 'createAddition'
                            ],
                            crumbsParams: { 'additionList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/Addition/:_id',
                        name: 'viewAddition',
                        component: AdditionUpdateForm,
                        props: true,
                        meta: {
                            title: 'addition.title.update',
                            crumbs: [
                                'listEmployee', 'additionList', 'viewAddition'
                            ],
                            crumbsParams: { 'additionList': { employee_id: "int" } }
                        }
                    },

                    {
                        path: '/employees/:employee_id/Deduction',
                        name: 'deductionList',
                        component: DeductionList,
                        props: true,
                        meta: {
                            title: 'deduction.title.list',
                            crumbs: [
                                'listEmployee', 'deductionList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/Deduction/add',
                        name: 'createDeduction',
                        component: DeductionForm,
                        props: true,
                        meta: {
                            title: 'deduction.title.create',
                            crumbs: [
                                'listEmployee', 'deductionList', 'createDeduction'
                            ],
                            crumbsParams: { 'deductionList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/Deduction/:_id',
                        name: 'viewDeduction',
                        component: DeductionForm,
                        props: true,
                        meta: {
                            title: 'deduction.title.update',
                            crumbs: [
                                'listEmployee', 'deductionList', 'viewDeduction'
                            ],
                            crumbsParams: { 'deductionList': { employee_id: "int" } }
                        }
                    },



                    {
                        path: '/employees/:employee_id/attendance-policy',
                        name: 'employeeAttendancePolicyList',
                        component: EmployeeAttendancePolicyList,
                        props: true,
                        meta: {
                            title: 'employee_attendance_policy.title.list',
                            crumbs: [
                                'listEmployee', 'employeeAttendancePolicyList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/attendance-policy/add',
                        name: 'createEmployeeAttendancePolicy',
                        component: EmployeeAttendancePolicyForm,
                        props: true,
                        meta: {
                            title: 'employee_attendance_policy.title.create',
                            crumbs: [
                                'listEmployee', 'employeeAttendancePolicyList', 'createEmployeeAttendancePolicy'
                            ],
                            crumbsParams: { 'employeeAttendancePolicyList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/attendance-policy/:_id',
                        name: 'viewEmployeeAttendancePolicy',
                        component: EmployeeAttendancePolicyForm,
                        props: true,
                        meta: {
                            title: 'employee_attendance_policy.title.update',
                            crumbs: [
                                'listEmployee', 'employeeAttendancePolicyList', 'viewEmployeeAttendancePolicy'
                            ],
                            crumbsParams: { 'employeeAttendancePolicyList': { employee_id: "int" } }
                        }
                    },



                    {
                        path: '/employees/:employee_id/overtime-policy',
                        name: 'employeeOvertimePolicyList',
                        component: EmployeeOvertimePolicyList,
                        props: true,
                        meta: {
                            title: 'employee_overtime_policy.title.list',
                            crumbs: [
                                'listEmployee', 'employeeOvertimePolicyList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/overtime-policy/add',
                        name: 'createEmployeeOvertimePolicy',
                        component: EmployeeOvertimePolicyForm,
                        props: true,
                        meta: {
                            title: 'employee_overtime_policy.title.create',
                            crumbs: [
                                'listEmployee', 'employeeOvertimePolicyList', 'createEmployeeOvertimePolicy'
                            ],
                            crumbsParams: { 'employeeOvertimePolicyList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/overtime-policy/:_id',
                        name: 'viewEmployeeOvertimePolicy',
                        component: EmployeeOvertimePolicyForm,
                        props: true,
                        meta: {
                            title: 'employee_overtime_policy.title.update',
                            crumbs: [
                                'listEmployee', 'employeeOvertimePolicyList', 'viewEmployeeOvertimePolicy'
                            ],
                            crumbsParams: { 'employeeOvertimePolicyList': { employee_id: "int" } }
                        }
                    },



                    {
                        path: '/employees/:employee_id/allowance-policy',
                        name: 'employeeAllowancePolicyList',
                        component: EmployeeAllowancePolicyList,
                        props: true,
                        meta: {
                            title: 'employee_allowance_policy.title.list',
                            crumbs: [
                                'listEmployee', 'employeeAllowancePolicyList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/allowance-policy/add',
                        name: 'createEmployeeAllowancePolicy',
                        component: EmployeeAllowancePolicyForm,
                        props: true,
                        meta: {
                            title: 'employee_allowance_policy.title.create',
                            crumbs: [
                                'listEmployee', 'employeeAllowancePolicyList', 'createEmployeeAllowancePolicy'
                            ],
                            crumbsParams: { 'employeeAllowancePolicyList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/allowance-policy/:_id',
                        name: 'viewEmployeeAllowancePolicy',
                        component: EmployeeAllowancePolicyForm,
                        props: true,
                        meta: {
                            title: 'employee_allowance_policy.title.update',
                            crumbs: [
                                'listEmployee', 'employeeAllowancePolicyList', 'viewEmployeeAllowancePolicy'
                            ],
                            crumbsParams: { 'employeeAllowancePolicyList': { employee_id: "int" } }
                        }
                    },



                    {
                        path: '/employees/:employee_id/document',
                        name: 'employeeDocumentList',
                        component: EmployeeDocumentList,
                        props: true,
                        meta: {
                            title: 'employee_document.title.list',
                            crumbs: [
                                'listEmployee', 'employeeDocumentList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/document/add',
                        name: 'createEmployeeDocument',
                        component: EmployeeDocumentForm,
                        props: true,
                        meta: {
                            title: 'employee_document.title.create',
                            crumbs: [
                                'listEmployee', 'employeeDocumentList', 'createEmployeeDocument'
                            ],
                            crumbsParams: { 'employeeDocumentList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/document/:_id',
                        name: 'viewEmployeeDocument',
                        component: EmployeeDocumentForm,
                        props: true,
                        meta: {
                            title: 'employee_document.title.update',
                            crumbs: [
                                'listEmployee', 'employeeDocumentList', 'viewEmployeeDocument'
                            ],
                            crumbsParams: { 'employeeDocumentList': { employee_id: "int" } }
                        }
                    },



                    {
                        path: '/employees/:employee_id/timetable',
                        name: 'employeeTimetableList',
                        component: EmployeeTimetableList,
                        props: true,
                        meta: {
                            title: 'employee_timetable.title.list',
                            crumbs: [
                                'listEmployee', 'employeeTimetableList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/timetable/:_id',
                        name: 'viewEmployeeTimetableInfo',
                        component: EmployeeTimetableForm,
                        props: true,
                        meta: {
                            title: 'employee_timetable.title.update',
                            crumbs: [
                                'listEmployee', 'employeeTimetableList', 'viewEmployeeTimetableInfo'
                            ],
                            crumbsParams: { 'employeeTimetableList': { employee_id: "int" } }
                        }
                    },






                    {
                        path: '/employees/:employee_id/attendances',
                        name: 'employeeAttendanceList',
                        component: EmployeeAttendanceList,
                        props: true,
                        meta: {
                            title: 'employee_attendances.title.list',
                            crumbs: [
                                'listEmployee', 'employeeAttendanceList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/attendances/add',
                        name: 'createEmployeeAttendance',
                        component: EmployeeAttendanceForm,
                        props: true,
                        meta: {
                            title: 'employee_attendances.title.create',
                            crumbs: [
                                'listEmployee', 'employeeAttendanceList', 'createEmployeeAttendance'
                            ],
                            crumbsParams: { 'employeeAttendanceList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/attendances/update/:_id',
                        name: 'viewEmployeeAttendance',
                        component: EmployeeAttendanceForm,
                        props: true,
                        meta: {
                            title: 'employee_attendances.title.update',
                            crumbs: [
                                'listEmployee', 'employeeAttendanceList', 'viewEmployeeAttendance'
                            ],
                            crumbsParams: { 'employeeAttendanceList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/attendances/:_id',
                        name: 'viewEmployeeAttendanceInfo',
                        component: EmployeeAttendanceInfo,
                        props: true,
                        meta: {
                            title: 'employee_attendances.title.update',
                            crumbs: [
                                'listEmployee', 'employeeAttendanceList', 'viewEmployeeAttendanceInfo'
                            ],
                            crumbsParams: { 'employeeAttendanceList': { employee_id: "int" } }
                        }
                    },




                    {
                        path: '/employees/:employee_id/leaves',
                        name: 'employeeLeaveList',
                        component: EmployeeLeaveList,
                        props: true,
                        meta: {
                            title: 'employee_leaves.title.list',
                            crumbs: [
                                'listEmployee', 'employeeLeaveList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/leaves/add',
                        name: 'createEmployeeLeave',
                        component: EmployeeLeaveForm,
                        props: true,
                        meta: {
                            title: 'employee_leaves.title.create',
                            crumbs: [
                                'listEmployee', 'employeeLeaveList', 'createEmployeeLeave'
                            ],
                            crumbsParams: { 'employeeLeaveList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/leaves/:_id',
                        name: 'viewEmployeeLeave',
                        component: EmployeeLeaveForm,
                        props: true,
                        meta: {
                            title: 'employee_leaves.title.update',
                            crumbs: [
                                'listEmployee', 'employeeLeaveList', 'viewEmployeeLeave'
                            ],
                            crumbsParams: { 'employeeLeaveList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/leaves-balance',
                        name: 'employeeLeaveBalance',
                        component: LeaveBalance,
                        props: true,
                        meta: {
                            title: 'employee_leaves.title.balance',
                            crumbs: [
                                'listEmployee', 'employeeLeaveBalance'
                            ]
                        }
                    },



                    {
                        path: '/employees/:employee_id/overtime',
                        name: 'employeeOvertimeList',
                        component: EmployeeOvertimeList,
                        props: true,
                        meta: {
                            title: 'employee_overtime.title.list',
                            crumbs: [
                                'listEmployee', 'employeeOvertimeList'
                            ]
                        }
                    },
                    {
                        path: '/employees/:employee_id/overtime/add',
                        name: 'createEmployeeOvertime',
                        component: EmployeeOvertimeForm,
                        props: true,
                        meta: {
                            title: 'employee_overtime.title.create',
                            crumbs: [
                                'listEmployee', 'employeeOvertimeList', 'createEmployeeOvertime'
                            ],
                            crumbsParams: { 'employeeOvertimeList': { employee_id: "int" } }
                        }
                    },
                    {
                        path: '/employees/:employee_id/overtime/:_id',
                        name: 'viewEmployeeOvertime',
                        component: EmployeeOvertimeForm,
                        props: true,
                        meta: {
                            title: 'employee_overtime.title.update',
                            crumbs: [
                                'listEmployee', 'employeeOvertimeList', 'viewEmployeeOvertime'
                            ],
                            crumbsParams: { 'employeeOvertimeList': { employee_id: "int" } }
                        }
                    },


                    {
                        path: '/employees/:employee_id/approvers',
                        name: 'employeeApproverList',
                        component: EmployeeApproverList,
                        props: true,
                        meta: {
                            title: 'employee_approvers.title.list',
                            crumbs: [
                                'listEmployee', 'employeeApproverList'
                            ]
                        }
                    },

                    
                    {
                        path: '/employees/:employee_id/approvals',
                        name: 'employeeApprovalList',
                        component: EmployeeApprovalList,
                        props: true,
                        meta: {
                            title: 'employee_approvals.title.list',
                            crumbs: [
                                'listEmployee', 'employeeApprovalList'
                            ]
                        }
                    },

                    
                    
                    {
                        path: '/employees/:employee_id/my_employees',
                        name: 'activityEmployeeList',
                        component: ActivityEmployeeList,
                        props: true,
                        meta: {
                            title: 'activityEmployeeList.title.list',
                            crumbs: [
                                'listEmployee', 'activityEmployeeList'
                            ]
                        }
                    },

                    
                    {
                        path: '/employees/:employee_id/payslips',
                        name: 'employeePayslipList',
                        component: EmployeePayslipList,
                        props: true,
                        meta: {
                            title: 'payslips.title.list',
                            crumbs: [
                                'listEmployee', 'employeePayslipList'
                            ]
                        }
                    },
                    
                    {
                        path: '/employees/:employee_id/payslips/:_id',
                        name: 'employeePayslipDetail',
                        component: EmployeePayslipDetail,
                        props: true,
                        meta: {
                            title: 'payslips.title.detail',
                            crumbs: [
                                'listEmployee', 'employeePayslipList', 'employeePayslipDetail'
                            ],
                            crumbsParams: { 'employeePayslipList': { employee_id: "int" } }
                        }
                    },

                ]
            },




            {
                path: '/settings',
                name: 'settings',
                component: Settings,
                meta: {
                    title: 'menu.settings.config',
                    crumbs: [
                        'settings'
                    ]
                }
            },


            {
                path: '/attendances/fixing',
                name: 'fixedAttendanceReport',
                component: FixedAttendanceReport,
                meta: {
                    title: 'employee_attendances.title.fixedAttendanceReport',
                    crumbs: [
                        'listManageAttendances', "fixedAttendanceReport"
                    ]
                }
            },

            {
                path: '/attendances/duty-assignment',
                name: 'createManualDutyAssignment',
                component: ManualDutyAssignmentEntryForm,
                meta: {
                    title: 'duty_assignment.title.create',
                    crumbs: [
                        'createManualDutyAssignment'
                    ]
                }
            },
            {
                path: '/attendances/pending',
                name: 'listPendingAttendance',
                component: PendingAttendanceList,
                meta: {
                    title: 'employee_attendances.title.pending',
                    crumbs: [
                        'listPendingAttendance'
                    ]
                }
            },

            {
                path: '/attendances/pending/:_id',
                name: 'listPendingAttendanceDetail',
                component: PendingAttendanceDetailList,
                props: true,
                meta: {
                    title: 'employee_attendances.title.pending_detail',
                    crumbs: [
                        'listPendingAttendance', 'listPendingAttendanceDetail'
                    ]
                }
            }, 

            {
                path: '/leaves/pending',
                name: 'listPendingLeaves',
                component: PendingLeavesList,
                meta: {
                    title: 'leaves.title.pending',
                    crumbs: [
                        'listPendingLeaves'
                    ]
                }
            },

            {
                path: '/leaves/pending/:_id',
                name: 'listPendingLeavesDetail',
                component: PendingLeavesDetailList,
                props: true,
                meta: {
                    title: 'leaves.title.pending_detail',
                    crumbs: [
                        'listPendingLeaves', 'listPendingLeavesDetail'
                    ]
                }
            }, 


            {
                path: '/overtime/pending',
                name: 'listPendingOvertime',
                component: PendingOvertimeList,
                meta: {
                    title: 'overtime.title.pending',
                    crumbs: [
                        'listPendingOvertime'
                    ]
                }
            },

            {
                path: '/overtime/pending/:_id',
                name: 'listPendingOvertimeDetail',
                component: PendingOvertimeDetailList,
                props: true,
                meta: {
                    title: 'overtime.title.pending_detail',
                    crumbs: [
                        'listPendingOvertime', 'listPendingOvertimeDetail'
                    ]
                }
            }, 


            

            
            {
                path: '/approvals/attendance',
                name: 'listAttendanceApproval',
                component: AttendanceApprovalList,
                meta: {
                    title: 'approvals.title.attendance',
                    crumbs: [
                        'listAttendanceApproval'
                    ]
                }
            },

            
            {
                path: '/approvals/attendance/:_id',
                name: 'viewAttendanceApproval',
                component: AttendanceApprovalForm,
                props: true,
                meta: {
                    title: 'approvals.title.attendance_view',
                    crumbs: [
                        // 'listAttendanceApproval', 'viewAttendanceApproval'
                        'listAttendanceApproval'
                    ]
                }
            },

            

            
            {
                path: '/approvals/leaves',
                name: 'listLeaveApproval',
                component: LeaveApprovalList,
                meta: {
                    title: 'approvals.title.leave',
                    crumbs: [
                        'listLeaveApproval'
                    ]
                }
            },

            
            {
                path: '/approvals/leaves/:_id',
                name: 'viewLeaveApproval',
                component: LeaveApprovalForm,
                props: true,
                meta: {
                    title: 'approvals.title.leave_view',
                    crumbs: [
                        'listLeaveApproval', 'viewLeaveApproval'
                    ]
                }
            },
                        

            
            {
                path: '/approvals/overtimes',
                name: 'listOvertimeApproval',
                component: OvertimeApprovalList,
                meta: {
                    title: 'approvals.title.overtime',
                    crumbs: [
                        'listOvertimeApproval'
                    ]
                }
            },

            
            {
                path: '/approvals/overtimes/:_id',
                name: 'viewOvertimeApproval',
                component: OvertimeApprovalForm,
                props: true,
                meta: {
                    title: 'approvals.title.overtime_view',
                    crumbs: [
                        'listOvertimeApproval', 'viewOvertimeApproval'
                    ]
                }
            },

            {
                path: '/settings/holidays',
                name: 'listHoliday',
                component: HolidayList,
                meta: {
                    title: 'holidays.title.list',
                    crumbs: [
                        'settings', 'listHoliday'
                    ]
                }
            },
            {
                path: '/settings/holiday/add',
                name: 'createHoliday',
                component: HolidayForm,
                meta: {
                    title: 'holidays.title.create',
                    crumbs: [
                        'settings', 'listHoliday', 'createHoliday'
                    ]
                }
            },
            {
                path: '/settings/holiday/:_id',
                name: 'viewHoliday',
                component: HolidayForm,
                props: true,
                meta: {
                    title: 'holidays.title.update',
                    crumbs: [
                        'settings', 'listHoliday', 'viewHoliday'
                    ]
                }
            },

            {
                path: '/settings/report/account-config',
                name: 'accountReportConfiguration',
                component: AccountReportConfigurationForm,
                meta: {
                    title: 'Account Report Configuration',
                    crumbs: [
                        'settings', 'accountReportConfiguration'
                    ]
                }
            },

            {
                path: '/settings/documents',
                name: 'listDocumentType',
                component: DocumentTypeList,
                meta: {
                    title: 'document_types.title.list',
                    crumbs: [
                        'settings', 'listDocumentType'
                    ]
                }
            },
            {
                path: '/settings/document/add',
                name: 'createDocumentType',
                component: DocumentTypeForm,
                meta: {
                    title: 'document_types.title.create',
                    crumbs: [
                        'settings', 'listDocumentType', 'createDocumentType'
                    ]
                }
            },
            {
                path: '/settings/document/:_id',
                name: 'viewDocumentType',
                component: DocumentTypeForm,
                props: true,
                meta: {
                    title: 'document_types.title.update',
                    crumbs: [
                        'settings', 'listDocumentType', 'viewDocumentType'
                    ]
                }
            },


            {
                path: '/settings/locations',
                name: 'listAttendanceLocation',
                component: AttendanceLocationList,
                meta: {
                    title: 'attendance_locations.title.list',
                    crumbs: [
                        'settings', 'listAttendanceLocation'
                    ]
                }
            },
            {
                path: '/settings/locations/add',
                name: 'createAttendanceLocation',
                component: AttendanceLocationForm,
                meta: {
                    title: 'attendance_locations.title.create',
                    crumbs: [
                        'settings', 'listAttendanceLocation', 'createAttendanceLocation'
                    ]
                }
            },
            {
                path: '/settings/locations/:_id',
                name: 'viewAttendanceLocation',
                component: AttendanceLocationForm,
                props: true,
                meta: {
                    title: 'attendance_locations.title.update',
                    crumbs: [
                        'settings', 'listAttendanceLocation', 'viewAttendanceLocation'
                    ]
                }
            },



            {
                path: '/settings/contract-status',
                name: 'listContractStatus',
                component: ContractStatusList,
                meta: {
                    title: 'contract_status.title.list',
                    crumbs: [
                        'settings', 'listContractStatus'
                    ]
                }
            },
            {
                path: '/settings/contract-status/add',
                name: 'createContractStatus',
                component: ContractStatusForm,
                meta: {
                    title: 'contract_status.title.create',
                    crumbs: [
                        'settings', 'listContractStatus', 'createContractStatus'
                    ]
                }
            },
            {
                path: '/settings/contract-status/:_id',
                name: 'viewContractStatus',
                component: ContractStatusForm,
                props: true,
                meta: {
                    title: 'contract_status.title.update',
                    crumbs: [
                        'settings', 'listContractStatus', 'viewContractStatus'
                    ]
                }
            },




            {
                path: '/settings/companies',
                name: 'listCompanyInfo',
                component: CompanyInfoList,
                meta: {
                    title: 'company_info.title.list',
                    crumbs: [
                        'settings', 'listCompanyInfo'
                    ]
                }
            },
            {
                path: '/settings/company/add',
                name: 'createCompanyInfo',
                component: CompanyInfoForm,
                meta: {
                    title: 'company_info.title.create',
                    crumbs: [
                        'settings', 'listCompanyInfo', 'createCompanyInfo'
                    ]
                }
            },
            {
                path: '/settings/company/:_id',
                name: 'viewCompanyInfo',
                component: CompanyInfoForm,
                props: true,
                meta: {
                    title: 'company_info.title.update',
                    crumbs: [
                        'settings', 'listCompanyInfo', 'viewCompanyInfo'
                    ]
                }
            },

            {
                path: '/settings/branches',
                name: 'listCompanyBranch',
                component: CompanyBranchList,
                meta: {
                    title: 'company_branchs.title.list',
                    crumbs: [
                        'settings', 'listCompanyBranch'
                    ]
                }
            },
            {
                path: '/settings/branch/add',
                name: 'createCompanyBranch',
                component: CompanyBranchForm,
                meta: {
                    title: 'company_branchs.title.create',
                    crumbs: [
                        'settings', 'listCompanyBranch', 'createCompanyBranch'
                    ]
                }
            },
            {
                path: '/settings/branch/:_id',
                name: 'viewCompanyBranch',
                component: CompanyBranchForm,
                props: true,
                meta: {
                    title: 'company_branchs.title.update',
                    crumbs: [
                        'settings', 'listCompanyBranch', 'viewCompanyBranch'
                    ]
                }
            },

            {
                path: '/settings/departments',
                name: 'listDepartment',
                component: DepartmentList,
                meta: {
                    title: 'departments.title.list',
                    crumbs: [
                        'settings', 'listDepartment'
                    ]
                }
            },
            {
                path: '/settings/department/add',
                name: 'createDepartment',
                component: DepartmentForm,
                meta: {
                    title: 'departments.title.create',
                    crumbs: [
                        'settings', 'listDepartment', 'createDepartment'
                    ]
                }
            },
            {
                path: '/settings/department/:_id',
                name: 'viewDepartment',
                component: DepartmentForm,
                props: true,
                meta: {
                    title: 'departments.title.update',
                    crumbs: [
                        'settings', 'listDepartment', 'viewDepartment'
                    ]
                }
            },

            {
                path: '/settings/transactions',
                name: 'listTransactionType',
                component: TransactionTypeList,
                meta: {
                    title: 'transaction_types.title.list',
                    crumbs: [
                        'settings', 'listTransactionType'
                    ]
                }
            },
            {
                path: '/settings/transaction/add',
                name: 'createTransactionType',
                component: TransactionTypeForm,
                meta: {
                    title: 'transaction_types.title.create',
                    crumbs: [
                        'settings', 'listTransactionType', 'createTransactionType'
                    ]
                }
            },
            {
                path: '/settings/transaction/:_id',
                name: 'viewTransactionType',
                component: TransactionTypeForm,
                props: true,
                meta: {
                    title: 'transaction_types.title.update',
                    crumbs: [
                        'settings', 'listTransactionType', 'viewTransactionType'
                    ]
                }
            },



            {
                path: '/settings/positions',
                name: 'listPosition',
                component: PositionList,
                meta: {
                    title: 'jon_positions.title.list',
                    crumbs: [
                        'settings', 'listPosition'
                    ]
                }
            },
            {
                path: '/settings/position/add',
                name: 'createPosition',
                component: PositionForm,
                meta: {
                    title: 'jon_positions.title.create',
                    crumbs: [
                        'settings', 'listPosition', 'createPosition'
                    ]
                }
            },
            {
                path: '/settings/position/:_id',
                name: 'viewPosition',
                component: PositionForm,
                props: true,
                meta: {
                    title: 'jon_positions.title.update',
                    crumbs: [
                        'settings', 'listPosition', 'viewPosition'
                    ]
                }
            },

            {
                path: '/settings/ranks',
                name: 'listRank',
                component: RankList,
                meta: {
                    title: 'rank_types.title.list',
                    crumbs: [
                        'settings', 'listRank'
                    ]
                }
            },
            {
                path: '/settings/rank/add',
                name: 'createRank',
                component: RankForm,
                meta: {
                    title: 'rank_types.title.create',
                    crumbs: [
                        'settings', 'listRank', 'createRank'
                    ]
                }
            },
            {
                path: '/settings/position/:_id',
                name: 'viewRank',
                component: RankForm,
                props: true,
                meta: {
                    title: 'rank_types.title.update',
                    crumbs: [
                        'settings', 'listRank', 'viewRank'
                    ]
                }
            },

            {
                path: '/settings/employee-categories',
                name: 'listEmployeeCategory',
                component: EmployeeCategoryList,
                meta: {
                    title: 'employee_category_lists.title.list',
                    crumbs: [
                        'settings', 'listEmployeeCategory'
                    ]
                }
            },
            {
                path: '/settings/employee-category/add',
                name: 'createEmployeeCategory',
                component: EmployeeCategoryForm,
                meta: {
                    title: 'employee_category_lists.title.create',
                    crumbs: [
                        'settings', 'listEmployeeCategory', 'createEmployeeCategory'
                    ]
                }
            },
            {
                path: '/settings/employee-category/:_id',
                name: 'viewEmployeeCategory',
                component: EmployeeCategoryForm,
                props: true,
                meta: {
                    title: 'employee_category_lists.title.update',
                    crumbs: [
                        'settings', 'listEmployeeCategory', 'viewEmployeeCategory'
                    ]
                }
            },

            {
                path: '/settings/employee-groups',
                name: 'listEmployeeGroup',
                component: EmployeeGroupList,
                meta: {
                    title: 'employee_groups.title.update',
                    crumbs: [
                        'settings', 'listEmployeeGroup', 'viewEmployeeGroup'
                    ]
                }
            },
            {
                path: '/settings/employee-group/add',
                name: 'createEmployeeGroup',
                component: EmployeeGroupForm,
                meta: {
                    title: 'employee_groups.title.update',
                    crumbs: [
                        'settings', 'listEmployeeGroup', 'viewEmployeeGroup'
                    ]
                }
            },
            {
                path: '/settings/employee-group/:_id',
                name: 'viewEmployeeGroup',
                component: EmployeeGroupForm,
                props: true,
                meta: {
                    title: 'employee_groups.title.update',
                    crumbs: [
                        'settings', 'listEmployeeGroup', 'viewEmployeeGroup'
                    ]
                }
            },


            {
                path: '/settings/approvals',
                name: 'approvalWorkflow',
                component: ApprovalWorkflowForm,
                meta: {
                    title: 'approval_workflow.title.create',
                    crumbs: [
                        'settings', 'approvalWorkflow'
                    ]
                }
            },


            // {
                
            //     path: '/activities',
            //     name: 'listEmployeeActivity',
            //     component: EmployeeActivityList,
            //     meta: {
            //         title: 'activity.title.listEmployeeActivity',
            //         crumbs: [
            //             'listEmployeeActivity'
            //         ]
            //     }
            // },
            

            {
                path: '/add_activity_leader',
                name: 'addActivityLeader',
                component: ActivityLeaderForm,
                meta: {
                    title: 'activityEmployeeList.title.addActivityLeader',
                    crumbs: [
                        'addActivityLeader'
                    ]
                }
            },



            {
                path: '/settings/working-hours',
                name: 'listWorkingHours',
                component: WorkingHoursList,
                meta: {
                    title: 'working_hours.title.list',
                    crumbs: [
                        'settings', 'listWorkingHours'
                    ]
                }
            },
            {
                path: '/settings/working-hour/add',
                name: 'createWorkingHours',
                component: WorkingHoursForm,
                meta: {
                    title: 'working_hours.title.create',
                    crumbs: [
                        'settings', 'listWorkingHours', 'createWorkingHours'
                    ]
                }
            },
            {
                path: '/settings/working-hour/:_id',
                name: 'viewWorkingHours',
                component: WorkingHoursForm,
                props: true,
                meta: {
                    title: 'working_hours.title.update',
                    crumbs: [
                        'settings', 'listWorkingHours', 'viewWorkingHours'
                    ]
                }
            },

            {
                path: '/settings/attendance-rules',
                name: 'listAttendanceRule',
                component: AttendanceRuleList,
                meta: {
                    title: 'attendance_rules.title.list',
                    crumbs: [
                        'settings', 'listAttendanceRule'
                    ]
                }
            },
            {
                path: '/settings/attendance-rule/add',
                name: 'createAttendanceRule',
                component: AttendanceRuleForm,
                meta: {
                    title: 'attendance_rules.title.create',
                    crumbs: [
                        'settings', 'listAttendanceRule', 'createAttendanceRule'
                    ]
                }
            },
            {
                path: '/settings/attendance-rule/:_id',
                name: 'viewAttendanceRule',
                component: AttendanceRuleForm,
                props: true,
                meta: {
                    title: 'attendance_rules.title.update',
                    crumbs: [
                        'settings', 'listAttendanceRule', 'viewAttendanceRule'
                    ]
                }
            },

            {
                path: '/settings/attendance-policies',
                name: 'listAttendancePolicy',
                component: AttendancePolicyList,
                meta: {
                    title: 'attendance_policies.title.list',
                    crumbs: [
                        'settings', 'listAttendancePolicy'
                    ]
                }
            },
            {
                path: '/settings/attendance-policy/add',
                name: 'createAttendancePolicy',
                component: AttendancePolicyForm,
                meta: {
                    title: 'attendance_policies.title.create',
                    crumbs: [
                        'settings', 'listAttendancePolicy', 'createAttendancePolicy'
                    ]
                }
            },
            {
                path: '/settings/attendance-policy/:_id',
                name: 'viewAttendancePolicy',
                component: AttendancePolicyForm,
                props: true,
                meta: {
                    title: 'attendance_policies.title.update',
                    crumbs: [
                        'settings', 'listAttendancePolicy', 'viewAttendancePolicy'
                    ]
                }
            },

            {
                path: '/settings/attendance-policies-detail/:_id',
                name: 'listAttendancePolicyDetail',
                component: AttendancePolicyDetailList,
                props: true,
                meta: {
                    title: 'attendance_policy_details.title.list',
                    crumbs: [
                        'settings', 'listAttendancePolicy', 'listAttendancePolicyDetail'
                    ]
                }
            },
            {
                path: '/settings/attendance-policy-detail/add/:ap_id',
                name: 'createAttendancePolicyDetail',
                component: AttendancePolicyDetailForm,
                props: true,
                meta: {
                    title: 'attendance_policy_details.title.create',
                    crumbs: [
                        'settings', 'listAttendancePolicy', 'listAttendancePolicyDetail', 'createAttendancePolicyDetail'
                    ],
                    crumbsParams: { 'listAttendancePolicyDetail': { _id: "int" } }
                    //replace in component stage
                }
            },
            {
                path: '/settings/attendance-policy-detail/edit/:_id',
                name: 'viewAttendancePolicyDetail',
                component: AttendancePolicyDetailForm,
                props: true,
                meta: {
                    title: 'attendance_policy_details.title.update',
                    crumbs: [
                        'settings', 'listAttendancePolicy', 'listAttendancePolicyDetail', 'viewAttendancePolicyDetail'
                    ],
                    crumbsParams: { 'listAttendancePolicyDetail': { _id: "int" } }
                    //replace in component stage
                }
            },

            {
                path: '/settings/late-policies',
                name: 'listLateDeductionPolicy',
                component: LateDeductionPolicyList,
                meta: {
                    title: 'late_deduction_policy.title.list',
                    crumbs: [
                        'settings', 'listLateDeductionPolicy'
                    ]
                }
            },
            {
                path: '/settings/late-policy/add',
                name: 'createLateDeductionPolicy',
                component: LateDeductionPolicyForm,
                meta: {
                    title: 'late_deduction_policy.title.create',
                    crumbs: [
                        'settings', 'listLateDeductionPolicy', 'createLateDeductionPolicy'
                    ]
                }
            },
            {
                path: '/settings/late-policy/:_id',
                name: 'viewLateDeductionPolicy',
                component: LateDeductionPolicyForm,
                props: true,
                meta: {
                    title: 'late_deduction_policy.title.update',
                    crumbs: [
                        'settings', 'listLateDeductionPolicy', 'viewLateDeductionPolicy'
                    ]
                }
            },

            {
                path: '/settings/allowance-types',
                name: 'listAllowanceType',
                component: AllowanceTypeList,
                meta: {
                    title: 'allowance_types.title.list',
                    crumbs: [
                        'settings', 'listAllowanceType'
                    ]
                }
            },
            {
                path: '/settings/allowance-type/add',
                name: 'createAllowanceType',
                component: AllowanceTypeForm,
                meta: {
                    title: 'allowance_types.title.create',
                    crumbs: [
                        'settings', 'listAllowanceType', 'createAllowanceType'
                    ]
                }
            },
            {
                path: '/settings/allowance-type/:_id',
                name: 'viewAllowanceType',
                component: AllowanceTypeForm,
                props: true,
                meta: {
                    title: 'allowance_types.title.update',
                    crumbs: [
                        'settings', 'listAllowanceType', 'viewAllowanceType'
                    ]
                }
            },

            {
                path: '/settings/allowance-rules',
                name: 'listAllowanceRule',
                component: AllowanceRuleList,
                meta: {
                    title: 'allowance_rules.title.list',
                    crumbs: [
                        'settings', 'listAllowanceRule'
                    ]
                }
            },
            {
                path: '/settings/allowance-rule/add',
                name: 'createAllowanceRule',
                component: AllowanceRuleForm,
                meta: {
                    title: 'allowance_rules.title.create',
                    crumbs: [
                        'settings', 'listAllowanceRule', 'createAllowanceRule'
                    ]
                }
            },
            {
                path: '/settings/allowance-rule/:_id',
                name: 'viewAllowanceRule',
                component: AllowanceRuleForm,
                props: true,
                meta: {
                    title: 'allowance_rules.title.update',
                    crumbs: [
                        'settings', 'listAllowanceRule', 'viewAllowanceRule'
                    ]
                }
            },

            {
                path: '/settings/leave-types',
                name: 'listLeaveType',
                component: LeaveTypeList,
                meta: {
                    title: 'leave_type.title.list',
                    crumbs: [
                        'settings', 'listLeaveType'
                    ]
                }
            },
            {
                path: '/settings/leave-type/add',
                name: 'createLeaveType',
                component: LeaveTypeForm,
                meta: {
                    title: 'leave_type.title.create',
                    crumbs: [
                        'settings', 'listLeaveType', 'createLeaveType'
                    ]
                }
            },
            {
                path: '/settings/leave-type/:_id',
                name: 'viewLeaveType',
                component: LeaveTypeForm,
                props: true,
                meta: {
                    title: 'leave_type.title.update',
                    crumbs: [
                        'settings', 'listLeaveType', 'viewLeaveType'
                    ]
                }
            },

            {
                path: '/settings/leave-rules',
                name: 'listLeaveRule',
                component: LeaveRuleList,
                meta: {
                    title: 'leave_rule.title.list',
                    crumbs: [
                        'settings', 'listLeaveRule'
                    ]
                }
            },
            {
                path: '/settings/leave-rule/add',
                name: 'createLeaveRule',
                component: LeaveRuleForm,
                meta: {
                    title: 'leave_rule.title.create',
                    crumbs: [
                        'settings', 'listLeaveRule', 'createLeaveRule'
                    ]
                }
            },
            {
                path: '/settings/leave-rule/:_id',
                name: 'viewLeaveRule',
                component: LeaveRuleForm,
                props: true,
                meta: {
                    title: 'leave_rule.title.update',
                    crumbs: [
                        'settings', 'listLeaveRule', 'viewLeaveRule'
                    ]
                }
            },

            {
                path: '/settings/leave-rule-detail/:_id',
                name: 'listLeaveRuleDetail',
                component: LeaveRuleDetailList,
                props: true,
                meta: {
                    title: 'leave_rule_details.title.list',
                    crumbs: [
                        'settings', 'listLeaveRule', 'listLeaveRuleDetail'
                    ]
                }
            },
            {
                path: '/settings/leave-rule-detail/add/:leave_policy_id',
                name: 'createLeaveRuleDetail',
                component: LeaveRuleDetailForm,
                props: true,
                meta: {
                    title: 'leave_rule_details.title.create',
                    crumbs: [
                        'settings', 'listLeaveRule', 'listLeaveRuleDetail', 'createLeaveRuleDetail'
                    ],
                    crumbsParams: { 'listLeaveRuleDetail': { _id: "int" } }
                    //replace in component stage
                }
            },
            {
                path: '/settings/leave-rule-detail/edit/:_id',
                name: 'viewLeaveRuleDetail',
                component: LeaveRuleDetailForm,
                props: true,
                meta: {
                    title: 'leave_rule_details.title.update',
                    crumbs: [
                        'settings', 'listLeaveRule', 'listLeaveRuleDetail', 'viewLeaveRuleDetail'
                    ],
                    crumbsParams: { 'listLeaveRuleDetail': { _id: "int" } }
                    //replace in component stage
                }
            },






            {
                path: '/settings/overtime-rules',
                name: 'listOvertimeRule',
                component: OvertimeRuleList,
                meta: {
                    title: 'overtime_rule.title.list',
                    crumbs: [
                        'settings', 'listOvertimeRule'
                    ]
                }
            },
            {
                path: '/settings/overtime-rule/add',
                name: 'createOvertimeRule',
                component: OvertimeRuleForm,
                meta: {
                    title: 'overtime_rule.title.create',
                    crumbs: [
                        'settings', 'listOvertimeRule', 'createOvertimeRule'
                    ]
                }
            },
            {
                path: '/settings/overtime-rule/:_id',
                name: 'viewOvertimeRule',
                component: OvertimeRuleForm,
                props: true,
                meta: {
                    title: 'overtime_rule.title.update',
                    crumbs: [
                        'settings', 'listOvertimeRule', 'viewOvertimeRule'
                    ]
                }
            },

            {
                path: '/settings/action-types',
                name: 'listActionType',
                component: ActionTypeList,
                meta: {
                    title: 'action_types.title.list',
                    crumbs: [
                        'settings', 'listActionType'
                    ]
                }
            },
            {
                path: '/settings/action-type/add',
                name: 'createActionType',
                component: ActionTypeForm,
                meta: {
                    title: 'action_types.title.create',
                    crumbs: [
                        'settings', 'listActionType', 'createActionType'
                    ]
                }
            },
            {
                path: '/settings/action-type/:_id',
                name: 'viewActionType',
                component: ActionTypeForm,
                props: true,
                meta: {
                    title: 'action_types.title.update',
                    crumbs: [
                        'settings', 'listActionType', 'viewActionType'
                    ]
                }
            },



            {
                path: '/settings/permission',
                name: 'listPermission',
                component: PermissionList,
                meta: {
                    title: 'permission.title.list',
                    crumbs: [
                        'settings', 'listPermission'
                    ]
                }
            },
            {
                path: '/settings/permission/add',
                name: 'createPermission',
                component: PermissionForm,
                meta: {
                    title: 'permission.title.create',
                    crumbs: [
                        'settings', 'listPermission', 'createPermission'
                    ]
                }
            },
            {
                path: '/settings/permission/:_id',
                name: 'viewPermission',
                component: PermissionForm,
                props: true,
                meta: {
                    title: 'permission.title.update',
                    crumbs: [
                        'settings', 'listPermission', 'viewPermission'
                    ]
                }
            },

            {
                path: '/settings/approver-groups',
                name: 'listApprovalGroup',
                component: ApprovalGroupList,
                meta: {
                    title: 'approval_group.title.list',
                    crumbs: [
                        'settings', 'listApprovalGroup'
                    ]
                }
            },
            {
                path: '/settings/approver-groups/add',
                name: 'createApprovalGroup',
                component: ApprovalGroupForm,
                meta: {
                    title: 'approval_group.title.create',
                    crumbs: [
                        'settings', 'listApprovalGroup', 'createApprovalGroup'
                    ]
                }
            },
            {
                path: '/settings/approver-groups/:_id',
                name: 'viewApprovalGroup',
                component: ApprovalGroupForm,
                props: true,
                meta: {
                    title: 'approval_group.title.update',
                    crumbs: [
                        'settings', 'listApprovalGroup', 'viewApprovalGroup'
                    ]
                }
            },
            {
                path: '/settings/approver-groups-assignment/:apge_apg_id',
                name: 'addEmployeeIntoApprovalGroup',
                component: EmployeeApprovalGroupForm,
                props: true,
                meta: {
                    title: 'approval_group.title.addEmployeeIntoApprovalGroup',
                    crumbs: [
                        'settings', 'listApprovalGroup', 'addEmployeeIntoApprovalGroup'
                    ]
                }
            },


            {
                path: '/payrolls/create',
                name: 'createPayroll',
                component: PayrollForm,
                props: true,
                meta: {
                    title: 'payrolls.title.create',
                    crumbs: [
                         'createPayroll',
                    ]
                }
            },
            {
                path: '/payrolls/queue-list',
                name: 'payrollRequestQueueList',
                component: PayrollRequestQueueList,
                props: true,
                meta: {
                    title: 'payrolls.title.payrollRequestQueueList',
                    crumbs: [
                         'payrollRequestQueueList',
                    ]
                }
            },
            
            {
                path: '/payrolls/payslips',
                name: 'payrollList',
                component: PayslipList,
                meta: {
                    title: 'payrolls.title.payrollList',
                    crumbs: [
                         'payrollList',
                    ]
                }
            },
            {
                path: '/payrolls/payslips/:_id',
                name: 'payslipDetail',
                component: EmployeePayslipDetail,
                props: true,
                meta: {
                    title: 'payslips.title.detail',
                    crumbs: [
                        'employeePayslipList', 'employeePayslipDetail'
                    ]
                }
            },


            {
                path: '/payrolls/publishing',
                name: 'payrollPublishing',
                component: PayslipPublishing,
                meta: {
                    title: 'payrolls.title.payrollPublishing',
                    crumbs: [
                         'payrollPublishing',
                    ]
                }
            },




            // {
            //     path: '/report/executive',
            //     name: 'executiveReport',
            //     component: ExecutiveReport,
            //     meta: {
            //         title: 'Executive Report',
            //         crumbs: [
            //             'report', "executiveReport"
            //         ]
            //     }
            // },

            {
                path: '/report',
                name: 'report',
                component: Report,
                meta: {
                    title: 'report.title.report',
                    crumbs: [
                        'report'
                    ]
                }
            },
            //////////////////////////////////////////
            {
                path: '/report/employees',
                name: 'employeesReport',
                component: EmployeesReport,
                meta: {
                    title: 'report.title.employees',
                    crumbs: [
                        'report', "employeesReport"
                    ]
                }
            },
            {
                path: '/report/permanentemployees',
                name: 'permanentEmployeeReport',
                component: PermanentEmployeeReport,
                meta: {
                    title: 'report.title.permanentEmployeeReport',
                    crumbs: [
                        'report', "permanentEmployeeReport"
                    ]
                }
            },
            {
                path: '/report/timetable',
                name: 'employeeTimeTableReport',
                component: EmployeeTimeTableReport,
                meta: {
                    title: 'report.title.employeeTimeTableReport',
                    crumbs: [
                        'report', "employeeTimeTableReport"
                    ]
                }
            },
            
            {
                path: '/report/timetable-menpower',
                name: 'employeeTimeTableMenpowerReport',
                component: EmployeeTimeTableMenpowerReport,
                meta: {
                    title: 'report.title.employeeTimeTableMenpowerReport',
                    crumbs: [
                        'report', "employeeTimeTableMenpowerReport"
                    ]
                }
            },

            
            
            {
                path: '/report/staff-movement',
                name: 'staffMovementReport',
                component: StaffMovementReport,
                meta: {
                    title: 'report.title.staffMovementReport',
                    crumbs: [
                        'report', "staffMovementReport"
                    ]
                }
            },
            {
                path: '/report/separation',
                name: 'separationReport',
                component: SeparationReport,
                meta: {
                    title: 'report.title.separationReport',
                    crumbs: [
                        'report', "separationReport"
                    ]
                }
            },

            {
                path: '/report/salary-detail',
                name: 'salarybyEachDepartmentsReport',
                component: SalarybyEachDepartments,
                meta: {
                    title: 'report.title.salarybyEachDepartments',
                    crumbs: [
                        'report', "salarybyEachDepartments"
                    ]
                }
            },



            {
                path: '/iso',
                name: 'listISO',
                component: ISOList,
                meta: {
                    title: 'iso.title.list',
                    crumbs: [
                        'listISO'
                    ]
                }
            },
            {
                path: '/iso/add',
                name: 'createISO',
                component: ISOForm,
                meta: {
                    title: 'iso.title.create',
                    crumbs: [
                        'listISO', 'createISO'
                    ]
                }
            },
            {
                path: '/iso/edit/:_id',
                name: 'viewISO',
                component: ISOForm,
                props: true,
                meta: {
                    title: 'iso.title.update',
                    crumbs: [
                        'listISO', 'viewISO'
                    ]
                }
            },
            //////////////////////////////////////////

            
            {
                path: '/report/sbg-report',
                name: 'SBGUsageReport',
                component: SBGUsageReport,
                meta: {
                    title: 'report.title.SBGUsageReport',
                    crumbs: [
                        'report', "SBGUsageReport"
                    ]
                }
            },
            
            {
                path: '/report/usage-report',
                name: 'employeeUsageReport',
                component: EmployeeUsageReport,
                meta: {
                    title: 'report.title.employeeUsageReport',
                    crumbs: [
                        'report', "employeeUsageReport"
                    ]
                }
            },
            {
                path: '/report/management-report',
                name: 'managementReport',
                component: ManagementReport,
                meta: {
                    title: 'report.title.management_report',
                    crumbs: [
                        'report', "managementReport"
                    ]
                }
            },

            {
                path: '/report/management-report/:year/:month',
                component: ManagementReportNavigation,
                props: true,
                children: [
                    {
                        path: '/report/management-report/:year/:month/summary',
                        name: 'managementSummaryReport',
                        component: ManagementSummaryReport,
                        props: true,
                        meta: {
                            title: 'report.title.management_summary_report',
                            crumbs: [
                                'report', 'managementReport', 'managementSummaryReport'
                            ]
                        }
                    },
        
                    {
                        path: '/report/management-report/:year/:month/headcount',
                        name: 'managementHeadcountReport',
                        component: ManagementHeadcountReport,
                        props: true,
                        meta: {
                            title: 'report.title.management_headcount_report',
                            crumbs: [
                                'report', 'managementReport', 'managementHeadcountReport'
                            ]
                        }
                    },
                    {
                        path: '/report/management-report/:year/:month/turnover',
                        name: 'managementTurnoverReport',
                        component: ManagementTurnoverReport,
                        props: true,
                        meta: {
                            title: 'report.title.management_turnover_report',
                            crumbs: [
                                'report', 'managementReport', 'managementTurnoverReport'
                            ]
                        }
                    },
                    
                    {
                        path: '/report/management-report/:year/:month/leave',
                        name: 'managementLeaveReport',
                        component: ManagementLeaveReport,
                        props: true,
                        meta: {
                            title: 'report.title.management_leave_report',
                            crumbs: [
                                'report', 'managementReport', 'managementLeaveReport'
                            ]
                        }
                    },

                    
                    {
                        path: '/report/management-report/:year/:month/overtime',
                        name: 'managementOvertimeReport',
                        component: ManagementOvertimeReport,
                        props: true,
                        meta: {
                            title: 'report.title.management_overtime_report',
                            crumbs: [
                                'report', 'managementReport', 'managementOvertimeReport'
                            ]
                        }
                    },

                    
                    {
                        path: '/report/management-report/:year/:month/late-early',
                        name: 'managementLateEarlyReport',
                        component: ManagementLateEarlyReport,
                        props: true,
                        meta: {
                            title: 'report.title.management_late_early_report',
                            crumbs: [
                                'report', 'managementReport', 'managementLateEarlyReport'
                            ]
                        }
                    },
                    
                    {
                        path: '/report/management-report/:year/:month/action',
                        name: 'managementActionReport',
                        component: ManagementActionReport,
                        props: true,
                        meta: {
                            title: 'report.title.management_action_report',
                            crumbs: [
                                'report', 'managementReport', 'managementActionReport'
                            ]
                        }
                    },
                    
                    {
                        path: '/report/management-report/:year/:month/salary',
                        name: 'managementSalaryReport',
                        component: ManagementSalaryReport,
                        props: true,
                        meta: {
                            title: 'report.title.management_salary_report',
                            crumbs: [
                                'report', 'managementReport', 'managementSalaryReport'
                            ]
                        }
                    },

                    
                    {
                        path: '/report/management-report/:year/:month/training',
                        name: 'managementTrainingReport',
                        component: ManagementTrainingReport,
                        props: true,
                        meta: {
                            title: 'report.title.management_training_report',
                            crumbs: [
                                'report', 'managementReport', 'managementTrainingReport'
                            ]
                        }
                    },
                ]
            },

            

            {
                path: '/report/executive',
                name: 'executiveReport',
                component: ExecutiveReport,
                meta: {
                    title: 'report.title.executive_summary',
                    crumbs: [
                        'report', "executiveReport"
                    ]
                }
            },
            {
                path: '/report/salary',
                name: 'salaryReport',
                component: SalaryReport,
                meta: {
                    title: 'report.title.salary_summary',
                    crumbs: [
                        'report', "salaryReport"
                    ]
                }
            },
            {
                path: '/report/head-count',
                name: 'headCountReport',
                component: HeadCountReport,
                meta: {
                    title: 'report.title.head_count',
                    crumbs: [
                        'report', "headCountReport"
                    ]
                }
            },
            {
                path: '/report/actions',
                name: 'actionsReport',
                component: ActionsReport,
                meta: {
                    title: 'report.title.actions',
                    crumbs: [
                        'report', "actionsReport"
                    ]
                }
            },
            {
                path: '/report/leaves',
                name: 'leavesReport',
                component: LeavesReport,
                meta: {
                    title: 'report.title.leaves',
                    crumbs: [
                        'report', "leavesReport"
                    ]
                }
            },
            {
                path: '/report/turnover',
                name: 'turnoverReport',
                component: TurnoverReport,
                meta: {
                    title: 'report.title.turnover',
                    crumbs: [
                        'report', "turnoverReport"
                    ]
                }
            },
            {
                path: '/report/training',
                name: 'trainingReport',
                component: TrainingReport,
                meta: {
                    title: 'report.title.training',
                    crumbs: [
                        'report', "trainingReport"
                    ]
                }
            },

            {
                path: '/report/employee-documents',
                name: 'documentDownloadReport',
                component: DocumentDownloadReport,
                meta: {
                    title: 'report.title.documentDownloadReport',
                    crumbs: [
                        'report', "documentDownloadReport"
                    ]
                }
            },
            {
                path: '/report/attendance-late-early',
                name: 'attendanceLateEarlyReport',
                component: AttendanceLateEarlyReport,
                meta: {
                    title: 'report.title.late_report',
                    crumbs: [
                        'report', "attendanceLateEarlyReport"
                    ]
                }
            },
            {
                path: '/report/leave-taken-balance',
                name: 'leaveTakenBalanceReport',
                component: LeaveTakenBalanceReport,
                meta: {
                    title: 'report.title.leave_taken_balance_report',
                    crumbs: [
                        'report', "leaveTakenBalanceReport"
                    ]
                }
            },
            {
                path: '/report/summary-leave-taken-balance',
                name: 'summaryLeaveTakenBalanceReport',
                component: SummaryLeaveTakenBalanceReport,
                meta: {
                    title: 'report.title.summary_leave_taken_balance_report',
                    crumbs: [
                        'report', "summaryLeaveTakenBalanceReport"
                    ]
                }
            },
            {
                path: '/report/current-leave',
                name: 'currentLeaveReport',
                component: CurrentLeaveReport,
                meta: {
                    title: "Current Leave Report",
                    crumbs: [
                        'report', "currentLeaveReport"
                    ]
                }
            },
            {
                path: '/report/new-hire',
                name: 'newHiringReport',
                component: NewHiringReport,
                meta: {
                    title: "New Hiring Report",
                    crumbs: [
                        'report', "newHiringReport"
                    ]
                }
            },
            {
                path: '/report/working_history',
                name: 'workingHistoryReport',
                component: WorkingHistoryReport,
                meta: {
                    title: "Working History Report",
                    crumbs: [
                        'report', "workingHistoryReport"
                    ]
                }
            },

            {
                path: '/report/addition',
                name: 'additionReport',
                component: AdditionReport,
                meta: {
                    title: "Other Addition Report",
                    crumbs: [
                        'report', "additionReport"
                    ]
                }
            },

            {
                path: '/report/attendance_policy',
                name: 'attendancePolicyReport',
                component: AttendancePolicyReport,
                meta: {
                    title: "Attendance Policy Report",
                    crumbs: [
                        'report', "attendancePolicyReport"
                    ]
                }
            },

            {
                path: '/report/deduction',
                name: 'deductionReport',
                component: DeductionReport,
                meta: {
                    title: "Other Deduction Report",
                    crumbs: [
                        'report', "deductionReport"
                    ]
                }
            },

            {
                path: '/report/payroll/account',
                name: 'accountReport',
                component: AccountReport,
                meta: {
                    title: 'report.title.account_report',
                    crumbs: [
                        'report', "accountReport"
                    ]
                }
            },

            
            
            {
                path: '/report/payroll/bank',
                name: 'bankReport',
                component: BankReport,
                meta: {
                    title: 'report.title.bank_report',
                    crumbs: [
                        'report', "bankReport"
                    ]
                }
            },

            {
                path: '/report/payroll/tax',
                name: 'taxReport',
                component: TaxReport,
                meta: {
                    title: 'report.title.tax_report',
                    crumbs: [
                        'report', "taxReport"
                    ]
                }
            },

            {
                path: '/report/payroll/ssb',
                name: 'ssbReport',
                component: SSBReport,
                meta: {
                    title: 'report.title.ssb_report',
                    crumbs: [
                        'report', "ssbReport"
                    ]
                }
            },

            
            {
                path: '/report/payroll/overtime',
                name: 'overtimeReport',
                component: OvertimeReport,
                meta: {
                    title: 'report.title.overtime_report',
                    crumbs: [
                        'report', "overtimeReport"
                    ]
                }
            },

            
            {
                path: '/report/employee/training',
                name: 'employeeTrainingReport',
                component: EmployeeTrainingReport,
                meta: {
                    title: 'report.title.employeeTrainingReport',
                    crumbs: [
                        'report', "employeeTrainingReport"
                    ]
                }
            },
            
            {
                path: '/report/employee/education',
                name: 'employeeEducationReport',
                component: EmployeeEducationReport,
                meta: {
                    title: 'report.title.employeeEducationReport',
                    crumbs: [
                        'report', "employeeEducationReport"
                    ]
                }
            },

            {
                path: '/report/fixed_attendance_users',
                name: 'fixedAttendanceUserReport',
                component: FixedAttendanceUserReport,
                meta: {
                    title: 'report.title.fixedAttendanceUserReport',
                    crumbs: [
                        'report', "fixedAttendanceUserReport"
                    ]
                }
            },
        ]

    },
    {
        path: '/',
        redirect: 'login',
        component: AuthLayout,
        children: [
            {
                path: '/login',
                name: 'Login',
                component: Login
            },
            {
                path: '/forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword
            },
            {
                path: '/verify',
                name: 'ResetPassword',
                component: ResetPassword
            },
            {
                path: '/success',
                name: 'ResetSuccess',
                component: ResetSuccess
            },
            { path: '*', component: NotFound }
        ]
    },
];
export default routes;
