<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <label class="form-input-label">
                    Select to show column in Account Report
                  </label>
                  <br /><br />

                  <div
                    v-for="(item, value_key, index) in account_configuration"
                    :key="index"
                  >
                   
                    <div class="form-check">
                      <!-- {{selected_configuration.values.find(c => c == value_key)}} -->
                    <input
                      type="checkbox"
                      class="form-check-input"
                      :id="'item_' + value_key"
                      :value="value_key"
                      v-model="selected_items"
                      
                    />
                    <label
                      class="form-check-label"
                      :for="'item_' + value_key"
                    >{{item}}</label>
                  </div>


                  </div>
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon icon="file-upload" v-show="!formSubmitting" />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
  </div>
</template>
<script>
import AccountReportConfig from "@/apis/AccountReportConfig";
import { mapActions, mapGetters } from "vuex";
import {
  mdbSpinner,
  mdbCard,
  mdbCardBody,
  // mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  // mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbSpinner,
    mdbCard,
    mdbCardBody,
    // mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    // mdbFormInline,
  },
  props: [],
  data() {
    return {
      account_configuration: {},
      selected_items:[],
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    fetchAccountReportConfig() {
      this.updateLoading(true);
      AccountReportConfig.get()
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              // console.log(response.data)

              this.account_configuration = response.data.data.all_cols;
              
              
              this.selected_items = response.data.data.selected_cols_keys;

              this.updateLoading(false);
            }
          } else {
            this.updateLoading(false);
            this.$router.push({ name: "dashboard" });
          }
        })
        .catch(() => {
          this.updateLoading(false);
          this.$router.push({ name: "dashboard" });
        });
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;


      const payload = {
        "arsc_columns":this.selected_items
      };

      AccountReportConfig.save(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    resetAlert() {
      this.feedback_error = false;
    },
  },
  created() {
    this.fetchAccountReportConfig();
  },
};
</script>