<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>
    <div class="text-right">
      <mdb-btn
        type="button"
        class="btn_back"
        color="uno-color"
        @click="redirectBackAction"
      >
        <mdb-icon icon="arrow-left" size="md" />
        {{ $t("general.button.go_back") }}
      </mdb-btn>

      <mdb-btn
        type="button"
        color="primary"
        class="btn_outline_style"
        @click="redirectEditAction"
      >
        <mdb-icon icon="edit" size="md" />
        Edit
      </mdb-btn>
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <template v-if="!employee_id">
              <mdb-row v-if="selectedEmployeeData" class="mb-5">
                <mdb-col offsetMd="2" md="8">
                  <div class="approval_info_box">
                    <EmployeeInfoBoxLayout :user="selectedEmployeeData" />
                  </div>
                </mdb-col>
              </mdb-row>
            </template>

            <mdb-row>
              <mdb-col offsetMd="2" md="8">
                Attendance Day {{ attendance.attendance_day }}</mdb-col
              >
            </mdb-row>

            <mdb-row>
              <mdb-col md="6">
                <label>Check in</label>
                <dl class="row">
                  <dt class="col-sm-4">Office Check In:</dt>
                  <dd class="col-sm-8">
                    {{ attendance.check_in.attendance_office_checkin_time }}
                  </dd>

                  <dt class="col-sm-4">Check In:</dt>
                  <dd class="col-sm-8">
                    {{ attendance.check_in.attendance_checkin_time }}
                  </dd>

                  <template
                    v-if="
                      attendance.check_in.attendance_online_request_checkin ==
                      'late_early_fixed'
                    "
                  >
                    <dt class="col-sm-4">remark</dt>
                    <dd class="col-sm-8">
                      Request for Fixing Attendance Time for job requirement
                      <br />
                      {{ attendance.check_in.oal_attendance_remark }}
                    </dd>
                  </template>

                  <template
                    v-if="
                      attendance.check_in.attendance_online_request_checkin ==
                        'late_early_fixed' &&
                      typeof attendance.check_in
                        .attendance_actual_checkin_time != 'object'
                    "
                  >
                    <dt class="col-sm-4">Acutal Check Out:</dt>
                    <dd class="col-sm-8">
                      {{ attendance.check_in.attendance_actual_checkin_time }}
                    </dd>
                  </template>

                  <dt class="col-sm-4">Late:</dt>
                  <dd class="col-sm-8">
                    {{ attendance.check_in.attendance_checkin_late_mins }}
                  </dd>

                  <dt class="col-sm-4">Type</dt>
                  <dd class="col-sm-8">
                    {{ attendance.check_in.attendance_checkin_type }}
                  </dd>

                  <template
                    v-if="attendance.check_in.oal_attendance_image != ''"
                  >
                    <dt class="col-sm-4">Image</dt>
                    <dd class="col-sm-8">
                      <img
                        :src="attendance.check_in.oal_attendance_image"
                        width="200"
                      />
                    </dd>
                  </template>

                  <template
                    v-if="
                      attendance.check_in.attendance_checkin_type != 'manual'
                    "
                  >
                    <dt class="col-sm-4">Status</dt>
                    <dd class="col-sm-8">
                      {{ attendance.check_in.attendance_checkin_status }}
                    </dd>

                    <dt class="col-sm-4"></dt>
                    <dd
                      class="col-sm-8"
                      v-if="
                        attendance.check_in.attendance_checkin_complete != '1' && attendance.check_in.attendance_checkin_time
                      "
                    >
                      Waiting Approval
                    </dd>
                  </template>
                </dl>
                
                <gmap-map
                v-if="getCheckinLocation"
                  :center="getCheckinLocation"
                  :zoom="14"
                  map-type-id="terrain"
                  style="width: 500px; height: 300px"
                >
             
                  <gmap-marker
                    :position="getCheckinLocation"
                    @click="center = getCheckinLocation"
                  ></gmap-marker>
                </gmap-map>

                <hr />

                <template
                  v-if="
                    attendance.check_out.attendance_checkout_type != 'manual'
                  "
                >
                  <div v-if="this.checkin_approved_logs_index.length > 0">
                    <mdb-card class="mb-5">
                      <mdb-card-body>
                        <mdb-row>
                          <mdb-col>
                            <div class="title_header mb-3">Approved By</div>
                            <div
                              v-for="(
                                obj_key, index
                              ) in checkin_approved_logs_index"
                              :key="index"
                            >
                              Level {{ obj_key }}
                              <ApprovedUserLayout
                                :users="checkin_approved_logs[obj_key]"
                              />
                              <hr
                                v-show="
                                  checkin_approved_logs.length - 1 > index
                                "
                              />
                            </div>
                          </mdb-col>
                        </mdb-row>
                      </mdb-card-body>
                    </mdb-card>
                  </div>

                  <div v-if="this.checkin_left_approval_log_index.length > 0">
                    <mdb-card class="mb-5">
                      <mdb-card-body>
                        <mdb-row>
                          <mdb-col>
                            <div class="title_header mb-3">
                              Pending Approvals
                            </div>
                            <mdb-btn
                              color="uno-color"
                              class="btn_submit"
                              @click="handleBehalfApproval('check_in', 'all')"
                            >
                              Approve all
                            </mdb-btn>
                            <mdb-btn
                              outline="primary"
                              class="btn_outline_style"
                              @click="handleBehalfApproval('check_in', 'level')"
                            >
                              Approve Level
                              {{ checkin_left_approval_log_index[0] }}
                            </mdb-btn>
                            <mdb-btn
                              class="btn_back"
                              color="uno-color"
                              @click="
                                handleBehalfApproval('check_in', 'cancel')
                              "
                            >
                              Cancel
                            </mdb-btn>
                            <div
                              v-for="(
                                obj_key, index
                              ) in checkin_left_approval_log_index"
                              :key="index"
                              class="pending_approval_main_box mb-4"
                            >
                              <mdb-row class="mt-4 mb-3">
                                <mdb-col md="2" col="3">
                                  Level {{ obj_key }}
                                </mdb-col>
                                <mdb-col md="10" col="9">
                                  <div
                                    style="border-top: 1px solid #dedddd"
                                    class="mt-2"
                                  ></div>
                                </mdb-col>
                              </mdb-row>
                              <mdb-row>
                                <mdb-col
                                  md="12"
                                  v-for="(
                                    user, index
                                  ) in checkin_left_approval_log[obj_key]"
                                  :key="index"
                                  class="pending_approval_box"
                                >
                                  <EmployeeInfoBoxLayout :user="user" />
                                </mdb-col>
                              </mdb-row>
                              <!-- <hr
                                v-show="
                                  checkin_left_approval_log_index.length - 1 >
                                  index
                                "
                              /> -->
                            </div>
                          </mdb-col>
                        </mdb-row>
                      </mdb-card-body>
                    </mdb-card>
                  </div>
                </template>
              </mdb-col>
              <mdb-col md="6">
                <label>Check out</label>

                <dl class="row">
                  <dt class="col-sm-4">Office Check Out:</dt>
                  <dd class="col-sm-8">
                    {{ attendance.check_out.attendance_office_checkout_time }}
                  </dd>

                  <dt class="col-sm-4">Check Out:</dt>
                  <dd class="col-sm-8">
                    {{ attendance.check_out.attendance_checkout_time }}
                  </dd>

                  <template
                    v-if="
                      attendance.check_out.attendance_online_request_checkout ==
                      'late_early_fixed'
                    "
                  >
                    <dt class="col-sm-4">remark</dt>
                    <dd class="col-sm-8">
                      Request for Fixing Attendance Time for job requirement
                      <br />
                      {{ attendance.check_out.oal_attendance_remark }}
                    </dd>
                  </template>

                  <template
                    v-if="
                      attendance.check_out.attendance_online_request_checkout ==
                        'late_early_fixed' &&
                      typeof attendance.check_out
                        .attendance_actual_checkout_time != 'object'
                    "
                  >
                    <dt class="col-sm-4">Acutal Check Out:</dt>
                    <dd class="col-sm-8">
                      {{ attendance.check_out.attendance_actual_checkout_time }}
                    </dd>
                  </template>

                  <dt class="col-sm-4">Early:</dt>
                  <dd class="col-sm-8">
                    {{ attendance.check_out.attendance_checkout_early_mins }}
                  </dd>

                  <dt class="col-sm-4">Type</dt>
                  <dd class="col-sm-8">
                    {{ attendance.check_out.attendance_checkout_type }}
                  </dd>

                  <template
                    v-if="attendance.check_out.oal_attendance_image != ''"
                  >
                    <dt class="col-sm-4">Image</dt>
                    <dd class="col-sm-8">
                      <img
                        :src="attendance.check_out.oal_attendance_image"
                        width="200"
                      />
                    </dd>
                  </template>

                  <template
                    v-if="
                      attendance.check_out.attendance_checkout_type != 'manual'
                    "
                  >
                    <dt class="col-sm-4">Status</dt>
                    <dd class="col-sm-8">
                      {{ attendance.check_out.attendance_checkout_status }}
                    </dd>
                    <dt class="col-sm-4"></dt>
                    <dd
                      class="col-sm-8"
                      v-if="
                        attendance.check_out.attendance_checkout_complete != '1' && attendance.check_out.attendance_checkout_time
                      "
                    >
                      Waiting Approval
                    </dd>
                  </template>
                </dl>

                <gmap-map
                 v-if="getCheckoutLocation"
                  :center="getCheckoutLocation"
                  :zoom="14"
                  map-type-id="terrain"
                  style="width: 500px; height: 300px"
                >
                  <gmap-marker
                    :position="getCheckoutLocation"
                    @click="center = getCheckoutLocation"
                  ></gmap-marker>
                </gmap-map>

                <hr />
                <template
                  v-if="
                    attendance.check_out.attendance_checkout_type != 'manual'
                  "
                >
                  <div v-if="this.checkout_approved_logs_index.length > 0">
                    <mdb-card>
                      <mdb-card-body>
                        <mdb-row>
                          <mdb-col>
                            <div class="title_header mb-3">Approved By</div>
                            <div
                              v-for="(
                                obj_key, index
                              ) in checkout_approved_logs_index"
                              :key="index"
                              class="pending_approval_main_box mb-4"
                            >
                              <mdb-row class="mt-3 mb-3">
                                <mdb-col md="2" col="3">
                                  Level {{ obj_key }}
                                </mdb-col>
                                <mdb-col md="10" col="9">
                                  <div
                                    style="border-top: 1px solid #dedddd"
                                    class="mt-2"
                                  ></div>
                                </mdb-col>
                              </mdb-row>
                              <ApprovedUserLayout
                                :users="checkout_approved_logs[obj_key]"
                              />
                              <hr
                                v-show="
                                  checkout_approved_logs.length - 1 > index
                                "
                              />
                            </div>
                          </mdb-col>
                        </mdb-row>
                      </mdb-card-body>
                    </mdb-card>
                  </div>

                  <div v-if="this.checkout_left_approval_log_index.length > 0">
                    <mdb-card>
                      <mdb-card-body>
                        <mdb-row>
                          <mdb-col>
                            <div class="title_header mb-3">
                              Pending Approvals
                            </div>
                            <mdb-btn
                              color="uno-color"
                              class="btn_submit"
                              @click="handleBehalfApproval('check_out', 'all')"
                            >
                              Approve all
                            </mdb-btn>
                            <mdb-btn
                              outline="primary"
                              class="btn_outline_style"
                              @click="
                                handleBehalfApproval('check_out', 'level')
                              "
                            >
                              Approve Level
                              {{ checkout_left_approval_log_index[0] }}
                            </mdb-btn>
                            <mdb-btn
                              class="btn_back"
                              color="uno-color"
                              @click="
                                handleBehalfApproval('check_out', 'cancel')
                              "
                            >
                              Cancel
                            </mdb-btn>
                            <div
                              v-for="(
                                obj_key, index
                              ) in checkout_left_approval_log_index"
                              :key="index"
                              class="pending_approval_main_box mb-4"
                            >
                              <mdb-row class="mt-4 mb-3">
                                <mdb-col md="2" col="3">
                                  Level {{ obj_key }}
                                </mdb-col>
                                <mdb-col md="10" col="9">
                                  <div
                                    style="border-top: 1px solid #dedddd"
                                    class="mt-2"
                                  ></div>
                                </mdb-col>
                              </mdb-row>

                              <mdb-row>
                                <mdb-col
                                  md="12"
                                  v-for="(
                                    user, index
                                  ) in checkout_left_approval_log[obj_key]"
                                  :key="index"
                                  class="pending_approval_box"
                                >
                                  <EmployeeInfoBoxLayout :user="user" />
                                </mdb-col>
                              </mdb-row>

                              <!-- <hr
                                v-show="
                                  checkout_left_approval_log_index.length - 1 >
                                  index
                                "
                              /> -->
                            </div>
                          </mdb-col>
                        </mdb-row>
                      </mdb-card-body>
                    </mdb-card>
                  </div>
                </template>
              </mdb-col>
            </mdb-row>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
  </div>
</template>
<script>
import Attendance from "@/apis/Attendance";
import LogList from "@/components/logs/List";

import EmployeeInfoBoxLayout from "@/components/EmployeeInfoBoxLayout";
import ApprovedUserLayout from "@/components/ApprovedUserLayout";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  // mdbCardTitle,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
} from "mdbvue";

export default {
  name: "EmployeeAttendanceForm",
  components: {
    mdbCard,
    mdbCardBody,
    // mdbCardTitle,
    mdbSpinner,
    mdbRow,
    mdbCol,
    LogList,
    mdbBtn,
    mdbIcon,
    EmployeeInfoBoxLayout,
    ApprovedUserLayout,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      attendance: {},
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",

      checkin_approved_logs: [],
      checkin_approved_logs_index: [],
      checkin_left_approval_log: [],
      checkin_left_approval_log_index: [],

      checkout_approved_logs: [],
      checkout_approved_logs_index: [],
      checkout_left_approval_log: [],
      checkout_left_approval_log_index: [],

      selectedEmployeeData: {},
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),

    getCheckinLocation() {
      if (
        this.attendance.check_in.oal_attendance_latitude &&
        this.attendance.check_in.oal_attendance_longitude
      ) {
        return {
          lat: Number(this.attendance.check_in.oal_attendance_latitude),
          lng: Number(this.attendance.check_in.oal_attendance_longitude),
        };
      }
      return false;
    },
    getCheckoutLocation() {
      if (
        this.attendance.check_out.oal_attendance_latitude &&
        this.attendance.check_out.oal_attendance_longitude &&
        this.attendance.check_out.oal_attendance_latitude !='' && 
        this.attendance.check_out.oal_attendance_longitude!='' 
      ) {
        return {
          lat: Number(this.attendance.check_in.oal_attendance_latitude),
          lng: Number(this.attendance.check_in.oal_attendance_longitude),
        };
      }
      return false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    redirectEditAction() {
      if (this.employee_id) {
        this.$router.push({
          name: "viewEmployeeAttendance",
          params: { _id: this.attendance.attendance_id },
        });
      } else {
        this.$router.push({
          name: "viewAttendanceForUpdate",
          params: { _id: this.attendance.attendance_id },
        });
      }
    },

    handleBehalfApproval(type, val) {
      this.formSubmitting = true;
      this.deleteSubmitted = true;

      let status_val = "";
      if (val == "all") {
        status_val = "approve_level_all";
      } else if (val == "level") {
        status_val = "approve_one_level";
      } else {
        status_val = "reject";
      }

      Attendance.behalf_approval({
        attendance_id: this.attendance.attendance_id,
        status: status_val,
        type: type,
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$notify.success({
              message: response.data.message,
              position: "top right",
              timeOut: 5000,
            });

            this.fetchRecord();

            this.formSubmitting = false;
            this.deleteSubmitted = false;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }
        }
      });
    },

    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          Attendance.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  this.attendance = response.data.data;

                  //preparation checkin
                  this.checkin_approved_logs = {};
                  this.checkin_approved_logs_index = [];

                  if (response.data.checkin_approve_logs.length > 0) {
                    let tmp_data_list = {};

                    for (const tmp_data of response.data.checkin_approve_logs) {
                      const temp_key = tmp_data.attendance_approval_level;
                      if (temp_key in tmp_data_list) {
                        tmp_data_list[temp_key].push(tmp_data);
                      } else {
                        this.checkin_approved_logs_index.push(temp_key);
                        tmp_data_list[temp_key] = [];
                        tmp_data_list[temp_key].push(tmp_data);
                      }
                    }
                    this.checkin_approved_logs = tmp_data_list;
                  } else {
                    this.checkin_approved_logs = {};
                  }

                  this.checkin_left_approval_log = {};
                  this.checkin_left_approval_log_index = [];
                  if (response.data.checkin_left_approve_level.length > 0) {
                    let tmp_data_list = {};

                    for (const tmp_data of response.data
                      .checkin_left_approve_level) {
                      const temp_key = tmp_data.attendance_approval_level;
                      if (temp_key in tmp_data_list) {
                        tmp_data_list[temp_key].push(tmp_data);
                      } else {
                        this.checkin_left_approval_log_index.push(temp_key);
                        tmp_data_list[temp_key] = [];
                        tmp_data_list[temp_key].push(tmp_data);
                      }
                    }

                    this.checkin_left_approval_log = tmp_data_list;
                  } else {
                    this.checkin_left_approval_log = {};
                  }
                  ////////////
                  //preparation checkout
                  this.checkout_approved_logs = {};
                  this.checkout_approved_logs_index = [];

                  if (response.data.checkout_approve_logs.length > 0) {
                    let tmp_data_list = {};

                    for (const tmp_data of response.data
                      .checkout_approve_logs) {
                      const temp_key = tmp_data.attendance_approval_level;
                      if (temp_key in tmp_data_list) {
                        tmp_data_list[temp_key].push(tmp_data);
                      } else {
                        this.checkout_approved_logs_index.push(temp_key);
                        tmp_data_list[temp_key] = [];
                        tmp_data_list[temp_key].push(tmp_data);
                      }
                    }
                    this.checkout_approved_logs = tmp_data_list;
                  } else {
                    this.checkout_approved_logs = {};
                  }

                  this.checkout_left_approval_log = {};
                  this.checkout_left_approval_log_index = [];
                  if (response.data.checkout_left_approve_level.length > 0) {
                    let tmp_data_list = {};

                    for (const tmp_data of response.data
                      .checkout_left_approve_level) {
                      const temp_key = tmp_data.attendance_approval_level;
                      if (temp_key in tmp_data_list) {
                        tmp_data_list[temp_key].push(tmp_data);
                      } else {
                        this.checkout_left_approval_log_index.push(temp_key);
                        tmp_data_list[temp_key] = [];
                        tmp_data_list[temp_key].push(tmp_data);
                      }
                    }

                    this.checkout_left_approval_log = tmp_data_list;
                  } else {
                    this.checkout_left_approval_log = {};
                  }
                  ////////////

                  if (!this.employee_id) {
                    this.selectedEmployeeData =
                      response.data.data.employee_info;
                    //emp info
                  }

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    employeeAttendanceList: {
                      employee_id: this.attendance.attendance_user_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "employeeAttendanceList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "employeeAttendanceList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "employeeAttendanceList" });
        }
      } else {
        // add
      }
    },

    redirectBackAction() {
      if (this.employee_id) {
        this.$router.push({
          name: "employeeAttendanceList",
          params: {
            employeeAttendanceList: this.attendance.attendance_user_id,
          },
        });
      } else {
        this.$router.push({
          name: "listManageAttendances",
        });
      }
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>