<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card
          class="mb-4"
          v-if="turnoverGraphData.label.length > 0 || turnover_data.length > 0"
        >
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Turnover Rate</h6>

                <div>
                  <VerticalBarChartView
                    v-if="
                      turnoverGraphData && turnoverGraphData.label.length > 0
                    "
                    :barGraphData="turnoverGraphData"
                    :xlabel="'Departments'"
                    :ylabel="'Percentage of Turnover Rate'"
                  />
                </div>
                <br />
                <br />
                <mdb-row v-if="turnover_data.length > 0">
                  <mdb-col>
                    <div>
                      <mdb-tbl responsive striped bordered sm>
                        <mdb-tbl-head style="background: #96a6bf; color: #fff">
                          <tr>
                            <td
                              v-for="(item_value, index2) in turnover_header"
                              :key="index2"
                            >
                              {{ item_value }}
                            </td>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr
                            v-for="(turnover_item, index) in turnover_data"
                            :key="index"
                          >
                            <td
                              v-for="(item_value, index2) in turnover_item"
                              :key="index2"
                            >
                              {{ item_value }}
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <!-- ///////////////////// -->
        <!--   -->
        <mdb-card class="mb-4" v-if="turnover_comparision_data.length > 0">
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <h6 class="mb-3">Monthly Turnover Analysis</h6>

                <mdb-row>
                  <mdb-col>
                    <div>
                      <mdb-tbl responsive striped bordered sm>
                        <mdb-tbl-head style="background: #96a6bf; color: #fff">
                          <tr>
                            <td
                              v-for="(
                                item_value, index
                              ) in turnover_comparision_header"
                              :key="index"
                            >
                               {{ item_value }}
                            </td>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr
                            v-for="(
                              turnover_item, index
                            ) in turnover_comparision_data"
                            :key="index"
                          >
                            <td
                              v-for="(item_value, index2) in turnover_item"
                              :key="index2"
                            >
                              {{ item_value }}
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-col>
                </mdb-row>
              </mdb-col>
            </mdb-row>
          </mdb-card-body></mdb-card
        >

        <!-- ///////////////////// -->

        <!-- ///////////////////////// -->
        <mdb-row>
          <!--  -->
          <mdb-col v-if="year_to_date_separation.length > 0">
            <mdb-card class="mb-4">
              <mdb-card-body>
                <mdb-row>
                  <mdb-col>
                    <h6 class="mb-3">Year To Date Separation</h6>

                    <mdb-row>
                      <mdb-col>
                        <div>
                          <mdb-tbl responsive striped bordered sm>
                            <mdb-tbl-head
                              style="background: #96a6bf; color: #fff"
                            >
                              <tr>
                                <td > Description</td>
                                <td > {{month}} (Employees)</td>
                                <td > {{year}} (Employees)</td>
                              </tr>
                            </mdb-tbl-head>
                            <mdb-tbl-body>
                              <tr
                                v-for="(item, index) in year_to_date_separation"
                                :key="index"
                              >
                                <td>
                                  {{ item["label"] }}
                                </td>

                                <td>
                                  {{ item["current_value"] }}
                                </td>
                                <td>
                                  {{ item["yearly_value"] }}
                                </td>
                              </tr>
                            </mdb-tbl-body>
                          </mdb-tbl>
                        </div>
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>
              </mdb-card-body></mdb-card
            >
          </mdb-col>

          <!-- ///////////////// -->
          <!--  -->
          <mdb-col v-if="new_hire.length > 0">
            <mdb-card class="mb-4">
              <mdb-card-body>
                <mdb-row>
                  <mdb-col>
                    <h6 class="mb-3">New Hire</h6>

                    <mdb-row>
                      <mdb-col>
                        <div>
                          <mdb-tbl responsive striped bordered sm>
                            <mdb-tbl-head
                              style="background: #96a6bf; color: #fff"
                            >
                              <tr>
                                <td > Description</td>
                                <td > {{month}} (Employees)</td>
                                <td > {{year}} (Employees)</td>
                              </tr>
                            </mdb-tbl-head>
                            <mdb-tbl-body>
                              <tr
                                v-for="(item, index) in new_hire"
                                :key="index"
                              >
                                <td>
                                  {{ item["label"] }}
                                </td>

                                <td>
                                  {{ item["current_value"] }}
                                </td>
                                <td>
                                  {{ item["yearly_value"] }}
                                </td>
                              </tr>
                            </mdb-tbl-body>
                          </mdb-tbl>
                        </div>
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>
              </mdb-card-body></mdb-card
            >
          </mdb-col>
        </mdb-row>

        <!-- ///////////////////////// -->

        <!-- ///////////////////////////////////////// -->

        <mdb-row
          v-if="
            turnoverGraphData.label.length == 0 &&
            turnover_data.length == 0 &&
            turnover_comparision_data.length == 0 &&
            year_to_date_separation.length == 0 &&
            new_hire.length == 0
          "
        >
          <mdb-col>
            <mdb-card>
              <mdb-card-body>
                <div class="d-flex justify-content-center">
                  <h5 style="margin-top: 60px">No Record Found.</h5>
                </div>
                <div class="d-flex justify-content-center">
                  <img src="/empty_item.svg" alt="Empty Record" />
                </div>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </template>
    </div>
  </div>
</template>
<script>
import ManagementReport from "@/apis/ManagementReport";
import { mapActions, mapGetters } from "vuex";

import VerticalBarChartView from "@/components/VerticalBarChartView";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    VerticalBarChartView,
  },
  data() {
    return {
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",

      turnoverGraphData: false,
      turnover_header: [],
      turnover_data: [],
      turnover_comparision_header: [],
      turnover_comparision_data: [],
      year_to_date_separation: [],
      new_hire: [],

      /////////////////////
    };
  },
  props: ["year", "month"],
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    fetchData() {
      this.updateLoading(true);

      const payload = {
        date: this.month + "-" + this.year,
      };

      // const response = {
      //   status: 200,
      //   data: {
      //     code: 200,
      //     turnoverGraphData: {
      //       label: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
      //       dataLabel: "No of Employee",
      //       dataSet: [12, 19, 3, 5, 2, 3],
      //     },
      //     turnover_header: [
      //       "position",
      //       "department 1",
      //       "department 1",
      //       "department 1",
      //     ],
      //     turnover_data: [
      //       ["lorum ipsuam", 1, 2, 3],
      //       ["lorum ipsuam", 1, 2, 3],
      //       ["lorum ipsuam", 1, 2, 3],
      //       ["lorum ipsuam", 1, 2, 3],
      //       ["lorum ipsuam", 1, 2, 3],
      //     ],
      //     turnover_comparision_header: [
      //       "Description",
      //       "January",
      //       "February",
      //       "March",
      //     ],
      //     turnover_comparision_data: [
      //       ["Opeining Men Power", 1, 2, 3],
      //       ["New Hire", 1, 2, 3],
      //       ["Resignation", 1, 2, 3],
      //     ],
      //     year_to_date_separation: [
      //       { label: "End of Contract", value: "0" },
      //       { label: "Termination : Organization Factor", value: "0" },
      //       { label: "Termination : Employee Factor", value: "0" },
      //     ],
      //     new_hire: [
      //       { label: "Appointment Replacement", value: "0" },
      //       { label: "Appointment New", value: "0" },
      //     ],
      //   },
      // };

      // this.turnoverGraphData = response.data.turnoverGraphData;
      // this.turnover_header = response.data.turnover_header;
      // this.turnover_data = response.data.turnover_data;
      // this.turnover_comparision_header =
      //   response.data.turnover_comparision_header;
      // this.turnover_comparision_data = response.data.turnover_comparision_data;
      // this.year_to_date_separation = response.data.year_to_date_separation;
      // this.new_hire = response.data.new_hire;

      ManagementReport.turnoverGraphData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.turnoverGraphData = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.turnoverHeaderAndData(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.turnover_header = response.data.data.turnover_header;
            this.turnover_data = response.data.data.turnover_data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.turnoverMonthlyComparison(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.turnover_comparision_header =
              response.data.data.comparision_header;
            this.turnover_comparision_data =
              response.data.data.comparision_data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.turnoverSeparation(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.year_to_date_separation = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });

      ManagementReport.turnoverNewHire(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.new_hire = response.data.data;
          } else {
            this.$router.push({ name: "managementReport" });
          }
          this.updateLoading(false);
        }
      });
    },

    validateParams() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (
        !months.find((month) => month.toLowerCase() == this.month.toLowerCase())
      ) {
        this.$router.push({ name: "managementReport" });
      }

      if (!/^\d{4}$/.test(this.year)) {
        this.$router.push({ name: "managementReport" });
      }

      this.fetchData();
    },
  },
  created() {
    this.validateParams();
  },
};
</script>

<style scoped>
.rotate_text {
  transform: rotate(180deg);

  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(180deg);

  /* Firefox */
  -moz-transform: rotate(180deg);

  /* IE */
  -ms-transform: rotate(180deg);

  /* Opera */
  -o-transform: rotate(180deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
</style>