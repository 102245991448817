var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.feedback_error)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[(typeof _vm.feedback_message === 'string')?_c('div',[_vm._v(" "+_vm._s(_vm.feedback_message)+" ")]):_vm._l((_vm.feedback_message),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error.message)+" ")])})],2):_vm._e(),[_c('div',{staticClass:"d-flex justify-content-center"},[_c('mdb-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"big":"","color":"blue"}})],1),(!_vm.isLoading)?_c('div',[_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-3"},[_vm._v("Title")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v(_vm._s(_vm.announcement.title))]),_c('dt',{staticClass:"col-sm-3"},[_vm._v("Description")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v(_vm._s(_vm.announcement.description))]),_c('dt',{staticClass:"col-sm-3"},[_vm._v("Rank / Grade")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v(_vm._s(_vm.announcement.grades))]),_c('dt',{staticClass:"col-sm-3"},[_vm._v("Attachments")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.announcement.attachments.length > 0)?_c('div',_vm._l((_vm.announcement.attachments),function(attachment_item,index){return _c('div',{key:index},[_c('div',{staticClass:"float-left attachments_text_style"},[_c('a',{attrs:{"href":attachment_item.attachment_file_name,"target":"_blank"}},[(
                      attachment_item.file_ext == 'png' ||
                      attachment_item.file_ext == 'gif' ||
                      attachment_item.file_ext == 'jpg' ||
                      attachment_item.file_ext == 'jpeg'
                    )?_c('img',{staticClass:"announcement_thumbnail_image",attrs:{"src":attachment_item.attachment_file_name,"height":"100"}}):(
                      attachment_item.file_ext == 'doc' ||
                      attachment_item.file_ext == 'docx'
                    )?_c('img',{staticClass:"announcement_thumbnail_image",attrs:{"src":"/doc.png","height":"100"}}):(
                      attachment_item.file_ext == 'xls' ||
                      attachment_item.file_ext == 'xlsx'
                    )?_c('img',{staticClass:"announcement_thumbnail_image",attrs:{"src":"/spreadsheet.png","height":"100"}}):(
                      attachment_item.file_ext == 'ppt' ||
                      attachment_item.file_ext == 'ppt'
                    )?_c('img',{staticClass:"announcement_thumbnail_image",attrs:{"src":"/powerpoint.png","height":"100"}}):(
                      attachment_item.file_ext == 'pdf'
                    )?_c('img',{staticClass:"announcement_thumbnail_image",attrs:{"src":"/pdf.png","height":"100"}}):_c('img',{staticClass:"announcement_thumbnail_image",attrs:{"src":"/unknowfile.png","height":"100"}}),_c('br'),_vm._v(" "+_vm._s(_vm._f("filename_str_limit")(attachment_item.attachment_original_file_name))+" ")])])])}),0):_vm._e()])]),_c('div',{staticClass:"text-right"},[_c('mdb-btn',{staticClass:"btn_outline_style",attrs:{"type":"button","color":"primary","icon":"edit"},on:{"click":_vm.redirectUpdateForm}},[_vm._v(" "+_vm._s(_vm.$t("general.button.edit"))+" ")])],1),_c('mdb-card',[_c('mdb-card-body',[_c('mdb-tbl',{attrs:{"responsive":"","hover":""}},[_c('mdb-tbl-head',[_c('tr',[_c('th',{on:{"click":function($event){return _vm.sort('department_name')}}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentSort === 'department_name'),expression:"currentSort === 'department_name'"}],class:[
                      _vm.currentSortDir === 'asc'
                        ? 'fas fa-arrow-up '
                        : 'fas fa-arrow-down ' ],attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t("general.formInput.name"))+" ")]),_c('th',{staticClass:"text-right",attrs:{"width":"120"}},[_vm._v("Total")]),_c('th',{staticClass:"text-right",attrs:{"width":"120"}},[_vm._v("Read")]),_c('th',{staticClass:"text-right",attrs:{"width":"120"}},[_vm._v("Unread")])])]),_c('mdb-tbl-body',_vm._l((_vm.sortedRecordList),function(list_item,index){return _c('tr',{key:index,attrs:{"scope":"row"}},[(list_item.employee_count.total > 0)?[_c('td',{attrs:{"scope":"row"},on:{"click":function($event){return _vm.rowClickEvent(list_item.department_id)}}},[_vm._v(" "+_vm._s(list_item.department_name)+" ")]),_c('td',{staticClass:"text-right",on:{"click":function($event){return _vm.rowClickEvent(list_item.department_id)}}},[_vm._v(" "+_vm._s(list_item.employee_count.total)+" ")]),_c('td',{staticClass:"text-right",on:{"click":function($event){return _vm.rowClickEvent(list_item.department_id)}}},[_vm._v(" "+_vm._s(list_item.employee_count.read)+" ")]),_c('td',{staticClass:"text-right",on:{"click":function($event){return _vm.rowClickEvent(list_item.department_id)}}},[_vm._v(" "+_vm._s(list_item.employee_count.unread)+" ")])]:[_c('td',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(list_item.department_name)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(list_item.employee_count.total)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(list_item.employee_count.read)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(list_item.employee_count.unread)+" ")])]],2)}),0)],1)],1)],1)],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }