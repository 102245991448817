<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('training_info.formInput.title')"
                    v-model="trainingInfos.etqinfo_title"
                    outline
                    required
                    :disabled="formDisableUpdate"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('training_info.formInput.school')"
                    v-model="trainingInfos.etqinfo_school"
                    outline
                    :disabled="formDisableUpdate"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('training_info.formInput.location')"
                    v-model="trainingInfos.etqinfo_location"
                    outline
                    :disabled="formDisableUpdate"
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-date-picker-2
                    :label="$t('training_info.formInput.start_date')"
                    v-model="trainingInfos.etqinfo_start_date"
                    title="Select date"
                    :disabled="formDisableUpdate"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-date-picker-2
                    v-model="trainingInfos.etqinfo_end_date"
                    :label="$t('training_info.formInput.end_date')"
                    title="Select date"
                    :disabled="formDisableUpdate"
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-date-picker-2
                    v-model="trainingInfos.etqinfo_expiry_date"
                    :label="$t('training_info.formInput.expiry_date')"
                    title="Select date"
                    :disabled="formDisableUpdate"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('training_info.formInput.trainer')"
                    v-model="trainingInfos.etqinfo_trainer"
                    outline
                    :disabled="formDisableUpdate"
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('training_info.formInput.category')"
                    v-model="trainingInfos.etqinfo_category"
                    outline
                    :disabled="formDisableUpdate"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="number"
                    :label="$t('training_info.formInput.duration_days')"
                    v-model="trainingInfos.etqinfo_duration_days"
                    outline
                    :disabled="formDisableUpdate"
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-input
                    type="number"
                    :label="$t('training_info.formInput.duration_hours')"
                    v-model="trainingInfos.etqinfo_duration_hours"
                    outline
                    :disabled="formDisableUpdate"
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <template v-if="!formDisableUpdate">
                    <mdb-btn
                      type="Submit"
                      color="uno-color"
                      class="btn_submit"
                      :disabled="formSubmitting"
                    >
                      <mdb-icon
                        icon="file-upload"
                        size="md"
                        v-show="!formSubmitting"
                      />

                      <span
                        class="spinner-border-vcenter spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        v-show="formSubmitting"
                      ></span>
                      {{ $t("general.button.submit") }}
                    </mdb-btn>

                    <mdb-btn
                      type="button"
                      :disabled="deleteSubmitted"
                      @click="showDeleteModal"
                      v-show="doesEditForm"
                      color="uno-color"
                      class="btn_delete float-right"
                    >
                      <mdb-icon
                        icon="trash-alt"
                        far
                        size="md"
                        v-show="!deleteSubmitted"
                      />

                      <span
                        class="spinner-border-vcenter spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        v-show="deleteSubmitted"
                      ></span>
                      {{ $t("general.button.delete") }}
                    </mdb-btn>
                  </template>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import EmployeeTrainingInfo from "@/apis/EmployeeTrainingInfo";
import LogList from "@/components/logs/List";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";

import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbDatePicker2,
  // mdbSelect,
  // mdbFormInline,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbDatePicker2,
    // mdbSelect,
    // mdbFormInline,
    ModalDeleteConfimation,
  },
  props: ["_id", "employee_id"],
  data() {
    return {
      trainingInfos: {
        etqinfo_id: "",
        etqinfo_employee_id:
          this.employee_id != "undefined" ? this.employee_id : "",
        etqinfo_title: "",
        etqinfo_school: "",
        etqinfo_location: "",
        etqinfo_start_date: "",
        etqinfo_end_date: "",
        etqinfo_expiry_date: "",
        etqinfo_training_type: "employee_training",
        etqinfo_trainer: "",
        etqinfo_category: "",
        etqinfo_duration_days: "",
        etqinfo_duration_hours: "",
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      formDisableUpdate: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.trainingInfos.etqinfo_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          EmployeeTrainingInfo.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)

                  let etqinfo_id,
                    etqinfo_employee_id,
                    etqinfo_title,
                    etqinfo_school,
                    etqinfo_location,
                    etqinfo_start_date,
                    etqinfo_end_date,
                    etqinfo_expiry_date,
                    etqinfo_training_type,
                    etqinfo_trainer,
                    etqinfo_category,
                    etqinfo_duration_days,
                    etqinfo_duration_hours;
                  // populate field
                  ({
                    etqinfo_id,
                    etqinfo_employee_id,
                    etqinfo_title,
                    etqinfo_school,
                    etqinfo_location,
                    etqinfo_start_date,
                    etqinfo_end_date,
                    etqinfo_expiry_date,
                    etqinfo_training_type,
                    etqinfo_trainer,
                    etqinfo_category,
                    etqinfo_duration_days,
                    etqinfo_duration_hours,
                  } = response.data.data);
                  this.trainingInfos.etqinfo_id = etqinfo_id;
                  this.trainingInfos.etqinfo_employee_id = etqinfo_employee_id;
                  this.trainingInfos.etqinfo_title = etqinfo_title;
                  this.trainingInfos.etqinfo_school = etqinfo_school;
                  this.trainingInfos.etqinfo_location = etqinfo_location;
                  this.trainingInfos.etqinfo_start_date = etqinfo_start_date;
                  this.trainingInfos.etqinfo_end_date = etqinfo_end_date;
                  this.trainingInfos.etqinfo_expiry_date = etqinfo_expiry_date;
                  this.trainingInfos.etqinfo_training_type =
                    etqinfo_training_type;
                  this.trainingInfos.etqinfo_trainer = etqinfo_trainer;
                  this.trainingInfos.etqinfo_category = etqinfo_category;
                  this.trainingInfos.etqinfo_duration_days =
                    etqinfo_duration_days;
                  this.trainingInfos.etqinfo_duration_hours =
                    etqinfo_duration_hours;

                  if (etqinfo_training_type != "employee_training") {
                    this.formDisableUpdate = true;
                  }

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }

                  this.$store.state.crumbsParams = {
                    trainingInfoList: {
                      _id: this.trainingInfos.etqinfo_employee_id,
                    },
                  };

                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "trainingInfoList" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "trainingInfoList" });
            });
        } else {
          // not a number
          this.$router.push({ name: "trainingInfoList" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      EmployeeTrainingInfo.save(this.trainingInfos).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.trainingInfos.etqinfo_id != "") {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({
                name: "trainingInfoList",
                params: { employee_id: this.trainingInfos.etqinfo_employee_id },
              });
            } else {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.resetForm();
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({
        name: "trainingInfoList",
        params: { employee_id: this.trainingInfos.etqinfo_employee_id },
      });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.trainingInfos).forEach((key) => {
        this.trainingInfos[key] = "";
      });

      this.trainingInfos.etqinfo_training_type = "employee_training";
      this.trainingInfos.etqinfo_employee_id = this.employee_id;
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.trainingInfos.etqinfo_id > 0) {
        EmployeeTrainingInfo.delete({
          etqinfo_id: this.trainingInfos.etqinfo_id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });

              this.$router.push({
                name: "trainingInfoList",
                params: {
                  _id: this.trainingInfos.etqinfo_employee_id,
                },
              });
            } else if (response.data.code === 400) {
              if (response.data.error.length > 0) {
                this.feedback_message = response.data.error;
              }
              this.feedback_error = true;
            } else if (response.data.code === 500) {
              this.feedback_message = "Error";
              this.feedback_error = true;
            }

            this.deleteSubmitted = false;
          }
        });
        // .catch((error) => {
        //   console.log(error);
        // });
      }
    },
  },
  created() {
    this.$store.state.crumbsParams = {
      trainingInfoList: {
        employee_id: this.trainingInfos.etqinfo_employee_id,
      },
    };
    this.fetchRecord();
  },
};
</script>