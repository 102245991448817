<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8"> 
                  From Date
                </mdb-col>
                <mdb-col offsetMd="2" md="8"> 
                  <month-picker-input :no-default="true" @change="setFromDate" :clearable="true" @clear="clearFromDate" ></month-picker-input>
                </mdb-col>  
              </mdb-row>
              <br />

              <mdb-row>

                <mdb-col offsetMd="2" md="8"> 
                  To Date
                </mdb-col>
                <mdb-col offsetMd="2" md="8"> 
                  <month-picker-input :no-default="true" @change="setToDate" :clearable="true" @clear="clearToDate" ></month-picker-input>

                </mdb-col>
              </mdb-row>

              <br />
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
  </div>
</template>
<script>
import Report from "@/apis/Report";
import { mapActions, mapGetters } from "vuex";
import { MonthPickerInput  } from 'vue-month-picker';
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    MonthPickerInput
  },
  data() {
    return {
      
      
      from_month:"",
      from_year:"",
      to_month:"",
      to_year:"",
      formSubmitting: false,
      feedback_error: false,
      feedback_message: "",
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {
    ...mapActions(["updateLoading"]),

    clearFromDate(){
      this.from_month = "";
      this.from_year = "";
    },
    clearToDate(){
      this.to_month = "";
      this.to_year = "";
    },
   
    setFromDate(val){
      this.from_month = val.monthIndex;
      this.from_year = val.year;
    },

    setToDate(val){
      this.to_month = val.monthIndex;
      this.to_year = val.year;
    },
   
    handleFormSubmit() {
      if (this.from_year != "" && this.from_month != "") {
        this.resetAlert();
        this.formSubmitting = true;

        
        const payload = {
          from_date: this.from_year + "-" +  (this.from_month <10 ? "0"+this.from_month : this.from_month) + "-01" ,
          to_date: ((this.to_year != "" && this.to_month != "") ? this.to_year + "-" +  (this.to_month <10 ? "0"+this.to_month : this.to_month) + "-01" : ''),
        
        };

        Report.otherDeduction(payload).then((response) => {
          
          if (response.status === 200) {

            if (response.data.code === 400) {
              

              this.feedback_message = response.data.error;
              this.feedback_error = true;
              this.formSubmitting = false;
              return;
            }

            let today = new Date();
          let [month, date, year] = today
            .toLocaleDateString("en-US")
            .split("/");
          let [hour, minute, second] = today
            .toLocaleTimeString("en-US")
            .split(/:| /);
          let today_string = year + month + date + "_" + hour + minute + second;

            var blob = new Blob(["\ufeff",response.data]);
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "OtherDeductionReport" + "_" + today_string + ".csv";
            link.click();

            this.formSubmitting = false;
          }
        });
      }else {
        this.feedback_message = "Select From Date";
        this.feedback_error = true;
      }
        
      
    },

    resetAlert() {
      this.feedback_error = false;
    },
  },
  
};
</script>