<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-if="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="allowance_rule.ar_name"
                    outline
                    required
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <AllowanceTypeDropdown
                    @updateAllowanceTypeID="setAllowanceTypeID"
                    :default_selected_value="allowance_rule.ar_at_id"
                    :required="true"
                    :resetSelected="doesFormReset"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <mdb-input
                    type="textarea"
                    :label="$t('general.formInput.remark')"
                    v-model="allowance_rule.ar_remark"
                    outline
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="8">
                  <label>{{
                    $t("allowance_rules.formInput.allowance_payment_type")
                  }}</label>
                  <mdb-form-inline>
                    <mdb-input
                      type="radio"
                      id="option-fixed_per_month"
                      name="option_ar_payment_type"
                      radioValue="fixed_per_month"
                      v-model="allowance_rule.ar_payment_type"
                      :label="$t('allowance_rules.formInput.fixed_per_month')"
                    />
                    <mdb-input
                      type="radio"
                      id="option-per_working_day"
                      name="option_ar_payment_type"
                      radioValue="per_working_day"
                      v-model="allowance_rule.ar_payment_type"
                      :label="$t('allowance_rules.formInput.per_working_day')"
                    />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>

              <div
                v-show="
                  this.allowance_rule.ar_payment_type == 'fixed_per_month'
                "
              >
                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <mdb-input
                      type="text"
                      :label="$t('allowance_rules.formInput.allowance_amount')"
                      v-model="allowance_rule.ar_fixed_amount"
                      outline
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <label>Quit/Unjoin Allowace Calculation</label>
                    <mdb-form-inline>
                      <mdb-input
                        type="radio"
                        id="option-total_days_in_month"
                        name="ar_employee_quit_base_on"
                        radioValue="total_days_in_month"
                        v-model="allowance_rule.ar_employee_quit_base_on"
                        :label="
                          $t('allowance_rules.formInput.total_days_in_month')
                        "
                      />
                      <mdb-input
                        type="radio"
                        id="option-total_actual_working_days"
                        name="ar_employee_quit_base_on"
                        radioValue="total_actual_working_days"
                        v-model="allowance_rule.ar_employee_quit_base_on"
                        :label="
                          $t(
                            'allowance_rules.formInput.total_actual_working_days'
                          )
                        "
                      />
                    </mdb-form-inline>
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col offsetMd="2" md="8" class="mt-4">
                    <label>{{
                      $t("allowance_rules.text.allowance_available_condition")
                    }}</label>
                    <mdb-form-inline>
                      <mdb-input
                        type="radio"
                        id="option-none"
                        name="option_ar_depend_on"
                        radioValue="none"
                        v-model="allowance_rule.ar_depend_on"
                        :label="$t('allowance_rules.formInput.none')"
                      />
                      <mdb-input
                        type="radio"
                        id="option-leave_day"
                        name="option_ar_depend_on"
                        radioValue="leave_day"
                        v-model="allowance_rule.ar_depend_on"
                        :label="$t('allowance_rules.formInput.leave_day')"
                      />
                    </mdb-form-inline>
                  </mdb-col>
                </mdb-row>

                <div v-show="this.allowance_rule.ar_depend_on == 'leave_day'">
                  <mdb-row>
                    <mdb-col offsetMd="2" md="8" class="mt-4">
                      <label>{{
                        $t("allowance_rules.text.allowance_leave_day_typ")
                      }}</label>
                      <mdb-input
                        type="radio"
                        id="option-base_on_percentage"
                        name="option_ar_leave_day_type"
                        radioValue="base_on_percentage"
                        v-model="allowance_rule.ar_leave_day_type"
                        :label="
                          $t('allowance_rules.formInput.base_on_percentage')
                        "
                      />
                      <mdb-input
                        type="radio"
                        id="option-base_on_amount"
                        name="option_ar_leave_day_type"
                        radioValue="base_on_amount"
                        v-model="allowance_rule.ar_leave_day_type"
                        :label="$t('allowance_rules.formInput.base_on_amount')"
                      />
                      <mdb-input
                        type="radio"
                        id="option-base_on_daily_allowance"
                        name="option_ar_leave_day_type"
                        radioValue="base_on_daily_allowance"
                        v-model="allowance_rule.ar_leave_day_type"
                        :label="
                          $t(
                            'allowance_rules.formInput.base_on_daily_allowance'
                          )
                        "
                      />
                    </mdb-col>
                  </mdb-row>

                  <div
                    v-show="
                      allowance_rule.ar_leave_day_type ==
                      'base_on_daily_allowance'
                    "
                  >
                    <mdb-row>
                      <mdb-col offsetMd="2" md="8" class="mt-4">
                        <label>Daily Allowace Calculation Base on</label>
                        <mdb-form-inline>
                          <mdb-input
                            type="radio"
                            id="option2-total_days_in_month"
                            name="ar_daily_allowance_base_on"
                            radioValue="total_days_in_month"
                            v-model="allowance_rule.ar_daily_allowance_base_on"
                            :label="
                              $t(
                                'allowance_rules.formInput.total_days_in_month'
                              )
                            "
                          />
                          <mdb-input
                            type="radio"
                            id="option2-total_actual_working_days"
                            name="ar_daily_allowance_base_on"
                            radioValue="total_actual_working_days"
                            v-model="allowance_rule.ar_daily_allowance_base_on"
                            :label="
                              $t(
                                'allowance_rules.formInput.total_actual_working_days'
                              )
                            "
                          />
                        </mdb-form-inline>
                      </mdb-col>
                    </mdb-row>
                  </div>

                  <div
                    v-show="
                      allowance_rule.ar_leave_day_type !=
                      'base_on_daily_allowance'
                    "
                  >
                    <mdb-row>
                      <mdb-col offsetMd="2" md="8">
                        <label class="mt-2"> Time Range:</label>
                        <mdb-btn
                          type="button"
                          class="btn_sm_light_blue"
                          color="uno-color"
                          @click="addLeaveDayDetail('', '', '')"
                          size="sm"
                        >
                          <mdb-icon icon="plus-square" size="md" />
                          Add New
                        </mdb-btn>
                      </mdb-col>
                    </mdb-row>

                    <div
                      v-for="(leave_day_item, counter) in leave_day_detail_list"
                      v-bind:key="counter"
                    >
                      <mdb-row>
                        <mdb-col offsetMd="2" md="4">
                          <mdb-input
                            type="number"
                            :label="$t('general.formInput.day')"
                            v-model="leave_day_item.no_of_day"
                            outline
                          />
                        </mdb-col>

                        <mdb-col md="4">
                          <mdb-input
                            type="number"
                            :label="
                              allowance_rule.ar_leave_day_type ==
                              'base_on_percentage'
                                ? $t('general.formInput.percentage')
                                : $t('general.formInput.amount')
                            "
                            v-model="leave_day_item.amount"
                            outline
                          />
                        </mdb-col>

                        <mdb-col offsetMd="2" md="8">
                          <mdb-btn
                            type="button"
                            size="sm"
                            @click="deleteLeaveDayDetail(counter)"
                            color="uno-color"
                            class="btn_sm_delete mt-4"
                            :disabled="
                              shouldDisabledDeleteLeaveDayDetailListButton
                            "
                          >
                            <mdb-icon icon="trash-alt" size="md" />
                            {{ $t("general.button.remove") }}
                          </mdb-btn>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row
                        v-if="counter < leave_day_detail_list.length - 1"
                      >
                        <mdb-col offsetMd="2" md="8">
                          <hr />
                        </mdb-col>
                      </mdb-row>
                    </div>
                  </div>
                </div>
              </div>

              <div v-show="allowance_rule.ar_payment_type == 'per_working_day'">
                <mdb-row class="mt-4">
                  <mdb-col offsetMd="2" md="8">
                    <label class="mt-2"> Minimun Hour Base On </label>
                    <mdb-form-inline>
                      <mdb-input
                        type="radio"
                        id="ar_min_hrs_depend_on-attendance_time"
                        name="ar_min_hrs_depend_on"
                        radioValue="attendance_time"
                        v-model="allowance_rule.ar_min_hrs_depend_on"
                        label="Attendance Time"
                      />

                      <mdb-input
                        type="radio"
                        id="ar_min_hrs_depend_on-ot_time"
                        name="ar_min_hrs_depend_on"
                        radioValue="ot_time"
                        v-model="allowance_rule.ar_min_hrs_depend_on"
                        label="Overtime Time"
                      />

                      <mdb-input
                        type="radio"
                        id="ar_min_hrs_depend_on-depend_on_checkout"
                        name="ar_min_hrs_depend_on"
                        radioValue="depend_on_checkout"
                        v-model="allowance_rule.ar_min_hrs_depend_on"
                        label="Depend on Check Out Time"
                      />

                      
                      <mdb-input
                        type="radio"
                        id="ar_min_hrs_depend_on-depend_on_overtime_request_time"
                        name="ar_min_hrs_depend_on"
                        radioValue="depend_on_overtime_request_time"
                        v-model="allowance_rule.ar_min_hrs_depend_on"
                        label="Depend on Overtime Request End Time"
                      />
                    </mdb-form-inline>
                  </mdb-col>
                </mdb-row>

                <mdb-row
                  v-if="
                    allowance_rule.ar_min_hrs_depend_on != 'depend_on_checkout' && allowance_rule.ar_min_hrs_depend_on != 'depend_on_overtime_request_time'
                  "
                >
                  <mdb-col offsetMd="2" md="8">
                    <mdb-input
                      type="text"
                      label="
                        Minimun hour to validate for allowance
                      "
                      v-model="allowance_rule.ar_minimum_hrs"
                      outline
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row
                  v-if="
                    allowance_rule.ar_min_hrs_depend_on == 'depend_on_checkout' || allowance_rule.ar_min_hrs_depend_on == 'depend_on_overtime_request_time'
                  "
                >
                  <mdb-col offsetMd="2" md="8">
                    <mdb-time-picker-2
                      id="CheckOuttimePicker"
                      :placeholder="$t('general.formInput.select_your_time')"
                      :label="allowance_rule.ar_min_hrs_depend_on == 'depend_on_checkout' ? 'Check Out Time' : 'Overtime Request Time'"
                      v-model="allowance_rule.ar_last_checkout"
                      :key="resetCounterKey"
                    />
                  </mdb-col>
                </mdb-row>

                <mdb-row>
                  <mdb-col offsetMd="2" md="8">
                    <mdb-input
                      type="text"
                      :label="
                        $t('allowance_rules.formInput.work_day_allowance')
                      "
                      v-model="allowance_rule.ar_working_day_amount"
                      outline
                    />
                  </mdb-col>

                  <mdb-col offsetMd="2" md="8">
                    <mdb-input
                      type="text"
                      :label="$t('allowance_rules.formInput.off_day_allowance')"
                      v-model="allowance_rule.ar_off_day_amount"
                      outline
                    />
                  </mdb-col>

                  <mdb-col offsetMd="2" md="8">
                    <mdb-input
                      type="text"
                      :label="
                        $t('allowance_rules.formInput.holiday_day_allowance')
                      "
                      v-model="allowance_rule.ar_holiday_day_amount"
                      outline
                    />
                  </mdb-col>
                </mdb-row>
              </div>

              <mdb-row class="mt-5">
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>
    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import AllowanceRule from "@/apis/AllowanceRule";
import LogList from "@/components/logs/List";
import AllowanceTypeDropdown from "@/components/AllowanceTypeDropdown";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbFormInline,
  mdbTimePicker2,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    mdbFormInline,
    AllowanceTypeDropdown,
    ModalDeleteConfimation,
    mdbTimePicker2,
  },
  props: ["_id"],
  data() {
    return {
      allowance_rule: {
        ar_id: "",
        ar_at_id: "",
        ar_name: "",
        ar_remark: "",
        ar_payment_type: "fixed_per_month",
        ar_fixed_amount: "",
        ar_depend_on: "none",
        ar_include_overtime_day: "no",
        ar_working_day_amount: "",
        ar_off_day_amount: "",
        ar_holiday_day_amount: "",
        ard_pay_percentage: [],

        ard_pay_amount: [],

        ard_day_num: [],
        ard_id: [],

        ar_min_hrs_depend_on: "attendance_time",
        ar_minimum_hrs: "",

        ar_employee_quit_base_on: "total_days_in_month",
        ar_leave_day_type: "base_on_percentage",
        ar_daily_allowance_base_on: "total_days_in_month",

        ar_last_checkout: "",
      },
      leave_day_detail_list: [
        {
          d_id: "",
          no_of_day: "",
          amount: "",
        },
      ],
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,

      resetCounterKey: 0,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.allowance_rule.ar_id != "" ? true : false;
    },
    shouldDisabledDeleteLeaveDayDetailListButton() {
      return this.leave_day_detail_list.length > 1 ? false : true;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    addLeaveDayDetail(d_id = "", no_of_day = "", amount = "") {
      this.leave_day_detail_list.push({
        d_id,
        no_of_day,
        amount,
      });
    },
    deleteLeaveDayDetail(counter) {
      this.leave_day_detail_list.splice(counter, 1);
    },
    setAllowanceTypeID(val) {
      this.allowance_rule.ar_at_id = val;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          AllowanceRule.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)
                  let ar_id,
                    ar_at_id,
                    ar_name,
                    ar_remark,
                    ar_payment_type,
                    ar_fixed_amount,
                    ar_depend_on,
                    ar_include_overtime_day,
                    ar_working_day_amount,
                    ar_off_day_amount,
                    ar_holiday_day_amount,
                    ar_min_hrs_depend_on,
                    ar_minimum_hrs,
                    ar_employee_quit_base_on,
                    ar_leave_day_type,
                    ar_daily_allowance_base_on,
                    ar_last_checkout;

                  // populate field
                  ({
                    ar_id,
                    ar_at_id,
                    ar_name,
                    ar_remark,
                    ar_payment_type,
                    ar_fixed_amount,
                    ar_depend_on,
                    ar_include_overtime_day,
                    ar_working_day_amount,
                    ar_off_day_amount,
                    ar_holiday_day_amount,
                    ar_min_hrs_depend_on,
                    ar_minimum_hrs,
                    ar_employee_quit_base_on,
                    ar_leave_day_type,
                    ar_daily_allowance_base_on,
                    ar_last_checkout,
                  } = response.data.data);
                  this.allowance_rule.ar_id = ar_id;
                  this.allowance_rule.ar_at_id = ar_at_id;
                  this.allowance_rule.ar_name = ar_name;
                  this.allowance_rule.ar_remark = ar_remark;
                  this.allowance_rule.ar_payment_type = ar_payment_type;
                  this.allowance_rule.ar_fixed_amount = ar_fixed_amount;
                  this.allowance_rule.ar_depend_on = ar_depend_on;
                  this.allowance_rule.ar_include_overtime_day =
                    ar_include_overtime_day == "0" ? "no" : "yes";
                  this.allowance_rule.ar_working_day_amount =
                    ar_working_day_amount;
                  this.allowance_rule.ar_off_day_amount = ar_off_day_amount;
                  this.allowance_rule.ar_holiday_day_amount =
                    ar_holiday_day_amount;

                  this.allowance_rule.ar_min_hrs_depend_on =
                    ar_min_hrs_depend_on;
                  this.allowance_rule.ar_minimum_hrs = ar_minimum_hrs;

                  this.allowance_rule.ar_employee_quit_base_on =
                    ar_employee_quit_base_on;
                  this.allowance_rule.ar_leave_day_type = ar_leave_day_type;
                  this.allowance_rule.ar_daily_allowance_base_on =
                    ar_daily_allowance_base_on;

                  this.allowance_rule.ar_last_checkout = ar_last_checkout;

                  if (
                    ar_payment_type == "fixed_per_month" &&
                    ar_depend_on == "leave_day"
                  ) {
                    if (response.data.data.detail.length > 0) {
                      //remove constructor
                      this.leave_day_detail_list = [];

                      if (ar_leave_day_type == "base_on_percentage") {
                        for (let leave_day_item of response.data.data.detail) {
                          this.addLeaveDayDetail(
                            leave_day_item.ard_id,
                            leave_day_item.ard_day_num,
                            leave_day_item.ard_pay_percentage
                          );
                        }
                      } else if (ar_leave_day_type == "base_on_amount") {
                        for (let leave_day_item of response.data.data.detail) {
                          this.addLeaveDayDetail(
                            leave_day_item.ard_id,
                            leave_day_item.ard_day_num,
                            leave_day_item.ard_pay_amount
                          );
                        }
                      }
                    }
                  }

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listAllowanceRule" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listAllowanceRule" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listAllowanceRule" });
        }
      } else {
        // add
      }
    },

    prepareLeaveDayDetailList() {
      this.allowance_rule.ard_id = [];
      this.allowance_rule.ard_day_num = [];
      this.allowance_rule.ard_pay_percentage = [];
      this.allowance_rule.ard_pay_amount = [];

      if (this.leave_day_detail_list.length > 0) {
        if (this.allowance_rule.ar_leave_day_type == "base_on_percentage") {
          for (let leave_day_item of this.leave_day_detail_list) {
            this.allowance_rule.ard_id.push(leave_day_item.d_id);
            this.allowance_rule.ard_day_num.push(leave_day_item.no_of_day);
            this.allowance_rule.ard_pay_percentage.push(leave_day_item.amount);
          }
        } else if (this.allowance_rule.ar_leave_day_type == "base_on_amount") {
          for (let leave_day_item of this.leave_day_detail_list) {
            this.allowance_rule.ard_id.push(leave_day_item.d_id);
            this.allowance_rule.ard_day_num.push(leave_day_item.no_of_day);
            this.allowance_rule.ard_pay_amount.push(leave_day_item.amount);
          }
        }
      }
    },

    handleFormSubmit() {
      if (this.allowance_rule.ar_payment_type == "fixed_per_month") {
        delete this.allowance_rule.ar_include_overtime_day;
        delete this.allowance_rule.ar_working_day_amount;
        delete this.allowance_rule.ar_off_day_amount;
        delete this.allowance_rule.ar_holiday_day_amount;

        delete this.allowance_rule.ar_min_hrs_depend_on;
        delete this.allowance_rule.ar_minimum_hrs;

        if (this.allowance_rule.ar_depend_on == "leave_day") {
          if (
            this.allowance_rule.ar_leave_day_type != "base_on_daily_allowance"
          ) {
            this.prepareLeaveDayDetailList();
            delete this.allowance_rule.ar_daily_allowance_base_on;
          } else {
            delete this.allowance_rule.ard_id;
            delete this.allowance_rule.ard_day_num;
            delete this.allowance_rule.ard_pay_percentage;
            delete this.allowance_rule.ard_pay_amount;
          }
        } else {
          //none clause
          delete this.allowance_rule.ar_leave_day_type;
          delete this.allowance_rule.ar_daily_allowance_base_on;

          delete this.allowance_rule.ard_id;
          delete this.allowance_rule.ard_day_num;
          delete this.allowance_rule.ard_pay_percentage;
          delete this.allowance_rule.ard_pay_amount;
        }
      } else {
        delete this.allowance_rule.ar_fixed_amount;
        delete this.allowance_rule.ar_depend_on;

        delete this.allowance_rule.ar_employee_quit_base_on;
        delete this.allowance_rule.ar_leave_day_type;
        delete this.allowance_rule.ar_daily_allowance_base_on;

        delete this.allowance_rule.ard_id;
        delete this.allowance_rule.ard_day_num;
        delete this.allowance_rule.ard_pay_percentage;
        delete this.allowance_rule.ard_pay_amount;
      }

      this.resetAlert();
      this.formSubmitting = true;
      AllowanceRule.save(this.allowance_rule).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.allowance_rule.ar_id != "") {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "listAllowanceRule" });
            } else {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({ name: "listAllowanceRule" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      // Object.keys(this.allowance_rule).forEach((key) => {
      //   this.allowance_rule[key] = "";
      // });
      this.allowance_rule = {
        ar_id: "",
        ar_at_id: "",
        ar_name: "",
        ar_remark: "",
        ar_payment_type: "fixed_per_month",
        ar_fixed_amount: "",
        ar_depend_on: "none",
        ar_include_overtime_day: "no",
        ar_working_day_amount: "",
        ar_off_day_amount: "",
        ar_holiday_day_amount: "",
        ard_pay_percentage: [],
        ard_pay_amount: [],
        ard_day_num: [],
        ard_id: [],

        ar_min_hrs_depend_on: "attendance_time",
        ar_minimum_hrs: "",

        ar_employee_quit_base_on: "total_days_in_month",
        ar_leave_day_type: "base_on_percentage",
        ar_daily_allowance_base_on: "total_days_in_month",

        ar_last_checkout: "",
      };

      this.leave_day_detail_list = [
        {
          d_id: "",
          no_of_day: "",
          amount: "",
        },
      ];

      this.resetCounterKey++;

      this.doesFormReset = !this.doesFormReset;
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.allowance_rule.ar_id > 0) {
        AllowanceRule.delete({ ar_id: this.allowance_rule.ar_id })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({ name: "listAllowanceRule" });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>