<template>
  <div>
    <mdb-select
      outline
      v-model.trim="monthOptions"
      label="Select Month"
      placeholder=""
      v-on:getValue="getSelectMonthValue"
    />
  </div>
</template>

<script>
import { mdbSelect } from "mdbvue";

const month_name = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthOption = (month) => {
  let months = [];
  for (let i = 0; i < month_name.length; i++) {
    if (month == month_name[i])
      months.push({
        text: month_name[i],
        value: month_name[i],
        selected: true,
      });
    else months.push({ text: month_name[i], value: month_name[i] });
  }
  return months;
};

export default {
  name: "MonthDropdown",
  components: {
    mdbSelect,
  },
  props: ["selected_date", "parent_index"],
  data() {
    return {
      monthOptions: null,
      filter_month: "",
    };
  },

  methods: {
    getSelectMonthValue(value) {
      this.filter_month = value;

      const index = month_name.findIndex((mn) => mn === value) + 1;

      if (typeof this.parent_index == "undefined") {
        if (index < 9) {
          this.$emit("updateMonth", "0" + index);
        } else {
          this.$emit("updateMonth", "" + index);
        }
      }else{
        if (index < 9) {
          this.$emit("updateMonth", {value:"0" + index, parent_index:this.parent_index});
        } else {
          this.$emit("updateMonth", {value:"" + index, parent_index:this.parent_index});
        }
      }
    },
  },

  created() {
    if (this.selected_date != "") {
      // const parts = this.selected_date.split("-");
      // // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
      // // January - 0, February - 1, etc.
      // const mydate = new Date(parts[0], parts[1] - 1, parts[2]);
      // this.filter_month = mydate.toLocaleString("default", { month: "long" });

      const tmp_index = parseInt(this.selected_date) - 1;

      if(tmp_index >=0){
        this.filter_month = month_name[tmp_index];
      }

    }
    // console.log("typeof" + typeof(this.parent_index));

    // console.log("parent index" + this.parent_index);
    this.monthOptions = monthOption(this.filter_month);
  },
};
</script>