<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="feedback_error">
      <div v-if="typeof feedback_message === 'string'">
        {{ feedback_message }}
      </div>
      <div v-else v-for="(error, index) in feedback_message" :key="index">
        {{ error.message }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <mdb-spinner big color="blue" v-show="isLoading" />
    </div>

    <div v-show="!isLoading">
      <template>
        <mdb-card class="mb-5">
          <mdb-card-body>
            <form @submit.prevent="handleFormSubmit">
              <mdb-row>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.name')"
                    v-model="company_branch.cb_name"
                    outline
                    required
                  />
                </mdb-col>

                <mdb-col md="4">
                  <CompanyDropdown
                    @updateCompanyID="setCompanyID"
                    :default_selected_value="company_branch.cb_company_id"
                    :required="true"
                    :resetSelected="doesFormReset"
                  />
                </mdb-col>

                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.phone_no')"
                    v-model="company_branch.cb_phone_no"
                    outline
                  />
                </mdb-col>

                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.country')"
                    v-model="company_branch.cb_country"
                    outline
                  />
                </mdb-col>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.state')"
                    v-model="company_branch.cb_state"
                    outline
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-input
                    type="text"
                    :label="$t('general.formInput.township')"
                    v-model="company_branch.cb_township"
                    outline
                  />
                </mdb-col>
                <mdb-col offsetMd="2" md="4">
                  <mdb-input
                    type="textarea"
                    :label="$t('general.formInput.address')"
                    v-model="company_branch.cb_address"
                    outline
                  />
                </mdb-col>
                <mdb-col md="4">
                  <mdb-input
                    type="textarea"
                    :label="$t('general.formInput.remark')"
                    v-model="company_branch.cb_remark"
                    outline
                  />
                </mdb-col>
              </mdb-row>

              <mdb-row>
                <mdb-col offsetMd="2" md="8">
                  <mdb-btn
                    type="button"
                    class="btn_back"
                    color="uno-color"
                    @click="redirectBackAction"
                  >
                    <mdb-icon icon="arrow-left" size="md" />
                    {{ $t("general.button.go_back") }}
                  </mdb-btn>

                  <mdb-btn
                    type="Submit"
                    color="uno-color"
                    class="btn_submit"
                    :disabled="formSubmitting"
                  >
                    <mdb-icon
                      icon="file-upload"
                      size="md"
                      v-show="!formSubmitting"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="formSubmitting"
                    ></span>
                    {{ $t("general.button.submit") }}
                  </mdb-btn>

                  <mdb-btn
                    type="button"                    
                    :disabled="deleteSubmitted"
                    @click="showDeleteModal"
                    v-show="doesEditForm"
                    color="uno-color"
                    class="btn_delete float-right"
                  >
                    <mdb-icon
                      icon="trash-alt"
                      far
                      size="md"
                      v-show="!deleteSubmitted"
                    />

                    <span
                      class="spinner-border-vcenter spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="deleteSubmitted"
                    ></span>
                    {{ $t("general.button.delete") }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
            </form>
          </mdb-card-body>
        </mdb-card>
      </template>
    </div>

    <log-list :logs="logs"></log-list>
    <ModalDeleteConfimation @comfirmAction="handleDeleteRecord" />
  </div>
</template>
<script>
import CompanyBranch from "@/apis/CompanyBranch";
import LogList from "@/components/logs/List";
import CompanyDropdown from "@/components/CompanyDropdown";
import ModalDeleteConfimation from "@/components/ModalDeleteConfimation";
import { mapActions, mapGetters } from "vuex";
import {
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    LogList,
    mdbIcon,
    CompanyDropdown,
    ModalDeleteConfimation,
  },
  props: ["_id"],
  data() {
    return {
      company_branch: {
        cb_id: "",
        cb_name: "",
        cb_company_id: "",
        cb_countr: "",
        cb_state: "",
        cb_township: "",
        cb_address: "",
        cb_phone_no: "",
        cb_remark: "",
      },
      logs: [],
      formSubmitting: false,
      deleteSubmitted: false,
      feedback_error: false,
      feedback_message: "",
      doesFormReset: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    doesEditForm() {
      return this.company_branch.cb_id != "" ? true : false;
    },
  },

  methods: {
    ...mapActions(["updateLoading"]),
    showDeleteModal() {
      this.$store.state.deleteModalVisible = true;
    },
    setCompanyID(val) {
      this.company_branch.cb_company_id = val;
    },
    fetchRecord() {
      if (this._id != undefined) {
        if (Math.sign(this._id) === 1) {
          this.updateLoading(true);
          CompanyBranch.view(this._id)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  // console.log(response.data)
                  let cb_id,
                    cb_name,
                    cb_company_id,
                    cb_country,
                    cb_state,
                    cb_township,
                    cb_address,
                    cb_phone_no,
                    cb_remark;

                  // populate field
                  ({
                    cb_id,
                    cb_name,
                    cb_company_id,
                    cb_country,
                    cb_state,
                    cb_township,
                    cb_address,
                    cb_phone_no,
                    cb_remark,
                  } = response.data.data);
                  this.company_branch.cb_id = cb_id;
                  this.company_branch.cb_name = cb_name;
                  this.company_branch.cb_company_id = cb_company_id;
                  this.company_branch.cb_country = cb_country;
                  this.company_branch.cb_state = cb_state;
                  this.company_branch.cb_township = cb_township;
                  this.company_branch.cb_address = cb_address;
                  this.company_branch.cb_phone_no = cb_phone_no;
                  this.company_branch.cb_remark = cb_remark;

                  // logs
                  if (response.data.logs.length > 0) {
                    this.logs = response.data.logs;
                  }
                  this.updateLoading(false);
                }
              } else {
                this.$router.push({ name: "listCompanyBranch" });
              }
            })
            .catch(() => {
              this.$router.push({ name: "listCompanyBranch" });
            });
        } else {
          // not a number
          this.$router.push({ name: "listCompanyBranch" });
        }
      } else {
        // add
      }
    },

    handleFormSubmit() {
      this.resetAlert();
      this.formSubmitting = true;
      CompanyBranch.save(this.company_branch).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (this.company_branch.cb_id != "") {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
              this.$router.push({ name: "listCompanyBranch" });
            } else {
              this.resetForm();
              this.$notify.success({
                message: response.data.message,
                position: "top right",
                timeOut: 5000,
              });
            }
          } else if (response.data.code === 400) {
            if (response.data.error.length > 0) {
              this.feedback_message = response.data.error;
            }
            this.feedback_error = true;
          } else if (response.data.code === 500) {
            this.feedback_message = "Error";
            this.feedback_error = true;
          }

          this.formSubmitting = false;
        }
      });
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    redirectBackAction() {
      this.$router.push({ name: "listCompanyBranch" });
    },
    resetAlert() {
      this.feedback_error = false;
    },
    resetForm() {
      Object.keys(this.company_branch).forEach((key) => {
        this.company_branch[key] = "";
      });
      this.doesFormReset = !this.doesFormReset;
    },
    handleDeleteRecord() {
      this.deleteSubmitted = true;
      this.$store.state.deleteModalVisible = false;

      if (this.company_branch.cb_id > 0) {
        CompanyBranch.delete({ cb_id: this.company_branch.cb_id })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$notify.success({
                  message: response.data.message,
                  position: "top right",
                  timeOut: 5000,
                });

                this.$router.push({ name: "listCompanyBranch" });
              } else if (response.data.code === 400) {
                if (response.data.error.length > 0) {
                  this.feedback_message = response.data.error;
                }
                this.feedback_error = true;
              } else if (response.data.code === 500) {
                this.feedback_message = "Error";
                this.feedback_error = true;
              }

              this.deleteSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.fetchRecord();
  },
};
</script>