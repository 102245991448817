import Api from './api'

export default {


  exportSpecialReport(payload) {
    if(payload.type == 'executive'){
      return Api.post('api/report/executive_summary', payload)
    }else if(payload.type == 'salary'){
      return Api.post('api/report/salary_summary', payload)
    }else if(payload.type == 'head_count'){
      return Api.post('api/report/head_count', payload)
    }else if(payload.type == 'actions'){
      return Api.post('api/report/action', payload)
    }else if(payload.type == 'leaves'){
      return Api.post('api/report/leave', payload)
    }else if(payload.type == 'trun_over'){
      return Api.post('api/report/turnover', payload)
    }else if(payload.type == 'hr_training'){
      return Api.post('api/report/hr_training', payload)
    }
    
  },

  fixedAttendance(payload){
    return Api.get('api/report/attendance/fixed_attendance_users', {params: payload})
  },

  
  fixedAttendanceExport(payload){
    return Api.post('api/report/fixed_attendance_users/export', payload)
  },

  accountReport(payload){
    return Api.post('api/report/payroll/account_report', payload)
  },
  bankReport(payload){
    return Api.post('api/report/payroll/bank_report', payload)
  },
  taxReport(payload){
    return Api.post('api/report/payroll/tax', payload)
  },  
  ssbReport(payload){
    return Api.post('api/report/payroll/ssb', payload)
  },


  overtimeReport(payload){
    if(payload.export_type == 'summary'){
      return Api.post('api/report/overtime/summary', payload)
    }else{  
      return Api.post('api/report/overtime/detail', payload)
    }
  },

  pendingAttendance(payload){
    return Api.get('api/report/attendance/summary_pending_report', {params: payload})
  },
  pendingAttendanceDetail(payload){
    return Api.get('api/report/attendance/detail_pending_report', {params: payload})
  },

  pendingLeave(payload){
    return Api.get('api/report/leave/summary_pending_report', {params: payload})
  },
  pendingLeaveDetail(payload){
    return Api.get('api/report/leave/detail_pending_report', {params: payload})
  },

  pendingOvertime(payload){
    return Api.get('api/report/overtime/summary_pending_report', {params: payload})
  },
  pendingOvertimeDetail(payload){
    return Api.get('api/report/overtime/detail_pending_report', {params: payload})
  },

  
  attendanceLateEarly(payload){
    return Api.post('api/report/attendance/late_early', payload)
  },
  
  leaveTakenBalance(payload){
    return Api.post ('api/report/leave/balance', payload)
  },
  SummaryleaveTakenBalanceYearly(payload){
    return Api.post ('api/report/leave/balance/summary', payload)
  },
  SummaryleaveTakenBalanceContract(payload){
    return Api.post ('api/report/leave/balance/summary/contract', payload)
  },
  


  documentDownload(payload){
    return Api.post ('api/employees/documents/zip_download', payload)
  },


  currentLeaveReport(payload){
    return Api.get('api/manage_leave/export', {params: payload})
  },
  
  newHiringReport(payload){
    return Api.post('api/report/newhire/export', payload)
  },



  EmployeePersonalReport(payload){
    return Api.post('api/report/personal_detail', payload)
  },

  

  WorkingHistoryExport(payload){
    return Api.post('api/report/working_history/export', payload)
  },
  EmployeeTrainingReport(payload){
    return Api.post('api/report/employee_training/export', payload)
  },
  EmployeeEducationReport(payload){
    return Api.post('api/report/employee_education/export', payload)
  },

  
  PermanentEmployeeReport(payload){
    return Api.post('api/report/monthly_permanent', payload)
  },

  requestCalculateTimetable(payload){
    
    return Api.post('api/report/time_table/request_calculate', payload)
  },  
  submitCalculateTimetable(payload){
    
    return Api.post('/api/report/time_table/calculate', payload)
  },  
  downloadTimetableReport(payload){
    
    return Api.post('/api/report/time_table/export', payload)
  },

  // Menpower
  requestCalculateTimetableMenpower(payload){
    
    return Api.post('api/report/manpower_byworkinghour/request_calculate', payload)
  },  
  submitCalculateTimetableMenpower(payload){
    
    return Api.post('/api/report/manpower_byworkinghour/calculate', payload)
  },  
  downloadTimetableMenpowerReport(payload){
    
    return Api.post('/api/report/manpower_byworkinghour/export', payload)
  },

  SeparationReport(payload){
    return Api.post('/api/report/separation/export', payload)
  },
  
  SalarybyDepartmentsReport(payload){
    return Api.post('/api/report/salary/export', payload)
  },
  
  StaffMovementReport(payload){
    return Api.post('/api/report/working_history/changes/export', payload)
  },


  
  usageGraphData(payload){
    return Api.post('/api/management/report/invoice/graph', payload)
  },
  usageData(payload){
    return Api.post('/api/management/report/invoice/data', payload)
  },


  
  SBGusageGraphData(payload){
    return Api.post('/api/management/report/sbg/graph', payload)
  },
  SBGusageData(payload){
    return Api.post('api/management/report/sbg/data', payload)
  },
  SBGusageAnalysis(payload){
    return Api.post('api/management/report/sbg/analysis', payload)
  },


  
  otherAddition(payload){
    return Api.post('/api/report/addition', payload)
  },
  otherDeduction(payload){
    return Api.post('/api/report/deduction', payload)
  },


  attendancePolicyExport(payload){
    return Api.post('/api/report/attendance_policy_export', payload)
  },

}